import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Icon from '@material-ui/core/Icon';

import { withStyles } from '@material-ui/core/styles';

import { icon } from 'util/icon';

const styles = {
    badge: {
        top: '2px',
        right: '2px',
        minWidth: '16px',
        height: '16px',
        boxShadow: '-1px 1px 0 0'
    }
};

const StyledBadge = withStyles(styles)(Badge);

export default class ResourceIcon extends React.PureComponent {

    withBadge = (avatar, value, color) => {
        return <StyledBadge
            badgeContent={value}
            color="secondary"
            invisible={false}
            classes={{ badge: color ? 'bg-'+color : null }}
        >
            {avatar}
        </StyledBadge>
    };
    
    render() {
        const { isActive, resource, resourceTypeKey, badgeValue, color } = this.props;

        let avatarClassName = isActive
            ? 'list-item__avatar active'
            : 'list-item__avatar';

        let iconComponent = icon(resourceTypeKey) || icon(resource);
        //if (!iconComponent) return <div className={avatarClassName} />;

        const avatar = (<Avatar color="inherit" classes={{ colorDefault: '' }} className={avatarClassName}>
            {iconComponent}
        </Avatar>);
        
        if (badgeValue || color) {
            return this.withBadge(avatar, badgeValue, color);
        }
        
        return avatar;
    }

}

ResourceIcon.propTypes = {
    isActive: PropTypes.bool,
    resource: PropTypes.string.isRequired,
    resourceTypeKey: PropTypes.string
};