import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {List} from 'list';

import {
    selectPath,
    selectResourceDefinition
} from 'state/selectors/resources';

import { selectListViewConfig } from 'state/selectors/list';

const mapStateToProps = (store, props) => {
    return {
        data: selectPath(store, props.path.split('/')) || [],
        definition: selectResourceDefinition(store, props.resource),
        config: selectListViewConfig(store, props)
    }
};

const mapDispatchToProps = {};

class NestedList extends React.PureComponent {

    render() {
        return (<List {...this.props} />);
    }

}

NestedList.propTypes = {
    data: PropTypes.array.isRequired,
    resource: PropTypes.string.isRequired
};

const ConnectedNestedList = connect(
    mapStateToProps,
    mapDispatchToProps
)(NestedList);

ConnectedNestedList.propTypes = {
    path: PropTypes.string.isRequired,
    resource: PropTypes.string.isRequired
};

export default ConnectedNestedList;