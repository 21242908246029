import React from 'react';
import {connect} from 'react-redux';
import {selectAllResources} from "state/selectors/resources";
import {uiNotificationsToggle} from "state/actions/ui";

import Notifications from 'components/layout/Notifications';

const mapStateToProps = (store, props) => {
    const all = selectAllResources(store, 'app.notifications');
    const pending = all
        ? all.filter((task)=>task.status!=='success')
        : 0;
    return {
        count: pending.length,
        events: all, //all ? all.reverse().slice(0,4) : [],
        open: store.ui.notifications
    }
};

const mapDispatchToProps = {
    toggleNotifications: uiNotificationsToggle
};

class NotificationsIcon extends React.PureComponent {

    render() {
        return (<Notifications {...this.props} />);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationsIcon);