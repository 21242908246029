import React, {Component} from 'react';

export default class Newsletter extends React.PureComponent {

    render() {
        return (<form className="l-rows l-rows--gap-md">
            <div className="l-area">
                <input
                    className="input--text"
                    placeholder="Wpisz adres email"
                />
            </div>
            <div className="l-area l-area--centered">
                <button className="c-btn c-btn--btn c-btn--success c-btn--md">
                    <span>
                        <div className="c-btn__label">Zapisz się</div>
                    </span>
                </button>
            </div>
        </form>);
    }

}