const base = 'console/themes/:id/vcs';

export default {
    'theme.vcs': {
        path: base
    },
    'theme.vcs.commit': {
        path: base + '/commit'
    },
    'theme.vcs.init': {
        path: base + '/init'
    },
    'theme.vcs.push': {
        path: base + '/push'
    },
    'theme.vcs.pull': {
        path: base + '/pull'
    }
};