import React from 'react';
import {connect} from 'react-redux';

import { stylesHelper } from 'state/selectors/styles';
import Preloader from "components/Preloader";
import { changed } from 'util/changed';

const mapStateToProps = (store, props) => {
    const styles = store.data['theme.styles'];
    const drafts = store.drafts['theme.styles'];
    const hasStyles = styles && Object.keys(styles).length > 0;
    return {
        styles: styles,
        drafts,
        hasStyles: hasStyles
    }
};

const mapDispatchToProps = {};

class Styled extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            s: null
        }
    }

    componentDidMount() {
        if (this.props.hasStyles)
            this.updateStyles();
    }

    componentDidUpdate(prevProps) {
        if ((!this.state.s && this.props.hasStyles)
            || changed(['styles', 'drafts'], prevProps, this.props)
        ) {
            this.updateStyles();
        }
    }

    updateStyles = () => {
        if (!this.props.hasStyles) return;
        setTimeout(()=>{
            this.setState({s: stylesHelper({
                data: { 'theme.styles': this.props.styles },
                drafts: { 'theme.styles': this.props.drafts || {} }
            }, this.props)});
        }, 1)
    };

    render() {
        const { render } = this.props;
        return <React.Fragment>
            <Preloader visible={false} />
            {this.state.s ? render(this.state) : null}
        </React.Fragment>;
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(Styled);