import React from 'react';
import {connect} from 'react-redux';
import {draftsSubmit} from 'state/actions';
import {selectResource} from 'state/selectors/resources';
import Field from 'v2/Field';
import IconButton from 'components/TooltipIconButton';
import TextField from 'components/fields/text/TextField';
import Checkbox from 'components/fields/checkbox/CheckboxField';
import ItemActions from './ItemActions';
import {RenderValue} from 'v2/list-items';
import {renderAddress} from 'v2/fields/info-fields/Address';
import Badge from '@material-ui/core/Badge';
import TaskStaySummary from "./tasks/TaskStaySummary";
import {icon} from 'util/icon';

const mapStateToProps = (store, props) => {
    let data = selectResource(store, props.resource, props.id);
    let type = data.subtype ? selectResource(store, 'db.types', data.subtype) : {};
    let status = data.lifecycle_status ? selectResource(store, 'db.enum-values', data.lifecycle_status) : {};
    let color = type.color;
    let priority = !!data.priority;
    let description = data.description;
    let location = data.location;
    let subtitle = location ? [location.street, location.street_no].join(' ') : null;
    if (!description && subtitle) {
        description = subtitle;
        subtitle = null;
    }
    return {
        color,
        priority,
        status,
        data,
        progress: data.task_progress || {},
        description,
        subtitle
    }
};

const mapDispatchToProps = {
    onSubmitTask: draftsSubmit
};

class InlineEdit extends React.PureComponent {
    handleChange = (evt) => {
        this.props.onChange(
            this.props.id,
            evt.target.value
        )
    };

    handleKey = (e) => {
        if (!this.props.onEnterKey) return;
        let code = (e.keyCode ? e.keyCode : e.which);
        if (code === 13) {
            e.preventDefault();
            this.props.onEnterKey(e);
        }
    };

    render() {
        const { value } = this.props;
        return <textarea
            rows={1}
            wrap="off"
            onChange={this.handleChange}
            onKeyPress={this.handleKey}
            onBlur={this.props.onEnterKey}
        >
            {value}
        </textarea>;
    }
}

class Task extends React.PureComponent {

    submit = () => {
        const { resource, id } = this.props;
        let submitFn = this.props.onSubmit || this.props.onSubmitTask;
        submitFn(resource, id);
    };

    handleEnter = (evt) => {
        this.submit();
    };

    handleChange = (onChange, key, value) => {
        onChange(key, value);
        if (key !== 'description') this.submit();
    };

    renderValue = (props) => {
        return <RenderValue
            {...props}
            onChange={(key, value)=>this.handleChange(props.onChange, key, value)}
        />
    };

    render() {
        const { onClick, resource, id, color, priority, progress, status, description, subtitle, data } = this.props;
        const editable = false;

        const descField = 'description';
        const categoryField = 'assigned_to';
        const checkField = 'completed';

        const pendingSubtasks = data.subtasks_count || null; //progress.total ? progress.total-progress.complete : null;

        return (<div className="task-outer">
            { color ? <div className={'task-color bg-'+color} /> : null }
            <div className={'task-item'}>
            <div className="task-item__title" onClick={!editable ? onClick : null}>
                <Badge badgeContent={pendingSubtasks} invisible={!pendingSubtasks} color={'primary'}>
                    <Field
                        resource={resource}
                        id={id}
                        fieldName={checkField}
                        variant={'checker'}
                    >
                        {this.renderValue}
                    </Field>
                </Badge>
                { status.icon ? <div className={status.color} style={{
                        width: '32px',
                        height: '32px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        {icon(status.icon)}
                </div> : null }
                <div>
                    { description ? <div>{description}</div> : null }
                    { subtitle ? <div style={{ opacity: 0.5, fontSize: '10px', lineHeight: '1.6em' }}>{subtitle}</div> : null }
                </div>
            </div>
            {/**<Field
                resource={resource}
                id={id}
                fieldName={categoryField}
            >
                {this.renderValue}
            </Field>**/}
            <div style={{ fontSize: '11px', display: 'flex', alignItems: 'center' }}>
                <Field
                    resource={resource}
                    id={id}
                    fieldName="date_due"
                >
                    {this.renderValue}
                </Field>
                { priority ? <div style={{ marginLeft: '10px' }} className={'color-dot bg-red'} /> : null }
            </div>
            <ItemActions
                resource={resource}
                id={id}
                actions='default'
                isOpen={false}
                onActionRequest={this.props.onRequestAction}
            />
            </div>
            {data.stay_checkin || data.stay_checkout ?
                <div className={'task-info'}>
                    <TaskStaySummary
                        isCheckin={!!data.stay_checkin}
                        isCheckout={!!data.stay_checkout}
                        checkinHour={data.stay_checkin ? data.stay_checkin.checkin_hour : null}
                        checkoutHour={data.stay_checkout ? data.stay_checkout.checkout_hour : null}
                        guests={data.stay_checkin ? data.stay_checkin.stay_total_beds : null}
                        defaultGuests={data.max_beds || 0}
                    />
                </div>
            : null }
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Task);