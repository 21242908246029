import TextField from './text/TextField';
import Default from "./Default";
import TabField from './tab/TabField';
import CheckboxField from './checkbox/CheckboxField';
import SelectField from './select/SelectField';
import RichEditor from './rich-editor/RichEditor';
import IdentityIcon from 'components/form/IdentityIcon';
import Components from './components/Components';
import Collection from './collection';
import Datepicker from './datepicker/Date';
import Media from './media/Media';
import IconPicker from './icon/IconPickerField';
import CodeEditor from './code-editor/CodeEditor';
import ColorPicker from './theme/ColorPicker';
import ColorScheme from './theme/ColorScheme';
import Geolocation from './geolocation/Geolocation';
import ListResourceCollection from './collection/ListResourceCollection';
import Seo from './seo/Seo';
import Slug from './text/Slug';
import Typography from './theme/Typography';
import TypeStyles from './theme/TypeStyles';
import DataMapping from './data-mapping/DataMapping';
import Ref from './ref/RefField';
import SchemaRef from './SchemaRef';
import AddressField from './address/AddressField';
import ColorField from './color/ColorField';
import LayoutField from './layout/LayoutField';
import SyncMapping from './data-mapping/SyncMapping';
import PropertyMap from './property-map/PropertyMap';

export default {
    text: TextField,
    collection: Collection,
    components: Components,
    rich: RichEditor,
    select: SelectField,
    tab: TabField,
    media: Media,
    date: Datepicker,
    checkbox: CheckboxField,
    geolocation: Geolocation,
    fontawesome: IconPicker,
    icon: IconPicker,
    'section-display': SelectField,
    slug: Slug,
    colors: ColorScheme,
    color: ColorPicker,
    typography: Typography,
    'type-styles': TypeStyles,
    font: Default,
    code: CodeEditor,
    style: SelectField,
    default: Default,
    id: IdentityIcon,
    property_map: PropertyMap,
    'ui-color-picker': ColorField,
    'resource-collection': ListResourceCollection,
    'tab.seo': Seo,
    'data-mapping': DataMapping,
    ref: Ref,
    schema: SchemaRef,
    address: AddressField,
    layout: LayoutField,
    'sync-mapping': SyncMapping
};