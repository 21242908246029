import React from 'react';
import {connect} from 'react-redux';

import Container from 'ide/sidepanel/Container';
import SideNav from "./SideNav";

const mapStateToProps = (store, props) => {
    return {
        current: store.context.side,
        zoomOut: store.ui.ideZoomOut,
        hide: store.context.q === 'attach-section'
    }
};

const mapDispatchToProps = {};

class Sidepanel extends React.PureComponent {

    render() {
        const { hide } = this.props;
        
        let className = "dev-tools";
        if (hide) className += " dev-tools--hide";

        return (
            <div className="ide-aside">
                <div className="ide-nav">
                    <SideNav />
                </div>
                <div className={className}>
                    <Container />
                </div>
            </div>
        );

    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Sidepanel);