import React from 'react';
import {connect} from 'react-redux';
import SectionEmptyState from 'ide/ui/SectionEmptyState';
import Typography from '@material-ui/core/Typography';
import EmptyState from 'components/EmptyState';

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

class PageSectionsEmptyState extends React.PureComponent {

    render() {
        return (<SectionEmptyState>
            <EmptyState
                title="Dodaj sekcje"
            >
                Ta podstrona nie ma jeszcze żadnych sekcji
            </EmptyState>
        </SectionEmptyState>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PageSectionsEmptyState);