import React from 'react';
import {connect} from 'react-redux';
import {resourceRoute} from 'state/actions/route';

import Dialog from 'components/Dialog';
import ThemeVcsContainer from "../ThemeVcs";

const mapStateToProps = (store, props) => {
    return {
        open: store.context.q === 'sync'
    }
};

const mapDispatchToProps = {
    resourceRoute
};

class ApartmentsImport extends React.Component {

    handleClose = () => this.props.resourceRoute('apartments.resource-bindings', null, {q:null});

    render() {
        const { open } = this.props;
        return (<Dialog
            open={open}
            onClose={this.handleClose}
            title="Import from CSV"
            maxWidth="sm"
            fullWidth={true}
        >
            import
        </Dialog>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ApartmentsImport);