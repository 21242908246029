import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';

import List from 'v2/List';
import Resource from 'v2/Resource';
import Field from 'v2/Field';
import Popover from 'components/Popover';
import MuiList from '@material-ui/core/MenuList';
import MuiListItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Button from '@material-ui/core/Button';
import Dot from 'components/Dot';
import Icon from '@material-ui/core/Icon';
import IconButton from 'components/TooltipIconButton';
import TextField from 'components/fields/text/TextField';
import ResourceContext from 'v2/ResourceContext';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {findDOMNode} from "react-dom";
import {icon} from 'util/icon';

const nullArr = [];

const arrayUtils = {
    toggle: function(arr, item) {
        let newValue = arr.slice();
        if (newValue.indexOf(item) === -1) {
            newValue.push(item);
        } else {
            newValue.splice(newValue.indexOf(item), 1);
        }
        return newValue;
    }
};

function normalizeValue({multiple, value}) {
    if (multiple && !value) return nullArr;
    return value;
}

const mapStateToProps = (store, props) => {
    return {
        value: normalizeValue(props)
    }
};

const mapDispatchToProps = {};

const styleDropdownLg = {
    fontWeight: '600',
    fontSize: '18px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse'
};

const styleBoxed = {
    backgroundColor: 'rgba(0,0,0,0.05)',
    borderRadius: '4px',
    padding: '4px 12px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: '600'
};

const style = {
    fontWeight: '600',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px'
};

function SelectValue(props) {
    const { label, value, multiple, radio, resource, variant, readOnly, onClick, onSelect, selection } = props;

    return <div className="select__values_outer"><div className="select__values">{value ? value.map(item=>{
        let isSelected = selection && selection.indexOf(item) > -1;
        let classes = {
            'select__value': true,
            'outlined': !!onSelect && !multiple,
            'selected': isSelected
        };
        classes[variant] = true;
        return <Field
            withData={true}
            fieldName="primaryText"
            key={item}
            resource={resource}
            id={item}
        >
            {({value, data})=><div
                onClick={onSelect && !readOnly ? ()=>onSelect(data.id) : null}
                className={classNames(classes)}
            >
                {radio && isSelected ? <div className="check-icon">
                    <Icon color="inherit" fontSize="inherit">check</Icon>
                </div> : null}
                <div className="select__value-text">
                    {data.color ? <Dot color={data.color} /> : null}
                    <div className="select__value-desc">{value}</div>
                </div>
                {multiple && !radio ?
                    <div className="clear-icon">
                        <Icon color="inherit" fontSize="inherit">clear_circle</Icon>
                    </div>
                : null}
            </div>}
        </Field>;
    }) : <div className="select__value select__value--empty">{label || 'choose'}</div> }
        { multiple && props.onClick ? <IconButton icon="add" onClick={props.onClick} /> : null }
    </div>
        {!multiple && !radio && !readOnly && variant !== 'input'
            ? <Icon>arrow_drop_down</Icon>
            : null }
    </div>
}

const ValueRenderer = children => props => {
    return <div>{children}</div>;
};

class SelectInput extends React.PureComponent {
    constructor(props) {
        super(props);
        this.InputLabelRef = null;
        this.state = {
            labelWidth: 0
        }
    }
    componentDidMount() {
        this.updateLabelWidth();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.label !== this.props.label) {
            this.updateLabelWidth();
        }
    }
    updateLabelWidth = () => {
        const label = findDOMNode(this.InputLabelRef);
        this.setState({
            labelWidth: label ? label.offsetWidth : 0,
        });
    };
    render() {
        const { value, radio, nullable, multiple, readOnly, label, onClick, className } = this.props;
        const clearIcon = nullable && value;
        const selectIcon = !radio && !clearIcon;
        return <FormControl variant="outlined" className="select--input" fullWidth={true}>
            <InputLabel ref={ref => {
                this.InputLabelRef = ref;
            }}>
                {this.props.label}
            </InputLabel>
            <OutlinedInput
                inputComponent={ValueRenderer(this.props.children)}
                readOnly={true}
                value={value ? ' ' : ''}
                labelWidth={this.state.labelWidth}
                endAdornment={<React.Fragment>
                    {clearIcon && !readOnly ? <IconButton icon="clear" onClick={()=>null} /> : null}
                    {selectIcon && !readOnly ? <IconButton
                        onClick={multiple ? onClick : ()=>null}
                        icon={multiple ? 'search' : 'keyboard_arrow_down'}
                    /> : null}
                </React.Fragment>}
                onClick={!multiple && !readOnly ? onClick : null}
                className={className}
            />
        </FormControl>
    }
}

function ControlWithLabel({label,variant,id,readOnly,children,onClick,className}) {
    return <div onClick={onClick} className={className}>
        { label ? <div className="select__label field__label">{label}</div> : null}
        {children}
    </div>;
};

function SelectMenu(props) {
    const {
        data,
        multiple,
        resource,
        onSelect,
        onClose,
        onSearch,
        onSelectAll,
        onClear,
        values
    } = props;

    const onClick = (value) => {
        onSelect(value);
        //onClose();
        if (!multiple) onClose();
    };

    let showFilter = onSearch && data.length > 6;

    return <div>
        { showFilter || multiple ? <div className="popover-filter">
            { showFilter ? <TextField
                id="search"
                variant="search"
                label="Szukaj"
                onChange={onSearch}
            /> : null }
            { multiple ? <div>
                <Button onClick={onSelectAll}>select all</Button>
                <Button onClick={onClear}>clear</Button>
            </div> : null }
        </div> : null }
        <MuiList className="popover-menu">{data.map(item=>{
            let isSelected = values && values.indexOf(item) > -1;
            return <Field withData={true} fieldName="primaryText" key={item} id={item} resource={resource}>
                {({id, value, data})=><MuiListItem
                    selected={isSelected && values.length === 1}
                    onClick={()=>onClick(item)}
                >
                    <div className="select-list__value">
                        {data.icon ? icon(data.icon) : null }
                        {data.color ? <Dot color={data.color} /> : null}
                        <span>{value || data.label || id}</span>
                    </div>
                    { isSelected ?
                        <ListItemSecondaryAction>
                            <ListItemIcon><Icon>check</Icon></ListItemIcon>
                        </ListItemSecondaryAction>
                    : null }
                </MuiListItem>}
            </Field>
        })}</MuiList>
    </div>;
}

class Select extends React.Component {

    handleChange = (item) => {
        const { id, onChange, value, multiple } = this.props;
        if (!onChange) return;
        onChange(id, multiple
            ? arrayUtils.toggle(value, item)
            : item
        );
    };

    handleSelectAll = (data) => {
        this.props.onChange(this.props.id, data);
    };

    handleClear = () => {
        this.props.onChange(this.props.id, []);
    };

    componentDidUpdate = () => {
        let value = this.props.value;
        let nullable = this.props.nullable;
    };

    render() {
        const { id, radio, label, source, value, inline, multiple, nullable, readOnly } = this.props;

        let values = value;
        let variant = this.props.variant || 'input';

        if (variant === 'input' && radio) variant = 'chip';

        let OuterElement = variant === 'input'
            ? SelectInput
            : ControlWithLabel;

        if (this.props.ValueComponent) {
            OuterElement = this.props.ValueComponent;
        }

        if (!multiple) {
            values = value ? [value] : value;
        }

        if (multiple || radio) variant = 'chip';

        let isEmpty = multiple && !value.length;

        let fetch = this.props.fetchPolicy || 'once';

        if (source.indexOf('api:enum-definitions/')===0)
            fetch = 'never';

        if (!Array.isArray(values) && values)
            return <span>value:{JSON.stringify(values)}</span>;

        let classes = {
            'select': true,
            'select--inline': !!inline
        };
        classes['select--'+variant] = true;

        if (isEmpty) classes['select--empty'] = true;

        if (radio) {
            return <List id={id} fetchStrategy={fetch} source={source}>
                {({resource, data})=>{
                    return <OuterElement {...this.props} variant={variant} className={classNames(classes)}>
                        <SelectValue
                            label={label}
                            value={data}
                            resource={resource}
                            multiple={multiple}
                            variant={variant}
                            radio={radio}
                            selection={values}
                            onSelect={radio ? this.handleChange: null}
                        />
                    </OuterElement>;
                }}
            </List>;
        }

        return (
            <ResourceContext.Consumer>
                {(contextResource)=><List
                    id={id}
                    fetchStrategy={fetch}
                    source={source}
                    contextResource={contextResource}
                >
                {({resource, data})=>{
                    return <React.Fragment>
                        <Popover
                            trigger={(props)=><OuterElement
                                {...this.props}
                                onClick={!readOnly && (!multiple || isEmpty) ? props.onClick : null}
                                variant={variant}
                                className={classNames(classes)}
                            >
                                <SelectValue
                                    readOnly={readOnly}
                                    label={label}
                                    variant={variant}
                                    multiple={multiple}
                                    value={values}
                                    radio={radio}
                                    resource={resource}
                                    onClick={props.onClick}
                                    onSelect={multiple ? this.handleChange : null}
                                />
                            </OuterElement>}
                            content={(props)=><SelectMenu
                                {...props}
                                values={values}
                                multiple={multiple}
                                onSelect={this.handleChange}
                                onSelectAll={()=>this.handleSelectAll(data)}
                                onClear={this.handleClear}
                                onSearch={this.props.onSearch}
                                data={data}
                                resource={resource}
                            />}
                        />
                    </React.Fragment>;
                }}
            </List>}
            </ResourceContext.Consumer>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Select);