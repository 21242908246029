import React from 'react';
import PropTypes from 'prop-types';

import TableHead from "@material-ui/core/TableHead";
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import {Listing} from 'list';
import Resource from 'v2/Resource';

class TableHeading extends React.PureComponent {
    render() {
        const { fields, sortable } = this.props;
        return <TableHead>
            <TableRow>
                { sortable ? <TableCell padding="checkbox" /> : null }
                {fields ? fields.map(field=><Resource
                    key={field}
                    resource="db.view_fields"
                    id={field}
                    fetchPolicy="never"
                >
                    {(props)=><TableCell
                        padding={props.data.source==='avatar' ? 'checkbox' : null}
                    >
                        {props.data.source}
                    </TableCell>}
                </Resource>) : null}
                <TableCell padding="checkbox" />
            </TableRow>
        </TableHead>;
    }
}

export default class TableListing extends React.PureComponent {

    render() {
        const { viewConfig, resource } = this.props;
        return (<Listing
            {...this.props}
            wrapperClass="full-width"
            layout="table"
            listHeader={<TableHeading
                sortable={this.props.variant==='sortable'}
                resource={resource}
                fields={viewConfig.view_fields}
            />}
        />);
    }

}

TableListing.propTypes = {};