import React from "react";

import Button from "./Button";
import Edit from "ide/ui/Edit";

export default class Text extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            force: false
        };
    }

    onChange = (key, value) => {
        const { onChange } = this.props;
        onChange(key, value);
    };

    handleForceShow = event => {
        event.stopPropagation();
        this.setState({
            force: !this.state.force
        });
    };

    render() {
        const { s, data, className, onFocus, editable, hideText } = this.props;

        const { title, headline, text, link } = data;

        const { force } = this.state;

        const headingStyle = s.get("heading") + " section-heading aligned";
        const titleStyle = s.get("title") + " heading-title aligned";
        const headlineStyle = s.get("headline") + " headline aligned";
        const textStyle = s.get("text_outer") + " text-outer";
        const buttonStyle = s.get("text_button") + " l-area";

        const containerStyle = className + ' ' + s.get('text');

        const showText = !hideText && (!!text || force);
        const showTitle = !!title || force;
        const showHeadline = !!headline || force;
        //const showLink = !!link || force;
        const showHeading = showTitle || showHeadline;
        const isEmpty = !showHeading && !showText;

        if (isEmpty) {
            return (
                <div className={containerStyle} onClick={this.handleForceShow}>
                    dodaj treść
                </div>
            );
        }

        return (
            <div
                className={containerStyle}
                onClick={onFocus}
                onDoubleClick={this.handleForceShow}
            >
                {showHeading ? (
                    <div className={headingStyle}>
                        {showTitle ? (
                            <Edit
                                onChange={this.onChange}
                                tagName="h2"
                                type="inline"
                                fieldKey="title"
                                placeholder="Lorem ipsum dolor"
                                className={titleStyle}
                                disabled={!editable}
                            >
                                {title}
                            </Edit>
                        ) : null}
                        {showHeadline ? (
                            <Edit
                                onChange={this.onChange}
                                tagName="div"
                                type="inline"
                                fieldKey="headline"
                                placeholder="Podtytuł"
                                className={headlineStyle}
                                disabled={!editable}
                            >
                                {headline}
                            </Edit>
                        ) : null}
                    </div>
                ) : null}
                {this.props.children}
                {showText ? (
                    <Edit
                        onChange={this.onChange}
                        type="rich"
                        fieldKey="text"
                        className={textStyle}
                        disabled={!editable}
                    >
                        {text}
                    </Edit>
                ) : null}
                {link ? (
                    <div className={buttonStyle}>
                        <Button
                            {...this.props}
                            data={{
                                ...this.props.data,
                                headline: null
                            }}
                        />
                    </div>
                ) : null}
            </div>
        );
    }
}
