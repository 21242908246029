import React from 'react';
import MaterialTextField from '@material-ui/core/TextField';
import Edit from 'ide/ui/Edit';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Input from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MaskedInput from 'react-text-mask';

import emailMask from 'text-mask-addons/dist/emailMask';

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            guide={false}
            mask={emailMask}
            placeholderChar={'\u2000'}
        />
    );
}

const searchInputClasses = {
    root: 'search-input'
};

export default class TextField extends React.PureComponent {

    handleChange = (event) => {
        this.props.onChange(this.props.id, event.target.value);
    };

    handleInlineChange = (key, value) => {
        this.props.onChange(this.props.id, value);
    };

    handleClear = () => {
        this.props.onChange(this.props.id, '');
    };

    render() {
        const { value, label, variant, readOnly, ValueComponent } = this.props;
        const normalizedValue = value || '';

        if (ValueComponent) {
            return <ValueComponent
                onChange={this.handleInlineChange}
                label={label}
                readOnly={readOnly}
                value={value}
            />
        }

        if (this.props.readonly) {
            return (<Typography variant="h5">{normalizedValue}</Typography>);
        }

        if (variant === 'inline') {
            return (<div style={{ fontSize: 'inherit' }}>
                { !readOnly ? <Edit
                    type="inline"
                    className={this.props.className}
                    tagName="div"
                    onChange={this.handleInlineChange}
                    placeholder={label}
                    onFocus={()=>null}
                    onBlur={this.props.onBlur}
                >
                    {value}
                </Edit> : normalizedValue }
            </div>);
        }
        if (variant === 'search') {
            return (<FormControl style={{ width: '100%' }}>
                <InputBase
                    name={this.props.id}
                    onChange={this.handleChange}
                    value={normalizedValue}
                    placeholder={this.props.label}
                    fullWidth={true}
                    type="text"
                    autoFocus={true}
                    classes={searchInputClasses}
                    startAdornment={<Icon className="search-input__icon">search</Icon>}
                    endAdornment={normalizedValue ? <IconButton onClick={this.handleClear}>
                        <Icon>clear</Icon>
                    </IconButton> : null}
                />
            </FormControl>);
        }
        const inputProps = {};

        if (this.props.startAdornment)
            inputProps.startAdornment = this.props.startAdornment;

        if (this.props.endAdornment)
            inputProps.endAdornment = this.props.endAdornment;

        if (this.props.mask)
            inputProps.inputComponent = TextMaskCustom;
        /*return <FormControl
            fullWidth={true}
            variant={'outlined'}
            error={this.props.error}
        >
            <InputLabel
                variant={'outlined'}
                htmlFor={this.props.id}
            >
                {this.props.label}
            </InputLabel>
            <Input
                id={this.props.id}
                value={normalizedValue}
                onChange={this.handleChange}
                {...inputProps} />
            {this.props.helperText ? <FormHelperText
                id={this.props.id}>
                {this.props.helperText}
            </FormHelperText> : null}
        </FormControl>;*/

        return (<MaterialTextField
            disabled={this.props.disabled || this.props.readonly}
            id={this.props.id}
            InputProps={inputProps}
            value={normalizedValue}
            label={this.props.label}
            onChange={this.handleChange}
            variant={this.props.variant || 'outlined'}
            size={this.props.size}
            fullWidth={true}
            autoFocus={this.props.autoFocus}
            placeholder={this.props.placeholder}
            helperText={this.props.helperText}
            error={this.props.error}
        />);
    }

}