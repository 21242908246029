import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {formLayoutDrop} from "state/actions/ide";

import {query} from 'state/actions/route';
import {draftsChangeField, draftsPathCreate, draftsPathDetach, draftsSave} from "state/actions";
import {selectResource} from 'state/selectors/resources';

import {List} from 'list';
import ViewField from "./Field";

import {FieldTypePlaceholder} from "./FormField";

const mapState = (store, props) => {
    const data = selectResource(
        store,
        props.fieldsetResource,
        props.fieldsetId
    );
    const fieldsKey = 'view_fields';
    const fieldResource = 'db.view_fields';

    let path = [
        props.fieldsetResource,
        props.fieldsetId,
        fieldsKey
    ].join('/');

    return {
        data,
        fieldsKey,
        fieldResource,
        fields: props.fields || data[fieldsKey],
        path
    }
};

const mapDispatch = {
    onCreate: draftsPathCreate,
    onDelete: draftsPathDetach,
    onChange: draftsChangeField,
    onDrop: formLayoutDrop,
    query
};

export class Fieldset extends React.PureComponent {

    handleChange = (field, value) => {
        this.props.onChange(
            this.props.fieldsetResource,
            this.props.fieldsetId,
            field,
            value
        );
    };

    handleEdit = (id) => {
        this.props.query({edit: [
            this.props.fieldResource,
            id
            ].join('/')
        });
    };

    handleDelete = (id) => {
        this.props.onDelete(
            this.props.path.split('/'),
            id
        );
    };

    handleUpdateOrder = ({data}) => {
        this.handleChange(this.props.fieldsKey, data);
    };
    
    handleAppend = (type, after) => {
        this.props.onCreate(
            this.props.fieldResource,
            this.props.path.split('/'),
            {type},
            after,
            false
        )
    };

    handleDropItem = (drop) => {
        console.log('handleDropItem', drop);
        this.props.onDrop(
            drop,
            this.props.fieldResource,
            this.props.path.split('/')
        );
        return false;
    };

    handleDropList = (drop) => {
        console.log('handleDropList', drop);
        let item = Object.values(drop.copy)[0];
        if (item.origin === 'schema') {
            let source = item.id;
            this.props.onCreate(
                this.props.fieldResource,
                this.props.path.split('/'),
                {type: 'field', source}
            )
        }
    };

    handleCreateChild = (parent, type) => {
        this.props.onCreate(
            this.props.fieldResource,
            [this.props.fieldResource, parent, this.props.fieldsKey],
            {type}
        )
    };

    render() {
        const { fields, editable } = this.props;
        const level = this.props.level || 0;
        const hasFields = fields && fields.length;

        return (<React.Fragment><List
            id={this.props.data.id}
            onChangeOrder={this.handleUpdateOrder}
            onDrop={!hasFields && editable ? this.handleDropList : null}
            onDropItem={this.handleDropItem}
            data={fields}
            variant={editable ? 'sortable' : 'list'}
            editable={this.props.editable}
            level={level}
            renderList={(props)=>props.connectDropTarget(
                <div className="fields-list">
                    {hasFields || !editable
                        ? props.items
                        : <FieldTypePlaceholder />}
                </div>
            )}
            renderItem={(props)=><ViewField
                {...props}
                editable={this.props.editable}
                onEdit={this.handleEdit}
                onAppend={this.handleAppend}
                onCreateChild={this.handleCreateChild}
                onDelete={this.handleDelete}
                resource={this.props.resource}
                id={this.props.id}
                fieldResource={this.props.fieldResource}
                fieldId={props.id}
            />}
        /></React.Fragment>);
    }

}

const ConnectedFieldset = connect(mapState, mapDispatch)(Fieldset);

ConnectedFieldset.propTypes = {
  fieldsetResource: PropTypes.string.isRequired,
  fieldsetId: PropTypes.string.isRequired  
};

export default ConnectedFieldset;