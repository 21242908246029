import React from 'react';

import { red, amber, lightGreen } from '@material-ui/core/colors';
import moment from "moment";
import Icon from '@material-ui/core/Icon';

const statuses = {
    valid: {
        color: lightGreen[500],
        icon: 'https'
    },
    expires_soon: {
        color: amber[500],
        icon: 'warning'
    },
    expired: {
        color: red[500],
        icon: 'no_encryption'
    },
    'no-certificate': {
        color: red[500],
        icon: 'error'
    }
};

const calculateStatus = (data) => {
    let statusId = 'valid';
    const now = moment().format('X') * 1;
    const validTo = data.certificate_valid_to * 1;
    if (!validTo) return statuses['no-certificate'];
    const warning = moment().add('days', 5).format('X')*1;
    if (validTo < warning) {
        statusId = 'expires_soon';
    }
    if (validTo <= now) {
        statusId = 'expired'
    }
    return statuses[statusId];
};

export default function DomainCert(props) {
    const value = props.data.certificate_valid_to;
    if (!props.data.secure_free) return null;
    const validTo = value ? moment(value * 1000) : false;
    const status = calculateStatus(props.data);
    const icon = <Icon fontSize="inherit" style={{ color: status.color }}>
        {status.icon}
    </Icon>;
    return (<div style={{ display: 'flex', alignItems: 'center' }}>
        {icon}
        <span style={{
            opacity: 0.6,
            fontSize: "0.8rem",
            paddingLeft: '0.4rem'
        }}>
                {validTo ? validTo.fromNow() : 'n/a'}
            </span>
    </div>);
}