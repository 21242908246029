import React from 'react';
import PropTypes from 'prop-types';

export default class SideSheet extends React.PureComponent {

    render() {
        const size = this.props.size || 'md';
        let className = "side-sheet";
        className += " side-sheet--" + size;
        return (<div className={className}>
            {this.props.children}
        </div>);
    }

}

SideSheet.propTypes = {};