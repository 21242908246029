import React, {Component} from 'react';
import {connect} from 'react-redux';

import FormFieldset from 'containers/FormFieldset';

const mapStateToProps = (store, props) => {
    return {
        schemaPath: props.schemaPath + '/' + props.id
    }
};

const mapDispatchToProps = {};

class TabField extends Component {

    render() {
        return (<div>
            <h4>{this.props.id}</h4>
            <FormFieldset
                {...this.props}
                path={this.props.parentPath}
            />
        </div>);
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(TabField);