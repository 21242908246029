import {selectResource,selectList,selectAllResourcesIndexed} from 'state/selectors/resources';

const selectFloors = (store, props) => {
    const filter = store.filter['apartments.flats'];
    if (!filter) return [];
    const building = selectResource(store, 'apartments.buildings', filter.buildings);
    return building.floors || [];
};

const selectBuildings = (store, props) => {
    const filter = store.filter['apartments.flats'];
    const buildings = selectAllResourcesIndexed(store, 'apartments.buildings');
    if (!filter) return Object.keys(buildings);
    const investmentId = filter.investment;
    if (!investmentId) return Object.keys(buildings);
    const investment = selectResource(store, 'apartments.investments', investmentId);
    const builidingsIds = Object.keys(buildings);
    return builidingsIds.filter(id=>buildings[id].investment===investment.id);
};

export default {
    'apartments.flats': {
        filters_config: {
            search: {
                type: 'text-search'
            },
            status: {
                label: 'Status',
                type: 'categories',
                source: 'schema:data.flat-status'
            },
            type: {
                label: 'Typ',
                type: 'categories',
                source: 'schema:data.flat-type',
                wrap: true
            },
            investment: {
                label: 'Investycja',
                type: 'categories',
                source: 'api:investments',
                valueKey: 'code_part',
                labelKey: 'code_part',
                resource: 'apartments.investments',
                link: ['buildings', 'floors']
            },
            buildings: {
                label: 'Budynek',
                type: 'categories',
                source: 'api:buildings',
                valueKey: 'code_part',
                labelKey: 'code_part',
                resource: 'apartments.buildings',
                selector: selectBuildings,
                link: ['floors'],
                wrap: true
            },
            floors: {
                label: 'Piętro',
                type: 'categories',
                source: 'api:floors',
                resource: 'apartments.floors',
                selector: selectFloors,
                wrap: true
            }
        }
    }
}