import React from 'react';
import {connect} from 'react-redux';

import ListWrapper from 'components/list/ListWrapper';
import ResourceList from "../../containers/ResourceList";
import NestedList from 'containers/NestedList';
import ResourceListItem from "../../components/list/ResourceListItem";
import RoutePrimary from "../../containers/list/RoutePrimary";
import RouteRelated from 'containers/list/RouteRelated';
import Static from 'containers/list/Static';
import ResourceForm from "../../containers/ResourceForm";
import Dev from 'ide/sidepanel/Dev';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Keymap from 'containers/keys/Keymap';

import { types } from './dev/index';

const mapStateToProps = (store, props) => {
    return {
        section: store.context.sc || store.context.relatedId,
        dev: store.context.dev
    }
};

class Main extends React.PureComponent {

    renderList = (ListComponent, props) => {
        return (<ListWrapper
                    primary
                    isPending={props.isPending}
                    title={props.list}
                >
                <ListComponent
                    {...props}
                    yieldData={true}
                    itemComponent={ResourceListItem}
                />
            </ListWrapper>);
    };

    renderPrimaryList = (props) => {
        return (<RoutePrimary
            primaryAction="ide"
            secondaryAction="sections"
            actions="ide"
            render={(props)=>this.renderList(ResourceList, props)}
        />);
    };

    renderRelatedList = (props) => {
        return (<RouteRelated
            primaryAction="edit"
            relation="sections"
            actions="cms.sections"
            render={(props)=>this.renderList(NestedList, props)}
        />);
    };

    renderStaticList = () => {
        const view = this.props.side.replace('cms.', '');
        const resource = this.props.side;
        const list = resource;
        return (<Static
            context={{
                app: 'ide',
                view: view
            }}
            resource={resource}
            list={list}
            actions="ide"
            primaryAction="ide"
            secondaryAction="sections"
            render={(props)=>this.renderList(ResourceList, props)}
        />)
    };

    renderForm = (type, id) => {
        return (<ResourceForm
            type={type}
            storeId="drafts"
            id={id}
        />);
    };

    renderDevTools = () => {
        return (<Dev />);
    };

    renderContent = () => {
        const { tab, dev, id, view, section, relation, relatedId } = this.props;

        if (dev && (section || dev !== 'components')) {
            let DevComponent = types[dev] || types.components;
            return <div className="sidepanel-content">
                <DevComponent />
            </div>;
        }

        switch (tab) {
            case 'edit-page':
                return this.renderForm('cms.'+view, id);

            case 'edit-section':
                return this.renderForm('cms.'+relation, relatedId);

            case 'pages':
                return this.props.side && this.props.side !== 'cms.' + this.props.view
                    ? this.renderStaticList(this.props.side)
                    : this.renderPrimaryList();

            case 'sections':
                return this.renderRelatedList();
            default:
                return null;
        }
    };



    render() {
        const { setUiFlag, tab, dev, zoomOut, hideOverlays, sidebarUndock } = this.props;

        return (<Keymap id="dev" className="dev-tools-inner">
            <div className="sidepanel-nav">
                { tab === 'edit-page' ? (
                    <IconButton
                        onClick={()=>this.props.onChangeTab('pages')}
                        color="inherit"
                    >
                        <Icon>arrow_back</Icon>
                    </IconButton>
                ) : null }
                { tab === 'sections' ? (
                    <IconButton
                        onClick={()=>this.props.onChangeTab('pages')}
                        color="inherit"
                    >
                        <Icon>arrow_back</Icon>
                    </IconButton>
                ) : null }
                { tab === 'edit-section' ? (
                    <IconButton
                        onClick={()=>this.props.onChangeTab('sections')}
                        color="inherit"
                    >
                        <Icon>arrow_back</Icon>
                    </IconButton>
                ) : null }
                <IconButton
                    onClick={()=>this.props.query({dev:null})}
                    color={!dev ? 'primary' : 'inherit'}
                >
                    <Icon>view_list</Icon>
                </IconButton>
                <Dev />
                <div style={{ flex: 1 }} />
                <IconButton onClick={()=>setUiFlag('ideHideOverlays',!hideOverlays)}>
                    <Icon>{ hideOverlays ? 'layers_clear' : 'layers'}</Icon>
                </IconButton>
                <IconButton onClick={()=>setUiFlag('ideSidebarUndock',!sidebarUndock)}>
                    <Icon>{ sidebarUndock ? 'keyboard_tab' : 'keyboard_tab'}</Icon>
                </IconButton>
                <IconButton onClick={this.props.onToggleZoom}>
                    <Icon>{ zoomOut ? 'zoom_in' : 'zoom_out'}</Icon>
                </IconButton>
            </div>
            {this.renderContent()}
        </Keymap>)
    }

}

export default connect(
    mapStateToProps
)(Main);