import React from 'react';
import {connect} from 'react-redux';

import TextField from 'components/fields/text/TextField';
import Checkbox from 'components/fields/checkbox/CheckboxField';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save'

const mapStateToProps = (store, props) => {
    return {
        ns: props.prev.split('.')[0]
    }
};

const mapDispatchToProps = {};

class CreateStyle extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            id: props.ns + '.',
            assign: false,
            clone: false
        }
    }

    handleChange = (id, value) => {
        let update = {};
        update[id] = value;
        this.setState(update);
    };

    handleSubmit = () => {
        this.props.onClose();
        this.props.onSubmit(
            this.state.id,
            this.state.assign,
            this.state.clone
        );
    };

    render() {
        return (<div className="popover-form">
            <TextField
                id="id"
                label="Style ID"
                value={this.state.id}
                onChange={this.handleChange}
                autoFocus={true}
            />
            <Checkbox
                id="assign"
                label="Assign to current element"
                value={this.state.assign}
                onChange={this.handleChange}
            />
            <Checkbox
                id="clone"
                label="Clone current style"
                value={this.state.clone}
                onChange={this.handleChange}
            />
            <div>
                <Button
                    variant="contained"
                    size="small"
                    fullWidth={true}
                    color="secondary"
                    onClick={this.handleSubmit}
                >
                    Zatwierdź
                    <SaveIcon style={{ marginLeft: '0.4rem' }}/>
                </Button>
            </div>
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateStyle);