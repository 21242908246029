import { query } from 'state/actions/route';

export default {
    keyMap: {
        list: "ctrl+1",
        components: "ctrl+2",
        styles: "ctrl+3",
        preset: "ctrl+4"
    },
    handlers: {
        list: () => query({dev: null}),
        components: () => query({dev: 'components'}),
        styles: () => query({dev: 'styles'}),
        preset: () => query({dev: 'preset'})
    },
    global: true
}