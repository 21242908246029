import React from 'react';
import {connect} from 'react-redux';
//import PropTypes from 'prop-types';

import ResourceActions from 'containers/ResourceActions';
import FilePreview from 'components/media/FilePreview';
import IconMenu from 'components/IconMenu';
import Preloader from "components/Preloader";

import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

const styleAbsolute = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
};

const styleActions = {
    ...styleAbsolute,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end'
};

const styleOuter = {
    width: '100%',
    height: '120px',
    position: 'relative'
};

const devices = {
    0: 'wszystkie',
    1: 'Desktop',
    2: 'Tablet+desktop',
    3: 'Mobile',
    4: 'Tablet',
    5: 'Telefon'
};

const generateContextMenu = (media, actions) => {
    let type = media.type;
    let menu = [];
    if (type === 'google_pano' || type === 'hosted_pano') {
        menu.push({
            key: 'embed',
            caption: 'Osadź panoramę',
            active: media.embed,
            onClick: () => actions.embed()
        });
    }
    menu.push({
        key: 'devices',
        caption: 'Urządzenia',
        subMenuItems: Object.keys(devices).map((id) => {
            let value = media.target_device || 0;
            return {
                key: id,
                caption: devices[id],
                active: value === id*1,
                onClick: () => actions.device(id)
            }
        })
    });
    menu.push({
        key: 'delete',
        caption: 'Usuń',
        onClick: actions.remove
    });
    return menu;
};

const mapState = (store, props) => {
    return {
        progress: store.ui.progress[props.id]
    };
};

const mapDispatch = {};

class Media extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: null
        };

        this.actions = {
            embed: this.handleToggleEmbed,
            device: this.handleToggleDevice,
            remove: this.handleDelete
        }
    };

    handleToggleEmbed = (value) => {
        let update = {};
        update.embed = !this.props.data.embed;
        this.props.onChange(this.props.id, update);
    };

    handleToggleDevice = (value) => {
        let update = {};
        update.target_device = value*1;
        this.props.onChange(this.props.id, update);
    };

    handleOpenContextMenu = (event) => {
        event.preventDefault();
        this.setState({open: true, anchorEl: event.target});
    };

    handleCloseContextMenu = () => {
        if (this.props.onClose) this.props.onClose();
        this.setState({open: false});
    };

    handleDelete = () => {
        this.props.onRequestAction('detach', this.props.id);
    };

    contextMenuItems = () => {
        return generateContextMenu(this.props.data, this.actions);
    };

    render() {
        const { onClick, data, readOnly, onRequestAction } = this.props;
        const type = data.type;
        return (<div
            className="media-preview"
            style={styleOuter}
            onContextMenu={this.handleOpenContextMenu}
            onClick={readOnly ? onClick : null}
        >
            <Preloader visible={this.props.isPending} progress={this.props.progress} />
            <FilePreview
                style={styleAbsolute}
                file={data.file}
                type={data.type}
            />
            { !readOnly ? <div className="media-preview__actions" style={styleActions}>
                <IconMenu
                    items={this.contextMenuItems()}
                    {...this.state}
                    onClose={this.handleCloseContextMenu}
                />
                { onRequestAction ? <IconButton color="inherit" onClick={this.handleDelete}>
                    <Icon>clear</Icon>
                </IconButton> : null }
            </div> : null }
        </div>);
    }

}

Media.propTypes = {};

export default connect(mapState,mapDispatch)(Media);