import React from 'react';
import AppBar from 'containers/AppBar';

import Snackbar from 'containers/Snackbar';
import Confirm from 'containers/dialogs/Confirm';
import Prompt from 'containers/dialogs/Prompt';
import CmsDialogs from 'views/cms/Dialogs';
import ConsoleDialogs from 'views/console/Dialogs';
import ApartmentsDialogs from 'views/apartments/Dialogs';
import Dialogs from 'views/Dialogs';
import Keymap from 'containers/keys/Keymap';
import DragLayer from 'components/DragLayer';
import Notifications from 'containers/Notifications';

export default class App extends React.PureComponent {

    render() {
        const { app } = this.props;
        return (<Keymap
            active
            global
            id="global"
            className="app"
        >
                <AppBar />
                {this.props.children}
                <Confirm />
                <Prompt />
                <Snackbar/>
                <Notifications />
                <DragLayer />
                <Dialogs />
                { app === 'cms' ? <CmsDialogs /> : null }
                { app === 'db' ? <CmsDialogs /> : null }
                { app === 'console' ? <ConsoleDialogs /> : null }
                { app === 'apartments' ? <ApartmentsDialogs /> : null }
        </Keymap>);
    }

}