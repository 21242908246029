import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';

import DragLayer from 'components/DragLayer';

import List from 'list/List';

const defaultItemStyle = {
    transition: 'opacity .2s'
};

const outerItemStyle = {
    transition: 'transform .2s, opacity .2s'
};

export class ExampleListItem extends React.PureComponent {
    render() {
        const { style, isPlaceholder, connectDnd, id } = this.props;
        let itemStyle = {...style, ...outerItemStyle};
        let innerStyle = {...defaultItemStyle};
        if (isPlaceholder) {
            itemStyle.transform = 'scale(0.8)';
            itemStyle.backgroundColor = 'rgba(0,0,0,0.05)';
            itemStyle.borderRadius = '3px';
            innerStyle.opacity = 0;
        }
        const item = (<div style={itemStyle}>
            <div style={innerStyle}>
                {this.props.children}
            </div>
        </div>);
        if (connectDnd) return connectDnd(item);
        return item;
    }
}

ExampleListItem.propTypes = {
    id: PropTypes.string.isRequired
};

class ExampleList extends React.PureComponent {
    render() {
        const { id, items, connectDropTarget } = this.props;
        return <div>
            <h1>{id}</h1>
            {connectDropTarget(<div>{items}</div>)}
        </div>;
    }
}

const exampleDataA = [
    uuid(),
    uuid(),
    uuid(),
    uuid(),
    uuid(),
    uuid()
];

const exampleDataB = [];

for (let i = 0; i < 100; i++) {
    exampleDataB.push(uuid());
}

export default class Playground extends React.PureComponent {

    render() {
        return (<div style={{ display: 'flex' }}>
            <div>
                <List
                    id="list-a"
                    resource="default-type"
                    data={exampleDataA}
                    onChangeOrder={(result)=>console.log(result)}
                    onDrop={(result)=>console.log(result)}
                    renderItem={(props)=><ExampleListItem {...props} />}
                >
                </List>
            </div>
            <div>
                <List
                    id="list-b"
                    resource="default-type"
                    data={exampleDataA}
                    variant="sortable"
                    onChangeOrder={(result)=>console.log(result)}
                    onDrop={(result)=>console.log(result)}
                    renderList={props=><ExampleList {...props} />}
                    renderItem={(props)=><ExampleListItem {...props} />}
                >
                </List>
            </div>
            <div>
                <List
                    id="list-c"
                    resource="default-type"
                    data={exampleDataB}
                    variant="virtualized"
                    onChangeOrder={(result)=>console.log(result)}
                    onDrop={(result)=>console.log(result)}
                    renderList={props=><ExampleList {...props} />}
                    renderItem={props=><ExampleListItem {...props} />}
                />
            </div>
            <DragLayer />
        </div>);
    }

}

Playground.propTypes = {};