import React from 'react';
import {connect} from 'react-redux';
import {selectResource} from "../../../state/selectors/resources";
import {RenderField} from "../../Field";

const mapStateToProps = (store, props) => {
    const type = props.valueType
        ? selectResource(store, 'db.enum-values', props.valueType).title
        : null;

    return {
        valueTypeName: type || 'default'
    }
};

const mapDispatchToProps = {};

class ConcernValue extends React.PureComponent {

    render() {
        const { valueTypeName } = this.props;
        return <RenderField
            {...this.props}
            variant={valueTypeName === 'accept' ? 'checker' : 'answer'}
        />;
        //return (<div>ConcernValue {valueTypeName}</div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConcernValue);