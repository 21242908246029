import React from 'react';

import SelectMenu from 'components/fields/select/SelectMenu';

export default class Select extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: null
        }
    }

    handleChange = (value) => {
        this.props.onChange([value]);
    };

    handleShow = (evt) => {
        this.setState({open: true, anchorEl: evt.currentTarget});
    };

    handleClose = (evt) => {
        this.setState({open: false});
    };

    render() {
        const { options, value } = this.props;
        let selectClass = 'se-class se-class--select';
        if (!value || !value.length) selectClass += ' se-class--empty';
        return (<div className="se-classes">
            <SelectMenu
                value={value}
                options={options}
                onSelect={this.handleChange}
                trigger={(props)=><div className={selectClass} {...props}>
                    {value && value.length
                        ? value
                        : <span className="empty-string">wybierz</span>}
                </div>}
            />
        </div>);
    }

}