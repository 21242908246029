import React from 'react';
import PropTypes from 'prop-types';

import MuiTableRow from '@material-ui/core/TableRow';
import MuiTableCell from '@material-ui/core/TableCell';

import DragHandle from 'components/list/DragHandle';

import IconButton from 'components/TooltipIconButton';
import Icon from '@material-ui/core/Icon';

export default class TableRow extends React.Component {

    static ownDragHandle = true;

    render() {
        const { data, isDragging, connectDnd, onClick } = this.props;
        let style = {
            opacity: isDragging ? 0 : 1
        };
        return (<MuiTableRow style={style}>
            {connectDnd ?
                <MuiTableCell padding="checkbox">
                    <DragHandle connectDnd={connectDnd} />
                </MuiTableCell> : null}
            
            <MuiTableCell>{this.props.primaryText}</MuiTableCell>
            <MuiTableCell>{this.props.secondaryText}</MuiTableCell>
            <MuiTableCell padding="checkbox">
                <IconButton icon="edit" onClick={onClick}/>
            </MuiTableCell>
        </MuiTableRow>);
    }

}

TableRow.propTypes = {};