import React from 'react';
import {connect} from 'react-redux';
import {consoleRefreshWebsite} from "state/actions/console";
import Button from "components/Button";

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {
    onRefresh: consoleRefreshWebsite
};

class ReloadWebsiteCache extends React.Component {

    handleClick = () => {
        this.props.onRefresh(this.props.id);
    }

    render() {
        return (<Button
            onClick={this.handleClick}
            color={"default"}
            size={"sm"}
            icon={"cached"}
        >
            Cache
        </Button>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReloadWebsiteCache);