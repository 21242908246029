import React from 'react';
//import PropTypes from 'prop-types';

import FontPreview from 'components/fields/theme/FontPicker/Font';

export default class Font extends React.PureComponent {

    render() {
        let source = this.props.data.source || null;
        let family = this.props.value;
        return (<FontPreview
            family={family}
            source={source}
            size="18px"
        >{this.props.value}</FontPreview>);
    }

}

Font.propTypes = {};