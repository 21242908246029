import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {push,query} from 'state/actions/route';
import {listCreate,listPrimaryAction} from 'state/actions';
import {uiSetView} from 'state/actions/ui';
import {selectViewId} from 'state/selectors/schema';

import Resource from './Resource';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import AppContext from 'containers/AppContext';
import App from "views/layouts/App";
import Access from 'components/Access';
import ResourcesNav from 'components/layout/SideNav';
import Form from 'v2/Form';
import MediaPreview from 'v2/dialogs/MediaPreview';

import RenderFormTabs from 'v2/FormTabs';

import RoutePrimary from 'containers/list/RoutePrimary';

import MainListingForm from 'views/layouts/MainListingForm';
import ViewSelect from 'v2/ViewSelect';
import ResourceList from 'v2/ResourceList';

import FormView from 'v2/views/FormView';
import PostsView from 'v2/views/PostsView';
import FormBuilder from 'v2/form-builder/FormBuilder';
import ListActions from "../containers/dialogs/ListActions";

const viewTypes = {
    form: FormView,
    posts: PostsView,
    'form-builder': FormBuilder
};

const mapStateToProps = (store, props) => {
    let resourceName = store.context.view;
    let id = store.context.id;
    return {
        resourceName,
        resource: resourceName
            ? [store.context.app,resourceName].join('.')
            : null,
        id: store.context.id,
        context: store.context,
        lang: store.context.lang,
        app: store.context.app,
        watch: ['app', 'lang', 'project', 'view'],
        listViewId: selectViewId(store, 'list', resourceName, id),
        formViewId: selectViewId(store, 'form', resourceName, id),
        isFormPending: store.ui.pending[store.context.id]
    }
};

const mapDispatchToProps = {
    onShowItem: push,
    query,
    listCreate,
    onChangeView: uiSetView,
    listPrimaryAction
};

class Main extends React.PureComponent {

    handleShowItem = (id) => {
        this.props.onShowItem('/:app/:lang/:project/:view/:id',{
            ...this.props.context,
            id
        });
    };

    handleCreate = () => {
        if (this.props.resource !== 'db.form-views') {
            this.props.listPrimaryAction(
                this.props.resource,
                this.props.resource
            );
            return;
        }
        this.props.listCreate(
            this.props.resource,
            this.props.resource
        );
    };

    render() {
        const { id, resource, app, lang, watch } = this.props;

        let viewType = null;

        if (id) {
            viewType = resource && resource === 'db.nodes' ? 'posts' : 'form';
        }

        if (resource === 'db.form-views') viewType = 'form-builder';

        const View = viewType ? viewTypes[viewType] : null;

        const legacyView = [
            'db.list-views',
            'db.types',
            'db.enum-definitions',
            'db.filters',
            'db.resource-bindings',
            'db.integrations',
            'db.tags'
        ];

        const showList = viewType !== 'form-builder';

        if (resource && legacyView.indexOf(resource) > -1) return (<MainListingForm/>);
        return (<AppContext key={app} app={app} watch={watch}>
            <App>
                <div className={classNames({
                    'app-columns': true,
                    'filters-aside': true,
                    'form-view': viewType === 'form'
                })}>
                    <div className="modules-sidebar">
                        <ResourcesNav />
                    </div>
                    { showList ? <div className="list-area primary">
                        <Access policy={['resource',resource,'create'].join(':')}>
                            <div className="resource-list-fab">
                                <Fab
                                    color="secondary"
                                    aria-label="Add"
                                    onClick={this.handleCreate}
                                >
                                    <AddIcon />
                                </Fab>
                                <ListActions
                                    id={id}
                                    resource={resource}
                                />
                            </div>
                        </Access>
                        { resource ? <RoutePrimary render={(routeProps)=>
                            <ResourceList
                                list={routeProps.resource}
                                resource={routeProps.resource}
                                onClick={this.handleShowItem}
                                onChangeOrder={routeProps.onChangeOrder} />}
                            /> : null }
                    </div> : null}
                    {viewType?<View
                        onCreate={this.handleCreate}
                        resource={resource}
                        id={id}
                        isPending={this.props.isFormPending}
                    />:null}
                </div>
            </App>
            <MediaPreview />
        </AppContext>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Main);