import {connect} from 'react-redux';

import { feedbackHide } from 'state/actions/ui';

import Snackbar from 'components/layout/Snackbar';

const nullObject = {};

const mapStateToProps = (store, props) => {
    return {
        notification: store.ui.snackbar[0] || nullObject,
        isOpen: store.ui.snackbarVisible
    }
};

const mapDispatchToProps = {
    onClose: feedbackHide
};

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar);