import React from 'react';

import Text from './Text';
import Select from './Select';
import Checkbox from './Checkbox';
import Collection from './Collection';
import Rich from './Rich';
import Ref from './Ref';
import Date from './Date';
import Address from './Address';
import Avatar from './Avatar';
import UserInfo from './UserInfo';
import AuditSurvey from './../AuditSurvey';
import Datetime from "./Datetime";

const types = {
    text: Text,
    select: Select,
    checkbox: Checkbox,
    collection: Collection,
    rich: Rich,
    ref: Ref,
    date: Date,
    datetime: Datetime,
    address: Address,
    avatar: Avatar,
    user: UserInfo,
    'audit-survey': AuditSurvey
};

const nameTypes = {
    created_at: 'datetime',
    created_by: 'user',
    completed_at: 'datetime',
    avatar: 'avatar'
};

export function makeFieldProps(props) {
    const {value,schema,onChange} = props;
    let fieldName = props.fieldName || schema.slug;
    const label = schema.label || schema.slug;
    let type = nameTypes[fieldName] || schema.type;
    return {
        ...schema.properties,
        ...props,
        id: schema.slug,
        label,
        onChange,
        value,
        readOnly: props.readOnly,
        fieldType: type
    };
}

export default function render(props) {
    const fieldProps = makeFieldProps(props);
    const type = fieldProps.fieldType;
    const value = fieldProps.value;
    const FieldComponent = types[type];
    if (!FieldComponent) {
        return (typeof value === 'object' && value)
            ? JSON.stringify(value)
            : value || '';
    }
    return <FieldComponent {...fieldProps} />;
}