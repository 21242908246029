import { resourcePathDetach, draftsPathCreate } from './index';

export const sectionDetach = (resource, id, list, args, context) => {
    return resourcePathDetach([
        ['cms',context.view].join('.'),
        context.id,
        context.relation
    ], id);
};

export const sectionCreateAfter = (resource, id, list, args, context) => {
    return draftsPathCreate(resource, [
        ['cms',context.view].join('.'),
        context.id,
        context.relation
    ], {}, id);
};

export const sectionAttach = (resource, id, list, context) => {
    return {
        type: "UI.DIALOG.SHOW"
    }
};

export const extractUrl = (resource, id, field) => {
    return {
        type: 'APP.EXTRACT_URL',
        resource,
        id,
        field
    }
};

export const fetchUrlInfo = (url) => {
    return {
        type: 'APP.FETCH_URL_INFO',
        url
    }
};