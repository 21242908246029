import React from 'react';
//import PropTypes from 'prop-types';

import ListItem from 'components/list/ListItem';
import Icon from '@material-ui/core/Icon';

const placeholderStyle = {
    backgroundColor: 'rgba(0,0,0,0.05)',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
    color: 'rgba(0,0,0,0.5)'
};

export default class PlaceholderSection extends React.PureComponent {

    render() {
        const { placeholder_id } = this.props.data;
        return (<ListItem
            {...this.props}
            avatar="none"
            primaryText={<div style={placeholderStyle}>
                <Icon>subdirectory_arrow_right</Icon>
                <div>{placeholder_id || 'Treść podstrony'}</div>
            </div>}
        />);
    }

}

PlaceholderSection.propTypes = {};