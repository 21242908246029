import React from 'react';
import {connect} from 'react-redux';

import {
    listReorder,
    //listCreate,
    listSaveOrder,
    resourceAction,
    //resourceSave
} from 'state/actions';

const mapDispatchToProps = {
    reorder: listReorder,
    listSaveOrder,
    resourceAction,
};

const mapStateToProps = (store, props) => {
    const list = props.list; //props.context.view ? ['cms',props.context.view].join('.') : null;
    const context = props.context || store.context;
    return {
        project: store.context.project,
        view: context.view,
        id: context.id,
        list,
        resource: props.resource,
        isPending: store.ui.pending[list],
        primaryAction: props.primaryAction || 'primary',
        selectedItem: context.id
    }
};

class Static extends React.PureComponent {

    constructor(props) {
        super(props);

        this.itemProps = {
            onChangeOrder: this.handleReorder,
            onClick: this.handleClick,
            onActionRequest: this.handleAction,
            onDrop: this.handleSaveOrder
        };
    }

    handleClick = (id) => {
        this.props.resourceAction(
            this.props.primaryAction,
            this.props.list,
            id,
            this.props.context
        );
    };

    handleReorder = (drag, hover) => {
        this.props.reorder(
            this.props.list,
            drag,
            hover
        );
    };

    handleSaveOrder = () => {
        this.props.listSaveOrder(
            this.props.list,
            this.props.list
        );
    };

    handleAction = (...args) => {
        console.log('handleAction', args, this.props.context);
        this.props.resourceAction(...args);
    };

    render() {
        return this.props.render({
            ...this.props,
            itemProps: this.itemProps
        })
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(Static);