import React from 'react';
import {connect} from 'react-redux';
import { selectResource } from 'state/selectors/resources';
import SelectMenu from 'components/fields/select/SelectMenu';
import { appChangeLang } from 'state/actions';

import Typography from '@material-ui/core/Typography';
import { availableLanguages } from "state/selectors/app";

const mapStateToProps = (store, props) => {
    return {
        options: availableLanguages(store),
        value: store.context.lang
    }
};

const mapDispatchToProps = {
    onChange: appChangeLang
};

class ProjectLocale extends React.PureComponent {

    handleSelect = (value) => {
        this.props.onChange(value);
    };

    render() {
        const { options, value } = this.props;
        if (!options.length || !value) return null;

        return (<div>
            <SelectMenu
                filters={{}}
                trigger={(props)=><div {...props} className="lang-select">{value}</div>}
                options={options}
                onSelect={this.handleSelect}
            />
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectLocale);