import React from 'react';
import PropTypes from 'prop-types';
import NavPills from 'components/NavPills';
import ListHeading from 'components/list/ListHeading';
import SelectMenu from 'components/fields/select/SelectMenu';
import NavPillButton from 'components/NavPillButton';
import { selectValue } from 'components/list/fields';
import withSelectOptions from 'components/fields/select/SelectOptions';

import Chip from 'components/Chip';
import ChipSelect from 'components/fields/select/ChipSelect';
import {ListItemConnector} from 'components/fields/Value';

class Categories extends React.PureComponent {

    labels = ['label', 'name', 'title', 'description', 'code_part', 'id'];

    handleChange = (value) => {
        const { id } = this.props;
        console.log(id, value);
        this.props.onSetFilter(this.props.id, value);
    };

    handleClear = () => {
        this.props.onSetFilter(this.props.id, null);
    };

    render() {
        const { optionsIndex, value, label, wrap, resource } = this.props;
        let options = optionsIndex ? Object.values(optionsIndex) : [];
        if (options.length < 1) return null;
        if (wrap) {
            let labelText = value ? selectValue(optionsIndex[value], this.labels) : label;
            return <SelectMenu
                onSelect={this.handleChange}
                trigger={(props)=>
                    <ListItemConnector
                        resource={resource}
                        id={value}
                        field="primaryText"
                    >{({value, avatar, data})=><Chip
                        avatar={avatar ? React.createElement(avatar, {data}) : null}
                        label={value ? value : label}
                        onDelete={value ? this.handleClear : null}
                        variant={!value ? "outlined" : "default"}
                        fullWidth={true}
                        {...props}
                    />}</ListItemConnector>}
                options={options}
            />;
        }
        return (<div>
            <ListHeading title={label} />
            { !wrap ? <ChipSelect
                value={value}
                options={options}
                resource={resource}
                onChange={this.handleChange}
                onClearSelection={this.handleClear}
                layout="vertical"
            /> : null }
            { false && !wrap ? <NavPills
                variant="vertical"
                onClick={this.handleChange}
                active={value}
                items={options}
            /> : null }
        </div>);
    }

}

export default withSelectOptions()(Categories);

Categories.propTypes = {};