import uuid from 'uuid/v4';

const defaults = {
    'cms.pages': {
        type: 'page'
    },
    'cms.sections': {
        type: 'content'
    }
};

const resolveDefault = (resource) => {
    if (defaults[resource]) return defaults[resource];
    if (resource.indexOf('cms.entries') === 0) {
        return {type: resource.split('.')[2]}
    }
    return {};
};

const createNewResource = (resource, data = {}) => {
    let defaultData = resolveDefault(resource);
    return {
        id: data.id ? data.id : uuid(),
        active: true,
        draft: true,
        ...defaultData,
        ...data
    }
};

export default createNewResource;