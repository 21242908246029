import {
    call,
    takeEvery,
    select,
    put
} from 'redux-saga/effects';

import {selectResource} from 'state/selectors/resources';
import {draftsPathAttach, draftsPathDetach, draftsSubmit, listFetch} from 'state/actions';

function* handleSubscribe(action) {
    try {
        const { id, target } = action;
        let path = ['db.nodes', target, 'subscribe'];
        let origin = yield select(store=>selectResource(store, 'db.nodes', target));
        if (origin.subscribe && origin.subscribe.indexOf(id) > -1) {
            yield put(draftsPathDetach(path, id));
        } else {
            yield put(draftsPathAttach(path, id));
        }
        yield put(draftsSubmit('db.nodes', target));
        let listId = ['db.nodes', origin.id, 'posts'].join('.');
        //yield put(listFetch('db.posts', listId, listId));
    } catch (e) {

    }
}

export default function*() {
    yield takeEvery('SUBSCRIBE', handleSubscribe);
}