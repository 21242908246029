import {
    call
} from 'redux-saga/effects'

import { request } from 'util/api';
import { createUploader } from 'util/saga-upload';

import resourceMapping from 'config/api';

const defaultMapping = (resource) => {
    if (!resource) return;
    return {
        path: resource.replace(/\./g, '/') + '/:id'
    }
};

const resourceToApi = (resource) => {
    return resourceMapping[resource] || defaultMapping(resource);
};

const prepareRequestArgs = (method = 'get', payload = {}, resource, id, context, options) => {
    let {lang, project} = context;
    let config = resourceToApi(resource);
    let headers = project ?
        {'X-zuu-project-id': project}
        : {};
    if (!config) return;
    let query = {...config.params};
    if (lang) query.lang = lang;
    let path = config.path;
    path = path.replace('/:ctx.id', '/'+context.id);
    path = path.replace(':app', context.app);
    if (path.indexOf(':id') > -1) {
        let useId = id;
        if (useId) {
            path = path.replace(':id', useId);
        } else {
            path = path.replace('/:id', '');
        }
    } else {
        if (id) path = path + '/' + id;
    }
    if (method === 'get') query = {...query, ...payload};

    if (config.path.indexOf('http') === 0) {
        return {
            path,
            method,
            query: {},
            payload: {},
            headers: {},
            options
        }
    }

    return {
        path,
        method,
        query,
        payload,
        headers,
        options
    }
};

function* makeRequest(...args) {
    const a = prepareRequestArgs(...args);
    return yield call(
        request,
        a.path,
        a.method,
        a.query,
        a.payload,
        a.headers,
        a.options
    );
}

export function* get(...args) {
    return yield call(makeRequest, 'get', {}, ...args);
}

export function* getArgs(...args) {
    return yield call(makeRequest, 'get', ...args);
}

export function* post(...args) {
    return yield call(makeRequest, 'post', ...args);
}

export function* put(...args) {
    return yield call(makeRequest, 'put', ...args);
}

export function* remove(...args) {
    return yield call(makeRequest, 'delete', {}, ...args);
}

export function upload(path, data, context) {
    return createUploader((progress)=>{
        const args = prepareRequestArgs('upload', data, path, null, context, {
            onUploadProgress: progress
        });
        console.log(args);
        return request(...Object.values(args));
    });
}