import TextSearch from './TextSearch';
import Categories from './Categories';
import Graph from 'v2/Graph';
import Checkbox from './Checkbox';
import Schedule from './Schedule';

export default {
    'text-search': TextSearch,
    categories: Categories,
    graph: Graph,
    checkbox: Checkbox,
    schedule: Schedule,
    default: TextSearch
};