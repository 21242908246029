import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/TooltipIconButton';

export default class Pagination extends React.PureComponent {

    render() {
        const { current, limit, count, pages_count, onSetPage } = this.props;
        const isFirst = current === 1;
        const isLast = current === pages_count;
        const rangeStart = ((current-1)*limit)+1;
        let rangeEnd = rangeStart+(limit-1);
        if (rangeEnd > count) rangeEnd = count;
        return (<div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flex:1, fontSize: '12px', opacity: 0.4 }}>
                {rangeStart}-{rangeEnd} z {count}
            </div>
            <IconButton
                icon="first_page"
                onClick={()=>onSetPage(1)}
                disabled={isFirst}
            />
            <IconButton
                icon="chevron_left"
                onClick={()=>onSetPage(current-1)}
                disabled={isFirst}
            />
            <IconButton
                icon="chevron_right"
                onClick={()=>onSetPage(current+1)}
                disabled={isLast}
            />
            <IconButton
                icon="last_page"
                onClick={()=>onSetPage(pages_count)}
                disabled={isLast}
            />
        </div>);
    }

}

Pagination.propTypes = {};