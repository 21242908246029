import React from 'react';
import {connect} from 'react-redux';
import {selectViewId} from 'state/selectors/schema';
import {selectResource} from 'state/selectors/resources';

import Dialog from 'components/Dialog';

import {draftsCancel, draftsSubmit} from "state/actions";
import SchemaForm from 'containers/ResourceForm';
import FormView from 'v2/views/FormView';

const embeddedResources = [
    'db.view_fields'
];

const mapStateToProps = (store, props) => {
    const resource = store.context.edit
        ? store.context.edit.split('/')
        : null;
    let resourceName = resource
        ? resource[0].split('.')[1]
        : null;
    let isDbType = store.data['db.types.byName']
        && store.data['db.types.byName'][resourceName];
    let resourceAlias = resource ? resource[0] : null;
    return {
        isEmbeddedType: embeddedResources.indexOf(resourceAlias) > -1,
        isDbType,
        resourceName,
        resource: resource ? resource[0] : null,
        id: resource ? resource[1] : null,
        formViewId: selectViewId(store, 'form', resource[0])
    }
};

const mapDispatchToProps = {
    onCancel: draftsCancel,
    onSubmit: draftsSubmit
};

class ResourceEdit extends React.PureComponent {

    handleClose = () => {
        this.props.onCancel(
            this.props.type,
            this.props.id,
            false
        );
        this.props.onClose();
    };

    handleSubmit = () => {
        const { isEmbeddedType, resource, id } = this.props;
        if (!isEmbeddedType) {
            this.props.onSubmit(resource, id);
        }
        this.props.onClose();
    };

    render() {
        const { open, ...other } = this.props;
        const { resource, id, formViewId, isDbType, isEmbeddedType } = this.props;
        return (<Dialog
            open={open && resource && id}
            onClose={this.handleClose}
            maxWidth="md"
            hideCloseButton={false}
            className={'console-context'}
        >
            { formViewId || isDbType
                ? <FormView
                    resource={resource}
                    id={id}
                    onCancel={this.handleClose}
                />
                : <SchemaForm
                    type={resource}
                    id={id}
                    dismissible={false}
                    onSubmit={this.handleSubmit}
                    fetchPolicy={isEmbeddedType ? 'never' : null}
            /> }
        </Dialog>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResourceEdit);