import React from 'react';
import {connect} from 'react-redux';
import ResourceForm from 'containers/ResourceForm';

import { currentTheme } from 'state/selectors/app';

const mapStateToProps = (store, props) => {
    let theme = currentTheme(store);
    return {
        presetId: theme.preset,
        themeId: theme.id
    }
};

const mapDispatchToProps = {};

class Preset extends React.PureComponent {

    render() {
        return (<ResourceForm
            type="console.presets"
            id={this.props.presetId}
            hideHeader={true}
        />);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Preset);