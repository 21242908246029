import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import MuiStepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import {selectResource} from "state/selectors/resources";
import Fieldset from "v2/form-builder/Fieldset";
import Button from 'components/Button';
import IconButton from 'components/TooltipIconButton';

const mapState = (store, props) => {
    let data = selectResource(
        store,
        props.fieldsetResource,
        props.fieldsetId
    );
    return {
        data,
        tabs: data.view_fields || []
    };
};

const mapDispatch = {};

class StepperLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {step: props.tabs[0]};
    }

    componentDidUpdate() {
        if (!this.state.step && this.props.tabs && this.props.tabs.length) {
            this.setState({step: this.props.tabs[0]});
        }
    }

    handleCreate = () => {
        this.props.onCreate('tab');
    };

    handleStep = step => () => {
        this.setState({step});
    };

    render() {
        const { tabs, editable } = this.props;
        const hasTabs = tabs.length > 0;
        return (<div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ flex: 1 }}>
                    { hasTabs ? <MuiStepper nonLinear activeStep={tabs.indexOf(this.state.step)}>
                        {tabs.map(tab=>(
                            <Step key={tab}>
                                <StepButton onClick={this.handleStep(tab)}>
                                    step
                                </StepButton>
                            </Step>
                        ))}
                    </MuiStepper> : null }
                </div>
                { editable ? <div style={{ marginRight: '1rem' }}>
                    { this.state.step ? <IconButton icon="clear" onClick={this.handleCreate}/> : null }
                    <IconButton icon="add" onClick={this.handleCreate}/>
                </div>: null }
            </div>
            <div style={{ flex: 1, padding: '0 24px 24px 24px', overflow: 'hidden auto' }}>
                <Fieldset
                    {...this.props}
                    fields={hasTabs ? [this.state.step] : null}
                />
            </div>
        </div>);
    }

}

StepperLayout.propTypes = {};

export default connect(mapState, mapDispatch)(StepperLayout);