import React  from "react";

import Text from "./Text";
import Icon from "./icon";

export default class MediaElement extends React.PureComponent {
  render() {
    const { s, className } = this.props;
    return (
      <div
          className={className + " " + s.get("item")}
          {...this.props.events}
      >
        <Icon {...this.props} />
        <div className={"l-area " + s.get("content")}>
          <Text {...this.props} />
        </div>
      </div>
    );
  }
}
