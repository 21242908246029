import React from 'react';
import { connect } from 'react-redux';
import { query } from 'state/actions/route';

import AttachSection from 'containers/dialogs/AttachSection';
import TypeSelectDialog from 'containers/dialogs/TypeSelectDialog';
import MediaPreview from 'v2/dialogs/MediaPreview';

const mapState = (store, props) => {
    return {
        sections: store.context.q === 'attach-section'
    }
};

const mapDispatch = {
    query
};

class Dialogs extends React.PureComponent {

    handleClose = () => {
        this.props.query({q: null});
    };
    
    render() {
        const { sections, media } = this.props;
        return (<React.Fragment>
            { sections ? <AttachSection withTheme={true} wrap="drawer"/> : null }
            <TypeSelectDialog />
            <MediaPreview />
        </React.Fragment>);
    }

}

export default connect(mapState,mapDispatch)(Dialogs);