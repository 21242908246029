import React from 'react';

import { DropTarget } from 'react-dnd';

import {
    collectTarget,
    dropAreaTarget
} from 'util/dnd';

export const droppable = () => {
    return (DecoratedComponent) => {
        const Decorator = class DroppableDecorator extends React.PureComponent {
            componentWillReceiveProps(nextProps) {
                if (this.props.isOver && !nextProps.isOver) {
                    if (this.props.onDragOutside) {
                        this.props.onDragOutside(this.props.overItem);
                    }
                }
            }
            render() {
                return <DecoratedComponent {...this.props} />
            }
        };
        return DropTarget("draggable", dropAreaTarget, collectTarget)(Decorator);
    }
};

class DropArea extends React.PureComponent {

    render() {
        const { connectDropTarget } = this.props;
        const passProps = {...this.props};
        delete passProps.connectDropTarget;
        return connectDropTarget(<div {...passProps} />);
    }

}

export default droppable()(DropArea);