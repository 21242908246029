import React from 'react';
import {connect} from 'react-redux';
import {selectResource} from "state/selectors/resources";
import moment from "moment";
import {currentUser} from "../../state/selectors/app";
import UserAvatar from "../../components/list/fields/UserAvatar";
import Resource from "v2/Resource";
import MessageMedia from "./MessageMedia";

const nullArr = [];

const mapStateToProps = (store, props) => {
    const data = selectResource(store, props.type, props.id);
    const { content, created_by, created_at, media } = data;
    return {
        content,
        created_by: created_by || currentUser(store).id,
        created_at: created_at ? moment.unix(created_at) : moment(),
        media: media || nullArr
    }
};

const mapDispatchToProps = {};

class Message extends React.Component {

    render() {
        const { type, id, media, content, created_by, created_at } = this.props;
        return (<div className={'message'}>
            <div className={'message__avatar'}>
                <UserAvatar id={created_by} />
            </div>
            <div className={'message__content'}>
                <div className={'message__header'}>
                    <div className={'message__author'}>
                        <Resource resource={'console.users'} id={created_by} fetchPolicy={'yield'}>
                            {(props)=><span>{props.data.name}</span>}
                        </Resource>
                    </div>
                    <div className={'message__time'}>
                        {created_at.format('DD MMM, H:mm')}
                    </div>
                </div>
                <div className={'message__body'}>
                    <div className={'message__text'}>
                        {content}
                    </div>
                    <MessageMedia
                        resource={type}
                        id={id}
                        items={media}
                        readOnly={true}
                    />
                </div>
            </div>
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Message);