export default {
    createWebsite: {
        fields: {
            name: {
                id: 'name',
                type: 'text',
                label: 'Unique identifier'
            },
            theme: {
                id: 'theme',
                type: 'select',
                label: 'Use existing theme',
                properties: {
                    source: 'api:themes'
                }
            },
            parent: {
                id: 'parent',
                type: 'select',
                label: 'Parent theme',
                properties: {
                    source: 'api:themes'
                }
            },
            vcs_repository: {
                id: 'vcs_repository',
                type: 'text',
                label: 'Theme vcs repository'
            },
            "content_source": {
                id: "content_source",
                "label": "Use existing CMS",
                "type": "select",
                "properties": {
                    "source": "api:services?type=cms",
                    "multiple": false
                }
            },
            content_source_clone: {
                id: 'content_source_clone',
                type: 'checkbox',
                label: 'Create new cms initializing content from selected cms'
            },
            "library": {
                id: "library",
                "label": "Library",
                "type": "select",
                "properties": {
                    "source": "api:services?type=cms",
                    "multiple": false
                }
            },
            "storage_id": {
                id: "storage_id",
                type: "text",
                label: "S3 storage ID"
            },
            "color_scheme": {
                id: "color_scheme",
                "label": "Color scheme",
                "type": "colors",
                "properties": {
                    "source": "api:colors",
                    "multiple": false,
                    "nullable": false
                },
                "weight": 8
            },
            "typography": {
                id: "typography",
                "label": "Typography",
                "type": "typography",
                "properties": {
                    "source": "api:typography",
                    "multiple": false,
                    "nullable": false
                },
                "weight": 7
            }
        }
    },
    createDirectory: {
        label: 'Nowy katalog',
        fields: {
            name: {
                id: 'name',
                type: 'text',
                label: 'Nazwa'
            }
        }
    },
    importVideo: {
        label: 'Importuj film',
        description: 'Wklej adres filmu na youtube lub vimeo',
        fields: {
            url: {
                id: 'url',
                type: 'text',
                label: 'URL'
            }
        }
    },
    importPano: {
        label: 'Importuj panoramę',
        description: 'Wklej kod iframe z Google Street View lub adres url',
        fields: {
            url: {
                id: 'url',
                type: 'text',
                label: 'URL'
            }
        }
    }
};