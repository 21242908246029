import React from 'react';

import { icon } from 'util/icon';

export default class ListActions extends React.PureComponent {

    handleAction = (id) => {
        this.props.onClose();
        this.props.onActionRequest(
            id,
            this.props.resource,
            this.props.id,
            this.props.context
        );
    };

    render() {
        const { actions } = this.props;
        return (<div className="list-actions" style={{ padding: '2rem', display: 'flex' }}>
            { Object.keys(actions).map((action) => {
                let actionKey = this.props.resource + '.' + action;
                return (<div className="card-icon" key={action} onClick={()=>this.handleAction(action)}>
                    <div className="card-icon__icon">{icon(actionKey)}</div>
                    <div>{actions[action].label}</div>
                </div>);
            })}
        </div>);
    }

}