import React, { Component } from "react";

import { webfont_icon } from "./icon";
import Popover from 'components/Popover';

class ButtonBadge extends React.PureComponent {
  render() {
    const { data, s } = this.props;
    const { badge_value } = data;
    return <div className={"c-btn__badge Badge " + s.get("button_badge")}>
      {badge_value}
    </div>
  }
}

export default class Button extends React.PureComponent {

  wrapEditable = (button) => {
    const { data } = this.props.data;
    return (<Popover
      trigger={(props) => this.renderButton(props.onClick)}
    >
      edit button
    </Popover>);
  };

  renderButton = (onClick) => {
    const { s, data, className, onFocus } = this.props;
    const { link_label, headline, enable_badge, badge_value } = data;
    let icon = data.icon;

    /*let buttonStyle = s.extend("button", [
      "c-btn",
      "aligned",
      "hover",
      className
    ]);*/

    let buttonStyle = 'c-btn aligned hover ' + className + ' ' + s.get('button');

    let label = label && (typeof link_label === "object")
        ? "[object]"
        : link_label;

    if (!label) {
      label = '???';
      icon = 'warning';
    }

    if (headline) buttonStyle += ' c-btn--headline';

    let badgeAttachment = s.get('button_badge_attachment') || 'inner';

    return (
        <div className={buttonStyle} onClick={onClick}>
        <span>
          {icon ? (
              <span className={"c-btn__icon " + s.get("button_icon")}>
              {webfont_icon(icon)}
            </span>
          ) : null}
          {label ? (
              headline ? (
                  <span className={'c-btn__heading ' + s.get("button_heading")}>
                    <span className={"c-btn__headline " + s.get("button_headline")}>{headline}</span>
                    <span className={"c-btn__label " + s.get("button_label")}>{label}</span>
                  </span>
              ) : (
                  <span className={"c-btn__label " + s.get("button_label")}>{label}</span>
              )
          ) : null}
          { enable_badge && badgeAttachment === 'inner' ? (
              <ButtonBadge s={s} data={data} />
          ) : null }
        </span>
        { enable_badge && badgeAttachment === 'outer' ? (
            <ButtonBadge s={s} data={data} />
        ) : null }
        </div>
    );
  };

  render() {
    const { editable } = this.props;
    if (editable) return this.wrapEditable();
    return this.renderButton();
  }
}
