import React from 'react';
import {connect} from 'react-redux';
import {selectCurrentApp} from 'state/selectors/access';

import CmsDashboard from 'views/cms/Dashboard';
import DbDashboard from 'views/db/Dashboard';

const mapStateToProps = (store, props) => {
    return {
        app: selectCurrentApp(store)
    }
};

const mapDispatchToProps = {};

class Home extends React.Component {

    render() {
        let app = this.props.app;
        if (app === 'apps.cms') return <CmsDashboard />;
        if (app === 'apps.console') return <CmsDashboard />;
        if (app === 'apps.db') return <DbDashboard />;
        return null;
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);