import { createReducer } from 'util/redux/create-reducer';
import { parseQueryString } from 'util/uri';

const parseRouteParams = (state, params, location) => {
    let query = parseQueryString(location.search);
    let newParams = {
        user: state.user,
        ...params,
        ...query
    };
    return newParams;
};

const actions = {
    SYNC_ROUTE_PARAMS: (state, {params, location}) => parseRouteParams(state, params, location),
    'APP.USER': (state, {id}) => ({...state, user: id})
};

const initial = {lang: 'pl'};

export default createReducer(initial, actions);