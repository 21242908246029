import React from 'react';
import PropTypes from 'prop-types';
import RefField from 'v2/fields/Ref';

export default class Ref extends React.PureComponent {

    render() {
        return (<RefField {...this.props} readOnly={true} />);
    }

}

Ref.propTypes = {};