import React from 'react';
import {connect} from 'react-redux';
import {selectSchemaOptions} from 'state/selectors/resources';

const mapStateToProps = (store, props) => {
    const options = selectSchemaOptions(store, 'apartments.data.flat-status');
    return {
        options: Object.values(options),
        value: options[props.data.status]
    }
};

const mapDispatchToProps = {};

class FlatStatus extends React.PureComponent {

    render() {
        const {value} = this.props;
        return (<div className="color-dot" style={{ backgroundColor: value ? value.color : null }}>&nbsp;</div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FlatStatus);