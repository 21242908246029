import React from "react";
import { connect } from "react-redux";
import ResourceActionsMenu from "components/list/ResourceActionsMenu";
import { selectResourceActions } from "state/selectors/schema";

import { resourceAction } from "state/actions";

const mapStateToProps = (store, props) => {
  let actionsDefKey = props.actionsDefKey || props.resource;
  let actions = selectResourceActions(store, props.resource);
  let schemaActions = store.resources[actionsDefKey]
    ? store.resources[actionsDefKey].actions
    : store.resources.default.actions;
  return {
    actions: { ...schemaActions, ...actions },
  };
};

const mapDispatchToProps = {
  resourceAction,
};

class ResourceActions extends React.PureComponent {
  handleAction = (id) => {
    if (this.props.onActionRequest) {
      this.props.onActionRequest(id, this.props.resource, this.props.id);
      return;
    }
    this.props.resourceAction(id, this.props.resource, this.props.id);
  };

  render() {
    return (
      <ResourceActionsMenu
        {...this.props}
        onActionRequest={this.handleAction}
      />
    );
  }
}

const ConnectedResourceActions = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceActions);
export default ConnectedResourceActions;
