import React from 'react';
import {connect} from 'react-redux';
import {selectResource} from "state/selectors/resources";
import {selectFieldSchema, selectTypeId} from 'state/selectors/schema';
import {query} from 'state/actions/route';
import {DndConnector} from "list/dnd";
import Card from "@material-ui/core/Card";

const selectCurrentResource = (store, props) => {
    if (!props.resource) return {};
    let fieldId = store.context.f;
    let type = selectResource(store, 'db.types', props.resource);
    if (!fieldId) return type;
    let schema = selectFieldSchema(store, ['db',type.name].join('.'), null, fieldId);
    if (!schema || !schema.properties) return {};
    return selectResource(store, 'db.types.byName', schema.properties.resource_type.split('.')[1]);
};

const mapStateToProps = (store, props) => {
    const schema = selectCurrentResource(store, props);
    const schemaFields = schema.id
        ? Object.values(schema.fields_index)
        : [];
    return {
        schema,
        schemaFields,
        fieldId: store.context.f
    }
};

const mapDispatchToProps = {
    query
};

class SchemaFields extends React.PureComponent {

    handleResetField = () => {
        this.props.query({f: null});
    };

    render() {
        const { schemaFields, fieldId } = this.props;
        return (<div style={{ flex: 1, overflow: 'hidden auto', padding: '0 1rem' }}>
            { fieldId ? <div onClick={this.handleResetField}>clear</div> : null }
            {schemaFields.map(field=>(
                <DndConnector origin="schema" id={field.uuid} resource="schema-field">
                    {({connectDragHandle})=>connectDragHandle(<div onClick={()=>this.handleCreateField(field.uuid)}>
                        <Card className="schema-field">
                            <div>{field.label}</div>
                            <div style={{ opacity: 0.3 }}>{field.type}</div>
                        </Card>
                    </div>)}
                </DndConnector>
            ))}
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SchemaFields);