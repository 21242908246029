import React from 'react';
//import PropTypes from 'prop-types';

import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';

import { icon } from 'util/icon';

export default class Default extends React.PureComponent {

    handleClick = (evt) => {
        this.props.onClick(this.props.id);
    };
    
    render() {
        const { label } = this.props;
        let actionIcon = this.props.icon;
        let iconComponent = actionIcon ? icon(actionIcon) : null;
        return (<MenuItem
            onClick={this.handleClick}
        >
            {iconComponent ? <ListItemIcon>{iconComponent}</ListItemIcon> : null}
            <Typography variant="inherit" noWrap>{label}</Typography>
        </MenuItem>);
    }

}

Default.propTypes = {};