import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { HotKeys } from "react-hotkeys";

import config from 'config/keys';

const bindHandlers = (props, dispatch) => {
    const boundHandlers = {};
    const keyMap = props.keyMap;
    const handlers = props.handlers;
    Object.keys(keyMap).forEach((key) => {
        boundHandlers[key] = (evt) => {
            evt.stopPropagation();
            evt.preventDefault();
            if (handlers[key]) {
                dispatch(handlers[key]());
            }
            if (props[key]) props[key]();
        }
    });
    return boundHandlers;
};

const mapState = (store, props) => {
    return {
        keyMap: config[props.id].keyMap,
        handlers: config[props.id].handlers
    }
};

class Keymap extends React.PureComponent {

    constructor(props) {
        super(props);
        this.hotKeys = React.createRef();

        this.handlers = bindHandlers(
            props,
            props.dispatch
        );
    }

    componentDidMount(){
        if (this.hotKeys.current) {
            const mousetrap = this.hotKeys.current.__mousetrap__;
            mousetrap.__proto__.stopCallback = (e, element) => {
                // Your custom logic here to replace the above function
            };
        }
    }

    render() {
        return (
            <HotKeys
                keyMap={this.props.active ? this.props.keyMap : null}
                handlers={this.handlers}
                focused={this.props.global || null}
                attach={window}
                className={this.props.className}
                style={this.props.style}
                ref={this.hotKeys}
            >
                {this.props.children}
            </HotKeys>
        );
    }

}

Keymap.propTypes = {
    id: PropTypes.string.isRequired,
    active: PropTypes.bool,
    global: PropTypes.bool
};

export default connect(mapState)(Keymap);