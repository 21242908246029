import { createSelector } from "reselect";
import uuid from 'uuid/v4';

import { parseTemplateMapping } from "util/templatable";
import {mergeEntities, selectResourcePath} from 'state/selectors/resources';
import { denormalizeResource } from "config/schema";
import * as tree from 'util/tree';

import _ from 'lodash';
const nullObj = {};

const unique = (arrArg) => arrArg.filter((elem, pos, arr) => arr.indexOf(elem) == pos);

export const selectDevice = (store) => {
    return store.context.device || 'desktop';
};

export const getPath = (store, props) => {
    return props.id ? 'cms.components/' + props.id : props.path;
};

export const getCtxPath = (store, props) => {
    const paths = props.ctxPath.split('$');
    //return paths[0];
    return paths[paths.length-1];
};

export const query = (entities, path) => {
    return _.get(entities, path.split('/'))
};

export const selectMapping = (store, props, ...args) => {
    if (props.mapping) return props.mapping;
    return selectPathMapping(store, getPath(store, props).split('/'));
};

export const selectPathMapping = (store, path) => {
    if (!path) return nullObj;
    const resource = selectResourcePath(store, path);
    if (!resource) return nullObj;
    const dataMapping = resource.template_id;
    if (!dataMapping) return nullObj;
    return parseTemplateMapping(dataMapping);
};

export const selectTemplateMapping = (store, path) => {
    const root = selectResourcePath(store, path);
    if (!root.template) return false;
    let mappings = {};
    if (root.template) {
        let section = denormalizeResource(mergeEntities(store), 'cms.sections', root.template);
        if (!section || !section.items) return null;
        let normalized = tree.normalize(section.items);
        let sectionMapping = parseTemplateMapping(section.template_id);
        mappings = {...mappings, ...sectionMapping};
        Object.keys(normalized).forEach(id=>{
            let mapping = normalized[id].data.template_id;
            let parsed = parseTemplateMapping(mapping, true);
            mappings = {...mappings, ...parsed};
        });
    }
    return mappings;
};

const mergeInlineStyles = (left, right) => {
    if (!left) left = {};
    if (!right) right = {};
    let keys = Object.keys(left).concat(Object.keys(right));
    let merge = {};
    keys.forEach(k => {
        let leftValue = left[k] || [];
        let rightValue = right[k] || [];
        if (!merge[k]) {
            merge[k] = unique(leftValue.concat(rightValue));
        }
    });
    return merge;
};

const selectMappedData = (ctxData, key, data) => {
    if (key.split('|')[1] === 'merge') {
        let k = key.split('|')[0];
        return mergeInlineStyles(ctxData[k], data);
    }
    return ctxData[key];
};

const mergeContextData = (path, ctxPath, data = {}, ctxData = {}, mapping) => {
    if (mapping === nullObj) return {data,path};
    let mapped = {};
    let mappedPaths = {};
    let mappedFallback = {};
    Object.keys(mapping).forEach((key) =>
    {
        if (ctxData) {
            mapped[key] = selectMappedData(ctxData, mapping[key], data[key]);
            mappedFallback[key] = ctxData.fallback_translations
                ? ctxData.fallback_translations[mapping[key]]
                : null;
            mappedPaths[key] = ctxPath
        }
    });
    let mergeFallback = {...data.fallback_translations, ...mappedFallback};
    return {
        path,
        ctxPath,
        data: {
            ...data,
            ...mapped,
            fallback_translations: mergeFallback
        },
        ctxData,
        mapped,
        mapping,
        mappedPaths
    }
};

export const createMappedSelector = () => {
    return createSelector(
        [
            getPath,
            getCtxPath,
            (store, props) => selectResourcePath(store, getPath(store, props).split('/')),
            (store, props) => selectResourcePath(store, getCtxPath(store, props).split('/')),
            selectMapping
        ],
        mergeContextData
    )
};

export const selectLayoutPlaceholders = createSelector(
    [(store)=>store.data['cms.sections']],
    (sections) => {
        if (!sections) return [];
        return Object.keys(sections).filter((id)=>sections[id].type==='placeholder')
    }
);

export const mappedSelector = createMappedSelector();