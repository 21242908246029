import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import ThemeVcsContainer from 'containers/ThemeVcs';
import Dialog from 'components/Dialog';
import {push} from "state/actions/route";

const mapStateToProps = (store, props) => {
    const themeId = store.context.id;
    return {
        open: store.context.q === 'vcs',
        isPending: store.ui.pending[themeId],
        theme: themeId
    }
};

const mapDispatchToProps = {
    push
};


class ThemeVcs extends React.PureComponent {

    handleClose = () => this.props.push('/console/themes');

    render() {
        const { theme, open, isPending } = this.props;
        return (<Dialog
            open={open}
            onClose={this.handleClose}
            title="Theme VCS"
            isPending={isPending}
            maxWidth="sm"
            fullWidth={true}
        >
            <ThemeVcsContainer themeId={theme} />
        </Dialog>);
    }

}

ThemeVcs.propTypes = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ThemeVcs);