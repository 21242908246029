import React from 'react';

export default class Tags extends React.PureComponent {

    render() {
        const { s, data } = this.props;
        const tags = data.tags_filter || [];
        const allTags = data.tags || [];
        const filteredTags = tags.filter((tag) => {
            return allTags.indexOf(tag.id) > -1;
        });
        const tag = filteredTags[0];
        if (!tag) return null;
        let tagStyle = s.tag([tag.slug]);
        return (<div className={"tag " + tagStyle.__get('tag')}>
            <div className={"tag__label " + tagStyle.__get('tag_label')}>
                {tag.title}
            </div>
        </div>);
    }

}