import React from 'react';
//import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Icon from '@material-ui/core/Icon';

const styles = {
    small: {
        fontSize: '16px'
    },
    regular: {

    }
};

class IconButton extends React.PureComponent {

    handleClick = (evt) => {
        evt.stopPropagation();
        if (!this.props.onClick) return;
        this.props.onClick(evt);
    };

    render() {
        const { classes, size, icon, className, active } = this.props;
        let activeClass = '';
        if (active === true) activeClass = 'active';
        if (active === false) activeClass = 'inactive';
        let btnClassName = 'icon-btn ' + (className || '') + ' ' + activeClass;
        return (<div className={btnClassName}><Icon
            onClick={this.handleClick}
            className={classes[size || 'regular']}
        >
            {icon}
        </Icon></div>);
    }

}

IconButton.propTypes = {};

export default withStyles(styles)(IconButton);