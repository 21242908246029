import React, {Component} from 'react';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Icon from '@material-ui/core/Icon';
import EnhancedMenu from 'components/menu/EnhancedMenu';

export default class IconMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: null
        }
    }

    handleToggle = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({
            open: !this.state.open,
            anchorEl: event.currentTarget
        });
    };

    handleClose = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({
            open: false,
            anchorEl: null
        });
        if (this.props.onClose) this.props.onClose();
    };

    render() {
        const { sm, items, trigger } = this.props;
        const { open, anchorEl } = this.state;
        const isMenuOpen = !!((open && anchorEl)||this.props.open);
        const menuAnchorEl = this.props.anchorEl || anchorEl;
        
        let triggerElement = sm
            ? (<Icon onClick={this.handleToggle}>
                {this.props.icon || 'more_vert'}
            </Icon>)
            : (<IconButton color="inherit" onClick={this.handleToggle}>
                <Icon>
                    {this.props.icon || 'more_vert'}
                </Icon>
            </IconButton>);
        
        if (trigger) triggerElement = trigger({onClick:this.handleToggle});

        if (items) {
            return (<div>
                {triggerElement}
                <EnhancedMenu
                    open={isMenuOpen}
                    anchorElement={menuAnchorEl}
                    onClose={this.handleClose}
                    menuItems={items} />
            </div>)
        }

        return (<div>
            {triggerElement}
            <Menu
                open={isMenuOpen}
                anchorEl={menuAnchorEl}
                onClose={this.handleClose}
                onClick={this.handleClose}
            >
                {this.props.children}
            </Menu>
        </div>);
    }

}