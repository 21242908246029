import { call, takeEvery, select, put } from "redux-saga/effects";

import {
  listFetchSuccess,
  resourceFetchSuccess,
  draftsCreate,
  listAppend,
  resourceCreate,
  resourceDeleteSuccess,
  resourcePathAttach,
  resourcePathDetach,
  resourceSave,
  resourceChangeField,
  draftsChangeField,
  draftsPathAttach,
  listSaveOrder,
  listFilterSet,
  draftsSave,
  listUpdateMeta,
} from "state/actions";

import { uiSetView } from "state/actions/ui";

import * as ui from "util/sagas/feedback";

import { resourceRoute, query } from "state/actions/route";
import * as api from "util/api/saga";
import parseResponse from "util/api/adapters";

import {
  selectStoreResource,
  selectResource,
  mergeEntities,
} from "state/selectors/resources";

import namedActions from "state/actions/aliases";
import { denormalizeResource } from "config/schema";

function* handleListFetch(action) {
  const { resource, list, filter, params, yieldData, context, append } = action;
  let listId = list || resource;
  let filterId = filter || list;
  try {
    yield ui.pending(listId, true);
    //const data = yield call(api.get, resource, null, context);
    const filters = yield select((store) => store.filter[filterId]);
    let filterArgs = {};
    if (filters && filters.pagination) {
      const { pagination, ...other } = filters;
      filterArgs = other;
      filterArgs.page = pagination.current;
      if (pagination.limit) filterArgs.limit = pagination.limit;
    } else {
      filterArgs = filters;
    }
    if (params) filterArgs = { ...filterArgs, ...params };
    const response = yield call(
      api.getArgs,
      filterArgs || {},
      resource,
      null,
      context
    );
    const data = parseResponse(resource, response);
    const resultResource =
      resource === "cms.data" ? "cms.entries." + filters.entry_type : resource;
    yield put(listUpdateMeta(resultResource, listId, data.data.meta));
    if (data) {
      if (resource === "cms.settings") {
        yield put(
          listFetchSuccess(
            resultResource,
            { data: [data.data.data] },
            listId,
            filterId,
            yieldData
          )
        );
      } else {
        yield put(
          listFetchSuccess(
            resultResource,
            data.data,
            listId,
            filterId,
            yieldData,
            !!append
          )
        );
      }
    }
    yield ui.pending(listId, false);
  } catch (e) {
    yield ui.pending(listId, false);
    yield ui.error(e);
  }
}

function* handleListFilter(action) {
  try {
    const { filterId, filter } = action;
    let prevFilter = yield select((store) => store.filter[filterId]);
    let nextFilter = prevFilter ? { ...prevFilter, ...filter } : filter;
    Object.keys(nextFilter).forEach((k) => {
      if (nextFilter[k] === null) delete nextFilter[k];
    });
    let setPage = filter.pagination && filter.pagination.current;
    if (prevFilter && !setPage) {
      nextFilter.pagination = { current: 1 };
    }
    yield put.resolve(listFilterSet(filterId, nextFilter));
    yield call(handleListFetch, {
      resource: action.resource,
      list: action.list,
      filter: action.filterId,
      yieldData: true,
      context: action.context,
    });
  } catch (e) {
    ui.error(e);
  }
}

function* handleListFilterAppend(action) {
  try {
    const { filterId, filter } = action;
    let prevFilter = yield select((store) => store.filter[filterId]);
    let nextFilter = prevFilter ? { ...prevFilter, ...filter } : filter;
    Object.keys(nextFilter).forEach((k) => {
      if (nextFilter[k] === null) delete nextFilter[k];
    });
    yield put.resolve(listFilterSet(filterId, nextFilter));
    yield call(handleListFetch, {
      resource: action.resource,
      list: action.list,
      filter: action.filterId,
      yieldData: true,
      context: action.context,
      append: true,
    });
  } catch (e) {
    ui.error(e);
  }
}

function* handleResourceFetch(action) {
  try {
    const { list, id, context } = action;
    yield ui.pending(id, true);
    const data = yield call(api.getArgs, { expand: true }, list, id, context);
    if (data && data.data.data) {
      yield put(resourceFetchSuccess(list, id, data.data.data));
    }
    //let resourceData = yield select((store)=>selectResource(store, list, id));
    //yield put(draftsCreate(list, id, resourceData));
    yield ui.pending(id, false);
  } catch (e) {
    yield ui.pending(action.id, false);
    yield ui.error(e);
  }
}

function* handleListCreate(action) {
  try {
    const { resource, listId, data, after, context } = action;
    if (resource === "db.nodes") {
      data.parent = context.id;
    }
    yield put(resourceCreate(resource, data));
    yield put(listAppend(listId, data, after));
    if (resource === listId) {
      yield put(resourceRoute(resource + "/edit", { id: data.id }));
    }
    if (resource === "db.form-views") {
      let type = yield select((store) =>
        selectResource(store, "db.types", data.resource)
      );
      yield put(uiSetView("form", type.name, data.id));
      yield put(query({ editView: true }));
    }
  } catch (e) {}
}

export function* handleDraftSubmit(action) {
  try {
    const { resource, id, dismiss, context } = action;
    const prev = yield select((store) =>
      selectStoreResource(store.data, [resource, id])
    );
    const data = yield select((store) =>
      denormalizeResource(store.schema, store.data)(
        mergeEntities(store),
        resource,
        id
      )
    );
    const schema = yield select((store) => store.resources[resource]);
    yield ui.pending(id, true);
    const useId = resource !== "cms.theme.files" ? id : null;
    let responseData = yield call(
      api.put,
      { id, ...data },
      resource,
      useId,
      context
    );
    let updateData = responseData.data.data
      ? { id, ...responseData.data.data, draft: false }
      : { id, ...prev, ...data, draft: false };
    const isNew = !prev.id || prev.draft;
    if (isNew && context.view !== "sections" && resource === "cms.sections") {
      console.log("Saving parent resource");
      yield put.resolve(
        draftsSave(context.app + "." + context.view, context.id)
      );
    }
    if (isNew && !context.relation && schema && schema.orderable) {
      yield put.resolve(listSaveOrder(resource, resource));
    }
    if (updateData) {
      yield put.resolve(resourceFetchSuccess(resource, id, updateData));
    }
    yield ui.pending(id, false);
    yield put.resolve({
      type: "DRAFT.SUBMIT.SUCCESS",
      resource,
      id,
      data: updateData,
    });
    yield ui.success();
    if (dismiss) {
      yield handleDismiss(context, resource, id);
    }
  } catch (e) {
    yield ui.pending(action.id, false);
    yield ui.error(e);
  }
}

export function* handleDraftPost(action) {
  let { resource, id, endpoint, context } = action;
  let formId = [resource, id].join(".");
  try {
    let data = yield select((store) => store.drafts[resource][id]);
    let response = yield call(api.post, { ...data }, endpoint, null, context);
    console.log(data, response);
    yield ui.pending(formId, true);
    yield put(query({ q: null }));
  } catch (e) {
    yield ui.pending(formId, false);
    yield ui.error(e);
  }
}

function* handleDismiss(context, resource, id) {
  try {
    if (resource && context.edit) {
      let editPath = context.edit.split("/");
      editPath = [editPath[0], editPath[1]].join("/");
      let path = [resource, id].join("/");
      if (editPath === path) {
        yield put(query({ edit: null }));
        return;
      }
    }
    let route = context.app + "." + context.view;
    if (context.relation) route += "/" + context.relation;
    yield put(resourceRoute(route));
  } catch (e) {
    yield ui.error(e);
  }
}

function* handleDraftCancel(action) {
  try {
    const { resource, id, list, context } = action;
    const data = yield select((store) => {
      if (!store.data[resource]) return {};
      return store.data[resource][id];
    });
    let route = context.app + "." + context.view;
    if (data && data.draft) {
      if (context.relation) {
        yield put(
          resourcePathDetach([route, context.id, context.relation], id)
        );
      } else {
        yield put(resourceDeleteSuccess(resource, id, list));
      }
    }
    if (route === resource || context.relation) {
      yield handleDismiss(context);
    }
  } catch (e) {
    yield ui.error(e);
  }
}

function* handleListSaveOrder(action) {
  try {
    const { resource, id, context } = action;
    const listOrder = yield select((store) => store.lists[id]);
    const serializedListOrder = {};
    listOrder.forEach((itemId, i) => {
      serializedListOrder[itemId] = i;
    });
    const parts = resource.split(".");
    const baseResource = parts[0] + "." + parts[1];
    //const type = parts[2];
    const resourceUrl = baseResource + "/treeIndex";
    //const q = {type};
    yield ui.pending(id, true);
    yield call(api.put, serializedListOrder, resourceUrl, null, context);
    yield ui.pending(id, false);
    yield ui.success();
  } catch (e) {
    yield ui.pending(action.id, false);
    yield ui.error(e);
  }
}

function* handleResourceSave(action) {
  try {
    const { resource, id, fields, context } = action;
    //let data = yield select((store) => store.data[resource][id]);

    let data = yield select((store) =>
      denormalizeResource(store.schema, store.data)(
        mergeEntities(store),
        resource,
        id
      )
    );

    let selectedFields = data;
    if (fields && fields.length) {
      selectedFields = { id: data.id };
      fields.forEach((f) => {
        selectedFields[f] = data[f];
      });
    }
    yield ui.pending(id, true);
    yield call(api.put, selectedFields, resource, id, context);
    yield put(resourceFetchSuccess(resource, id, data));
    yield ui.pending(id, false);
    yield ui.success();
  } catch (e) {
    yield ui.pending(action.id, false);
    yield ui.error(e);
  }
}

function* handleResourceDelete(action) {
  try {
    const { resource, id, list, context } = action;
    const confirmed = yield call(ui.confirm, "Czy na pewno?");
    if (confirmed) {
      yield ui.pending(id, true);
      let prev = yield select((store) => store.data[resource][id]);

      if (!prev || !prev.draft) {
        yield call(api.remove, resource, id, context);
      }

      yield ui.pending(id, false);
      yield put(resourceDeleteSuccess(resource, id, list));
      yield ui.success();
    }
  } catch (e) {
    yield ui.pending(action.id, false);
    yield ui.error(e);
  }
}

function* handleResourcePathCreate(action) {
  try {
    const { resource, path, data, after, single } = action;
    yield put(resourceCreate(resource, data));
    yield put(resourcePathAttach(path, data.id, after, single));
    if (resource === "cms.sections") {
      yield put(resourceRoute(path[0] + "/section", { relatedId: data.id }));
    }
  } catch (e) {
    yield ui.error(e);
  }
}

function* handleDraftPathCreate(action) {
  try {
    const { resource, path, data, after, single } = action;
    yield put(draftsCreate(resource, null, data));
    yield put(draftsPathAttach(path, data.id, after, single));
    if (resource === "cms.sections") {
      yield put(resourceRoute(path[0] + "/section", { relatedId: data.id }));
    }
  } catch (e) {
    yield ui.error(e);
  }
}

function* handleResourceToggle(action) {
  try {
    const { resource, id, property } = action;
    const prev = yield select((store) => store.data[resource][id][property]);
    yield put(resourceChangeField(resource, id, property, !prev));
    yield put(draftsChangeField(resource, id, property, !prev));
    if (resource !== "cms.items") {
      yield put(resourceSave(resource, id, [property]));
    }
  } catch (e) {
    yield ui.error(e);
  }
}

function* handleResourceRequest(action) {
  try {
    const { data, context } = action;
    const { resource, id } = action.context;
    const endpoint = action.endpoint;
    yield ui.pending(id, true);
    const response = yield call(api.post, data, endpoint, id, context);
    if (response && response.data.data) {
      yield put(resourceFetchSuccess(resource, id, response.data.data));
    }
    yield ui.pending(id, false);
  } catch (e) {
    yield ui.pending(action.context.id, false);
    yield ui.error(e);
  }
}

export default function* () {
  yield takeEvery("LIST.FETCH", handleListFetch);
  yield takeEvery("LIST.FETCH_PARAMS", handleListFetch);
  yield takeEvery("LIST.FILTER", handleListFilter);
  yield takeEvery("LIST.FILTER_APPEND", handleListFilterAppend);
  yield takeEvery("RESOURCE.FETCH", handleResourceFetch);
  yield takeEvery("LIST.CREATE", handleListCreate);
  yield takeEvery("DRAFT.SUBMIT", handleDraftSubmit);
  yield takeEvery("DRAFT.CANCEL", handleDraftCancel);
  yield takeEvery("LIST.SAVE_ORDER", handleListSaveOrder);
  yield takeEvery("RESOURCE.SAVE", handleResourceSave);
  yield takeEvery("RESOURCE.DELETE", handleResourceDelete);
  yield takeEvery("RESOURCE.PATH.CREATE", handleResourcePathCreate);
  yield takeEvery("RESOURCE.TOGGLE", handleResourceToggle);
  yield takeEvery("DRAFT.PATH.CREATE", handleDraftPathCreate);
  yield takeEvery("DRAFT.SAVE", handleDraftSubmit);
  yield takeEvery("RESOURCE.REQUEST", handleResourceRequest);
  yield takeEvery("DRAFT.POST", handleDraftPost);
}
