import React from 'react';
import PropTypes from 'prop-types';
import Fieldset from 'v2/Fieldset';

export default class Subfields extends React.PureComponent {
    render() {
        const id = this.props.resourceId;
        const { resource, schema } = this.props;
        const { fields } = schema;

        return <Fieldset
            resource={resource}
            id={id}
            fields={fields}
        />;

        return fields ? <div style={{
            display: 'flex',
            marginBottom: '1rem',
            paddingBottom: '1rem',
            borderBottom: 'solid 1px rgba(0,0,0,0.1)'
        }}>
            <div style={{ width: '160px', fontSize: '16px', fontWeight: 600, paddingRight: '1rem' }}>
                {this.props.label}
            </div>
            <div style={{ flex: 1 }}>
            </div>
        </div> : null;
    }
}
