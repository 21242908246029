import React from 'react';
import {connect} from 'react-redux';
import {listFilterSet} from 'state/actions';
import TextField from 'components/fields/text/TextField';
import SelectMenu from 'components/fields/select/SelectMenu';

const selectFilteredResources = (store, resource, listId) => {
    let data = store.data[resource]
        ? Object.keys(store.data[resource])
        : [];
    if (!data) return [];
    let filter = store.filter[listId];
    if ((!filter || !filter.search) && data) return data;
    return data.filter((item) => {
        return item.indexOf(filter.search) > -1;
    });
};

const mapStateToProps = (store, props) => {
    return {
        styles: selectFilteredResources(
            store,
            'theme.styles',
            props.id
        ),
        filter: store.filter[props.id] || {}
    }
};

const mapDispatchToProps = {
    onSetFilter: listFilterSet
};

class FindStyle extends React.PureComponent {

    handleSearch = (key, value) => {
        this.props.onSetFilter(
            this.props.id,
            { search: value }
        )
    };

    handleChange = (s) => {
        //this.props.onClose();
        this.props.onChange(s);
    };

    render() {
        return (<SelectMenu
            filters={this.props.filter}
            onSearch={this.handleSearch}
            onSelect={this.handleChange}
            trigger={this.props.trigger}
            options={this.props.styles}
        />);
        const { styles, filter } = this.props;
        return (<div>
            <TextField
                onChange={this.handleSearch}
                value={filter.search}
                label="Szukaj..."
                size="small"
            />
            <div className="popover-content">
            {styles.map((s)=> {
                return <div onClick={()=>this.handleChange(s)}>{s}</div>
            })}
            </div>
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FindStyle);