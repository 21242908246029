import React from "react";
import Icon from '@material-ui/core/Icon';

import icons from 'config/icons';
import {webfont_icon} from 'ide/components/icon'

export const icon = (id, size, color) => {
    let IconComponent = icons[id];
    if (typeof IconComponent === 'string') {
        if (IconComponent.indexOf('fh-')>-1) {
            return webfont_icon(IconComponent);
        }
        if (IconComponent.indexOf('fa-')>-1) {
            return webfont_icon(IconComponent);
        }
        return (<Icon fontSize={size || 'default'} color={color || 'inherit'}>{IconComponent}</Icon>);
    }
    if (!IconComponent && id) {
        if (id.indexOf('fh-')>-1) {
            return webfont_icon(id);
        }
        if (id.indexOf('fa-')>-1) {
            return webfont_icon(id);
        }
        if (id.indexOf('mui-')>-1) {
            return (<Icon fontSize={size || 'default'} color={color || 'inherit'}>{id.replace('mui-','')}</Icon>);
        }
    }
    if (!IconComponent) return null;
    return (<IconComponent />);
};