import React from 'react';
import PropTypes from 'prop-types';
import FormField from 'containers/FormField';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';

export default class AddressField extends React.Component {

    field = (field) => {
        const { id, schemaPath, fields, parentPath } = this.props;
        if (!fields[field]) return null;
        let fieldSchemaPath = schemaPath.split('/').concat([id, 'fields']).join('/');
        return (<FormField
            parentPath={parentPath}
            schemaPath={fieldSchemaPath}
            id={field}

        />);
    };

    render() {
        return <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex' }}>
                <Icon>location_on</Icon>
                <Typography variant="h5">{this.props.label}</Typography>
            </div>
            <div style={{ marginTop: '1rem', flex: 1, display: 'flex' }}>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }}>{this.field('location.street')}</div>
                        <div style={{ width: '120px', marginLeft: '1rem' }}>{this.field('location.street_no')}</div>
                    </div>
                    <div style={{ display: 'flex', marginTop: '1rem' }}>
                        <div style={{ width: '200px' }}>{this.field('location.zip')}</div>
                        <div style={{ marginLeft: '1rem', flex: 1 }}>{this.field('location.city')}</div>
                    </div>
                </div>
                <div style={{ marginLeft: '1rem', display: 'flex' }}>
                    {this.field('location.geolocation')}
                </div>
            </div>
        </div>
    }

}

AddressField.propTypes = {};