import React from 'react';

import {ResourceList,Resource} from "list";
import App from 'views/layouts/App';
import {push} from 'connected-react-router';
import {connect} from 'react-redux';

import WebsiteCard from 'components/list/items/WebsiteCard';
import Filters from 'containers/list/Filters';

const mapStateToProps = (store, props) => {
    return {
        project: store.context.project,
        resource: 'console.services.website',
        filterId: 'dashboard.websites'
    }
};
const mapDispatchToProps = {
    onChangeWebsite: push
};

class Dashboard extends React.PureComponent {

    handleClick = (id) => {
        this.props.onChangeWebsite('/cms/pl/'+id+'/pages');
    };

    render() {
        const { resource, filterId } = this.props;
        return (<App>
            <ResourceList
                resource={resource}
                listId={resource}
                filterId={resource}
                view={resource}
                renderItem={props=><Resource
                    {...props}
                    onClick={this.handleClick}
                />}
            />
        </App>);
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);