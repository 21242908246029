import {
    call,
    takeEvery,
    select,
    put
} from 'redux-saga/effects';

import * as ui from 'util/sagas/feedback';
import * as api from 'util/api/saga';
import uuid from 'uuid/v4';

import {selectResource} from 'state/selectors/resources';
import {query, resourceRoute, push} from 'state/actions/route';
import {listFilter, draftsCreate, draftsCancel, listFetch} from 'state/actions/index';

function* handleView(action) {
    try {
        const [app,resource] = action.resource.split('.');
        yield put(push('/:app/:lang/:project/:view/:id/:relation', {
            ...action.context,
            app,
            view: resource,
            id: action.id,
            relation: action.view
        }));
    } catch (e) {
        yield ui.error(e);
    }
}

function* handleJobRequest(action) {
    try {
        let jobResource = 'db.' + action.job;
        let data = {
            id: uuid(),
            template: action.id
        };
        yield put(draftsCreate(jobResource, jobResource, data));
        yield put(query({
            edit: jobResource+'/'+data.id
        }))
        /*let data = {};
        data[action.resource] = action.id;
        yield ui.pending(action.list, true);
        const response = yield call(api.post, data, 'db.job', action.job, action.context);
        yield put(listFilter(action.resource, action.list));*/
    } catch (e) {
        yield ui.pending(action.list, false);
        yield ui.error(e);
    }
}

function* handleJobRequestSubmit(action) {
    try {
        const { resource, id } = action;
        let job = action.resource.split('.')[1];
        let data = yield select(store=>selectResource(store, resource, id));
        yield ui.pending(id, true);
        const response = yield call(api.post, data, 'db.job', job, action.context);
        yield ui.pending(id, false);
        yield put(draftsCancel(resource, id));
        yield put(query({edit:null}));
        const respData = response.data.data;
        const result = respData.result;
        if (respData.result) {
            yield put(resourceRoute(result.resource+'/edit', {id: result.id}));
        }
        //yield put(listFilter(action.resource, action.resour));
    } catch (e) {
        yield ui.pending(action.id, false);
        yield ui.error(e);
    }
}

function* handleJob(action) {
    try {
        const { job, data, list } = action;
        yield ui.pending(list, true);
        const response = yield call(api.post, data, 'cms.job', job, action.context);
        yield ui.pending(list, false);
        yield put(listFetch(list, list));
    } catch (e) {
        yield ui.pending(action.list, false);
        yield ui.error(e);
    }
}

export default function*() {
    yield takeEvery('DB.JOB', handleJobRequest);
    yield takeEvery('DB.JOB_EXECUTE', handleJob);
    yield takeEvery('DB.VIEW', handleView);
    yield takeEvery('DB.JOB_REQUEST_SUBMIT', handleJobRequestSubmit);
}