import React from 'react';
import { connect } from 'react-redux';

import IconButton from 'components/TooltipIconButton';
import copy from 'copy-to-clipboard';

import { pageUrl } from "state/selectors/app";
import { feedback } from 'state/actions/ui';

const mapState = (store, props) => {
    return {
        url: pageUrl(store, store.context.id)
    }
};

const mapDispatch = {
    feedback
};

class ProjectLink extends React.PureComponent {
    
    handleClick = (evt) => {
        if (evt.ctrlKey) {
            copy('foobar');
            this.props.feedback('Skopiowano do schowka!', 'info');
            return;
        }
        window.open(this.props.url);
    };

    render() {
        if (!this.props.url) return null;
        
        return (<IconButton
            icon="launch"
            tooltip={this.props.url}
            onClick={this.handleClick}
            size="small"
        />);
    }

}

export default connect(mapState, mapDispatch)(ProjectLink);