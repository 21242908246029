import React from 'react';
import PropTypes from 'prop-types';
import { thumbnail } from 'util/thumbnail';

import Avatar from '@material-ui/core/Avatar';
import BrowseMediaButton from 'components/media/BrowseMediaButton';

export default class Media extends React.PureComponent {

    handleSelect = (media) => {
        console.log(media);
    };

    render() {
        const { isActive } = this.props;
        const media = this.props.data.media;
        let cover = null;
        if (media) cover = media[0];

        let img = null;
        
        if (cover && cover.type === 'image') img = thumbnail(cover.file, 'xsmall');
        if (cover && cover.type === 'link') img = cover.url;

        return (<BrowseMediaButton
            trigger={(props)=><Avatar
                {...props}
                className={isActive ? 'avatar-media active' : 'avatar-media inactive'}
            >
                {img ? <img
                        alt="avatar"
                        src={img}
                    /> : null}
            </Avatar>}
            onSelect={this.handleSelect}
        />);
    }

}

Media.propTypes = {
    data: PropTypes.object.isRequired
};