import React  from "react";

import AceEditor from "react-ace-cdn";

export default class CodeEditor extends React.PureComponent {
  constructor(props) {
    super(props);
    let properties = props.properties || {};
    this.state = {
      code: properties.format === "json" ? "{}" : ""
    };
    this.editorProps = { $blockScrolling: false };
  }

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    if (prevProps.rootId && prevProps.rootId !== this.props.rootId) {
      this.initialize();
    }
  }

  initialize() {
    let properties = this.props.properties || {};
    if (this.props.value) {
      if (properties.format === "json") {
        this.setState({
          code: JSON.stringify(this.props.value, null, 2) || "{}"
        });
      } else {
        this.setState({
          code: this.props.value || ""
        });
      }
    }
  }

  handleChange = (value) => {
    let properties = this.props.properties || {};
    try {
      let parsed = properties.format === "json" ? JSON.parse(value) : value;
      this.props.onChange(this.props.id, parsed);
      this.setState({ code: value });
    } catch (e) {
      this.setState({ code: value });
      //throw e;
    }
  };

  render() {
    return (
      <AceEditor
        fontSize="14px"
        width="100%"
        height={this.props.height || "370px"}
        mode="less"
        theme="solarized_dark"
        onChange={this.handleChange}
        readOnly={false}
        name={this.props.id}
        value={this.state.code}
        editorProps={this.editorProps}
        debounceChangePeriod={1000}
        wrapEnabled={true}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true,
          tabSize: 4
        }}
      />
    );
  }
}
