import React from 'react';
import PropTypes from 'prop-types';

import { selectValue } from 'components/list/fields';

import TextField from 'components/fields/text/TextField';
import Popover from 'components/Popover';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Dot from 'components/Dot';
import {icon} from 'util/icon';

const anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left'
};

export default class SelectMenu extends React.PureComponent {

    labels = ['label', 'name', 'title', 'description', 'code_part', 'type', 'id'];

    render() {
        const { options, filters, value, multiple, trigger, onMouseOver } = this.props;
        return (<Popover
            trigger={trigger}
            anchorOrigin={anchorOrigin}
            content={(props)=><div>
                { this.props.onSearch && (options.length > 5 || filters.search) ? <div className="popover-filter"><TextField
                    id="select-search"
                    onChange={this.props.onSearch}
                    label="Wyszukaj..."
                    value={filters.search}
                    autoFocus={true}
                    variant="search"
                /></div> : null }
                <MenuList
                    dense={true}
                    className="list-scroll"
                    style={{ maxHeight: '280px', minWidth: '200px' }}
                >
                    { options && Array.isArray(options) ? options.map((option, i) => {
                        let optionValue = option.id !== undefined
                            ? option.id
                            : option;
                        let isSelected = (multiple && value.indexOf(optionValue) > -1)
                            || value === optionValue;
                        if (optionValue === undefined) optionValue = i;
                        return (<MenuItem
                            key={option.id || i}
                            value={option.id}
                            selected={isSelected}
                            onClick={() => {
                                if (!multiple) props.onClose();
                                this.props.onSelect(optionValue);
                            }}
                            onMouseOver={onMouseOver ? ()=>onMouseOver(optionValue) : null}
                            onDoubleClick={() => {
                                props.onClose();
                                this.props.onSelect(optionValue);
                            }}
                        >
                            {option.color ? <ListItemIcon>
                                <Dot color={option.color} />
                            </ListItemIcon> : null}
                            {option.icon ? <ListItemIcon>
                                {icon(option.icon)}
                            </ListItemIcon> : null}
                            {selectValue(option, this.labels) || optionValue}
                        </MenuItem>);
                    }) : null }
                </MenuList>
            </div>}
        />);
    }

}

SelectMenu.propTypes = {
    options: PropTypes.array.isRequired,
    trigger: PropTypes.func.isRequired
};