export default {
    'db.users': {
        primaryText: ['name'],
        avatar: 'user-avatar'
    },
    'db.fields': {
        primaryText: ['slug'],
        secondaryText: ['type'],
        layout: 'table'
    },
    'db.filters_config': {
        primaryText: ['key'],
        secondaryText: ['type'],
        layout: 'table'
    },
    'db.attachments': {
        primaryText: ['file'],
        secondaryText: ['type']
    },
    'db.resource-bindings': {
        primaryText: ['resource.resource.name', 'resource.resource.code_part'],
        secondaryText: ['remote.resource_type'],
        fields: {
            synced_at: {
                format: 'date-diff',
                value: ['synced_at']
            }
        }
    },
}