import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { hasAccess, validatePolicy } from 'state/selectors/access';

const mapStateToProps = (store, props) => {
    return {
        grant: props.policy
            ? validatePolicy(store, props.policy)
            : hasAccess(store, props.scopes, props.strict)
    }
};

const mapDispatchToProps = {};

class Access extends React.PureComponent {

    render() {
        if (!this.props.grant) return null;
        return this.props.children;
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Access);