import React from 'react';
import {connect} from 'react-redux';
import { resourceRoute, query } from 'state/actions/route';

import Main from './Main';
import {ideToggleZoomOut} from "state/actions/ide";
import {setUiFlag} from 'state/actions/ui';

const tabs = {
    pages: 'Pages',
    'edit-page': 'Edit page'
};

const understandRoute = ({context}) => {
    if (context.side === 'edit-page') return 'edit-page';
    if (context.relatedId) return 'edit-section';
    if (context.relation) return 'sections';
    return context.s || Object.keys(tabs)[0];
};

const mapStateToProps = (store, props) => {
    return {
        id: store.context.id,
        view: store.context.view,
        relation: store.context.relation,
        relatedId: store.context.relatedId,
        tab: understandRoute(store),
        tabs,
        side: store.context.side,
        dev: store.context.dev,
        zoomOut: store.ui.ideZoomOut,
        hideOverlays: store.ui.flags.ideHideOverlays,
        sidebarUndock: store.ui.flags.ideSidebarUndock
    }
};

const mapDispatchToProps = {
    onChangeTab: query,
    route: resourceRoute,
    onToggleZoom: ideToggleZoomOut,
    setUiFlag,
    query
};

class Container extends React.PureComponent {

    handleChangeTab = (tabId) => {
        switch (tabId) {
            case 'pages': return this.props.route(
                'cms.pages/edit',{},{dev:null}
            );
            case 'sections': return this.props.route(
                'cms.pages/sections',{},{dev:null}
            );
            case 'edit-section': return null;
            default: break;
        }
        this.props.onChangeTab({s: tabId});
    };

    handleZoom = () => {
        let currentScroll = window.scrollY;
        let nextScroll = 0;
        if (this.props.zoomOut) {
            nextScroll = currentScroll / 0.4;
        } else {
            nextScroll = currentScroll * 0.4;
        }
        window.scrollTo(0,nextScroll);
        this.props.onToggleZoom();
    };

    render() {
        return (<Main
            {...this.props }
            onChangeTab={this.handleChangeTab}
            onToggleZoom={this.handleZoom}
        />);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Container);