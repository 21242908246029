export const clipboardCopy = (resource, data) => ({
    type: 'CLIPBOARD.COPY',
    resource,
    data
});

export const clipboardCopyResource = (resource, id) => ({
    type: 'CLIPBOARD.COPY_RESOURCE',
    resource,
    id
});

export const clipboardCopyCollection = (resource, items) => ({
    type: 'CLIPBOARD.COPY_COLLECTION',
    resource,
    items
});

export const clipboardPaste = (resource, path, id) => ({
    type: 'CLIPBOARD.PASTE',
    resource,
    path
});