import React from 'react';
import {connect} from 'react-redux';
import { interact } from 'ide/Interact';
import { query } from 'state/actions/route';

import { styled } from 'state/selectors/styles';

import componentTypes from 'ide/components/index';
import Layout from './components/Layout';

import { draftsChange } from 'state/actions';
import { createMappedSelector } from 'state/selectors/ide';

import DataContext from './DataContext';
import EditableContext from './EditableContext';

const makeMapStateToProps = () => {
    const getMappedData = createMappedSelector();
    return (store, props) => {
        let virtual = props.virtual;
        let mapped = getMappedData(store, props);
        let type = virtual ? props.type : mapped.data.element_type;
        let s = virtual
            ? props.s
            : styled(store, props, type, mapped.data);
        return {
            ...mapped,
            s: s,
            type
        }
    };
};

const mapDispatchToProps = {
    onChange: draftsChange,
    query: query
};

class ComponentConnector extends React.PureComponent {

    handleChange = (key, value) => {
        const { mapping, path, ctxPath, mappedPaths } = this.props;
        if (mapping && mapping[key]) {
            let update = {};
            let targetPath = mappedPaths[key].split('/');
            update[mapping[key]] = value;
            this.props.onChange(targetPath, update);
        } else {
            let update = {};
            update[key] = value;
            this.props.onChange(path.split('/'), update);
        }
    };

    render() {
        const { type, connectedComponent } = this.props;

        if (type === 'structured') {
            return (<Layout
                {...this.props}
                onChange={this.handleChange}
            />);
        }

        let ActualComponent = connectedComponent
            || componentTypes[type]
            || componentTypes.default;

        if (this.props.data.template_component) {
            ActualComponent = componentTypes['connect-template'];
        }

        return (<ActualComponent
            {...this.props}
            onChange={this.handleChange}
        >
            {this.props.children}
        </ActualComponent>);
    }

}

const ConnectedComponent = connect(makeMapStateToProps, mapDispatchToProps)(
    interact()(ComponentConnector)
);

export default class WithContext extends React.PureComponent {
    render() {
        return (
            <EditableContext.Consumer>
                {isEditable => (
                    <DataContext.Consumer>
                        {path => (
                            <ConnectedComponent
                                {...this.props}
                                ctxPath={path}
                                editable={isEditable}
                            />
                        )}
                    </DataContext.Consumer>
                )}
            </EditableContext.Consumer>
        )
    }
}

//export default ConnectedComponent;