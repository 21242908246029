import React from 'react';
import PropTypes from 'prop-types';
import NavPillButton from 'components/NavPillButton';

export default class NavPills extends React.PureComponent {

    render() {
        const { active, onClick, items } = this.props;
        const props = this.props;
        let outerClassName = "nav-pills";
        if (props.variant === 'vertical') outerClassName += ' nav-pills--vertical';
        return (<div className={outerClassName}>
            {items.map((opt) => {
                let itemClass = "nav-pills__btn";
                if (active === opt.id) itemClass += " active";
                return (<NavPillButton
                    active={active===opt.id}
                    key={opt.id}
                    color={opt.color}
                    onClick={onClick ? ()=>onClick(opt.id) : null}
                    onClear={onClick ? ()=>onClick(null) : null}
                >
                    {opt.label || opt.title || opt.name || opt.code_part}
                </NavPillButton>);
            })}
        </div>);
    }

}

NavPills.propTypes = {
    active: PropTypes.string,
    items: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired
};