import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import Dot from 'components/Dot';

export default class NavPillButton extends React.PureComponent {

    render() {
        const { color, active, selectable, onClick, onClear } = this.props;
        let className = "nav-pill";
        if (active) className += ' active';
        if (!active && selectable) className += ' nav-pill--selectable';
        return (<div onClick={!active ? onClick : null} className={className}>
            <div className="nav-pill__label" onClick={active ? onClick : null}>
                {color ? <Dot color={color} /> : null }
                <div>{this.props.children}</div>
            </div>
            { active && onClear ? <div className="nav-pill__icon" onClick={onClear}>
                <Icon fontSize="inherit">clear</Icon>
            </div> : null }
            { active && !onClear ? (<div className="nav-pill__icon">
                <Icon fontSize="inherit">arrow_drop_down</Icon>
            </div>) : null }
            { selectable && !active ? <div className="nav-pill__icon">
                <Icon fontSize="inherit">search</Icon>
            </div> : null }
        </div>);
    }

}

NavPillButton.propTypes = {};