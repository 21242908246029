import React from 'react';
import PropTypes from 'prop-types';

import IconButton from 'components/IconButton';

export default class ToggleIcon extends React.PureComponent {

    handleClick = () => {
        let media = {...this.props.value};
        let property = this.props.property;
        media[property] = !media[property];
        this.props.onChange(media);
    };

    render() {
        const { value, icon, property } = this.props;
        return (<IconButton
            onClick={this.handleClick}
            active={!!value[property]}
            icon={icon}
        />);
    }

}

ToggleIcon.propTypes = {};