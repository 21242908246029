import React from 'react';
import PropTypes from 'prop-types';

import Icon from '@material-ui/core/Icon';

const style = {
    opacity: 0.3,
    cursor: 'move',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};

export default class DragHandle extends React.PureComponent {

    render() {
        const { connectDnd } = this.props;
        return connectDnd(<div style={style}>
            <Icon>drag_indicator</Icon>
        </div>);
    }

}

DragHandle.propTypes = {};