import React from 'react';
import PropTypes from 'prop-types';

import MuiChip from '@material-ui/core/Chip';
import Label from 'components/Label';

const outerStyle = {
    display: 'flex',
    alignItems: 'center'
};

const fullWidthStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
};

export default function Chip(props) {

    const {
        rightIcon,
        avatar,
        dotColor,
        label,
        onClick,
        fullWidth,
        ...other
    } = props;

    let style = props.style;
    if (fullWidth) style = {...style, ...fullWidthStyle};

    return <div style={outerStyle}>
        <MuiChip
            {...other}
            label={<Label dotColor={dotColor} label={label} />}
            icon={null}
            onClick={onClick}
            avatar={avatar}
            style={style}
        />
        {rightIcon}
    </div>;

}

Chip.propTypes = {
    label: PropTypes.string,
    dotColor: PropTypes.string,
    onClick: PropTypes.func,
    avatar: PropTypes.element,
    rightIcon: PropTypes.element,
    fullWidth: PropTypes.bool
};