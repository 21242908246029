import React, {Component} from 'react';
import { connect } from 'react-redux';

import EmbeddedCollection from 'components/fields/collection/EmbeddedCollection';
import Upload from 'containers/Upload';
import MediaActions from './MediaCollectionActions';

import { draftsPathAttach } from 'state/actions';
import { handleUploadDirect } from 'state/actions/files';

const mapState = (store, props) => {
    return {
        variant: props.variant || 'media'
    };
};

const mapDispatch = {
    onAttach: draftsPathAttach,
    onUpload: handleUploadDirect
};

const variantConfig = {
    media: {
        layout: 'grid',
        resource: 'cms.media'
    },
    attachments: {
        layout: 'list',
        resource: 'cms.files'
    }
};

class MediaCollection extends Component {
    handleSelect = (media) => {
        this.props.onAttach(this.props.path.split('/'), media);
    };

    handleUpload = (files) => {
        files.map((file)=>this.props.onUpload(
            file,
            this.props.path.split('/')
        ));
    };

    render() {
        const { variant } = this.props;

        return (<React.Fragment>
            <Upload onFileUpload={this.handleUpload}>
                <EmbeddedCollection
                    {...this.props}
                    {...variantConfig[variant]}
                    asideActions={<MediaActions
                        path={this.props.path}
                        onSelect={this.handleSelect}
                        onUpload={this.handleUpload}
                    />}
                />
            </Upload>

        </React.Fragment>);
    }

}

export default connect(mapState, mapDispatch)(MediaCollection);