import React from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';

export default class ListItemAvatar extends React.PureComponent {

    render() {
        const { isPending } = this.props;

        return (<React.Fragment>
            {this.props.children}
            { isPending
                ? (<CircularProgress
                    className="avatar-progress"
                    size={46}
                    thickness={4}
                />)
                : null }
        </React.Fragment>);
    }

}

ListItemAvatar.propTypes = {
    isPending: PropTypes.bool.isRequired
};