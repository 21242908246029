import React from 'react';
import PropTypes from 'prop-types';
import DateField from "../../../components/fields/datepicker/Date";

export default class Datetime extends React.Component {

    render() {
        return (<DateField
            {...this.props}
            variant={'inline'}
            format={'D MMM, H:m'}
        />);
    }

}

Datetime.propTypes = {};