import React from "react";
import PropTypes from "prop-types";
import Icon from "@material-ui/core/Icon";
import Fab from "@material-ui/core/Fab";
import { icon } from "util/icon";

import "css/media-library.css";

import MediaBrowser from "containers/MediaBrowser";
import DirectoryTree from "components/media/DirectoryTree";
import IconMenu from "components/IconMenu";
import IconButton from "components/TooltipIconButton";
import Popover from "components/Popover";

const mediaMenu = [
  {
    key: "addFolder",
    caption: "Utwórz katalog",
    icon: "create_new_folder",
  },
  {
    key: "upload",
    caption: "Wgraj pliki",
    icon: "cloud_upload",
  },
  {
    key: "divider",
  },
  {
    key: "importVideo",
    caption: "Importuj film",
    icon: "movie",
  },
  {
    key: "importPano",
    caption: "Importuj panoramę",
    icon: "360",
  },
  {
    key: "divider",
  },
  {
    key: "syncCloud",
    caption: "Importuj z s3",
    icon: "download",
  },
];

class MediaMenu extends React.PureComponent {
  constructor(props) {
    super(props);
    this.actions = {
      addFolder: props.onCreateDirectory,
      importVideo: props.onImport,
      importPano: props.onImport,
      syncCloud: props.onSyncDirectory,
    };
    this.items = props.items.map((item) => {
      item.onClick = () => this.handleAction(item.key);
      if (item.key === "upload") {
        item.onUpload = props.onUpload;
      }
      return item;
    });
  }

  handleAction = (key) => {
    if (this.actions[key]) this.actions[key](key);
  };

  render() {
    return (
      <IconMenu
        trigger={(props) => {
          return (
            <Fab {...props} variant="extended">
              <Icon style={{ marginRight: "0.6rem" }}>add</Icon>
              Nowy
            </Fab>
          );
        }}
        items={this.items}
      />
    );
  }
}

export default class Library extends React.PureComponent {
  render() {
    const { path, selection, selectedItems, dirs, libs, onChangeDirectory } =
      this.props;

    return (
      <div className="media-library" style={{ display: "flex" }}>
        <div className="media-library__sidebar">
          <div style={{ padding: "1rem" }}>
            <MediaMenu {...this.props} items={mediaMenu} />
          </div>
          {libs && libs.map
            ? libs.map((dir) => {
                let id = dir.id || "files";
                let libIcon = "media." + id;
                let className = "media-lib";
                if (id === this.props.dir) className += " active";
                if (id === "files" && !this.props.dir) className += " active";
                return (
                  <div key={dir.name}>
                    <div
                      className={className}
                      onClick={() => onChangeDirectory(dir.id)}
                    >
                      {icon(libIcon)}
                      <div>{dir.name}</div>
                    </div>
                    {!dir.id && dirs ? (
                      <div style={{ marginBottom: "1rem" }}>
                        <DirectoryTree
                          current={this.props.dir}
                          data={dirs}
                          onChangeDirectory={onChangeDirectory}
                        />
                      </div>
                    ) : null}
                  </div>
                );
              })
            : null}
        </div>
        <div style={{ display: "flex", flex: 1 }}>
          <MediaBrowser
            listId={this.props.listId}
            isPending={this.props.isPending}
            onClose={this.props.onClose}
            onSelect={this.props.onSelect}
            onDeleteFile={this.props.onDeleteFile}
            directories={dirs}
            size="lg"
            cols={6}
            multiple={true}
            path={path}
            selection={selection}
            selectedItems={selectedItems}
          />
        </div>
      </div>
    );
  }
}

Library.propTypes = {};
