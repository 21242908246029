import {createSelector} from "reselect";
import {index, selectResource, filterList} from 'state/selectors/resources';


import config from 'config/filters';

const emptyObj = {};
const emptyArr = [];

export const selectFilterConfig = (store, resource, filterId, mobile) => {
    let filterDefinition = selectResource(store, 'db.filters', filterId);
    if (filterId && filterDefinition.id) return filterDefinition;

    let filters = store.data['db.filters'];
    let indexed = {};
    if(filters) Object.values(filters).forEach(filter=>{
        indexed[filter.name] = filter;
    });
    if (indexed[resource]) {
        let fields = {};
        indexed[resource].filters_config.forEach(fId=>{
            let filter = selectResource(store, 'db.filters_config', fId);
            fields[filter.key] = filter;
        });
        return {...indexed[resource], filters_config: fields};
    }
    return config[resource] || config.default;
};

export const selectFilter = (store, id) => {
    return store.filter[id] || emptyObj;
};

export const selectFilteredResources = createSelector([
    (store, props) => store.data[props.resource] || emptyObj,
    (store, props) => store.filter[props.filterId] || emptyObj,
    (store, props) => store.lists[props.listId] || emptyArr
], filterList);

export const makeSelectFilteredList = () => {
    return createSelector([
        (store, props) => store.data[props.resource] || emptyObj,
        (store, props) => store.filter[props.filterId] || emptyObj,
        (store, props) => store.lists[props.listId] || emptyArr
    ], (data, filter, list) => {
        return Object.keys(filterList(data, filter, list));
    });
};

export const selectFilteredList = makeSelectFilteredList();