import React from 'react';
//import PropTypes from 'prop-types';

import {ResourceList,Resource,Listing} from 'list';

export default class PresetBrowser extends React.PureComponent {

    constructor(props) {
        super(props);
        this.itemProps = {
            onClick: props.onClick,
            resourceType: ['console.presets.card',props.resource].join('.')
        }
    }
    
    render() {
        const { filterId, listId } = this.props;
        let resource = ['console', this.props.resource].join('.');
        let presetListId = [listId, this.props.resource].join('.');

        return <div>
            <ResourceList
                listId={presetListId}
                filterId={filterId}
                resource="console.presets"
                view={this.itemProps.resourceType}
                renderItem={(props)=><Resource
                    {...props}
                    onClick={this.itemProps.onClick}
                />}
            />
            <ResourceList
                listId={listId}
                filterId={filterId}
                resource={resource}
                view={this.itemProps.resourceType}
                renderItem={(props)=><Resource
                    {...props}
                    onClick={this.itemProps.onClick}
                />}
            />
        </div>;
    }

}

PresetBrowser.propTypes = {};