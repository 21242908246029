import { createReducer } from "util/redux/create-reducer";
import reorder from 'util/reorder';

import { pathUpdate, attach } from './data';
import { index } from 'state/selectors/resources';

const updateList = (state, resource, data, list, append) => {
    if (!data.data) return state;
    let listId = list ? list : resource;
    let newState = {...state};
    let indexed = index(data.data, 'id');
    if (!indexed) return newState;
    let ids = Object.keys(indexed);
    let prev = newState[listId] || [];
    if (!append) {
        newState[listId] = ids;
    } else {
        newState[listId] = prev.concat(ids);
    }
    return newState;
};

export const updateOrder = (state, id, drag, hover) => {
    let prev = state[id];
    let next = reorder(prev, drag, hover, true);
    let newState = {...state};
    newState[id] = next;
    return newState;
};

export const cleanupResource = (state, resource, id) => {
    let newState = {...state};
    Object.keys(newState).forEach((key) => {
        newState[key] = newState[key].filter(itemId=>itemId!==id);
    });
    return newState;
};

export const listSetOrder = (state, list, data) => {
    let newState = {...state};
    newState[list] = data;
    return newState;
};

const actions = {
    'APP.BOOTSTRAP': (state) => ({}),
    'LIST.UPDATE': (state, {list, data}) => listSetOrder(state, list, data, list),
    'LIST.FETCH.SUCCESS': (state, {resource, data, list, append}) => updateList(state, resource, data, list, append),
    'LIST.REORDER': (state, {id, drag, hover}) => updateOrder(state, id, drag, hover),
    'LIST.APPEND': (state, {id, data, after}) => pathUpdate(state, [id], (list) => attach(list, data.id, after)),
    'RESOURCE.DELETE.SUCCESS': (state, {resource, id}) => cleanupResource(state, resource, id),
    'LIST.UPDATE_META': (state, {resource, list, data}) => {
        let nextState = {...state};
        if (!data) return state;
        let entities = data.entities;
        if (!entities) return state;
        let types = Object.keys(entities);
        types.forEach(type => {
            let listId = [list, 'meta', type].join('.');
            nextState = updateList(
                nextState,
                type,
                {data: entities[type]},
                listId
            );
        });
        return nextState;
    }
};

export default createReducer({}, actions);