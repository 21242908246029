import { createReducer } from "util/redux/create-reducer";
import { setIn, updateIn, deleteIn } from "lodash-redux-immutability";
import _ from 'lodash';
import {normalizeResources} from 'config/schema';
import reorder, { moveAtPosition } from 'util/reorder';
import {index} from 'state/selectors/resources';

function indexCollectionSchemas(indexed, fields) {
    fields.forEach(field=>{
        if (field.type === 'collection') {
            let fieldResource = [field.id].join('.');
            indexed[fieldResource] = field;
            if (field.fields) {
                indexed = indexCollectionSchemas(
                    indexed,
                    Object.values(field.fields)
                );
            }
        }
    });
    return indexed;
}

export const updateList = (state, id, data, yieldData, schema) => {
    if (id==='cms.theme') {
        data = {data: data.data[0]};
    }
    let entities = normalizeResources(schema, state)(id, data.data);
    let newState = {...state};
    newState = updateEntities(
        state,
        entities,
        yieldData
    );
    if (id==='cms.fonts') {
        console.log(data);
    }
    let type = id.split('.')[1];
    if (id === 'theme.styles') {
        let options = data.data.options;
        let optionsIndex = {};
        if (options) {
            Object.keys(options).forEach((group) => {
                options[group].forEach((opt) => {
                    optionsIndex[opt] = group;
                });
            });
            newState['theme.styles.optionsIndex'] = optionsIndex;
        }
    }
    if (type === 'enum-definitions') {
        let indexName = [id, 'index'].join('.');
        let prevIndex = newState[indexName] || {};
        let newIndex = {...prevIndex};
        Object.keys(newState[id]).forEach((entityId) => {
            let ent = newState[id][entityId];
            newIndex[ent.slug] = ent.id;
        });
        newState[indexName] = newIndex;
    }
    if (type === 'types') {
        newState['db.types.byName'] = index(Object.values(newState[id]), 'name');
        Object.values(newState[id]).forEach(type=>{
            newState['db.types.byName'] = indexCollectionSchemas(
                newState['db.types.byName'],
                Object.values(type.fields_index)
            );
        });
    }
    return newState;
};

const updateEntities = (state, entities, yieldData) => {
    let newState = {...state};
    for (let key in entities) {
        if (!newState[key]) newState[key] = {};
        if (!yieldData) {
            //...newState[key],
            newState[key] = {...newState[key],...entities[key]};
        } else {
            Object.keys(entities[key]).forEach((id) => {
                if (!newState[key][id]) {
                    newState[key][id] = entities[key][id];
                }
            });
        }
    }
    return newState;
};

export const pathUpdate = (state, path, update) => {
    let key = path[path.length-1];
    let data = {};
    let prevValue = _.get(state, path);
    data[key] = update(prevValue);
    let updatePath = path.slice(0,path.length-1);
    return updateIn(state, updatePath, data);
};

export const detach = (data = [], id) => {
    let index = data.indexOf(id);
    if (index === -1) return data;
    return data.filter((itemId)=>itemId!==id);
};

export const detachId = (data = [], id) => {
    return data.filter(item=>item.id!==id);
};

export const attach = (data = [], id, after, single) => {
    if (single) return id;
    let newData = [];
    if (!data) data = [];
    if (!Array.isArray(data)) data = [];
    if (after < 0) newData.push(id);
    if (data.indexOf(id) === -1) {
        for (let i = 0; i < data.length; i++) {
            newData.push(data[i]);
            if (data[i] === after) {
                newData.push(id);
            }
        }

        if ((!after || data.indexOf(after) === -1) && newData.indexOf(id) === -1) {
            newData.push(id);
        }
    } else {
        return data;
    }
    return newData;
};

export const append = (data = [], item) => {
    let newData = data.slice();
    newData.push(item);
    return newData;
};

export const treeStructure = (state, path, tree) => {
    let newState = pathUpdate(state, path, data => {
        let upd = tree
            ? tree.map(node => node.id)
            : [];
        return upd;
    });

    for (let i = 0; i < tree.length; i++) {
        newState = treeStructure(newState, [
            'cms.components',
            tree[i].id,
            'components'
        ], tree[i].children || [])
    }
    return newState;
};

export const treeMove = (store, id, from, to, index, fd, td) => {
    if (from.join('/') === to.join('/')) {
        return pathUpdate(store, to, (data)=>{
            let d = data || td;
            return reorder(d, d.indexOf(id), index)
        });
    }
    let newState = pathUpdate(store, from, (data)=>detach(data || fd, id));
    newState = pathUpdate(newState, to, (data)=>moveAtPosition(data || td, id, index));
    return newState;
};

const updateStyles = (state, id, data) => {
    let newState = {...state};
    let prev = newState['theme.styles']
        ? newState['theme.styles'][id]
        : {};
    delete data.id;
    delete data.draft;
    newState['theme.styles'][id] = {...prev, ...data};
    return newState;
};

const cleanupResources = (state, prefix) => {
    let newState = {...state};
    Object.keys(newState).forEach((key) => {
        if (key.indexOf(prefix) > -1) {
            newState[key] = {};
        }
    });
    return newState;
};

const cleanupCmsResources = (state) => {
    let newState = cleanupResources(state, 'cms.');
    newState = cleanupResources(newState, 'theme.');
    return newState;
};

export const actions = {
    'APP.BOOTSTRAP': (state) => cleanupCmsResources(state, 'cms.'),
    'APP.CLEANUP_RESOURCES': (state) => cleanupCmsResources(state, 'cms.'),
    'LIST.FETCH.SUCCESS': (state, {resource, data, yieldData, schema}) =>
        updateList(state, resource, data, yieldData, schema),
    'LIST.UPDATE_META': (state, {resource, list, data, schema}) => {
        let nextState = {...state};
        if (!data) return state;
        let entities = data.entities;
        if (!entities) return state;
        let types = Object.keys(entities);
        types.forEach(type => {
            nextState = updateList(
                nextState,
                type,
                {data:entities[type]},
                false,
                schema
            );
        });
        return nextState;
    },
    'RESOURCE.CREATE': (state, {resourceType, data, schema}) =>
        updateList(state, resourceType, {data: [data]}, null, schema),
    'RESOURCE.FETCH.SUCCESS': (state, {resource,id,data, schema}) => {
        if (resource === 'theme.styles') return updateStyles(state, id, data);
        let importData = {data: [{id, ...data}]};
        return updateList(state, resource, importData, null, schema);
    },
    'RESOURCE.CHANGE': (state, {path,update}) => {
        return updateIn(state, path, update);
    },
    'RESOURCE.PATH.CHANGE': (state, {path, value}) =>
        setIn(state, path, value),
    'RESOURCE.PATH.REORDER': (state, {path,drag,hover, defaultValue}) =>
        pathUpdate(state, path, (data) =>
            reorder(data || defaultValue,drag,hover, true)
        ),
    'RESOURCE.PATH.REORDER_INDEX': (state, {path,drag,hover, defaultValue}) =>
        pathUpdate(state, path, (data) =>
            reorder(data || defaultValue,drag,hover, false)
        ),
    'RESOURCE.PATH.DETACH': (state, {path, id, defaultValue}) =>
        pathUpdate(state, path, (data) =>
            detach(data || defaultValue, id)
        ),
    'RESOURCE.PATH.ATTACH': (state, {path, id, after, single, defaultValue}) =>
        pathUpdate(state, path, (data) => {
            return attach(data || defaultValue, id, after, single)
        }),
    'RESOURCE.PATH.TREE': (state, {path, tree}) =>
        treeStructure(state, path, tree),
    'RESOURCE.PATH.TREE_MOVE': (state, {id, from, to, index, fromDefault, toDefault}) =>
        treeMove(state, id, from, to, index, fromDefault, toDefault),
    'RESOURCE.PATH.DELETE': (state, {path}) =>
        deleteIn(state, path),
    'RESOURCE.PATH.DELETE_BY_ID': (state, {path, id, defaultValue}) => {
        return pathUpdate(state, path, (data) => {
            return detachId(data || defaultValue, id);
        });
    },
    'RESOURCE.PATH.APPEND': (state, {path, item, defaultValue}) => pathUpdate(state, path, (data) => {
        return append(data || defaultValue, item);
    }),
    'RESOURCE.DELETE.SUCCESS': (state, {resource, id}) => {
        return deleteIn(state, [resource, id]);
    },
    'APP.SCHEMA.SUCCESS': (state, {id, schema}) => {
        return state;
    }
};

export default createReducer({}, actions);