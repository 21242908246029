import React from 'react';
//import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import { List as ListLoader } from 'react-content-loader'

const outerStyle = {
    display: 'flex',
    minHeight: '300px',
    alignItems: 'center',
    justifyContent: 'center'
};

const paperStyle = {
    width: '480px',
    maxWidth: '480px',
    minHeight: '100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
};

const defaultText = 'Ta sekcja nie posiada jeszcze treści';

export default class SectionEmptyState extends React.PureComponent {

    render() {
        const { isPending, text } = this.props;
        return (<div style={outerStyle}>
            <div style={paperStyle}>
                { isPending ? <ListLoader /> : null }
                {!isPending && this.props.children
                    ? this.props.children
                    : null}
                {!isPending && !this.props.children
                    ? (<Typography variant="h5">
                        {text || defaultText}
                      </Typography>)
                    : null }
            </div>
        </div>);
    }

}

SectionEmptyState.propTypes = {};