import Editable from "ide/ui/Editable";
//import Quill from "ide/ui/Quill";
import React from "react";

let Quill = null;

try {
  Quill = require('ide/ui/Quill').default;
} catch (e) {
  Quill = null;
}

class Edit extends React.PureComponent {
  handleChange = value => {
    const { onChange, fieldKey } = this.props;
    onChange(fieldKey, value);
  };

  render() {
    const {
      type,
      disabled,
      tagName,
      className,
      placeholder
    } = this.props;
    const value = this.props.children;

    if (disabled || (type === 'rich' && !Quill)) {
      return <div dangerouslySetInnerHTML={{__html: value }} className={className} />;
    }

    switch (type) {
      case "inline":
        return (
          <Editable
            value={value}
            className={className}
            tagName={tagName}
            onChange={this.handleChange}
            isPreview={false}
            placeholder={placeholder}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
          />
        );
      case "rich":
        //return value;
        return (
          Quill ? <Quill
            placeholder={placeholder}
            style={{ pointerEvents: "all", minWidth: "50px" }}
            className={className}
            value={value || ""}
            onChange={this.handleChange}
          /> : null
        );
      default:
        return value;
    }
  }
}

export default Edit;
