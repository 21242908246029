import React from 'react';
import PropTypes from 'prop-types';
import DateField from 'components/fields/datepicker/Date';

export default class Date extends React.PureComponent {

    render() {
        return (<DateField {...this.props} variant={'inline'} />);
    }

}

Date.propTypes = {};