import React from 'react';
import {connect} from 'react-redux';

import { selectPath } from 'state/selectors/resources';

const mapStateToProps = (store, props) => {
    return {
        colors: selectPath(store, props.path.split('/'))
    }
};

const mapDispatchToProps = {};

class PresetColors extends React.PureComponent {

    colors = ['primary_color', 'success_color', 'dark_color', 'light_color'];

    render() {
        const { colors, height } = this.props;

        if (!colors) return null;

        return (<div className="theme-colors theme-colors--sm">
            {this.colors.map((color) => {
                return (<div
                    className="theme-colors__color"
                    key={color}
                    style={{ backgroundColor: colors[color], height: height }}
                />);
            })}
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PresetColors);