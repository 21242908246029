import React from 'react';
import {connect} from 'react-redux';
import {selectResource} from 'state/selectors/resources';
import {selectFieldSchema} from 'state/selectors/schema';
import {query} from 'state/actions/route';
import Fieldset from './Fieldset';
import FormViewModeContext from "./FormViewModeContext";
import Resource from 'v2/Resource';

const mapStateToProps = (store, props) => {
    const schema = selectFieldSchema(
        store,
        props.resource,
        props.id,
        props.data.source
    );
    const resource = selectResource(
        store,
        props.resource,
        props.id
    );
    const value = resource[schema.slug];
    return {
        schema,
        resource: schema.properties ? schema.properties.resource_type : props.resource,
        id: value
    }
};

const mapDispatchToProps = {
    query
};

class Subview extends React.Component {

    handleFocus = () => {
        this.props.query({f:this.props.schema.id});
    };

    render() {
        const { resource, id, editable } = this.props;
        return (<div onClick={editable ? this.handleFocus : null}>
            { editable ? <div>
                <div>Subview</div>
                <div>{this.props.data.source}</div>
                <div>{this.props.resource}:{this.props.id}</div>
            </div> : null }
            <Resource resource={resource} id={id} fetchPolicy={'once'}>
                {(props)=><FormViewModeContext.Provider value={'info'}>
                    <Fieldset
                        editable={this.props.editable}
                        resource={this.props.resource}
                        id={this.props.id}
                        fieldsetResource={this.props.fieldResource}
                        fieldsetId={this.props.fieldId}
                        level={this.props.level+1}
                    />
                </FormViewModeContext.Provider>}
            </Resource>
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Subview);