import * as dnd from './dnd';
import ListComponent from './List';
import {RenderResourceListItem} from 'components/list/ResourceListItem';
import {RenderListWrapper} from "components/list/ListWrapper";
import ResourceListContainer from './containers/ResourceList';

export const collectDragActors = dnd.collectDragActors;
export const applyDndCapabilities = dnd.applyDndCapabilities;
export const DndConnector = dnd.DndConnector;
export const DropAreaConnector = dnd.DropAreaConnector;
export const target = dnd.target;
export const source = dnd.source;
export const collectTarget = dnd.collectTarget;

export const List = ListComponent;
export const Listing = RenderListWrapper;
export const Resource = RenderResourceListItem;
export const ResourceList = ResourceListContainer;