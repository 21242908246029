import React from 'react';
//import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {selectSeo} from 'state/selectors/seo';

import SerpPreview from 'react-serp-preview';

const mapState = (store, props) => {
    let context = store.context;
    let pagePath = [context.app,context.view,context.id,'title'];
    let pageId = context.id;
    console.log(pagePath, pageId);
    return {
        seo: selectSeo(store, props)
    };
};

class Seo extends React.PureComponent {

    render() {
        const { title, description, url } = this.props.seo;

        return (<div style={{ marginBottom: '2rem' }}>
            <SerpPreview
                title={title || ''}
                metaDescription={description || ''}
                url={url}
            />
        </div>);
    }

}

Seo.propTypes = {};

export default connect(mapState)(Seo);