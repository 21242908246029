import React from 'react';
//import PropTypes from 'prop-types';

import IconButton from 'components/IconButton';
import Popover from 'components/Popover';
import MediaBrowser from 'containers/MediaBrowser';

export default class ImagePicker extends React.PureComponent {

    handleSelectImage = (media) => {
        this.props.onChange('media', [media]);
    };

    renderTrigger = (props)=> {
        return <IconButton
            {...props}
            icon="image_search"/>;
    };

    render() {
        return (<Popover
            trigger={this.props.trigger || this.renderTrigger}
            content={(props)=><div
                style={{ padding: '1rem' }}
            >
                <MediaBrowser
                    onClose={props.onClose}
                    onSelect={this.handleSelectImage}
                    size={'sm'}
                />
            </div>}
        />);
    }

}

ImagePicker.propTypes = {};