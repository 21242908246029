import React from 'react';
//import PropTypes from 'prop-types';

import TextField from 'components/fields/text/TextField';

export default class TextSearch extends React.PureComponent {

    render() {
        const { id, value, onSetFilter } = this.props;
        return (<TextField
            id={id}
            variant="search"
            value={value || ''}
            onChange={onSetFilter}
            label="Szukaj..." />);
    }

}

TextSearch.propTypes = {};