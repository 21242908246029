import {
    takeEvery,
    select,
    put
} from 'redux-saga/effects';

import * as ui from 'util/sagas/feedback';

import { push } from 'state/actions/route';
import { appMenuHide } from 'state/actions/ui';

const queryString = require('query-string');

const selectResourceRoute = (store, route) => {
    let path = route.split('/');
    let resource = store.resources[path[0]];
    if (!resource) resource = store.resources.default;
    if (path[1]) return resource.routes[path[1]];
    return resource.route;
};

const queryParams = (location, params) => {
    const prev = queryString.parse(location.search);
    const newQuery = {...prev, ...params};
    return queryString.stringify(newQuery);
};

function* handleRoute(action) {
    try {
        const { route, params, context } = action;
        const resourceRoute = yield select(selectResourceRoute, route);
        const location = yield select((store)=>store.router.location);
        const query = queryParams(location, action.query || {});
        const path = resourceRoute
            ? [resourceRoute, '?' + query].join('/')
            : '?' + query;
        yield put(push(path, {
            ...context,
            ...params,
            app: context.appPath || context.app
        }));
    } catch (e) {
        yield ui.error(e);
    }

}

function* handleRouteQuery(action) {
    try {
        const { params } = action;
        const location = yield select((store)=>store.router.location);
        const normalizedPath = location.pathname.replace(/^\/|\/$/g, '');
        const query = queryParams(location, params);
        const path = ['', normalizedPath, '?' + query].join('/');
        yield put(push(path));
    } catch (e) {

    }
}

function* handleChangeRoute(action) {
    try {
        yield put(appMenuHide());
    } catch (e) {

    }
}

export default function*() {
    yield takeEvery('ROUTE.PUSH', handleRoute);
    yield takeEvery('ROUTE.QUERY', handleRouteQuery);
    yield takeEvery('@@router/LOCATION_CHANGE', handleChangeRoute);
}