import React from 'react';
import {connect} from 'react-redux';
import { query } from 'state/actions/route';
import {icon} from 'util/icon';
import IconButton from "components/TooltipIconButton";
import Access from 'components/Access';

import { tabs } from './dev/index';

const mapStateToProps = (store, props) => {
    return {
        current: store.context.dev
    }
};

const mapDispatchToProps = {
    query
};



class Dev extends React.PureComponent {

    handleChangeTab = (id) => {
        this.props.query({dev:id});
    };

    render() {
        const { current } = this.props;
        return (<React.Fragment>
                { tabs.map((tab) => {
                    return (
                        <Access key={tab.id} scopes={tab.scope}>
                            <IconButton
                                icon={'dev.'+tab.id}
                                onClick={() => this.handleChangeTab(tab.id)}
                                tooltip={tab.id}
                                active={current === tab.id}
                            />
                        </Access>
                    );
                }) }
            </React.Fragment>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dev);