import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'components/fields/checkbox/CheckboxField';

export default function Check({id, isActive, onChange}) {

    const activate = onChange ? () => {
        onChange(id, !isActive)
    } : null;

    return (<Checkbox
        variant="checker"
        value={!!isActive}
        onChange={activate}
        label="Mark as completed"
    />);
}

Check.propTypes = {
    isActive: PropTypes.bool.isRequired,
    onActionRequest: PropTypes.func
};