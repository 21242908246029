import React, {Component} from 'react';
import {connect} from 'react-redux';

import PageImpl from 'ide/Page';
import { fetchPage, sectionDrag } from 'state/actions/ide';

import { styled } from 'state/selectors/styles';
import { selectResource } from "state/selectors/resources";
import EditableContext from 'ide/EditableContext';

import { changed } from 'util/changed';

const resolveLayout = (page, settings) => {
    if (!settings) return null;
    if (page.layout) return page.layout;
    let layout = null;

    if (settings.layouts) {
      settings.layouts.forEach((forType) => {
          if (forType.entry_type === page.type) {
              layout = forType.layout;
          }
      });
    }

    if (!layout && page.type === 'offers') return settings.default_layout_offers;
    return settings.default_layout;
};

const mapStateToProps = (store, props) => {
    const { id } = store.context;

    const pageNs = 'cms.' + store.context.view;
    const settings = store.data['cms.settings'];

    const page = selectResource(store, pageNs, id);

    let layout = page && settings
        ? resolveLayout(page, Object.values(settings)[0])
        : null;

    let layoutDef = layout
        ? selectResource(store, 'cms.layouts', layout)
        : {};

    let layoutId = layoutDef.title || layoutDef.description || 'default';
    let layoutStyle = layoutDef.style || 'page-layout.' + layoutId;
    let s = styled(store, props, 'page-layout', {style: layoutStyle});

    return {
        s,
        path: pageNs + '/' + id,
        className: 'layout-' + layoutId,
        context: store.context,
        //page: page,
        data: page.sections || [],
        resource: 'cms.sections',
        layout: layout,
        isPending: store.ui.pending['ide.page'] || !page,
        readOnly: props.readOnly || store.ui.ideZoomOut || store.context.q === 'attach-section'
    }
};

const mapDispatchToProps = {
    fetchPage,
    onDropSection: sectionDrag
};

class Page extends React.PureComponent {

    componentDidMount() {
        this.fetch();
    }

    componentDidUpdate(prevProps) {
        if (changed(['lang', 'id'], prevProps.context, this.props.context)) {
            window.scrollTo(0, 0);
            this.fetch();
        }
    }

    fetch = () => {
        let { id } = this.props.context;
        this.props.fetchPage(id);
    };

    render() {
        return (
            <EditableContext.Provider value={!this.props.readOnly}>
                <PageImpl
                    {...this.props}
                    onChangeOrder={this.props.onDropSection}
                    onDropInto={this.props.onDropSection}
                />
            </EditableContext.Provider>
        );
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps)
(Page);