import React from 'react';
import {connect} from 'react-redux';
import {push} from 'state/actions/route';
import {selectCurrentApp} from "state/selectors/access";

import AppMenuComponent from 'components/layout/AppMenu';

const projectTypes = {
    cms: 'console.services.website',
    apartments: 'console.services.apartments',
    db: 'console.services.db'
};

const mapStateToProps = (store, props) => {
    let app = selectCurrentApp(store);

    let ns = app.split('.')[1];

    let project = store.context.app === ns
        ? store.context.project
        : null;

    let projectType = projectTypes[ns];

    return {
        resourceNamespace: ns,
        project,
        requireProject: !!projectType,
        projectType: projectType,
        projectRoute: store.resources[app].route,
        view: project
            ? [projectType,'quick'].join('.')
            : [projectType,'cards'].join('.')
    }
};

const mapDispatchToProps = {
    push
};

class AppMenu extends React.PureComponent {

    handleOpenProject = (id) => {
        this.props.push(
            this.props.projectRoute, // + '/:view',
            {lang: 'pl', project: id}
        );
    };

    render() {
        return (<AppMenuComponent
            {...this.props}
            onOpenProject={this.handleOpenProject}
        />);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppMenu);