import React from 'react';
import {connect} from 'react-redux';
import {query} from 'state/actions/route';
import Card from '@material-ui/core/Card';
import Button from 'components/Button';
import Identity from 'v2/components/Identity';
import {selectResource} from "state/selectors/resources";

const subscribe = (id, target) => ({type: 'SUBSCRIBE', id, target});

const mapStateToProps = (store, props) => {
    let data = selectResource(store, props.resource, props.id);
    let identity = selectResource(store, 'db.identities', data.identity);
    let origin = selectResource(store, 'db.nodes', store.context.id);
    let subscribed = origin.subscribe || [];
    return {
        data,
        identity,
        origin,
        subscribed: subscribed.indexOf(data.id) > -1
    }
};

const mapDispatchToProps = {
    query,
    subscribe
};

class Node extends React.Component {

    handleClick = () => {
        this.props.query({node:this.props.id});
    };

    handleFollow = (evt) => {
        evt.stopPropagation();
        this.props.query({node: null});
        this.props.subscribe(this.props.id, this.props.origin.id);
    };

    render() {
        const { data, identity, subscribed, origin } = this.props;
        return (<Card onClick={this.handleClick} style={{ marginBottom: '1rem' }}>
            <div style={{ padding: '1rem', display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <div style={{ marginBottom: '0.4rem' }}>{data.name}</div>
                    <Identity id={data.identity} />
                </div>
                { subscribed
                    ? <Button onClick={this.handleFollow} color="default">Unfollow</Button>
                    : <Button onClick={this.handleFollow}>Add to {origin.name}</Button> }
            </div>
        </Card>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Node);