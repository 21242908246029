import React from 'react';
//import PropTypes from 'prop-types';

import MaterialButton from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

const iconStyle = {
    marginLeft: '0.4rem'
};

export default class Button extends React.PureComponent {

    render() {
        const { icon } = this.props;
        return (<MaterialButton
            {...this.props}
            variant={this.props.variant || 'contained'}
            size={this.props.size || 'large'}
            color={this.props.color || 'primary'}
        >
            {this.props.children || this.props.label}
            { icon ? <Icon
                color="inherit"
                style={iconStyle}
            >
                {icon}
            </Icon> : null}
        </MaterialButton>);
    }

}

Button.propTypes = {};