import React from 'react';
import PropTypes from 'prop-types';

export const Item = (props) => {
    let render = props.renderItem;
    return render(props);
};

export const ArrayIterator = (props, Item) => {
    const { data, ...other } = props;
    if (!Array.isArray(data)) return null;
    return data.map((id, index)=><Item
        key={id}
        id={id}
        index={index}
        {...other}
    />);
};

ArrayIterator.propTypes = {
    data: PropTypes.array.isRequired,
    listId: PropTypes.string.isRequired,
    onDrop: PropTypes.func,
    onChangeOrder: PropTypes.func,
    renderItem: PropTypes.func.isRequired
};

export const makeIterator = (Iterator) => (Item) => (props) => Iterator(props, Item);
export const DefaultArrayIterator = makeIterator(ArrayIterator)(Item);