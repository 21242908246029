import React from 'react';
import PropTypes from 'prop-types';
import { changed } from 'util/changed';

export default class FormState extends React.PureComponent {

    constructor(props) {
        super(props);
        
        this.state = {
            value: props.value || {},
            initialValue: props.value || {}
        }
    }
    
    componentDidMount() {
        this.syncValue();
    }
    
    componentDidUpdate(prevProps) {
        if (changed(['value'], this.props, prevProps)) {
            this.syncValue();
        }
    }
    
    syncValue = () => {
        this.setState({value: this.props.value});
    };
    
    handleChange = (id, val) => {
        let newState = {...this.state.value};
        newState[id] = val;
        this.setState({value: newState});
    };

    handleSubmit = () => {
        this.props.onChange(this.state.value);
        this.setState({value: this.state.initialValue});
    };
    
    render() {
        return this.props.children({
            ...this.state,
            onChange: this.handleChange,
            onSubmit: this.handleSubmit
        });
    }

}

FormState.propTypes = {
    children: PropTypes.func.isRequired
};