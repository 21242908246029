import React  from "react";

import fontAwesomeIcons from "resources/font-awesome.json";
import fhDefaultIcons from "resources/fh-default.json";
import muiIcons from "resources/material-ui.json";

import TextField from 'components/fields/text/TextField';
import { webfont_icon } from "ide/components/icon";


const libraries = {
  'fh-default': Object.entries(fhDefaultIcons.icons).map(([key, value]) => key),
  'font-awesome': Object.entries(fontAwesomeIcons.icons).map(([key, value]) => {
    return value.toLowerCase().split(" ").join("-");
  }),
  'material-ui': Object.entries(muiIcons.icons).map(([key, value]) => 'mui-'+key),
};

const listItemStyle = {
  width: "40px",
  float: "left",
  textAlign: "center",
  fontSize: "1.6rem",
  padding: "10px 0"
};

export default class IconPickerDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      icons: [],
      filteredIcons: [],
      hover: null
    };
  }

  componentWillMount() {
    let icons = [];
    Object.values(libraries).forEach(set=>{ icons = icons.concat(set); });
    this.setState({ icons, filteredIcons: icons });
  }

  handleSubmit = (name) => {
    this.props.onSubmit(name);
  };

  handleHover(name) {
    this.setState({
      ...this.state,
      hover: name
    });
  }

  filter = (id, value) => {
    let filteredList = this.state.icons;
    this.setState({search: value});
    filteredList = filteredList.filter(item => {
      return item.toLowerCase().search(value.toLowerCase()) !== -1;
    });
    this.setState({ filteredIcons: filteredList });
  };

  render() {
    const listItems = this.state.filteredIcons.map((name, key) => (
      <div
        key={key}
        style={listItemStyle}
        onClick={()=>this.handleSubmit(name)}
        onMouseOver={this.handleHover.bind(this, name)}
      >
        {webfont_icon(name)}
      </div>
    ));
    return (
      <div>
        <div style={{ display: "flex", alignItems: "center", padding: '1rem' }}>
          <TextField label="Szukaj" onChange={this.filter} value={this.state.search} />
          <div
            style={{
              flexGrow: 1,
              fontWeight: "bold",
              fontSize: "1.2rem",
              textAlign: "right",
              flexBasis: '300px'
            }}
          >
            {this.state.hover ? this.state.hover : null}
          </div>
        </div>
        <div style={{ maxHeight: "500px", overflowY: "auto", padding: '1rem' }}>
          {listItems}
        </div>
      </div>
    );
  }
}
