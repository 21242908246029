import React, { Component } from "react";
import { connect } from "react-redux";
//import NestedList from 'containers/NestedList';
import { List } from "list";
import { ResourceListItem } from "components/list/ResourceListItem";
import ListWrapper from "components/list/ListWrapper";

import { query } from "state/actions/route";

import {
  draftsPathReorderIndex,
  draftsPathReorder,
  draftsPathTree,
  draftsPathAppend,
  draftsPathDelete,
  draftsPathCreate,
} from "state/actions";

import { selectListViewConfig } from "state/selectors/list";

import { selectPath, index } from "state/selectors/resources";

const mapStateToProps = (store, props) => {
  const current = store.context.item ? store.context.item.split(".") : null;

  const items = index(selectPath(store, props.path.split("/"))) || {};
  const data = Object.keys(items);

  const resource =
    props.id === "components"
      ? "cms.components"
      : props.resource || "cms.items";

  return {
    config: selectListViewConfig(store, { resource }),
    resource,
    data,
    items,
    path: props.path,
    isEmpty: !data.length,
    selectedItem: current && current[0] === props.id ? current[1] : null,
  };
};

const mapDispatchToProps = {
  query,
  reorder: draftsPathReorder,
  create: draftsPathAppend,
  updateTree: draftsPathTree,
  detach: draftsPathDelete,
};

class Collection extends Component {
  handleEditItem = (item) => {
    if (this.props.resource === "cms.components") {
      this.props.query({ cp: item });
      return;
    }
    this.props.query({ item: this.props.id + "." + item });
  };

  handleUpdateOrder = (data) => {
    let newOrdered = [];
    data.data.forEach((id) => {
      newOrdered.push(this.props.items[id]);
    });
    this.props.onChange(this.props.id, newOrdered);
  };

  handleAction = (action, id) => {
    if (action === "detach") {
      let newValue = Object.values(this.props.items);
      newValue.splice(this.props.data.indexOf(id), 1);
      this.props.onChange(this.props.id, newValue);
    }
  };

  handleChangeItem = (id, data) => {
    let items = { ...this.props.items };
    items[id] = { ...items[id], ...data };
    this.props.onChange(this.props.id, Object.values(items));
  };

  handleCreate = () => {
    this.props.create(this.props.path.split("/"), {});
  };

  render() {
    let asideActions = this.props.asideActions ? (
      this.props.asideActions
    ) : (
      <div onClick={this.handleCreate}>action</div>
    );

    return (
      <React.Fragment>
        <List
          id={this.props.path}
          resource={this.props.resource}
          data={this.props.data}
          variant="sortable"
          onChangeOrder={this.handleUpdateOrder}
          renderList={(props) => (
            <ListWrapper
              layout={this.props.layout}
              wrapperClass="list-collection"
              primary
              title={this.props.label}
              asideActions={asideActions}
              isEmpty={this.props.isEmpty}
            >
              {props.items}
            </ListWrapper>
          )}
          renderItem={(props) => {
            return (
              <ResourceListItem
                {...props}
                config={this.props.config}
                resource={this.props.resource}
                data={this.props.items[props.id]}
                onRequestAction={this.handleAction}
                onChange={this.handleChangeItem}
                onClick={this.handleEditItem}
              />
            );
          }}
        />
      </React.Fragment>
    );
  }
}

export const connectCollection = (CollectionComponent) =>
  connect(mapStateToProps, mapDispatchToProps)(CollectionComponent);

export default connectCollection(Collection);
