import React from "react";
import PropTypes from "prop-types";
import FileState from "containers/File";
import Preloader from "components/Preloader";
import FilePreview from "components/media/FilePreview";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "components/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { CopyToClipboard } from "react-copy-to-clipboard";

export const sizes = {
  xs: 48,
  sm: 64,
  md: 96,
  lg: 120,
  xlg: 160,
};

export const fileLabel = (name) => {
  if (!name) return "";
  let parts = name.split("/");
  if (!parts) return "";
  return parts[parts.length - 1];
};

function FileMenu(props) {
  const { id, file, fileType, onShowNotification, onDeleteFile, ...other } =
    props;

  const url =
    fileType === "document" ? `/download/${file}` : `/download?file=${file}`;

  return (
    <Menu {...other}>
      <CopyToClipboard
        text={url}
        onCopy={() => onShowNotification("Skopiowano", "info")}
      >
        <MenuItem>Skopiuj link do schowka</MenuItem>
      </CopyToClipboard>
      <MenuItem onClick={() => onDeleteFile(id)}>Usuń</MenuItem>
    </Menu>
  );
}

function makeUrl(data) {
  const fileType = data.type;
  const file = data.file;
  return fileType === "document"
    ? `/download/${file}`
    : `/download?file=${file}`;
}

function RenderFile(props) {
  const {
    selected,
    menu,
    anchorEl,
    onShowMenu,
    onCloseMenu,
    onDeleteFile,
    onShowNotification,
  } = props;
  //const { menu, anchorEl } = this.state;
  const sizePreset = props.size || "xs";
  const size = sizes[sizePreset];

  return (
    <div
      style={props.style}
      className="file"
      onClick={() => props.onClick(props.data)}
    >
      <div style={{ width: "100%", height: size + "px", position: "relative" }}>
        {props.isPending && (
          <Preloader visible={props.isPending} progress={props.progress} />
        )}
        <FilePreview file={props.data.file} type={props.data.type} />
      </div>
      {sizePreset !== "xs" ? (
        <div className={"file-footer"}>
          <div
            className="file-label"
            // onContextMenu={() => alert(makeUrl(props.data))}
          >
            {props.progress}{" "}
            <Tooltip title={props.data.file}>
              <span>{fileLabel(props.data.file)}</span>
            </Tooltip>
            {/*<span>{fileLabel(props.data.file)}</span>*/}
          </div>
          <div>
            <FileMenu
              open={menu}
              anchorEl={anchorEl}
              onClose={onCloseMenu}
              onClick={onCloseMenu}
              onDeleteFile={onDeleteFile}
              onShowNotification={onShowNotification}
              id={props.data.id}
              file={props.data.file}
              fileType={props.data.type}
            />
            <IconButton
              size={"small"}
              icon={"more_vert"}
              onClick={menu ? onCloseMenu : onShowMenu}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default class File extends React.PureComponent {
  render() {
    return <FileState {...this.props} render={RenderFile} />;
  }
}

File.propTypes = {
  onClick: PropTypes.func,
};
