import React from 'react';
import PropTypes from 'prop-types';
import FormField from 'containers/FormField';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Field, {RenderField} from 'v2/Field';
import TextField from 'components/fields/text/TextField';
import Geolocation from 'components/fields/geolocation/Geolocation';

export default class AddressField extends React.Component {

    field = (field) => {
        return (<div><Field {...this.props} fieldName={field}>{RenderField}</Field></div>);
        const { id, schemaPath, fields, parentPath } = this.props;
        if (!fields[field]) return null;
        let fieldSchemaPath = schemaPath.split('/').concat([id, 'fields']).join('/');
        return (<FormField
            parentPath={parentPath}
            schemaPath={fieldSchemaPath}
            id={field}

        />);
    };

    handleChange = (id, value) => {
        let newValue = {...this.props.value};
        newValue[id] = value;
        this.props.onChange(this.props.id, newValue);
    };

    render() {
        const { onChange } = this.props;
        let value = this.props.value || {};
        return <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ flex: 1, display: 'flex' }}>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                            <TextField id="street" onChange={this.handleChange} label="Street" value={value.street}/>
                        </div>
                        <div style={{ width: '120px', marginLeft: '1rem' }}>
                            <TextField id="street_no" onChange={this.handleChange} label="Number" value={value.street_no}/>
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: '1rem' }}>
                        <div style={{ width: '200px' }}>
                            <TextField id="zip" onChange={this.handleChange} label="Postal code" value={value.zip}/>
                        </div>
                        <div style={{ marginLeft: '1rem', flex: 1 }}>
                            <TextField id="city" onChange={this.handleChange} label="City" value={value.city}/>
                        </div>
                    </div>
                </div>
                <div style={{ marginLeft: '1rem', display: 'flex' }}>
                    <Geolocation id="geolocation" onChange={this.handleChange} label="Geolocation" value={value.geolocation} />
                </div>
            </div>
        </div>
    }

}

AddressField.propTypes = {};