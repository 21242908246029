import React from 'react';
import {connect} from 'react-redux';

import { listFilter, listFilterSet } from 'state/actions';
import { selectFilteredList } from 'state/selectors/resources';
import { changed } from 'util/changed';

import FontBrowserImpl from 'components/fields/theme/FontPicker/FontBrowser';

const mapStateToProps = (store, props) => {
    const filterId = 'cms.fonts';
    const filters = store.filter[filterId] || {};
    const source = filters.source || 'google';
    const listId = [filterId,source].join('.');
    return {
        filters,
        filterId,
        listId: listId,
        source,
        data: selectFilteredList(store, listId, filterId),
        initialized: !!store.data[listId]
    }
};

const mapDispatchToProps = {
    fetch: listFilter,
    onSetFilter: listFilterSet
};

class FontBrowser extends React.PureComponent {

    fetch = () => {
        this.props.fetch(
            this.props.listId,
            this.props.listId,
            this.props.filterId,
            {source: this.props.source}
        );
    };

    handleSetFilter = (id, val) => {
        let newFilter = {...this.props.filters};
        newFilter[id] = val;
        this.props.onSetFilter(this.props.filterId, newFilter);
    };

    handleChangeFont = (font, family) => {
        let newValue = {...this.props.value};
        newValue[font].family = family;
        newValue[font].source = this.props.source;
        this.props.onChange(this.props.id, newValue);
    };
    
    handleChangeField = (font, field, value) => {
        let newValue = {...this.props.value};
        newValue[font][field] = value;
        this.props.onChange(this.props.id, newValue);
    };

    componentDidMount() {
        this.fetch();
    }

    componentDidUpdate(prevProps) {
        if (changed(['listId'], prevProps, this.props)) {
            this.fetch();
        }
    }

    render() {
        return (<FontBrowserImpl
            {...this.props}
            onSetFilter={this.handleSetFilter}
            onChangeFont={this.handleChangeFont}
            onChangeField={this.handleChangeField}
        />);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FontBrowser);