import { createReducer } from 'util/redux/create-reducer';

const addToClipboard = (state, resource, data, project) => {
    if (!state[resource]) state[resource] = [];
    state[resource].push({
        resource,
        data,
        project
    });
    return {...state};
};

const actions = {
    'CLIPBOARD.COPY': (state, {resource, data, context}) => addToClipboard(state, resource, data, context.project)
};

export default createReducer({}, actions);