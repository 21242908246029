export const fetchPage = (id) => ({
   type: "IDE.PAGE",
   id
});

export const fetchPageSuccess = (data) => ({
   type: "IDE.PAGE.SUCCESS",
   data
});

export const bootstrap = () => ({
   type: "IDE.BOOTSTRAP"
});

export const tearDown = () => ({
   type: "IDE.TEAR_DOWN"
});

export const refreshLessStyles = (less, preset, device) => ({
   type: "IDE.REFRESH_STYLES",
   payload: {
      less,
      preset,
      device
   }
});

export const refreshLessStylesFromState = () => ({
   type: 'IDE.REFRESH_STYLES_STATE'
});

export const stylesReady = () => ({
   type: "IDE.STYLES_READY",
   payload: {}
});

export const fetchComponentData = ({id, entry_type, data_source, filtering_mode, limit, tags, defined_order}, list) => ({
   type: "IDE.FETCH_DATA",
   id,
   list,
   query: {
      entry_type,
      filtering_mode,
      limit,
      tags,
      data_source,
      defined_order
   }
});

export const fetchComponentDataSuccess = (id, data) => ({
   type: "IDE.FETCH_DATA.SUCCESS",
   id,
   data
});

export const ideSaveAll = () => ({
   type: "IDE.SAVE_ALL"
});

export const ideToggleZoomOut = () => ({type: 'IDE.TOGGLE_ZOOM_OUT'});

export const sectionClick = (id, tplId, componentId, keys) => ({
   type: 'IDE.SECTION.CLICK',
   id,
   tplId,
   componentId,
   keys
});

export const sectionDrag = ({data, copy, effect}) => ({
   type: 'IDE.SECTION.DRAG', order: data, copy, effect
});

export const formLayoutDrop = (dropResult, fieldResource, path) => ({
   type: 'IDE.FORM_LAYOUT_DROP',
   result: dropResult,
   fieldResource,
   path
});
