import React from 'react';
import {connect} from 'react-redux';
import {setUiFlag} from 'state/actions/ui';

import Popover from 'components/Popover';
import IconButton from 'components/TooltipIconButton';
import TextField from 'components/fields/text/TextField';

const mapStateToProps = (store, props) => {
    return {
        mappingMode: store.ui.flags.formMappingMode
    }
};

const mapDispatchToProps = {
    onSetUiFlag: setUiFlag
};

function PopoverContent(props) {
    const { textFieldProps, onToggleMapping, onClose } = props;
    const handleToggleMode = (evt) => {
        onToggleMapping(evt);
        onClose(evt);
    };
    return <div>
        <div className="popover-title">
            {props.label}
        </div>
        <div className="popover-content">
            <TextField {...textFieldProps}/>
            <button onClick={handleToggleMode}>assign mapping</button>
        </div>
    </div>;
}

class DataMapping extends React.Component {

    handleMappingMode = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        this.props.onSetUiFlag(
            'formMappingMode',
            !this.props.mappingMode
        );
    };

    render() {
        const textFieldProps = {
            id: this.props.id,
            label: this.props.label,
            value: this.props.value,
            onChange: this.props.onChange
        };
        return (<Popover
            trigger={(props)=><IconButton
                {...props}
                onContextMenu={this.handleMappingMode}
                active={this.props.mappingMode}
                badge={!!this.props.value}
                icon="device_hub"
            />}
            content={(props)=><PopoverContent
                label={this.props.label}
                onClose={props.onClose}
                textFieldProps={textFieldProps}
                onToggleMapping={this.handleMappingMode}
            />}
        />);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DataMapping);