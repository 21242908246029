import { appMenuToggle } from 'state/actions/ui';
import { query, push } from 'state/actions/route';

export default {
    keyMap: {
        appMenu: "ctrl+e",
        websites: "ctrl+shift+a",
        save: "ctrl+s",
        listActions: "ctrl+alt+n"
    },
    handlers: {
        appMenu: appMenuToggle,
        websites: () => push('/cms/pl'),
        save: () => ({type: 'VOID'}),
        listActions: () => query({q: 'list-actions'})
    },
    global: true
}