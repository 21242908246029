import React from 'react';
import {connect} from 'react-redux';

import App from 'views/layouts/App';
import ResourcesNav from 'components/layout/SideNav';
import AppContext from 'containers/AppContext';

import MediaLibrary from 'containers/dialogs/MediaLibrary';

const mapStateToProps = (store, props) => {
    return {
        app: store.context.app,
        lang: store.context.lang,
        appKey: [store.context.lang,store.context.project].join('.')
    }
};

const mapDispatchToProps = {};

class MediaLibraryView extends React.PureComponent {

    constructor(props) {
        super(props);
        this.appWatch = ["project", "lang"];
    }

    render() {
        const { app, appKey, lang } = this.props;
        return (<AppContext key={appKey} app={app} watch={this.appWatch}>
            <App app={app} key={lang}>
                <div className="app-columns">
                    <div className="modules-sidebar">
                        <ResourcesNav />
                    </div>
                    <div style={{ flex: 1 }}>
                        <MediaLibrary/>
                    </div>
                </div>
            </App>
        </AppContext>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MediaLibraryView);