import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/TooltipIconButton';

export function renderAddress(value) {
    if (!value) return null;
    let lines = [];
    lines.push([value.street, value.street_no].join(' '));
    if (value.city) {
        lines.push([value.zip, value.city].join(' '));
    }
    return lines.join(', ');
}

export default class Address extends React.PureComponent {

    handleNavigate = () => {
        let geo = this.props.value.geolocation;
        let url = `https://www.google.com/maps/search/?api=1&query=${geo.lat},${geo.lng}`;
        window.open(url);
    };

    render() {
        const { value, listView } = this.props;
        if (!value) return null;
        const geo = value.geolocation;
        const hasLocation = geo && geo.lat;
        return (<div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{ lineHeight: listView ? 'inherit' : '2.6em' }}>{renderAddress(value)}</div>
            {hasLocation && !listView ? <div style={{ display: 'flex' }}>
                <IconButton icon={'directions'} onClick={this.handleNavigate} />
            </div> : null}
        </div>);
    }

}

Address.propTypes = {};