import React from 'react';

import Preloader from "../Preloader";
import List from "@material-ui/core/List";
import EmptyState from 'components/EmptyState';
import Pagination, {ScrollPagination} from 'containers/list/Pagination';
import ListFilterArea from 'components/list/ListFilterArea';
import MuiTable from '@material-ui/core/Table';
import MuiTableBody from '@material-ui/core/TableBody';
import GraphCanvas from 'v2/GraphCanvas';
import Filters from 'components/list/MobileFilter';
import ListMeta from 'components/list/ListMeta';

const listStyle = { flex: 1, padding: 0, height: '100%' };

const ListLayout = (props) => {
    const { type, classes, domRef } = props;
    if (props === 'virtualized') return props.children;
    return <div ref={domRef} className={classes.outer}>
        { type === 'list' ? <List style={listStyle}>{props.children}</List> : null }
        { type === 'grid' ? <div className={classes.list}>{props.children}</div> : null }
        { type === 'table' ? <MuiTable>
            {props.listHeader}
            <MuiTableBody>{props.children}</MuiTableBody>
            {props.listFooter}
        </MuiTable> : null }
        { type === 'tree' ? props.children : null }
        { type === 'graph' ? <GraphCanvas>{props.children}</GraphCanvas> : null }
        { type === 'stream' ? <ScrollPagination {...props}>{props.children}</ScrollPagination> : null }
    </div>
};

export default class ListWrapper extends React.PureComponent {

    wrapDropArea = (list) => {
        const { connectDropTarget } = this.props;
        if (!connectDropTarget) return list;
        return <React.Fragment>
            {connectDropTarget(list)}
        </React.Fragment>
    };

    render() {
        const {
            title,
            isPending,
            primary,
            secondary,
            sidebar,
            filterId,
            resource,
            layout,
            asideActions,
            isEmpty,
            wrapperClass,
            cols,
            listClassName,
            filterLayout,
            domRef,
            fetch,
            connectDropArea,
            displayOptions
        } = this.props;

        let listId = this.props.listId || this.props.id;

        let wrapperClassNames = "list-wrapper " + wrapperClass;
        let listClass = "resource-list";

        let role = null;
        if (primary) role = "primary";
        if (secondary) role = "secondary";
        if (sidebar) role = "sidebar";

        wrapperClassNames += " list-wrapper--" + layout;

        if (role) {
            wrapperClassNames += " list-wrapper--" + role;
            listClass += " resource-list--" + role;
        }

        let listOuterClass = isEmpty ? 'list-scroll hide' : 'list-scroll';

        let listLayoutClass = layout === 'grid'
            ? 'resource-grid ' + listClassName || ''
            : 'resource-list';

        if (resource) listLayoutClass += ' ' + resource.replace(/\./g, '-');

        if (cols) listLayoutClass += ' cols-' + cols;

        wrapperClassNames += ' filter-layout--' + (filterLayout || 'top');

        const items = this.props.items || this.props.children;

        const classes = {
            outer: listOuterClass,
            list: listLayoutClass
        };

        const sortable = this.props.variant === 'sortable';

        const list = <div className={listClass}>
            <Preloader visible={isPending} />
            {isEmpty && !isPending ? (<div className="list-scroll list-empty-state">
                <EmptyState title="Nic jeszcze tu nie ma" />
            </div>) : null }
            <ListLayout
                domRef={domRef}
                type={layout || 'list'}
                classes={classes}
                listHeader={this.props.listHeader}
                listFooter={this.props.listFooter}
                sortable={sortable}
                listId={listId}
                filterId={filterId}
                resource={resource}
            >
                <ListMeta list={listId} />
                {items}
            </ListLayout>
            {asideActions ? <div className="list-aside">
                {asideActions}
            </div> : null}
        </div>;

        return (<div className={wrapperClassNames}>
            { filterLayout !== 'none' ? <ListFilterArea
                title={title}
                resource={resource}
                listId={listId}
                filterId={filterId}
                onReloadList={fetch}
                displayOptions={displayOptions}
                context={this.props.context}
                heading={this.props.heading}
            /> : null }
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden' }}>
                { filterId ? <Filters
                    resource={resource}
                    filterId={filterId}
                    listId={listId}
                    context={this.props.context}
                /> : null }
                {this.wrapDropArea(list)}
                <div style={{ paddingRight: '90px' }}>
                    <Pagination resource={resource} listId={listId} filterId={filterId} />
                </div>
            </div>
        </div>);
    }

}

export const RenderListWrapper = (props) => {
    return <ListWrapper {...props} />
};