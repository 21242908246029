import React from 'react';
//import PropTypes from 'prop-types';

import Popover from 'components/Popover'
import {SketchPicker} from "react-color";

const anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left'
};

export default class ColorPicker extends React.PureComponent {

    handleChange = (value) => {
        this.props.onChange(this.props.id, value.hex);
    };

    render() {
        const { id, value } = this.props;
        let className = "color-field " + id;
        if (!value) className += ' color-field--empty';
        return (
            <Popover
                anchorOrigin={anchorOrigin}
                trigger={(props)=><div
                    {...props}
                    className={className}
                    style={{ backgroundColor: value ? value : null }}
                >
                    <div className="color-filed__id">{id.split('_')[0]}</div>
                    <div className="color-field__value">{value}</div>
                </div>}
                content={(props)=>
                    <SketchPicker
                        color={value || "#ffffff"}
                        onChange={this.handleChange}/>}
            />
        );
    }

}

ColorPicker.propTypes = {};