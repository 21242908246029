import React from 'react';
import {connect} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import pathToRegexp from "path-to-regexp";
import {push} from 'connected-react-router';

const mapStateToProps = (store, props) => {
    const toPath = props.to.indexOf(':') > -1
        ? pathToRegexp.compile(props.to)
        : (params)=>props.to;
    let link = null;
    try {
        link = store.context ? toPath(store.context) : null;
    } catch (e) {
        link = props.to;
    }
    return {
        to: link
    }
};

const mapDispatchToProps = {
    push
};

class Link extends React.PureComponent {

    handleLink = () => {
        this.props.push(this.props.to);
    };

    render() {
        const { to, render } = this.props;
        if (render) return render({onClick: this.handleLink});
        return (<RouterLink to={to}>
            {this.props.children}
        </RouterLink>);
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(Link);