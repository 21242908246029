import React from 'react';
import {connect} from 'react-redux';
import _ from "lodash";
import TabsNav from "components/form/TabsNav";
import FormField from 'containers/FormField';

import { query } from 'state/actions/route';
import fieldTypes from 'components/fields';

const mapStateToProps = (store, props) => {
    let schemaPath = props.schemaPath;
    let schema = _.get(store.schema, schemaPath.split('/'));
    if (!schema || !schema.fields) {
        return {};
        //throw new Error(`Unable to resolve schema for ${schemaPath}`);
    }
    const tabs = {
        default: {
            id: 'default',
            label: 'Default',
            fields: {}
        }
    };

    Object.keys(schema.fields).forEach((key)=>{
        if (schema.fields[key].type === 'tab') {
            if (!tabs[key]) tabs[key] = {
                id: key,
                label: schema.fields[key].label,
                fields: schema.fields[key].fields
            }
        } else {
            tabs.default.fields[key] = schema.fields[key];
        }
    });

    const tabsWithFields = {};

    Object.keys(tabs).forEach((tab) => {
        if (Object.keys(tabs[tab].fields).length) {
            tabsWithFields[tab] = tabs[tab];
        }
    });

    const current = store.context.t && tabs[store.context.t]
        ? store.context.t
        : Object.keys(tabsWithFields)[0];

    const tabSchemaPath = current === 'default'
        ? schemaPath + '/fields'
        : schemaPath + '/fields/' + current + '/fields';

    return {
        current,
        schemaPath: tabSchemaPath,
        parentSchemaPath: schemaPath,
        tabs: Object.values(tabsWithFields),
        fields: tabsWithFields[current].fields ? Object.values(tabsWithFields[current].fields) : [],
        tabComponent: fieldTypes['tab.'+current]
    }
};

const mapDispatchToProps = {
    query
};

class FormTabs extends React.PureComponent {

    handleChangeTab = (id) => {
        this.props.query({t: id});
    };

    render() {
        const { current, tabs, fields, schemaPath, path } = this.props;
        const TabComponent = this.props.tabComponent || null;

        if (!tabs) return null;

        return (<div className="form__tabs" style={{ overflow: 'hidden' }}>

            { tabs.length > 1 ? <div>
                <TabsNav
                    tabs={tabs}
                    current={current}
                    onChange={this.handleChangeTab}
                />
            </div> : null }

            <div className="form__body">
                { TabComponent ?
                    <TabComponent {...this.props} id={current} />
                : null }
                { fields.map((field) => {
                    return (<FormField
                        parentPath={path}
                        schemaPath={schemaPath}
                        id={field.id}
                        key={field.id}
                    />);
                } )}
            </div>
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormTabs);