import { createReducer } from "util/redux/create-reducer";
import resources from "config/resources";
import { deepMerge } from 'util/deepMerge';

const systemResources = [
    'cms.entries-base',
    'cms.data',
    'cms.viewconfig',
    'cms.presets',
    'cms.document',
    'cms.blocks',
    'console.data',
    'apartments.data',
    'db.data',
    'db.fields'
];

const expandSubtypes = [
    'cms.entries',
    'console.services'
];

const crud = {
    edit: {
        type: 'route',
        label: 'Edytuj'
    },
    "activate": {
        "type": "action",
        "action": "RESOURCE.TOGGLE",
        "args": {
            "property": "active"
        },
        "label": "Aktywuj"
    },
    "delete": {
        "type": "action",
        "action": "RESOURCE.DELETE",
        "label": "Usuń"
    }
};

const makeDefaultResource = (app, ns, state, prevState, schema, resource) => {
    if (schema[resource].type === 'view') return state;
    let key = ns + '.' + resource;
    let parts = key.split('.');
    let appRoute = prevState['apps.'+app].base;
    let route = appRoute + '/' + parts.slice(1).join('.');
    let prevSchema = prevState[key] || {};
    let newSchema = {
        name: schema[resource].label || resource,
        route: route,
        icon: schema[resource].icon,
        routes: {
            edit: route + '/:id'
        },
        primaryAction: 'edit',
        actions: {
            ...crud
        }
    };
    state[key] = deepMerge({}, newSchema, prevSchema);
    if (key === 'cms.sections') return state;
    
    state[key] = {
        ...state[key],
        actions: {
            ...prevSchema.actions,
            ...newSchema.actions
        }
    };
    return state;
};

const makeSubtypeResources = (id, ns, state, prevState, schema, resource) => {
    let types = schema[resource].types;
    Object.keys(types).forEach((type) => {
        state = makeDefaultResource(
            id,
            ns,
            state,
            prevState,
            types,
            type
        );
    });
    return state;
};

const updateResourcesFromSchema = (prevState, id, schema) => {
    let state = {...prevState};
    Object.keys(schema).forEach((resource) => {
        let key = id + '.' + resource;
        if (systemResources.indexOf(key) > -1) return;
        if (expandSubtypes.indexOf(key) > -1) {
            state = makeSubtypeResources(
                id,
                key,
                state,
                prevState,
                schema,
                resource
            )
        } else {
            state = makeDefaultResource(
                id,
                id,
                state,
                prevState,
                schema,
                resource
            )
        }

    });
    return state;
};

const actions = {
    'APP.SCHEMA.SUCCESS': (state, {id, schema}) =>
        updateResourcesFromSchema(state, id, schema)
};

export default createReducer(resources, actions);