import {findDOMNode} from "react-dom";
import {DragSource, DropTarget} from "react-dnd";
import React from "react";
import {getEmptyImage} from "react-dnd-html5-backend";
import PropTypes from "prop-types";
const moveMonitor = {y: 0};

export const collectDragActors = (props, monitor) => {
  return {
    drag: monitor ? monitor.getItem() : null,
    hover: {
      id: props.id,
      index: props.index,
      type: props.type,
      origin: props.origin,
      level: props.level
    }
  }
};

export const source = {
  beginDrag: (props,monitor,component) => {
    return collectDragActors(props).hover
  },
  endDrag: (props,monitor) => {
    const { onItemDragEnd } = props;
    if (onItemDragEnd) {
      let drop = monitor.getDropResult();
      if (!drop) return;
      onItemDragEnd({
        drag: collectDragActors(props, monitor).hover,
        effect: drop.dropEffect
      });
    }
  }
};

export const collectSource = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
});

export const target = {
  hover: (props, monitor, component) => {
    const { onSort, onHover } = props;
    if (onSort) {
      let status = sortingMonitor(props, monitor, component);
      if (status) onSort(status);
    }
    if (onHover) {
      onHover(collectDragActors(props, monitor));
    }
  },
  drop: (props, monitor, component) => {
    const { onSort, onSortEnd, onDrop } = props;
    if (onSort && onSortEnd) {
      onSortEnd(collectDragActors(props, monitor));
    }
    if (onDrop) {
      //if (monitor.didDrop()) return;
      onDrop(collectDragActors(props, monitor));
    }
  }
};

export const collectTarget = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver({ shallow: false }),
  canDrop: monitor.canDrop(),
  overItem: monitor.getItem()
});

const isDraggable = (props) => {
  return true;
  return props.draggable || props.sortable;
};

const isDroppable = (props) => {
  return true;
  return props.droppable || props.sortable;
};

export const applyDndCapabilities = (Component, props) => {
  const sourceArgs = ['test', source, collectSource];
  const targetArgs = ['test', target, collectTarget];

  let WithDnd = Component;
  if (isDroppable(props)) WithDnd = DropTarget(...targetArgs)(WithDnd);
  if (isDraggable(props)) WithDnd = DragSource(...sourceArgs)(WithDnd);

  return WithDnd;
};

export class DragDecorator extends React.PureComponent {
  componentDidMount() {
    // Use empty image as a drag preview so browsers don't draw it
    // and we can draw whatever we want on the custom drag layer instead.
    this.props.connectDragPreview(getEmptyImage(), {
      // IE fallback: specify that we'd rather screenshot the node
      // when it already knows it's being dragged so we can hide it with CSS.
      captureDraggingState: true,
    })
  }
  connectDnd = (component) => {
    const { connectDragSource, connectDropTarget, ...other } = this.props;
    let connected = component;
    if (connectDropTarget) connected = connectDropTarget(connected);
    if (connectDragSource) connected = connectDragSource(connected);
    return connected;
  };
  render() {
    const {
      connectDragSource,
      connectDropTarget,
      ...other
    } = this.props;

    const render = this.props.children;
    if (!render) return null;

    const props = {
      ...other,
      connectDnd: this.connectDnd,
      connectDragHandle: connectDragSource
    };
    return render({...props});
  }
}

/**
 * This could enable dnd only on components
 * providing relevant handlers but it doesn't work
 */

/*const DndConnector = (props) => {
    const Draggable = applyDndCapabilities(DragDecorator, props);
    return <Draggable {...props} />;
};*/

/*const dndConnector = () => {
    return (Component) => {
        return (props) => {
            const WithDnd = applyDndCapabilities(DragDecorator, props);
            return (<WithDnd {...props} />);
        };
    }
};*/

/**
 * Instead we use this:
 */
export const DndConnector = applyDndCapabilities(DragDecorator, {});

DndConnector.propTypes = {
  id: PropTypes.string.isRequired,
  origin: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired
};

export class DropArea extends React.PureComponent {
  componentWillReceiveProps(nextProps) {
    if (!nextProps.isOver && this.props.isOver && this.props.onHoverOut) {
      this.props.onHoverOut({drag: this.props.overItem});
    }
  }
  render() {
    return this.props.children(this.props);
  }
}

export const DropAreaConnector = DropTarget('test', target, collectTarget)(DropArea);

export function sortingMonitor(props, monitor, component) {
  const { drag, hover } = collectDragActors(props, monitor);

  const dragIndex = drag.index;
  const hoverIndex = hover.index;
  const dragId = drag.id;
  const hoverId = hover.id;

  // Don't replace items with themselves
  if (dragId === hoverId) {
    return false;
  }

  if (drag.origin !== hover.origin) {
    //return false;
    //console.log(drag, hover, props);
  }

  // Determine rectangle on screen
  const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

  // Get vertical middle
  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
  const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

  // Determine mouse position
  const clientOffset = monitor.getClientOffset();

  // Get pixels to the top
  const hoverClientY = clientOffset.y; // - hoverBoundingRect.top;
  const hoverClientX = clientOffset.x - hoverBoundingRect.left;

  // Only perform the move when the mouse has crossed half of the items height
  // When dragging downwards, only move when the cursor is below 50%
  // When dragging upwards, only move when the cursor is above 50%

  // Dragging downwards
  if (
      (dragIndex < hoverIndex && hoverClientX > hoverMiddleX) &&
      (dragIndex < hoverIndex && hoverClientY < hoverBoundingRect.bottom-20)
  ) {
    return false;
  }

  // Dragging upwards
  if (
      (dragIndex >= hoverIndex && hoverClientX > hoverMiddleX) &&
      (dragIndex >= hoverIndex && hoverClientY > hoverBoundingRect.top+20)
  ) {
    return false;
  }

  //console.log(dragIndex, hoverIndex, clientOffset, hoverBoundingRect);

  let moveDelta = Math.abs(moveMonitor.y - clientOffset.y);

  if (moveDelta < 20) {
    //return false;
  }
  moveMonitor.y = clientOffset.y;

  //console.log('reorder',dragIndex,hoverIndex,hoverClientX,hoverClientY);

  return {drag, hover};
}