import React from 'react';
import {connect} from 'react-redux';
import {fetchUrlInfo} from 'state/actions/cms';
import {selectResource} from 'state/selectors/resources';
import IconButton from 'components/TooltipIconButton';
import Card from '@material-ui/core/Card';

const mapStateToProps = (store, props) => {
    return {
        data: selectResource(store, 'db.links', props.url)
    }
};

const mapDispatchToProps = {
    fetch: fetchUrlInfo
};

class LinkCard extends React.Component {

    componentDidMount() {
        if (!this.props.data.id) this.fetch();
    }

    componentDidUpdate(prevProps) {
        if (!this.props.data.id) this.fetch();
    }

    fetch = () => {
        this.props.fetch(this.props.url);
    };

    render() {
        const { readOnly, data } = this.props;
        const { id, ogImage, title, description } = data;
        return (<Card className="link-card">
            <div className="link-image">
                <a href={id} target="_blank">
                    <img src={ogImage} alt={title} />
                </a>
            </div>
            <div className="link-info">
                <div className="link-title">
                    <a href={id} target="_blank">{title}</a>
                </div>
                <div className="link-description">
                    {description}
                </div>
            </div>
            { !readOnly ? <div className="link-actions">
                <IconButton icon="clear" onClick={this.props.onClear} />
            </div> : null }
        </Card>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LinkCard);