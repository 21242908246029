import React from 'react';
import {connect} from 'react-redux';
import TextField from 'components/fields/text/TextField';

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

class PropertyMap extends React.Component {

    change = (id, value) => {
        const { onChange } = this.props;
        const prevValue = this.props.value || {};
        const nextValue = {...prevValue};
        nextValue[id] = value;
        onChange(this.props.id, nextValue);
    };

    render() {
        const { properties, schema, label, id, value, onChange } = this.props;
        const propSchema = properties ? properties.schema || [] : [];
        return (<div style={{ display: 'grid', gridTemplateColumns: 'repeat(2,1fr)', gridGap: '10px'}}>
            {propSchema ? propSchema.map(prop => {
                const { id, label } = prop;
                const propValue = value ? value[id] : null;
                return <div>
                    <TextField
                        onChange={this.change}
                        value={propValue}
                        id={id}
                        label={label}
                    />
                </div>;
            }) : null}
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PropertyMap);