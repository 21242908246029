import { takeEvery, put, call, all, select } from "redux-saga/effects";

import * as ui from "util/sagas/feedback";
import * as api from "util/api/saga";
import { query } from "state/actions/route";
import uuid from "uuid/v4";

import {
  draftsPathAttach,
  draftsPathDetach,
  listAppend,
  listFetchSuccess,
  resourceDeleteSuccess,
  resourceFetchSuccess,
} from "state/actions";

const parseDirectories = (dirs) => {
  return dirs.map((dir) => {
    return { id: dir.path, name: dir.name };
  });
};

export function* handleInit(action) {
  try {
    const { listId, context } = action;
    let dir = context.dir || "";
    yield ui.pending("cms.media", true);
    const response = yield call(
      api.getArgs,
      {},
      "cms.browser.dirs",
      null,
      context
    );
    let dirs = response.data.data.directories;
    let libraries = response.data.data.libraries;
    let files = response.data.data.files;
    dirs = parseDirectories(dirs);
    libraries = libraries.map((lib) => {
      return { id: lib.path, ...lib };
    });
    yield put.resolve(
      listFetchSuccess(
        "cms.media.dirs",
        { data: dirs },
        "cms.media.dirs",
        null,
        true
      )
    );
    yield put.resolve(listFetchSuccess("cms.media.libs", { data: libraries }));
    /*yield put.resolve(listFetchSuccess(
            'cms.media',
            {data: files},
            listId,
            true
        ));*/
    yield ui.pending("cms.media", false);
  } catch (e) {
    yield ui.pending("cms.media", false);
    ui.error(e);
  }
}

function* handleCreateDirectory(action) {
  try {
    const { context } = action;
    const submit = yield call(ui.prompt, "createDirectory");
    let parent = yield select((store) => store.context.dir);
    if (["videos", "pano"].indexOf(parent) > -1) parent = null;
    if (submit && submit.name) {
      yield ui.pending("cms.media", true);
      let name = submit.name;
      let path = [];
      if (parent) path.push(parent);
      path.push(name);
      path = path.join("/");
      let request = { name: path, allDirectories: true };
      let response = yield call(
        api.post,
        request,
        "cms.media.directory.create",
        "?allDirectories=true",
        context
      );
      let dirs = response.data.data.directories;
      yield put.resolve(
        listFetchSuccess("cms.media.dirs", { data: parseDirectories(dirs) })
      );
      yield put(query({ dir: path }));
      yield ui.pending("cms.media", false);
    }
  } catch (e) {
    yield ui.pending("cms.media", false);
    yield ui.error(e);
  }
}

function* handleImport(action) {
  try {
    const { id, context } = action;
    const submit = yield call(ui.prompt, id);
    if (submit && submit.url) {
      yield ui.pending("cms.media", true);
      let url = submit.url;
      let request = {
        id: uuid(),
        url,
      };
      let response = yield call(
        api.post,
        request,
        "cms.media.import",
        null,
        context
      );
      let data = response.data.data;
      let path = null;
      if (data.type === "video") path = "videos";
      if (["google_pano", "hosted_pano"].indexOf(data.type) > -1) path = "pano";
      yield put(resourceFetchSuccess("cms.media", data.id, data));
      yield put.resolve(listAppend("cms.media", data));
      if (path) yield put(query({ dir: path }));
      yield ui.pending("cms.media", false);
    }
  } catch (e) {
    yield ui.pending("cms.media", false);
    yield ui.error(e);
  }
}

function* handleSyncDir(action) {
  try {
    const { context } = action;
    const dir = yield select((store) => store.context.dir);
    let response = yield call(
      api.post,
      { dir },
      "cms.browser.sync",
      null,
      context
    );
  } catch (e) {
    yield ui.error(e);
  }
}

function* handleDeleteFile(action) {
  console.log("handleDeleteFile", action);
  const { id, context } = action;
  try {
    const confirmed = yield call(ui.confirm, "Potwierdź usunięcie pliku");
    if (confirmed) {
      yield ui.pending(id, true);
      yield call(api.remove, "cms.browser", id, context);
      yield put(resourceDeleteSuccess("cms.media", id));
      yield ui.pending(id, false);
    }
  } catch (e) {
    yield ui.pending(id, false);
    yield ui.error(e);
  }
}

export default function* () {
  yield takeEvery("MEDIA.INIT", handleInit);
  yield takeEvery("MEDIA.DIRECTORY.CREATE", handleCreateDirectory);
  yield takeEvery("MEDIA.DELETE", handleDeleteFile);
  yield takeEvery("MEDIA.IMPORT", handleImport);
  yield takeEvery("MEDIA.SYNC_DIR", handleSyncDir);
}
