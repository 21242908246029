import React from 'react';
//import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectPath } from 'state/selectors/resources';
import Slider from 'components/fields/slider/Slider';
import Radio from 'ide/style-editor/fields/Radio';


const mapState = (store, props) => {
    return {
        value: selectPath(store, props.path.split('/'))
    };
};

const mapDispatch = {
};

const fieldStyle = {
    flex: 1
};

class TypographyStyleSettings extends React.PureComponent {

    handleChange = (key, value) => {
        this.props.onChange(key, value);
    };

    render() {
        const { value } = this.props;
        return (<div className="form--sm type-style-settings">
            <div className="form__field">
                <div className="form__label">Font family</div>
                <Radio
                    value={value.font}
                    options={['body', 'heading', 'decorative']}
                    onChange={(v)=>this.props.onChange('font', v)}
                />
            </div>
            <div className="form__field">
                <div className="form__label">Font weight</div>
                <Radio
                    value={value.font_weight}
                    options={['regular', 'light', 'bold']}
                    onChange={(v)=>this.props.onChange('font_weight', v)}
                />
            </div>
            <div className="form__field">
                <div className="form__label">Text transform</div>
                <Radio
                    value={value.text_transform}
                    options={[null, 'uppercase', 'lowercase']}
                    onChange={(v)=>this.props.onChange('text_transform', v)}
                />
            </div>
            <div className="form__field">
                <div className="form__label">Font size (px)</div>
                <Slider
                    style={fieldStyle}
                    label="Font size"
                    id="font_size"
                    min={6}
                    max={300}
                    step={1}
                    value={value.font_size}
                    onChange={this.props.onChange}
                />
            </div>

            <div className="form__field">
                <div className="form__label">Letter spacing (px)</div>
                <Slider
                    style={fieldStyle}
                    label="Letter spacing"
                    id="letter_spacing"
                    min={-20}
                    max={60}
                    step={0.1}
                    value={value.letter_spacing}
                    onChange={this.props.onChange}
                />
            </div>

            <div className="form__field">
                <div className="form__label">Line height (em)</div>
                <Slider
                    style={fieldStyle}
                    label="Line height"
                    id="line_height"
                    min={0.1}
                    max={3}
                    step={0.1}
                    value={value.line_height}
                    onChange={this.props.onChange}
                />
            </div>
        </div>);
    }

}

TypographyStyleSettings.propTypes = {};

export default connect(mapState, mapDispatch)(TypographyStyleSettings);