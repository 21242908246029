import React, {Component} from 'react';
import {connect} from 'react-redux';

import TreeViewImpl from 'components/fields/components/TreeView';

import {
    query
} from 'state/actions/route';

import {
    draftsPathTree,
    draftsPathCreate
} from 'state/actions';

import {
    uiTreeExpand
} from "state/actions/ui";

import {
    selectResource,
    selectPath
} from 'state/selectors/resources';

const canHaveChildren = (component) => {
    let type = component.element_type || component.type;
    return [
        'list',
        'layout',
        'structured',
        'tab',
        'group',
        'collection',
        'context',
        'form',
        'map'
    ].indexOf(type) > -1;
};

export const treeStructure = (roots, resource, entities, childKey, parentId, treeState) => {
    if (!roots) return [];
    let nodes = roots.map((id)=> {
        let ent = selectResource(entities, resource, id);
        if (!ent) return {id};
        let children = ent[childKey];
        children = children
            ? treeStructure(children, resource, entities, childKey, id, treeState)
            : null;
        let node = {id};
        node.parent = parentId;
        if (children) node.children = children;
        node.leaf = !canHaveChildren(ent);
        node.collapsed = children && children.length > 0 && !treeState[id];
        return node;
    });
    return nodes;
};

const mapStateToProps = (store, props) => {
    const current = store.context.item
        ? store.context.item.split('.')
        : null;

    const data = selectPath(store, props.path.split('/')) || [];
    const tree = {
        id: 'root',
        children: treeStructure(
            data,
            props.resource,
            store,
            props.childKey,
            null,
            store.ui.treeState
        )
    };
    return {
        data,
        tree,
        selectedItem: current && current[0] === props.id
            ? current[1]
            : null
    }
};

const mapDispatchToProps = {
    query,
    create: draftsPathCreate,
    updateTree: draftsPathTree,
    onExpand: uiTreeExpand,
    treeMove: (path, resource, childKey, data) => {
        return {
            type: 'DRAFT.PATH.TREE_MOVE',
            id: data.subject.id,
            from: data.from.id === 'root'
                ? path
                : [resource, data.from.id, childKey],
            to: data.to.id === 'root'
                ? path
                : [resource, data.to.id, childKey],
            index: data.index
        }
    }
};

class TreeView extends Component {

    handleEditItem = (item) => {
        this.props.query({item: this.props.id + '.' + item});
    };

    handleTreeUpdate = (tree) => {
        this.props.updateTree(
            this.props.path.split('/'),
            tree.children
        );
    };

    handleMove = (data) => {
        this.props.treeMove(
            this.props.path.split('/'),
            this.props.resource,
            this.props.childKey,
            data
        );
    };

    handleToggleBranch = (node) => {
        this.props.onExpand(node.id);
    };

    render() {
        return (<TreeViewImpl
            {...this.props}
            onEdit={this.handleEditItem}
            onUpdate={this.handleTreeUpdate}
            onMove={this.handleMove}
            onCollapse={this.handleToggleBranch}
            onExpand={this.handleToggleBranch}
        />);
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(TreeView);