import React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';

import classNames from 'classnames';

export default class CheckboxField extends React.Component {

    handleChange = (event) => {
        console.log(this.props.id, this.props.value);
        event.stopPropagation();
        event.preventDefault();
        if (!this.props.onChange) {
            alert('onChange handler not assigned');
            return;
        }
        this.props.onChange(this.props.id, !this.props.value);
    };

    answer = (answer) => {
        let value = this.props.value;
        let id = this.props.id;
        if (value === answer){
            this.props.onChange(id, null);
            return;
        }
        this.props.onChange(id, answer);
    };

    render() {
        const { label, icon, variant, value, readOnly, ValueComponent } = this.props;

        if (ValueComponent) {
            return <ValueComponent
                value={!!value}
                label={label}
                onClick={!readOnly ? this.handleChange : null}
            />
        }

        if (variant === 'checker') {
            return (<Tooltip title={label}><div
                onClick={this.handleChange}
                className={classNames({
                    'input-checker': true,
                    'checked': !!value
                })}>
                    <Icon fontSize="inherit">{icon || 'check'}</Icon>
            </div></Tooltip>);
        }

        if (variant === 'answer') {
            return (<div style={{ display: 'flex' }}>
                    <div
                        onClick={()=>this.answer(-1)}
                        className={classNames({
                            'input-checker': true,
                            'input-checker--danger': true,
                            'checked': value === -1
                        })}>
                        <Icon fontSize="inherit">{icon || 'clear'}</Icon>
                    </div>
                    <div
                        onClick={()=>this.answer(1)}
                        className={classNames({
                            'input-checker': true,
                            'checked': value === 1
                        })}>
                        <Icon fontSize="inherit">{icon || 'check'}</Icon>
                    </div>
            </div>);
        }

        if (variant === 'checkbox') {
            return (<FormControlLabel
                onClick={this.handleChange}
                control={
                    <Checkbox
                        value={true}
                        checked={this.props.value || false}
                    />
                }
                label={label}
            />);
        }

        return (<FormControlLabel
            onClick={this.handleChange}
            control={
                <Switch
                    value={true}
                    checked={this.props.value || false}
                />
            }
            label={label}
        />);
    }

}