import { createReducer } from "util/redux/create-reducer";

import {normalizeResources} from 'config/schema';
import { actions as resourceActions, updateList } from 'state/reducers/data';

const draftCancel = (state, resource, id) => {
    if (!state || !state[resource] || !state[resource][id]) return state;
    let newState = {...state};
    delete newState[resource][id];
    newState[resource] = {...newState[resource]};
    return newState;
};

const cleanupDrafts = (prevState, entities) => {
    let state = {...prevState};
    Object.keys(entities).forEach(resource=>{
        Object.keys(entities[resource]).forEach(id=>{
            state = draftCancel(state, resource, id);
        })
    });
    return state;
};

export const actions = {
    'APP.BOOTSTRAP': resourceActions['APP.BOOTSTRAP'],
    'APP.CLEANUP_RESOURCES': resourceActions['APP.CLEANUP_RESOURCES'],
    'DRAFT.CREATE': (state, {path, update, schema}) =>
        updateList(state, path[0], {data:[update]}, null, schema),
    'DRAFT.CHANGE': resourceActions['RESOURCE.CHANGE'],
    'DRAFT.REORDER': resourceActions['RESOURCE.REORDER'],
    'DRAFT.CANCEL': (state, {resource, id}) =>
        draftCancel(state, resource, id),
    'DRAFT.SUBMIT.SUCCESS': (state, {resource, id, data, schema}) => {
        let entities = normalizeResources(state)(resource, [{id, ...data}]);
        return cleanupDrafts(state, entities);
    },
    'DRAFT.PATH.CHANGE': resourceActions['RESOURCE.PATH.CHANGE'],
    'DRAFT.PATH.REORDER': resourceActions['RESOURCE.PATH.REORDER'],
    'DRAFT.PATH.REORDER_INDEX': resourceActions['RESOURCE.PATH.REORDER_INDEX'],
    'DRAFT.PATH.ATTACH': resourceActions['RESOURCE.PATH.ATTACH'],
    'DRAFT.PATH.DETACH': resourceActions['RESOURCE.PATH.DETACH'],
    'DRAFT.PATH.TREE': resourceActions['RESOURCE.PATH.TREE'],
    'DRAFT.PATH.TREE_MOVE': resourceActions['RESOURCE.PATH.TREE_MOVE'],
    'DRAFT.PATH.DELETE': resourceActions['RESOURCE.PATH.DELETE'],
    'DRAFT.PATH.DELETE_BY_ID': resourceActions['RESOURCE.PATH.DELETE_BY_ID'],
    'DRAFT.PATH.APPEND': resourceActions['RESOURCE.PATH.APPEND'],
    'RESOURCE.DELETE.SUCCESS': (state, {resource,id}) => draftCancel(state, resource, id)
};

export default createReducer({}, actions);