import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {selectResource} from "state/selectors/resources";
import {selectList} from 'state/selectors/resources';
import {draftsChangeField,draftsSubmit} from 'state/actions';
import {formChangeField} from 'state/actions/form';
import fieldTypes from './fields';

import {selectValue} from 'components/list/fields';
import {selectFieldSchema} from 'state/selectors/schema';

const nullObj = {};

const renderValue = (schema, data) => {
    let key = schema.slug;
    if (schema.properties && schema.properties.fields) {
        key = schema.properties.fields;
    }
    if (!key) key = ['title', 'name', 'label'];
    let value = selectValue(data, key);
    return value;
};

const helperText = (schema) => {
    let format = schema.format || {};
    let messages = [];
    let len = [];
    if (format.min_length) len.push(format.min_length);
    if (format.max_length) len.push(format.max_length);
    if (len.length) messages.push([len.join('-'), 'znaków'].join(' '));
    return messages.join(', ');
};

const mapStateToProps = (store, props) => {
    let schema = selectFieldSchema(store, props.resource, props.id, props.fieldId, props.fieldName);
    let data = selectResource(store, props.resource, props.id);
    let value = renderValue(schema, data);
    if (props.hydrate && (schema.type === 'collection' || schema.type === 'media')) {
        value = selectList(store, ['db',schema.slug].join('.'), value);
    }
    return {
        path: [props.resource, props.id].join('/'),
        schema,
        data: props.withData ? data : nullObj,
        label: schema.label,
        value,
        helperText: null
    }
};

const mapDispatchToProps = {
    onChange: formChangeField,
    onSubmit: draftsSubmit
};

class Field extends React.PureComponent {

    handleSubmit = () => {
        let resource = this.props.rootResource || this.props.resource;
        let id = this.props.rootId || this.props.id;
        this.props.onSubmit(resource, id);
        if (this.props.parentAutoSave) {
            let path = this.props.parentAutoSave.split('/');
            this.props.onSubmit(path[0], path[1]);
        }
    };

    handleChange = (id, value) => {
        let update = {};
        update[id] = value;
        this.props.onChange(
            this.props.resource,
            this.props.id,
            id,
            value
        );
        if (this.props.autoSave) {
            this.handleSubmit();
        }
    };

    render() {
        return this.props.children({
            ...this.props,
            onChange: this.handleChange,
            onSubmit: this.handleSubmit
        });
    }

}

Field.propTypes = {
    resource: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    fieldId: PropTypes.string,
    fieldName: PropTypes.string
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Field);

export function RenderField(props) {
    let {type, schema, data, resource, value, helperText, id, display, onChange, onSubmit, autoSave} = props;
    const { fields } = schema;
    if (!type) type = schema.type;
    if (type === 'virtual') return null;
    if (display === 'default') display = null;
    let FieldComponent = fieldTypes[display] || fieldTypes[type] || fieldTypes.default;
    const properties = schema.properties || {};
    return <FieldComponent
        display={display}
        {...props}
        {...properties}
        variant={props.variant || properties.variant}
        readOnly={props.readOnly}
        id={schema.slug}
        label={schema.label || schema.slug}
        value={value}
        onChange={onChange}
        onSubmit={onSubmit}
        autoSave={autoSave}
        parentAutoSave={props.parentAutoSave}
        resource={resource}
        resourceId={id}
        schema={schema}
        helperText={helperText}
        error={false}
    />;
}