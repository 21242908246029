import {connect} from 'react-redux';
import {selectNamespacedResources} from "state/selectors/resources";
import { hasAccess, hasResourceAccess } from 'state/selectors/access';

const mapStateToProps = (store, props) => {
    let resources = selectNamespacedResources(
        store,
        props,
        props.resourceNamespace
    );
    resources = resources.filter(res=>{
        return !res.subtype_of
            && res.type !== 'job'
            && hasResourceAccess(store, res)
    });
    return {
        resources,
        current: [store.context.app,store.context.view].join('.')
    }
};

const mapDispatchToProps = {};

const Nav = (props) => {
    return props.render({...props});
};

export default connect(mapStateToProps, mapDispatchToProps)(Nav);