import { createReducer } from "util/redux/create-reducer";
import { setIn } from 'lodash-redux-immutability';

const updateMeta = (state, resource, list, data) => {
    return setIn(state, [list], data);
};

const actions = {
    'LIST.UPDATE_META': (state, {resource, list, data}) => updateMeta(state, resource, list, data)
};

export default createReducer({}, actions);