import {
    call,
    takeEvery,
    takeLatest,
    select,
    put,
    all,
    fork
} from 'redux-saga/effects';

import * as ui from 'util/sagas/feedback';
import * as api from 'util/api/saga';

import { fetchPageSuccess } from 'state/actions/ide';

import {
    listFetchSuccess,
    draftsSubmit,
    resourceFetch,
    draftsPathAttach,
    draftsPathReorder, draftsPathChange
} from 'state/actions';

import { query, resourceRoute } from 'state/actions/route';

import {currentPreset} from "state/selectors/app";
import {selectParentId, selectResource, selectDrafts} from 'state/selectors/resources';
import parseResponse from 'util/api/adapters';
import { handleImport, handleCopy } from 'state/sagas/library';

import {
    stylesReady
} from 'state/actions/ide';

import {
    convertPresetToLessVars,
    responsivePreviewBreakpoints
} from "util/styles";

import { handleDraftSubmit } from "./resource";

function* bootstrap(action) {
    try {
        const { context } = action;
        const lessStyles = yield call(api.getArgs, {v3: true}, 'cms/theme/files', null, context);
        const stylesResponse = yield call(api.getArgs, {all: true}, 'cms/theme/styles', null, context);
        const docs = yield call(api.getArgs, {}, 'cms.styles.docs', null, context);
        const styles = parseResponse('theme.styles', stylesResponse);
        const website = yield call(api.getArgs, {type: 'website', expand: true}, 'console/services', context.project, context);
        yield put(listFetchSuccess('console.services.website', {data: [website.data.data]}));
        yield put(listFetchSuccess('cms.theme.files', {data: lessStyles.data.data}));
        yield put(listFetchSuccess('theme.styles', {data: styles.data.data}));
        yield put(listFetchSuccess('cms.styles.docs', {data: docs.data.data}));
    } catch (e) {

    }
}

function* handleFetchPage(action) {
    try {
        const { id, context } = action;

        let url = [
            'cms/ide/page',
            id
        ].join('/');

        yield ui.pending('ide.page', true);
        const response = yield call(api.get, url, null, context);
        const data = response.data.data;
        const pageType = data.page_type;
        const ns = pageType === 'entries'
            ? ['cms', pageType, data.page.type].join('.')
            : ['cms', pageType].join('.');

        yield put(listFetchSuccess(ns, {data: [data.page]}, 'cms.pages.ide'));
        yield put(listFetchSuccess('cms.layouts', {data: data.layouts}));
        yield put(listFetchSuccess('cms.navigation', {data: data.nav}));
        yield put(listFetchSuccess('cms.settings', {data: [data.settings]}));
        yield put(fetchPageSuccess(data));
        yield ui.pending('ide.page', false);
    } catch (e) {
    }
}

function* refreshStylesFromState() {
    try {
        const data = yield select((store)=>store.data['cms.theme.files']);
        const drafts = yield select((store)=>store.drafts['cms.theme.files']);
        const styles = {...data, ...drafts};
        const preset = yield select(currentPreset);
        const device = yield select((store)=>store.context.device);
        yield fork(refreshStyles, {payload: {less: styles, preset, device}});
    } catch (e) {
        yield ui.error(e);
    }
}

function* refreshStyles(action) {
    try {
        console.log('Rendering less stylesheet');
        const less = window.less;
        const { preset } = action.payload;
        const lessFiles = action.payload.less;
        const device = action.payload.device;
        let styles = document.getElementById("less:static-less-style");

        if (!styles) {
            styles = document.createElement("style");
            styles.setAttribute("id", "less:static-less-style");
            document.head.appendChild(styles);
        }
        styles.setAttribute("type", "text/css");

        const presetVars = convertPresetToLessVars(preset);
        console.log(presetVars);
        const renderBlacklist = ['imports.less', 'less/imports.less', '.htaccess-generated', 'schema.json'];
        const files = Object.values(lessFiles).filter((file)=>renderBlacklist.indexOf(file.id) === -1);

        if (!files.length) return;

        const stylesText = files
            .map(file => {
                return file.content;
            })
            .join("\r\n\r\n");

        /*const lessStyles = document.createElement('style');
        lessStyles.setAttribute('rel', 'stylesheet/less');
        lessStyles.setAttribute('type', 'text/css');
        lessStyles.textContent = stylesText;

        document.head.appendChild(lessStyles);
        //less.render();
        return;*/
        const breakpointVars = responsivePreviewBreakpoints(device);

        const vars = {
            ...presetVars,
            ...breakpointVars
        };

        const lessOptions = {
            modifyVars: vars
        };

        let result = {};

        try {
            console.log('Rendering whole less');
            result = yield less.render(
                stylesText,
                lessOptions
            );
            console.log('LESS is ready');
            if (result.css)  {
                styles.textContent = result.css;
                yield put(stylesReady());
            }
        } catch (e) {
            console.log(e);
            yield ui.error(e);
            yield put(stylesReady());
        }
    } catch (e) {
        throw e;
    }
}

function* handleFetchData(action) {
    try {
        const { list, query, context } = action;
        let type = 'cms.entries.' + query.entry_type;
        let data = yield call(api.getArgs, query, 'cms.data', null, context);
        yield put.resolve(listFetchSuccess(type, data.data, list));
        const tags = data.data.tags;
        if (tags) yield put.resolve(listFetchSuccess(
            'cms.tags',
            {data: tags},
            [list,'tags'].join('.'),
            true
        ));
    } catch (e) {
        yield ui.error(e);
    }
}

function* handleSaveAll(action) {
    try {
        const persistable = [
            'cms.sections',
            'cms.entries.offers',
            'cms.pages',
            'cms.layouts',
            'cms.theme.files',
            'theme.styles',
            'console.presets'
        ];
        const { context } = action;
        const drafts = yield select(selectDrafts);
        yield ui.pending('ide.save', true);
        const createActions = [];
        const submitActions = [];
        drafts.map((draft) => {
            if (persistable.indexOf(draft.resource) > -1) {
                if (draft.draft) {
                    createActions.push(draftsSubmit(draft.resource, draft.id));
                } else {
                    submitActions.push(draftsSubmit(draft.resource, draft.id));
                }
            }
        });
        const applyCreateActions = function* applyCreateActions() {
            return yield all(createActions.map((a) => {
                return call(handleDraftSubmit, {...a, context});
            }));
        };
        const applySubmitActions = function* applyCreateActions() {
            return yield all(submitActions.map((a) => {
                return call(handleDraftSubmit, {...a, context});
            }));
        };
        yield* applyCreateActions();
        yield* applySubmitActions();
        yield ui.pending('ide.save', false);
    } catch (e) {
       yield ui.error(e);
    }
}

const updateOrder = (prevOrder, sections, replaceId) => {
    let order = prevOrder.slice();
    sections.forEach(function(section){
        if (section.type !== 'view') {
            if (order.indexOf(section.id) > -1) {
                order.splice(order.indexOf(replaceId),1);
            } else {
                order[order.indexOf(replaceId)] = section.id;
            }
        }
    });
    return order;
};

function* handleSectionDrag(action) {
    try {
        const {order, copy, context} = action;
        const placeholderId = Object.keys(copy)[0];
        const id = copy[placeholderId].id;
        let effect = action.effect || 'move';
        const source = yield select((store) => store.context.src);
        const project = context.project;
        const {app, view} = context;
        const pageId = context.id;
        const resource = [app, view].join('.');
        const page = yield select(selectResource, resource, pageId);
        const sections = page.sections;
        const path = [resource, pageId, 'sections'];
        if (action.effect) {
            let effectChoice = yield ui.confirm('Copy?');
            effect = effectChoice ? 'copy' : 'move';
        }
        yield ui.pending('ide.save', true);
        yield ui.pending(id, true);
        if (sections.indexOf(id) > -1 && effect !== 'copy') {
            //yield fork(ui.info, 'Just move and save');
            let orderImport = order; //updateOrder(order, [{id}], 'placeholder');
            yield put.resolve(draftsPathChange(path, orderImport));
            yield put.resolve(draftsSubmit(resource, pageId, false, context));
        } else {
            if (source && (source !== project)) {
                //yield fork(ui.info, effect + ' from another project');
                try {
                    let importedSections = yield call(handleImport, {
                        project: source,
                        from: ['cms.sections', id],
                        to: path,
                        context
                    });
                    let orderImport = updateOrder(order, importedSections, placeholderId);
                    yield put.resolve(draftsPathChange(path, orderImport));
                    yield put.resolve(draftsSubmit(resource, pageId, false, context));
                } catch (e) {
                    yield put.resolve(draftsPathChange(path, sections.slice()));
                }
            } else {
                if (effect !== 'copy') {
                    //yield fork(ui.info, effect + ' from same project');
                    let orderImport = updateOrder(order, [{id: id}], placeholderId);
                    console.log(action);
                    console.log(id, placeholderId, order, orderImport);
                    yield put.resolve(draftsPathChange(path, orderImport));
                    yield put.resolve(draftsSubmit(resource, pageId, false, context));
                } else {
                    yield fork(ui.info, effect + ' from same project');
                    let sections = yield handleCopy({
                        from: ['cms.sections', id],
                        context
                    });
                    let orderImport = updateOrder(order, sections.sections, placeholderId);
                    yield put.resolve(draftsPathChange(path, orderImport));
                    yield put.resolve(draftsSubmit(resource, pageId, false, context));
                }
            }
        }
        yield ui.pending('ide.save', false);
        yield ui.pending(id, false);
    } catch(e) {
        yield ui.pending('ide.save', false);
        yield ui.error(e);
    }
}

function* handleSectionClick(action) {
    try {
        const { id, tplId, componentId, keys, context} = action;
        let resource = [context.app,context.view].join('.');
        if (keys.alt && keys.shift) {
            yield put(resourceRoute(resource+'/section', {relatedId:id}, {dev:null}));
            return;
        }
        if (keys.alt && id !== tplId && context.sc !== tplId) {
            yield put(query({sc:tplId, scr:id, cp:null, dev:'styles', s: null}));
            return;
        }
        yield put(query({sc: id, scr: null, cp:null, dev: 'styles', s: null}));
    } catch (e) {
        yield ui.error(e);
    }
}

export default function*() {
    yield takeEvery('IDE.PAGE', handleFetchPage);
    yield takeLatest("IDE.REFRESH_STYLES", refreshStyles);
    yield takeLatest("IDE.BOOTSTRAP", bootstrap);
    yield takeEvery("IDE.FETCH_DATA", handleFetchData);
    yield takeLatest("IDE.SAVE_ALL", handleSaveAll);
    yield takeLatest("IDE.SECTION.DRAG", handleSectionDrag);
    yield takeEvery("IDE.SECTION.CLICK", handleSectionClick);
    yield takeLatest('IDE.REFRESH_STYLES_STATE', refreshStylesFromState);
}