export const themeConfig = {
    palette: {
        primary: {main:'#7c4dff'},
        secondary: {main:'rgb(245, 0, 87)'},
        background: {
            default: '#F5F5F5'
        },
        divider: 'rgba(0,0,0,0.05)'
    },
    typography: {
        useNextVariants: true,
        fontSize: 12,
        fontFamily: [
            'Montserrat',
            'Roboto'
        ].join(',')
    },
    overrides: {
        MuiIconButton: {
            root: {
                padding: '8px'
            },
            label: {
                fontSize: '24px',
            }
        }
    }
};