import React from 'react';
//import PropTypes from 'prop-types';

import Default from './Default';

export default class Activate extends React.PureComponent {

    render() {
        const { isActive } = this.props;
        return isActive
            ? (<Default {...this.props}
                icon="action.deactivate"
                label="Deaktywuj"
            />)
            : (<Default {...this.props} />);
    }

}

Activate.propTypes = {};