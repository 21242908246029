import React  from "react";
import { webfont_icon } from 'ide/components/icon';

export default class Nav extends React.PureComponent {
  render() {
    const { s, className, onFocus, items } = this.props;
    return (
      <nav className={s.get("menu") + " " + className} onClick={onFocus}>
        {items.map((item, i) => {
          return (
            <span key={i} className={"nav__item hover " + s.get("menu_item")}>
              <span>
                {item.icon
                  ? <span className={'nav__item_icon.c-btn__icon ' + s.get('icon')}>
                      {webfont_icon(item.icon)}
                  </span>
                  : null }
                <span className={"nav__item_label c-btn__label"}>
                  {item.label || item.title}
                </span>
              </span>
            </span>
          );
        })}
      </nav>
    );
  }
}
