import React from 'react';
//import PropTypes from 'prop-types';

import ColorPicker from './ColorPicker';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import Dialog from 'components/Dialog';
import PresetBrowser from 'containers/PresetBrowser';

const emptyObj = {};

export default class ColorScheme extends React.PureComponent {

    constructor(props) {
        super(props);

        this.colors = [
            "primary_color",
            "success_color",
            "dark_color",
            "light_color"
        ];

        this.state = {open: false};
    }

    handleChange = (id, value) => {
        let prevValue = this.props.value || emptyObj;
        let newValue = {...prevValue};
        newValue[id] = value;
        this.props.onChange(this.props.id, newValue);
    };

    handleOpen = () => this.setState({open: true});
    handleClose = () => this.setState({open: false});

    handleSelect = (id, data) => {
        this.handleClose();
        console.log(id, data);
        this.props.onChange(this.props.id, data);
    };

    render() {
        const value = this.props.value || emptyObj;
        return (<React.Fragment>
            <Paper>
                <div className="color-scheme-field">
                {this.colors.map((color) => {
                    return (<ColorPicker
                        key={color}
                        id={color}
                        value={value[color]}
                        onChange={this.handleChange}
                    />);
                })}
                </div>
                <div onClick={this.handleOpen} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <IconButton onClick={this.handleOpen}>
                        <Icon>search</Icon>
                    </IconButton>
                </div>
            </Paper>
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                maxWidth="lg"
                fullWidth={true}
            >
                <div className="browser-dialog">
                    <PresetBrowser
                        path={this.props.path}
                        onSelect={this.handleSelect}
                        resource="colors"
                    />
                </div>
            </Dialog>
        </React.Fragment>);
    }

}

ColorScheme.propTypes = {};