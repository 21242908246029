import { selectResource } from './resources';
import { createSelector } from "reselect";
import { selectAllResources } from 'state/selectors/resources';

const emptyArr = [];
const emptyObj = {};

export const selectSettings = (store) => {
    return selectAllResources(store, 'cms.settings')[0] || emptyObj;
};

export const currentWebsite = (store, props) => {
    //if (store.context.app !== 'cms') return {};
    let project = store.context.project;
    if (!project) return {};
    return selectResource(store, 'console.services.website', project);
};

export const currentTheme = (store) => {
    return selectResource(store, 'console.themes', currentWebsite(store).theme);
};

export const currentPreset = (store) => {
    const theme = currentTheme(store);
    return selectResource(store, 'console.presets', theme.preset);
};

export const canonicalDomain = (store) => {
    const website = currentWebsite(store);
    return selectResource(store, 'console.domains', website.canonical_domain);
};

export const pageUrl = (store, id) => {
    let domain = canonicalDomain(store);
    const page = selectResource(store, [store.context.app,store.context.view].join('.'), id);
    if (!page.slug) return domain.hostname;
    if (page.slug === 'index') return domain.hostname;
    return [domain.hostname,page.slug].join('/');
};

export const selectCurrentCms = (store) => {
    const websiteId = store.context.project;
    const website = selectResource(store, 'console.services.website', websiteId);
    return selectResource(store, 'console.services.cms', website.content_source);
};

export const selectAvailableLanguages = (cms, all) => {
    const available = cms && cms.languages ? all.filter((lang) => {
        return cms.languages.indexOf(lang.id) > -1
    }) : all;
    return available.length ? available : emptyArr;
};

export const availableLanguages = createSelector([
    selectCurrentCms,
    (store) => store.schema.console.data.languages
], selectAvailableLanguages);

export const currentUser = (store) => {
    return store.data['app.users'][store.context.user];
};

export const selectUiTheme = (store) => {
    let settings = selectResource(store, 'console.user_settings', 'current');
    if (settings.theme_preset) return selectResource(store, 'console.presets', settings.theme_preset);
};