import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Conversation from "./Conversation";
import Message from './Message';
import Dialog from 'components/Dialog';

export default class Thread extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    open = () => this.setState({open: true});
    close = () => this.setState({open: false});

    render() {
        const { attachId, threadId, messages, currentUser, onCreateMessage } = this.props;
        const messagesCount = messages.length;
        const { open } = this.state;
        const readOnly = messagesCount > 0;
        return (<div>
            <div className={classNames({
                'conversation-summary': readOnly
            })} onClick={readOnly ? this.open : null}>
                <Conversation
                    currentUser={currentUser}
                    onCreate={onCreateMessage}
                    messages={messagesCount}
                    threadId={threadId}
                    attachId={attachId}
                    readOnly={readOnly}
                >
                    {messages.slice(messagesCount-1,messagesCount).map(messageId=><Message
                        key={messageId}
                        type={'db.messages'}
                        id={messageId}
                    />)}
                    {readOnly || messagesCount > 1 ? <div className={'conversation-show'}>
                        pokaż wszystkie ({messagesCount})
                    </div> : null}
                </Conversation>
            </div>
            <Dialog open={open} className={'console-context'} onClose={this.close}>
                <div className={'conversation-dialog'}>
                    <Conversation
                        currentUser={currentUser}
                        onCreate={onCreateMessage}
                        messages={messagesCount}
                        threadId={threadId}
                        attachId={attachId}
                    >
                        {messages.map(messageId =><Message
                            key={messageId}
                            type={'db.messages'}
                            id={messageId}
                        />)}
                    </Conversation>
                </div>
            </Dialog>
        </div>);
    }

}

Thread.propTypes = {};