import dbConfig from './lists/db';

const defaultEntry = {
    primaryText: ['title'],
    secondaryText: ['slug'],
    avatar: 'media'
};

const resourceListSchema = {
    'cms.pages': {
        primaryText: ['title', 'description'],
        secondaryText: ['slug']
    },
    'cms.sections': {
        primaryText: ['title', 'description'],
        secondaryText: ['slug'],
        avatar: 'section-type'
    },
    'cms.items': {
        primaryText: ['title'],
        avatar: 'media'
    },
    'cms.items.list-element': {
        primaryText: ['title'],
        avatar: 'media'
    },
    'cms.components': {
        primaryText: ['element_type'],
        secondaryText: ['style'],
        typeKey: 'element_type'
    },
    'cms.entries.offers': {
        ...defaultEntry,
        activeKey: '!archived',
        fields: {
            price: {
                format: 'price',
                value: ['price']
            }
        }
    },
    'cms.entries.rooms': defaultEntry,
    'cms.entries.attractions': defaultEntry,
    'cms.entries.properties': defaultEntry,
    'cms.entries.gallery': {
        ...defaultEntry,
        layout: 'grid',
        type: 'cms.gallery.card'
    },
    'cms.integrations': {
        primaryText: ['type'],
        secondaryText: ['account_id', 'client_id', 'username']
    },
    'db.integrations': {
        primaryText: ['type'],
        secondaryText: ['account_id', 'client_id', 'username']
    },
    'cms.nav-link': {
        primaryText: ['label', 'link_action', 'link'],
        secondaryText: ['url'],
        avatar: 'icon'
    },
    'cms.theme.files': {
        primaryText: ['id'],
        avatar: 'none'
    },
    'cms.files': {
        primaryText: ['file'],
        secondaryText: ['type']
    },
    'apartments.flats': {
        primaryText: ['code_part'],
        secondaryText: ['stats.rooms.counts'],
        avatar: 'flat-status',
        fields: {
            price: {
                format: 'price',
                value: ['price']
            },
            area: {
                format: 'area',
                value: ['total_area']
            }
        }
    },
    'apartments.buildings': {
        primaryText: ['name', 'code_part']
    },
    'apartments.leads': {
        primaryText: ['contact.name']
    },
    'apartments.resource-bindings': {
        primaryText: ['resource.resource.name', 'resource.resource.code_part'],
        secondaryText: ['remote.resource_type'],
        fields: {
            synced_at: {
                format: 'date-diff',
                value: ['synced_at']
            }
        }
    },
    'console.services.cms': {
        primaryText: ['name'],
        secondaryText: ['service_id']
    },
    'console.domains': {
        primaryText: ['name'],
        secondaryText: ['hostname'],
        fields: {
            cert: {
                format: 'domain-cert',
                value: ['certificate_valid_to']
            }
        }
    },
    'cms.fonts': {
        primaryText: ['family'],
        primaryTextFormat: 'font',
        avatar: 'none'
    },
    'console.cms.snapshots': {
        primaryText: ['id'],
        secondaryText: ['created_at'],
        secondaryTextFormat: 'date-diff'
    },
    'cms.translations': {
        primaryText: ['value'],
        secondaryText: ['key']
    },
    'theme.vcs-log': {
        primaryText: ['message'],
        secondaryText: ['hash'],
        avatar: 'none'
    },
    'console.users': {
        primaryText: ['name'],
        avatar: 'user-avatar'
    },

    'console.services.website.quick': {
        primaryText: ['name'],
        secondaryText: ['url'],
        avatar: 'none'
    },
    'console.services.website': {
        primaryText: ['name', 'description'],
        secondaryText: ['slug', 'type']
    },
    'console.services.website.cards': {
        primaryText: ['name', 'description'],
        secondaryText: ['slug', 'type'],
        layout: 'grid',
        type: 'console.services.website.card',
        filterLayout: 'aside'
    },
    'console.presets.card.colors': {
        primaryText: ['name'],
        type: 'console.presets.card.colors',
        layout: 'grid'
    },
    'console.presets.card.typography': {
        primaryText: ['name'],
        type: 'console.presets.card.typography',
        layout: 'grid'
    },
    ...dbConfig,
    default: {
        primaryText: ['title', 'name', 'description'],
        secondaryText: ['slug', 'type']
    }
};

export default resourceListSchema;