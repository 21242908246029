import React from 'react';
import {connect} from 'react-redux';
import ResourceForm from 'containers/ResourceForm';

import Dialog from 'components/Dialog';

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

class UserSettings extends React.Component {

    render() {
        const { open, onClose } = this.props;
        return (<Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth={true}>
            <ResourceForm type="console.user_settings" id="current" />
        </Dialog>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserSettings);