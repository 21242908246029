import React from 'react';
import {connect} from 'react-redux';
import { listFilterSet, listFilter } from 'state/actions';
import {selectFilter,selectFilterConfig} from 'state/selectors/filter';

import ListFilters from 'components/list/ListFilters';

const mapStateToProps = (store, props) => {
    return {
        filters: selectFilter(store, props.filterId),
        config: selectFilterConfig(store, props.resource, props.filterId, props.mobile),
        showFilters: store.context.filters
    }
};

const mapDispatchToProps = {
    onSetFilter: listFilter, //listFilterSet
};

class Filters extends React.PureComponent {

    handleSetFilter = (id, value) => {
        const newFilter = {...this.props.filters};
        const config = this.props.config.filters_config[id];
        const link = config && config.link;
        if (link) {
            link.forEach(linkId=>delete newFilter[linkId]);
        }
        newFilter[id] = value;

        if (newFilter.pagination) newFilter.pagination.current = 1;
        this.props.onSetFilter(
            this.props.resource,
            this.props.listId,
            this.props.filterId,
            newFilter,
            this.props.context
        );
    };

    render() {
        const { resource, listId, filterId, ...otherProps } = this.props;
        return (<ListFilters
            {...otherProps}
            resource={resource}
            onSetFilter={this.handleSetFilter}
        />);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Filters);