import React from 'react';
import {connect} from 'react-redux';
import {resourceRoute,query} from 'state/actions/route';
import {draftsChangeField, draftsPathCreate, draftsPathDetach, draftsSave} from "state/actions";
import {selectResource} from 'state/selectors/resources';
import Select from 'v2/fields/Select';

import TextField from 'components/fields/text/TextField';
import Button from 'components/Button';
import IconButton from 'components/TooltipIconButton';
import FormLayout from 'v2/form-builder/FormLayout';
import SchemaFields from 'v2/form-builder/SchemaFields';

import {ResourceList, Resource as ListResource} from 'list';

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/core/SvgIcon/SvgIcon";

const mapStateToProps = (store, props) => {
    const resource = 'db.form-views';
    const data = selectResource(store, resource, store.context.id);
    const schema = data.resource
        ? selectResource(store, 'db.types', data.resource)
        : {};
    return {
        resource,
        id: store.context.id,
        data,
        resourceType: ['db',schema.name].join('.'),
        resourceTypeId: schema.id,
        mode: store.context.editMode ? 'form' : data.view_mode
    }
};

const mapDispatchToProps = {
    resourceRoute,
    onChange: draftsChangeField,
    onSave: draftsSave,
    onCreateField: draftsPathCreate,
    onDelete: draftsPathDetach,
    query
};

class FormBuilder extends React.Component {

    handleChange = (field, value) => {
        this.props.onChange(
            this.props.resource,
            this.props.id,
            field,
            value
        );
    };

    handleEdit = (id) => {
        this.props.resourceRoute(
            'db.form-views/edit',
            {id}
        )
    };

    handleSave = () => {
        this.props.onSave(this.props.resource, this.props.id);
    };

    handleCreateField = (id) => {
        this.props.onCreateField(
            'db.view_fields',
            [this.props.resource,this.props.id,'view_fields'],
            {type: 'field', source: id},
            null,
            false
        );
    };

    handleCreate = (type,after) => {
        this.props.onCreateField(
            'db.view_fields',
            [this.props.resource,this.props.id,'view_fields'],
            {type},
            after,
            false
        );
    };

    handleCreateChild = (type,field) => {
        this.props.onCreateField(
            'db.view_fields',
            ['db.view_fields', field, 'view_fields'],
            {type}
        );
    };

    handleDelete = (id) => {
        this.props.onDelete(
            [this.props.resource,this.props.id,'view_fields'],
            id
        );
    };

    handleUpdateOrder = ({data}) => {
        this.handleChange('view_fields', data);
    };

    handleSettings = () => {
        this.props.query({r:'db.form-views/'+this.props.id});
    };

    render() {
        const { data, schema, schemaFields } = this.props;


        return (<div style={{ display: 'flex', flex: 1 }}>
            <div style={{ maxWidth: '320px', flex: 1 }}>
                <ResourceList
                    primary
                    listId="db.form-views"
                    resource="db.form-views"
                    renderItem={props=>
                        <ListResource
                            onClick={this.handleEdit}
                            actions="default"
                            {...props}
                    />}
                />
                <div className="resource-list-fab">
                    <Fab
                        color="secondary"
                        aria-label="Add"
                        onClick={this.props.onCreate}
                    >
                        <AddIcon />
                    </Fab>
                </div>
            </div>
            <div style={{ display: 'flex', width: '280px' }}>
                <SchemaFields resource={this.props.resourceTypeId} />
            </div>
            { data.id ? <div style={{ flex: 1, display: 'flex' }}>

                <div style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <div style={{
                        display: 'flex',
                        maxWidth: '960px',
                        marginBottom: '1rem',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Select
                                id="resource"
                                value={data.resource}
                                onChange={this.handleChange}
                                source="api:db.types"
                                variant="dropdown"
                            />
                            <TextField
                                id={"name"}
                                value={data.name}
                                label="View name"
                                onChange={this.handleChange}
                                variant="standard"
                            />
                            <div>
                                <IconButton onClick={this.handleSettings} icon="settings" />
                            </div>
                        </div>
                        <Button
                            onClick={this.handleSave}
                        >
                            Save changes
                        </Button>
                    </div>
                    <div style={{ overflow: 'visible auto', flex: 1 }}>
                        <div style={{ padding: '1rem', maxWidth: '960px', margin: '0 auto', backgroundColor: 'white' }}>
                            <FormLayout
                                type={this.props.data.view_type}
                                mode={this.props.mode}
                                resource={this.props.resourceType}
                                id={null}
                                fieldsetResource={this.props.resource}
                                fieldsetId={this.props.id}
                                editable={true}
                                viewId={this.props.id}
                            />
                        </div>
                    </div>
                </div>
            </div> : null }
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormBuilder);