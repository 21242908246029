import React from 'react';
import PropTypes from 'prop-types';

import {connect} from 'react-redux';
import {changed} from 'util/changed';

import { listFetchFiltered } from 'state/actions';
import { makeSelectFilteredList } from 'state/selectors/filter';
import { selectListViewConfig } from 'state/selectors/list';

import { List } from 'list';

const mapStateToProps = () => {

    const selectFilteredList = makeSelectFilteredList();

    return (store, props) => {

        let actionsDefKey = props.actions && props.actions !== true
            ? props.actions
            : props.resource;

        let listId = props.listId || props.resource;

        let data = props.filterId
            ? selectFilteredList(store, props) //props.resource, props.filterId, listId)
            : store.lists[listId];

        let context = props.context
            ? {...store.context, ...props.context}
            : store.context;

        let definition = store.resources[actionsDefKey] || {};

        return {
            context: context,
            definition,
            resourceDefinition: store.resources[props.resource] || {},
            data: data,
            listId: listId,
            isPending: store.ui.pending[listId],
            config: selectListViewConfig(store, props)
        }

    };
};

const mapDispatchToProps = {
    fetch: listFetchFiltered
};

class ResourceList extends React.PureComponent {

    fetch = () => {
        if (this.props.hold) return;

        let { resource, listId, filterId, fetchStrategy } = this.props;
        
        if (fetchStrategy === 'never') return;
        if (fetchStrategy === 'once' && this.props.data.length) return;

        this.props.fetch(
            resource,
            listId,
            filterId,
            this.props.yieldData,
            this.props.context
        );
    };

    componentDidMount() {
        if (this.props.data.length && this.props.fetchOnce) return;
        this.fetch();
    }

    componentDidUpdate(prevProps) {
        if (this.props.fetchOnce) return;
        let watch = ['resource', 'listId', 'hold'];
        if (changed(watch,prevProps,this.props)) {
            this.fetch();
        }
    }

    render() {
        if (!Array.isArray(this.props.data)) return null;
        return <List {...this.props} />;
    }

}

ResourceList.defaultProps = {
    data: PropTypes.array
};

ResourceList.propTypes = {
    data: PropTypes.array.isRequired,
    fetchOnce: PropTypes.bool
};

const ConnectedResourceList = connect(
    mapStateToProps,
    mapDispatchToProps
)(ResourceList);

ConnectedResourceList.propTypes = {
    resource: PropTypes.string.isRequired,
    listId: PropTypes.string.isRequired
};

export default ConnectedResourceList;