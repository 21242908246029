import React from 'react';
import {connect} from 'react-redux';
import Fieldset from './Fieldset';
import TextField from 'components/fields/text/TextField';
import Button from 'components/Button';

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

class Tab extends React.Component {

    render() {
        const { data, editable } = this.props;
        return (<div className="form-tab">
            { editable ? <div style={{ marginBottom: '1.6rem' }}>
                <Button onClick={this.props.onSettings}>
                    tab settings
                </Button>
                <Button onClick={()=>this.props.onDelete(this.props.fieldId)}>
                    delete
                </Button>
            </div> : null }
            <Fieldset
                editable={this.props.editable}
                resource={this.props.resource}
                id={this.props.id}
                fieldsetResource={this.props.fieldResource}
                fieldsetId={this.props.fieldId}
                level={this.props.level+1}
            />
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Tab);