import React  from "react";

export default class Logout extends React.PureComponent {
  componentDidMount() {
    localStorage.setItem("access_token", null);
    localStorage.setItem("id_token", null);
    localStorage.setItem("expires_at", null);
    window.location.href = "/login";
  }

  render() {
    return null;
  }
}
