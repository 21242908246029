import React from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import SettingsIcon from '@material-ui/icons/Settings';
import IconMenu from 'components/IconMenu';
import IconButton from 'components/TooltipIconButton';
import Icon from '@material-ui/core/Icon';

import ResourceActions from 'containers/ResourceActions';
import ListItemFields from 'components/list/ListItemFields';
import ListItemAvatar from 'components/list/ListItemAvatar';
import { icon } from 'util/icon';

const containerProps = {
    default: {
        className: 'list-item'
    },
    selected: {
        className: 'list-item selected'
    }
};

export default class ListItemImpl extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {open: false, anchorEl: null};
    }

    handleSecondaryAction = () => {
        let actionId = this.props.secondaryAction || 'secondary';
        this.props.onActionRequest(actionId);
    };

    handleOpenContextMenu = (e) => {
        e.preventDefault();
        this.setState({open: true, anchorEl: e.target});
    };

    handleCloseContextMenu = () => {
        this.setState({open: false, anchorEl: null});
    };

    render() {
        const {
            onClick,
            primaryText,
            secondaryText,
            resource,
            id,
            isActive,
            isSelected,
            isPending,
            isDragging,
            isDraft,
            secondaryAction,
            secondaryActionLabel,
            secondaryActions,
            actions,
            fields,
            avatar,
            readOnly
        } = this.props;

        const placeholderText = "Bez nazwy";

        return (<ListItem
                ContainerComponent='div'
                ContainerProps={isSelected
                    ? containerProps.selected
                    : containerProps.default}
                button
                ref={this.menuAnchorRef}
                onClick={onClick}
                onContextMenu={this.handleOpenContextMenu}
                disabled={isDragging || isPending}
        >
            { avatar && typeof avatar === 'function' ?
                (<div className="list-item__avatar_wrapper">
                    <ListItemAvatar isPending={isPending || false} isActive={isActive}>
                        {avatar({isPending, isActive})}
                    </ListItemAvatar>
                </div>) : null }
            <ListItemText
                className="list-item__title"
                secondary={secondaryText}
            >
                { isDraft ? <Icon>edit</Icon> : null }
                {primaryText || (<span className="empty-string">{placeholderText}</span>)}
            </ListItemText>
            { fields ? <ListItemFields>{fields}</ListItemFields> : null }
            <ListItemSecondaryAction>
                <div style={{ display: 'flex' }}>
                    { secondaryAction ? (<div className="list-item__secondary-action">
                        <IconButton
                            tooltip={secondaryActionLabel}
                            onClick={this.handleSecondaryAction}
                            icon={"action."+secondaryAction}
                        />
                    </div>) : null }
                    { secondaryActions ? <div>
                        {secondaryActions}
                    </div> : null}
                    { actions && !readOnly ? (
                        <IconMenu
                            {...this.state}
                            onClose={this.handleCloseContextMenu}
                        >
                            <ResourceActions
                                isActive={isActive}
                                onActionRequest={this.props.onActionRequest}
                                actionsDefKey={actions}
                                resource={resource}
                                id={id}
                            />
                        </IconMenu>
                    ) : null }
                </div>
            </ListItemSecondaryAction>
        </ListItem>);
    }

}