import React from 'react';
import {connect} from 'react-redux';
import _ from "lodash";

import {
    listFetchFiltered,
    listFilterSet,
    listFilter,
    resourceFetch
} from 'state/actions';

import {
    selectList,
    selectListIndexed,
    selectPath,
    selectFilteredList,
    selectUnindexedList,
    selectResource,
    index
} from 'state/selectors/resources';

import {
    selectFilter
} from 'state/selectors/filter';

import { changed } from 'util/changed';

const emptyObj = {};
const emptyArray = [];

const parseQueryString = (string) => {
    return JSON.parse(
        '{"' +
        decodeURI(string)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
        '"}'
    );
};

const split = (str, delimiter, keys) => {
    let parts = str.split(delimiter);
    let result = {};
    parts.forEach((part,i)=>{
        result[keys[i]] = part;
    });
    return result;
};

export const parseSchemaQuery = (source, resourceContext) => {
    if (resourceContext) {
        source = source.replace('$example_source', resourceContext.example_source);
        source = source.replace('$resource_type', resourceContext.resource_type);
    }
    const { type, path } = split(source, ':', ['type','path']);
    const { resource, filter } = split(path, '?', ['resource', 'filter']);
    const filterParams = filter ? parseQueryString(filter) : {};
    return { type, path: resource, params: filterParams };
};

const schemaSelector = path => store => {
    return _.get(store.schema, path);
};

const listEnumSelector = query => store => {
    let app = store.context.app;
    let enumIndex = store.data[[app,'enum-definitions.index'].join('.')];
    if (!enumIndex) return null;
    let enumId = enumIndex[query.resourceId];
    let ids = selectPath(store, [[app,'enum-definitions'].join('.'), enumId, 'values']);
    return selectList(store, [app,'enum-values'].join('.'), ids);
};

export const listSelector = (query) => {
    let resource = query.resource;

    if (resource.indexOf('.enum-definitions') > -1 && query.resourceId) {
        return listEnumSelector(query);
    }

    if (resource.indexOf('.data') > -1) {
        resource = 'cms.entries.' + query.params.entry_type;
    }

    return store => {
        return selectUnindexedList(
            store,
            resource,
            query.filterId,
            query.listId
        );
    };
};

const propsSelector = (store, props) => {
    return props.options;
};

export const makeSelector = (query) => {
    let { type } = query;
    if (type === 'schema') return schemaSelector(query.path);
    if (type === 'api') return listSelector(query);
    return propsSelector;
};

const schemaConfig = (query, store) => {
    let path = query.path.split('.');
    let queryNs = path[0];
    if (!store.schema[queryNs]) path = [store.context.app].concat(path);
    return {...query, path};
};

const ensureNamespace = (store, id) => {
    const { app } = store.context;
    let namespaces = Object.keys(store.schema);
    let parts = id.split('.');
    if (namespaces.indexOf(parts[0]) === -1) return [app,id].join('.');
    return id;
};

export const resourceConfig = (query, store, props) => {
    let parts = query.path.split('/');
    let resourceName = parts[0];
    let id = null;
    if (resourceName === 'enum-definitions') {
        id = parts[1];
    } else {
        resourceName = parts.join('.');
    }
    //const { resourceName, id } = split(query.path, '/', ['resourceName', 'id']);
    let resource = ensureNamespace(store, resourceName);
    let listId = null;
    if (props.listId) {
        listId = props.listId;
    } else {
        listId = id ? query.path : [resource, props.id, 'options'].join('.');
    }
    let params = {
        ...query.params
    };
    if (props.viewId) params.view = props.viewId;

    let displayResource = id
        ? resource.replace('enum-definitions', 'enum-values')
        : resource;

    if (query.path.indexOf('enum') === -1 && query.path.indexOf('/') > -1) {
        displayResource = query.path;
    }

    let resourceId = id;

    if (id === 'styles') {
        resource = 'cms.theme.styles';
        listId = 'cms.theme.styles';
        displayResource = 'cms.theme.styles';
        resourceId = null;
    }

    return {
        ...query,
        params,
        resource,
        resourceId,
        displayResource,
        listId,
        filterId: listId
    }
};

export const makeQueryConfig = query => (store, props) => {
    let { type } = query;

    if (type === 'schema') return schemaConfig(query, store);
    if (type === 'api') return resourceConfig(query, store, props);

    if (!type) return {};
};

const mapState = (store, props) => {
    const query = props.source
        ? parseSchemaQuery(props.source)
        : emptyObj;
    const config = makeQueryConfig(query)(store, props);
    const selector = makeSelector(config);
    const options = selector(store, props) || emptyArray;
    const optionsIndex = index(options);
    return {
        ...config,
        options: options,
        optionsIndex: optionsIndex || emptyObj,
        filters: selectFilter(store, config.filterId),
        query,
        apiFilter: query.params
    };
};

const mapDispatch = {
    fetch: listFetchFiltered,
    fetchResource: resourceFetch,
    setFilter: listFilterSet,
    fetchFilter: listFilter
};

export default function withSelectOptions() {
    return (DecoratedComponent) => {
        const Decorated = class Decorator extends React.PureComponent {
            updateOptions = () => {
                if (this.props.options) {
                    if (!Array.isArray(this.props.options)) {
                        this.optionsArray = Object.values(this.props.options);
                    }
                }
            };

            componentDidMount() {
                this.updateOptions();
                //this.fetch();
                if (!this.props.options.length) this.fetch();
            }

            fetch = () => {
                if (this.props.resource) {
                    if (this.props.resourceId) {
                        this.props.fetchResource(
                            this.props.resource,
                            this.props.resourceId
                        );
                    } else {
                        if (this.props.apiFilter) {
                            this.props.fetchFilter(
                                this.props.resourceApi || this.props.resource,
                                this.props.listId,
                                this.props.listId,
                                this.props.apiFilter
                            );
                        } else {
                            this.props.fetch(
                                this.props.resourceApi || this.props.resource,
                                this.props.listId,
                                this.props.filterId,
                                true
                            );
                        }
                    }
                }
            };

            componentDidUpdate(prevProps, prevState, snapshot) {
                if (changed(['options', 'resource', 'list'], prevProps, this.props)) {
                    this.updateOptions();
                }
                if (changed(['resource', 'filters.entry_type', 'filters.data_source'], prevProps, this.props)) {
                    this.fetch();
                }
            }

            handleChange = (value) => {
                const { multiple, nullable } = this.props;
                let newValue = value;
                if (!nullable && value === null) return;

                if (multiple) {
                    if (!newValue || !Array.isArray(newValue)) {
                        newValue = [];
                    } else {
                        newValue = value.slice()
                    }
                    if (!newValue.indexOf(value)) {
                        newValue.push(value);
                    }
                }
                this.props.onChange(this.props.id, newValue);
            };

            handleSearch = (id, value) => {
                console.log(id, value);
                this.props.setFilter(this.props.filterId, {
                    search: value
                });
            };

            render() {
                const options = this.optionsArray || this.props.options;
                const { displayResource } = this.props;

                return (<DecoratedComponent
                    {...this.props}
                    resource={displayResource}
                    options={options}
                    onChange={this.handleChange}
                    onSearch={this.props.filterId ? this.handleSearch : null}
                    filters={this.props.filters}
                />);
            }
        }
        return connect(mapState, mapDispatch)(Decorated);
    }
};