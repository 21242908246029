import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as ui from 'util/sagas/feedback';
import {listFetchSuccess, draftsPathAttach} from 'state/actions';
import {clipboardCopy} from 'state/actions/clipboard';
import {mergeEntities,selectResource} from 'state/selectors/resources';
import {selectCopiedResource, cloneComponent} from "state/selectors/clipboard";
import {denormalizeResource} from 'config/schema';

function* handleCopyResource(action) {
    try {
        const { resource, id } = action;
        let data = yield select((store)=>denormalizeResource(store.schema)(
            mergeEntities(store),
            resource,
            id
        ));
        let copy = cloneComponent(data);
        yield put(clipboardCopy(resource, copy.slice(0,1).pop().data));
        yield ui.info('Skopiowano ' + resource);
    } catch (e) {
        yield ui.error(e);
    }
}

function* handleCopyCollection(action) {
    try {
        const { resource, items } = action;
        const copy = yield items.map(function* (item) {
            let data = yield select(selectResource, resource, item);
            return cloneComponent(data).slice(0,1).pop().data;
        });
        let resourceType = [resource,'collection'].join('.');
        yield put(clipboardCopy(resourceType, copy));
        yield ui.info('Skopiowano ' + resourceType);
    } catch (e) {
        yield ui.error(e);
    }
}

function* handlePaste(action) {
    try {
        const { resource, path, context } = action;
        let resourceType = resource.replace('.collection', '');
        const item = yield select(selectCopiedResource, resource);
        if (item.project !== context.project) {
            yield ui.error('Copying items between projects is not currently supported');
            return;
        }
        if (item) {
            let data = item.data;
            let items = Array.isArray(data) ? data : [data];
            let copy = items.map(data=>{
                return cloneComponent(data).slice(0,1).pop().data;
            });
            yield put.resolve(listFetchSuccess(resourceType, {data: copy}));
            yield copy.map(function*(listItem) {
                console.log(listItem);
                yield put.resolve(draftsPathAttach(path, listItem.id));
            });
        }
    } catch (e) {
        yield ui.error(e);
    }
}

export default function*() {
    yield takeEvery('CLIPBOARD.COPY_COLLECTION', handleCopyCollection);
    yield takeEvery('CLIPBOARD.COPY_RESOURCE', handleCopyResource);
    yield takeEvery('CLIPBOARD.PASTE', handlePaste);
}