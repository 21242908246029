import React from 'react';
import {connect} from 'react-redux';

import { selectPath } from 'state/selectors/resources';
import { makeSortableList, connectDropArea } from 'containers/Sortable';

const mapStateToProps = (store, props) => {
    return {
        data: selectPath(store, props.path.split('/')) || [],
        definition: store.resources[props.resource],
    }
};

const mapDispatchToProps = {};

class NestedList extends React.PureComponent {

    render() {
        const {
            data,
            resource,
            actions,
            itemComponent,
            renderItem,
            itemProps,
            makeItemProps,
            selectedItem,
            wrapper
        } = this.props;

        const items =
            data.map((item, i) => {
                let id = typeof item === 'object'
                    ? item.id
                    : item;
                let props = {
                    ...itemProps,
                    resource,
                    key: id,
                    id: id,
                    index: i,
                    isSelected: id === selectedItem,
                    actions: actions
                };
                if (makeItemProps) props = makeItemProps(props);
                return itemComponent
                    ? React.createElement(
                        itemComponent,
                        props
                    )
                    : renderItem(props)
            });

        if (wrapper) return wrapper(this.props, items);
        return items;
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(makeSortableList(connectDropArea(NestedList)));