import React from 'react';
import PropTypes from 'prop-types';
import { icon } from 'util/icon';

export default class Radio extends React.PureComponent {

    handleChange = (value) => {
        this.props.onChange(value);
    };

    render() {
        const { options, value } = this.props;
        if (!options) return null;
        return (<div className="se-classes">
            {options.map((opt) => {
                let classIcon = icon('style.'+opt, 'inherit');
                let optionClass = opt === value ? 'se-class active' : 'se-class';
                if (classIcon) optionClass += ' se-class--icon';
                return (<div
                    key={opt}
                    className={optionClass}
                    onClick={()=>this.handleChange(opt)}
                >
                    {classIcon || opt}
                </div>);
            })}
        </div>);
    }

}

Radio.propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
};