import React from 'react';
import {connect} from 'react-redux';
import {query} from 'state/actions/route';
import Filters from 'containers/list/Filters';
import IconButton from 'components/TooltipIconButton';

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {
    query
};

class MobileFilter extends React.Component {

    handleShowFilters = () => {
        this.props.query({filters:this.props.open ? null : true});
    };

    render() {
        const {
            resource,
            filterId,
            listId,
            context
        } = this.props;

        return (<div className={'hide-desktop mobile-filters'}>
            <Filters
                resource={resource}
                filterId={filterId}
                listId={listId}
                context={context}
                mobile={true}
            />
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MobileFilter);