import React from "react";
import { connect } from "react-redux";
import ComponentsTree from "components/fields/components/ComponentsTree";
import ComponentsDialog from "components/fields/components/Components";
import Resource from "containers/Resource";

import { query } from "state/actions/route";
import { draftsPathCreate, draftsPathChange } from "state/actions";

import CreateComponent from "components/fields/components/CreateComponent";
import SectionInfo from "ide/sidepanel/dev/SectionInfo";

const mapStateToProps = (store, props) => {
  let resourcePath =
    store.context.cp && store.context.cp !== "root"
      ? ["cms.components", store.context.cp, "components"]
      : ["cms.sections", store.context.sc, "items"];
  return {
    section: store.context.sc || store.context.relatedId,
    parent: store.context.scr,
    stylesReady: !!store.data["theme.styles"],
    storeId: "data",
    path: resourcePath.join("/"),
    editComponent: store.context.cpe,
  };
};

const mapDispatchToProps = {
  query,
  onCreate: draftsPathCreate,
  onChange: draftsPathChange,
};

class Components extends React.PureComponent {
  handleCreate = (type) => {
    //alert(type);
    this.props.onCreate("cms.components", this.props.path.split("/"), {
      element_type: type,
    });
  };

  handleChange = (key, value) => {
    let id = this.props.section;
    let path = ["cms.sections", id, key];
    this.props.onChange(path, value);
  };

  handleEdit = (item) => {
    this.props.query({ cpe: item });
  };

  render() {
    const { storeId, section, parent } = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          maxWidth: "100%",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              padding: "1rem",
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            {parent ? (
              <Resource
                resource="cms.sections"
                id={parent}
                render={(props) => (
                  <SectionInfo
                    data={props.data}
                    active={section === props.data.id}
                  />
                )}
              />
            ) : null}
            <Resource
              resource="cms.sections"
              id={section}
              render={(props) => (
                <React.Fragment>
                  <SectionInfo
                    data={props.data}
                    active={section === props.data.id}
                    template={!!parent}
                    value={section}
                  />
                  {props.data.template && !parent ? (
                    <Resource
                      resource="cms.sections"
                      id={props.data.template}
                      render={(props) => (
                        <>
                          <SectionInfo
                            data={props.data}
                            section={parent || section}
                            active={section === props.data.id}
                            template={true}
                            value={props.id}
                            onChange={
                              this.props.onChange ? this.handleChange : null
                            }
                          />
                          {props.data.template && (
                            <Resource
                              resource={"cms.sections"}
                              id={props.data.template}
                              render={(props) => (
                                <SectionInfo
                                  data={props.data}
                                  section={parent || section}
                                  active={section === props.data.id}
                                  template={false}
                                  value={props.id}
                                  onChange={
                                    this.props.onChange
                                      ? this.handleChange
                                      : null
                                  }
                                />
                              )}
                            />
                          )}
                        </>
                      )}
                    />
                  ) : null}
                  {!props.data.template && !parent ? (
                    <SectionInfo
                      data={{}}
                      template={true}
                      value={null}
                      onChange={this.handleChange}
                    />
                  ) : null}
                </React.Fragment>
              )}
            />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <ComponentsTree
            resource="cms.components"
            path={"cms.sections/" + section + "/items"}
            storeId={storeId}
            childKey={"components"}
            onEdit={this.handleEdit}
          />
          <ComponentsDialog
            dialogOnly={true}
            resource="cms.components"
            path={"cms.sections/" + section + "/items"}
            selectedItem={this.props.editComponent || false}
            onEdit={this.handleEdit}
            onClick={this.handleEdit}
          />
          <div>
            <CreateComponent
              resource="cms.components"
              onCreate={this.handleCreate}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Components);
