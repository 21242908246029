import Radio from './Radio';
import Select from './Select';
import Default from './Default';
import Colors from './Colors';

export default {
    radio: Radio,
    colors: Colors,
    select: Select,
    default: Default
}