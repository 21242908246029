import { put, race, fork, take, select } from "redux-saga/effects";
import * as actions from "state/actions/ui";

export function* error(e) {
    yield fork(put,actions.feedback(e.message, "error"));
    console.log(e);
}

export function* success() {
    yield fork(put,actions.feedback("Zapisano", "success"));
}

export function* info(msg) {
    //yield put(actions.feedback(msg, "info"));
    yield put(actions.feedback(msg, "info"));
}

export function* pending(id, state) {
    yield put.resolve(actions.pending(id, state));
}

export function* confirm(message) {
    yield put(actions.confirmShow(message));
    const { yes } = yield race({
        yes: take("UI.CONFIRM.YES"),
        no: take("UI.CONFIRM.NO")
    });
    yield put(actions.confirmHide());
    return !!yes;
}

export function* prompt(id) {
    yield put(actions.promptShow(id));
    const { confirm } = yield race({
        confirm: take('UI.PROMPT.CONFIRM'),
        cancel: take('UI.PROMPT.CANCEL')
    });
    if (confirm) {
        const value = yield select((store) => {
            if (!store.drafts.forms) return false;
            return store.drafts.forms[id];
        });
        if (!value) return yield prompt(id);
        yield put(actions.promptHide());
        return value;
    } else {
        yield put(actions.promptHide());
        return false;
    }
}