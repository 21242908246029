import React from 'react';
import Image from 'ide/components/Image';
import Text from 'ide/components/Text';
import {webfont_icon} from './icon';

import Toolbar from 'ide/ui/Toolbar';
import ToggleIcon from 'ide/ui/ToggleIcon';
import ImagePicker from 'ide/ui/ImagePicker';
import ImagePlaceholder from 'ide/ui/ImagePlaceholder';

const typeIcons = {
    image: 'fh-circle-plus',
    google_pano: 'fa-street-view',
    hosted_pano: 'fa-street-view',
    video: 'fa-play',
    default: 'fh-circle-plus'
};
const typeDescriptions = {
    google_pano: '360&deg;',
    hosted_pano: '360&deg;'
};

const CardOverlayContent = (props) => {
    const type = props.media.type;
    const icon = typeIcons[type] || typeIcons.default;
    const description = typeDescriptions[type] || null;
    return (<React.Fragment>
        <div className="card__overlay_icon">{webfont_icon(icon)}</div>
        { description ? <div dangerouslySetInnerHTML={{__html: description}} /> : null }
    </React.Fragment>)
};

const CardInfoOverlayContent = (props) => {
    const type = props.media.type;
    if (['google_pano', 'hosted_pano'].indexOf(type) > -1) {
        return (<div className="overlay t-on-image card__media_info_overlay">
            <div>
                {webfont_icon(typeIcons[type])}
                <div dangerouslySetInnerHTML={{__html: typeDescriptions[type]}} />
            </div>
        </div>)
    }
    return null;
};

export default class Card extends React.PureComponent {

    constructor(props) {
        super(props);
        this.imgRef = React.createRef();
        this.state = {ready: false};
    }
    
    handleImageLoad = () => {
        this.setState({ready: true});
    };

    render() {
        const { s, className, data, editable } = this.props;
        const cover = data.media ? data.media[0] : null;
        const cardClassName = "card gallery-item " + s.get("item") + " " + s.get('card') + " " + (className || '');
        const children = this.props.children;
        const showDescription = !!s.get('show_description');
        let contentClass = 'card__content ' + s.get('content');
        let isPano = cover && ['google_pano', 'hosted_pano'].indexOf(cover.type) > -1;
        if (!cover) contentClass += ' ignore-events';

        return (<div
            className={cardClassName}
            {...this.props.events}
            onContextMenu={()=>alert('context')}
        >
            { cover ? (
                <ImagePicker
                    onChange={this.props.onChange}
                    trigger={(props)=><Image
                        {...props}
                        onContextMenu={()=>alert('context')}
                        onLoad={this.handleImageLoad}
                        imgRef={this.imgRef}
                        media={cover}
                        size={s.get('media_size')}
                        className={"card__image " + s.get("image")}
                        overlayContent={<CardOverlayContent media={cover}/>}
                        infoOverlayContent={<CardInfoOverlayContent media={cover} />}
                        overlayClassName={"overlay card__overlay " + s.get("overlay")}
                        outerClassName={"card__image_outer " + s.get("image_outer")}
                    />}
                />
            ) : <div
                ref={this.imgRef}
                className={"card__image_outer " + s.get('image_outer')}
            >
                <ImagePicker
                    onChange={this.props.onChange}
                    trigger={(props)=><ImagePlaceholder {...props}/>}
                />
            </div>}

            { children
                ? (<div className={contentClass}>
                    {children}
                </div>)
                : (<Text
                    {...this.props}
                    className={contentClass}
                    showText={showDescription}
                />) }
            { editable && (!cover || this.state.ready) ? <Toolbar
                id={this.props.id || this.props.itemId}
                open={true}
                anchorEl={this.imgRef.current}
                placement="top-end"
                type="card"
            >
                { isPano
                    ? <ToggleIcon
                        icon="save_alt"
                        value={cover}
                        property={"embed"}
                        onChange={(media)=>{
                            console.log(media);
                            this.props.onChange('media', [media])
                        }}
                    />
                    : null }
                <ImagePicker onChange={this.props.onChange} />
            </Toolbar> : null }
        </div>);
    }

}