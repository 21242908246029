import React from "react";

import Edit from "ide/ui/Edit";

export default class Info extends React.PureComponent {
  render() {
    const { data, onFocus, onChange } = this.props;
    return (
      <Edit onClick={onFocus} onChange={onChange} fieldKey="text" type="rich">
        {data.text}
      </Edit>
    );
  }
}
