/* eslint-disable */
import CssClassNameBag from './CssClassNameBag';

const array_unique = require("locutus/php/array/array_unique");
const array_merge = require("locutus/php/array/array_merge");
const str_replace = require("locutus/php/strings/str_replace");
const strpos = (haystack, needle) => {
  return haystack.indexOf(needle);
};

class Scope {
  constructor(ns, styles, parentScope = undefined) {
    this.ns = ns;
    this.styles = styles;
    this.parentScope = parentScope;
  }

  __toString() {
    var ret = String(this.styles.fetch(this.ns, this.parentScope).__toString());
    return str_replace("@all", "", ret).trim();
  }

  __get(key) {
    var namespaces = this.getAllNamespaces(key);

    var ret = this.styles.fetchNs(namespaces, key).__toString();

    ret = str_replace("@all", "", ret).trim();
    ret = str_replace("  ", " ", ret);
    return ret;
  }

  tag(tags, append = false) {
    if (!tags) tags = Array();
    if (append && Array.isArray(this.tags)) tags = array_merge(this.tags, tags);
    this.tags = tags;
    return this;
  }

  extend(key = undefined, classNames) {
    var ns = key ? this.ns + "." + key : this.ns;
    var ret = this.styles.fetch(ns, this.parentScope);
    ret = ret
      .extend(new CssClassNameBag(classNames), this.styles.getOptions())
      .__toString();
    return str_replace("@all", "", ret).trim();
  }

  __call(
    method,
    args //if ($sub) $nsParts[] = '.'.$sub;
  ) //$ns = str_replace($method.'.', '', $ns);
  //echo $fNs . '/' . $this->ns . '.' . $method;
  //echo PHP_EOL;
  {
    var ns = undefined !== args[0] && args[0] ? args[0] : "default";
    var p = ns.split(".");
    var type = p.length > 1 ? p[0] : method;
    var id = p.length > 1 ? p[1] : p[0];
    var sub = p.length > 1 ? method : undefined;
    var parentNs = this.ns;
    parentNs += "." + method;
    var nsParts = [type];
    nsParts.push(":" + id);
    var fNs = nsParts.join("");
    var parent = new Scope(parentNs, this.styles, this);
    var scope = new Scope(fNs, this.styles, parent);
    scope.tag(this.tags);
    return scope;
  }

  getNamespace() {
    return this.ns;
  }

  getParentScope() {
    return this.parentScope;
  }

  getAllNamespaces(component) {
    if (strpos(component, "@") > -1) {
      return [component];
    }

    var scope = this;
    var lookupScopes = Array();

    if (this.tags) {
      var tagConfig = this.styles.getNs(this.ns, "@tags");

      if (tagConfig) {
        for (var tag of Object.values(this.tags)) {
          if (undefined !== tagConfig[tag]) {
            lookupScopes.push(tagConfig[tag] + "." + component);
          }
        }
      }
    }

    lookupScopes.push(scope.getNamespace() + "." + component);
    var rootNsParts = scope.getNamespace().split(":");

    while ((scope = scope.getParentScope())) {
      var ns = scope.getNamespace();
      var nsParts = ns.split(".");
      ns = nsParts[0] + "." + component;
      lookupScopes.push(ns);
      nsParts = ns.split(":");
      lookupScopes.push(nsParts[0] + "." + component);
    }

    lookupScopes = Object.values(array_unique(lookupScopes));
    lookupScopes.push(rootNsParts[0] + "." + component);
    lookupScopes.push(component);
    return lookupScopes;
  }

  attr(attributes) {
    var attrs = String(this.styles.getNs(this.ns, "@attributes"));
    attrs = attrs.split(" ");
    var merge = Array();

    for (var attr of Object.values(attrs)) {
      var attrValue = attr.split(":");
      var value =
        undefined !== attrValue[1] && attrValue[1] ? attrValue[1] : attr;
      var key = undefined !== attrValue[1] ? attrValue[0] : attr;
      merge[key] = value;
    }

    return array_merge(attributes, merge);
  }
}

export default Scope;
