import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import ConsoleThemeProvider from 'containers/ConsoleThemeProvider';
import configureStore, {history} from './state/store';
//import HTML5Backend from "react-dnd-html5-backend";
//import {DragDropContextProvider} from "react-dnd";

import {defaultNotifier} from "why-did-you-update/es/defaultNotifier";



import Echo from "laravel-echo";

const {whyDidYouUpdate} = require('why-did-you-update');

//whyDidYouUpdate(React, {include: [/^ConnectedComponent/]});
//whyDidYouUpdate(React);
let notifierCount = 0;
const notifier = (groupByComponent, collapseComponentGroups, displayName, diffs) => {
    if (notifierCount > 20) {
        setTimeout(function() { notifierCount=0; }, 1000);
        return;
    }
    defaultNotifier(groupByComponent, collapseComponentGroups, displayName, diffs);
    notifierCount++;
};
const excludes = [
    /^WithStyles/,
    /^WithFormControlContext/,
    /^NoSsr/,
    /Icon/,
    /Popper/,
    /Typography/,
    /NotchedOutline/,
    /Avatar/,
    /ListItemText/,
    /ButtonBase/,
    /Menu/,
    /Popover/,
    /MergeListContext/,
    /ListItemSecondaryAction/,
    /List/,
    /PositionedFormField/,
    /FormLabel/,
    /InputLabel/,
    /SelectInput/,
    /OutlinedInput/,
    /InputBase/,
    /Portal/,
    /DropArea/,
    /DropTarget/,
    /RenderResourceListItem/,
];
//whyDidYouUpdate(React, { exclude: excludes });

const store = configureStore();

window.io = require('socket.io-client');

window.Echo = new Echo({
    broadcaster: 'socket.io',
    host: window.ECHO_SERVER
});

const render = Component => {
    return ReactDOM.render(<Provider store={store}>
            <ConsoleThemeProvider>
                <ConnectedRouter history={history}>
                    <App/>
                </ConnectedRouter>
            </ConsoleThemeProvider>
    </Provider>, document.getElementById('root'));
};

render(App);

if (module.hot) {
    module.hot.accept('./App', () => {
        const NextApp = require('./App').default;
        render(NextApp);
    });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
