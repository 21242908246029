import React from 'react';

import CmsSnapshots from 'containers/dialogs/CmsSnapshots';
import ThemeVcs from 'containers/dialogs/ThemeVcs';
import {CreateWebsiteDialog} from 'containers/dialogs/CreateWebsite';

export default class Dialogs extends React.PureComponent {

    render() {
        return (<React.Fragment>
            <CmsSnapshots/>
            <ThemeVcs />
            <CreateWebsiteDialog maxWidth="md" fullWidth={true} />
        </React.Fragment>);
    }

}