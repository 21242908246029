import React from 'react';
import ConnectedComponent from 'ide/Component';

export default class Layout extends React.PureComponent {

    renderChildren = (children) => {
        if (!children) return null;

        const { s, areaKey } = this.props;

        const embed = s.bool('embed');
        let area = areaKey || 'area';
        const areaClass = s.get(area);

        return children.map((item, i)=>{
            return embed ? (
                <ConnectedComponent
                    s={s}
                    id={item}
                    className={areaClass}
                    key={item}
                />
            )
            : (<div key={item} className={areaClass}>
                <ConnectedComponent
                    s={s}
                    id={item}
                />
            </div>);
        });
    };

    render() {
        const { s, data, layoutKey, childKey, className } = this.props;
        let layout = layoutKey || 'layout';
        let child = childKey || 'components';
        let layoutClass = className + ' ' + s.get(layout);
        const children = data[child];

        return (<div
            className={layoutClass}
            {...this.props.events}
        >
            {this.renderChildren(children)}
        </div>);
    }

}