export default [
    'cms.pages',
    'cms.entries.offers',
    'cms.entries.rooms',
    'cms.entries.rooms_conf',
    'cms.entries.gallery',
    'cms.entries.attractions',
    'cms.entries.reviews',
    'cms.entries.posts',
    'cms.navigation',
    'apartments.flats',
    'apartments.leads',
    'console.services.website',
    'console.domains',
    'console.themes',
    'console.services.cms',
    'console.users'
];

export const asideHide = [
    'db.types',
    'db.filters',
    'db.list-views',
    'db.form-views',
    'db.enum-definitions',
    'db.notes'
];