import validation from './validation';
import resource from './resource';
import resourceAction from './resource-action';
import app from './app';
import route from './route';
import ide from './ide';
import library from './library';
import upload from './upload';
import console from './console';
import media from './media';
import notifications from './notifications';
import clipboard from './clipboard';
import jobs from './jobs';
import messages from './messages';
import dev from './dev';

export default [
    app,
    route,
    resource,
    validation,
    resourceAction,
    ide,
    library,
    upload,
    media,
    console,
    notifications,
    clipboard,
    jobs,
    messages,
    dev
];