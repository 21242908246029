import React from 'react';
import {connect} from 'react-redux';
import ListMetaSummary from "./ListMetaSummary";
import ListSyncTasks from "./ListSyncTasks";

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

class ListMeta extends React.PureComponent {

    render() {
        return (<>
            <ListSyncTasks {...this.props} />
            <ListMetaSummary {...this.props} />
        </>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListMeta);