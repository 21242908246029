import _ from 'lodash';

export const changed = (keys, prevProps, props) => {
  if (!prevProps) return false;
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i];
    let path = key.split('.');
    if (_.get(prevProps,path) !== _.get(props,path)) return true;
  }
  return false;
};
