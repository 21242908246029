import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'

import context from './context';
import schema from './schema';
import resources from './resources';
import data from './data';
import lists from './lists';
import filter from './filter';
import drafts from './drafts';
import ui from './ui';
import clipboard from './clipboard';
import validation from './validation';
import listsMeta from './lists-meta';

const rootReducer = (history) => combineReducers({
    context,
    resources,
    data,
    drafts,
    lists,
    listsMeta,
    filter,
    schema,
    ui,
    clipboard,
    validation,
    router: connectRouter(history)
});

export default rootReducer
