import { push as routerPush } from 'connected-react-router';
import pathToRegexp from "path-to-regexp";

export const push = (route, params) => {
    const toPath = pathToRegexp.compile(route);
    const path = toPath(params);
    return routerPush(path);
};

export const query = (params) => {
    return {
        type: "ROUTE.QUERY",
        params
    }
};

export const resourceRoute = (route, params, query) => {
    return {
        type: "ROUTE.PUSH",
        route,
        params,
        query
    }
};