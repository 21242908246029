import React from 'react';
import { connect } from 'react-redux';
//import PropTypes from 'prop-types';
import DataContext, {MergeDataContext} from 'ide/DataContext';
import ConnectedComponent from 'ide/Component';
import Slider from 'ide/components/Slider';

import Toolbar from 'ide/ui/Toolbar';
import IconButton from 'components/IconButton';
import { selectResource } from 'state/selectors/resources';

import { styled } from 'state/selectors/styles';

const makeMapStateToProps = () => {
    return (store, props) => {
        let parts = props.ctxPath.split('/');
        let data = selectResource(store, parts[0], parts[1]);
        let s = !data.style
            ? props.s
            : styled(store, props, 'card', data, props.index);
        return {
            s: s,
            ps: props.s
        }
    };
};
const StyledListItem = connect(makeMapStateToProps)((props) =>
    props.render(props.item, props.s, props.ps, props.index));

function resolveItemTemplate(repeat, index, components) {
    let templates = components.length;
    if (repeat === 'loop') {
        if (index+1 > templates) {
            return components[index%(templates)];
        }
        return components[index];
    }
    if (repeat === 'start') {
        if (index+1 > templates) {
            return components[templates-1];
        }
        return components[index];
    }
    return components[0];
}

export default class List extends React.PureComponent {

    renderItem = (item, s, ps, index) => {

        const type = s.get('item_type').replace(/\s/g, '');
        const repeat = s.get('item_repeat').replace(/\s/g, '');

        const { components, itemProps, path, mapping } = this.props;

        if (type === 'template' && components && components.length) {
            let component = resolveItemTemplate(repeat, index, components);
            return (<ConnectedComponent
                id={component}
                className={'l-area ' + s.get('item')}
                s={ps}
                itemId={item}
                {...itemProps}
            />)
        }
        if (type === 'template-content') {
            return (<ConnectedComponent
                virtual={true}
                path={path}
                type="card"
                s={s}
                mapping={mapping}
                itemId={item}
                {...itemProps}
            >
                { components && components.length ? (<ConnectedComponent
                    id={resolveItemTemplate(repeat, index, components)}
                    s={ps}
                />) : null }
            </ConnectedComponent>);
        }

        if (type === 'media' || type === 'media-element') {
            return (<ConnectedComponent
                virtual={true}
                path={path}
                type="media-element"
                s={s}
                mapping={mapping}
                itemId={item}
                {...itemProps}
            />);
        }

        return (<ConnectedComponent
            virtual={true}
            path={path}
            type="card"
            className="l-area"
            s={s}
            mapping={mapping}
            itemId={item}
            {...itemProps}
        />);
    };

    renderSlider = () => {
        return (
            <Slider
                {...this.props}
                onFocus={this.handleFocus}
                domRef={this.props.domRef}
            >
                {this.renderItems()}
            </Slider>
        );
    };

    renderItems = () => {
        const { items, s, dsitemPath } = this.props;
        return items && items.length
            ? items.map((item, index) => {
                let itemPath = dsitemPath + '/' + item;
                return (<MergeDataContext key={item} value={itemPath}>
                    <StyledListItem
                        item={item}
                        s={s}
                        index={index}
                        ctxPath={itemPath}
                        path={this.props.path}
                        render={this.renderItem}
                    />
                </MergeDataContext>);
            })
            : (<div className={"l-area " + s.get('item')}>
                <div className="image-placeholder" onClick={this.props.onCreateItem}>
                    no items
                </div>
            </div>);
    };

    renderToolbar = () => {
        const {
            id,
            hover,
            anchorEl,
            dstype,
            editable,
            onCreateItem,
            onDeleteItem
        } = this.props;
        return editable && dstype === 'items' ? <Toolbar
            id={id}
            open={!!hover}
            anchorEl={anchorEl}
            placement={"bottom"}
        >
            <IconButton onClick={onDeleteItem} icon="delete" />
            <IconButton onClick={onCreateItem} icon="add" />
        </Toolbar> : null
    };

    render() {
        const {
            s,
            events
        } = this.props;

        const listType = s.get('list_type');

        if (listType === 'slider') {
            return this.renderSlider();
        }

        const { filtering_mode } = this.props.data;
        const showFilter = filtering_mode === 2;

        if (showFilter) {
            let filterStyle = s.get('filter_style');
            return (<div className={s.get('filter_layout')}>
                <ConnectedComponent
                    virtual={true}
                    type="nav"
                    className={'l-area'}
                    s={s.fork('nav', filterStyle)}
                    path={this.props.path}
                    navType="cms.tags"
                    navPath={[this.props.dspath,'tags'].join('.')}
                />
                <div {...events} className={'l-area ' + s.get('list')}>
                    {this.renderItems()}
                </div>
            </div>);
        }

        return (<div {...events} className={s.get('list')}>
            {this.renderItems()}
            {this.renderToolbar()}
        </div>);
    }

}

List.propTypes = {};