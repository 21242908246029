import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {webfont_icon} from 'ide/components/icon';

import Button from './Button';

function renderDate(s, dateIcon) {
    const dFormat = s.get("format_day") || 'DD';
    const mFormat = s.get("format_month") || 'MMM';
    const yFormat = s.get("format_year");
    const d = moment().format(dFormat);
    const m = moment().format(mFormat);
    const y = yFormat ? moment().format(yFormat) : null;
    return <div className={s.get("date_value")}>
        <div className={s.get("day")}>{d}</div>
        <div className={s.get("month")}>{m}</div>
        { yFormat ? <div className={s.get("year")}>{y}</div> : null }
        { dateIcon ? <div className={s.get("date_icon")}>{webfont_icon(dateIcon)}</div> : null }
    </div>;
}

export default class Booking extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            checkin: null,
            checkout: null
        };
    }

    componentDidMount() {
        this.showDates();
    }

    showDates = () => {

    };

    render() {

        const {data, s} = this.props;
        const {date_icon, promo_code_icon, nullable, placeholder_icon} = data;

        return (
            <div className={s.get("layout")}>
                <div className={"l-area " + s.get("quickbook_form")}>
                    <div className={"l-area " + s.get("dates")}>
                        <div className={"l-area " + s.get("date")}>
                            <div className={s.get("date_label")}>Przyjazd</div>
                            {renderDate(s, date_icon)}
                        </div>
                        <div className={"l-area " + s.get("date")}>
                            <div className={s.get("date_label")}>Wyjazd</div>
                            {renderDate(s, date_icon)}
                        </div>
                    </div>
                    {data.show_promo_code ? <div className={"l-area " + s.get("promo_code")}>
                        {data.promo_code_label ?
                            <div className={s.get("promo_code_label")}>{data.promo_code_label}</div> : null}
                        <div className={s.get("promo_code")}>
                            <input
                                className={s.get("promo_code_input")}
                                placeholder={data.promo_code_placeholder || null}
                                value={data.promo_code_value || null}
                            />
                            {promo_code_icon
                                ? <div className={s.get('promo_code_icon')}>
                                    {webfont_icon(promo_code_icon)}
                                </div>
                                : null}
                        </div>
                    </div> : null}
                </div>
                <Button {...this.props} />
            </div>
        );
    }

}

Booking.propTypes = {};