import React from 'react';
import {connect} from 'react-redux';
import Card from '@material-ui/core/Card';
import ItemActions from './ItemActions';
import {thumbnail} from 'util/thumbnail';

const mapStateToProps = (store, props) => {
    const media = props.data.media;
    return {
        cover: media ? media[0] : {}
    }
};

const mapDispatchToProps = {};

class GalleryCard extends React.Component {

    render() {
        const { connectDnd, resource, id, cover, data } = this.props;
        const cardStyle = {};
        cardStyle.height = '120px';
        cardStyle.position = 'relative';
        if (cover && cover.file) {
            cardStyle.color = 'white';
            cardStyle.backgroundImage = 'url("'+thumbnail(cover.file, 'sm')+'")';
        }
        return connectDnd(<div>
            <Card style={cardStyle} onClick={this.props.onClick}>
                <div className={'gallery-card__overlay'}>
                    <div className={'gallery-card__footer'}>
                        <div>
                            {data.title}
                        </div>
                        <ItemActions
                            resource={resource}
                            id={id}
                            actions='default'
                            isOpen={false}
                        />
                    </div>
                </div>
            </Card>
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GalleryCard);