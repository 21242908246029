import React from 'react';
import PropTypes from 'prop-types';
import {Grid as VirtualizedList, AutoSizer} from "react-virtualized";

export const VirtualizedGridIterator = (props, Item) => {
    const { data, ...other } = props;
    const render = (cell) => {
        let index = (cell.rowIndex*2) + cell.columnIndex;
        let item = data[index];
        return <Item
            key={item}
            index={index}
            id={item}
            style={cell.style}
            {...other}
        />;
    };
    return (<AutoSizer>{({height, width}) => <VirtualizedList
        width={width}
        height={height}
        columnCount={2}
        columnWidth={width/2}
        rowHeight={280}
        overscanRowCount={0}
        rowCount={Math.ceil(data.length/2)}
        cellRenderer={render}
    />}</AutoSizer>);
};

export const VirtualizedListIterator = (props, Item) => {
    const { data, ...other } = props;
    const render = (cell) => {
        let index = cell.rowIndex;
        let item = data[index];
        return <Item
            key={item}
            index={index}
            id={item}
            style={cell.style}
            {...other}
        />;
    };
    return (<AutoSizer>{({height, width}) => <VirtualizedList
        width={width}
        height={height}
        columnCount={1}
        columnWidth={width}
        rowHeight={63}
        overscanRowCount={0}
        rowCount={data.length}
        cellRenderer={render}
    />}</AutoSizer>);
};