import React from 'react';
import PropTypes from 'prop-types';
import SelectField from 'v2/fields/Select';

export default class Select extends React.PureComponent {

    render() {
        return (<SelectField
            {...this.props}
            radio={false}
            variant={'inline'}
        />);
    }

}

Select.propTypes = {};