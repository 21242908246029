import React from 'react';
import {connect} from 'react-redux';
import {selectResource} from 'state/selectors/resources';
import moment from 'moment';
import Resource from 'v2/Resource';
import Field from 'v2/Field';
import Avatar from '@material-ui/core/Avatar';
import { thumbnail } from "util/thumbnail";

const mapStateToProps = (store, props) => {
    let data = selectResource(store, props.resource, props.id);
    return {
        data,
        created_at: moment.unix(data.created_at)
    }
};

const mapDispatchToProps = {};

const avatarStyle = {
    width: '40px',
    height: '40px',
    marginRight: '1rem'
};

class Message extends React.Component {

    render() {
        const { data, created_at, resource, id, onClick, onDelete } = this.props;
        return (<div className={'message'}>
            <Resource
                resource="db.identities"
                id={data.identity}
                fetchPolicy="yield"
            >
                {(identity) => {
                    return <React.Fragment>
                        <div className={'message__avatar'}>
                            { identity.data.media && identity.data.media.length ? <Resource resource={'db.media'} id={identity.data.media[0]} fetchPolicy={'never'}>
                                {({data})=><Avatar
                                    src={thumbnail(data.file, [64, 64])}
                                    style={avatarStyle}
                                />}
                            </Resource> : null }
                        </div>
                        <div className={'message__content'}>
                            <div className={'message__header'}>
                                <div className={'message__author'}>
                                    {identity.data.name}
                                </div>
                                <div className={'message__time'}>{created_at.format('H:mm')}</div>
                            </div>
                            <div className={'message__body'}>
                                <Field resource={resource} id={id} fieldName={'content'}>
                                    {(props)=>{
                                        return <div className={'message__text'}>{props.value}</div>
                                    }}
                                </Field>
                            </div>
                        </div>
                    </React.Fragment>
                }}
            </Resource>
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Message);