import React, {Component} from 'react';
import { connect } from 'react-redux';
import { selectDevice } from 'state/selectors/ide';

import AppContext from 'containers/AppContext';
import ThemeProvider from 'ide/ThemeProvider';
import Page from 'ide/containers/Page';
import Styled from 'ide/containers/Styled';

import 'css/ide.css';
import 'css/style-editor.css';
import 'css/ide-layout.css';


import FabContainer from 'ide/sidepanel/Fab';
import Confirm from "../../containers/dialogs/Confirm";
import Snackbar from "../../containers/Snackbar";
import Sidepanel from 'ide/sidepanel/Sidepanel';
import CmsDialogs from 'views/cms/Dialogs';
import Keymap from 'containers/keys/Keymap';
import DragLayer from 'components/DragLayer';
import AttachSection from 'containers/dialogs/AttachSection';
import Dialogs from 'views/Dialogs';

const mapState = (store, props) => {
    return {
        zoomOut: store.ui.ideZoomOut,
        sidebarUndock: store.ui.flags.ideSidebarUndock,
        hideOverlays: store.ui.flags.ideHideOverlays,
        sectionBrowser: store.context.q === 'attach-section',
        key: [store.context.lang,store.context.project].join('.'),
        device: selectDevice(store),
        largeSidebar: store.context.dev === 'editor'
            || (store.context.dev === 'preset' && store.context.t === 'typography.styles')
    }
};

class Wrapper extends React.PureComponent {
    render() {
        const { sidebarUndock, hideOverlays, zoomOut, sectionBrowser, largeSidebar, render } = this.props;
        let layout = 'zoomed-in';
        if (zoomOut) layout = 'zoomed-out';
        if (!sidebarUndock) layout += ' with-sidebar';
        if (sidebarUndock) layout += ' undocked-sidebar';
        if (largeSidebar) layout += ' sidebar-lg';
        if (sectionBrowser) layout = 'zoomed-out sections';
        if (hideOverlays) layout += ' hide-overlays';
        return render({...this.props, layout});
    }
}

const ConnectedWrapper = connect(mapState)(Wrapper);

export default class index extends Component {

    render() {
        return (<AppContext key={this.props.key} app="cms" watch={["project", "lang"]}>
            <ConnectedWrapper
                render={(props)=>
                    <React.Fragment>
                    <Keymap id="ide" className={props.layout} active global>
                        <Sidepanel/>
                        <div className="ide-page">
                            <ThemeProvider>
                                <Styled render={({s}) => (
                                    <Page s={s} device={props.device} />
                                )} />
                            </ThemeProvider>
                        </div>
                        { props.layout === 'zoomed-out sections'
                            ? <AttachSection/>
                            : null }
                        <FabContainer />
                    </Keymap>
                    </React.Fragment>
                }
            />
            <Dialogs />
            <Confirm />
            <Snackbar/>
            <DragLayer />
        </AppContext>);
    }

}