import React from 'react';
import PropTypes from 'prop-types';

import ActualFormField, {RenderField} from 'v2/Field';
import FormViewModeContext from 'v2/form-builder/FormViewModeContext';
import IconButton from 'components/TooltipIconButton';
import {RenderValue} from 'v2/list-items';
import classNames from 'classnames';

function RenderValueField(props) {
    let classes = {
        'info-field': true
    };
    let type = props.schema.type;
    classes['info-field--'+type] = true;

    /*return <tr>
        <td>{props.label || props.slug}</td>
        <td><RenderValue {...props} readOnly={true} /></td>
    </tr>;*/

    let readOnly = props.mode === 'info';

    return (<div className={classNames(classes)}>
        {props.schema.form_state}
        <div className={'info-field__label'}>
            {props.label || props.slug}
        </div>
        <div className={'info-field__value'}>
            <RenderValue {...props} readOnly={readOnly} />
            {/** props.mode === 'info-edit' ? <IconButton icon="edit" size="small" /> : null **/}
        </div>
    </div>);
}

const fieldPlaceholderStyle = {
    padding: '1rem',
    border: 'dashed 2px rgba(0,0,0,0.1)',
    borderRadius: '4px',
    fontSize: '12px',
    opacity: 0.5
};

export class FieldTypePlaceholder extends React.PureComponent {
    render() {
        return <div style={fieldPlaceholderStyle}>
            Drag schema field from the list on the left
        </div>;
    }
}

export default class FormField extends React.PureComponent {

    render() {
        const { data, resource, id, editable } = this.props;
        if (data.source) {
            return <ActualFormField
                resource={resource}
                id={id}
                fieldId={data.source}
            >
                {(props)=>{
                    return <FormViewModeContext.Consumer>
                            {mode=>{
                                if (mode === 'info') {
                                    return <RenderValueField mode={mode} {...props} {...data.properties} />;
                                }
                                if (mode === 'info-edit') {
                                    return <RenderValueField mode={mode} {...props} {...data.properties} />;
                                }
                                return <div className="form-field">
                                    <RenderField {...props} {...data.properties} />
                                </div>;
                            }}
                        </FormViewModeContext.Consumer>;
                }}
            </ActualFormField>
        }
        return <FieldTypePlaceholder />;
    }

}

FormField.propTypes = {};