import React from 'react';
import {connect} from 'react-redux';
import {draftsChange} from 'state/actions';
import SelectMenu from 'components/fields/select/SelectMenu';

const selectNamespacedStyles = (store, ns) => {
    let styles = store.data['theme.styles'] || {};
    let filtered = Object.keys(styles).filter((s)=>{
        return s.indexOf(ns) === 0 && s !== ns;
    });
    return filtered;
};

const mapStateToProps = (store, props) => {
    let resourcePath = store.context.cp && store.context.cp !== 'root'
        ? ['cms.components', store.context.cp]
        : ['cms.sections', store.context.sc];
    return {
        resourcePath,
        styles: [{id: props.ns, label: 'default'}].concat(selectNamespacedStyles(store, props.ns))
    }
};

const mapDispatchToProps = {
    onChange: draftsChange
};

class ChangeStyle extends React.PureComponent {

    handleChange = (value) => {
        //this.props.onClose();
        if (value === this.props.ns) value = null;
        this.props.onChange(
            this.props.resourcePath,
            { style: value }
        );
    };

    handlePreview = (value) => {
        //console.log(value);
        //this.handleChange(value);
    };

    render() {
        return (<SelectMenu
            trigger={this.props.trigger}
            options={this.props.styles}
            onSelect={this.handleChange}
            onMouseOver={this.handlePreview}
            value={this.props.value}
        />);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangeStyle);