import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import Field from "v2/Field";
import UserInfo from "v2/fields/info-fields/UserInfo";

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

function Datetime(props) {
    const format = 'D MMM, H:m';
    return moment.unix(props.value).format(format);
}

class TaskHistory extends React.PureComponent {

    render() {
        const { resource, id, value } = this.props;
        const fieldProps = {resource, id};

        return (<div>
            <div className={'task-history-entry'}>
                <span>Utworzone </span>
                <Field {...fieldProps} fieldName={'created_at'}>
                    {(props) => {
                        return <span><Datetime value={props.value} /></span>
                    }}
                </Field>
                <span> przez </span>
                <div className={'task-history-entry__created_by'}>
                    <Field {...fieldProps} fieldName={'created_by'}>
                        {(props) => <UserInfo value={props.value} />}
                    </Field>
                </div>
            </div>
            <Field {...fieldProps} fieldName={'completed'}>
                {(props) => {
                    if (!props.value) return null;
                    return <div className={'task-history-entry'}>
                        <span>Zakończone </span>
                        <span><Field {...fieldProps} fieldName={'completed_at'}>
                            {(props)=><Datetime value={props.value} />}
                        </Field></span>
                    </div>;
                }}
            </Field>
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskHistory);