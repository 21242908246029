import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {selectResource, selectAllResources} from "state/selectors/resources";
import {setUiFlag} from 'state/actions/ui';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import IconButton from 'components/TooltipIconButton';

const optLabelStyle = {
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRadius: '4px',
    marginRight: '1em',
    fontSize: '12px',
    padding: '3px'
};

function selectMatchingOptions(store, value) {
    let index = store.data['theme.styles.optionsIndex'];
    let types = [];
    value.forEach(v=>{
        let type = index[v] || index['phone@'+v] || index['desktop@'+v];
        if (type) types.push(type);
    });
    return types;
}

function sortOptions(store, e, value, options) {
    let matching = selectMatchingOptions(store, value);
    const compare = (a, b) => {
        let hasA = matching.indexOf(a.id) > -1;
        let hasB = matching.indexOf(b.id) > -1;
        if (hasA||hasB) {
            if (hasA && hasB) return 0;
            if (hasA) return -1;
            return 1;
        }
        let wA = a.weights
            ? a.weights[e]
            : 0;
        let wB = b.weights
            ? b.weights[e]
            : 0;
        if (wA||wB) {
            if (wA===wB) return 0;
            if (wA > wB) return -1;
            return 1;
        }
        return 0;
    };
    return options.sort(compare);
}

const mapStateToProps = (store, props) => {
    let selected = store.ui.flags.stylesDocsPreview;
    let options = selectAllResources(store, 'cms.styles.docs');
    let matching = selectMatchingOptions(store, props.value);
    //options = sortOptions(store, props.elementId, props.value, options);
    return {
        options,
        selected,
        matching,
        property: selected
            ? selectResource(store, 'cms.styles.docs', selected)
            : null
    }
};

const mapDispatchToProps = {
    onPreview: setUiFlag
};

class FindProperty extends React.Component {

    constructor(props) {
        super(props);
    }

    handleSelect = (evt) => {
        alert('select');
    };

    handlePreview = (id) => {
        this.props.onPreview('stylesDocsPreview', id);
    };

    handleChange = (v) => {
        let value = this.props.value || [];
        let newValue = value.slice();
        let opts = Object.keys(this.props.property.options);
        newValue = newValue.filter(v=>opts.indexOf(v)===-1);
        if (v && value.indexOf(v) === -1) newValue.push(v);
        this.props.onChange(
            this.props.elementId,
            newValue
        );
    };

    handleDocsOpen = (id) => {
        window.open('http://atoms.d'+id);
    };

    handleGithub = (url) => window.open(url);

    render() {
        const { options, selected, matching, property, value } = this.props;
        return (<div
            className="console-context"
            style={{ width: '620px', height: '400px', display: 'flex', overflow: 'hidden' }}
        >
            <List style={{ flexShrink: 0, overflow: 'hidden auto' }}>
            {options.map(opt=>{
                return (<ListItem
                    key={opt.id}
                    selected={matching.indexOf(opt.id) > -1}
                    onMouseOver={()=>this.handlePreview(opt.id)}
                >
                    <ListItemText primary={opt.title} secondary={opt.reference} />
                </ListItem>);
            })}
            </List>
            {selected ? <div style={{ flex: 1, padding: '1rem' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h5">{property.title}</Typography>
                    <div style={{ display: 'flex' }}>
                        <IconButton onClick={()=>this.handleGithub(property.github)} icon="fa-github" />
                        <IconButton onClick={()=>this.handleDocsOpen(property.url)} icon="launch" />
                    </div>
                </div>
                {property.rwd ? <div>rwd</div> : null}
                { property.description 
                    ? <div className="se-docs-prop__desc" dangerouslySetInnerHTML={{__html: property.description}} />
                    : null }
                <div>
                {Object.values(property.options).map(opt=>{
                    let isActive = value.indexOf(opt.id) > -1;
                    return (<div className={classNames({
                        "se-docs-option": true,
                        "active": isActive
                    })} key={opt.id}>
                        <div onClick={()=>this.handleChange(opt.id)} style={{ display: 'flex' }}>
                            <div>
                                <div className="se-docs-option__name">
                                    <span className={
                                        classNames({
                                            'se-class': true,
                                            'active': isActive
                                        })
                                    }>.{opt.id}</span>
                                    <span>{opt.name}</span>
                                </div>
                                { opt.description
                                    ? <div className="se-docs-option__desc" dangerouslySetInnerHTML={{__html: opt.description}} />
                                    : null }
                            </div>
                        </div>
                    </div>)
                })}
                </div>
            </div> : null }
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FindProperty);