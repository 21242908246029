import React  from "react";

import { thumbnail } from 'util/thumbnail';

const c_image = (media, size, classNames, ref, onLoad, onClick) => {
    const props = {media, size, classNames, domRef: ref, onLoad, onClick};
    if (media.type === "video") return (<VideoLoader {...props} />);
    return (<ImageLoader {...props} />);
};

class VideoLoader extends React.PureComponent {
    render() {
        const { media, classNames } = this.props;
        return (
            <video
                muted={true}
                autoPlay={false}
                loop={"loop"}
                className={"video-background-mp4 " + classNames}
            >
                <source src={media.url} type={"video/mp4"} />
            </video>
        );
    }
}

class ImageLoader extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {ready: false};
    }
    handleOnLoad = (evt) => {
        this.setState({ready: true});
        if (this.props.onLoad) this.props.onLoad();
    };
    render() {
        const { classNames, media, size, domRef} = this.props;
        return <img
            onClick={this.props.onClick}
            onLoad={this.handleOnLoad}
            ref={domRef}
            alt=""
            className={"c-img " + classNames}
            src={thumbnail(media.file, size)} />
    }
}

const style = {
    pointerEvents: 'all'
};

export default class Image extends React.PureComponent {

    render() {
        const {
            outerClassName,
            className,
            overlayClassName,
            media,
            size,
            imgRef,
            onLoad,
            onClick,
            onContextMenu,
            overlayContent,
            infoOverlayContent
        } = this.props;

        return (
            <div
                className={outerClassName}
                onContextMenu={onContextMenu}
            >
                {c_image(media, size, className, imgRef, onLoad, onClick)}
                {infoOverlayContent}
                { overlayClassName ? <div
                    className={overlayClassName}
                    style={onClick ? style : null}
                    onClick={onClick}
                >{overlayContent}</div> : null }
            </div>
        );
    }
}
