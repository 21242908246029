import global from './global';
import { ideSaveAll, refreshLessStylesFromState } from 'state/actions/ide';

export default {
    keyMap: {
        ...global.keyMap,
        refresh: "ctrl+space",
        save: "ctrl+s"
    },
    handlers: {
        ...global.handlers,
        save: ideSaveAll,
        refresh: refreshLessStylesFromState
    }
}