import React from "react";

import Edit from "ide/ui/Edit";

export default class Value extends React.PureComponent {
  render() {
    const { data, onFocus, onChange } = this.props;
    return (
      <Edit
        onClick={onFocus}
        onChange={onChange}
        fieldKey="template_id"
        type="inline"
        editable={false}
      >
        {data.template_id || data.value}
      </Edit>
    );
  }
}
