import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {resourceFetch,resourceAction} from 'state/actions';
import {selectResource} from 'state/selectors/resources';
import Field from 'v2/Field';
import ListItem from 'components/list/ListItem';
import ResourceContext from 'v2/ResourceContext';

const nullObj = {};

const mapStateToProps = (store, props) => {
    let schema = store.data['db.types.byName'];
    let resourceName = props.resource.split('.')[1]
    if (schema && schema[resourceName]) schema = schema[resourceName].id;
    return {
        data: selectResource(store, props.resource, props.id),
        isPending: store.ui.pending[props.id],
        path: [props.resource, props.id].join('/'),
        schema: schema ? selectResource(store, 'db.types', schema) : nullObj
    }
};

const mapDispatchToProps = {
    fetch: resourceFetch,
    resourceAction
};

class Resource extends React.PureComponent {

    handleClick = (evt) => {
        this.props.onClick(evt, this.props.id);
    };

    fetch = () => {
        if (!this.props.id) return;
        if (this.props.fetchPolicy === 'never') return;
        if (
            this.props.fetchPolicy === 'yield'
            && (this.props.data.id || this.props.isPending) // !== nullObj
        ) return;

        this.props.fetch(
            this.props.resource,
            this.props.id
        );
    };

    componentDidMount() {
        this.fetch();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.path !== this.props.path) {
            this.fetch();
        }
    }

    render() {
        const { data, schema, path, onClick, provideContext } = this.props;
        const content = this.props.children({
            ...this.props,
            onActionRequest: this.props.resourceAction,
            onClick: onClick ? this.handleClick : null
        });

        return provideContext
            ? <ResourceContext.Provider value={path}>{content}</ResourceContext.Provider>
            : content;
    }

}

Resource.propTypes = {
    fetchPolicy: PropTypes.oneOf([
        'always',
        'yield',
        'never'
    ])
};

Resource.defaultProps = {
    data: nullObj,
    fetchPolicy: 'always',
    schema: nullObj
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Resource);

function RenderValue({value,schema}) {
    let type = schema.type;
    if (type === 'date') return 'date';
    return value||'';
}

export function RenderResource(props) {
    const onClick = props.onClick
        ? ()=>props.onClick(props.id)
        : null;
    return (<ListItem
        onClick={onClick}
        primaryText={<Field
            resource={props.resource}
            id={props.id}
            fieldName="primaryText"
        >
            {RenderValue}
        </Field>}
        secondaryText={<Field
            resource={props.resource}
            id={props.id}
            fieldName="secondaryText"
        >
            {RenderValue}
        </Field>}
    />);
}