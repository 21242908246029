import React from 'react';
import {connect} from 'react-redux';
import uuid from 'uuid/v4';
import Thread from "v2/messages/Thread";
import {selectResource} from "state/selectors/resources";
import {draftsChange, draftsSubmit, listCreate, listFetchParams} from "state/actions";
import {currentUser} from "state/selectors/app";
import {createMessage} from "state/actions/messages";

const mapStateToProps = (store, props) => {
    const root = selectResource(store, props.resource, props.id);
    const threadId = root.thread;
    const listId = ['db.threads',threadId,'messages'].join('.');
    const messages = store.lists[listId] || [];
    return {
        threadId: threadId,
        listId,
        messages,
        currentUser: currentUser(store).id
    }
};

const mapDispatchToProps = {
    onCreate: createMessage,
    onSubmit: draftsSubmit,
    onChange: draftsChange,
    fetch: listFetchParams
};

class ThreadContainer extends React.PureComponent {

    componentDidMount() {
        this.fetch();
    }

    fetch = () => {
        if (!this.props.threadId) return null;
        const { listId } = this.props;
        this.props.fetch('db.messages', listId, {
            parent: this.props.threadId
        });
    };

    createMessage = (data) => {
        const { onCreate, threadId, resource, id, rootResource, rootId } = this.props;
        onCreate(threadId, data, resource, id, rootResource, rootId);
    };

    render() {
        const { id, currentUser, messages, threadId, listId } = this.props;
        return <Thread
                threadId={threadId}
                attachId={id}
                currentUser={currentUser}
                messages={messages}
                onCreateMessage={this.createMessage} />;
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ThreadContainer);