import React from 'react';
import {connect} from 'react-redux';
import CmsSnapshotsDialog from 'components/dialogs/CmsSnapshots';

import { query, push } from 'state/actions/route';
import { listFilter } from 'state/actions';

import Dialog from 'components/Dialog';

const mapStateToProps = (store, props) => {
    const resource = 'console.cms.snapshots';
    return {
        open: store.context.q === 'snapshots',
        resource,
        filters: store.filter[resource] || {},
        isPending: store.ui.pending[resource]
    }
};

const mapDispatchToProps = {
    query,
    push,
    onSetFilter: listFilter
};

class CmsSnapshots extends React.PureComponent {

    handleClose = () => this.props.push('/console/services.cms');

    handleFilter = (key, val) => {
        const { resource } = this.props;
        const filter = {};
        filter[key] = val;
        this.props.onSetFilter(
            resource,
            resource,
            resource,
            filter
        );
    };

    render() {
        const { open, isPending } = this.props;
        return (<Dialog
            isPending={isPending}
            open={open}
            onClose={this.handleClose}
            title="Restore from snapshot"
        >
            <CmsSnapshotsDialog
                {...this.props}
                onSetFilter={this.handleFilter}
            />
        </Dialog>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CmsSnapshots);