import React from 'react';
import {connect} from 'react-redux';
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import {appMenuHide, appMenuToggle} from "../../state/actions/ui";

const mapStateToProps = (store, props) => {
    return {
        showAppMenu: store.ui.showAppMenu,
    }
};

const mapDispatchToProps = {
    appMenuToggle,
    appMenuHide
};

class AppMenuButton extends React.PureComponent {

    toggleMenu = () => this.props.appMenuToggle();
    closeMenu = () => this.props.appMenuHide();

    render() {
        const { showAppMenu } = this.props;
        return (<React.Fragment>
            <IconButton
                color="inherit"
                aria-label="Menu"
                onClick={this.toggleMenu}
            >
                <MenuIcon />
            </IconButton>
        </React.Fragment>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppMenuButton);