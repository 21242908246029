import React from 'react';
import {connect} from 'react-redux';
import Fieldset from './Fieldset';
import Field from './Field';
import Card from '@material-ui/core/Card';
import IconButton from 'components/TooltipIconButton';
import Resource from 'v2/Resource';

const mapStateToProps = (store, props) => {
    return {
        tabs: props.data.view_fields
            ? props.data.view_fields
            : []
    }
};

const mapDispatchToProps = {};

class Tabs extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            tab: props.tabs[0]
        }
    }

    handleCreateChild = () => {
        this.props.onCreateChild(
            this.props.fieldId,
            'tab'
        )
    };

    handleSwitchTab = (tab) => {
        this.setState({tab});
    };

    render() {
        const { tabs, data, editable } = this.props;
        const hasTabs = tabs.length > 0;
        return (<Card style={{ display: 'flex' }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '1rem 0 1rem 1rem'
            }}>
                {tabs.map(tab => (
                    <Resource resource="db.view_fields" id={tab} fetchPolicy={'never'}>
                        {({data})=><IconButton
                            icon={data.icon || "edit"}
                            tooltip={data.label}
                            onClick={()=>this.handleSwitchTab(tab)}
                            active={tab===this.state.tab}
                        />}
                    </Resource>
                ))}
                { editable
                    ? <IconButton
                        icon="add"
                        onClick={this.handleCreateChild}
                        tooltip="Add tab"
                    />
                    : null }
            </div>

            <div style={{ flex: 1, padding: '1rem' }}>
                { hasTabs ? <Field
                    {...this.props}
                    fieldId={this.state.tab}
                /> : null }
            </div>
        </Card>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Tabs);