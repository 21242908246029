import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {query} from 'state/actions/route';

import MaterialDialog from '@material-ui/core/Dialog';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import Preloader from 'components/Preloader';

class Dialog extends React.PureComponent {

    render() {
        const {
            hideCloseButton,
            title,
            isPending,
            fullScreen,
            ...otherProps
        } = this.props;
        return (<MaterialDialog
            {...otherProps}
            open={!!otherProps.open}
            scroll={fullScreen ? 'body' : 'paper'}
            fullScreen={fullScreen}
        >
            <Preloader visible={isPending || false} />
            { !hideCloseButton ? <IconButton
                onClick={this.props.onClose}
                style={{ zIndex: 10, position: 'absolute', top: 0, right: 0 }}
            >
                <Icon>clear</Icon>
            </IconButton> : null }
            { title ? <DialogTitle>{title}</DialogTitle> : null}
            {this.props.children}
        </MaterialDialog>);
    }

}

const ResponsiveDialog = withMobileDialog()(Dialog);
export default ResponsiveDialog;

Dialog.propTypes = {
    isPending: PropTypes.bool,
    title: PropTypes.string,
    onClose: PropTypes.func
};

export const withDialog = (open) => {
    return (DecoratedComponent) => {
        const Decorator = class WithDialog extends React.PureComponent {
            handleClose = () => {
                this.props.query({q: null});
            };
            render() {
                const { open, title, maxWidth, fullWidth, ...props } = this.props;
                return <ResponsiveDialog
                    open={open}
                    title={title}
                    maxWidth={maxWidth}
                    fullWidth={fullWidth}
                    onClose={this.handleClose}
                >
                    <DecoratedComponent
                        {...props}
                        onClose={this.handleClose}
                    />
                </ResponsiveDialog>
            }
        };
        return connect((store, props) => ({
            open: open(store, props)
        }), {query})(Decorator);
    }
};