import React from 'react';
import { connect } from 'react-redux';
import { changed } from 'util/changed';

const syncRouteParams = (params, location) => {
    return {
        type: 'SYNC_ROUTE_PARAMS',
        params,
        location
    }
};

const mapStateToProps = (store, props) => {
  return {
      location: store.router.location,
      search: store.router.location.search
  }
};

const mapDispatchToProps = {
    syncRouteParams
};

class SyncRouteParams extends React.PureComponent {

    componentDidMount() {
        this.props.syncRouteParams(
            this.props.params,
            this.props.location
        );
    }

    componentDidUpdate(prevProps) {
        if (changed(['params','search'], prevProps, this.props)) {
            this.props.syncRouteParams(
                this.props.params,
                this.props.location
            );
        }
    }

    render() {
        return this.props.children;
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(SyncRouteParams);