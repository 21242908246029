import React from 'react';
import PropTypes from 'prop-types';

import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';

const statuses = {
    99: {
        title: 'Not connected to github',
        color: grey[600]
    },
    0: {
        title: 'Everything in sync',
        color: green[500]
    },
    1: {
        title: 'Pending changes',
        color: amber['A400']
    }
};

export default class GitStatus extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    render() {
        const statusId = this.props.status;
        let status = statuses[statusId];
        if (!status) return null;

        return (<div style={{
            backgroundColor: status.color,
            color: 'white',
            display: 'flex',
            justifyContent: 'space-between'
        }}>
            <div style={{ padding: '1rem' }}>{status.title}</div>
            <div>
                {this.props.children}
            </div>
        </div>);
    }

}

GitStatus.propTypes = {
    status: PropTypes.number.isRequired
};