import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import _ from 'lodash';
import {parseSchemaQuery, makeQueryConfig} from "components/fields/select/SelectOptions";
import {selectResource,selectPath} from 'state/selectors/resources';
import {listFilter} from 'state/actions';
import {changed} from 'util/changed';

const nullArr = [];

function makeSelector(config) {
    return (store) => {
        if (config.resource === 'db.enum-definitions' && config.resourceId) {
            let enumIndex = store.data['db.enum-definitions.index'];
            if (!enumIndex) return null;
            let enumId = enumIndex[config.resourceId];
            return selectPath(store, ['db.enum-definitions', enumId, 'values']);
        }
        if (config.type === 'schema') {
            let data = _.get(store.schema, config.path) || [];
            return data.map(item => item.id);
            //return data;
        }
        let path = ['lists', config.listId];
        return _.get(store, path);
    };
}

const mapStateToProps = (store, props) => {
    let path = ['lists', props.resource];
    let config = {};
    let query = {};
    let contextResource = {};
    let selector = (store) => _.get(store, path);
    if (props.source) {
        if (props.source && props.source.indexOf('$') > -1 && props.contextResource) {
            contextResource = selectResource(
                store,
                props.contextResource.split('/')[0],
                props.contextResource.split('/')[1]
            );
        }
        query = parseSchemaQuery(props.source, contextResource);
        config = makeQueryConfig(query)(store, props);
        selector = makeSelector(config);
    }
    let resource = config.displayResource || props.resource;
    let listId = config.listId || props.listId || resource;
    return {
        path: config.path,
        resource: resource,
        listId,
        params: config.params,
        data: selector(store),
        isPending: store.ui.pending[resource]
    }
};

const mapDispatchToProps = {
    fetch: listFilter
};

class List extends React.PureComponent {

    fetch = () => {
        let { path, resource, listId, filterId, fetchStrategy } = this.props;
        if (fetchStrategy === 'never') return;
        if (fetchStrategy === 'once' && this.props.data.length) return;
        let url = path;
        this.props.fetch(
            url,
            listId,
            filterId || listId,
            this.props.params
        );
    };

    componentDidMount() {
        this.fetch();
    }

    componentDidUpdate(prevProps) {
        if (changed(['resource', 'listId', 'filterId', 'source'], prevProps, this.props)) {
            this.fetch();
        }
    }

    render() {
        const { resource, data } = this.props;
        return this.props.children(this.props);
    }

}

List.propTypes = {
    data: PropTypes.array,
    children: PropTypes.func.isRequired,
    fetchStrategy: PropTypes.string
};

List.defaultProps = {
    data: nullArr
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(List);