import React from 'react';
import {connect} from 'react-redux';
import {selectListMeta} from "state/selectors/list";

const nullArr = [];

const mapStateToProps = (store, props) => {
    return {
        data: selectListMeta(store, props).aggregate || nullArr
    }
};

const mapDispatchToProps = {};

class ListMetaSummary extends React.PureComponent {

    render() {
        const { data } = this.props;
        if (!data.length) return null;

        return (<div className='list-summary'>
            {data.map((item, i) => {
                return <div key={i}>{item.label} {item.value}</div>
            })}
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListMetaSummary);