import React from 'react';
import {connect} from 'react-redux';
import { selectDevice } from 'state/selectors/ide';
import TooltipIconButton from 'components/TooltipIconButton';
import SelectMenu from 'components/fields/select/SelectMenu';
import { icon } from 'util/icon';
import { query } from 'state/actions/route';
import devices from 'config/devices';

const mapStateToProps = (store, props) => {
    return {
        value: selectDevice(store),
        options: Object.values(devices)
    }
};

const mapDispatchToProps = {
    query
};

class DeviceSelect extends React.PureComponent {

    handleChange = (value) => {
        this.props.query({device:value});
    };

    render() {
        const { value, options } = this.props;
        return (<SelectMenu
          trigger={(props)=><TooltipIconButton
              {...props}
              icon={'device.'+value}
              tooltip={devices[value].label}
          />}
          value={value}
          options={options}
          onSelect={this.handleChange}
        />);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceSelect);