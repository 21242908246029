import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import uuid from 'uuid/v4';

import {query} from 'state/actions/route';

import Resource from 'v2/Resource';
import RenderListItem from 'v2/items/ListItem';
import List from 'v2/List';
import {CollectionLayout} from 'v2/fields/Collection';
import Conversation from 'v2/messages/Conversation';

import IconButton from 'components/TooltipIconButton';
import {
    draftsPathCreate,
    draftsSubmit,
    draftsPathDetach,
    draftsChangeField,
    draftsCreate,
    listCreate
} from "state/actions";

import Note from 'components/list/items/Note';

const layoutTypes = {
    'db.messages': Conversation,
    //'db.notes': Conversation,
    default: CollectionLayout
};

const emptyLabelStyle = {
    fontSize: '12px',
    opacity: 0.5,
    padding: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
};

const mapState = (store, props) => ({
    listId: [props.resource,props.resourceId,props.id].join('.'),
    source: props.parent_id
        ? 'api:'+props.resource_type+'?'+props.parent_id+'='+props.resourceId
        : null
});

const mapDispatch = {
    onCreate: draftsPathCreate,
    onCreateRelated: listCreate,
    onDelete: draftsPathDetach,
    onSubmit: draftsSubmit,
    query
};

const mapItemState = (store, props) => ({});

const mapItemDispatch = {
    onSubmit: draftsSubmit,
    onChange: draftsChangeField
};

class RenderItem extends React.PureComponent {

    handleDelete = () => {
        this.props.onDelete(this.props.id);
    };

    handleSave = () => {
        this.props.onSubmit(
            this.props.resource,
            this.props.id
        );
    };

    handleChange = (key, value) => {
        this.props.onChange(
            this.props.resource,
            this.props.id,
            key,
            value
        );
    };

    render() {
        if (this.props.resource === 'db.notes') {
            return <Note
                {...this.props}
                onSubmit={this.handleSave}
                onDelete={this.handleDelete}
                onChange={this.handleChange}
            />;
        }
        return <RenderListItem {...this.props} />
    }
}

const ConnectedItem = connect(mapItemState, mapItemDispatch)(RenderItem);

class RefContainer extends React.Component {

    handleCreate = (evt, itemData) => {
        let path = [this.props.resource,this.props.resourceId,this.props.id].join('/');
        let data = {
            id: uuid()
        };
        if (itemData) data = {...data, ...itemData};
        if (this.props.parent_id) {
            data[this.props.parent_id] = this.props.resourceId;
            this.props.onCreateRelated(
                this.props.resource_type,
                this.props.listId,
                data
            );
            this.props.onSubmit(
                this.props.resource_type,
                data.id
            );
        } else {
            this.props.onCreate(
                this.props.resource_type,
                path.split('/'),
                data,
                null,
                !this.props.multiple
            );
        }

    };

    handleAttach = () => {
        if (!this.props.attach && this.props.create) {
            this.handleCreate();
            return;
        }
        const { resource_type } = this.props;
        const path = [resource_type].join('/');
        let tgPath = [this.props.resource,this.props.resourceId,this.props.id].join('/');
        this.props.query({find:path, target: tgPath});
    };

    handleClear = () => {
        let value = this.props.multiple ? [] : null;
        let id = this.props.id;
        this.props.onChange(id, value);
    };

    handleEdit = (id) => {
        if (this.props.onClick) {
            this.props.onClick(id);
            return;
        }
        const { resource_type } = this.props;
        const path = [resource_type, id].join('/');
        let args = {
            edit: path
        };
        if (this.props.view) args.v = this.props.view;
        this.props.query(args);
    };

    handleDelete = (id) => {
        let tgPath = [this.props.resource,this.props.resourceId,this.props.id].join('/');
        this.props.onDelete(tgPath.split('/'), id);
    };

    render() {
        const { data, parent_id, create, attach, label, id, multiple, readOnly } = this.props;

        let value = parent_id
            ? data
            : this.props.value;

        let values = value;

        if (!multiple) {
            values = value ? [value] : value;
        }
        if (multiple && typeof value === 'string') {
            values = [value];
        }
        if (multiple && !value) {
            values = [];
        }
        let isEmpty = !values || !values.length;

        if (isEmpty && !attach && !create) return null;

        let props = {
            ...this.props,
            values,
            isEmpty,
            onClick: this.handleEdit,
            onAttach: this.handleAttach,
            onCreate: this.handleCreate,
            onClear: this.handleClear,
            onDelete: this.handleDelete
        };

        return this.props.children(props);
    }

}

export const ConnectedRefContainer = connect(mapState, mapDispatch)(RefContainer);

export default class Ref extends React.PureComponent {

    renderList = (props) => {
        const { values, resource_type, multiple } = props;

        return values ? <div>
            {values.map(id=><Resource
            key={id}
            resource={resource_type}
            id={id}
            onClick={this.handleEdit}
        >
            {(itemProps)=><ConnectedItem
                {...itemProps}
                onClick={props.onClick}
                onDelete={props.onDelete}
                readOnly={props.readOnly}
            />}
        </Resource>)}</div> : <div style={emptyLabelStyle}>
            Kliknij aby przypisać
        </div>;
    };

    connectList = (props) => {
        let resource = props.resource_type;
        if (!props.parent_id) return this.renderList({...props, data: props.value});
        return <List
            {...props}
            resource={resource}
        >
            {(props)=>this.renderList({...props, values: props.data})}
        </List>;
    };

    render() {
        let resource = this.props.resource_type;
        const Layout = layoutTypes[resource] || layoutTypes.default;
        const flatLayout = ['db.notes'];
        return <ConnectedRefContainer {...this.props}>
            {(props)=> {
                const {
                    id,
                    label,
                    create,
                    value,
                    multiple,
                    attach,
                    isEmpty,
                    parent_id,
                    onAttach,
                    onCreate,
                    onClear,
                    readOnly
                } = props;
                if (isEmpty && readOnly) return null;
                const card = true; //flatLayout.indexOf(resource) === -1 || !readOnly;
                return <Layout
                    card={card}
                    readOnly={readOnly}
                    label={label || id}
                    onClick={attach && (isEmpty && !parent_id) ? onAttach : null}
                    onCreate={onCreate}
                    actions={!readOnly ? <div>
                        {attach && (!value || multiple) ? <IconButton size="small" icon="search" onClick={onAttach} /> : null }
                        {create && (!value || multiple) ? <IconButton size="small" icon="add" onClick={onCreate} /> : null}
                        {(attach || create) && (value && !multiple) ? <IconButton size="small" icon="clear" onClick={onClear} /> : null}
                    </div> : null}
                >
                    {this.connectList(props)}
                </Layout>
            }}
        </ConnectedRefContainer>
    }
}

Ref.propTypes = {};