import React from 'react';
import {connect} from 'react-redux';
import {draftsPathCreate,draftsPathDetach} from "state/actions";
import {selectViewId} from 'state/selectors/schema';

import Default from './layouts/Default';
import Tabs from './layouts/Tabs';
import Stepper from './layouts/Stepper';
import ViewSelect from 'v2/ViewSelect';
import Access from 'components/Access';

import FormViewModeContext from 'v2/form-builder/FormViewModeContext';

const types = {
    default: Default,
    tabs: Tabs,
    stepper: Stepper
};

const mapStateToProps = (store, props) => {
    const viewId = props.viewId || selectViewId(store, 'form', props.resource, props.id);
    return {
        fieldResource: 'db.view_fields',
        fieldsKey: 'view_fields',
        viewId: viewId,
        fieldsetId: viewId
    }
};

const mapDispatchToProps = {
    onCreate: draftsPathCreate,
    onDelete: draftsPathDetach
};

class FormLayout extends React.Component {

    constructor(props) {
        super(props);
        this.path = [
            props.fieldsetResource,
            props.fieldsetId,
            props.fieldsKey
        ];
    }
    
    handleCreate = (type) => {
        this.props.onCreate(
            this.props.fieldResource,
            this.path,
            {type},
            null,
            false
        )
    };
    
    handleDelete = (id) => {
        alert('delete');
        this.props.onDelete(this.path,id);
    };

    render() {
        const { resource, id, type, mode, viewId } = this.props;
        let LayoutComponent = types[type] || types.default;
        return <FormViewModeContext.Provider value={mode}>
            <Access scopes={'master'} strict={true}>
                <div className={'phone-view-select hide-desktop'}>
                    <ViewSelect type={'form'} resource={resource} value={viewId} />
                </div>
            </Access>
            <LayoutComponent
                {...this.props}
                onCreate={this.handleCreate}
                onDelete={this.handleDelete}
            />
        </FormViewModeContext.Provider>;
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormLayout);