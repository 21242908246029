import React from "react";
import PropTypes from "prop-types";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

export default function RadioSelect(props) {
  const { id, label, value, nullable, options, onChange } = props;

  const handleChange = (evt) => {
    onChange(evt.target.value);
  };

  const handleClick = (evt) => {
    if (nullable && value === evt.target.value) {
      onChange(null);
      return;
    }
    evt.stopPropagation();
  };

  return (
    <FormControl component="div" variant="outlined">
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        aria-label="position"
        name="position"
        value={value || ""}
        onChange={handleChange}
        row
      >
        {options.map((opt) => {
          return (
            <FormControlLabel
              key={opt.id}
              value={opt.id}
              control={<Radio color="primary" />}
              label={opt.title || opt.label || opt.name}
              onClick={handleClick}
              labelPlacement="end"
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}

RadioSelect.propTypes = {};
