import createNewResource from "state/create";

export const appBootstrap = (id, context) => {
  return {
    type: "APP.BOOTSTRAP",
    id,
    context,
  };
};

export const appUser = (id) => ({
  type: "APP.USER",
  id,
});

export const appSchemaSuccess = (id, schema) => {
  return {
    type: "APP.SCHEMA.SUCCESS",
    id,
    schema,
  };
};

export const appChangeLang = (lang) => ({
  type: "APP.CHANGE_LANG",
  lang,
});

export const listFetch = (resource, list, yieldData, context) => {
  return {
    type: "LIST.FETCH",
    resource,
    list,
    yieldData,
    context,
  };
};

export const listFetchParams = (resource, list, params, yieldData, context) => {
  return {
    type: "LIST.FETCH_PARAMS",
    resource,
    list,
    params,
    yieldData,
    context,
  };
};

export const listFetchFiltered = (
  resource,
  list,
  filter,
  yieldData,
  context
) => {
  return {
    type: "LIST.FETCH",
    resource,
    list,
    filter,
    yieldData,
    context,
  };
};

export const listFetchSuccess = (
  resource,
  data,
  list,
  filterId,
  yieldData,
  append
) => {
  return {
    type: "LIST.FETCH.SUCCESS",
    resource,
    data,
    list,
    filterId,
    yieldData,
    append,
  };
};

export const listFilter = (resource, list, filterId, filter, context) => {
  return {
    type: "LIST.FILTER",
    resource,
    list,
    filterId,
    filter,
    context,
  };
};

export const listFilterAppend = (resource, list, filterId, filter, context) => {
  return {
    type: "LIST.FILTER_APPEND",
    resource,
    list,
    filterId,
    filter,
    context,
  };
};

export const listFilterSet = (list, filter) => {
  return {
    type: "LIST.FILTER.SET",
    list,
    filter,
  };
};

export const listReorder = (id, drag, hover) => {
  return {
    type: "LIST.REORDER",
    id,
    drag,
    hover,
  };
};

export const listSaveOrder = (resource, id) => {
  return {
    type: "LIST.SAVE_ORDER",
    resource,
    id,
  };
};

export const listAppend = (id, data, after) => {
  return {
    type: "LIST.APPEND",
    id,
    data,
    after,
  };
};

export const listUpdate = (list, data) => ({
  type: "LIST.UPDATE",
  list,
  data,
});

export const resourceCreate = (resourceType, data) => {
  return {
    type: "RESOURCE.CREATE",
    resourceType,
    data,
  };
};

export const listCreate = (resource, listId, data, after) => {
  return {
    type: "LIST.CREATE",
    resource,
    listId,
    data: createNewResource(resource, data),
    after,
  };
};

export const listCreateAfter = (resource, id, list, context) => {
  return listCreate(list, {}, id);
};

export const resourceFetch = (list, id, context) => {
  return {
    type: "RESOURCE.FETCH",
    list,
    id,
    context,
  };
};

export const resourceSave = (resource, id, fields) => {
  return {
    type: "RESOURCE.SAVE",
    resource,
    id,
    fields,
  };
};

export const resourceFetchSuccess = (resource, id, data) => {
  return {
    type: "RESOURCE.FETCH.SUCCESS",
    resource,
    id,
    data,
    path: [resource, id],
    update: data,
  };
};

export const resourcePathDetach = (path, id) => ({
  type: "RESOURCE.PATH.DETACH",
  path,
  id,
});

export const resourcePathAttach = (path, id, after, single) => ({
  type: "RESOURCE.PATH.ATTACH",
  path,
  id,
  after,
  single,
});

export const resourcePathAppend = (path, data) => ({
  type: "RESOURCE.PATH.APPEND",
  path,
  item: createNewResource("default", data),
});

export const resourcePathDelete = (path) => ({
  type: "RESOURCE.PATH.DELETE",
  path,
});

export const resourcePathReorder = (path, drag, hover) => {
  return {
    type: "RESOURCE.PATH.REORDER",
    path,
    drag,
    hover,
  };
};

export const resourcePathReorderIndex = (path, drag, hover) => {
  return {
    type: "RESOURCE.PATH.REORDER_INDEX",
    path,
    drag,
    hover,
  };
};

export const resourcePathCreate = (
  resource,
  path,
  data = {},
  after,
  single
) => {
  return {
    type: "RESOURCE.PATH.CREATE",
    resource,
    path,
    data: createNewResource(resource, data),
    after,
    single,
  };
};

export const resourceAction = (type, resource, id, context) => {
  return {
    type: "RESOURCE.ACTION",
    actionType: type,
    resource,
    id,
    context,
  };
};

export const resourceDelete = (resource, id, list) => ({
  type: "RESOURCE.DELETE",
  resource,
  id,
  list,
});

export const resourceDeleteSuccess = (resource, id) => ({
  type: "RESOURCE.DELETE.SUCCESS",
  resource,
  id,
});

export const resourceToggle = (resource, id, property) => ({
  type: "RESOURCE.TOGGLE",
  resource,
  id,
  property,
});

export const draftsCreate = (list, id, data) => {
  return {
    type: "DRAFT.CREATE",
    path: [list, id],
    update: data,
  };
};

export const resourceChange = (path, update) => {
  return {
    type: "RESOURCE.CHANGE",
    path,
    update,
  };
};

export const resourceChangeField = (list, id, key, value) => {
  let update = {};
  update[key] = value;
  return {
    type: "RESOURCE.CHANGE",
    path: [list, id],
    update,
  };
};

export const resourcePathChange = (path, value) => {
  return {
    type: "RESOURCE.PATH.CHANGE",
    path,
    value,
  };
};

export const resourcePathTree = (path, tree) => ({
  type: "RESOURCE.PATH.TREE",
  path,
  tree,
});

export const resourcePathDeleteById = (path, id) => ({
  type: "RESOURCE.PATH.DELETE_BY_ID",
  path,
  id,
});

export const draftsChange = (...args) => {
  let resourceAction = resourceChange(...args);
  resourceAction.type = "DRAFT.CHANGE";
  return resourceAction;
};

export const draftsChangeField = (...args) => {
  let resourceAction = resourceChangeField(...args);
  resourceAction.type = "DRAFT.CHANGE";
  return resourceAction;
};

export const draftsPathTree = (...args) => {
  let resourceAction = resourcePathTree(...args);
  resourceAction.type = "DRAFT.PATH.TREE";
  return resourceAction;
};

export const draftsPost = (resource, id, endpoint) => ({
  type: "DRAFT.POST",
  resource,
  id,
  endpoint,
});

const draft = (action) => {
  return (...args) => {
    let resourceAction = action(...args);
    resourceAction.type = resourceAction.type.replace("RESOURCE.", "DRAFT.");
    return resourceAction;
  };
};

export const draftsSave = draft(resourceSave);
export const draftsPathChange = draft(resourcePathChange);
export const draftsPathDetach = draft(resourcePathDetach);
export const draftsPathAttach = draft(resourcePathAttach);
export const draftsPathCreate = draft(resourcePathCreate);
export const draftsPathDelete = draft(resourcePathDelete);
export const draftsPathReorder = draft(resourcePathReorder);
export const draftsPathReorderIndex = draft(resourcePathReorderIndex);
export const draftsPathDeleteById = draft(resourcePathDeleteById);
export const draftsPathAppend = draft(resourcePathAppend);

export const draftsSubmit = (resource, id, dismiss, context) => {
  return {
    type: "DRAFT.SUBMIT",
    resource,
    id,
    dismiss,
    context,
  };
};

export const draftsCancel = (resource, id) => {
  return {
    type: "DRAFT.CANCEL",
    resource,
    id,
  };
};

export const jobRequestSubmit = (resource, id) => {
  return {
    type: "DB.JOB_REQUEST_SUBMIT",
    resource,
    id,
  };
};

export const listPrimaryAction = (resource, list) => {
  return {
    type: "LIST.ACTION",
    resource,
    list,
  };
};

export const listUpdateMeta = (resource, list, data) => {
  return {
    type: "LIST.UPDATE_META",
    resource,
    list,
    data,
  };
};
