import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Fieldset from 'v2/Fieldset';

export default class Layout extends React.Component {

    render() {
        const { layout } = this.props;
        const id = this.props.resourceId;
        const { resource, schema } = this.props;
        const { fields } = schema;
        return (<div className={classNames([
            'form-layout',
            'form-layout--' + (layout || 'cols-flex')
        ])}>
            <Fieldset
                resource={resource}
                id={id}
                fields={fields}
            />
        </div>);
    }

}

Layout.propTypes = {};