import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';

import DateField from 'components/fields/datepicker/Date';
import IconButton from 'components/TooltipIconButton';

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

const fieldStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
};

class Schedule extends React.Component {

    handlePrev = () => {
        let { value, id } = this.props;
        let prev = value
            ? moment.unix(value).subtract(1, 'days')
            : moment().startOf('day');
        this.props.onChange(id, prev.format('X'));
    };

    handleNext = () => {
        let { value, id } = this.props;
        let next = value
            ? moment.unix(value).add(1, 'days')
            : moment().startOf('day');
        this.props.onChange(id, next.format('X'));
    };

    handleClear = () => {
        this.props.onChange(this.props.id, false);
    };

    handleToday = () => {
        this.props.onChange(this.props.id, moment().format('X'));
    };

    render() {
        const { value } = this.props;
        return (<div>
            <div style={fieldStyle}>
                <IconButton onClick={this.handlePrev} icon={'keyboard_arrow_left'} />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <DateField {...this.props} variant={'inline'} />
                    { value ? <IconButton onClick={this.handleClear} icon={'clear'} /> : null }
                    {/**<IconButton tooltip="Today" onClick={this.handleToday} icon={'event'} />**/}
                </div>
                <IconButton onClick={this.handleNext} icon={'keyboard_arrow_right'} />
            </div>
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Schedule);