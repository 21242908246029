import React from 'react';
//import PropTypes from 'prop-types';
import OwlCarousel from "ide/ui/LockSlider";

export default class Slider extends React.PureComponent {

    render() {
        const { items, s, domRef } = this.props;
        let sliderItems = s.get("slider_items") || 1;
        let sliderMargin = s.get("slider_margin") || 0;
        let autoHeight = s.get("slider_auto_height") || false;
        if (!items || !items.length) {
            return (<div>tu byłby owl ale nie ma itemów</div>);
        }
        return (<OwlCarousel
            ref={domRef}
            s={s}
            className={"owl-carousel owl-theme l-slider " + s.get("list")}
            options={{
                items: sliderItems*1,
                margin: sliderMargin*1,
                autoHeight: !!autoHeight
            }}
            onClick={this.props.onFocus}
        >
            {this.props.children}
        </OwlCarousel>);
    }

}

Slider.propTypes = {};