import apartments from './filters/apartments';

const defaultFilter = {
    filters_config: {
        search: {
            type: 'text-search'
        }
    }
};

const db = {
    'db.types': {
        filters_config: {
            ...defaultFilter.filters,
            type: {
                type: 'categories',
                source: 'schema:data.schema-types'
            }
        }
    }
};

const console = {
    'console.services.website.browse': {
        filters_config: {
            ...defaultFilter.filters_config
        }
    }
};

export default {
    ...console,
    'cms.sections': {
        filters_config: {
            ...defaultFilter.filters_config,
            cms_tags: {
                label: 'Tagi',
                type: 'categories',
                source: 'api:tags.cms',
                resource: 'cms.tags',
                wrap: false
            },
            type: {
                label: 'Typ sekcji',
                type: 'categories',
                source: 'schema:sections.types'
            },
            active: {
                label: 'Tylko aktywne',
                type: 'checkbox'
            }
        }
    },
    'cms.fonts': {
        filters_config: {
            ...defaultFilter.filters_config,
            source: {
                label: 'Source',
                options: [
                    {id: 'google'},
                    {id: 'zuu'}
                ],
                type: 'categories'
            }
        }
    },
    ...apartments,
    ...db,
    default: {
         ...defaultFilter
    }
}