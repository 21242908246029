import React from 'react';
import {connect} from 'react-redux';

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

class FormInput extends React.Component {

    render() {
        const { data, s } = this.props;
        const inputClasses = s.get('input');
        return (<input
            className={inputClasses}
            type={'text'}
            placeholder={data.placeholder}
        />);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormInput);