import React from 'react';
import Link from 'components/router/Link';

import Card from '@material-ui/core/Card';
import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import { icon } from 'util/icon';

export default class ResourcesNav extends React.PureComponent {

    render() {
        const { resources } = this.props;
        return (<div className="app-nav-resources">
            {resources.map((resource) => {
                if (!resource.route) return null;
                let iconComponent = icon(resource.icon || resource.id, 'inherit')
                    || icon('default', 'inherit');
                return (<div className="app-nav-resource" key={resource.id}>
                    <Link
                        key={resource.id}
                        to={resource.route}
                    >
                        <div className="card-icon">
                            <div className="card-icon__icon">
                                <Avatar className="card-icon__avatar">{iconComponent}</Avatar>
                            </div>
                            <div>
                                {resource.label || resource.name}
                            </div>
                        </div>
                </Link>
            </div>);
            })}
        </div>);
    }

}