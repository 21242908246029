import React from 'react';

import withScroll from './ScrollWatch';

import Layout from './Layout';
import Section from 'ide/containers/Section';
import DataContext from './DataContext';
import EmptyState from 'ide/ui/PageSectionsEmptyState';
import {List} from 'list';

class Page extends React.PureComponent {

    render() {
        const {
            path,
            data,
            scroll,
            s,
            device,
            onDropSection
        } = this.props;

        if (!s) return null;

        let wrapperClass = '';
        wrapperClass += " " + s.get('page_wrapper');

        if (scroll) wrapperClass += " s-scroll--body";

        let outerClass = 'ide-wrapper theme-scope';

        wrapperClass += " device-" + device;

        let items = data.map((section)=><Section key={section} id={section} s={this.props.s} />);

        if (!this.props.readOnly) return <DataContext.Provider value={path}>
            <div className={outerClass}>
                <div id="wrapper" className={wrapperClass}>
                    { this.props.layout ? <Layout id={this.props.layout} s={this.props.s}>
                        {items}
                    </Layout> : items }
                </div>
            </div>
        </DataContext.Provider>;

        return (<List
            id="page-sections"
            data={data}
            resource="cms.sections"
            variant="sortable"
            onChangeOrder={onDropSection}
            onDrop={onDropSection}
            s={this.props.s}
            renderItem={(props)=>{
                if (props.isPlaceholder && data.indexOf(props.id) === -1) {
                    return <section className="l-section">placeholder</section>;
                }
                return <Section {...props} s={this.props.s} />;
            }}
            renderList={(props)=>{
                let items = props.items || <EmptyState/>;
                return <DataContext.Provider value={path}>{props.connectDropTarget(
                    <div className={outerClass}>
                        <div id="wrapper" className={wrapperClass}>
                            { this.props.layout ? <Layout id={this.props.layout} s={this.props.s}>
                                {items}
                            </Layout> : items }
                        </div>
                    </div>
                )}</DataContext.Provider>;
            }}
        />);
    }

}

export default withScroll(200)(Page);