import React from 'react';
import {connect} from 'react-redux';

import {
    listCreate,
    resourceAction
} from 'state/actions';

import {
    query
} from 'state/actions/route';

import {selectFilterConfig} from 'state/selectors/filter';

import Form from 'containers/ResourceForm';
import App from 'views/layouts/App';
import ResourcesNav from 'components/layout/SideNav';
import AppContext from 'containers/AppContext';
import ListActions from 'containers/dialogs/ListActions';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import ResourceListItem from 'components/list/ResourceListItem';
import ListWrapper from 'components/list/ListWrapper';

import RoutePrimary from 'containers/list/RoutePrimary';
import RouteRelated from 'containers/list/RouteRelated';

import NestedList from 'list/containers/NestedList';
import {ResourceList,Resource,Listing} from 'list';

const dialogViews = [
    'snapshots',
    'vcs',
    'sync'
];

const emptyObj = {};

const mapStateToProps = (store, props) => {
    const list = store.context.view ? [
        store.context.app,
        store.context.view
    ].join('.') : null;

    let id = store.context.id;
    if (dialogViews.indexOf(store.context.q) > -1) id = null;

    let actionsResource = store.context.relation
        ? [store.context.app,store.context.relation].join('.')
        : [store.context.app,store.context.view].join('.');

    actionsResource = [store.context.app,store.context.view].join('.');

    if (actionsResource === 'cms.pages' && !store.context.relation) {
        actionsResource = null;
    }

    return {
        app: store.context.app,
        lang: store.context.lang,
        project: store.context.project,
        view: store.context.view,
        id: id,
        relation: store.context.relation,
        relatedId: store.context.relatedId,
        relatedResource: store.context.relation
            ? [store.context.app,store.context.relation].join('.')
            : null,
        list,
        filterLayout: Object.keys(selectFilterConfig(store, list).filters_config).length > 1
            ? 'aside'
            : 'top',
        listPending: store.ui.pending[list],
        relatedPath: [
            list,
            store.context.id,
            store.context.relation
        ].join('/'),
        modalForm: !!(store.context.item || store.context.cp),
        appKey: [store.context.lang,store.context.project].join('.'),
        actions: store.resources[actionsResource]
            ? store.resources[actionsResource].listActions
            : emptyObj,
    }
};

const mapDispatchToProps = {
    push: (route, params) => {
        return { type: "ROUTE.PUSH", route, params }
    },
    listCreate,
    query,
    resourceAction
};

class Project extends React.PureComponent {

    constructor(props) {
        super(props);
        this.appWatch = ["project", "lang"];
    }

    handleShowListActions = () => {
        if (this.props.actions) {
            if (Object.keys(this.props.actions).length > 1) {
                this.props.query({q: 'list-actions'});
            } else {
                let action = Object.values(this.props.actions)[0];
                if (action && action.query) {
                    this.props.query(action.query);
                } else {
                    this.props.listCreate(this.props.list, this.props.list);
                }
            }
        } else {
            this.props.listCreate(this.props.list, this.props.list);
        }
    };

    render() {
        const {
            app,
            appKey,
            lang,
            list,
            id,
            relation,
            view,
            relatedId,
            listPending,
            filterLayout
        } = this.props;

        let formClassName = "form";

        if ((id && !relation) || relatedId) {
            formClassName += ' visible';
        }

        let listAreaClass = "list-area";

        if (relation) {
            listAreaClass += " secondary";
        } else {
            listAreaClass += " primary";
        }

        const virtualized = ['sections', 'media'];

        const listDriver = virtualized.indexOf(view) > -1
            ? 'virtualized-list'
            : 'sortable';

        return (
            <AppContext key={appKey} app={app} watch={this.appWatch}>
                <App app={app} key={lang}>
                    <div className={"app-columns filters-"+filterLayout}>
                        <div className="modules-sidebar">
                            <ResourcesNav />
                        </div>
                        <div className={listAreaClass}>
                            <div className="resource-list-fab">
                                <Fab
                                    color="secondary"
                                    aria-label="Add"
                                    onClick={this.handleShowListActions}
                                >
                                    <AddIcon />
                                </Fab>
                                <ListActions
                                    id={id}
                                    resource={list}
                                />
                            </div>
                            { view ?
                                <RoutePrimary render={(routeProps) => (
                                    <ResourceList
                                        path={routeProps.path}
                                        listId={routeProps.list}
                                        filterId={routeProps.list}
                                        resource={routeProps.list}
                                        selectedItem={routeProps.selectedItem}
                                        renderItem={props=><Resource
                                            {...props}
                                            {...routeProps.itemProps}
                                            actions={routeProps.list}
                                        />}
                                        renderList={props=><Listing
                                            {...props}
                                            primary
                                            filterLayout={filterLayout}
                                            filterId={routeProps.list}
                                            selectedItem={routeProps.selectedItem}
                                        />}
                                        variant={listDriver}
                                        id={routeProps.list}
                                        onChangeOrder={routeProps.onChangeOrder}
                                    />
                                )} /> : null }
                            { relation ?
                                <RouteRelated render={(routeProps) => (
                                    <NestedList
                                        path={routeProps.path}
                                        resource={routeProps.resource}
                                        selectedItem={routeProps.selectedItem}
                                        renderItem={props=><Resource
                                            {...props}
                                            {...routeProps.itemProps}
                                            actions={routeProps.relatedResource}
                                        />}
                                        renderList={props=><Listing
                                            {...props}
                                            title={routeProps.resource}
                                        />}
                                        variant="sortable"
                                        id={routeProps.list}
                                        onChangeOrder={routeProps.onChangeOrder}
                                        onDrop={routeProps.onChangeOrder}
                                    />)}
                                /> : null }
                        </div>
                        <div className={formClassName}>
                            { id && !relation && !listPending ?
                                <Form
                                    type={list}
                                    id={id}
                                    keysActive={!this.props.modalForm}
                                    dismissible={true}
                                    backdrop={true}
                                />
                                : null }
                            { relatedId && !listPending ?
                                <Form
                                    type={"cms."+relation}
                                    id={relatedId}
                                    keysActive={!this.props.modalForm}
                                    dismissible={true}
                                    backdrop={true}
                                />
                                : null }
                        </div>
                    </div>
                </App>
            </AppContext>
        );
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Project);