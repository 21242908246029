import { call, takeEvery, select, put } from "redux-saga/effects";

import { query, resourceRoute } from "state/actions/route";
import namedActions from "state/actions/aliases";
import { resourceAction, resourceSave } from "state/actions";
import {
  selectResourceActions,
  selectListActions,
} from "state/selectors/schema";

function* handleResourceAction(action) {
  try {
    const { actionType, resource, id } = action;
    let context = action.context;
    context.app = context.appPath || context.app;
    let resourceDef = yield select((store) => store.resources[resource]);
    let dbActions = yield select((store) =>
      selectResourceActions(store, resource, id)
    );
    if (!resourceDef)
      resourceDef = yield select((store) => store.resources.default);
    let actionId = actionType;
    if (actionType === "primary") actionId = resourceDef.primaryAction;
    if (actionType === "secondary") actionId = resourceDef.secondaryAction;
    const actions = {
      ...resourceDef.actions,
      ...resourceDef.listActions,
      ...dbActions,
    };
    const actionDef = actions[actionId];
    if (actionDef.type === "route") {
      let actionRoute = actionDef.route || actionId;
      let resourceActionPath = resource + "/" + actionRoute;
      if (!actionRoute && actionDef.query) {
        yield put(query(actionDef.query));
      } else {
        yield put(
          resourceRoute(resourceActionPath, { ...context, id }, actionDef.query)
        );
      }
    }
    if (actionDef.type === "action") {
      let targetAction = namedActions[actionDef.action];
      let defArgs = actionDef.args || {};
      let defContext = actionDef.context || {};
      let actionArgs = {
        resource,
        id,
        list: resource,
        context: {
          ...context,
          ...defContext,
        },
        ...defArgs,
      };
      console.log(`MAP RESOURCE ACTION ${resource} | ${actionType}`);
      console.log(`----> ${actionDef.action} from state/actions/aliases.js`);
      console.log("----- arguments: resource, id, list,");
      console.log("----- {resource, id, list, context, ...defArgs},");
      console.log("----- context");
      console.log(actionArgs, defArgs);
      targetAction = targetAction(
        actionArgs.resource,
        actionArgs.id,
        actionArgs.list,
        defArgs,
        actionArgs.context
      );
      console.log(`----> ${targetAction.type}`);
      yield put(targetAction);
      if (targetAction.type === "RESOURCE.PATH.DETACH") {
        yield put(resourceSave("cms." + context.view, context.id));
      }
    }
  } catch (e) {}
}

function* handleListAction(action) {
  try {
    const { resource, list, context } = action;
    let actions = yield select((store) => selectListActions(store, resource));
    actions = Object.keys(actions);
    if (actions.length > 1) {
      yield put(query({ q: "list-actions" }));
    } else {
      let listAction = actions[0] || "create";
      yield put(resourceAction(listAction, resource));
    }
  } catch (e) {
    //yield ui.error(e);
  }
}

function* handleShowEditor(action) {
  console.log(action.context, action.id);
  const token = localStorage.getItem("access_token");
  const url = `http://plan-editor.d/?scope=apartments&id=${action.id}&access_token=${token}&project_id=${action.context.project}`;
  window.open(url);
}

export default function* () {
  yield takeEvery("RESOURCE.ACTION", handleResourceAction);
  yield takeEvery("LIST.ACTION", handleListAction);
  yield takeEvery("APARTMENTS.PLAN.EDITOR", handleShowEditor);
}
