import React, { Component } from "react";
import { connect } from "react-redux";
import ListWrapper from "components/list/ListWrapper";
import IconButton from "@material-ui/core/IconButton";
import IconMenu from "components/IconMenu";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "@material-ui/core/Icon";
import Dialog from "components/Dialog";
import Form from "components/form/Form";

import NestedList from "list/containers/NestedList";
import { Resource, Listing } from "list";

import { subtypeSchema } from "containers/ResourceForm";

import { query } from "state/actions/route";

import {
  draftsPathReorder,
  draftsPathTree,
  draftsPathChange,
  draftsPathDetach,
  draftsPathCreate,
  draftsPathToggle,
  resourceToggle,
} from "state/actions";

import {
  clipboardCopyCollection,
  clipboardPaste,
} from "state/actions/clipboard";

import { selectPath } from "state/selectors/resources";

import { bindMenuActions } from "components/menu/EnhancedMenu";

const contextMenu = [
  {
    key: "copy",
    caption: "Skopiuj",
    icon: "file_copy",
  },
  {
    key: "paste",
    caption: "Wklej",
    icon: "assignment",
  },
  {
    key: "clear",
    caption: "Wyczyść",
    icon: "clear",
  },
];

const mapStateToProps = (store, props) => {
  const data = selectPath(store, props.path.split("/"));
  const open = store.context["p." + props.id];
  const openIds = open && data ? open.split(",") : false;
  const selectedItem =
    data && openIds ? openIds.filter((id) => data.indexOf(id) > -1)[0] : false;
  const current = selectedItem ? [props.id, openIds] : null;

  /*const selectedItem = current && current[0] === props.id
        ? current[1]
        : null;*/

  const itemType =
    props.schema && props.schema.properties
      ? props.schema.properties.item_type
      : null;

  let resource = props.resource || [store.context.app, props.id].join(".");
  let baseResource = props.path.split("/")[0];
  let baseResourceType = baseResource.split(".")[1];

  if (itemType && resource !== "cms.components") resource = "cms.items";
  if (props.id === "components") resource = "cms.components";
  if (props.id === "links") resource = "cms.nav-link";
  if (baseResource === "cms.nav-link" && props.id === "children")
    resource = "cms.nav-link";
  if (baseResource === "cms.enum-definitions") resource = "cms.enum-values";
  if (baseResource === "db.enum-definitions") resource = "db.enum-values";

  let schemaPath = itemType
    ? "cms/blocks/types/" + itemType
    : props.schemaPath + "/" + props.id;

  if (resource === "db.fields" && selectedItem) {
    schemaPath = subtypeSchema(store, resource, selectedItem);
  }

  return {
    route: open,
    resource,
    data,
    formPath: selectedItem ? resource + "/" + selectedItem : null,
    schemaPath,
    itemType,
    selectedItem,
    isEmpty: !data || !data.length,
  };
};

const mapDispatchToProps = {
  query,
  reorder: draftsPathReorder,
  onCreate: draftsPathCreate,
  updateTree: draftsPathTree,
  detach: draftsPathDetach,
  onChange: draftsPathChange,
  onCopy: clipboardCopyCollection,
  onPaste: clipboardPaste,
  onToggle: resourceToggle,
};

export class Collection extends React.PureComponent {
  constructor(props) {
    super(props);

    this.contextMenu = bindMenuActions(contextMenu, {
      copy: this.handleCopy,
      paste: this.handlePaste,
      clear: this.handleClear,
    });
  }

  handleCopy = () => {
    this.props.onCopy(this.props.resource, this.props.value);
  };

  handlePaste = () => {
    this.props.onPaste(
      [this.props.resource, "collection"].join("."),
      this.props.path.split("/")
    );
  };

  handleClear = () => {
    this.handleUpdate({ data: [] });
  };

  handleEditItem = (item) => {
    if (this.props.childKey || this.props.resource === "cms.components") {
      this.props.query({ cp: item });
      return;
    }
    if (this.props.query) {
      let path = [this.props.id, item].join(".");
      let q = {};
      //q.item = path;
      let p = [];
      if (this.props.route) {
        p.push(this.props.route);
      }
      p.push(item);
      q["p." + this.props.id] = p.join(",");
      this.props.query(q);
    }
    if (this.props.onClick) {
      this.props.onClick(item);
    }
  };

  handleReorder = (drag, hover) => {
    this.props.reorder(this.props.path.split("/"), drag, hover);
  };

  handleUpdate = (data) => {
    this.props.onChange(this.props.path.split("/"), data.data);
  };

  handleAction = (action, resource, id) => {
    if (action === "delete" || action === "detach")
      this.props.detach(this.props.path.split("/"), id);
    if (action === "activate") {
      this.props.onToggle(this.props.resource, id, "active");
    }
  };

  handleCreate = () => {
    this.props.onCreate(this.props.resource, this.props.path.split("/"), {
      type: this.props.itemType,
      active: true,
    });
  };

  handleChangeItem = (id, data) => {};

  handleClose = () => {
    let q = {};
    let p = this.props.route.split(",");
    let data = this.props.data;
    p = p.filter((id) => data.indexOf(id) === -1).join(",");
    q["p." + this.props.id] = p ? p : null;
    //q.item = null;
    this.props.query(q);
  };

  render() {
    const {
      label,
      asideActions,
      selectedItem,
      formPath,
      schemaPath,
      resource,
      path,
      isEmpty,
    } = this.props;

    return (
      <React.Fragment>
        <NestedList
          resource={resource}
          path={path}
          id={path}
          view={resource}
          variant="sortable"
          onChangeOrder={this.handleUpdate}
          renderItem={(props) => (
            <Resource
              {...props}
              editable={true}
              onActionRequest={this.handleAction}
              actions={props.resource}
              onClick={this.handleEditItem}
              onChange={this.handleChangeItem}
            />
          )}
          renderList={(props) => (
            <ListWrapper
              {...props}
              primary
              title={label}
              wrapperClass={"list-collection"}
              asideActions={
                asideActions ? (
                  asideActions
                ) : (
                  <React.Fragment>
                    <IconButton onClick={this.handleCreate}>
                      <Icon>add_circle</Icon>
                    </IconButton>
                    <IconMenu icon="more_horiz" items={this.contextMenu} />
                  </React.Fragment>
                )
              }
            >
              {props.items}
            </ListWrapper>
          )}
        />
        <Dialog
          open={!!selectedItem}
          onBackdropClick={this.handleClose}
          maxWidth={false}
        >
          <div
            className="form form-embed visible"
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              width: "600px",
            }}
          >
            {formPath && schemaPath ? (
              <Form
                resource={resource}
                id={this.props.selectedItem}
                path={formPath}
                schemaPath={schemaPath}
                onSubmit={this.handleClose}
                keysActive={true}
              />
            ) : null}
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Collection);
