import React from 'react';
//import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import { icon as generateIcon } from 'util/icon';

export default class TooltipIconButton extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {tooltip: false};
    }
    
    handleClick = (evt) => {
        this.setState({tooltip: false});
        if (this.props.onClick) this.props.onClick(evt);
    };

    handleContext = (evt) => {
        evt.preventDefault();
        if (this.props.onContextMenu) this.props.onContextMenu(evt);
    };

    renderIcon = () => {
        const { icon, size, active, badge } = this.props;
        let color = active ? 'primary' : 'inherit';
        const iconComponent = generateIcon(icon, 'inherit', color);
        let renderedIcon = iconComponent ? iconComponent : <Icon
            fontSize={size}
            color={active ? 'primary' : 'inherit'}
        >
            {icon || 'star'}
        </Icon>;
        if (badge) {
            return <Badge color="primary" variant="dot">{renderedIcon}</Badge>
        }
        return renderedIcon;
    };

    render() {
        const { tooltip, disabled, ref } = this.props;


        let button = <IconButton
            ref={ref}
            disabled={disabled}
            onClick={this.handleClick} color="inherit"
            onContextMenu={this.handleContext}
            onMouseOver={()=>this.setState({tooltip:true})}
            onMouseOut={()=>this.setState({tooltip:false})}
        >
            {this.renderIcon()}
        </IconButton>;

        if (!tooltip) return button;

        return (<Tooltip open={this.state.tooltip} title={tooltip}>
            {button}
        </Tooltip>);
    }

}

TooltipIconButton.propTypes = {};