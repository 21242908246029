import uuid from "uuid/v4";

export const handleUpload = file => {
    return {
        type: "FILES.UPLOAD",
        payload: {
            id: uuid(),
            file
        }
    };
};

export const handleUploadDirect = (resource, path, file) => {
    return {
        type: "FILES.UPLOAD_DIRECT",
        resource,
        path,
        payload: {
            id: uuid(),
            file
        }
    }
};

export const uploadProgress = (id, progress) => {
    return {
        type: "FILES.UPLOAD.PROGRESS",
        payload: {
            id,
            progress
        }
    };
};

export const uploadSuccess = (id, data, resource = 'cms.media') => {
    return {
        type: "FILES.UPLOAD.SUCCESS",
        resource,
        payload: {
            id,
            data
        }
    };
};