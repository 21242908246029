import React, {Component} from 'react';
import {connect} from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import Collection from 'components/fields/collection/Collection';
import ComponentsTree from './ComponentsTree';
import Form from 'components/form/Form';
import CreateComponent from './CreateComponent';

import { subtypeSchema } from 'containers/ResourceForm';
import { selectPath } from 'state/selectors/resources';

import {
    query
} from 'state/actions/route';

import {
    draftsPathReorder,
    draftsPathCreate,
    draftsPathChange
} from 'state/actions';


const mapStateToProps = (store, props) => {
    const current = props.selectedItem !== false
        ? props.selectedItem || store.context.cp
        : null;

    const data = selectPath(store, props.path.split('/'));
    const selectedItem = current;
    const resource = props.resource || 'cms.components';
    return {
        view: store.context.view,
        data,
        selectedItem,
        resource,
        childKey: props.childKey || 'components',
        formPath: selectedItem
            ? resource + '/' + selectedItem
            : null,
        schemaPath: selectedItem
            ? subtypeSchema(store, resource, selectedItem)
            : null
    }
};

const mapDispatchToProps = {
    query,
    reorder: draftsPathReorder,
    onCreate: draftsPathCreate
};

class Components extends Component {

    handleClose = () => {
        this.props.query({cp: null, cpe: null});
    };

    handleCreate = (type) => {
        let data = {};
        let resource = this.props.resource;
        let typeKey = resource === 'cms.components' ? 'element_type' : 'type';
        data[typeKey] = type;
        this.props.onCreate(
            resource,
            this.props.path.split('/'),
            data
        )
    };

    render() {
        const { id, view, formPath, schemaPath, resource, dialogOnly } = this.props;
        if (id === 'components' && view !== 'components') return null;
        return (<React.Fragment>
            { !dialogOnly ? <Collection
                {...this.props}
                id={id}
                resource={resource}
                asideActions={<React.Fragment>
                    <CreateComponent resource={resource} onCreate={this.handleCreate} />
                </React.Fragment>}
            /> : null }
            <Dialog
                open={!!this.props.selectedItem}
                onBackdropClick={this.handleClose}
                maxWidth={false}
            >
                <div style={{ display: 'flex', width: '1200px', maxWidth: '90vw', height: '800px', overflow: 'hidden' }}>
                    <div className="form__sidebar">
                        <div style={{ padding: '1rem 1rem 0 1rem', display: 'flex', justifyContent: 'flex-end' }}>
                        <CreateComponent resource={resource} onCreate={this.handleCreate} />
                        </div>
                        <ComponentsTree
                            path={this.props.path}
                            resource={this.props.resource}
                            childKey={this.props.childKey}
                            onClick={this.props.onClick}
                            onEdit={this.props.onEdit}
                            selectedItem={this.props.selectedItem}
                        />
                    </div>
                    <div className="form form-embed visible" style={{ flex:1, display: 'flex', flexDirection: 'column' }}>
                        { formPath && schemaPath ? (<Form
                            resource={resource}
                            id={this.props.selectedItem}
                            path={formPath}
                            schemaPath={schemaPath}
                            onSubmit={this.handleClose}
                        />) : null }
                    </div>
                </div>
            </Dialog>
        </React.Fragment>);
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(Components);