import React from 'react';
import {connect} from 'react-redux';

import { selectResource } from 'state/selectors/resources';
import { Collection } from './Collection';

import withSelectOptions from 'components/fields/select/SelectOptions';
import SelectMenu from 'components/fields/select/SelectMenu';

import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import {
    draftsPathAttach,
    draftsPathDetach,
    draftsPathReorder,
    listFilter
} from "state/actions";

import { changed } from 'util/changed';

const serialize = function(obj, prefix) {
    var str = [],
        p;
    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            var k = prefix ? prefix + "[" + p + "]" : p,
                v = obj[p];
            str.push((v !== null && typeof v === "object") ?
                serialize(v, k) :
                encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
    }
    return str.join("&");
};

const mapStateToProps = (store, props) => {
    const parent = props.parentPath.split('/');
    const parentResource = selectResource(store, parent[0], parent[1]);
    const filters = {
        entry_type: parentResource.entry_type,
        data_source: parentResource.data_source
    };
    const filterId = [props.path.replace('/', '.'), 'options'].join('.');
    const resource = 'cms.entries.' + filters.entry_type;
    let source = ["api:data"];
    let params = serialize(filters);
    if (params) source.push(params);
    source = source.join('?');
    return {
        source,
        parent: parentResource,
        listId: filterId,
        filterId,
        queryParams: filters,
        resource,
        data: props.value,
        isPending: store.ui.pending[filterId],
        dataPath: [props.parentPath,props.id].join('/'),
        dataResource: resource
    }
};

const mapDispatchToProps = {
    attach: draftsPathAttach,
    reorder: draftsPathReorder,
    detach: draftsPathDetach,
    setFilter: listFilter
};

class ListResourceCollection extends React.PureComponent {

    handleSelect = (id) => {
        this.props.attach(
            this.props.dataPath.split('/'),
            id
        );
    };

    handleSearch = (id, value) => {
        this.props.setFilter(this.props.filterId, {
            ...this.props.query,
            search: value
        });
    };
    
    handleChange = (path, data) => {
        //console.log(path, data);
        this.props.onChange(data);
    };

    render() {
        const { isPending, options } = this.props;
        let value = this.props.value || [];
        let label = [
            this.props.label,
            'wybrano ' + value.length + ' z ' + options.length
        ].join(', ');
        if (isPending) return null;
        return (<React.Fragment>
            <Collection
                data={value}
                path={this.props.dataPath}
                resource={this.props.dataResource}
                onChange={this.handleChange}
                detach={this.props.detach}
                label={label}
                asideActions={<SelectMenu
                    onSelect={this.handleSelect}
                    onSearch={this.handleSearch}
                    filters={this.props.filters}
                    trigger={(props) => <IconButton {...props}>
                        <Icon>add_circle</Icon>
                    </IconButton>}
                    options={options}
                />}
                onCreate={this.handleCreate}
            /></React.Fragment>
        );
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withSelectOptions()(ListResourceCollection));