import React from 'react';
import {connect} from 'react-redux';

import { colors } from 'config/colors';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

const swatchStyle = {
    width: '28px',
    height: '28px',
    borderRadius: '999px',
    border: 'solid 2px transparent',
    margin: '4px'
};

const valueStyle = {
    width: '100%',
    height: '34px',
    fontSize: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    borderRadius: '4px',
    margin: '4px'
};

class Color extends React.Component {

    handleChange = (value) => {
        this.props.onChange(this.props.id, value);
    };

    render() {
        const { value } = this.props;
        return (<div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Typography style={valueStyle} className={"bg-"+value}>{this.props.value || 'wybierz'}</Typography>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {Object.keys(colors).map(color=>{
                    return (<Tooltip key={color} title={color}>
                        <div
                            onClick={()=>this.handleChange(color)}
                            className={"bg-"+color}
                            style={swatchStyle}
                        />
                    </Tooltip>);
            })}
            </div>
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Color);