import React from 'react';

const DataContext = React.createContext(null);
export default DataContext;

export const MergeDataContext = (props) => {
    return <DataContext.Consumer>
        {path => (
            <DataContext.Provider value={[path,props.value].join('$')}>
                {props.children}
            </DataContext.Provider>
        )}
    </DataContext.Consumer>
};