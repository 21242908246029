import React from 'react';
import {connect} from 'react-redux';
import ResourcesNav from 'containers/ResourcesNav';
import IconButton from 'components/TooltipIconButton';
import Link from 'components/router/Link';

import asideResources, {asideHide} from 'config/aside-resources';

const mapStateToProps = (store, props) => {
    return {
        app: store.context.app
    }
};

const mapDispatchToProps = {
};

class ResourceLink extends React.PureComponent {
    renderButton = (linkProps) => {
        const { id, icon, name, current } = this.props;
        return <IconButton
            tooltip={name}
            active={current===id}
            {...linkProps}
            icon={icon||id}
        />;
    };
    render() {
        const props = this.props;
        return <Link to={props.route} render={this.renderButton} />
    }
}

class SideNav extends React.PureComponent {

    render() {
        return <React.Fragment>
            <ResourcesNav render={({resources, current})=>{
                let filtered = resources.filter((res)=>{
                    return (asideResources.indexOf(res.id) > -1 || res.id.indexOf('db.') > -1)
                        && asideHide.indexOf(res.id) === -1
                        && !res.subtype_of
                        && res.type !== 'job'
                        && (!!res.quick_access || res.id.indexOf('db.') === -1)
                });
                return filtered.map((res) => {
                    if (!res.route) return null;
                    return (<ResourceLink key={res.id} {...res} />);
                });
            }} />
            { this.props.app === 'cms' ? <ResourceLink
                id="cms.media-library"
                tooltip="Media library"
                route="/:app/:lang/:project/media"
            /> : null }
        </React.Fragment>;
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SideNav);