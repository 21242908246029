export const initLibrary = (listId) => ({
   type: 'MEDIA.INIT',
   listId
});

export const mediaCreateDirectory = (parent, name) => ({
   type: 'MEDIA.DIRECTORY.CREATE',
   parent,
   name
});

export const mediaDeleteFile = (id) => ({
   type: 'MEDIA.DELETE',
   id
});

export const mediaImport = (id) => ({
   type: 'MEDIA.IMPORT',
   id
});

export const syncDirectory = (dir) => ({
   type: 'MEDIA.SYNC_DIR',
   dir
});