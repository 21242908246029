import React from 'react';
import {connect} from 'react-redux';
import {query} from 'state/actions/route';
import Collection from './Collection';
import {draftsPathCreate} from "state/actions";
import {handleUploadDirect} from "state/actions/files";
import MediaCollectionActions from "components/fields/media/MediaCollectionActions";

const mapStateToProps = (store, props) => {
    return {
        itemResource: ['db', props.id].join('.'),
        path: [props.resource,props.resourceId,props.id].join('/')
    }
};

const mapDispatchToProps = {
    onCreate: draftsPathCreate,
    onUpload: handleUploadDirect,
    query
};

class Media extends React.Component {

    handleUpload = (files) => {
        files.map((file)=>this.props.onUpload(
            ['db', this.props.id].join('.'),
            this.props.path.split('/'),
            file
        ));
    };

    handleSelect = (value) => {
        this.props.onCreate(
            this.props.itemResource,
            this.props.path.split('/'),
            value,
            null,
            false
        );
        if (this.props.autoSave) this.props.onSubmit();
    };

    handlePreview = (id) => {
        this.props.query({gallery: [this.props.itemResource, id].join('/')});
    };

    renderActions = () => {
        return <MediaCollectionActions
            onSelect={null /*this.handleSelect*/}
            onUpload={this.handleUpload}
            path={this.props.path}
        />;
    };
    
    render() {
        return <Collection
            {...this.props}
            viewType="media"
            card={!this.props.readOnly}
            onClick={this.handlePreview}
            actions={this.props.onChange && !this.props.readOnly ? this.renderActions() : null}
        />;
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Media);