import TaskHeader from "./TaskHeader";
import TaskHistory from './TaskHistory';
import Thread from './Thread';
import EvernymConnectedForm from "../../evernym/EvernymConnectedForm";

export default {
    'task-header': TaskHeader,
    'task-history': TaskHistory,
    'thread': Thread,
    'evernym-connected-form': EvernymConnectedForm
}