import React from 'react';
//import PropTypes from 'prop-types';

export default class Text extends React.PureComponent {

    render() {
        return this.props.value;
    }

}

Text.propTypes = {};