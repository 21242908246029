import React from 'react';
//import PropTypes from 'prop-types';

import { List as VirtualizedList } from 'react-virtualized';
import List from '@material-ui/core/List';
import Filters from 'containers/list/Filters';

import ResourceListItem from 'components/list/ResourceListItem';
import FontPicker from 'components/fields/theme/FontPicker';
import DropArea from 'containers/DropArea';
import Popover from 'components/Popover';
import FontWeights from 'components/fields/theme/FontPicker/FontWeights';

import TypographyStylesCollection from './TypographyStylesCollection';

const fonts = ["body_font", "heading_font", "decorative_font"];

export default class FontBrowser extends React.PureComponent {

    rowRenderer = ({index, style}) => {
        const { data } = this.props;
        return (<ResourceListItem
            key={index}
            style={style}
            resource={this.props.listId}
            view="cms.fonts"
            id={data[index]}
            onClick={(id)=>alert(id)}
            draggable={true}
        />);
    };

    render() {
        const {
            data,
            filters,
            onSetFilter,
            filterId,
            onChangeFont,
            value,
            path
        } = this.props;

        return (<div style={{ display: 'flex' }}>
            <div style={{ padding: '1rem'}}>
            <div style={{ width: '240px' }}><Filters
                resource="cms.fonts"
                filterId={filterId}
            />
            <List>
                <VirtualizedList
                    width={240}
                    height={600}
                    rowCount={data.length}
                    rowHeight={45}
                    rowRenderer={this.rowRenderer}
                />
            </List>
            </div>
            </div>
            <div style={{ width: '720px', padding: '1rem' }}>
                <div style={{ display: 'flex', marginBottom : '2rem' }}>
                {fonts.map(font => {
                    return (
                        <DropArea
                            key={font}
                            onDropInto={(v)=>onChangeFont(font, v.id)}
                            style={{ flex: 1 }}
                        >
                            <Popover
                                trigger={(props)=><FontPicker
                                    label={font}
                                    id={font}
                                    value={value ? value[font] : null}
                                    {...props}
                                />}
                                content={(props)=><FontWeights
                                    value={value ? value[font] : null}
                                    onChange={(field,value)=>this.props.onChangeField(font, field, value)}
                                />}
                            />

                        </DropArea>
                    );
                })}
                </div>
                <TypographyStylesCollection
                    id="styles"
                    onChange={this.props.onChange}
                    path={[path, 'styles'].join('/')}
                    preset={{...value}}
                />
            </div>
        </div>);
    }

}

FontBrowser.propTypes = {};