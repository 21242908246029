import React from 'react';
import PropTypes from 'prop-types';

import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';

const paperStyle = {
    item: {
        padding: '0.2rem',
        margin: '0.2rem',
        transform: 'translateY(-100%)',
        display: 'flex',
        color: 'black'
    },
    section: {
        margin: '1rem',
        transform: 'translateY(-70px)',
        display: 'flex'
    },
    'layout-section': {
        margin: '1rem',
        transform: 'translateY(-70px)',
        display: 'flex'
    }
};

const modifiers = {
    flip: {
        enabled: false,
    }
};

export default class Toolbar extends React.Component {

    render() {
        const { id, placement, open, anchorEl } = this.props;
        let type = this.props.type || 'item';
        let { onMouseEnter } = this.props;
        return ( <Popper
                id={id}
                open={open || false}
                anchorEl={anchorEl}
                placement={placement || "bottom"}
                className="ide-toolbar"
                disablePortal={true}
                modifiers={modifiers}
        >
            <Paper
                style={paperStyle[type]}
                className={"ide-toolbar__options toolbar-"+type}
                onMouseEnter={onMouseEnter}
            >
                {this.props.children}
            </Paper>
        </Popper>);
    }

}

Toolbar.propTypes = {
    id: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired
};