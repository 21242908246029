import {
    takeEvery,
    put,
    call,
    all
} from 'redux-saga/effects'

import * as ui from 'util/sagas/feedback';
import * as api from 'util/api/saga';

import { cloneSection } from 'state/selectors/clipboard';

import {
    draftsPathAttach,
    draftsPathDetach,
    listFetchSuccess
} from 'state/actions';

export function* handleImport(action) {
    const { project, from, to, context } = action;
    try {
        const sourceContext = {...context, project};
        const response = yield call(api.getArgs, {export: true}, from[0], from[1], sourceContext);
        const data = response.data.data;
        const copy = cloneSection(data, true, project);
        const stylesImportReq = {
            website_id: sourceContext.project,
            styles: copy.styles,
            sections: copy.sections
        };
        const importResponse = yield call(api.post, stylesImportReq, 'cms.sections/import', null, context);
        const importedSections = importResponse.data.data.sections;
        yield put.resolve(listFetchSuccess(from[0], {data: importedSections}, from[0], true));
        yield ui.success();
        return importedSections;
    } catch (e) {
        yield ui.error(e);
        throw e;
    }
}

function* handleExport(action) {
    try {
        const { project, from, context } = action;
        const resource = from[0];
        if (!project) throw new Error('Project id not defined');
        if (!from[1]) throw new Error('Resource id not defined');
        const targetContext = {...context, project};
        yield ui.pending('cms.sections.attach.'+project, true);
        const response = yield call(api.getArgs, {export: true}, resource, from[1], context);
        const data = response.data.data;
        const copy = cloneSection(data, true, context.project);
        const stylesImportReq = {
            website_id: context.project,
            styles: copy.styles,
            sections: copy.sections
        };
        yield call(api.post, stylesImportReq, 'cms.sections/import', null, targetContext);
        yield ui.success();
        yield ui.pending('cms.sections.attach.'+project, false);
    } catch (e) {
        yield ui.error(e);
        yield ui.pending('cms.sections.attach.'+action.project, false);
    }
}

export function* handleCopy(action) {
    try {
        const { from, context } = action;
        const response = yield call(api.getArgs, {export: true}, from[0], from[1], context);
        const data = response.data.data;
        const copy = cloneSection(data, false);
        const stylesImportReq = {
            website_id: context.project,
            sections: copy.sections
        };
        const importResponse = yield call(api.post, stylesImportReq, 'cms.sections/import', null, context);
        const importedSections = importResponse.data.data.sections;
        yield put.resolve(listFetchSuccess(from[0], {data: importedSections}, from[0], null, true));
        return copy;
    } catch (e) {
        yield ui.error(e);
    }
}

export default function*() {
    yield takeEvery('LIBRARY.IMPORT', handleImport);
    yield takeEvery('LIBRARY.EXPORT', handleExport);
}