import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/lab/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import HomeIcon from '@material-ui/icons/Home';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
    root: {
        padding: theme.spacing.unit,
    },
    chip: {
        backgroundColor: theme.palette.grey[100],
        height: 24,
        color: theme.palette.grey[800],
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.grey[300],
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(theme.palette.grey[300], 0.12),
        },
    },
    avatar: {
        background: 'none',
        marginRight: -theme.spacing.unit * 1.5,
    },
});

function handleClick(event) {
    event.preventDefault();
    alert('You clicked a breadcrumb.'); // eslint-disable-line no-alert
}

function CustomBreadcrumb(props) {
    const { classes, ...rest } = props;
    return <Chip className={classes.chip} {...rest} />;
}

CustomBreadcrumb.propTypes = {
    classes: PropTypes.object.isRequired,
};

const StyledBreadcrumb = withStyles(styles)(CustomBreadcrumb);

function CustomizedBreadcrumbs(props) {
    const { classes } = props;

    return (
        <Paper className={classes.root}>
            <Breadcrumbs arial-label="Breadcrumb">
                <StyledBreadcrumb
                    component="a"
                    href="#"
                    label="Home"
                    avatar={
                        <Avatar className={classes.avatar}>
                            <HomeIcon />
                        </Avatar>
                    }
                    onClick={handleClick}
                />
                <StyledBreadcrumb component="a" href="#" label="Catalog" onClick={handleClick} />
                <StyledBreadcrumb
                    label="Accessories"
                    deleteIcon={<ExpandMoreIcon />}
                    onClick={handleClick}
                    onDelete={handleClick}
                />
            </Breadcrumbs>
        </Paper>
    );
}

CustomizedBreadcrumbs.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomizedBreadcrumbs);