import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import Portal from '@material-ui/core/Portal';
import {FormLayoutContext} from 'components/form/Form';
import Access from 'components/Access';
import FieldMapping from 'components/fields/data-mapping/FieldMapping';
import {withMapping} from 'containers/FormFieldMapped';
import _ from 'lodash';

import {
    draftsChange,
    resourceChange
} from "state/actions";

import {setUiFlag} from 'state/actions/ui';

import {selectPathMapping,selectTemplateMapping} from 'state/selectors/ide';
import {serializeMapping} from "util/templatable";

import {
    selectPath
} from 'state/selectors/resources';

import fields from 'components/fields';

export function withSchema(path) {
    return function(DecoratedComponent) {
        const mapStateToProps = (store, props) => {
            const schema = _.get(store.schema, path);
            if (!schema) {
                throw new Error(`Unable to resolve schema for ${path}`);
            }
            return {
                schemaPath: path,
                schema
            }
        };
        const mapDispatchToProps = {};
        class WithSchema extends React.PureComponent {
            render() {
                return (<DecoratedComponent {...this.props} />);
            }
        }
        return connect(mapStateToProps, mapDispatchToProps)(WithSchema)
    }
}

const mapStateToProps = (store, props) => {
    //const path = props.parentPath + '/' + props.id;

    if (!props.parentPath || !props.id) {
        throw new Error(`Undefined parentPath or id ${props.schemaPath} ${props.parentPath} ${props.id}`);
    }

    const path = props.parentPath.split('/').concat(props.id.split('.')).join('/');
    const schemaPath = props.schemaPath.split('/').concat([props.id.replace(/\//g, '.')]);
    const schema = _.get(store.schema, schemaPath);
    if (!schema) {
        throw new Error(`Unable to resolve schema for ${schemaPath.join('/')}`);
    }
    const storeId = props.storeId || 'drafts';
    const value = selectPath(store, path.split('/'));
    let uiType = schema.properties
        ? schema.properties.formFieldType
        : null;

    let type = uiType || schema.type;
    let id = props.id.replace(/\./g, '/');

    if (id === 'slug') type = 'slug';

    return {
        id: id,
        path: path,
        value: value,
        schema: schema,
        component: fields[type] || fields[type] || fields.default,
        storeId
    }
};

const mapDispatchToProps = {
    onChange: draftsChange,
    onChangeResource: resourceChange
};

export class FormField extends React.PureComponent {

    handleChange = (key, value) => {
        let update = {};
        let updatePath = this.props.parentPath.split('/');
        let keyParts = key.split('/');
        if (keyParts.length > 1) {
            updatePath = updatePath.concat(keyParts.slice(0, -1));
            update[keyParts.slice(-1)[0]] = value;
        } else {
            update[key] = value;
        }
        if (this.props.storeId === 'drafts') {
            this.props.onChange(updatePath, update);
        } else {
            this.props.onChangeResource(updatePath, update);
        }
    };

    renderPositionedField = (ref) => {

    };

    render() {
        const {
            schema,
            schemaPath,
            path,
            parentPath,
            id,
            value,
            storeId,
            headerRef,
            onClick,
            ...other
        } = this.props;

        let FieldComponent = this.props.component;

        let field = 
            <FieldComponent
                {...other}
                {...schema}
                {...schema.properties}
                id={id}
                path={path}
                parentPath={parentPath}
                value={value}
                schema={schema}
                schemaPath={schemaPath}
                storeId={storeId}
                onChange={this.handleChange}
            />;

        let wrappedField = <div
            className="form-field"
            onMouseDown={this.props.onClick}
        >
            <div style={{ pointerEvents: onClick ? 'none' : 'all' }}>
                {field}
            </div>
        </div>;

        let scope = schema.properties
            ? schema.properties.scope
            : null;
        
        if (
            schema.properties
            && schema.properties.area === 'header'
            && wrappedField
        ) {
            return headerRef ? <Portal container={headerRef}>
                <Access scopes={scope}>{field}</Access>
            </Portal> : <Access scopes={scope}>{wrappedField}</Access>;
        }
        return <Access scopes={scope}>{wrappedField}</Access>;
    }

}

FormField.propTypes = {
    id: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    parentPath: PropTypes.string.isRequired,
    schemaPath: PropTypes.string.isRequired,
    schema: PropTypes.object.isRequired
};

const MappedFormField = withMapping()(FormField);

const ConnectedFormField = connect(
    mapStateToProps,
    mapDispatchToProps
)(MappedFormField);

ConnectedFormField.propTypes = {
    parentPath: PropTypes.string.isRequired,
    schemaPath: PropTypes.string.isRequired
};

const PositionedFormField = (props) => {
    return (<FormLayoutContext.Consumer>
        {headerRef=>{return <ConnectedFormField
            {...props} headerRef={headerRef}
        />}}
    </FormLayoutContext.Consumer>)
};

export default PositionedFormField;