import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { createReducer } from "util/redux/create-reducer";
import reorder, { moveAtPosition } from 'util/reorder';
import uuid from 'uuid/v4';

const nullObj = {};
const nullArr = [];

const actions = {
    'SET': (state, {data}) => {
        return {...state, data: data ? data.slice() : nullArr, lock: null, item: null};
    },
    'REORDER': (state, {drag, hover}) => {
        return {
            ...state,
            data: reorder(state.data, drag.id, hover.id, true),
            dangling: false
        };
    },
    'PLACEHOLDER': (state, {drag}) => {
        let data = state.data.slice();
        let id = uuid();
        data.push(id);
        return {
            ...state,
            data,
            lock: id,
            item: drag,
            dangling: true
        };
    },
    'REJECT': (state, {drag}) => {
        let data = state.data.slice();
        data.splice(data.indexOf(state.lock), 1);
        return {
            ...state,
            data,
            lock: null,
            item: null
        }
    }
};

const reducer = createReducer({data: []}, actions);
const emptyObj = {};

export default class LocalState extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {data: []};
        this.hover = null;
        this.throttledUpdate = this.update; //_.debounce(this.update, 100);
    }

    /**
     * Call internal state reducer
     * @param action
     */
    update = (action) => {
        this.setState(reducer(this.state, action));
    };

    /**
     * Sync internal list to props
     * @returns {*}
     */
    syncDataToProps = () => {
        this.update({type: 'SET', data: this.props.data});
    };

    componentDidMount() { this.syncDataToProps(); }
    componentDidUpdate(prevProps) {
        if (!prevProps.data || !this.props.data) { this.syncDataToProps(); return }
        if (prevProps.data.join(',') !== this.props.data.join(',')) {
            this.syncDataToProps();
        }
    }

    /**
     * Update internal list order or placeholder position
     *
     * @param drag
     * @param hover
     */
    handleSort = ({drag, hover}) => {
        this.hover = hover;

        if (drag.origin === hover.origin) {
            this.throttledUpdate({type: 'REORDER', drag, hover});
        }
        if (this.state.lock) {
            drag = {id: this.state.lock};
            this.throttledUpdate({type: 'REORDER', drag, hover});
        }
    };

    /**
     * Submit internal state
     *
     * @param drag
     * @param hover
     */
    handleSortEnd = ({drag, hover}) => {
        if (drag.origin !== hover.origin) return;
        if (this.state.lock) return;
        const result = {
            data: this.state.data,
            copy: {}
        };
        result.copy[drag.id] = drag;
        this.props.onChangeOrder(result);
    };

    /**
     * Accept external item as a placeholder
     *
     * @param drag
     * @param hover
     */
    handleDragInto = ({drag, hover}) => {
        if (drag.origin === this.props.id) return;
        if (this.state.lock) return;
        this.update({type: 'PLACEHOLDER', drag});
    };

    /**
     * External items are being added on last position
     * Update placeholder position as soon as list item hover
     * event gets triggered
     *
     * @param drag
     * @param hover
     */
    handleHoverItem = ({drag, hover}) => {
        if (this.state.lock && this.state.dangling) {
            drag = {id: this.state.lock};
            this.update({type: 'REORDER', drag, hover});
        }
    };

    /**
     * Remove placeholder when external item is being
     * dragged back outside
     *
     * @param drag
     */
    handleDragOutside = ({drag}) => {
        if (drag.origin === this.props.id || !this.state.lock) return;

        /** Delay to let drop event interrupt **/
        this.outsideTimeout = setTimeout(()=>{
            if (window.__queueDragEffect) return;
            this.update({type: 'REJECT', drag})
        }, 1);
    };

    handleDrop = ({drag, hover}) => {
        console.log(drag, hover);
        if (drag.origin === this.props.id) return;

        /** cancel drag outside execution **/
        clearTimeout(this.outsideTimeout);

        let result = {data: this.state.data, listId: this.props.id};
        
        if (this.state.lock) {
            const copy = {};
            copy[this.state.lock] = this.state.item;
            result = {data: this.state.data, copy: copy, listId: this.props.id};
            result.copy = copy;
        } else {
            result.copy = {};
            result.copy[drag.id] = drag;
        }

        if (window.__queueDragEffect) {
            result.effect = window.__queueDragEffect.effect;
            delete window.__queueDragEffect;
        }

        this.props.onDrop(result);
    };
    
    resolvePlaceholder = ({isDragging, id}) => {
        if (!this.props.onChangeOrder) return false;
        return isDragging || id === this.state.lock;
    };

    handleItemDragEnd = (result) => {
        window.__queueDragEffect = result;
    };

    itemHandlers = {};

    handlers = () => {
        const { onChangeOrder, onDrop, onDropItem } = this.props;
        const handlers = this.itemHandlers;
        if (onChangeOrder) {
            handlers.onSort = this.handleSort;
            handlers.onSortEnd = this.handleSortEnd;
        }
        if (onChangeOrder && onDrop) {
            handlers.onHover = this.handleHoverItem;
        }
        if (onDropItem) {
            handlers.onDrop = onDropItem;
        }
        handlers.isPlaceholder = this.resolvePlaceholder;
        handlers.onItemDragEnd = this.handleItemDragEnd;
        return handlers;
    };

    delayedDrop = (result) => {
        setTimeout(()=> {
            this.handleDrop(result);
        },1)
    };
    
    listHandlers = () => {
        const { onDrop, onChangeOrder } = this.props;
        const handlers = {};
        if (onDrop && onChangeOrder) {
            handlers.onHoverOut = this.handleDragOutside;
            handlers.onHover = this.handleDragInto;
        }
        if (onDrop) {
            handlers.onDrop = this.delayedDrop;
        }
        return handlers;
    };

    render() {
        const render = this.props.children;
        return render({
            data: this.state.data,
            itemHandlers: this.handlers(),
            listHandlers: this.listHandlers()
        });
    }
}

LocalState.propTypes = {
    data: PropTypes.array.isRequired
};