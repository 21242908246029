import React from 'react';
import PropTypes from 'prop-types';

export default class Rich extends React.PureComponent {

    render() {
        const { value } = this.props;
        return (<div dangerouslySetInnerHTML={{__html: value}} />);
    }

}

Rich.propTypes = {};