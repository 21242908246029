import React from 'react';
import {connect} from 'react-redux';
import {query} from "state/actions/route";
import {Card} from "@material-ui/core";
import Button from "components/Button";
import Resource from "../../v2/Resource";
import DateDiff from "./fields/DateDiff";
import IconButton from "../IconButton";

const mapStateToProps = (store, props) => {
    let listId = [props.list, 'meta', 'cms.sync-tasks'].join('.');
    return {
        data: store.lists[listId] || []
    }
};

function triggerJob(job, data, list) {
    return {
        type: 'DB.JOB_EXECUTE',
        job,
        data,
        list
    }
}
const mapDispatchToProps = {
    onSyncRequest: triggerJob,
    query
};

class ListSyncTasks extends React.PureComponent {

    sync = () => {
        const { list } = this.props;
        const { data, onSyncRequest } = this.props;
        onSyncRequest('sync-task', {
            'sync-task': data[0]
        }, list);
    };

    settings = () => {
        const { data } = this.props;
        this.props.query({
            edit: ['cms.sync-tasks', data[0]].join('/')
        });
    };

    render() {
        const { data } = this.props;
        if (!data[0]) return null;

        return (<div style={{ padding: '1rem' }}>
            <Card style={{
                fontSize: '12px',
                padding: '0.65rem 1rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <div>
                    <Resource resource={'cms.sync-tasks'} id={data[0]} fetchPolicy={'never'}>
                        {({data}) => {
                            return <Resource resource={'cms.integrations'} id={data.integration}>
                                    {(props) => {
                                        return <div>
                                            <div style={{ fontSize: '1.2rem', textTransform: 'uppercase' }}>{props.data.type}</div>
                                            <div style={{ opacity: 0.5 }}>
                                                Ostatnia synchronizacja: {data.synced_at ? <DateDiff value={data.synced_at}/> : <strong>nigdy</strong> }
                                            </div>
                                        </div>
                                    }}
                            </Resource>
                        }}
                    </Resource>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: '1rem' }}>
                        <IconButton onClick={this.settings} icon={'settings'} />
                    </div>
                    <Button onClick={this.sync}>
                        Pobierz
                    </Button>
                </div>
            </Card>
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListSyncTasks);