import React from 'react';
import {connect} from 'react-redux';
import {query} from 'state/actions/route';
import {listCreate} from 'state/actions';
import Dialog from 'components/Dialog';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ResourceIcon from 'components/list/avatar/ResourceIcon';

const mapStateToProps = (store, props) => {
    let schema = store.schema[store.context.app];
    let integrations = schema.integrations;
    return {
        open: store.context.q === 'create-integration',
        listId: [store.context.app, store.context.view].join('.'),
        data: integrations ? Object.values(integrations.types) : []
    }
};

const mapDispatchToProps = {
    listCreate,
    query
};

class TypeSelectDialog extends React.Component {

    handleCreate = (type) => {
        this.props.listCreate(
            this.props.listId,
            this.props.listId,
            {type}
        );
        this.handleClose();
    };

    handleClose = () => {
        this.props.query({q: null});
    };

    render() {
        return (<Dialog
            open={this.props.open}
            onClose={this.handleClose}
            maxWidth="md"
            fullWidth={true}
        >
            <div className="resource-grid" style={{ padding: '1rem', backgroundColor: 'rgba(0,0,0,0.1)' }}>
            {this.props.data.map(type=> {
                let typeKey = ['cms.integrations', type.id].join('.');
                return (<Card className="resource-card" key={type.id} onClick={()=>this.handleCreate(type.id)}>
                    <CardContent>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <ResourceIcon
                                resource={typeKey}
                                resourceTypeKey={typeKey}
                            />
                            <Typography variant="h6" style={{ marginLeft: '1rem' }}>{type.id}</Typography>
                        </div>
                    </CardContent>
                </Card>);
            })}
            </div>
        </Dialog>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TypeSelectDialog);