import React from 'react';
import PropTypes from 'prop-types';

const stayInfoStyle = {
    backgroundColor: 'rgba(0,0,0,0.05)',
    borderRadius: '4px',
    padding: '3px 5px',
    display: 'flex'
};

export default class TaskStaySummary extends React.Component {

    render() {
        const {
            isCheckin,
            isCheckout,
            checkinHour,
            checkoutHour,
            guests,
            defaultGuests
        } = this.props;

        let guestsCount = guests || defaultGuests || 0;

        return (<div style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
            <div style={isCheckout ? stayInfoStyle : null}>{isCheckout ? <div>Wyjazd: {checkoutHour || '11:00'}</div> : null }</div>
            <div style={{ display: 'flex' }}>
                { isCheckin ? <div style={stayInfoStyle}>
                    <div>Przyjazd: {checkinHour || '16:00'}</div>
                </div> : null }
                { guestsCount ? <div style={stayInfoStyle}>{guestsCount} os</div> : null }
            </div>
        </div>);
    }

}

TaskStaySummary.propTypes = {
    isCheckin: PropTypes.any,
    isCheckout: PropTypes.any,
    checkinHour: PropTypes.string,
    checkoutHour: PropTypes.string,
    guests: PropTypes.string
};