import {selectAllResources, selectResource, selectList, index} from "./resources";

const nullObj = {};

const fieldSchemaSelector = (store, resource, id, fieldId, fieldName) => {
    if (!resource) return nullObj;
    let data = selectResource(store, resource, id);
    if (data.subtype) {
        let subtype = selectResource(store, 'db.types', data.subtype);
        resource = ['db', subtype.name].join('.');
    }
    let resourceName = resource.split('.')[1];
    let typeResource = selectResource(store, 'db.types.byName', resourceName);
    let fieldsIndex = null;
    if (typeResource.type === 'collection'){
        fieldsIndex = typeResource.fields;
    } else {
        let typeId = typeResource.id;
        let type = selectResource(store, 'db.types', typeId);
        fieldsIndex = type ? type.fields_index : null;
    }
    if (fieldName && fieldsIndex && fieldsIndex[fieldName]) fieldId = fieldsIndex[fieldName].uuid;
    let fieldSchema = selectResource(store, 'db.fields', fieldId);
    if (fieldSchema.type === 'virtual' && fieldSchema.properties && fieldSchema.properties.fields) {
        let field = fieldsIndex[fieldSchema.properties.fields];
        if (field) return selectResource(store, 'db.fields', field.uuid);
    }
    return fieldSchema;
};

const viewIdSelector = (store, viewType, resource, id, typeFilter) => {
    if (!resource) return null;
    
    let viewResourceName = viewType === 'list'
        ? 'list-views'
        : 'form-views';

    let viewResource = ['db',viewResourceName].join('.');
    let views = selectAllResources(store, viewResource);

    let view = resource.split('.')[1];
    let type = selectResource(store, 'db.types.byName', view);
    let typeId = type.id || resource;

    let data = resource && id ? selectResource(store, resource, id) : {};
    if (data.subtype) typeId = data.subtype; //console.log('view',data);

    let typeViews = views.filter(view=>{
        if (view.resource!==typeId) return false;
        if (data.draft && view.target_action === 'edit') return false;
        if (!data.draft && view.target_action === 'create') return false;
        return true;
    });
    //if (typeFilter) typeViews = typeViews.filter(view=>view.view_type===typeFilter);
    let typeViewsIds = typeViews.map(view=>view.id);
    let uiId = store.ui.views[viewType][view];
    let ctxId = store.context.v;
    if (viewResourceName === 'form-views' && ctxId && typeViewsIds.indexOf(ctxId) > -1) uiId = store.context.v;
    if (uiId && typeViewsIds.indexOf(uiId) > -1) return uiId;
    return typeViews.length ? typeViews[0].id : null;
};

export const selectTypeId = (store, resource, id) => {
    if (!resource) return false;
    let view = resource.split('.')[1];
    let type = selectResource(store, 'db.types.byName', view);
    let data = selectResource(store, resource, id);
    if (data.subtype) return data.subtype;
    if (!type.id) return false;
    return type.id;
};

export const selectSubtypes = (store, resource) => {
    let typeId = selectTypeId(store, resource);
    let types = store.data['db.types.byName'];
    let subtypes = [];
    if (!types) return subtypes;
    Object.keys(types).forEach(key=>{
        if (types[key].subtype_of === typeId) {
            subtypes.push(types[key].id);
        }
    });
    return subtypes;
};

export const selectResourceActions = (store, resource, id) => {
    let typeId = selectTypeId(store, resource, id);
    let type = selectResource(store, 'db.types', typeId);
    if (!type) return nullObj;
    let actions = selectList(store, 'db.actions', type.actions);
    actions = actions.map(action => {
        let type = action.action_type;
        return {
            action: type==='job' ? 'DB.JOB' : 'DB.VIEW',
            args: {action: action.slug},
            label: action.name,
            type: 'action',
            icon: action.icon,
            slug: action.slug,
            category: action.action_category
        }
    });
    return index(actions, 'slug');
};

export const selectListActions = (store, resource) => {
    let schemaActions = store.resources[resource]
        ? store.resources[resource].listActions
        : store.resources.default.listActions;
    let resourceActions = selectResourceActions(store, resource);
    let listActions = {};
    Object.keys(resourceActions).forEach(key=>{
        if (resourceActions[key].category === 'create') {
            listActions[key] = resourceActions[key];
        }
    });
    return {...schemaActions, ...listActions};
};

export const selectFieldSchema = fieldSchemaSelector;
export const selectViewId = viewIdSelector;