import { isBrowser } from 'util/ssr';
import { thumbnailTypes } from 'config/files';

const thumbnailerPresets = isBrowser()
    ? window.THUMBNAILER_PRESETS
    : {sizes: {}};

const apiEndpoint = isBrowser()
    ? window.ZUU_CONSOLE_API_ENDPOINT
    : 'http://ow-helena.d';

const upgradeHttps = url => {
    if (!isBrowser()) return url;
    if (window && window.location.href.indexOf("https:") === 0) {
        return url.replace("http:", "https:");
    }
    return url;
};

export const thumbnail = (file, size) => {
    let preset = thumbnailerPresets
        ? thumbnailerPresets.sizes[size]
        : null;
    if (Array.isArray(size)) {
        preset = {w: size[0], h: size[1]};
    }
    if (!preset) preset = { w: 640, h: 480 };
    if (!file) return null;
    let fnParts = file.split('.');
    let extension = fnParts[fnParts.length-1];
    if (thumbnailTypes.indexOf(extension) === -1) return null;
    if (/^https?:/.test(file)) return file;
    const host = upgradeHttps(apiEndpoint).replace(
        "/api",
        ""
    );
    let url = host + "/thumb?file=" + encodeURIComponent(file);
    if (preset.w) url += "&w=" + preset.w;
    if (preset.h) url += "&h=" + preset.h;
    url += "#" + size;
    return url;
};