import React from 'react';
import PropTypes from 'prop-types';

import {connect} from 'react-redux';

import Paper from '@material-ui/core/Paper';

import { selectResource } from 'state/selectors/resources';
import { resourceFetch, draftsChange, draftsSubmit } from 'state/actions';

const mapStateToProps = (store, props) => {
    return {
        data: selectResource(store, props.resource, props.id),
        isPending: store.ui.pending[props.id]
    }
};

const mapDispatchToProps = {
    resourceFetch,
    onChange: draftsChange,
    onSubmit: draftsSubmit
};

class Resource extends React.PureComponent {

    componentDidMount() {
        if (this.props.shouldFetch) {
            this.props.resourceFetch(
                this.props.resource,
                this.props.id,
                this.props.context
            )
        }
    }

    handleClick = (...args) => {
        this.props.onClick(this.props.id, ...args);
    };

    handleAction = (action) => {
        const { resource, id, context } = this.props;
        this.props.onActionRequest(action, resource, id, context);
    };

    handleChange = (data) => {
        this.props.onChange(
            [this.props.resource, this.props.id],
            data
        );
    };

    handleSubmit = () => {
        this.props.onSubmit(
            this.props.resource,
            this.props.id
        );
    };

    render() {
        const {
            data,
            render,
            onActionRequest,
            editable,
            ...other
        } = this.props;

        if (!data) return null;

        const childProps = {
            ...other,
            data,
            onClick: this.handleClick,
            onActionRequest: onActionRequest ? this.handleAction : null,
            onChange: editable ? this.handleChange : null,
            onSubmit: editable ? this.handleSubmit : null
        };

        if (render) {
            return render(childProps);
        }

        return (<Paper {...childProps}>
            {data.id} {data.name} {data.title}
        </Paper>);
    }

}

const ConnectedResource = connect(
    mapStateToProps,
    mapDispatchToProps
)(Resource);

ConnectedResource.propTypes = {
  resource: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
};

export default ConnectedResource;