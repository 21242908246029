import React from 'react';
import PropTypes from 'prop-types';

export default class EmptyState extends React.PureComponent {

    render() {
        return (<div className="empty-state">
            <div className="empty-state__icon">
                <i className="fh fh-monitor" />
            </div>
            { this.props.title ? <div className="empty-state__title">
                {this.props.title}
            </div> : null }
            { this.props.children ? <div className="empty-state__description">
                {this.props.children}
            </div> : null }
        </div>);
    }

}

EmptyState.propTypes = {};