import React, {Component} from 'react';
import Card from '@material-ui/core/Card';

export default class Default extends Component {
    render() {
        const schema = this.props.schema || {};
        const properties = schema.properties || {};
        const type = properties.formFieldType || schema.type;
        return (<Card style={{ padding: '1rem' }}>
            {this.props.label}
        </Card>);
    }

}