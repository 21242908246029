import React from 'react';
import PropTypes from 'prop-types';

import types from './custom-views';

export default class CustomView extends React.PureComponent {

    render() {
        const data = this.props.data || {};
        const properties = data.properties || {};
        const id = properties.custom_view_id;
        const ViewComponent = types[id] || null;
        if (!ViewComponent) return null;
        return <ViewComponent {...this.props} />;
    }

}

CustomView.propTypes = {};