import React  from "react";
import { connect } from "react-redux";

import { bootstrap, refreshLessStyles, tearDown } from "state/actions/ide";
import { selectResource } from 'state/selectors/resources';
import { changed } from "util/changed";
import { selectDevice } from 'state/selectors/ide';

import Preloader from "components/Preloader";

const mapStateToProps = (store, props) => {

  let project = store.context.project;
  let websites = store.data['console.services.website'];
  let website = websites
      ? websites[project]
      : null;
  let theme = website
      ? selectResource(store, 'console.themes', website.theme)
      : null;
  let preset = theme
      ? selectResource(store, 'console.presets', theme.preset)
      : null;
  const s = store.data['theme.styles'];
  const less = store.data['cms.theme.files'];
  
  let isPending = !store.ui.styles
      || !s
      || !less
      || !preset
      || store.ui.pending['ide.page'];

  const shouldRefresh = (
      store.context.t !== 'typography.styles'
      && store.context.q1 !== 'fonts'
  );
  
  return {
    project: project,
    s: s,
    less: less,
    preset: preset,
    isPending: isPending,
    refresh: shouldRefresh,
    device: selectDevice(store)
  };
};

const mapDispatchToProps = {
  onInit: bootstrap,
  renderLess: refreshLessStyles,
  onUnmount: tearDown
};

class ThemeProvider extends React.PureComponent {
  componentDidMount() {
    this.props.onInit();
  }

  componentDidUpdate(prevProps) {
    if (changed(["project"], prevProps, this.props)) {
      console.log('ThemeProvider.onInit (update)');
      this.props.onInit();
      return;
    }

    if (changed(["less", "preset", "device", "refresh"], prevProps, this.props)) {
      if (!prevProps.less) {
        this.updateStyles();
      } else {
        if (!this.props.refresh) return;
        let delay = changed(["less"], prevProps, this.props) ? 1000 : 100;
        if (window.__lessUpdateTimeout)
          clearTimeout(window.__lessUpdateTimeout);
        console.log('Process less styles delay ' + delay);
        window.__lessUpdateTimeout = setTimeout(() => {
          this.updateStyles();
        }, delay);
      }
    }
  }

  componentWillUnmount() {
    this.props.onUnmount();
    let styles = document.getElementById("less:static-less-style");
    if (!styles) return;
    styles.remove();
  }

  updateStyles() {
    const { less, preset, device } = this.props;
    if (!less || !preset) return;
    this.props.renderLess(less, preset, device);
  }

  render() {
    const { isPending } = this.props;
    return <React.Fragment>
        <Preloader visible={isPending} className="theme-preloader" />
        {this.props.children}
    </React.Fragment>;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThemeProvider)
