import React from 'react';
import {connect} from 'react-redux';
import {resourceFetch} from 'state/actions';
import {currentTheme} from 'state/selectors/app';
import {selectResource} from 'state/selectors/resources';

import GitStatus from 'components/github/GitStatus';
import FormState from 'containers/FormState';
import TextField from 'components/fields/text/TextField';
import Button from 'components/Button';
import Preloader from 'components/Preloader';
import NestedList from 'list/containers/NestedList';
import IconButton from 'components/TooltipIconButton';

const mapStateToProps = (store, props) => {
    const theme = props.themeId
        ? selectResource(store, 'console.themes', props.themeId)
        : currentTheme(store);
    const data = selectResource(store, 'theme.vcs', theme.id);
    return {
        status: data.repository ? data.status : 99,
        repository: data.repository,
        repositoryUrl: data.url,
        message: data.message,
        isPending: store.ui.pending[theme.id],
        theme: theme,
        logPath: ['theme.vcs', theme.id, 'log'].join('/')
    }
};

const mapDispatchToProps = {
    onSubmit: (endpoint, data, context) => ({
        type: 'RESOURCE.REQUEST',
        endpoint,
        data,
        context
    }),
    resourceFetch
};


const formStyle = {
    display: 'flex',
    padding: '1rem'
};

class ThemeVcs extends React.PureComponent {

    fetch = () => {
        this.props.resourceFetch(
            'theme.vcs',
            this.props.theme.id
        );
    };

    componentDidMount() {
        this.fetch();
    }

    handleRepositoryNameClick = () => {
        this.handleOpen();
    };

    handleOpen = (hash) => {
        let repositoryUrl = this.props.repositoryUrl;
        let targetUrl = hash
            ? repositoryUrl.replace('tree/master', 'commit/'+hash)
            : repositoryUrl;
        window.open(targetUrl);
    };

    handleLogEntryClick = (id) => {
        this.handleOpen(id);
    };

    handleSubmitCommit = (data) => {
        this.props.onSubmit('theme.vcs.commit', data, {
            resource: 'theme.vcs',
            id: this.props.theme.id
        });
    };

    handleRequest = (endpoint, data = {}) => {
        this.props.onSubmit(endpoint, data, {
            resource: 'theme.vcs',
            id: this.props.theme.id
        });
    };

    render() {
        const {
            status,
            repository,
            message,
            logPath,
            isPending
        } = this.props;

        return (<div style={{ flex: 1 }}>
            <Preloader visible={isPending} />
            { status !== 99 ? <div
                style={{
                    cursor: 'pointer',
                    backgroundColor: 'white',
                    padding: '1rem',
                    fontSize: '1.2rem'
                }}
                onClick={this.handleRepositoryNameClick}
            >
                {repository}
            </div> : null }
            <GitStatus status={status}>
                <IconButton
                    onClick={this.fetch}
                    icon="refresh"
                    tooltip="Refresh status"
                />
            </GitStatus>
            <div style={{ backgroundColor: 'white' }}>
                { status === 0 ? <div>
                    <IconButton
                        onClick={()=>this.handleRequest('theme.vcs.push')}
                        icon="cloud_upload"
                        tooltip="Push"
                        disabled={status !== 0}
                    />
                    <IconButton
                        onClick={()=>this.handleRequest('theme.vcs.pull')}
                        icon="cloud_download"
                        tooltip="Pull"
                        disabled={status !== 0}
                    />
                </div> : null }
                { status === 99 ? <div style={formStyle}>
                    <Button
                        onClick={()=>this.handleRequest('theme.vcs.init')}
                        label="Connect to github"
                        icon="compare_arrows"
                    />
                </div> : null }
                { status === 1 ? <FormState value={{}} onChange={this.handleSubmitCommit}>
                    {(props) => {
                        return <div style={formStyle}>
                            <TextField
                                id="commit_message"
                                value={props.value.commit_message}
                                onChange={props.onChange}
                                label="Commit message"
                            />
                            <Button
                                onClick={props.onSubmit}
                                label="Commit" />
                        </div>
                    }}
                </FormState> : null }
            </div>
            { status !== 99 ? <NestedList
                path={logPath}
                resource={'theme.vcs-log'}
                itemProps={{
                    onClick: this.handleLogEntryClick
                }}
            /> : null }
            <pre style={{ whiteSpace: 'pre-wrap', fontSize: '10px', padding: '1rem' }}
                 dangerouslySetInnerHTML={{__html: message }}
            />
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ThemeVcs);