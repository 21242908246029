import { createReducer } from "util/redux/create-reducer";
import { updateIn } from "lodash-redux-immutability";
import forms from 'config/forms';

const mergeSettingsToBase = (schema) => {
    if (schema.fields && schema.fields.settings) {
        schema.fields = {
            ...schema.fields,
            ...schema.fields.settings.fields
        };
        delete schema.fields.settings;
    }
    return schema;
};

const updateSchema = (state, id, schema) => {
    Object.keys(schema).forEach((type) => {
        schema[type] = mergeSettingsToBase(schema[type]);
        if (schema[type].types) {
            Object.keys(schema[type].types).forEach((subtype) => {
                schema[type].types[subtype] = mergeSettingsToBase(schema[type].types[subtype]);
            })
        }
    });
    return updateIn(state, [id], schema);
};

const actions = {
    'APP.SCHEMA.SUCCESS': (state, {id, schema}) => updateSchema(state, id, schema)
};

export default createReducer({forms}, actions);