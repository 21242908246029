import defaultFieldTypes from 'components/fields';

import Subfields from './Subfields';
import Select from './Select';
import Layout from './Layout';
import Ref from './Ref';
import Collection from './Collection';
import Address from './AddressField';
import Media from './Media';
import AuditSurvey from './AuditSurvey';
import TagSelect from './TagSelect';
import Schedule from './Schedule';

const fieldTypes = {
    ...defaultFieldTypes,
    select: Select,
    tab: Subfields,
    address: Address,
    components: defaultFieldTypes.default,
    collection: Collection,
    ref: Ref,
    layout: Layout,
    media: Media,
    schedule: Schedule,
    'audit-survey': AuditSurvey,
    'tag-select': TagSelect
};

export default fieldTypes;