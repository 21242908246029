import { createReducer } from 'util/redux/create-reducer';

const setListFilter = (state, list, filter) => {
    let update = {};
    update[list] = filter || {};
    return {...state, ...update};
};

const updateListFilter = (state, list, data) => {
    let update = {};
    let prev = state[list] || {};
    let pagination = data.meta
        ? data.meta.pagination
        : {};
    if (!pagination || !prev) return state;
    if (Object.keys(pagination).length === 0 && Object.keys(prev).length === 0) {
        return state;
    }
    update[list] = {...prev, pagination};
    return {...state, ...update};
};

const actions = {
    'LIST.FILTER.SET': (state, {list, filter}) => setListFilter(state, list, filter),
    'LIST.FETCH.SUCCESS': (state, {list, filterId, data}) => updateListFilter(state, filterId || list, data)
};

export default createReducer({}, actions);