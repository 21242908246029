import React from 'react';
import PropTypes from 'prop-types';

import Access from 'components/Access';
import actionsDefinitions from './actions';

export default class ResourceActionsMenu extends React.PureComponent {

    render() {
        const { resource, actions, onActionRequest } = this.props;

        return Object.keys(actions).map((key) => {
            if (!actions[key].label) return null;
            let actionIcon = actions[key].icon || 'action.'+key;
            let ActionComponent = actionsDefinitions[key] || actionsDefinitions.default;
            let policy = ['resource',resource,key].join(':');
            return (
                <Access policy={policy}>
                    <ActionComponent
                        {...this.props}
                        key={key}
                        id={key}
                        onClick={onActionRequest}
                        icon={actionIcon}
                        label={actions[key].label}
                    />
                </Access>);
        });
    }

}

ResourceActionsMenu.propTypes = {
    onActionRequest: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired
};