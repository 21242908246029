import React from 'react';
import {connect} from 'react-redux';
import {query} from 'state/actions/route';
import Fieldset from 'v2/Fieldset';
import Portal from '@material-ui/core/Portal';
import Resource from 'v2/Resource';
import IconButton from 'components/TooltipIconButton';

const nullArr = [];

const mapStateToProps = (store, props) => {
    let fields = props.schema.fields;
    return {
        fields,
        currentTab: store.context.t
    }
};

const mapDispatchToProps = {
    query
};

class FormTabs extends React.PureComponent {
    constructor(props) {
        super(props);
        this.bodyRef = React.createRef();
    }
    handleChangeTab = (id) => {
        this.props.query({t:id});
    };
    render() {
        let hasTabs = this.props.fields.length > 1;
        return (<div className="form__tabs" style={{
            display: hasTabs ? 'flex' : 'none'
        }}>
            <div className="form__tabs-nav">
                {this.props.fields.map((fieldId,i) => <Resource
                    resource="db.fields"
                    id={fieldId}
                    fetchPolicy="never"
                >
                    {(props)=>{
                        let isCurrent = (props.data.slug === this.props.currentTab)
                            || (!this.props.currentTab && i === 0);
                        return <React.Fragment>
                        { props.data.type === 'tab' ?
                            <React.Fragment>
                                {isCurrent ? <Portal container={this.bodyRef.current}>
                                    <Fieldset
                                        {...this.props}
                                        fields={props.data.fields}
                                    />
                                </Portal> : null}
                                <IconButton
                                    active={isCurrent}
                                    tooltip={props.data.label}
                                    icon={props.data.icon || ['tab',props.data.slug].join('.')}
                                    onClick={()=>this.handleChangeTab(props.data.slug)}/>
                            </React.Fragment> : null }
                    </React.Fragment>}}
                </Resource>)}
            </div>
            <div ref={this.bodyRef} className="form__body" />
        </div>);
    }
}

FormTabs.defaultProps = {
    fields: nullArr
};

const ConnectedFormTabs = connect(
    mapStateToProps,
    mapDispatchToProps
)(FormTabs);

const RenderFormTabs = (props)=><ConnectedFormTabs {...props} />;

export default RenderFormTabs;