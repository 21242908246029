import React from 'react';
import { connect } from 'react-redux';
import { isAuthenticated } from "auth/auth0";

import AuthRoutes from 'auth/views/AuthRoutes';

const mapStateToProps = (store, props) => {
    return {
        isAuthenticated: isAuthenticated()
    }
};

class AuthContext extends React.PureComponent {

    render() {
        return (<AuthRoutes {...this.props} />);
    }

}

export default connect(mapStateToProps)(AuthContext);