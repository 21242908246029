import React from 'react';
import {connect} from 'react-redux';

import listViewsConfig from "v2/list-views";
import List from "v2/List";
import Resource from './Resource';
import RenderList from 'list/List';
import ViewSelect from 'v2/ViewSelect';
import {selectViewId} from "state/selectors/schema";

const mapStateToProps = (store, props) => {
    return {
        listViewId: selectViewId(store, 'list', props.resource, props.viewType),
        isPending: store.ui.pending[props.list]
    }
};

const mapDispatchToProps = {};

class ResourceList extends React.PureComponent {

    handleItemClick = (id) => {
        if (this.props.onClick) this.props.onClick(id);
    };

    render() {
        const routeProps = this.props;
        const { viewType } = this.props;
        return (<Resource resource="db.list-views" id={this.props.listViewId} fetchPolicy="never">
            {({data})=> {
                let listView = data;
                let viewConfig = listViewsConfig[listView.view_type] || listViewsConfig.list;
                let variant = viewConfig.variant || 'list';
                let ItemRenderer = viewConfig.itemType;
                let ListRenderer = viewConfig.listType;
                let filterLayout = this.props.filterLayout || listView.filter_layout || viewConfig.filterLayout;
                let source = routeProps.source || "api:"+routeProps.resource;
                if (!listView.filters) return null;
                return <List
                    fetchStrategy="always"
                    source={source}
                    resource={routeProps.list}
                    listId={routeProps.list}
                    viewId={listView.id}
                    filterId={this.props.filterId || listView.filters}
                    onClick={this.handleItemClick}
                >
                    {({data, resource})=><RenderList
                        resource={resource}
                        data={data}
                        onChangeOrder={routeProps.onChangeOrder}
                        variant={variant}
                        listId={routeProps.list}
                        filterId={listView.filters}
                        config={viewConfig}
                        renderList={(listProps)=><div className="list-with-header">
                            <ListRenderer
                                primary
                                {...listProps}
                                listId={routeProps.list}
                                filterLayout={filterLayout}
                                isPending={this.props.isPending}
                                viewConfig={listView}
                                heading={!viewType ? <ViewSelect
                                    type="list"
                                    resource={resource}
                                    value={this.props.listViewId}
                                /> : null}
                                listClassName={viewConfig.className}
                            />
                        </div>}
                        renderItem={(itemProps)=><Resource
                            resource={itemProps.resource}
                            id={itemProps.id}
                            fetchPolicy="never"
                            origin={routeProps.list}
                            onClick={this.handleItemClick}
                        >
                            {(props)=><ItemRenderer
                                {...itemProps}
                                {...props}
                                view={listView.id}
                                style={itemProps.style}
                            />}
                        </Resource>}
                    />}
                </List>}}
        </Resource>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResourceList);