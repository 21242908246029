import React from 'react';
import {connect} from 'react-redux';
import Font from './Font';

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};
const weights = ['regular', 'light', 'bold'];
const weightValues = [
    100,
    200,
    300,
    400,
    500,
    600,
    700,
    800,
    900
];

class FontWeights extends React.Component {

    render() {
        let values = this.props.value || {};
        return (<div className="console-context" style={{ padding: '1rem', minWidth: '200px' }}>
            <div style={{ paddingBottom: '1rem' }}><strong>Font weights</strong></div>
            {weights.map(weight=>{
                let key = 'weight_'+weight;
                let value = values[key];
                return (<div
                    key={weight}
                    style={{ marginBottom: '0.6rem' }}
                >
                    <div style={{ paddingBottom: '4px', marginBottom: '4px', borderBottom: 'solid 1px rgba(0,0,0,0.1)'}}>
                        <Font
                            weight={value}
                            family={values.family}
                            source={values.source}
                        >
                            {weight} {value}
                        </Font>
                    </div>
                    <div style={{ display: 'flex' }}>
                        {weightValues.map(w=>{
                            return <div
                                key={w}
                                style={{ display: 'flex', cursor: 'pointer', marginRight: '5px' }}
                                onClick={()=>this.props.onChange(key, w)}
                            ><Font
                                weight={w}
                                family={values.family}
                                source={values.source}
                            >{w}</Font></div>
                        })}
                    </div>
                </div>)
            })}
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FontWeights);