import Components from './Components';
import Editor from './Editor';
import Styles from './Styles';
import Preset from './Preset';
import Github from './Github';

export const tabs = [
    {
        id: 'styles',
        scope: 'websites.power_user'
    }
];

export const types = {
    components: Components,
    editor: Editor,
    styles: Styles,
    preset: Preset,
    github: Github
};