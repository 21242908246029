import React, { Component } from "react";
import { DragSource, DropTarget } from "react-dnd";
import { getEmptyImage } from 'react-dnd-html5-backend';

import {
    cardSource,
    cardTarget,
    collectSource,
    collectTarget
} from 'util/dnd';

const style = {
    cursor: "move",
    //height: "100%"
};

export const draggable = () => {
    return (DecoratedComponent) => {
        const Decorator = class Decorator extends React.PureComponent {
            componentDidMount() {
                // Use empty image as a drag preview so browsers don't draw it
                // and we can draw whatever we want on the custom drag layer instead.
                this.props.connectDragPreview(getEmptyImage(), {
                    // IE fallback: specify that we'd rather screenshot the node
                    // when it already knows it's being dragged so we can hide it with CSS.
                    captureDraggingState: true,
                })
            }
            connectDrag = (component) => {
                const {
                    connectDragSource,
                    connectDropTarget
                } = this.props;
                return connectDragSource(connectDropTarget(component));
            };
            render() {
                return (<DecoratedComponent
                    {...this.props}
                    connectDrag={this.connectDrag}
                />);
            }
        };
        return DropTarget("draggable", cardTarget, collectTarget)(
            DragSource("draggable", cardSource, collectSource)(
                Decorator
            )
        );
    }
};

const dragStyles = {
    outline: {
        outer: {
            border: "none", //"dashed 2px rgba(0,0,0,0.1)",
            margin: '0 0',
            backgroundColor: 'rgba(0,0,0,0.04)',
            transform: 'scale(0.8) translateX(1rem)'
        },
        content: {
            border: 'none', //'dashed 2px rgba(0,0,0,0.1)',
            opacity: 0
        }
    },
    fade: {
        outer: {},
        content: {opacity: 0.5}
    },
    none: {
        outer: {},
        content: {}
    }
};

class DraggableItem extends Component {

    render() {
        const {
            overItem,
            isOver,
            connectDrag,
            renderDragging
        } = this.props;

        let dragStyle = this.props.dragStyle || 'none';
        
        const canDrag = overItem
            ? this.props.resource === overItem.resource
              && this.props.id !== overItem.id
            : false;
        
        const isDraggingProp = this.props.isDragging;
        const isDragging = isDraggingProp || renderDragging;// || (canDrag && isOver);

        const outerStyle = isDragging
            ? dragStyles[dragStyle].outer
            : {};

        const contentStyle = isDragging
            ? dragStyles[dragStyle].content
            : {};

        if (this.props.shouldRender && !this.props.shouldRender(this.props.id)) return null;

        return connectDrag(<div
            className={this.props.className}
            style={{ ...style, ...this.props.style }}
        >
            <div
                style={{transition: 'all .1s', ...outerStyle}}
            >
                <div style={{ transition: "opacity .1s", ...contentStyle }}>
                    {this.props.children}
                </div>
            </div>
        </div>);
    }
}

export default draggable()(DraggableItem);