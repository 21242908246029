import React from 'react';
import {connect} from 'react-redux';
import * as actions from 'state/actions/media';
import {query} from 'state/actions/route';
import {handleUpload} from 'state/actions/files';
import {draftsPathAttach, draftsPathDeleteById, listFilter, listFilterSet} from 'state/actions';
import {selectFilter} from 'state/selectors/filter';
import {selectAllResources,selectPath,selectIds} from 'state/selectors/resources';
import {changed} from 'util/changed';

import MediaLibraryComponent from 'components/media/Library';
import Dialog from 'components/Dialog';

const emptyArr = [];

const collectNode = (tree, nodes, id) => {
    nodes[id].forEach((node) => {
        if (nodes[node.id]) node.children = collectNode([], nodes, node.id);
        tree.push(node);
    });
    return tree;
};

const selectDirectoryTree = (store) => {
    let dirs = selectAllResources(store, 'cms.media.dirs');
    if (!dirs || !dirs.map) return [];
    let tree = [];
    let nodes = {root: []};
    dirs.forEach((dir) => {
        let path = dir.id.split('/');
        let level = path.length;
        let parent = path.slice(0, -1).join('/') || 'root';
        let dirname = dir.id.replace(parent+'/', '');
        if (!nodes[parent]) {
            nodes[parent] = [];
        }
        nodes[parent].push(dir);
    });
    tree = collectNode(tree, nodes, 'root');
    return tree;
};

const mapStateToProps = () => (store, props) => {
    let path = store.context.select;
    let selection = path ? selectPath(store, path.split('/')) : null;
    let selectedItems = path ? selectIds(store, path.split('/')) : emptyArr;
    let filterId = 'cms.media.quick';
    let filters = selectFilter(store, filterId);
    let listId = 'cms.media';
    return {
        open: store.context.q === 'media',
        dirs: selectDirectoryTree(store),
        libs: selectAllResources(store, 'cms.media.libs'),
        routeDir: store.context.dir || '',
        dir: filters.path,
        isPending: store.ui.pending['cms.media'],
        filterId,
        filters,
        listId,
        path,
        selection,
        selectedItems
    }
};

const mapDispatchToProps = {
    init: actions.initLibrary,
    onCreateDirectory: actions.mediaCreateDirectory,
    onDeleteFile: actions.mediaDeleteFile,
    onImport: actions.mediaImport,
    onSyncDirectory: actions.syncDirectory,
    onUpload: handleUpload,
    onFetch: listFilter,
    onSetFilter: listFilterSet,
    attach: draftsPathAttach,
    detach: draftsPathDeleteById,
    query
};

class MediaLibrary extends React.PureComponent {

    componentDidMount() {
        this.props.onSetFilter(
            this.props.filterId,
            {...this.props.filters, path: this.props.routeDir}
        );
        this.fetch();
    }

    componentDidUpdate(prevProps) {
        if (changed(['routeDir'], prevProps, this.props)) {
            //this.fetch();
            this.props.onSetFilter(
                this.props.filterId,
                {...this.props.filters, path: this.props.routeDir}
            );
            /*this.props.onSetFilter(
                this.props.filterId,
                {...this.props.filters, path: this.props.routeDir}
            );*/
        }
    }
    
    fetch = () => {
        this.props.init(this.props.listId);
    };
    
    handleChangeDir = (dir) => {
        this.props.query({dir: dir});
    };

    handleCreateDirectory = (name) => {
        this.props.onCreateDirectory(
            this.props.dir,
            name
        );
    };

    handleUpload = (files) => {
        files.forEach(file => {
            this.props.onUpload(file);
        });
    };

    handleSelect = (media) => {
        if (!this.props.path) return;

        if (this.props.selectedItems.indexOf(media.id) > -1) {
            this.props.detach(
                this.props.path.split('/'),
                media.id
            );
            return;
        }

        this.props.attach(
            this.props.path.split('/'),
            media
        );
    };

    render() {
        return (<MediaLibraryComponent
            {...this.props}
            onCreateDirectory={this.handleCreateDirectory}
            onChangeDirectory={this.handleChangeDir}
            onSelect={this.handleSelect}
            onUpload={this.handleUpload}
        />);
    }

}

export class MediaLibraryDialog extends React.PureComponent {
    render() {
        return (<Dialog
            {...this.props}
            fullWidth={true}
            maxWidth="lg"
        >
            <ConnectedMediaLibrary
                onClose={this.props.onClose}
            />
        </Dialog>);
    }
}

const ConnectedMediaLibrary = connect(
    mapStateToProps,
    mapDispatchToProps
)(MediaLibrary);

export default ConnectedMediaLibrary;