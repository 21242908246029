import React from 'react';
import PropTypes from 'prop-types';

import {connect} from 'react-redux';
import {changed} from 'util/changed';

import { listFetch } from 'state/actions';
import { selectFilteredList } from 'state/selectors/resources';
import { makeSortableList, connectDropArea } from 'containers/Sortable';

import ResourceListItem from 'components/list/ResourceListItem';
import ListWrapper from 'components/list/ListWrapper';

import { Grid } from 'react-virtualized';

const mapStateToProps = (store, props) => {

    let actionsDefKey = props.actions && props.actions !== true
        ? props.actions
        : props.resource;

    let listId = props.listId || props.resource;

    let data = props.filterId
        ? selectFilteredList(store, props.resource, props.filterId, listId)
        : store.lists[listId];

    let context = props.context
        ? {...store.context, ...props.context}
        : store.context;

    return {
        context: context,
        definition: store.resources[actionsDefKey] || {},
        resourceDefinition: store.resources[props.resource] || {},
        data: data || [],
        listId: props.listId || props.resource
    }
};

const mapDispatchToProps = {
    fetch: listFetch
};

class ResourceList extends React.PureComponent {

    fetch = () => {
        this.props.fetch(
            this.props.resource,
            this.props.listId,
            this.props.yieldData,
            this.props.context
        );
    };

    componentDidMount() {
        if (this.props.data.length && this.props.fetchOnce) return;
        console.log('ResourceList.componentDidMount -> fetch');
        this.fetch();
    }

    componentDidUpdate(prevProps) {
        if (this.props.fetchOnce) return;
        let watch = ['resource', 'listId'];
        //if (this.props.watch) watch = watch.concat(this.props.watch);
        if (changed(watch,prevProps,this.props)) {
            console.log('ResourceList.componentDidUpdate -> fetch');
            this.fetch();
        }
    }

    renderItem = (index, addItemProps = {}) => {
        const item = this.props.data[index];

        const {
            definition,
            resourceDefinition,
            resource,
            itemComponent,
            renderItem,
            itemProps,
            selectedItem,
            actions,
            secondaryAction
        } = this.props;

        let props = {
            ...itemProps,
            ...addItemProps,
            resource,
            key:item,
            id:item,
            index:index,
            isSelected: itemProps.isSelected || (item === selectedItem),
            secondaryAction: secondaryAction || definition.secondaryAction,
            actions: actions && definition.actions
                ? actions
                : null
        };

        if (!resourceDefinition.sortable) {
            //delete props.onChangeOrder;
        }

        if (renderItem) return renderItem(props);
        //return <div>item</div>;

        return itemComponent ?
            React.createElement(itemComponent, props)
            : renderItem(props)
    };

    rowRenderer = (cell) => {
        let index = (cell.rowIndex*2) + cell.columnIndex;
        return this.renderItem(index, {
            style: cell.style,
            isScrolling: cell.isScrolling
        });
    };

    render() {
        const {
            data,
            wrapper
        } = this.props;

        let items = [];

        if (this.props.virtualized) {
            items = (<Grid
                width={780}
                height={760}
                columnCount={2}
                rowCount={Math.ceil(data.length/2)}
                rowHeight={280}
                columnWidth={380}
                overscanRowCount={0}
                cellRenderer={this.rowRenderer}
            />);
        } else {
            items = data.map((item, i) => this.renderItem(i));
        }
        
        if (wrapper) return wrapper({...this.props, fetch: this.fetch}, items);
        return items;
    }

}

ResourceList.defaultProps = {
    data: PropTypes.array
};

ResourceList.propTypes = {
    data: PropTypes.array.isRequired,
    fetchOnce: PropTypes.bool
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(makeSortableList(connectDropArea(ResourceList)));

export const Wrapper = ListWrapper;
export const Item = ResourceListItem;