import React, { Component } from "react";
import ReactQuill, { Quill } from "react-quill";

var Delta = Quill.import("delta");
let Break = Quill.import("blots/break");
let Embed = Quill.import("blots/embed");

let QUILL_NO_MATCH_BR = false;

function lineBreakMatcher() {
  var newDelta = new Delta();
  newDelta.insert({ break: "" });
  return newDelta;
}

class SmartBreak extends Break {
  length() {
    return 1;
  }
  value() {
    return "\n";
  }

  insertInto(parent, ref) {
    Embed.prototype.insertInto.call(this, parent, ref);
  }
}

SmartBreak.blotName = "break";
SmartBreak.tagName = "BR";

Quill.register(SmartBreak);

function lineBreakHandler(range) {
  let currentLeaf = this.quill.getLeaf(range.index)[0];
  let nextLeaf = this.quill.getLeaf(range.index + 1)[0];

  this.quill.insertEmbed(range.index, "break", true, "user");

  // Insert a second break if:
  // At the end of the editor, OR next leaf has a different parent (<p>)
  if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
    this.quill.insertEmbed(range.index, "break", true, "user");
  }

  // Now that we've inserted a line break, move the cursor forward
  this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
}

const filterBreaks = (quill) => {
  //return null;
  if (!quill) return null;

  var length = quill.getLength();
  var text = quill.getText(length - 2, 2);

  // Remove extraneous new lines
  if (text === "\n\n") {
    quill.deleteText(quill.getLength() - 2, 2);
  }
};

export default class SmartBreakQuill extends Component {
  constructor(props) {
    super(props);
    this.quillRef = null; // Quill instance
    this.reactQuillRef = null; // ReactQuill component
    this.state = { hasError: false };
  }

  componentDidMount() {
    this.attachQuillRefs();
    filterBreaks(this.quillRef);
  }

  componentDidUpdate(prevProps) {
    this.attachQuillRefs();
    if (prevProps.value !== this.props.value) filterBreaks(this.quillRef);
  }

  componentWillUnmount() {
    this.quillRef = null;
    this.reactQuillRef = null;
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {}

  attachQuillRefs = () => {
    if (!this.reactQuillRef) return;
    if (typeof this.reactQuillRef.getEditor !== "function") return;
    this.quillRef = this.reactQuillRef.getEditor();
  };

  handleChange = (value, delta, source) => {
    if (source !== "user") return;
    this.props.onChange(value);
  };

  render() {
    if (this.state.hasError) {
      QUILL_NO_MATCH_BR = true;
      //return <div dangerouslySetInnerHTML={{ __html: this.props.value }} />;
    }

    const { modules } = this.props || {};

    modules.keyboard = {
      bindings: {
        linebreak: {
          key: 13,
          shiftKey: true,
          handler: lineBreakHandler,
        },
      },
    };
    modules.clipboard = {
      matchVisual: false,
      matchers: QUILL_NO_MATCH_BR ? [] : [["BR", lineBreakMatcher]],
    };

    const props = {
      ...this.props,
      modules: modules,
      ref: (el) => {
        this.reactQuillRef = el;
      },
    };

    return <ReactQuill {...props} onChange={this.handleChange} />;
  }
}
