import React from 'react';
import {connect} from 'react-redux';
import TextField from './TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import {selectPath} from "../../../state/selectors/resources";
import { changed } from 'util/changed';
import slugify from 'slugify';
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon';

const mapStateToProps = (store, props) => {
    return {
        domain: selectPath(store, ['console.services.website', store.context.project, 'canonical_domain', 'hostname']),
        title: selectPath(store, [props.parentPath, 'title'].join('/').split('/'))
    }
};

const mapDispatchToProps = {};

class Slug extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            autoUpdate: false
        }
    }

    componentDidMount() {
        let sync = this.toggleAutoUpdate();
        if (sync) this.generateSlug();
    }

    componentDidUpdate(prevProps) {
        if (changed(['path'], prevProps, this.props)) {

        }
        if (this.state.autoUpdate && changed(['title'], prevProps, this.props)) {
            this.generateSlug();
        }
    }

    toggleAutoUpdate = () => {
        let state = !this.props.value;
        this.setState({autoUpdate: state});
        return state;
    };

    handleToggleAutoUpdate = () => {
        if (!this.state.autoUpdate) {
            this.generateSlug();
        }
        this.setState({autoUpdate: !this.state.autoUpdate});
    };

    generateSlug = () => {
        let slug = slugify(this.props.title || '', {
            replacement: '-',    // replace spaces with replacement
            remove: /[*+~.()'"!:@<>?%#$^]/g,        // regex to remove characters
            lower: true          // result in lower case
        });
        this.props.onChange(this.props.id, slug);
    };

    handleChange = (id, value) => {
        this.setState({autoUpdate: false});
        this.props.onChange(id, value);
    };

    render() {
        return (<TextField
            {...this.props}
            onChange={this.handleChange}
            startAdornment={<InputAdornment
                style={{ pointerEvents: 'none', whiteSpace: 'nowrap', opacity: 0.5 }}
            >
                {this.props.domain}/
            </InputAdornment>}
            endAdornment={<InputAdornment>
                <IconButton onClick={this.handleToggleAutoUpdate}>
                    <Icon color={this.state.autoUpdate ? 'primary' : 'inherit'}>
                        autorenew
                    </Icon>
                </IconButton>
            </InputAdornment>}
        />);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Slug);