import React from 'react';
import {connect} from 'react-redux';
import {draftsChange} from 'state/actions';
import {selectResourcePath} from "state/selectors/resources";
import {selectPathMapping, selectTemplateMapping} from "state/selectors/ide";
import {setUiFlag} from "state/actions/ui";
import {serializeMapping} from "util/templatable";
import FieldMapping from "components/fields/data-mapping/FieldMapping";

export function withMapping() {
    return function(Component) {
        const mapState = (store, props) => {

            let id = props.id;
            let resource = selectResourcePath(store, props.parentPath.split('/'));
            let supportsMapping = resource.type === 'default-content';
            let mapping = selectPathMapping(store, props.parentPath.split('/'));
            let templateMapping = supportsMapping && selectTemplateMapping(store, props.parentPath.split('/'));
            let mappingMode = store.ui.flags.formMappingMode
                && id !== 'template_id';

            return {
                mapping,
                fieldMapping: mapping[id],
                templateMapping,
                mappingMode
            }
        };

        const mapDispatch = {
            onChange: draftsChange,
            onSetUiFlag: setUiFlag
        };
        class MappedField extends React.PureComponent {
            handleMapping = (event) => {
                event.preventDefault();
                event.stopPropagation();
                let oldMapping = this.props.mapping;
                let newMapping = oldMapping ? {...oldMapping} : {};
                if (newMapping[this.props.id]) {
                    delete newMapping[this.props.id]
                } else {
                    newMapping[this.props.id] = this.props.id;
                }
                let newValue = serializeMapping(newMapping);
                this.props.onChange(
                    this.props.parentPath.split('/'),
                    {template_id: newValue}
                );
                if (!event.ctrlKey) {
                    this.props.onSetUiFlag('formMappingMode', false);
                }
            };
            render() {
                const { id, parentPath, fieldMapping, mappingMode, templateMapping} = this.props;

                let mappable = [
                    'tags',
                    'title',
                    'headline',
                    'items',
                    'media',
                    'gallery',
                    'link',
                    'link_label',
                    'link_action'
                ];

                if (templateMapping && mappable.indexOf(id) > -1 && !templateMapping[id]) return null;

                const field = (<Component
                    {...this.props}
                    onClick={mappingMode ? this.handleMapping : null}
                />);
                return fieldMapping
                    ? <FieldMapping
                        from={fieldMapping}
                        to={id}
                        onClick={this.handleMapping}
                    >
                        {field}
                    </FieldMapping>
                    : field;

            }
        }
        return connect(mapState, mapDispatch)(MappedField);
    }
};