import React from 'react';
import {connect} from 'react-redux';

import { makeFontPreview } from "./FontPicker";
import { selectPath } from 'state/selectors/resources';

const mapStateToProps = (store, props) => {
    return {
        value: selectPath(store, props.path.split('/'))
    }
};

const mapDispatchToProps = {};

class PresetFontsPreview extends React.PureComponent {

    fonts() {
        return ["body_font", "heading_font", "decorative_font"];
    }
    
    render() {
        const sizes = ['1.6rem', '1rem', '1rem'];
        let value = this.props.value || {};
        return (<div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            {this.fonts().map((font,i) => {
                return makeFontPreview(value[font])(null, sizes[i], 'weight_regular');
            })}
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PresetFontsPreview);