import { createSelector } from "reselect";
import createCachedSelector from "re-reselect";
import { buildStyles } from "util/styles";
import { breakpointClasses } from "config/devices";

import Scope from "ide/php/Scope";

const mapDisplayToClass = ({display,breakpoints}) => {
    let classes = [];
    
    if (display) {
        classes = classes.concat(display);
    }

    const map = {
        alt: "t-alt",
        primary: "t-primary",
        //success: "t-success",
        invert: "t-invert",
        parallax: "Background Background--image relative t-on-image",
        "t-primary": "t-primary",
        "t-default": "t-default",
        "t-invert": "t-invert",
        "t-success": "t-success",
        "t-light": "t-light",
        "t-dark": "t-dark",
        "t-alt": "t-alt",
        "t-on-image": "t-on-image",
        "t-black": "t-black",
        "t-primary-alt": "t-primary-alt",
        "t-success-alt": "t-success-alt",
        "t-dark-alt": "t-dark-alt",
        "t-light-gray": "t-light-gray"
    };

    const options = classes.map(opt => {
        return map[opt] || null;
    });

    if (breakpoints) {
        options.push(breakpointClasses[breakpoints]);
    }

    return options.filter(opt => {
        return !!opt;
    });
};

const adapter = (scope, styles) => {
    return {
        fork: (ns, style, styles) => {
            return adapter(scope.__call(ns, [style]), styles);
        },
        clone: () => adapter(new Scope(scope.ns, scope.styles, scope.parentScope)),
        get: ns => {
            if (styles && styles[ns]) {
                return scope.extend(ns, styles[ns]);
            }
            return scope.__get(ns)
        },
        display: (ns, data) => {
            let withClasses = mapDisplayToClass(data);
            if (styles && styles[ns]) withClasses = styles[ns].concat(withClasses);
            let result = scope.extend(ns, withClasses);
            return result;
        },
        tag: (tags, append) => scope.tag(tags, append),
        bool: ns => {
            if (styles && styles[ns]) return styles[ns][0] === 'true';
            return !!scope.__get(ns);
        },
        extend: (ns, withClasses) => scope.extend(ns, withClasses),
        api: () => scope.styles,
        scope: () => scope,
        rwd: (ns, {breakpoints}) => {
            if (!breakpoints) return scope.__get(ns);
            let classes = scope.__get('ns');
            return classes += ' ' + breakpointClasses[breakpoints];
        }
    };
};

export const selectStyles = (store) => {
    let data = store.data['theme.styles'] || {};
    let draft = store.drafts['theme.styles'] || {};
    let all = Object.keys(data).concat(Object.keys(draft));
    let merge = {};
    all.forEach((key) => {
        let styleData = data[key] || {};
        let styleDraft = draft[key] || {};
        merge[key] = {...styleData, ...styleDraft};
    });
    return merge;
};

export const stylesHelper = createSelector([selectStyles], styles => {
    //console.log("TRIGGER STYLES BUILD");
    //console.log(styles);
    if (!styles) return null;
    const api = buildStyles(styles).scope("root");
    return adapter(api);
});

const getStyles = (stylesApi, ns, style, k, ownStyles) => {
    let s = stylesApi ? stylesApi.fork(ns, style, ownStyles) : null;
    //console.log(['getStyles',ns,style,k].join('.'));
    return s;
};

export const styled = createCachedSelector(
    [
        (store, props) => props.s,
        (store, props, ns) => ns,
        (store, props, ns, item) => item.style,
        (store, props, ns, item, k) => k,
        (store, props, ns, item) => item.styles,
        store => store.data['theme.styles']
    ],
    getStyles
)((store, props, ns, item, k, ownStyles) => {
    return item.id + "/" + item.style + "/" + k;
});