import React, {Component} from 'react';
import moment from 'moment';

import { DatePicker, InlineDatePicker } from 'material-ui-pickers';
import IconButton from 'components/TooltipIconButton';
import ClearIcon from '@material-ui/icons/Clear';
import InlineField from 'components/fields/InlineField';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';

/* eslint-disable */
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';

const fieldStyle = { display: 'flex', alignItems: 'center' };

export default class Datepicker extends Component {

    constructor(props) {
        super(props);
        this.pickerRef = React.createRef();
    }

    handleDateChange = (value) => {
        let timestamp = value.startOf('day').format('X');
        this.props.onChange(
            this.props.id,
            timestamp
        )
    };

    handleClear = () => {
        this.props.onChange(
            this.props.id,
            null
        );
    };

    handleOpenInline = (e) => {
        if(this.pickerRef.current) {
            this.pickerRef.current.open(e);
        }
    };

    renderInline = (value) => {
        const { readOnly, format, label, ValueComponent } = this.props;
        let valueTxt = value
            ? value.format(format || 'D MMM') //value.startOf('day').fromNow()
            : null;
        //format('D MMM')

        return <React.Fragment>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <InlineDatePicker
                    value={value}
                    onChange={this.handleDateChange}
                    ref={this.pickerRef}
                    style={{ display: 'none' }}
                />
            </MuiPickersUtilsProvider>
            { ValueComponent ? <ValueComponent
                onClick={!readOnly ? this.handleOpenInline : null}
                value={valueTxt}
                label={label}
            /> : <div onClick={!readOnly ? this.handleOpenInline : null} className="datepicker--inline">
                { value
                    ? <div className="datepicker__value">{value ? valueTxt : 'choose'}</div>
                    : <div style={{ fontStyle: 'italic', opacity: 0.5 }}>wybierz datę</div> }
                {/**<IconButton icon="event" onClick={this.handleOpenInline} tooltip={this.props.label}/>**/}
            </div> }
        </React.Fragment>;
    };

    render() {
        const { variant, value, label, readOnly, ValueComponent } = this.props;

        let dateValue = value
            ? moment.unix(value)
            : null;

        if (variant === 'inline' || ValueComponent) {
            return this.renderInline(dateValue);
        }

        return (<div style={fieldStyle}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                    keyboard
                    value={dateValue}
                    onChange={this.handleDateChange}
                    variant="outlined"
                    label={label}
                    readOnly={readOnly}
                />
            </MuiPickersUtilsProvider>
            <IconButton icon="clear" onClick={this.handleClear} />
        </div>);
    }

}