import React from 'react';
import {connect} from 'react-redux';
import {listFilterSet} from 'state/actions';
import {handleUpload} from 'state/actions/files';
import {makeSelectFilteredList} from 'state/selectors/filter';

import MediaPicker from 'components/media/MediaPicker';

const mapState = () => {
    const selectFilteredResources = makeSelectFilteredList();
    return (store, props) => {
        let filterId = props.filterId || 'cms.media.quick';
        let listId = props.listId || 'cms.media';
        return {
            filter: store.filter[filterId] || {},
            filterId,
            listId,
            data: selectFilteredResources(store, 'cms.media', filterId),
            isPending: store.ui.pending['cms.media']
        }
    }
};

const mapDispatchToProps = {
    onSetFilter: listFilterSet,
    onUpload: handleUpload
};

class MediaBrowser extends React.PureComponent {

    handleUpload = (files) => {
        files.forEach(file => {
            this.props.onUpload(file);
        });
    };

    render() {
        return (<MediaPicker
            {...this.props}
            onUpload={this.handleUpload}
        />);
    }

}

export default connect(
    mapState,
    mapDispatchToProps
)(MediaBrowser);