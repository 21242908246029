import React  from 'react';

import './css/app.css';
import './css/datepicker.css';
import './css/app-menu.css';
import './css/buttons.css';
import './css/popover.css';
import './css/list-layout.css';
import './css/list-summary.css';
import './css/form.css';
import './css/form-info.css';
import './css/select.css';
import './css/empty-state.css';
import './css/props-table.css';
import './css/quill.css';
import './css/side-sheet.css';
import './css/section-preview.css';
import './css/preloader.css';
import './css/upload.css';
import './css/media.css';
import './css/material-colors.css';
import './css/tasks.css';
import './css/table.css';
import './css/post.css';
import './css/graph.css';
import './css/survey.css';
import './css/gallery.css';
import './css/gallery-card.css';
import './css/filters.css';
import './css/messages.css';
import './css/rwd.css';
import 'react-owl-carousel2/lib/styles.css';

import { Route, Switch } from 'react-router'

import routes from './routes';

import SyncRouteParams from "containers/SyncRouteParams";

import AppContext from "containers/AppContext";
import AuthContext from "containers/AuthContext";

import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

import moment from 'moment';

const plLocale = require('moment/locale/pl');
moment.locale('pl', plLocale);

class App extends React.Component {
  render() {
    return <AuthContext>
        <Switch>
            {routes.map((route) => {
                return route.paths.map((path) => {
                    let RouteComponent = route.component;
                    return (<Route path={path} render={(props) => (
                        <SyncRouteParams
                            params={{
                                ...route.params,
                                ...props.match.params
                            }}
                        >
                            <AppContext app="console">
                                <RouteComponent />
                            </AppContext>
                        </SyncRouteParams>
                    )} />);
                });
            })}
        </Switch>
    </AuthContext>
  }
}

export default DragDropContext(HTML5Backend)(App);
