import React from 'react';

import Card from 'components/list/Card';
import PresetColors from "components/list/fields/PresetColors";
import PresetFontsPreview from 'components/fields/theme/PresetFontsPreview';

export class ThemePresetCardColors extends React.PureComponent {

    handleClick = (evt) => {
        this.props.onClick(this.props.data);
    };
    
    render() {
        const { id } = this.props;
        let path = this.props.data.color_scheme
            ? ['console.presets',id,'color_scheme'].join('/')
            : ['console.colors', id].join('/');
        return (<Card
            {...this.props}
            onClick={this.handleClick}
            footer={<PresetColors
                path={path}
            />}
        />);
    }

}


export class ThemePresetCardTypography extends React.PureComponent {

    handleClick = (evt) => {
        this.props.onClick(this.props.data);
    };

    render() {
        const { id } = this.props;
        let path = this.props.data.typography
            ? ['console.presets',id,'typography'].join('/')
            : ['console.typography', id].join('/');
        return (<Card
            {...this.props}
            onClick={this.handleClick}
        >
            <PresetFontsPreview
                path={path}
            />
        </Card>);
    }

}