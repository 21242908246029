export const createMessage = (thread, message, attachType, attachId, rootType, rootId) => {
    return {
        type: 'MESSAGES.CREATE',
        payload: {
            thread,
            message,
            attachType,
            attachId,
            rootType,
            rootId
        }
    }
};