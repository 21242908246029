import React from 'react';
import {connect} from 'react-redux';
import {selectResource} from 'state/selectors/resources';
import Resource from 'v2/Resource';
import Avatar from '@material-ui/core/Avatar';
import { thumbnail } from "util/thumbnail";

const mapStateToProps = (store, props) => {
    let resource = props.resource || 'db.identities';
    return {
        data: props.data || selectResource(store, resource, props.id)
    }
};

const mapDispatchToProps = {};

const outerStyle = {
    display: 'flex',
    alignItems: 'center',
    color: 'rgba(0,0,0,0.8)',
    fontSize: '12px'
};

const avatarStyle = {
    width: '24px',
    height: '24px',
    marginRight: '0.4em'
};

class Identity extends React.Component {

    render() {
        let { name, media } = this.props.data;
        return (<div style={outerStyle}>
            { media && media.length ?
                <Avatar src={thumbnail(media[0].file, [64, 64])} style={avatarStyle} />
                : null }
            <div>{name}</div>
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Identity);