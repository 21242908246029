const defaultActions = {
  route: "/:app/:lang/:project/:view",
  routes: {
    edit: "/:app/:lang/:project/:view/:id",
  },
  actions: {
    edit: {
      type: "route",
      label: "Edytuj",
    },
    delete: {
      type: "action",
      action: "RESOURCE.DELETE",
      label: "Usuń",
    },
  },
  listActions: {
    create: {
      type: "action",
      action: "LIST.CREATE",
      label: "Create",
    },
  },
};

const pages = {
  name: "Pages",
  sortable: true,
  route: "/:app/:lang/:project/pages",
  routes: {
    edit: "/:app/:lang/:project/:view/:id",
    "edit-page": "/:app/:lang/:project/:view/:id/edit-page",
    ide: "/ide/:lang/:project/:view/:id",
    sections: "/:app/:lang/:project/:view/:id/sections",
    section: "/:app/:lang/:project/:view/:id/sections/:relatedId",
  },
  primaryAction: "sections",
  secondaryAction: "edit",
  actions: {
    edit: {
      type: "route",
      label: "Edytuj",
    },
    ide: {
      type: "route",
      label: "Edytuj w trybie podglądu",
    },
    "edit-page": {
      type: "route",
      query: { side: "edit-page" },
    },
    sections: {
      type: "route",
      label: "Pokaż sekcje",
    },
    createAfter: {
      type: "action",
      action: "LIST.CREATE_AFTER",
      label: "Utwórz kolejną",
    },
    activate: {
      type: "action",
      action: "RESOURCE.TOGGLE",
      args: {
        property: "active",
      },
      label: "Aktywuj",
    },
    delete: {
      type: "action",
      action: "RESOURCE.DELETE",
      label: "Usuń",
    },
  },
  listActions: {
    createPage: {
      type: "action",
      action: "LIST.CREATE",
      label: "Nowa podstrona",
    },
    createSection: {
      type: "action",
      action: "SECTION.CREATE_AFTER",
      label: "Nowa sekcja",
      args: {
        resource: "cms.sections",
      },
      context: {
        relation: "sections",
      },
    },
    attach: {
      type: "route",
      query: { q: "attach-section" },
      action: "SECTION.ATTACH",
      label: "Dołącz sekcję",
    },
  },
};

export default {
  "apps.console": {
    name: "console",
    route: "/console/services.website",
    base: "/console",
  },
  "apps.cms": {
    name: "cms",
    route: "/cms/:lang/:project/pages",
    base: "/cms/:lang/:project",
  },
  "apps.apartments": {
    name: "apartments",
    route: "/apartments/:lang/:project/flats",
    base: "/apartments/:lang/:project",
  },
  "apps.db": {
    name: "database",
    route: "/db/:lang/:project/apartments",
    base: "/db/:lang/:project",
  },
  "console.services.website": {
    name: "Websites",
    route: "/console/services.website",
    routes: {
      edit: "/console/services.website/:id",
    },
    actions: {
      edit: {
        type: "route",
        label: "Edytuj",
      },
      delete: {
        type: "action",
        label: "Usuń",
      },
      destroy: {
        type: "action",
        action: "CONSOLE.WEBSITES.DESTROY",
        label: "Destroy",
      },
      refresh: {
        type: "action",
        action: "CONSOLE.WEBSITES.REFRESH",
        label: "Refresh"
      },
      activate: {
        type: "action",
        label: "Aktywuj",
      },
    },
    primaryAction: "edit",
    listActions: {
      add: {
        type: "action",
        action: "LIST.CREATE",
        label: "Nowa witryna",
      },
      createWebsite: {
        type: "route",
        action: "SECTION.CREATE_AFTER",
        label: "Kreator",
        query: { q: "create-website" },
      },
    },
  },
  "console.services.cms": {
    routes: {
      snapshots: "/console/services.cms/:id",
    },
    actions: {
      snapshots: {
        label: "Restore",
        type: "route",
        query: { q: "snapshots" },
      },
      backup: {
        label: "Create snapshot",
        type: "action",
        action: "CONSOLE.CMS.DUMP",
      },
    },
  },
  "console.cms.snapshots": {
    actions: {
      restore: {
        type: "action",
        label: "Restore",
        action: "CONSOLE.CMS.RESTORE",
      },
    },
    primaryAction: "restore",
  },
  "console.domains": {
    name: "Domains",
    route: "/console/domains",
    routes: {
      edit: "/console/:view/:id",
    },
    actions: {
      "cert-renew": {
        type: "action",
        action: "CONSOLE.CERT.RENEW",
        label: "Renew SSL certificate",
      },
      "config-reload": {
        type: "action",
        action: "CONSOLE.DOMAIN.CONFIG",
        label: "Reload http config",
      },
      "cache-clear": {
        type: "action",
        action: "CONSOLE.DOMAIN.CACHECLEAR",
        label: "Clear domain cache",
      },
    },
  },
  "console.themes": {
    name: "Themes",
    route: "/console/themes",
    routes: {
      edit: "/console/:view/:id",
      vcs: "/console/:view/:id",
    },
    actions: {
      build: {
        type: "action",
        action: "CONSOLE.THEME.BUILD",
        label: "Build",
      },
      vcs: {
        label: "Github",
        type: "route",
        query: { q: "vcs" },
      },
    },
  },
  "cms.pages": pages,
  ide: {
    routes: {
      "edit-page": "/ide/:lang/:project/:view/:id/edit-page",
      ide: "/ide/:lang/:project/:view/:id",
      sections: "/ide/:lang/:project/:view/:id/sections",
      section: "/ide/:lang/:project/:view/:id/sections/:relatedId",
    },
    actions: {
      "edit-page": {
        type: "route",
        query: { side: "edit-page" },
        label: "Edytuj podstronę",
      },
      ide: {
        type: "route",
        label: "IDE",
      },
      sections: {
        type: "route",
        label: "Pokaż sekcje",
      },
      delete: {
        type: "action",
        action: "RESOURCE.DELETE",
        label: "Usuń",
      },
      activate: {
        type: "action",
        action: "RESOURCE.TOGGLE",
        args: {
          property: "active",
        },
        label: "Aktywuj",
      },
      createAfter: {
        type: "action",
        action: "LIST.CREATE_AFTER",
        label: "Utwórz kolejną",
      },
    },
    listActions: {
      ...pages.listActions,
    },
  },
  "sections-attach-target": {
    actions: {
      export: {
        type: "action",
        label: "Eksportuj",
      },
    },
  },
  "cms.sections": {
    name: "Sections",
    sortable: true,
    route: "/cms/:lang/:project/sections",
    routes: {
      edit: "/cms/:lang/:project/sections/:id",
    },
    actions: {
      edit: {
        type: "route",
        label: "Edytuj",
      },
      copy: {
        label: "Skopiuj",
      },
      createAfter: {
        type: "action",
        action: "SECTION.CREATE_AFTER",
        label: "Utwórz kolejną",
      },
      activate: {
        type: "action",
        action: "RESOURCE.TOGGLE",
        args: {
          property: "active",
        },
        label: "Aktywuj",
      },
      detach: {
        type: "action",
        action: "SECTION.DETACH",
        label: "Odłącz",
      },
    },
  },
  "cms.layouts": {
    ...pages,
    name: "Szablony podstron",
    route: "/cms/:lang/:project/layouts",
  },
  "cms.entries.offers": {
    ...pages,
    name: "Pakiety",
    route: "/cms/:lang/:project/entries.offers",
    primaryAction: "edit",
    secondaryAction: "sections",
    actions: {
      ...pages.actions,
      activate: {
        type: "action",
        action: "RESOURCE.TOGGLE",
        args: {
          property: "archived",
        },
        label: "Aktywuj",
      },
    },
  },
  "cms.entries.posts": {
    ...pages,
    name: "Wpisy",
    route: "/cms/:lang/:project/entries.posts",
    primaryAction: "edit",
    secondaryAction: "sections",
    actions: {
      ...pages.actions,
    },
  },
  "cms.entries.rooms": {
    name: "Pokoje",
    sortable: true,
    route: "/cms/:lang/:project/entries.rooms",
    routes: {
      edit: "/cms/:lang/:project/entries.rooms/:id",
      sections: "/cms/:lang/:project/entries.rooms/:id/sections",
      section: "/cms/:lang/:project/entries.rooms/:id/sections/:relatedId",
    },
    primaryAction: "edit",
    secondaryAction: "sections",
    actions: {
      edit: {
        type: "route",
        label: "Edytuj",
      },
      sections: {
        type: "route",
        label: "Pokaż sekcje",
      },
      delete: {
        type: "action",
        label: "Usuń",
      },
      activate: {
        type: "action",
        label: "Aktywuj",
      },
    },
  },
  "cms.entries.projects": {
    ...pages,
    name: "Realizacje",
    sortable: true,
    route: "/cms/:lang/:project/entries.projects",
    routes: {
      edit: "/cms/:lang/:project/entries.projects/:id",
      sections: "/cms/:lang/:project/entries.projects/:id/sections",
      section: "/cms/:lang/:project/entries.projects/:id/sections/:relatedId",
    },
    primaryAction: "edit",
    secondaryAction: "sections",
    actions: {
      edit: {
        type: "route",
        label: "Edytuj",
      },
      sections: {
        type: "route",
        label: "Pokaż sekcje",
      },
      delete: {
        type: "action",
        label: "Usuń",
      },
      activate: {
        type: "action",
        label: "Aktywuj",
      },
    },
  },
  "cms.items": {
    sortable: true,
    actions: {
      activate: {
        type: "action",
        label: "Aktywuj",
        action: "TOGGLE_CMS_ITEM_ACTIVE",
      },
      detach: {
        type: "action",
        action: "DRAFT.PATH.DETACH",
        label: "Usuń",
      },
    },
  },
  "cms.nav-link": {
    sortable: true,
    actions: {
      detach: {
        type: "action",
        action: "DRAFT.PATH.DETACH",
        label: "Usuń",
      },
    },
  },
  "cms.fonts": {
    sortable: true,
  },
  "cms.media": {
    actions: {
      delete: {
        type: "action",
        label: "Usuń",
      },
    },
  },
  "cms.integrations": {
    listActions: {
      createIntegration: {
        type: "route",
        label: "Dodaj integrację",
        query: { q: "create-integration" },
      },
    },
  },
  "db.integrations": {
    listActions: {
      createIntegration: {
        type: "route",
        label: "Dodaj integrację",
        query: { q: "create-integration" },
      },
    },
  },
  "apartments.flats": {
    sortable: true,
    routes: {
      preview: "/:app/:lang/:project/:view/:id",
    },
    primaryAction: "preview",
    actions: {
      preview: {
        type: "route",
        query: { form: "preview" },
        label: "preview",
      },
    },
  },
  "apartments.plans": {
    actions: {
      editor: {
        label: "Plan editor",
        type: "action",
        action: "APARTMENTS.PLAN.EDITOR",
      },
    },
  },
  "apartments.resource-bindings": {
    routes: {
      sync: "/:app/:lang/:project/:view/:id",
    },
    actions: {
      sync: {
        label: "Synchronize",
        type: "route",
        query: { q: "sync" },
      },
    },
  },
  "db.resource-bindings": {
    ...defaultActions,
    route: "/:app/:lang/:project/resource-bindings",
    routes: {
      ...defaultActions.routes,
      sync: "/:app/:lang/:project/:view/:id",
    },
    actions: {
      ...defaultActions.actions,
      sync: {
        label: "Synchronize",
        type: "route",
        query: { q: "sync" },
      },
    },
  },
  "db.nodes": {
    ...defaultActions,
    route: "/:app/:lang/:project/nodes",
    routes: {
      view: defaultActions.routes.edit,
      edit: "/:app/:lang/:project/nodes/:id/?edit=db.nodes%2F:id",
    },
    actions: {
      view: {
        type: "route",
        label: "View stream",
      },
      ...defaultActions.actions,
    },
    primaryAction: "view",
    icon: "mui-share",
  },
  default: {
    ...defaultActions,
  },
  "db.posts": {
    ...defaultActions,
    actions: {
      ...defaultActions.actions,
      edit: defaultActions.actions.edit,
      change: {
        ...defaultActions.actions.edit,
      },
    },
  },
};
