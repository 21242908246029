import React from "react";

import {webfont_icon} from  'ide/components/icon';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import IconPickerDialog from './IconPickerDialog';

export default class IconPicker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  handleOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
  };

  handleSubmit = (value) => {
    this.props.onChange(this.props.id, value);
    this.handleClose();
  };

  handleClear = () => {
    this.props.onChange(this.props.id, null);
  };

  render() {
    const { label, value } = this.props;

    return (
      <React.Fragment>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ fontWeight: "bold", marginRight: "1rem" }}>{label}</div>
          <div style={{ flexGrow: 1 }}>
            {value ? (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  fontSize: "1.2rem"
                }}
              >
                <Paper style={{
                  fontSize: "1.6em",
                  padding: '1rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  {webfont_icon(value, this.handleOpen)}
                </Paper>
                <div style={{ marginLeft: "1rem", opacity: "0.4" }}>
                  {value}
                </div>
              </div>
            ) : (
              <span>Wybierz</span>
            )}
          </div>
          <div>
            { value ? <IconButton
              onClick={this.handleClear}
              label="Wyczyść"
                ><Icon>clear</Icon>
            </IconButton> : null }
            <Button
              onClick={this.handleOpen}
              primary={true}
            >
              Wybierz ikonę
            </Button>
          </div>
        </div>
        <Dialog open={this.state.open} onClose={this.handleClose}>
          <div style={{ width: '600px', height: '600px' }}>
            <IconPickerDialog
                onSubmit={this.handleSubmit}
                libraries={this.props.libraries}
            />
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}
