import React from 'react';
import Dialog from 'components/Dialog';

import AppMenu from 'containers/AppMenu';

export default class AppMenuDialog extends React.PureComponent {

    render() {
        const { open, onClose } = this.props;
        return (<Dialog
            maxWidth={"md"}
            fullWidth={true}
            open={open}
            onClose={onClose}
        >
            <AppMenu />
        </Dialog>);
    }

}