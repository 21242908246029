import React from 'react';
//import PropTypes from 'prop-types';
import Dialog from 'components/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from 'components/TooltipIconButton';

import Map from './Map';

const normalizeLatLng = (latLng = {}) => {
    return {
        lat: latLng ? latLng.lat*1 || 0 : 0,
        lng: latLng ? latLng.lng*1 || 0 : 0
    }
};

const MapsImg = (props) => {
    let key = window.GOOGLE_API_KEY;
    const { lat, lng } = props;
    if (!lat || !lng) return null;
    let src = `https://maps.googleapis.com/maps/api/staticmap`
        + `?center=${lat},${lng}&zoom=15`
        + `&markers=color:red%7Clabel:A%7C${lat},${lng}`
        + `&size=120x120&key=${key}`;
    return <img style={{ width: '100%' }} src={src} alt="map" />;
};

export default class Geolocation extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {open: false};
    }

    handleOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    handleChange = (value) => {
        //this.handleClose();
        this.props.onChange(this.props.id, value);
    };

    render() {
        const latLng = normalizeLatLng(this.props.value);
        const marker = { position: latLng};
        return (<div style={{ flex: 1 }}>
            <div className="map-preview" onClick={this.handleOpen}>
                {latLng.lat
                    ? <MapsImg {...latLng} />
                    : <IconButton icon="location_on" onClick={this.handleOpen} /> }
            </div>
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                maxWidth="lg"
                fullWidth={true}
            >
                <Map
                    marker={marker}
                    setGeolocation={this.handleChange}
                />
            </Dialog>
        </div>);
    }

}

Geolocation.propTypes = {};