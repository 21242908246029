import * as index from "./index";
import * as cms from "./cms";
import * as console from "./console";

export default {
  "RESOURCE.DELETE": index.resourceDelete,
  "SECTION.DETACH": cms.sectionDetach,
  "SECTION.CREATE_AFTER": cms.sectionCreateAfter,
  "SECTION.ATTACH": cms.sectionAttach,
  "LIST.CREATE": (resource, ...args) =>
    index.listCreate(resource, resource, ...args),
  "LIST.CREATE_AFTER": (resource, ...args) =>
    index.listCreateAfter(resource, resource, ...args),
  "RESOURCE.TOGGLE": (resource, id, list, { property }) =>
    index.resourceToggle(resource, id, property),
  "CONSOLE.WEBSITES.DESTROY": (resource, id) => console.consoleDestroyWebsite(id),
  "CONSOLE.WEBSITES.REFRESH": (resource, id) => console.consoleRefreshWebsite(id),
  "CONSOLE.CMS.RESTORE": (resource, id) => console.consoleRestoreSnapshot(id),
  "CONSOLE.CMS.DUMP": (resource, id) => console.consoleDumpSnapshot(id),
  "CONSOLE.CERT.RENEW": (resource, id) => console.consoleRenewCertificate(id),
  "CONSOLE.DOMAIN.CONFIG": (resource, id) =>
    console.consoleDomainConfigReload(id),
  "CONSOLE.DOMAIN.CACHECLEAR": (resource, id) =>
    console.consoleDomainCacheClear(id),
  "CONSOLE.THEME.BUILD": (resource, id) => console.consoleThemeBuild(id),
  "DB.JOB": (resource, id, list, args) => ({
    type: "DB.JOB",
    resource,
    id,
    list,
    job: args.action,
  }),
  "DB.VIEW": (resource, id, list, args) => ({
    type: "DB.VIEW",
    resource,
    id,
    list,
    view: args.action,
  }),
  "APARTMENTS.PLAN.EDITOR": (resource, id) => ({
    type: "APARTMENTS.PLAN.EDITOR",
    resource,
    id,
  }),
};
