import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';

export default class FieldMapping extends React.Component {

    render() {
        const { from, to, onClick } = this.props;
        return (<div onClick={onClick} className="field-mapping-outer">
            <div className="field-mapping">
                <Icon fontSize="inherit">device_hub</Icon>
                <div>{from}</div>
            </div>
            <div><Icon style={{ opacity: 0.2, margin: '0 0.8rem' }}>arrow_forward</Icon></div>
            <div className="field-mapping">
                <div>{to}</div>
            </div>
            <div className="mapped-field">
                {this.props.children}
            </div>
        </div>);
    }

}

FieldMapping.propTypes = {};