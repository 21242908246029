import React, { Component } from "react";

export default class Template extends React.PureComponent {
  render() {
    let { template, onFocus } = this.props.data;
    return (
      <span onClick={onFocus} dangerouslySetInnerHTML={{ __html: template }} />
    );
  }
}
