import React, {Component} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormFieldset from 'containers/FormFieldset';

export default class Confirm extends Component {

    render() {
        const {
            isOpen,
            yes,
            no,
            message,
            description,
            cancelText,
            confirmText,
            schemaPath,
            path
        } = this.props;

        return (<Dialog
            open={isOpen}
            onClose={no}
        >
            {message ? <DialogTitle id="alert-dialog-title">{message}</DialogTitle> : null}
            <DialogContent>
                { description ? <DialogContentText>{description}</DialogContentText> : null }
                { schemaPath
                    ? <div style={{ marginTop: '5px' }}><FormFieldset
                        path={path}
                        schemaPath={schemaPath}
                    /></div>
                    : <DialogContentText>Ta operacja nie może zostać cofnięta.</DialogContentText> }
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center', padding: '1rem' }}>
                <Button color="default" size="large" onClick={no}>
                    {cancelText || 'Anuluj'}
                </Button>
                <Button variant="contained" color="secondary" size="large" onClick={yes}>
                    {confirmText || 'Zatwierdź'}
                </Button>
            </DialogActions>
        </Dialog>);
    }

}