import React from 'react';
import {connect} from 'react-redux';
import {query} from 'state/actions/route';

import ListHeading from "./ListHeading";
import Filters from 'containers/list/Filters';
import ListDisplay from 'containers/list/ListDisplay';
import Portal from '@material-ui/core/Portal';
import IconButton from 'components/TooltipIconButton';

const mapStateToProps = (store, props) => {
    return {
        open: store.context.filters
    }
};

const mapDispatchToProps = {
    query
};

class ListFilterArea extends React.PureComponent {

    constructor(props) {
        super(props);
        this.mobileActionsRef = document.getElementById('app-bar-actions');
    }

    handleShowFilters = () => {
        this.props.query({filters:this.props.open ? null : true});
    };

    render() {
        const {
            resource,
            listId,
            filterId,
            onReloadList,
            displayOptions,
            title,
            open
        } = this.props;
        
        let className = 'list-filter-area';
        if (open) className += ' open';

        let backdropClass = 'filter-area-backdrop';
        if (open) backdropClass += ' open';
        
        return (<React.Fragment>
            <div className={className}>
                <ListHeading title={title}>
                    { onReloadList ? <IconButton
                        icon="refresh"
                        tooltip="Odśwież"
                        onClick={onReloadList}
                        color="inherit"
                    />: null }
                </ListHeading>
                {this.props.heading ? <ListHeading>
                    {this.props.heading}
                </ListHeading> : null}
                { displayOptions ? <ListDisplay
                    id={listId}
                    display={displayOptions}
                /> : null }
                { filterId ? <Filters
                    resource={resource}
                    filterId={filterId}
                    listId={listId}
                    context={this.props.context}
                /> : null }
            </div>
            <Portal container={this.mobileActionsRef}>
                <IconButton
                    icon="search"
                    onClick={this.handleShowFilters}
                />
            </Portal>
            <div className={backdropClass} onClick={this.handleShowFilters} />
        </React.Fragment>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListFilterArea);