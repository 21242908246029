import React from 'react';
import PropTypes from 'prop-types';

import {connect} from 'react-redux';
import FormField from "./FormField";
import _ from "lodash";

const mapStateToProps = (store, props) => {
    let schemaPath = props.schemaPath;
    let schema = _.get(store.schema, schemaPath.split('/'));
    if (!schema) {
        throw new Error(`Unable to resolve schema for ${schemaPath}`);
    }
    return {
        schema
    }
};

const mapDispatchToProps = {};

class FormFieldset extends React.PureComponent {

    render() {
        const { schemaPath, schema, path, storeId } = this.props;
        const fields = schema && schema.fields
            ? Object.keys(schema.fields)
            : [];
        return (<React.Fragment>
            {fields.map((key) => {
                let fieldSchemaPath = schemaPath.split('/').concat(['fields']).join('/');
                return (<FormField
                    key={key}
                    parentPath={path}
                    schemaPath={fieldSchemaPath}
                    storeId={storeId}
                    id={key}
                />);
            })}
        </React.Fragment>);
    }

}

FormFieldset.propTypes = {
    path: PropTypes.string.isRequired,
    schemaPath: PropTypes.string.isRequired,
    schema: PropTypes.object.isRequired
};

const ConnectedFormFieldset = connect(mapStateToProps, mapDispatchToProps)(FormFieldset);

ConnectedFormFieldset.propTypes = {
    path: PropTypes.string.isRequired,
    schemaPath: PropTypes.string.isRequired
};

export default ConnectedFormFieldset;