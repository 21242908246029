import React from "react";
import MuiIcon from '@material-ui/core/Icon'

export const webfont_icon = (icon, onClick, color) => {
  if (!icon) return null;
  icon = icon.replace('fa-', '');

  if (icon.indexOf("fh-") > -1) {
    return <i onClick={onClick} className={"fh " + icon}/>;
  }

  if (icon.indexOf("mui-") === 0) {
    return <MuiIcon onClick={onClick} color={color||'inherit'} size="inherit">{icon.replace('mui-','')}</MuiIcon>;
  }

  return <i onClick={onClick} className={"fa fa-" + icon} />;
};

export default class Icon extends React.PureComponent {
  handleSelectIcon = () => {
    alert('select icon');
  };

  render() {
    const { s, className, data } = this.props;

    if (!data.icon) {
      return (
          <div
              className={className + " " + s.get("icon")}
              style={{ opacity: 0.5 }}
              onClick={this.handleSelectIcon}
          >
            {webfont_icon("fh-circle-more")}
          </div>
      );
    }
    return (
        <div className={className + " " + s.get("icon")} onClick={this.handleSelectIcon}>
          {webfont_icon(data.icon)}
        </div>
    );
  }
}
