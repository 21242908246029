import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import { connect } from "react-redux";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import Chip from "@material-ui/core/Chip";

import Icon from "@material-ui/core/Icon";
import SelectMenu from "./SelectMenu";
import IconButton from "components/TooltipIconButton";
import InlineField from "components/fields/InlineField";
import NavPillButton from "components/NavPillButton";
import ChipSelect from "components/fields/select/ChipSelect";
import RadioSelect from "components/fields/select/RadioSelect";
import TextField from "components/fields/text/TextField";

import Value from "components/fields/Value";

import withSelectOptions from "./SelectOptions";

import { selectValue } from "components/list/fields";

const emptyArray = [];
const emptyObj = {};

const mapStateToProps = (store, props) => {
  let value = props.value;
  if (props.multiple && !value) value = emptyArray;
  return {
    value,
  };
};

const mapDispatchToProps = {};

class SelectInput extends React.PureComponent {
  render() {
    const { className, value, multiple, optionsIndex, labels } = this.props;
    return (
      <div className={className} style={{ minHeight: "1em" }}>
        {multiple
          ? value.map((item, i) => {
              return (
                <Chip
                  onClick={(evt) => {
                    evt.stopPropagation();
                    this.props.onRemove(item);
                  }}
                  key={[item, i].join(".")}
                  label={selectValue(optionsIndex[item], labels, false) || null}
                  style={{ margin: "0.2rem" }}
                />
              );
            })
          : selectValue(optionsIndex[value], labels, false) || (
              <span className="select-option--not-found">
                {value ? JSON.stringify(value) : null}
              </span>
            )}
      </div>
    );
  }
}

export class SelectField extends Component {
  constructor(props) {
    super(props);
    this.InputLabelRef = null;
    this.state = {
      labelWidth: 0,
    };
  }

  componentDidMount() {
    this.updateLabelWidth();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.label !== this.props.label) {
      this.updateLabelWidth();
    }
  }

  labels = ["label", "name", "title", "description", "code_part", "type", "id"];

  updateLabelWidth = () => {
    const label = findDOMNode(this.InputLabelRef);
    this.setState({
      labelWidth: label ? label.offsetWidth : 0,
    });
  };

  handleChange = (event) => {
    this.props.onChange(event.target.value);
  };

  renderChips = (selected) => {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {selected.map((value) => (
          <Chip key={value} label={value} style={{ margin: "0.2rem" }} />
        ))}
      </div>
    );
  };

  handleSelect = (value) => {
    if (!this.props.multiple) {
      this.props.onChange(value);
      return;
    }

    let newValue = this.props.value.slice();
    if (newValue.indexOf(value) === -1) {
      newValue.push(value);
    } else {
      newValue.splice(newValue.indexOf(value), 1);
    }

    this.props.onChange(newValue);
  };

  handleRemove = (value) => {
    this.handleSelect(value);
  };

  handleClear = (evt) => {
    evt.stopPropagation();
    this.props.onChange(this.props.multiple ? [] : null);
  };

  renderSelectInput = (props) => {
    const value = this.props.value;
    const hasValue = !!(
      value !== null &&
      (!Array.isArray(value) || value.length > 0)
    );
    const { nullable, options, multiple } = this.props;

    const inputProps = {
      hasValue: hasValue,
      value: value,
      options: options,
      multiple: multiple,
      optionsIndex: this.props.optionsIndex || emptyObj,
      labels: this.labels,
      onRemove: this.handleRemove,
    };

    return (
      <OutlinedInput
        name={this.props.id}
        labelWidth={this.state.labelWidth}
        readOnly={true}
        inputComponent={SelectInput}
        value={hasValue ? " " : ""}
        inputProps={inputProps}
        endAdornment={
          hasValue && nullable ? (
            <div style={{ display: "flex" }}>
              <Icon onClick={this.handleClear} className="icon-btn">
                clear
              </Icon>
              <Icon>keyboard_arrow_down</Icon>
            </div>
          ) : (
            <Icon>keyboard_arrow_down</Icon>
          )
        }
        style={{ cursor: "pointer" }}
        {...props}
      />
    );
  };

  render() {
    const { id, label, options, radio, multiple } = this.props;

    let value =
      this.props.value && this.props.optionsIndex
        ? this.props.optionsIndex[this.props.value]
        : null;

    let properties = this.props.properties || {};
    let variant = this.props.variant || properties.variant;
    let isInline = variant === "inline" || this.props.inline;
    let isChip = variant === "chip";
    let textFallback = this.props.textFallback || properties.textFallback;
    let isEmpty = !this.props.options || !this.props.options.length;

    if (properties.hide && !this.props.options.length) return null;

    if (textFallback && isEmpty) {
      return (
        <TextField
          {...this.props}
          onChange={(id, value) => this.handleSelect(value)}
        />
      );
    }

    if (variant === "filled") {
      return (
        <SelectMenu
          {...this.props}
          onSelect={this.handleSelect}
          trigger={(props) => (
            <NavPillButton
              {...props}
              active={!!this.props.value}
              selectable={true}
            >
              {this.props.value || this.props.label}
            </NavPillButton>
          )}
        />
      );
    }

    if (isChip && radio) {
      return (
        <InlineField
          value={
            <ChipSelect
              options={this.props.optionsIndex}
              onChange={this.handleSelect}
              value={this.props.value}
              layout="horizontal"
              multiple={multiple}
            />
          }
          label={this.props.label}
        />
      );
    }

    if (radio) {
      return (
        <RadioSelect
          id={id}
          label={label}
          options={options}
          value={this.props.value}
          nullable={this.props.nullable}
          onChange={this.handleSelect}
        />
      );
    }

    if (isChip || isInline) {
      return (
        <SelectMenu
          {...this.props}
          onSelect={this.handleSelect}
          trigger={(props) => (
            <InlineField
              value={
                <Value
                  resource={this.props.resource}
                  id={this.props.value}
                  rightIcon={<Icon>arrow_drop_down</Icon>}
                />
              }
              label={this.props.label}
              {...props}
            />
          )}
        />
      );
    }

    if (variant === "icon") {
      if (!this.props.options[0]) return "error";
      return (
        <SelectMenu
          {...this.props}
          onSelect={this.handleSelect}
          trigger={(props) => (
            <IconButton
              {...props}
              active={!!this.props.value}
              tooltip={this.props.label}
              icon={value ? value.icon : this.props.options[0].icon}
            />
          )}
        />
      );
    }
    return (
      <FormControl variant="outlined" fullWidth={true}>
        <InputLabel
          ref={(ref) => {
            this.InputLabelRef = ref;
          }}
        >
          {this.props.label}
        </InputLabel>
        <SelectMenu
          {...this.props}
          trigger={this.renderSelectInput}
          onSelect={this.handleSelect}
        />
      </FormControl>
    );
  }
}

export default withSelectOptions()(
  connect(mapStateToProps, mapDispatchToProps)(SelectField)
);
