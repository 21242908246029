import React from 'react';
import ResourceList from "containers/ResourceList";
import App from 'views/layouts/App';
import {push} from 'connected-react-router';
import {connect} from 'react-redux';

import ResourceListItem from 'components/list/ResourceListItem';

import List from '@material-ui/core/List';

const mapStateToProps = (store, props) => {
    const listId = "console.services.db";
    return {
        listId,
        project: store.context.project
    }
};
const mapDispatchToProps = {
    onChangeProject: push
};

class Dashboard extends React.PureComponent {

    handleClick = (id) => {
        this.props.onChangeProject('/db/pl/'+id+'/apartments');
    };

    render() {
        const { listId } = this.props;
        return (<App>
            <div>
                {this.props.project}
                <List>
                    <ResourceList
                        resource={listId}
                        itemComponent={ResourceListItem}
                        itemProps={{
                            onClick: this.handleClick
                        }}
                    />
                </List>
            </div>
        </App>);
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);