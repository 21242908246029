import React from 'react';
import {connect} from 'react-redux';
import {feedback} from "state/actions/ui";

const mapStateToProps = (store, props) => {
    return {
        progress: store.ui.progress[props.id],
        isPending: store.ui.pending[props.id]
    }
};

const mapDispatchToProps = {
    onShowNotification: feedback
};

class File extends React.Component {

    constructor(props) {
        super(props);

        this.state = {menu: false};
    }

    openMenu = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        this.setState({menu: true, anchorEl: evt.target});
    };

    closeMenu = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        this.setState({menu: false});
    }

    render() {
        const { render } = this.props;
        const FileComponent = render;
        return <FileComponent
            {...this.props}
            onShowMenu={this.openMenu}
            onCloseMenu={this.closeMenu}
            menu={this.state.menu}
            anchorEl={this.state.anchorEl}
        />
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(File);