/**
 * Modify less variables based on preset
 */

import defaultStyles from "resources/styles-default.json";

import Styles from "ide/php/Styles";

export const buildStyles = styles => {
  console.log("build styles");  
  const s = new Styles();
  s.appendOptions(styles.options);
  Object.keys(styles).forEach(k => {
    Object.keys(styles[k]).forEach(c => {
      let ns = k.replace(".", ":") + "." + c;
      if (ns.indexOf("@tags") > -1) {
        s.appendConfig(ns, styles[k][c]);
      } else {
        s.append(ns, styles[k][c]);
      }
    });
  });
  return s;
};

const mapping = {
  color_scheme: {
    dark_color: "dark-color",
    light_color: "light-color",
    primary_color: "brand-primary",
    success_color: "brand-success"
  }
};

export default function styles(preset) {
  const less = window.less;
  let vars = convertPresetToLessVars(preset);
  window.__lessModifyVars__ = vars;
  less.modifyVars(vars);
}

export const convertTypeStyleToVars = style => {
  let vars = {};
  Object.keys(style).forEach((k) => {
    if (k !== 'id') {
      let lessVarName = '@' + [
          style.id,
          k.replace(/_/g, '-')
      ].join('-');
      let v = style[k];
      if (k === 'font' && !v) v = 'body';
      if (k === 'font_weight' && !v) v = 'regular';
      vars[lessVarName] = v;
    }
  });
  return vars;
};

export const convertTypeStylesToVars = styles => {
  let vars = {};
  if (styles && styles.length > 0) {
    vars['@type-styles-list'] = styles.map(s => s.id).join(',');
    styles.forEach((style) => {
      let styleVars = convertTypeStyleToVars(style);
      vars = {...vars, ...styleVars};
    });
  }
  return vars;
};

export const convertPresetToLessVars = preset => {
  let vars = {};
  vars = applyMapping(preset, vars, "color_scheme");
  vars = applyMapping(preset, vars, "typography");
  vars = loadWebfont(vars, "body-font", preset.typography.body_font);
  vars = loadWebfont(vars, "heading-font", preset.typography.heading_font);
  vars = loadWebfont(vars, "decorative-font", preset.typography.decorative_font);
  if (preset.mapping) { vars["@color-mapping"] = preset.mapping; }
  vars["@enable-reveal-effects"] = 0;
  let typeStyles = convertTypeStylesToVars(preset.typography.styles);
  return {...vars, ...typeStyles};
};

export const responsivePreviewBreakpoints = device => {
  if (!device || device === 'desktop') return {};
  return {
    '@xss-min': '500px',
    '@xs-min': '1680px',
    '@sm-min': '2000px',
    '@md-min': '2000px',
    '@lg-min': '2000px',
    '@xlg-min': '2000px',
    '@container-width': '375px'
  }
};

const applyMapping = function(preset, vars, key) {
  var scopeMapping = mapping[key];
  var scopeData = preset[key];
  for (var k in scopeMapping)
    if (scopeMapping.hasOwnProperty(k)) {
      if (scopeData[k]) {
        var mappedKey = "@" + scopeMapping[k];
        vars[mappedKey] = scopeData[k];
      }
    }
  return vars;
};

const loadWebfont = function(vars, font, data) {
  const WebFont = window.WebFont;
  for (var k in data)
    if (data.hasOwnProperty(k)) {
      if (data[k]) {
        var key = "@" + font;
        if (k !== "family") {
          key += "-" + k.replace("_", "-");
        } else {
          if (data.source === "google") {
            WebFont.load({
              google: {
                families: [data[k]]
              }
            });
          } else {
            WebFont.load({
              custom: {
                families: [data[k]],
                urls: [
                  "https://s3.eu-central-1.amazonaws.com/zuu-webfonts/" +
                    data[k] +
                    "/stylesheet.css"
                ]
              }
            });
          }
        }
        vars[key] = data[k];
      }
    }
  return vars;
};
