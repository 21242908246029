import React from 'react';
import {connect} from 'react-redux';
import { selectResource } from "state/selectors/resources";
import { draftsChangeField } from "state/actions";
import DragHandle from 'components/list/DragHandle';
import IconButton from 'components/TooltipIconButton';
import Field, {RenderField} from 'v2/Field';
import RenderListField from 'v2/fields/info-fields';
import Resource from 'v2/Resource';
import ResourceContext from 'v2/ResourceContext';

const mapStateToProps = (store, props) => {
    let data = selectResource(store, props.resource, props.id);
    let [parentResource, parentId] = props.parentResource.split('/');
    let parentData = selectResource(store, parentResource, parentId);
    let type = selectResource(store, 'db.types', parentData.resource_type);
    return {
        type: ['db', type.name].join('.'),
        data
    }
};

const mapDispatchToProps = {
    onChange: draftsChangeField
};

const withParentResource = (Component) => {
    class ResourceContextDecorator extends React.Component {
        render() {
            return <ResourceContext.Consumer>
                {(parentResource) => {
                    return <Component {...this.props} parentResource={parentResource} />;
                }}
            </ResourceContext.Consumer>;
        }
    }
    return ResourceContextDecorator;
};

class FieldMapping extends React.Component {

    static ownDragHandle = true;

    handleClick = (evt) => {
        this.props.onClick(this.props.id);
    };

    handleDelete = (evt) => {
        this.props.onDelete(this.props.id);
    };

    renderField = (name) => {
        const { resource, id } = this.props;
        return <Field resource={resource} id={id} fieldName={name}>{RenderListField}</Field>;
    };

    handleValue = (field, value) => {
        const { resource, id } = this.props;
        this.props.onChange(resource, id, 'value', value);
    };

    handleEdit = () => {
        this.props.onClick(this.props.id);
    };

    render() {
        const { connectDnd, type, data } = this.props;
        const { target_id, value } = data;
        return (<div className={'info-field'} style={{ display: 'flex', flexDirection: 'row' }}>
            <DragHandle connectDnd={connectDnd} />
            {this.renderField('source_id')}
            {this.renderField('target_id')}
            <Field resource={type} fieldName={target_id}>
                {(props)=><RenderListField
                    {...props}
                    value={value}
                    onChange={this.handleValue}
                />}
            </Field>
            <div style={{ display: 'flex' }}>
                <IconButton icon={'edit'} onClick={this.handleEdit} />
                <IconButton icon={'clear'} onClick={this.handleDelete} />
            </div>
        </div>);
    }

}

export default withParentResource(connect(
    mapStateToProps,
    mapDispatchToProps
)(FieldMapping));