import React from 'react';

import Collection from './Collection';
import EmbeddedCollection from './EmbeddedCollection';
import Components from 'components/fields/components/Components';

export default class CollectionField extends React.PureComponent {
    render() {
        let id = this.props.id;
        if (id === 'fields') {
            let resource = this.props.path.split('/')[0];
            if (resource === 'db.fields') return null;
            return (<Components
                {...this.props}
                resource="db.fields"
                childKey="fields"
            />)
        }
        //if (id === 'access_codes') return (<EmbeddedCollection {...this.props} />);
        return (<Collection {...this.props} />);
    }
}