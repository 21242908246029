import fonts from './font-sources';
import themeVcs from './api/theme-vcs';

let config = {
    'console.schema': {
        path: 'console/schema'
    },
    'cms.schema': {
        path: 'cms/schema'
    },
    'apartments.schema': {
        path: 'apartments/schema'
    },
    'console.website.destroy': {
        path: 'console/websites/:id/purge'
    },
    'console.website.refresh': {
        path: 'console/websites/:id/refresh'
    },
    'console.website.setup': {
        path: 'console/websites'
    },
    'console.cms.snapshots': {
        path: 'console/cms/:ctx.id/snapshots'
    },
    'console.cms.snapshots.dump': {
        path: 'console/cms/:id/dump'
    },
    'console.cms.snapshots.restore': {
        path: 'console/cms/:id/restore'
    },
    'console.domains.cert.renew': {
        path: 'console/domains/:id/renew'
    },
    'console.domains.config.reload': {
        path: 'console/domains/:id/config'
    },
    'console.domains.cache.clear': {
        path: 'console/domains/:id/cacheclear'
    },
    'console.themes.build': {
        path: 'console/themes/:id/build'
    },
    'theme.styles': {
        path: 'cms/styles'
    },
    'cms.theme.styles': {
        path: 'cms/styles'
    },
    'cms.media.directory.create': {
        path: ':app/browser/dirs'
    },
    'cms.media': {
        path: ':app/media'
    },
    'cms.browser': {
        path: ':app/browser'
    },
    'cms.media.upload': {
        path: ':app/browser/upload'
    },
    'cms.media.import': {
        path: ':app/media/import'
    },
    'cms.theme.files': {
        path: 'cms/theme/files',
        params: { v3: true }
    },
    'cms.styles.docs': {
        path: 'cms/styles/docs'
    },
    ...themeVcs,
    ...fonts
};

const entryTypes = [
    'offers',
    'rooms',
    'reviews',
    'gallery',
    'rooms_conf',
    'attractions',
    'properties',
    'posts',
    'projects',
    'routes',
    'contacts'
];

const serviceTypes = [
    'website',
    'cms',
    'internal',
    'cluster',
    'apartments',
    'db'
];

const withSubtypes = (prefix, types, config) => {
    let withEntries = {...config};
    types.map((type) => {
        let key = prefix + '.' + type;
        withEntries[key] = {
            path: prefix.replace('.', '/'),
            params: {type: type}
        };
        return withEntries;
    });
    return withEntries;
};

config = withSubtypes('console.services', serviceTypes, config);
config = withSubtypes('cms.entries', entryTypes, config);

export default config;