import React from 'react';
import {connect} from 'react-redux';
import {selectResource} from 'state/selectors/resources';
import IconButton from 'components/TooltipIconButton';
import ItemActions from "./ItemActions";
import ListItem from "components/list/ListItem";

const mapStateToProps = (store, props) => {
    return {
        data: selectResource(store, props.resource, props.id)
    }
};

const mapDispatchToProps = {};

class Contact extends React.PureComponent {

    handleCall = (evt) => {
        evt.stopPropagation();
        window.open('tel:'+this.props.data.phone);
    };

    handleEmail = (evt) => {
        evt.stopPropagation();
        window.open('mailto:'+this.props.data.email);
    };

    render() {
        const { onClick, data, resource, id, readOnly } = this.props;
        const { phone, email } = data;
        let contactActions = <React.Fragment>
            {phone ? <IconButton onClick={this.handleCall} icon={'phone'} /> : null}
            {email ? <IconButton onClick={this.handleEmail} icon={'mail_outline'} /> : null}
        </React.Fragment>;
        return (<ListItem
            actions={!readOnly ? resource : null}
            resource={resource}
            id={id}
            onClick={onClick}
            primaryText={data.name}
            secondaryActions={contactActions}
        />);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Contact);