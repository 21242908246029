import React from 'react';
import {connect} from 'react-redux';
import {selectResource} from 'state/selectors/resources';
import {selectViewId, selectTypeId} from 'state/selectors/schema';
import {draftsSubmit, listCreate, jobRequestSubmit} from 'state/actions';
import {query} from 'state/actions/route';
import PropTypes from 'prop-types';
import classNames from "classnames";
import Form from "v2/Form";
import ViewSelect from "v2/ViewSelect";
import Resource from "v2/Resource";
import RenderFormTabs from "v2/FormTabs";
import FormLayout from 'v2/form-builder/FormLayout';
import SchemaFields from 'v2/form-builder/SchemaFields';
import Button from 'components/Button';

const mapState = (store, props) => {
    let typeId = props.resource ? selectTypeId(store, props.resource, props.id) : null;
    let viewId = selectViewId(store, 'form', props.resource, props.id);
    let editable = !!store.context.editView;
    let type = selectResource(store, 'db.types', typeId);
    let viewData = selectResource(store, 'db.form-views', viewId);
    let mode = store.context.editMode ? 'form' : viewData.view_mode;
    let data = selectResource(store, props.resource, props.id);
    if (data.draft) mode = 'form';
    return {
        viewId,
        typeId,
        type,
        viewData,
        mode,
        editable,
        isPending: props.isPending || store.ui.pending[viewId] || store.ui.pending[props.id],
        formResource: editable ? 'db.form-views' : props.resource,
        formId: editable ? viewId : props.id
    }
};

const mapDispatch = {
    onSubmit: draftsSubmit,
    onSubmitJobRequest: jobRequestSubmit,
    onCreateView: listCreate,
    query
};

const schemaFieldsStyle = {
    position: 'absolute',
    backgroundColor: 'white',
    padding: '1rem 0 0 0',
    transform: 'translateX(-100%)',
    top: 0,
    bottom: 0,
    boxShadow: '0 0 20px rgba(0,0,0,0.1)',
    display: 'flex',
    width: '280px'
};

class FormView extends React.PureComponent {

    handleSubmit = () => {
        if (this.props.type.type === 'job') {
            this.props.onSubmitJobRequest(
                this.props.formResource,
                this.props.formId
            );
            this.props.query({editView: null});
            return;
        }
        this.props.onSubmit(
            this.props.formResource,
            this.props.formId,
            !this.props.editable
        );
        this.props.query({editView: null});
    };
    
    handleCancel = () => {
        
    };

    handleCreateView = () => {
        this.props.onCreateView(
            'db.form-views',
            ['db.form-views','','options'].join('.'),
            {resource: this.props.typeId, name: 'new view'}
        );
    };

    render() {
        const { resource, id, editable, isPending } = this.props;
        return (<div className={classNames({
            form: true,
            visible: !!id
        })}>
            { editable ? <div style={schemaFieldsStyle}>
                <SchemaFields resource={this.props.viewData.resource} />
            </div> : null }
            <Form
                resource={resource}
                id={id}
                dismissible={true}
                isPending={isPending}
                onSubmit={this.handleSubmit}
                onCancel={this.props.onCancel}
                headerContent={this.props.viewId ? <div>
                    <ViewSelect
                        type="form"
                        resource={resource}
                        value={this.props.viewId}
                        typeId={this.props.typeId}
                    />
                </div> : null}
            >
                { this.props.viewId ? <Resource
                    resource={resource}
                    fetchPolicy="always"
                    id={id}
                    provideContext={true}
                >
                    {(props)=><FormLayout
                        type={this.props.viewData.view_type}
                        mode={this.props.mode}
                        fieldsetResource="db.form-views"
                        fieldsetId={this.props.viewId}
                        resource={this.props.resource}
                        id={this.props.id}
                        editable={this.props.editable}
                    />}
                </Resource> : <div className="form__body">
                    There are no views available for this resource.
                    <div>
                        <Button onClick={this.handleCreateView}>Create view</Button>
                    </div>
                </div>}
            </Form>
        </div>);
    }

}

FormView.propTypes = {};

export default connect(mapState, mapDispatch)(FormView);