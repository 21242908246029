import React from 'react';
import {connect} from 'react-redux';
import {selectResource} from 'state/selectors/resources';
import Resource from "v2/Resource";
import Field, {RenderField} from "v2/Field";
import Thread from "v2/form-builder/custom-views/Thread";
import ConcernValue from "./ConcernValue";

const mapStateToProps = (store, props) => {
    return {
        data: selectResource(store, props.resource, props.id)
    }
};

const mapDispatchToProps = {};

function AuditConcernValue(props) {
    return <ConcernValue {...props} />;
    return RenderField(props);
}

class Section extends React.PureComponent {
    render() {
        const { data, rootResource, rootId } = this.props;
        return data['audit-subsections'].map(id=> {
            return <Resource resource={'db.audit-subsections'} id={id} fetchPolicy={'never'}>
                {(props)=><div className="survey-subsection">
                    <div className={"survey-subsection__header"}>
                        <div className={"survey-subsection__name"}>{props.data.name}</div>
                    </div>
                    {props.data['audit-concerns'].map(id=> {
                        return <Resource resource={'db.audit-concerns'} id={id} fetchPolicy={'never'}>
                            {(props)=><div className={'survey-question'}><div className={'survey-question__header'}>
                                <div className="survey-question__name">{props.data.name}</div>
                                <Field
                                    resource={'db.audit-concerns'}
                                    id={id}
                                    fieldName={'value'}
                                    autoSave={true}
                                    rootResource={rootResource}
                                    rootId={rootId}
                                    valueType={props.data.value_type}
                                >
                                    {AuditConcernValue}
                                </Field>
                                </div>
                                { props.data.value === -1 ? <div className={'survey-question__notes'}>
                                    <Thread
                                        resource={'db.audit-concerns'}
                                        id={id}
                                        rootResource={rootResource}
                                        rootId={rootId}
                                    />
                                    {/*<Field
                                        resource={'db.audit-concerns'}
                                        id={id}
                                        fieldName={'comments'}
                                        autoSave={true}
                                        parentAutoSave={[rootResource,rootId].join('/')}
                                        rootResource={rootResource}
                                        rootId={rootId}
                                        readOnly={false}
                                    >
                                        {RenderField}
                                    </Field>*/}
                                </div>: null }
                            </div>}
                        </Resource>
                    })}
                    {/**<Field resource={'db.audit-subsections'} id={id} fieldName={'comments'}>{RenderField}</Field>**/}
                </div>}
            </Resource>
        })
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Section);