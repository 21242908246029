import React from 'react';
import {connect} from 'react-redux';

import filterTypes from 'components/list/filters';

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

class Filter extends React.PureComponent {
    
    render() {
        const { type } = this.props;
        const FilterComponent = filterTypes[type] || filterTypes.default;
        return (<FilterComponent {...this.props} />);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Filter);