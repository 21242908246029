import React from 'react';
import PropTypes from 'prop-types';
import CheckboxField from 'components/fields/checkbox/CheckboxField';

export default class Checkbox extends React.Component {

    render() {
        const { id, value, onSetFilter, label } = this.props;
        return (<div>
            <CheckboxField
                id={id}
                label={label}
                value={value}
                onChange={onSetFilter}
            />
        </div>);
    }

}

Checkbox.propTypes = {};