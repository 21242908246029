import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {handleUploadDirect} from "state/actions/files";
import UserInfo from 'components/list/fields/UserInfo';
import UserAvatar from 'components/list/fields/UserAvatar';
import DateDiff from 'components/list/fields/DateDiff';

import 'css/notes.css';

import TextField from 'components/fields/text/TextField';
import IconButton from "components/TooltipIconButton";
import UploadArea from 'containers/Upload';
import UploadButton from "components/UploadButton";
import Field,{RenderField} from 'v2/Field';

const mapState = (store, props) => {
    return {};
};

const mapDispatch = {
    onUpload: handleUploadDirect
};

class Note extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            showAttachments: false,
            edit: false
        }
    }

    handleChange = (key, value) => {
        this.props.onChange(key, value);
    };

    toggleAttachments = () => {
        this.setState({showAttachments: !this.state.showAttachments});
    };

    handleSubmit = () => {
        this.props.onSubmit();
    };

    handleUpload = (files) => {
        const { resource, id } = this.props;
        const mediaResource = 'db.media';
        files.map((file)=>this.props.onUpload(
            mediaResource,
            [resource,id,'media'],
            file
        ));
    };

    handleToggleEdit = () => {
        if (this.state.edit) this.handleSubmit()
        this.setState({edit: !this.state.edit});
    };

    render() {
        const { id, data, onSubmit, onDelete } = this.props;
        const readOnly = !this.state.edit;
        const { media } = data;
        const attachments = media ? media.length : 0;
        return (<div className="note">
            { attachments || this.state.showAttachments ? <Field
                resource={this.props.resource}
                id={this.props.id}
                fieldName="media"
                autoSave={true}
                readOnly={readOnly}
            >
                {(props)=><RenderField {...props} readOnly={readOnly} />}
            </Field> : null }
            <div className="note__footer">
                {data.created_by ? <div>
                    <UserAvatar id={data.created_by} />
                </div> : null}
                <div style={{ flex: 1, marginLeft: '1rem' }}>
                    { readOnly ? data.content : <TextField
                        value={data.content}
                        label="Wpisz treść..."
                        id="content"
                        onChange={this.handleChange}
                        variant="inline"
                        className="note-content"
                        onBlur={this.handleSubmit}
                    /> }
                    <div className="author-info">
                        <div><DateDiff value={data.updated_at} /></div>
                    </div>
                </div>
                { !readOnly ? <div>
                    { onDelete ? <IconButton size="small" icon="delete" onClick={onDelete} /> : null }
                    {/**<IconButton size="small" badge={attachments} icon="attach_file" onClick={this.toggleAttachments}/>**/}
                    <IconButton size="small" icon="check" onClick={this.handleToggleEdit} />
                    <UploadButton onUpload={this.handleUpload} trigger={<IconButton icon={'cloud_upload'} />} />
                </div> : <div>
                    <IconButton size="small" icon="edit" onClick={this.handleToggleEdit} />
                    <UploadButton onUpload={this.handleUpload} trigger={<IconButton icon={'cloud_upload'} />} />
                </div>}
            </div>
        </div>);
    }

}

Note.propTypes = {};

export default connect(mapState, mapDispatch)(Note);