import React from 'react';
import PropTypes from 'prop-types';
import Icon from "@material-ui/core/Icon";

class Directory extends React.PureComponent {
    render() {
        const { current, dir, onClick } = this.props;
        const isOpen = current && current.indexOf(dir.id) === 0;
        let className = "media-dir";
        let children = dir.children
            ? dir.children.length
            : null;
        if (current === dir.id) className += ' active';
        if (children) className += ' has-children';
        return <div>
            <div className={className}
                 onClick={()=>onClick(dir.id)}>
                {children ? <Icon>
                    {isOpen
                        ? 'keyboard_arrow_down'
                        : 'keyboard_arrow_right'}
                </Icon> : null }
                <Icon>{isOpen ? 'folder_open' : 'folder'}</Icon>
                <div>{dir.name}</div>
            </div>
            { dir.children && isOpen ? <div style={{ paddingLeft: '30px' }}><DirectoryList
                dirs={dir.children}
                current={current}
                onChangeDirectory={onClick}
            /></div> : null }
        </div>
    }
}

class DirectoryList extends React.PureComponent {
    render() {
        const { dirs, current, onChangeDirectory } = this.props;
        return (<div>
            {dirs.map((dir)=><Directory
                key={dir.id}
                onClick={onChangeDirectory}
                dir={dir}
                current={current}
            />)}
        </div>);
    }
}

export default class DirectoryTree extends React.PureComponent {

    render() {
        const { data, current, onChangeDirectory } = this.props;
        return <DirectoryList
            dirs={data}
            onChangeDirectory={onChangeDirectory}
            current={current} />;
    }

}

DirectoryTree.propTypes = {};