import React from 'react';
import {connect} from 'react-redux';
import TreeView from "containers/TreeView";
import TreeNode from "./TreeNode";

import {query} from "state/actions/route";
import {
    uiTreeExpand
} from "state/actions/ui";

import {
    resourcePathDetach,
    resourcePathCreate,
    draftsPathDetach,
    draftsChangeField
} from "state/actions";

const mapStateToProps = (store, props) => {
    return {
        selectedItem: props.selectedItem || store.context.cp
    }
};

const mapDispatchToProps = {
    query,
    create: resourcePathCreate,
    remove: draftsPathDetach,
    onChangeField: draftsChangeField,
    onExpand: uiTreeExpand
};

class ComponentsTree extends React.PureComponent {

    handleEditItem = (item) => {
        if (item === this.props.selectedItem) {
            this.props.onExpand(item, true);
        }
        if (this.props.onClick) {
            this.props.onClick(item);
            return;
        }
        this.props.query({cp: item, s: null});
    };

    handleTreeUpdate = (tree) => {
        this.props.updateTree(
            this.props.path.split('/'),
            tree.children
        );
    };

    handleDelete = (parent, id) => {
        let path = parent
            ? [this.props.resource, parent, this.props.childKey]
            : this.props.path.split('/');
        this.props.remove(path, id);
    };

    handleChangeItem = (id, key, value) => {
        this.props.onChangeField(
            this.props.resource,
            id,
            key,
            value
        );
    };

    renderTreeNode = (node) => {
        return (<TreeNode
            key={node.id}
            resource={this.props.resource}
            storeId={this.props.storeId}
            id={node.id}
            isSelected={node.id===this.props.selectedItem}
            onClick={this.handleEditItem}
            onEdit={this.props.onEdit || this.handleEditItem}
            onDelete={this.handleDelete}
            onChange={this.handleChangeItem}
            node={node}
        />);
    };

    render() {
        return (<TreeView
            path={this.props.path}
            renderNode={this.renderTreeNode}
            storeId={this.props.storeId}
            resource={this.props.resource}
            childKey={this.props.childKey}
        />);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ComponentsTree);