import { selectPath } from 'state/selectors/resources';
import {selectSettings,canonicalDomain} from "state/selectors/app";

const selectSeoTitle = (seoTitle, pageTitle, settings, lang) => {

    if (!settings || !settings.contact) return null;

    let titleFormat = settings.seo_title_default_template
        || settings.default_seo_title;

    let titleTemplate = settings.seo_title_template
        || settings.default_seo_title;

    let name = settings.contact.name;
    let city = settings.contact.address.city;

    let vars = {
        name,
        city,
        lang,
        title: seoTitle || pageTitle
    };

    let format = replaceVars(titleFormat, vars);
    let title = replaceVars(titleTemplate, {...vars, title: format});

    return title;
};

const replaceVars = (template, vars) => {
    let parsed = template;
    Object.keys(vars).forEach((k) => {
        parsed = parsed.replace('{'+k+'}', vars[k]);
    });
    return parsed;
};

export const selectSeo = (store, props) => {
    let domain = canonicalDomain(store, props).hostname;
    let pageTitle = selectPath(store, [props.path, 'title'].join('/').split('/'));
    let seo = selectPath(store, [props.path,props.id].join('/').split('/')) || {};
    let slug = selectPath(store, [props.path, 'slug'].join('/').split('/'));
    let normalizedSlug = slug === 'index' ? '' : slug;
    let settings = selectSettings(store);
    let title = selectSeoTitle(seo.title, pageTitle, settings, store.context.lang);

    return {
        title: title,
        description: seo ? seo.description : null,
        url: [domain,normalizedSlug].join('/'),
    }
};