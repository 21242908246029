import React from 'react';
import {connect} from 'react-redux';
import {draftsChangeField,draftsSubmit,draftsCancel,resourceAction} from 'state/actions';
import {extractUrl} from 'state/actions/cms';
import {query,push} from 'state/actions/route';
import DateDiff from "components/list/fields/DateDiff";
import Field, {RenderField} from 'v2/Field';
import Resource from 'v2/Resource';
import {RenderValue} from 'v2/list-items';
import ItemActions from 'v2/items/ItemActions';
import Button from 'components/Button';
import IconButton from 'components/TooltipIconButton';
import ContentEditor from 'ide/ui/Edit';
import {selectResource} from 'state/selectors/resources';
import Card from '@material-ui/core/Card';
import LinkCard from 'v2/LinkCard';
import Identity from 'v2/components/Identity';
import _ from 'lodash';

const mapStateToProps = (store, props) => {
    let data = selectResource(store, props.resource, props.id);
    let parent = data.parent; //selectResource(store, 'db.nodes', data.parent);
    let identity = data.identity; //selectResource(store, 'db.identities', data.identity);
    let type = selectResource(store, 'db.types', data.subtype);
    return {
        data,
        parent,
        type,
        identity,
        owned: data.created_by === store.context.user,
        context: store.context
    }
};

const mapDispatchToProps = {
    onShowNode: push,
    onChange: draftsChangeField,
    onActionRequest: resourceAction,
    onSubmit: draftsSubmit,
    onCancel: draftsCancel,
    extractUrl,
    query
};

const postStyle = {
    borderBottom: 'solid 1px rgba(0,0,0,0.1)',
    display: 'flex',
    flexDirection: 'column'
};

class Post extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            showMedia: false,
            edit: false
        }
    }

    handleSubmit = () => {
        this.setState({edit: false});
        this.props.onSubmit(this.props.resource, this.props.id);
    };

    handleCancel = () => {
        this.setState({edit: false});
        this.props.onCancel(this.props.resource, this.props.id);
    };

    handleAction = (type, resource, id) => {
        if (type === 'edit') {
            this.setState({edit: true});
            return;
        }
        if (type === 'change') {
            this.props.query({edit: [this.props.resource,this.props.id].join('/')});
            return;
        }
        this.props.onActionRequest(type, this.props.resource, this.props.id);
    };

    extractUrl = () => {
        this.props.extractUrl(
            this.props.resource,
            this.props.id,
            'content'
        );
    };

    handleChange = (field, value) => {
        this.props.onChange(
            this.props.resource,
            this.props.id,
            field,
            value
        );
        if (field === 'content') {
            //_.debounce(this.extractUrl, 1000);
            this.extractUrl();
        }
        if (field === 'credentials') {
            this.props.onSubmit(this.props.resource, this.props.id);
        }
    };

    handleClearLink = () => {
        this.props.onChange(
            this.props.resource,
            this.props.id,
            'link',
            null
        );
    };

    handleShowNode = (id) => {
        if (!this.props.owned) {
            this.props.query({node: id});
            return;
        }
        this.props.onShowNode('/:app/:lang/:project/:view/:id',{
            ...this.props.context,
            id
        });
    };


    handleClick = (evt) => {
        this.handleAction('change', this.props.resource, this.props.id);
    };

    render() {
        const { resource, id, data, parent, identity, type, owned } = this.props;
        const { media } = data;
        const attachments = media ? media.length : 0;
        const editMode = data.draft || this.state.edit;
        const clickable = type.name === 'thread';

        return (<Card className="post" style={postStyle} onClick={clickable ? this.handleClick : null}>
                { data.content || editMode ? <div style={{ flex: 1, padding: '1rem', paddingBottom: 0}}>
                    <ContentEditor
                        placeholder="Type something..."
                        onChange={this.handleChange}
                        type="rich"
                        fieldKey="content"
                        disabled={!editMode}
                        className="post-content"
                    >
                        {data.content}
                    </ContentEditor>
                </div> : null }
                { type && type.name === 'credential'
                    ? <div>
                        <Field resource={resource} id={id} fieldName={'credentials'}>
                            {RenderField}
                        </Field>
                    </div>
                    : null
                }
                { type && type.name === 'todo-list'
                    ? <div>
                        <Field resource={resource} id={id} fieldName="tasks" card={false} autoSave={true}>
                            {RenderField}
                        </Field>
                    </div>
                    : null
                }
                { type && type.name === 'album' && (attachments || editMode) ? <Field
                    resource={resource}
                    id={id}
                    fieldName="media"
                    readOnly={!editMode}
                >
                    {RenderField}
                </Field> : null }
                { data.last_message ? <div style={{ padding: '1rem' }}><Field
                    resource={resource}
                    id={id}
                    fieldName="last_message"
                    readOnly={!editMode}
                >
                    {RenderField}
                </Field></div> : null }
                <div style={{ flex: 1, padding: '1rem', paddingTop: 0 }}>
                    { data.link ? <LinkCard
                        url={data.link}
                        onClear={this.handleClearLink}
                        readOnly={!editMode}
                    /> : null}
                    <div className="author-info" style={{ marginTop: '1rem' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            { identity ? <Identity id={identity.id} data={identity}/> : null }
                            <div style={{ marginLeft: '0.4em' }}>
                                <DateDiff value={data.updated_at} />
                            </div>
                            <div style={{ marginLeft: '0.4em' }}>
                                { parent && parent.id
                                    ? <span>
                                        <span> in </span>
                                        { editMode ? <span>
                                            <Field resource={resource} id={id} fieldName={'parent'}>
                                                {RenderValue}
                                            </Field>
                                        </span> :
                                        <span className={'link'} onClick={()=>this.handleShowNode(parent.id)}>{parent.name}</span> }
                                    </span>
                                    : null }
                            </div>
                        </div>
                    </div>
                    { editMode ? <div className="post-form-actions">
                        <Button color="default" onClick={this.handleCancel}>Cancel</Button>
                        <Button onClick={this.handleSubmit}>Submit</Button>
                    </div> : null }
                </div>
                { !editMode && owned ? <div className="post-actions">
                    <ItemActions
                        actions="db.posts" resource={resource}
                        onActionRequest={this.handleAction}
                    />
                </div> : null }
            </Card>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Post);