import React from 'react';
import {connect} from 'react-redux';
import { selectPath } from 'state/selectors/resources';

import TypographyStylesCollection from "./FontPicker/TypographyStylesCollection";

const mapStateToProps = (store, props) => {
    let presetPath = props.path.split('/').slice(0,-1);
    return {
        preset: selectPath(store, presetPath)
    }
};

class TypeStyles extends React.PureComponent {

    handleChange = (key, value) => {
        let newValue = {...this.props.preset};
        this.props.onChange(
            'typography',
            {...newValue, styles:value}
        );
    };

    render() {
        //return this.props.path;
        return (<TypographyStylesCollection
            id="styles"
            onChange={this.handleChange}
            path={this.props.path}
            preset={this.props.preset}
        />);
    }

}

export default connect(
    mapStateToProps
)(TypeStyles);