import React from 'react';
import PropTypes from 'prop-types';
import Font from './Font';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Popover from 'components/Popover';
import TypographyStyleSettings from './TypographyStyleSettings';
import TextField from 'components/fields/text/TextField';
import FormState from 'containers/FormState';
import Button from 'components/Button';

const labelStyle = {
    opacity: 0.5,
    fontSize: '0.8rem',
    paddingTop: '0.2rem',
    paddingBottom : '1rem'
};

class TypographyStyle extends React.PureComponent {

    handleChange = (...args) => {
        this.props.onChange(...args);
    };

    handleUpdateId = (value) => {
        this.props.onChange('id', value.id);
    };

    handleUpdateStyles = () => {
        setTimeout(()=>this.props.onRefresh(), 1);
    };

    render() {
        const { value, preset } = this.props;
        const weightType = value.font_weight || 'regular';
        const familyType = value.font || 'body';
        const font = preset[familyType+'_font'] || {};
        const weight = font['weight_'+weightType];

        return (<div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        }}>
            <Popover
                onClose={this.handleUpdateStyles}
                anchorOrigin={{ horizontal: 'right' }}
                trigger={(props)=><div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                    <div style={{
                        letterSpacing: value.letter_spacing,
                        lineHeight: value.line_height,
                        textTransform: value.text_transform,
                        flex: 1,
                        display: 'block',
                        borderBottom: 'solid 1px rgba(0,0,0,0.1)',
                        width: '100%',
                        cursor: 'pointer'
                    }} {...props}>
                        <Font
                            family={font.family}
                            source={font.source}
                            size={value.font_size}
                            weight={weight}
                        >Lorem ipsum dolor</Font>
                    </div>
                    <div style={labelStyle}>{value.font_size}px {value.id}</div>
                </div>}
                content={(props)=><React.Fragment>
                    <div className="form--sm type-style-settings">
                        <FormState
                            value={value}
                            onChange={this.handleUpdateId}
                        >
                            {({value, onChange, onSubmit}) => {
                                return <div style={{ display: 'flex' }}><TextField
                                    id="id"
                                    value={value.id}
                                    onChange={onChange}
                                    label="Zmień identyfikator"
                                />
                                    <Button onClick={onSubmit}>
                                        Zastosuj
                                    </Button>
                                </div>
                            }}
                        </FormState>
                    </div>
                    <TypographyStyleSettings
                        path={this.props.path}
                        onChange={this.handleChange}
                    />
                </React.Fragment>}
            />
        </div>);
    }

}

TypographyStyle.propTypes = {
    value: PropTypes.object.isRequired,
    preset: PropTypes.object.isRequired
};

export default TypographyStyle;