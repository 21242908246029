import React from 'react';
import PropTypes from 'prop-types';
import CheckboxField from 'components/fields/checkbox/CheckboxField';
import Icon from '@material-ui/core/Icon';

const checkStyle = {
    display: 'flex',
    alignItems: 'center'
};

const baseIconStyle = {
    marginRight: '0.2rem'
};

export default class Checkbox extends React.PureComponent {

    render() {
        const { value, label, variant, readOnly } = this.props;
        if (readOnly) { //variant !== 'checker'
            let iconStyle = {...baseIconStyle};
            if (!value) iconStyle.opacity = 0.5;
            if (value) iconStyle.color = '#4caf50';
            return value
                ? <div style={checkStyle}><span style={iconStyle}><Icon color={'inherit'}>check</Icon></span>yes</div>
                : <div style={checkStyle}><span style={iconStyle}><Icon color={'inherit'}>close</Icon></span>no</div>;
        }
        return (<CheckboxField
            {...this.props}
        />);
    }

}

Checkbox.propTypes = {};