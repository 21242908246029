import React from 'react';
import {connect} from 'react-redux';

import {
    resourcePathReorder,
    resourceChange,
    resourceSave,
    resourceAction
} from "state/actions";

import {
    resourceRoute
} from 'state/actions/route';

import { sectionDrag } from 'state/actions/ide';

import { isEmptyPath } from 'state/selectors/resources';

const mapDispatchToProps = {
    push: resourceRoute,
    onChangeOrder: sectionDrag,
    onChange: resourceChange,
    resourceSave: resourceSave,
    resourceAction: resourceAction
};

const mapStateToProps = (store, props) => {
    const list = store.context.view ? ['cms',store.context.view].join('.') : null;
    let relation = props.relation || store.context.relation;
    let relatedId = relation
        ? props.relatedId || store.context.relatedId
        : null;
    let id = store.context.id;
    let path = [list, store.context.id, relation];
    return {
        project: store.context.project,
        view: store.context.view,
        id: id,
        relation: relation,
        relatedId: relatedId,
        relatedResource: relation
            ? [store.context.app,relation].join('.')
            : null,
        list,
        isPending: store.ui.pending[store.context.id],
        isEmpty: isEmptyPath(store, path),
        relatedPath: path.join('/'),
        selectedItem: relatedId
    }
};

class RouteRelated extends React.PureComponent {

    constructor(props) {
        super(props);

        this.itemProps = {
            onClick: this.handleClick,
            onActionRequest: this.handleAction,
        };
    }

    handleClick = (id) => {
        this.props.push(
            this.props.list + '/section',
            {relatedId: id}
        );
    };
    
    handleAction = (...args) => {
        this.props.resourceAction(...args);
    };

    render() {
        return this.props.render({
            ...this.props,
            resource: this.props.relatedResource,
            path: this.props.relatedPath,
            selectedItem: this.props.relatedId,
            itemProps: this.itemProps
        });
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(
    RouteRelated
);