import React from 'react';
import {listFetch} from 'state/actions';
import {connect} from 'react-redux';
import Select from './Select';
import Chip from 'components/Chip';
import Resource from 'v2/Resource';
import {ConnectedRefContainer} from './Ref';

const mapStateToProps = (store, props) => {
    return {
        tags: store.data['db.tags'] || {}
    }
};

const mapDispatchToProps = {
    listFetch
};

class TagSelect extends React.PureComponent {

    componentDidMount() {
        if (!Object.values(this.props.tags).length) {
            this.props.listFetch('db.tags');
        }
    }

    render() {
        let resource = this.props.resource_type;
        let value = this.props.value || [];
        return <ConnectedRefContainer {...this.props}>
            {(props)=>{
                return <div style={{ display: 'flex' }}>
                    {value.map(id=>{
                        return <Resource resource={resource} id={id} fetchPolicy={'never'}>
                            {(tagProps)=><Chip
                                label={tagProps.data.name}
                                onDelete={()=>props.onDelete(id)}
                            />}
                        </Resource>;
                    })}
                    <div onClick={props.onAttach}>attach</div>
                    <div onClick={props.onCreate}>create</div>
                </div>
            }}
        </ConnectedRefContainer>;
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TagSelect);