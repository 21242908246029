import {
    call,
    takeEvery,
    select,
    put
} from 'redux-saga/effects';

import {draftsChangeField} from 'state/actions';

function* handleChangeField(action) {
    try {
        const {type, ...other} = action;
        yield put(draftsChangeField(
            action.resource,
            action.id,
            action.field,
            action.value
        ));
    } catch (e) {
        console.log(e);
    }
}

export default function*() {
    yield takeEvery('FORM.CHANGE_FIELD', handleChangeField);
}