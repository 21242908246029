import React from 'react';
import PropTypes from 'prop-types';
import Fieldset from 'v2/form-builder/Fieldset';
import FormLayout from "../FormLayout";

export default class Default extends React.Component {

    render() {
        return (<div className="form__body">
            <Fieldset {...this.props} />
        </div>);
    }

}

Default.propTypes = {};