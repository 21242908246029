import React from 'react';
import {connect} from 'react-redux';

import Avatar from '@material-ui/core/Avatar';

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

function UserAvatar(props) {
    const { profile } = props.data;
    return <Avatar
        size={props.size}
        src={profile ? profile.avatar : null}
    />;
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserAvatar);