import {
    call,
    takeEvery,
    put,
    select
} from 'redux-saga/effects';

import {delay} from 'redux-saga';

import { resourceFetchSuccess } from 'state/actions';
import { selectResource } from 'state/selectors/resources';
import { uiNotificationsHide } from "state/actions/ui";

import * as ui from 'util/sagas/feedback';

function* handleNotify(action) {
    try {
        console.log(action);
        const data = action.payload;
        const prev = yield select((store)=>selectResource(store, 'app.notifications', data.id));
        const task = {
            subtasks: [],
            ...prev,
            id: data.id,
            resource: data.resource,
            resource_id: data.resource_id,
            status: data.status,
            message: data.message,
            created_at: data.created_at
        };
        if (data.id !== data.task_id) {
            const subtask = {
                ...task,
                id: data.task_id,
                task_id: data.id
            };
            yield put(resourceFetchSuccess(
                'app.notifications.tasks',
                subtask.id,
                subtask
            ));
            if (task.subtasks && !(task.subtasks.indexOf(subtask.id) > -1)) {
                task.subtasks.push(subtask.id);
            }
        } else {
            yield put({type: 'UI.NOTIFICATIONS.SHOW'});
        }
        yield put(resourceFetchSuccess(
            'app.notifications',
            task.id,
            task
        ));
        if (data.id === data.task_id && task.status === 'success') {
            //yield ui.success();
            yield ui.pending(task.id, false);
            //yield delay(2000);
            //yield put(uiNotificationsHide());
        } else {
            yield ui.pending(task.id, true);
        }
    } catch (e) {
        yield ui.error(e);
    }
}

export default function*() {
    yield takeEvery('UI.EVENT.SHOW_NOTIFICATION', handleNotify);
}