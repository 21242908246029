import React from 'react';

import ApartmentsImport from 'containers/dialogs/ApartmentsImport';

export default class Dialogs extends React.PureComponent {

    render() {
        return (<React.Fragment>
            <ApartmentsImport />
        </React.Fragment>);
    }

}