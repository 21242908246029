import React from "react";

import { NativeTypes } from 'react-dnd-html5-backend';
import { DropTarget } from 'react-dnd';
import Icon from '@material-ui/core/Icon';

const fileTarget = {
  drop(props, monitor) {
    props.onDrop(monitor.getItem().files);
  }
};

function FileDropZone(props) {
  const { connectDropTarget, isOver } = props; //canDrop
  return connectDropTarget(
      <div style={{ flex: 1, position: 'relative', width: '100%', height: '100%' }}>
          {isOver ? <div className="drop-overlay">
              <Icon fontSize="inherit" color="inherit">cloud_upload</Icon>
              Drop the files
          </div> : null}
          {props.children}
      </div>
  );
}

const Dropzone = DropTarget(
    NativeTypes.FILE,
    fileTarget,
    (connect, monitor) => ({
      connectDropTarget: connect.dropTarget(),
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
)(FileDropZone);

export default class Upload extends React.Component {

  handleDrop = (accepted, rejected) => {
    if (!accepted.length) return;
    this.props.onFileUpload(accepted);
  };

  render() {

    return (
        <div className="upload-area" style={this.props.style}>
          <Dropzone onDrop={this.handleDrop}>
            {this.props.children}
          </Dropzone>
        </div>
    );
  }
}
