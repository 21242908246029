import React, {Component} from 'react';
import {connect} from 'react-redux';

import AppBarImpl from 'components/layout/AppBar';

import { currentWebsite } from 'state/selectors/app';
import { appMenuToggle, appMenuHide } from 'state/actions/ui';

const selectAppBarTitle = (store) => {
    let app = store.context.app;
    return currentWebsite(store).name || app;
};

const mapStateToProps = (store, props) => {
    return {
        showAppMenu: store.ui.showAppMenu,
        title: selectAppBarTitle(store),
        serviceType: currentWebsite(store).type,
        serviceId: currentWebsite(store).id
    }
};

const mapDispatchToProps = {
    appMenuToggle,
    appMenuHide
};

class AppBar extends Component {

    render() {
        return (<AppBarImpl {...this.props} />);
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(AppBar);