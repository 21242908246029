import React from 'react';

export function interact() {
    return (DecoratedComponent) => {
        return class Interactive extends React.PureComponent {
            constructor(props) {
                super(props);

                this.events = {
                    onMouseEnter: this.handleMouseEnter,
                    onMouseLeave: this.handleMouseLeave,
                    onClick: this.handleClick
                }
            }
            id = () => {
                return this.props.itemId || this.props.data.id;
            };

            handleMouseEnter = (evt) => {
                if (this.props.onMouseEnter) {
                    //console.log(evt);
                    this.props.onMouseEnter(evt, this.id());
                }
            };

            handleMouseLeave = (evt) => {
                if (this.props.onMouseLeave) {
                    //console.log(evt);
                    this.props.onMouseLeave(evt, this.id());
                }
            };

            handleClick = (evt) => {
                let type = this.props.data.element_type;
                console.log(type, this.props.data.id);
                if (type === 'structured' && !this.props.itemId) {
                    return;
                }
                if (this.props.onClick && this.props.itemId) {
                    this.props.onClick(evt, this.id());
                } else {
                    evt.componentId = this.props.data.id;
                }
            };

            render() {
                return (
                    <DecoratedComponent
                        {...this.props}
                        events={this.events}
                    />
                );
            }
        }
    }
}