import React from 'react';
//import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectResource } from 'state/selectors/resources';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';

import IconMenu from 'components/IconMenu';
import PresetColors from 'components/list/fields/PresetColors';

import { connectResource } from 'components/list/ResourceListItem';
import ResourceActions from "../../../components/list/ListItem";

const mapState = (store, props) => {
    if (!props.data) return {};
    const { theme, canonical_domain } = props.data;
    const themeData = selectResource(store, 'console.themes', theme);
    return {
        theme: themeData,
        preset: selectResource(store, 'console.presets', themeData.preset),
        domain: selectResource(store, 'console.domains', canonical_domain),
        presetPath: ['console.presets', themeData.preset, 'color_scheme'].join('/')
    }
};

class WebsiteCard extends React.PureComponent {

    domainLink = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const url = this.props.domain.hostname;
        window.open(url);
    };

    render() {
        const { data, presetPath, domain, theme, preset, onClick } = this.props;
        if (!data) return null;

        return (<Card
            onClick={onClick}
            className="resource-card"
        >
            <CardContent style={{ flex: 1 }}>
                <div style={{ flex: 1, display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        <Typography variant={"h6"}>{data.name}</Typography>
                        <Typography color="textSecondary">
                            {theme.slug}
                        </Typography>
                        { domain.name ?
                            <div className="link card-link card-link--sm" onClick={this.domainLink}>
                                <Typography>{domain.name}</Typography>
                                <Icon className="link__icon" fontSize="inherit">open_in_new</Icon>
                            </div>
                         : null }
                    </div>
                    <div>
                        <IconMenu>
                            <ResourceActions
                                resource={this.props.resource}
                                id={this.props.id}
                            />
                        </IconMenu>
                    </div>
                </div>
            </CardContent>
            { theme && preset ? <PresetColors path={presetPath} /> : null }
        </Card>);
    }

}

WebsiteCard.propTypes = {};

export default connect(mapState)(WebsiteCard);

/*export default connectResource()(
    connect(mapState)(WebsiteCard)
);*/