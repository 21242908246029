import React from 'react';
//import PropTypes from 'prop-types';

import LocalState from "./LocalState";
import {DropAreaConnector} from "./dnd";

import {makeIterator, ArrayIterator, DefaultArrayIterator, Item} from './Iterator';
import {RenderResourceListItem} from 'components/list/ResourceListItem';
import {RenderListWrapper} from "components/list/ListWrapper";

import {SortableItem} from './Sortable';
import {VirtualizedGridIterator, VirtualizedListIterator} from './Virtualized';
import {GraphIterator} from './Graph';

const Virtualized = makeIterator(VirtualizedGridIterator)(SortableItem);
const VirtualizedList = makeIterator(VirtualizedListIterator)(SortableItem);
const Sortable = makeIterator(ArrayIterator)(SortableItem);
const Graph = makeIterator(GraphIterator)(Item);

export default class List extends React.Component {
    renderItem = (props) => {

    };
    renderList = (props) => {
        const {
            id,
            isPending,
            renderItem,
            renderList,
            resource,
            view,
            filterId,
            selectedItem,
            readOnly
        } = this.props;

        let variant = this.props.variant;
        if (readOnly) variant = 'default';

        const {
            data,
            itemHandlers,
            listHandlers,
            ...other
        } = props;

        const render = renderItem || this.props.children || RenderResourceListItem;
        const wrapper = renderList || RenderListWrapper;

        let Iterator = DefaultArrayIterator;

        switch (variant) {
            case 'virtualized-list': Iterator = VirtualizedList; break;
            case 'virtualized': Iterator = Virtualized; break;
            case 'sortable': Iterator =  Sortable; break;
            case 'graph': Iterator = Graph; break;
            case 'stream': Iterator = VirtualizedList; break;
            default: Iterator = DefaultArrayIterator; break;
        }

        const items = <Iterator
            listId={id}
            data={data}
            resource={resource}
            itemHandlers={itemHandlers}
            view={view}
            renderItem={render}
        />;

        const listProps = {
            isPending,
            filterId,
            filterLayout: this.props.config
                ? this.props.config.filterLayout
                : null,
            isEmpty: data.length === 0 && !isPending,
            layout: this.props.config
                ? this.props.config.layout
                : null,
            context: this.props.context
        };

        if (wrapper) return <DropAreaConnector {...listHandlers}>
            {(props)=>{
                return wrapper({
                    id,
                    variant,
                    ...props,
                    ...other,
                    ...listProps,
                    readOnly,
                    resource,
                    items
                })
            }}
        </DropAreaConnector>;
        return items;
    };

    render() {
        const { data, ...other } = this.props;

        return (<LocalState data={data} {...other}>
            {this.renderList}
        </LocalState>);
    }
}

List.propTypes = {};