import React from 'react';
import {connect} from 'react-redux';

import {selectResource} from 'state/selectors/resources';
import {selectFieldSchema} from "state/selectors/schema";
import {selectFilter} from "state/selectors/filter";
import {RenderField} from 'v2/Field';
import Graph from 'v2/Graph';

const mapStateToProps = (store, props) => {
    let data = selectResource(store, 'db.filters_config', props.id);
    let key = data.key;
    let filters = selectFilter(store, props.filterId);
    let schema = selectFieldSchema(
        store,
        props.resource,
        null,
        null,
        key
    );
    schema = {...schema};
    if (!schema.properties) schema.properties = {};
    if (!schema.id) {
        schema = {
            slug: data.key,
            label: data.label,
            type: data.type,
            properties: {
                source: data.source
            }
        }
    }
    schema.properties = {...schema.properties};
    if (data.type) schema.type = data.type;
    if (schema.type === 'text') {
        schema.properties.variant = 'search';
    }
    if (schema.type === 'select') {
        schema.type = 'select';
        schema.properties.variant = 'radio';
        schema.properties.multiple = true;
        if (data.source) schema.properties.source = data.source;
    }
    if (schema.type === 'chip') {
        schema.type = 'select';
        schema.properties.variant = 'chip';
        schema.properties.radio = true;
        schema.properties.multiple = true;
        if (data.source) schema.properties.source = data.source;
    }
    return {
        id: key,
        value: filters[key],
        data,
        schema
    }
};

const mapDispatchToProps = {};

class Filter extends React.PureComponent {

    handleSetFilter = (id, value) => {
        this.props.onSetFilter(id, value);
    };

    render() {
        const { id, data } = this.props;
        if (this.props.mobile && !data.mobile) return null;
        if (this.props.schema.type === 'graph') {
            return (<Graph
                onClick={this.handleSetFilter}
                resource={this.props.schema.properties.resource_type}
                schema={this.props.schema}
            />);
        }
        return RenderField({...this.props, onChange: this.handleSetFilter});
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Filter);