import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import {icon as renderIcon} from 'util/icon';

export default class InlineField extends React.PureComponent {

    render() {
        const { onClick, value, label, icon } = this.props;

        return (<div onClick={onClick} className="inline-field-base">
            { icon ? <div className="inline-field-icon">{renderIcon(icon, "inherit")}</div> : null }
            <div className="inline-field">
                { label ? <Typography variant="overline" style={{
                    lineHeight: '1em',
                    paddingBottom: '0.6rem'
                }}>
                    {label}
                </Typography> : null }
                <div className="inline-field-value">{value}</div>
            </div>
        </div>)
    }

}


InlineField.propTypes = {};