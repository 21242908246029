import React from 'react';
import {connect} from 'react-redux';
import {resourceChangeField} from 'state/actions';
import {query} from 'state/actions/route';
import {selectResource} from 'state/selectors/resources';
import EditableField from 'v2/form-builder/EditableField';

import FormField from 'v2/form-builder/FormField';
import Layout from 'v2/form-builder/Layout';
import Tab from 'v2/form-builder/Tab';
import Tabs from 'v2/form-builder/Tabs';
import Subview from 'v2/form-builder/Subview';
import CustomView from 'v2/form-builder/CustomView';

import {FieldTypePlaceholder} from './FormField';

const types = {
    'layout': Layout,
    'custom-view': CustomView,
    'field': FormField,
    'tab': Tab,
    'tabs': Tabs,
    'subview': Subview
};

const mapStateToProps = (store, props) => {
    return {
        isDraft: selectResource(store, props.resource, props.id).draft,
        data: selectResource(
            store,
            props.fieldResource,
            props.fieldId
        )
    }
};

const mapDispatchToProps = {
    onChange: resourceChangeField,
    query
};

class Field extends React.PureComponent {

    handleShowViewFieldSettings = () => {
        this.props.query({edit:[
            this.props.fieldResource,
            this.props.fieldId
        ].join('/')});
    };

    handleChange = (key, value) => {
        this.props.onChange(
            this.props.fieldResource,
            this.props.fieldId,
            key,
            value
        );
    };

    renderField = () => {
        const { data, resource, id } = this.props;
        const { type } = data;
        let FieldType = types[type];
        if (!FieldType) return <FieldTypePlaceholder />;
        return <FieldType
            {...this.props}
            onChange={this.handleChange}
            onSettings={this.handleShowViewFieldSettings}
        />;
    };

    render() {
        const { data, isDraft } = this.props;
        const { type } = data;
        const targetState = this.props.data.form_state;
        if (targetState === 'edit' && isDraft) return null;
        if (targetState === 'add' && !isDraft) return null;
        const field = this.renderField();
        if (!this.props.editable) return field;
        if (type === 'tab') return field;

        return <EditableField {...this.props}>
            {field}
        </EditableField>;
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Field);