import React from 'react';
import ConnectedComponent from 'ide/Component';
import DataContext, {MergeDataContext} from 'ide/DataContext';

export default class ConnectTemplate extends React.PureComponent {

    render() {
        const { data } = this.props;
        const templateId = data.template_component;
        const path = 'cms.components/' + templateId;
        return (<MergeDataContext value={path}>
            <ConnectedComponent
                {...this.props}
                id={templateId}
            />
        </MergeDataContext>);
    }

}