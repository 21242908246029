import React from 'react';
import {connect} from 'react-redux';
import Components from './Components';
import StyleEditor from 'ide/style-editor/Editor';
import {draftsChange} from "../../../state/actions";
import { selectResource } from 'state/selectors/resources';

import _ from "lodash";

const mapComponentType = (type) => {
    if (!type) return 'section';
    if (type === 'structured') return 'layout';
    return type;
};

const mapStateToProps = (store, props) => {
    let componentId = store.context.cp;

    let sectionId = store.context.sc;
    let component = !componentId || componentId === 'root'
        ? selectResource(store, 'cms.sections', sectionId)
        : selectResource(store, 'cms.components', componentId);

    let styleId = component ? component.style: null;
    if (!styleId && component) styleId = mapComponentType(component.element_type);
    let style = selectResource(store, 'theme.styles', styleId);
    return {
        styles: store.data['theme.styles'],
        style,
        styleId,
        codeEditMode: !!store.context.code
    }
};

const mapDispatchToProps = {
    onChange: draftsChange
};

const editorProperties = {
    format: 'json'
};


class Styles extends React.PureComponent {

    handleEdit = (key, value) => {
        console.log(value);
        let update = {};
        update[this.props.styleId] = value;
        this.props.onChange(['theme.styles'], update);
    };

    debounced = _.debounce(this.handleEdit, 1000);

    render() {
        const { styleId, style, codeEditMode } = this.props;
        const id = this.props.id || "code";
        const rootId = id + '.' + styleId;
        if (codeEditMode) return <StyleEditor id={styleId} />;
        return (<React.Fragment>
            <div className="sidepanel-area-header">structure</div>
            <div className="sidepanel-area">
                <Components />
            </div>
            {styleId ? (
                <React.Fragment>
                    <div className="sidepanel-area-header">style</div>
                    <div className="sidepanel-area">
                        <StyleEditor id={styleId} />
                    </div>
                </React.Fragment>
            ) : null }
        </React.Fragment>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Styles);