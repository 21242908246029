import global from './global';
import dev from './dev';
import form from './form';
import ide from './ide';

export default {
    global,
    dev,
    form,
    ide
};