import React from 'react';
import PropTypes from 'prop-types';
import FormField from "containers/FormField";

import classNames from 'classnames';

export default class LayoutField extends React.Component {

    field = (field) => {
        const { id, inline, schemaPath, fields, parentPath } = this.props;
        if (!fields[field]) return null;
        let fieldSchemaPath = schemaPath.split('/').concat([id, 'fields']).join('/');
        let variant = inline ? 'inline' : null;
        return (<FormField
            parentPath={parentPath}
            schemaPath={fieldSchemaPath}
            id={field}
            key={field}
            variant={variant}
        />);
    };

    render() {
        const { fields } = this.props.schema;
        const { layout } = this.props;
        return (<div className={classNames([
            'form-layout',
            'form-layout--' + (layout || 'cols-flex')
        ])}>
            {Object.keys(fields).map((field) => {
                return this.field(field);
            })}
        </div>);
    }

}

LayoutField.propTypes = {};