import React from 'react';
import {connect} from 'react-redux';

import NavImpl from 'ide/components/Nav';

import { selectPath, selectList } from 'state/selectors/resources';

const fakeItems = [
    {
        label: "Link1"
    },
    {
        label: "Link2"
    },
    {
        label: "Link3"
    }
];

const mapStateToProps = (store, props) => {
    const navId = props.data.navigation
        ? props.data.navigation.id
        : null;

    const { navType, navPath } = props;
    const type = navType || (navId ? 'cms.nav-link' : null);

    let ids = [];

    if (type === 'cms.nav-link') ids = selectPath(store, ['cms.navigation', navId, 'links']) || [];
    if (type === 'cms.tags') ids = store.lists[navPath] || [];

    const links = type
        ? selectList(store, type, ids)
        : null;

    return {
        items: props.items || links || fakeItems,
    }
};

const mapDispatchToProps = {};

class Nav extends React.PureComponent {

    render() {
        return (<NavImpl {...this.props} />);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Nav);