import {currentUser} from 'state/selectors/app';
import {selectResource} from 'state/selectors/resources';
import {selectNamespacedResources} from "state/selectors/resources";
import _ from 'lodash';
import {selectTypeId} from "./schema";

const userScopes = (store, props) => {
    let user = currentUser(store);
    if (!props.strict && user.scopes.indexOf('master') > -1) {
        let settings = selectResource(store, 'console.user_settings', 'current');
        if (settings.scopes && settings.scopes.length) {
            return settings.scopes;
        }
    }
    return user.scopes;
};

export const hasAccess = (store, scopes, strict) => {
    let user = userScopes(store, {strict});
    let grant =
        user.indexOf('master') > -1
        || !scopes
        || user.indexOf(scopes) > -1;
    return grant;
};

export const appsAccess = (store, apps) => {
    return apps.filter(app=>{
        let appId = app.id.split('.')[1];
        return hasAccess(store, 'app_'+appId)
            || hasAccess(store, 'apps.'+appId);
    })
};

export const selectUserApps = (store) => {
    return appsAccess(store, selectNamespacedResources(store, {}, 'apps'));
};

export const hasResourceAccess = (store, resource) => {
    let user = currentUser(store);
    let access = hasAccess(store, resource.access_level);
    if (!user.app_access_profile) return access;
    let group = user.app_access_profile.access_group;
    if (group) {
        if (!resource.schema) return false;
        return access && group.resources.indexOf(resource.schema.uuid) > -1;
    }
    return access;
};

export const selectCurrentApp = (store) => {
    let route = ['apps', store.context.app].join('.');
    let app = store.ui.flags.appNavTab || route;
    let access = selectUserApps(store).map(app=>app.id);
    if (access.indexOf(app) > -1) return app;
    return access[0];
};

function match(policy, rule) {
    return rule === '*' || policy === rule;
}

export const validatePolicy = (store, policy) => {
    const user = currentUser(store);
    const rules = _.get(store, ['data', 'app.users', user.id, 'app_access_profile', 'access_group', 'rules']);
    if (!rules) return true;
    const [path, resource, action] = policy.split(':');
    //const typeId = selectTypeId(store, resource);
    const typeId = resource;
    let result = false;
    rules.forEach(rule=> {
        let [rPath, rResource, rAction, effect] = rule.split(':');
        let ruleMatch = match(path, rPath) && match(typeId, rResource)
            && match(action, rAction);
        if (ruleMatch) {
            result = effect === 'ALLOW';
        }
    });
    return result;
};