import React from 'react';
import PropTypes from 'prop-types';
import MediaAvatar from "components/list/avatar/Media";
import CheckAvatar from "components/list/avatar/Check";

function RenderAvatar({value,schema,onChange}) {
    let type = schema.type;
    if (type === 'media') return <MediaAvatar isActive={true} data={{media:value}} />;
    if (type === 'checkbox') return <CheckAvatar
        id={schema.slug}
        isActive={value}
        onChange={onChange}
    />;
    return null;
}

export default class Avatar extends React.PureComponent {

    render() {
        return (<RenderAvatar {...this.props} />);
    }

}

Avatar.propTypes = {};