import React, {Component} from 'react';
import {connect} from 'react-redux';

import Section from 'ide/containers/Section';

import { selectLayoutPlaceholders } from 'state/selectors/ide';
import { selectResource } from 'state/selectors/resources';

const mapStateToProps = (store, props) => {
    let placeholders = selectLayoutPlaceholders(store);
    let layout = selectResource(store, 'cms.layouts', props.id);
    return {
        data: layout,
        sections: layout ? layout.sections : props.sections,
        placeholders: placeholders
    }
};

const mapDispatchToProps = {};

class Layout extends React.Component {

    componentDidUpdate() {
        console.log('update layout', this.props.data.description);
    }

    render() {
        const { data, placeholders, s } = this.props;
        const sections = data.sections || [];

        //if (!data) return null;

        if (data && data.layout) {
            return (<ConnectedLayout id={data.layout} s={s}>
                { sections.map((id, index) => {
                    return placeholders.indexOf(id) > -1
                        ? this.props.children
                        : <Section
                            id={id}
                            key={id}
                            index={index}
                            s={s}
                            layout={this.props.id}
                        />;
                } ) }
            </ConnectedLayout>)
        }
        return (<React.Fragment>
            { sections.map((id, index) => {
                return !this.props.id || placeholders.indexOf(id) > -1
                    ? this.props.children
                    : <Section
                        id={id}
                        key={id}
                        index={index}
                        s={s}
                        layout={this.props.id}
                    />;
            } ) }
        </React.Fragment>);
    }

}

const ConnectedLayout = connect(mapStateToProps, mapDispatchToProps)(Layout);
export default ConnectedLayout;