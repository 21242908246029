import React from 'react';
import {connect} from 'react-redux';

import Form from 'components/form/Form';
import FormStyleContext from 'v2/FormStyleContext';

import {
    resourceFetch,
    draftsSubmit,
    draftsCancel
} from "state/actions";

const mapStateToProps = (store, props) => {
    return {
        type: props.resource
    }
};

const mapDispatchToProps = {
    submit: draftsSubmit,
    cancel: draftsCancel
};

class FormContainer extends React.Component {

    handleSubmit = () => {
        const submit = this.props.onSubmit || this.props.submit;
        submit(this.props.type, this.props.id);
    };

    handleSubmitDismiss = () => {
        const submit = this.props.onSubmitDismiss || this.props.onSubmit || this.props.submit;
        submit(this.props.type, this.props.id, true);
    };

    handleCancel = () => {
        const cancel = this.props.onCancel || this.props.cancel;
        cancel(this.props.type, this.props.id);
    };
    
    render() {
        let formStyle = this.props.resource === 'db.tasks'
            ? 'default'
            : 'labeled';

        return (<FormStyleContext.Provider value={formStyle}>
            <Form
                {...this.props}
                onSubmit={this.handleSubmit}
                onSubmitDismiss={this.props.onSubmitDismiss ? this.handleSubmitDismiss : null}
                onCancel={this.handleCancel}
            />
        </FormStyleContext.Provider>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormContainer);