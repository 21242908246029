import React from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames';

import Element from './Element';
import {
    draftsChange,
    draftsPathDelete
} from "state/actions";

import {query} from 'state/actions/route';
import {selectStyles} from 'state/selectors/styles';
import {selectResource,selectResourcePath} from 'state/selectors/resources';

import IconButton from 'components/TooltipIconButton';
import Popover from 'components/Popover';

import AddElement from './popovers/AddElement';
import ChangeStyle from './popovers/ChangeStyle';
import FindStyle from './popovers/FindStyle';
import CreateStyle from './popovers/CreateStyle'
import FindProperty from './popovers/FindProperty';
import PropertyGroup from './PropertyGroup';
import ElementForm from './ElementForm';

import CodeEditor from 'components/fields/code-editor/CodeEditor';

const nullObj = {};
const nullArr = [];

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

const selectStyleInheritance = (styles, id) => {
    let parts = id.split('.');
    let element = parts[0];
    let path = [];
    if (element !== id) path.push(element);
    let style = styles[id];
    if (style && style['@extends']) {
        path.push(style['@extends'][0].replace(':', '.'));
    }
    path.push(id);
    return path;
};

const mapStateToProps = (store, props) => {
    const id = store.context.s || props.id;
    const parts = id.split('.');
    const ns = parts[0];
    const styles = selectStyles(store);

    let resourcePath = store.context.cp && store.context.cp !== 'root'
        ? ['cms.components', store.context.cp]
        : ['cms.sections', store.context.sc || store.context.relatedId];

    const inlineMode = !store.context.s;

    let stylePath = inlineMode
        ? resourcePath.concat('styles')
        : ['theme.styles', id];

    let style = selectResourcePath(store, stylePath);
    let elements = selectResourcePath(store, stylePath);

    if (!style) style = nullObj;
    if (!elements) elements = nullObj;

    elements = Object.keys(elements).filter(onlyUnique);

    let nsStyles = ns ? styles[ns] : {};

    let ownElements = nsStyles ? elements.concat(Object.keys(nsStyles)) : elements;
    ownElements = ownElements.filter(onlyUnique);
    ownElements = _.difference(ownElements, elements);

    let path = selectStyleInheritance(styles, props.id);

    return {
        elements,
        ownElements,
        path,
        resourcePath,
        stylePath,
        inlineMode,
        ns,
        id,
        assignedId: props.id,
        styles: styles,
        optionGroups: styles ? styles.options : null,
        style: style,
        valueTypes: selectResource(store, 'theme.styles', 'option_types'),
        codeEditMode: !!store.context.code
    }
};

const mapDispatchToProps = {
    onChange: draftsChange,
    onDelete: draftsPathDelete,
    query
};

class Editor extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            open: null
        };
    }

    handleTogglePanel = id => event => {
        if (id === this.state.open) {
            this.setState({open: false});
        } else {
            this.setState({open: id});
        }
    };

    handleChange = (e, value) => {
        let id = this.props.id;
        value = value.filter(v=>!!v);
        let update = {};
        update[e] = value;
        let path = this.props.stylePath; //['theme.styles', id];
        this.props.onChange(path, update);
    };

    handleEdit = (id, value) => {
        let path = this.props.stylePath.slice(0,-1);
      //let path = ['theme.styles'];
        let update = {};
        update[this.props.id] = value;
        this.props.onChange(path, update);
    };

    handleClear = (e) => {
        let id = this.props.id;
        this.props.onDelete([
            'theme.styles',
            id,
            e
        ]);
    };

    handleDefineElement = (e) => {
        this.handleChange(e, []);
        this.setState({open: e});
    };

    handleShowStyle = (e) => {
        if (e === this.props.assignedId) {
            this.props.query({s:e});
        } else {
            this.props.query({s:e});
        }
    };

    handleToggleInherit = (e) => {
        let prev = this.props.style[e];
        let next = prev.slice();
        if (next.indexOf('@all') > -1) {
            next.splice(next.indexOf('@all'), 1);
        } else {
            next.push('@all');
        }
        let update = {};
        update[e] = next;
        this.props.onChange([
            'theme.styles',
            this.props.id
        ], update);
    };

    handleToggleCodeEditor = () => {
        if (this.props.codeEditMode) {
            this.props.query({code: null});
        } else {
            this.props.query({code: true});
        }
    };

    handleCreateStyle = (id, assign, clone) => {
        let validate = id.split('.');
        if (!validate[1]) return;
        let update = {};
        if (clone) update = {...this.props.style};
        this.props.onChange(['theme.styles', id], update);
        if (assign) {
            this.props.onChange(
                this.props.resourcePath,
                { style: id }
            );
        }
        this.handleShowStyle(id);
    };

    render() {
        const {
            path,
            styles,
            elements,
            ownElements,
            style,
            assignedId,
            id,
            ns,
            valueTypes,
            inlineMode
        } = this.props;

        if (!styles) return null;

        const editorMode = this.props.codeEditMode ? 'code' : 'props';
        const withinPath = path.indexOf(id) > -1;

        return (<div className="se-root">
            {withinPath ? <div className="se-header">
                <ChangeStyle
                    ns={ns}
                    value={id}
                    trigger={(props)=><div
                        {...props}
                        className="se-style-select"
                    >{assignedId}</div>}
                />
                <div className="se-header-icons">
                    <IconButton
                        icon="code"
                        onClick={this.handleToggleCodeEditor}
                        active={this.props.codeEditMode}
                        tooltip="Code editor"
                    />
                    <FindStyle
                        trigger={(props)=><IconButton
                            {...props}
                            icon="search"
                            active={!withinPath}
                            tooltip="Find style"
                        />}
                        onChange={this.handleShowStyle}
                    />
                    <Popover
                        trigger={(props)=><IconButton
                            {...props}
                            icon="create"
                            tooltip="Create new style"
                        />}
                        content={(props)=><CreateStyle
                            {...props}
                            ns={ns}
                            prev={id}
                            onSubmit={this.handleCreateStyle}
                        />}
                    />
                </div>
            </div> : null }
            <div className="se-path-outer">
                <div className="se-path">
                    {withinPath ? path.map((e)=>{
                    return <div
                        key={e}
                        onClick={()=>this.handleShowStyle(e)}
                        className={!inlineMode && id === e ? 'se-path-item active' : 'se-path-item'}
                    >
                        {e}
                    </div>;
                }) : (<React.Fragment>
                    <IconButton
                        onClick={()=>this.handleShowStyle(assignedId)}
                        icon="arrow_back"
                        tooltip="Back"
                    />
                    <div className="se-path-item-active">{id}</div>
                </React.Fragment>)}
                    {withinPath ? <div
                        onClick={()=>this.props.query({s:null})}
                        className={inlineMode ? 'se-path-item active' : 'se-path-item'}
                    >styl elementu</div> : null}
                </div>
                <div style={{ display: 'flex' }}>
                    <Popover
                        trigger={(props)=>{
                            return <IconButton
                                {...props}
                                icon="add"
                                tooltip="Define element"
                            />
                        }}
                        content={(props)=><AddElement
                            {...props}
                            ownElements={ownElements}
                            onAdd={this.handleDefineElement}
                        />}
                    />
                </div>
            </div>
            { editorMode === 'props' ? <div>
            {elements.map((e) => {
                let isExpandable = !valueTypes[e];
                let isOpen = isExpandable && e === this.state.open;
                let type = valueTypes[e] ? valueTypes[e].type : null;
                return (<div
                        key={e}
                        className={classNames({
                            'se-element': true,
                            'is-open': isOpen
                        })}
                >
                    <div className="se-element__heading">
                        <div className="se-element__label">
                            <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                                <div onClick={isExpandable ? this.handleTogglePanel(e) : null}>{e}</div>
                                { type ? <ElementForm
                                    id={e}
                                    value={style[e]}
                                    type={type}
                                    onChange={this.handleChange}
                                /> : null }
                            </div>
                            { isExpandable ? <div className="se-element-actions">
                                <div style={{ flex: 1 }} />
                                { isOpen ?
                                    <IconButton
                                        tooltip="Remove element"
                                        className="se-icon-button"
                                        onClick={()=>this.handleClear(e)}
                                        size="small"
                                        icon="delete" />
                                : null }
                                { isOpen ?
                                    <Popover trigger={props=><IconButton
                                        {...props}
                                        tooltip="Find property"
                                        className="se-icon-button"
                                        size="small"
                                        icon="search" />} 
                                        content={props=><FindProperty
                                            onChange={this.handleChange}
                                            elementId={e}
                                            value={style[e]}
                                        />}
                                    />
                                 : null }
                                { !inlineMode && style && style[e] && Array.isArray(style[e]) ?
                                    <IconButton
                                        tooltip="Inherit"
                                        className="se-icon-button primary"
                                        active={style[e].indexOf('@all') > -1}
                                        onClick={()=>this.handleToggleInherit(e)}
                                        size="small"
                                        icon="subdirectory_arrow_right" />
                                : null }
                            </div> : null }
                        </div>
                    </div>
                    <div style={{ display: isOpen ? 'flex' : 'none' }}>

                            { e !== '@tags'
                                ? <Element
                                    key={e}
                                    id={e}
                                    ns={ns}
                                    value={style[e]}
                                    onChange={(value)=>this.handleChange(e, value)}
                                />
                                : (<div>tags</div>) }
                        
                    </div>
                </div>);
            })}
                {ownElements.length ? (<div className="se-undefined-elements">
                    {ownElements.map(e=>{
                        return <div key={e} className="se-define-element" onClick={()=>this.handleDefineElement(e)}>{e}</div>
                    })}
                </div>) : null}
            </div> : null }
            { editorMode === 'code' ? (<div style={{ flex: 1, minHeight: '400px' }}><CodeEditor
                value={this.props.style}
                height="100%"
                id="style-code"
                rootId={this.props.id}
                properties={{format: 'json'}}
                onChange={this.handleEdit}
            /></div>) : null }
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Editor);