import React from 'react';

import Filter from 'containers/list/Filter';
import FilterField from 'v2/Filter';

import classNames from 'classnames';

export default class ListFilters extends React.PureComponent {

    render() {
        const { resource, id, filters, onSetFilter, config } = this.props;
        //if (!Array.isArray(config.filters_config)) return null;
        let layout = this.props.layout || 'vertical';
        let layoutClass = 'list-filters--' + layout;
        let classes = {'list-filters': true};
        classes[layoutClass] = true;
        if (config.id) {
            return (<form autoComplete="off" className={classNames(classes)}>
                {config.filters_config ? config.filters_config.map(id=>{
                    return <FilterField
                        resource={this.props.resource}
                        id={id}
                        filterId={config.id}
                        onSetFilter={onSetFilter}
                        mobile={this.props.mobile}
                    />;
                }) : null}
            </form>);
        }
        let keys = Object.keys(config.filters_config);
        return (<form
            autoComplete="off"
            className={classNames(classes)}
        >
            {keys.map((filter) => {
                return (<Filter
                    key={filter}
                    onSetFilter={onSetFilter}
                    {...config.filters_config[filter]}
                    id={filter}
                    value={filters[filter]}
                />);
            })}

        </form>);
    }

}