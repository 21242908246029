import React from 'react';
import PropTypes from 'prop-types';

export default function Dot(props) {
    const { color } = props;

    return (<div
        className={"color-dot bg-"+color}
    >
        &nbsp;
    </div>);
}

Dot.propTypes = {
    color: PropTypes.string
};