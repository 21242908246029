import React from 'react';
import {connect} from 'react-redux';
//import PropTypes from 'prop-types';
import {refreshLessStylesFromState} from "state/actions/ide";
import { updateIn } from 'lodash-redux-immutability';
import { connectCollection } from 'components/fields/collection/EmbeddedCollection';
import TypographyStyle from './TypographyStyle';
import IconButton from 'components/TooltipIconButton';

const defaultStyleValues = {
    font: 'body',
    font_weight: 'regular',
    font_size: 16,
    letter_spacing: 0,
    line_height: 1,
    text_transform: 'none'
};

const mapState = (store, props) => {
    return {};
};

const mapDispatch = {
    onRefresh: refreshLessStylesFromState
};

class TypographyStylesCollection extends React.PureComponent {

    handleChange = (key, value) => {

    };

    handleReorder = (drag, hover) => {
        this.props.reorder(
            this.props.path.split('/'),
            drag,
            hover
        );
    };

    handleCreate = () => {
        let newValue = Object.values(this.props.items);
        newValue.push({
            id: 'type'+this.props.data.length,
            ...defaultStyleValues
        });
        this.props.onChange(this.props.id, newValue);
    };

    handleChangeItem = (id, key, value) => {
        let newValue = {...this.props.items};
        let update = {};
        update[key] = value;
        newValue = updateIn(newValue, id, update);
        this.props.onChange(this.props.id, Object.values(newValue));
    };

    renderStyle = (id, i) => {
        return (<TypographyStyle
            key={id}
            path={[this.props.path, i].join('/')}
            value={this.props.items[id]}
            preset={this.props.preset}
            onChange={(key, value)=>this.handleChangeItem(id, key, value)}
            onRefresh={this.props.onRefresh}
        />);
    };

    render() {
        const { data } = this.props;
        return (<div>
            {data.map(this.renderStyle)}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <IconButton
                    icon="add_circle"
                    tooltip="Dodaj styl"
                    onClick={this.handleCreate}
                />
            </div>
        </div>);
    }

}

TypographyStylesCollection.propTypes = {};

export default connectCollection(connect(mapState, mapDispatch)(TypographyStylesCollection));