import React from 'react';
//import PropTypes from 'prop-types';

export default class Map extends React.PureComponent {

    render() {
        const { s } = this.props;
        return (
            <div className={"contour-map " + s.get("geo")}>
                <div className="ContourMap">
                    <div className="MapOfPoland MapOfPoland--1px" />
                </div>
            </div>
        );
    }

}

Map.propTypes = {};