import React from 'react';
import {connect} from 'react-redux';

import Post from './Post';
import Node from './Node';
import ListItem from 'v2/items/ListItem';

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

class StreamPost extends React.Component {

    render() {
        let { resource } = this.props;
        if (resource === 'db.posts') return <Post {...this.props} />;
        if (resource === 'db.nodes') return <Node {...this.props} />;
        return <ListItem {...this.props} />;
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StreamPost);