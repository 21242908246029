import React from 'react';

import {RenderListWrapper as Listing} from 'components/list/ListWrapper';
import TableListing from 'v2/TableListing';
import {RenderResourceListItem} from 'components/list/ResourceListItem';
import RenderListItem from "v2/items/ListItem";
import GraphNode from "v2/items/GraphNode";
import StreamPost from "v2/items/StreamPost";
import TableRow, {SimpleTableRow} from "v2/items/TableRow";
import FieldMapping from 'v2/items/FieldMapping';
import Resource from "v2/Resource";

export function RenderTableRow(props) {
    if (props.fields) return <TableRow {...props} />;
    return (<Resource resource="db.list-views" fetchPolicy="never" id={props.view}>
        {(viewProps)=><TableRow {...props} fields={viewProps.data.view_fields} />}
    </Resource>);
}

function SimpleTable(props) {
    return <table className={'props-table'}>
        {props.items}
    </table>;
}

function RenderGraphNode(props) {
    return <GraphNode {...props} />;
}

export const itemTypes = {
    listItem: RenderListItem,
    tableRow: RenderTableRow,
    tableRowSimple: SimpleTableRow,
    graphNode: GraphNode,
    streamPost: StreamPost,
    'db.sync-field-mapping': FieldMapping
};

export default {
    list: {
        layout: 'list',
        itemType: itemTypes.listItem,
        listType: Listing,
        filterLayout: 'aside'
    },
    table: {
        layout: 'table',
        itemType: itemTypes.tableRow,
        listType: TableListing,
        filterLayout: 'above'
    },
    'table-simple': {
        layout: 'table',
        itemType: itemTypes.tableRowSimple,
        listType: SimpleTable,
        filterLayout: 'above'
    },
    grid: {
        layout: 'grid',
        itemType: itemTypes.listItem,
        listType: Listing,
        className: 'media-grid',
        filterLayout: 'above'
    },
    tree: {
        layout: 'tree',
        itemType: itemTypes.listItem,
        listType: Listing
    },
    graph: {
        layout: 'graph',
        itemType: itemTypes.graphNode,
        listType: Listing,
        variant: 'graph'
    },
    stream: {
        layout: 'stream',
        itemType: itemTypes.streamPost,
        listType: Listing,
        filterLayout: 'above'
    },
    media: {
        layout: 'grid',
        itemType: 'RenderResourceListItem',
        listType: Listing,
        className: 'media-grid'
    }
};