import React from 'react';
import {connect} from 'react-redux';
import {listCreate} from 'state/actions';
import ResourceList from 'v2/ResourceList';
import Resource from 'v2/Resource';
import Button from 'components/Button';
import Filters from 'containers/list/Filters';
import {selectResource} from 'state/selectors/resources';
import {selectSubtypes, selectViewId} from "state/selectors/schema";
import ViewSelect from 'v2/ViewSelect';
import Popover from 'components/Popover';
import {TypeCard} from 'components/fields/components/ComponentTypeSelect';

const mapStateToProps = (store, props) => {
    let nodeId = store.context.node || store.context.id;
    let viewId = selectViewId(store, 'list', 'db.posts');
    let node = selectResource(store, 'db.nodes', store.context.id);
    return {
        parent: nodeId,
        node,
        listId: 'db.nodes.' + nodeId + '.posts',
        resource: 'db.posts',
        query: "api:db.posts?parent="+nodeId,
        listViewId: viewId,
        listView: selectResource(store, 'db.list-views', viewId),
        types: selectSubtypes(store, 'db.posts'),
        owned: node.created_by === store.context.user
    }
};

const mapDispatchToProps = {
    onCreate: listCreate
};

class PostsView extends React.Component {

    handleCreate = (type) => {
        this.props.onCreate(
            this.props.resource,
            this.props.listId,
            {draft: true, parent: this.props.parent, subtype: type},
            -1
        );
    };

    render() {
        const { resource, listId, listViewId, types, owned } = this.props;
        return (<div style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: '0 0 0 1rem', overflow: 'hidden' }}>
            <div style={{ marginBottom: '1rem', display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex' }}>
                    {/**<ViewSelect resource={resource} type="list" value={listViewId} />**/}
                    <Filters
                        resource={resource}
                        filterId={this.props.listView.filters}
                        listId={listId}
                        layout={'horizontal'}
                    />
                </div>
                { owned ? <Popover
                    trigger={(props)=><Button
                        {...props}
                        color={'secondary'}>Create new post</Button>}
                    content={(props)=><div className="console-context popover-content">
                        <div className="app-nav-resources app-nav-resources--3">
                            {types.map(type=>{
                                return <Resource resource="db.types" id={type} fetchPolicy={'never'}>
                                    {({data})=><TypeCard
                                        key={type}
                                        onClick={()=>{props.onClose();this.handleCreate(type)}}
                                        icon={data.icon}
                                        label={data.name}
                                    />}
                                </Resource>
                            })}
                        </div>
                    </div>}
                /> : null }
            </div>
            <div className="posts-list" style={{ flex: 1, overflow: 'hidden', display: 'flex' }}>
                <ResourceList
                    list={listId}
                    filterId={this.props.listView.filters}
                    resource={resource}
                    filterLayout="none"
                    source={this.props.query}
                    onClick={(id)=>alert(id)}
                />
            </div>
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PostsView);