import React from 'react';
import {connect} from 'react-redux';
import Icon from "@material-ui/core/Icon";
import Card from "@material-ui/core/Card";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from '@material-ui/core/styles';
import {selectResource} from 'state/selectors/resources';
import ItemActions from "./ItemActions";
import Dot from 'components/Dot';

import Field from 'v2/Field';
import {RenderValue} from 'v2/list-items';
import {icon as renderIcon} from 'util/icon';

const mapStateToProps = (store, props) => {
    let data = selectResource(store, props.resource, props.id);
    let status = data['audit-status'];
    status = selectResource(store, 'db.enum-values', status);
    return {
        data,
        status
    }
};

const mapDispatchToProps = {};

const styles = {
    success: {},
    successBar: {},
};

class SurveyProgressBar extends React.PureComponent {
    render() {
        const { classes } = this.props;
        return <LinearProgress
            {...this.props}
            classes={{
                colorPrimary: classes.success,
                barColorPrimary: classes.successBar
            }}
        />;
    }
}

export const StyledProgress = withStyles(styles)(SurveyProgressBar);

class EnumStatus extends React.Component {
    render() {
        const { icon, title, color } = this.props;
        return icon
            ? <div className={color}>{renderIcon(icon)}</div>
            : <Dot color={color} />
    }
}

class Audit extends React.Component {

    render() {
        const { status } = this.props;
        const progress = this.props.data.progress || {};
        const resource = 'db.audits';
        const id = this.props.data.id;
        const isTemplate = this.props.data.template;
        return (<Card className="audit-item" onClick={this.props.onClick} style={{ marginBottom: '0.4rem' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', padding: '1rem 0.8rem', flex: 1, alignItems: 'center' }}>
                    <div style={{ marginRight: '1rem' }}>
                        <EnumStatus {...status} />
                    </div>
                    <div style={{ display: 'flex', flex: 1 }}>
                        <div style={{ fontSize: '12px' }}><Field
                            resource={resource}
                            id={id}
                            fieldName={'description'}
                        >
                            {RenderValue}
                        </Field></div>
                        { !isTemplate ? <div style={{ fontSize: '12px', opacity: 0.5, marginLeft: '1rem' }}>
                            {progress.progress}%
                        </div> : null }
                    </div>
                    { progress.failed ? <div style={{ display: 'flex' }}>
                        <div className="text-danger" style={{ display: 'flex', alignItems: 'center', marginRight: '1rem'}}>
                            <Icon>warning</Icon> {progress.failed}
                        </div>
                        <div className="text-success" style={{ display: 'flex', alignItems: 'center', marginRight: '1rem'}}>
                            <Icon>check</Icon> {progress.answered-progress.failed}
                        </div>
                    </div> : null }
                </div>
                { this.props.onClick ? <ItemActions
                    resource={resource}
                    id={id}
                    actions='db.audits'
                    isOpen={false}
                /> : null }
            </div>
            { !isTemplate ? <StyledProgress
                color="primary"
                variant="determinate"
                value={progress.progress}
            /> : null }
        </Card>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Audit);