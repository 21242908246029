import React from 'react';
import {connect} from 'react-redux';
import uuid from 'uuid/v4';
import Button from 'components/Button';
import UploadButton from "components/UploadButton";
import IconButton from "components/TooltipIconButton";
import UserAvatar from "components/list/fields/UserAvatar";
import {selectResource} from "state/selectors/resources";
import {draftsChange} from "state/actions";
import {handleUploadDirect} from "../../state/actions/files";
import MessageMedia from "./MessageMedia";

const nullArr = [];

const mapStateToProps = (store, props) => {
    const { threadId, attachId } = props;
    const resource = 'db.messages';
    const id = ['@newMessage',threadId || attachId].join('.');
    const data = selectResource(store, resource, id);
    const media = data.media || nullArr;
    return {
        data,
        path: [resource, id],
        resource,
        id,
        media: media,
        canSubmit: !!(media.length || data.content)
    }
};

const mapDispatchToProps = {
    onChange: draftsChange,
    onUpload: handleUploadDirect
};

class Conversation extends React.Component {

    constructor(props) {
        super(props);
    }

    handleCreate = () => {
        const { path, data, canSubmit } = this.props;
        if (!canSubmit) return;

        this.props.onCreate({
            ...data,
            id: uuid(),
        });
        this.props.onChange(path, {content: false, media: []});
    };

    handleChange = (key, value) => {
        const { path, onChange } = this.props;
        const update = {};
        update.content = value;
        onChange(path, update);
    };

    handleUpload = files => {
        const { path } = this.props;
        const mediaResource = 'db.media';
        files.map((file)=>this.props.onUpload(
            mediaResource,
            path.concat(['media']),
            file
        ));
    };

    render() {
        const { resource, id, data, readOnly, currentUser, canSubmit, media } = this.props;
        return (<div className={'conversation'}>
            <div className={'messages'}>
                {this.props.children}
            </div>
            { !readOnly ? <div className={'message-form'}>
                <div className={'message-form__avatar'}>
                    <UserAvatar id={currentUser} />
                </div>
                <div className={'message-form__content'}>
                    <div className={'message-form__upload'}>
                        <UploadButton
                            onUpload={this.handleUpload}
                            trigger={<IconButton color={'inherit'} icon={'cloud_upload'} />}
                        />
                    </div>
                    <div className={'message-form__message'}>
                        <InlineEdit
                            onChange={this.handleChange}
                            value={data.content||''}
                            onEnterKey={this.handleCreate}
                        />
                        { media.length
                            ? <div className={'message-form__media'}>
                                <MessageMedia
                                    resource={resource}
                                    id={id}
                                    items={data.media}
                                    readOnly={true}
                                />
                            </div>
                            : null }
                    </div>
                    <div className={'message-form__submit'}>
                        <IconButton
                            icon={'send'}
                            onClick={this.handleCreate}
                            disabled={!canSubmit}
                            color={'primary'}
                        />
                    </div>
                </div>
            </div> : null }
        </div>);
    }

}

class InlineEdit extends React.PureComponent {
    handleChange = (evt) => {
        this.props.onChange(
            this.props.id,
            evt.target.value
        )
    };

    handleKey = (e) => {
        if (!this.props.onEnterKey) return;
        let code = (e.keyCode ? e.keyCode : e.which);
        if (code === 13) {
            e.preventDefault();
            this.props.onEnterKey(e);
        }
    };

    render() {
        const { value } = this.props;
        return <textarea
            rows={1}
            wrap="off"
            onChange={this.handleChange}
            onKeyPress={this.handleKey}
            value={value}
            placeholder={'Enter a note'}
        />;
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Conversation);