import React from 'react';
import {connect} from 'react-redux';
import Dialog from 'components/Dialog';
import ResourceForm from 'containers/ResourceForm';

import {draftsCancel} from 'state/actions';

const mapStateToProps = (store, props) => {
    const resource = store.context.r
        ? store.context.r.split('/')
        : null;
    return {
        type: resource ? resource[0] : null,
        id: resource ? resource[1] : null
    }
};

const mapDispatchToProps = {
    onCancel: draftsCancel
};

class FormDialog extends React.Component {

    handleClose = () => {
        this.props.onCancel(
            this.props.type,
            this.props.id,
            false
        );
        this.props.onClose();
    };

    render() {
        const { open, ...other } = this.props;
        const { type, id } = this.props;
        return (<Dialog
            open={open && type && id}
            onClose={this.handleClose}
            maxWidth="md"
            fullWidth={true}
            hideCloseButton={false}
        >
            <ResourceForm {...other} hideCloseButton={true} />
        </Dialog>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormDialog);