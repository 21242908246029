import React from 'react';
import {connect} from 'react-redux';

import VisibilitySensor from 'react-visibility-sensor';

import Section from 'ide/containers/Section';
import Resource from 'containers/Resource';
import Draggable from 'components/Draggable';

import { List as ListLoader } from 'react-content-loader'
import { changed } from 'util/changed';

const mapStateToProps = (store, props) => {
    const isPending = store.ui.pending[props.id]
        || store.ui.pending['theme.styles']
        || !store.ui.styles;
    return {
        preview: true, //!!store.context.preview,
        isPending: isPending,
        shouldFetch: store.data['cms.sections'][props.id]
            && store.data['cms.sections'][props.id].items === undefined
    }
};

const mapDispatchToProps = {};

const emptyStyle = {
    display: 'flex',
    flex: 1,
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center'
};

class SectionPreview extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            visible: false
        }
    }

    componentDidMount() {
        this.updateVisibility();
    }

    componentDidUpdate() {
        this.updateVisibility();
    }

    updateVisibility = () => {
        if (!this.state.visible && this.props.isVisible) {
            this.setState({visible: true});
        }
    };

    render() {
        const { style, isPending, shouldFetch, connectDnd } = this.props;
        const isVisible = this.state.visible;
        const propsVisible = this.props.isVisible;
        const pending = isPending; // || shouldFetch;
        return connectDnd(<div
            style={style}
            className="section-preview-outer"
        >
            <div className="theme-scope section-preview">
                <Resource
                    resource="cms.sections"
                    context={this.props.context}
                    id={this.props.id}
                    shouldFetch={shouldFetch}
                    fetchOnce={true}
                    render={(props)=> {
                        return !pending ? <React.Fragment>
                            <div className="section-canvas">
                                <Section
                                    id={this.props.id}
                                    s={this.props.s}
                                />
                            </div>
                            <div className="section-preview__header">
                                {props.data.title || props.data.description} {props.data.style}
                            </div>
                            <div className="section-preview__overlay" />
                        </React.Fragment>
                        : <div className="section-preview__loader">
                                <ListLoader style={{ width: '200px', height: '200px' }}/>
                        </div>
                    }}
                />
            </div>
        </div>);
    }

}

function SectionVisibility(props) {
    return (
        <VisibilitySensor
            key={props.id}
            scrollCheck={true}
            partialVisibility={true}
            delayedCall={true}
            containment={props.listRef.current}
        >
            {({isVisible}) =>
                <SectionPreview {...props} isVisible={isVisible} />
            }
        </VisibilitySensor>
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SectionPreview);