import React from "react";
import ConnectedComponent from "ide/Component";

export default class Children extends React.PureComponent {
  render() {
    const { s, data, childKey } = this.props;
    let child = childKey || "components";
    const children = data[child];

    return children.map((item, i) => {
      return <ConnectedComponent s={s} id={item} key={item} />;
    });
  }
}
