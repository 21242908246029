import React from 'react';
import {connect} from 'react-redux';
import {selectViewId, selectTypeId} from 'state/selectors/schema';
import {selectResource} from 'state/selectors/resources';
import {query} from 'state/actions/route';
import {listCreate, resourceDelete} from 'state/actions';
import {uiSetView} from 'state/actions/ui';
import Select from 'v2/fields/Select';
import Icon from '@material-ui/core/Icon';
import IconButton from 'components/TooltipIconButton';
import IconMenu from 'components/IconMenu';
import Access from 'components/Access';

const mapStateToProps = (store, props) => {
    let [app, resourceName] = props.resource.split('.');
    const viewResource =  props.type === 'list'
        ? 'db.list-views'
        : 'db.form-views';
    const id = props.value;
    return {
        id,
        typeId: props.typeId || selectTypeId(store, props.resource),
        data: selectResource(store, viewResource, id),
        resource: resourceName,
        viewResource,
        editMode: store.context.editMode
    }
};

const mapDispatchToProps = {
    uiSetView,
    query,
    onCreate: listCreate,
    onDelete: resourceDelete
};

function ViewSelect(props) {
    let { resource, viewResource, type, id } = props;
    const isForm = type === 'form';

    const setView = (key, value) => {
        props.uiSetView(type, resource, value);
    };

    const viewSettings = () => {
        let path = [viewResource, id].join('/');
        props.query({r:path});
    };

    const modelSettings = () => {
        let path = ['db.types', props.typeId].join('/');
        props.query({r:path});
    };

    const editView = () => {
        props.query({editView: true});
    };

    const createView = () => {
        props.onCreate(
            viewResource,
            [viewResource,'','options'].join('.'),
            {resource: props.typeId, name: 'new view'}
        );
    };

    const deleteView = () => {
        props.onDelete(viewResource, id);
    };

    const enableEditMode = () => {
        props.query({editMode: props.editMode ? null : true});
    };

    const menu = [
        {
            key: 'edit-view',
            caption: 'View settings',
            onClick: viewSettings
        },
        {
            key: 'edit-layout',
            caption: 'Edit layout',
            onClick: editView
        },
        {
            key: 'edit-data-model',
            caption: 'Data model',
            onClick: modelSettings
        },
        {
            key: 'delete-view',
            caption: 'Delete',
            onClick: deleteView
        },
        {
            key: 'create-view',
            caption: 'Create view',
            onClick: createView
        }
    ];

    return (<div className="list-view-options">
        <Select
            id={props.typeId}
            variant="dropdown-lg"
            source={"api:"+viewResource+"?resource="+props.typeId}
            value={id}
            onChange={setView}
            fetchPolicy="always"
        />
        <div style={{ display: 'flex' }}>
            <Access scopes="master">
                <IconMenu
                    sm
                    items={menu}
                    trigger={(props)=><IconButton {...props} icon="settings" size="small" />}
                />
            </Access>
            {isForm ? <IconButton onClick={enableEditMode} icon={'edit'} size={'small'} /> : null}
        </div>
    </div>);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewSelect);