import React from 'react';
import {connect} from 'react-redux';
import {listDisplay} from 'state/actions/ui';
import IconButton from 'components/TooltipIconButton';

const mapStateToProps = (store, props) => {
    return {
        value: store.ui.listDisplay[props.id]
    }
};

const mapDispatchToProps = {
    onChange: listDisplay
};

class ListDisplay extends React.PureComponent {

    render() {
        const { id, value, onChange } = this.props;
        return (<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div>Widok listy</div>
            <div style={{ display: 'flex' }}>
                <IconButton
                    tooltip="Lista"
                    icon="view_headline"
                    active={value === 'list'}
                    onClick={()=>onChange(id, 'list')} />
                <IconButton
                    tooltip="Podgląd sekcji"
                    icon="view_module"
                    active={value === 'grid'}
                    onClick={()=>onChange(id, 'grid')} />
            </div>
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListDisplay);