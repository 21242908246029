import React from 'react';
import {connect} from 'react-redux';
import {selectResource} from 'state/selectors/resources';
import PropTypes from 'prop-types';

import Fieldset from 'v2/form-builder/Fieldset';
import MuiStepper from "@material-ui/core/Stepper/Stepper";
import Button from "components/Button";
import IconButton from "components/TooltipIconButton";
import Resource from 'v2/Resource';

const mapState = (store, props) => {
    let data =selectResource(
        store,
        props.fieldsetResource,
        props.fieldsetId
    );
    return {
        data,
        tabs: data.view_fields || []
    };
};

const mapDispatch = {};

class Tabs extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            tab: props.tabs[0]
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tabs !== this.props.tabs) {
            if (this.props.tabs.indexOf(this.state.tab) === -1) {
                this.setState({tab: this.props.tabs[0]});
            }
        }
    }

    handleCreateTab = () => {
        this.props.onCreate('tab');
    };

    handleSwitchTab = (tab) => {
        this.setState({tab});
    };

    render() {
        const { tabs, data, editable } = this.props;
        const hasTabs = tabs.length > 0;
        const hasMultipleTabs = tabs.length > 1;
        return (<div className={'form-tabs-wrapper'}>
            { !hasTabs ? <div style={{ padding: '1rem' }}>
                <Button onClick={this.handleCreateTab}>Create tab</Button>
            </div> : null }
            { hasMultipleTabs || editable ? <div className="form__tabs-nav" style={{
            }}>
                {tabs.map(tab => (
                    <Resource resource="db.view_fields" id={tab} fetchPolicy={'never'}>
                        {({data})=><IconButton
                            icon={data.icon || "edit"}
                            tooltip={data.label}
                            onClick={()=>this.handleSwitchTab(tab)}
                            active={tab===this.state.tab}
                        />}
                    </Resource>
                ))}
                { editable
                    ? <IconButton
                        icon="add"
                        onClick={this.handleCreateTab}
                        tooltip="Add tab"
                    />
                    : null }
            </div> : null }

            <div className="form__body">
                { hasTabs ? <Fieldset
                    {...this.props}
                    fields={[this.state.tab]}
                /> : null }
            </div>
        </div>);
    }

}

Tabs.propTypes = {};

export default connect(mapState, mapDispatch)(Tabs);