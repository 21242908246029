import React, {Component} from 'react';
import {connect} from 'react-redux';

import { selectListActions } from 'state/selectors/schema';

import ListActionsImpl from 'components/dialogs/ListActions';

import {
    query
} from "state/actions/route";

import {
    resourceAction
} from "state/actions";

import Dialog from "@material-ui/core/Dialog/Dialog";

const mapStateToProps = (store, props) => {
    let actions = selectListActions(store, props.resource);
    return {
        open: store.context.q === 'list-actions', //store.ui.showListActions,
        actions,
        context: store.context
    }
};

const mapDispatchToProps = {
    query,
    onActionRequest: resourceAction
};

class ListActions extends Component {

    handleClose = () => {
        if (this.props.open) this.props.query({q: null});
    };

    render() {
        const { open } = this.props;
        return (<Dialog open={open} onClose={this.handleClose}>
            <ListActionsImpl {...this.props} onClose={this.handleClose} />
        </Dialog>);
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ListActions);