import {
    call,
    takeEvery,
    takeLatest,
    put
} from 'redux-saga/effects';

import { listFetchFiltered, resourceDeleteSuccess } from 'state/actions';

import * as api from 'util/api/saga';
import * as ui from 'util/sagas/feedback';

function* apiRequest(endpoint, method, args, id, confirm, context) {
    try {
        let confirmed = true;
        if (confirm) confirmed = yield call(ui.confirm, "Czy na pewno?");
        if (!confirm || confirmed) {
            yield ui.pending(id, true);
            yield call(api[method], args, endpoint, id, context);
            yield ui.pending(id, false);
            yield ui.success();
            return true;
        }
    } catch (e) {
        yield ui.pending(id, false);
        yield ui.error(e);
    }
}

function* handleDestroyWebsite(action) {
    const { id, context } = action;
    const resource = 'console.services.website';
    try {
        const confirmed = yield call(ui.confirm, "Czy na pewno?");
        if (confirmed) {
            yield ui.pending(id, true);
            yield call(api.post, {}, 'console.website.destroy', id, context);
            yield ui.pending(id, false);
            yield ui.success('Website destroyed');
            yield put(resourceDeleteSuccess(resource, id));
        }
    } catch (e) {
        yield ui.pending(id, false);
        yield ui.error(e);
    }
}

function* handleRefreshWebsite(action) {
    const { id, context } = action;
    const resource = 'console.services.website';
    try {
        const confirmed = yield call(ui.confirm, "Czy na pewno usunąć serwerową pamięć podręczną?");
        if (confirmed) {
            yield ui.pending(id, true);
            yield call(api.post, {}, 'console.website.refresh', id, context);
            yield ui.pending(id, false);
            yield ui.success('Pamięć podręczna wyczyszczona');
        }
    } catch (e) {
        yield ui.pending(id, false);
        yield ui.error(e);
    }
}

function* handleCmsDump(action) {
    const { id, context } = action;
    const endpoint = 'console.cms.snapshots.dump';
    try {
        const args = [endpoint, 'getArgs', {}, id, false, context];
        yield call(apiRequest, ...args);
    } catch (e) {
        yield ui.error(e);
    }
}

function* handleCmsRestore(action) {
    const { id, context } = action;
    const resource = 'console.cms.snapshots';
    const endpoint = 'console.cms.snapshots.restore';
    try {
        yield ui.pending(id, true);
        const args = [endpoint, 'getArgs', {from: id}, context.id, true, context];
        const status = yield call(apiRequest, ...args);
        yield ui.pending(id, false);
        if (status) {
            yield put(listFetchFiltered(resource, resource, resource, false, context));
        }
    } catch (e) {
        yield ui.pending(id, false);
        yield ui.error(e);
    }
}

function* handleRenewCert(action) {
    const { id, context } = action;
    const endpoint = 'console.domains.cert.renew';
    try {
        const args = [endpoint, 'post', {}, id, false, context];
        yield call(apiRequest, ...args);
    } catch (e) {
        yield ui.error(e);
    }
}

function* handleReloadDomainConfig(action) {
    const { id, context } = action;
    const endpoint = 'console.domains.config.reload';
    try {
        const args = [endpoint, 'post', {}, id, false, context];
        yield call(apiRequest, ...args);
    } catch (e) {
        yield ui.error(e);
    }
}

function* handleClearDomainCache(action) {
    const { id, context } = action;
    const endpoint = 'console.domains.cache.clear';
    try {
        const args = [endpoint, 'post', {}, id, false, context];
        yield call(apiRequest, ...args);
    } catch (e) {
        yield ui.error(e);
    }
}


function* handleThemeBuild(action) {
    const { id, context } = action;
    const endpoint = 'console.themes.build';
    try {
        const args = [endpoint, 'post', {}, id, false, context];
        yield call(apiRequest, ...args);
    } catch (e) {
        yield ui.error(e);
    }
}


export default function*() {
    yield takeEvery('CONSOLE.CMS.DUMP', handleCmsDump);
    yield takeEvery('CONSOLE.CMS.RESTORE', handleCmsRestore);
    yield takeEvery('CONSOLE.WEBSITES.DESTROY', handleDestroyWebsite);
    yield takeEvery('CONSOLE.WEBSITES.REFRESH',handleRefreshWebsite);
    yield takeEvery('CONSOLE.CERT.RENEW', handleRenewCert);
    yield takeLatest('CONSOLE.DOMAIN.CONFIG', handleReloadDomainConfig);
    yield takeLatest('CONSOLE.DOMAIN.CACHECLEAR', handleClearDomainCache);
    yield takeEvery('CONSOLE.THEME.BUILD', handleThemeBuild);
}