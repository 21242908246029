import React from 'react';
import {connect} from 'react-redux';
import Fieldset from './Fieldset';
import FormViewModeContext from "./FormViewModeContext";
import classNames from 'classnames';

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

class Layout extends React.Component {

    render() {
        let properties = this.props.data.properties;
        let layout = properties ? properties.layout || 'cols' : 'cols';
        let mode = properties ? properties.view_mode : null;
        let classes = {
            'field-layout': true
        };
        classes['field-layout--'+layout] = true;
        return (<div className={classNames(classes)}>
            <FormViewModeContext.Consumer>
                {(formMode)=>{
                    return <FormViewModeContext.Provider value={mode||formMode}>
                        <Fieldset
                            editable={this.props.editable}
                            resource={this.props.resource}
                            id={this.props.id}
                            fieldsetResource={this.props.fieldResource}
                            fieldsetId={this.props.fieldId}
                            level={this.props.level+1}
                        />
                    </FormViewModeContext.Provider>
                }}
            </FormViewModeContext.Consumer>
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Layout);