import React from 'react';
import {connect} from 'react-redux';

import {promptConfirm, promptCancel} from 'state/actions/ui';
import ConfirmDialog from 'components/dialogs/Confirm';

const mapStateToProps = (store, props) => {
    let id = store.ui.prompt;
    return {
        isOpen: !!store.ui.prompt,
        id: id,
        schemaPath: id ? ['forms',id].join('/') : null,
        path: id ? ['forms',id].join('/') : null,
        message: id ? store.schema.forms[id].label : null,
        description: id ? store.schema.forms[id].description: null
    }
};

const mapDispatchToProps = {
    yes: promptConfirm,
    no: promptCancel
};

class Prompt extends React.PureComponent {

    render() {
        return (<ConfirmDialog
            {...this.props}
        />);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Prompt);