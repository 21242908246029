import React from 'react';
import {connect} from 'react-redux';
import { query } from "state/actions/route";
import Field, {RenderField} from "v2/Field";
import Resource from "v2/Resource";
import FilePreview from "components/media/FilePreview";
import {selectResource} from "../../state/selectors/resources";
import Preloader from "components/Preloader";

const mapStateToProps = (store, props) => {
    const data = selectResource(store, props.type, props.id);
    const isPending = store.ui.pending[props.id] || false;
    const progress = store.ui.progress[props.id];
    return {
        data,
        isPending,
        uploadProgress: progress
    }
};

const mapDispatchToProps = {
    onClick: query
};

class MediaItem extends React.PureComponent {
    click = () => {
        const { isPending, onClick, type, id } = this.props;
        if (isPending) return null;
        onClick({gallery: [type,id].join('/')});
    };
    render() {
        const { isPending, uploadProgress, data } = this.props;
        return <div onClick={this.click} className={'media-preview'}>
            <Preloader visible={isPending} progress={uploadProgress} />
            <FilePreview file={data.file} type={data.type} />
        </div>
    }
}

export default class MessageMedia extends React.Component {

    render() {
        const { resource, id, items, readOnly } = this.props;
        return items.length ? <Field
            resource={resource}
            id={id}
            fieldName="media"
            autoSave={true}
            readOnly={readOnly}
        >
            {(props)=>{
                return <div className={'message-media'}>{props.value.map(mediaId=>{
                    return <Media key={mediaId} type={'db.media'} id={mediaId} />;
                })}</div>;
            }}
        </Field> : null;
    }

}

MessageMedia.defaultProps = {
    items: []
};

export const Media = connect(
    mapStateToProps,
    mapDispatchToProps
)(MediaItem);