import React from 'react';
import PropTypes from 'prop-types';

import MaterialSlider from '@material-ui/lab/Slider';
import Typography from '@material-ui/core/Typography';
import InputBase from "@material-ui/core/InputBase";

const wrapperStyle = {
    flex: 1,
    display: 'flex',
    alignItems: 'center'
};

const valueStyle = {
    width: '60px',
    textAlign: 'center'
};

const sliderStyle = {
    flex: 1,
    margin: '0 1rem'
};

export default class Slider extends React.PureComponent {

    handleChange = (event, value) => {
        const step = this.props.step || 1;
        let rounded = step > 0.1 ? Math.round(value*(100*step))/(100*step) : value*1;
        this.props.onChange(this.props.id, rounded);
    };

    render() {
        const { id, min, max, step, label, value } = this.props;
        let style = this.props.style || {};
        return (<div style={wrapperStyle}>
                <div style={sliderStyle}>
                    <MaterialSlider
                        min={min}
                        max={max}
                        step={step}
                        value={value || min}
                        onChange={this.handleChange}
                    />
                </div>
                <div style={valueStyle}>
                    <InputBase
                        name={id}
                        onChange={(evt)=>this.handleChange(evt, evt.target.value)}
                        value={value || ''}
                        placeholder={''+min}
                        min={min}
                        max={max}
                        classes={{root: 'se-inline-input'}}
                    />
                </div>
        </div>);
    }

}

Slider.propTypes = {
    label: PropTypes.string,
    value: PropTypes.any.isRequired
};