import styleIcons from './icons/styles';
import actionIcons from './icons/actions';
import consoleIcons from './icons/console';
import apartmentsIcons from './icons/apartments';
import { icons as deviceIcons } from './devices';

const icons = {
    'apps.cms': 'description',
    'apps.console': 'settings',
    'apps.apartments': 'meeting_room',
    'apps.database': 'web',

    'cms.pages': 'description',
    'cms.pages.page': 'description',
    'cms.entries.offers': 'redeem',
    'cms.entries.rooms': 'meeting_room',
    'cms.entries.attractions': 'place',
    'cms.entries.routes': 'near_me',
    'cms.entries.reviews': 'rate_review',
    'cms.entries.gallery': 'photo',
    'cms.entries.contacts': 'supervised_user_circle',
    'cms.entries.posts': 'message',
    'cms.entries.products': 'local_grocery_store',
    'cms.entries.projects': 'assignment_turned_in',
    'cms.entries.rooms_conf': 'business_center',
    'cms.products': 'local_grocery_store',
    'cms.entries.categories': 'category',
    'cms.entries.properties': 'store',
    'cms.categories': 'category',
    'cms.tags': 'local_offer',
    'cms.layouts': 'web',
    'cms.navigation': 'link',
    'cms.properties': 'store',
    'cms.settings': 'settings',
    'cms.sections': 'note',
    'cms.sections.view': 'border_inner',
    'cms.sections.data': 'library_books',
    'cms.plans': 'select_all',
    'cms.enum-definitions': 'ballot',
    'cms.integrations': 'extension',
    'cms.components': 'storage',
    'cms.components.list': 'view_module',
    'cms.components.structured': 'view_compact',
    'cms.components.text': 'format_align_left',
    'cms.components.nav': 'insert_link',
    'cms.components.media-element': 'art_track',
    'cms.components.button': 'open_in_new',
    'cms.components.value': 'extension',
    'cms.components.template': 'code',
    'cms.components.geo': 'explore',
    'cms.components.card': 'insert_photo',
    'cms.integrations.facebook': 'fa-facebook',
    'cms.integrations.instagram': 'fa-instagram',
    'cms.integrations.google': 'fa-google',
    'cms.styles': 'format_shapes',
    'cms.translations': 'language',
    'cms.media-library': 'photo_library',
    'default': 'extension',
    'dev': 'code',
    'dev.editor': 'code',
    'dev.github': 'fa-github',
    'dev.preset': 'palette',
    'dev.styles': 'format_shapes',
    'dev.components': 'storage',

    'tab.default': 'edit',
    'tab.data_source_settings': 'local_offer',
    'tab.translations': 'language',
    'tab.nav_settings': 'insert_link',
    'tab.seo': 'fa-google',
    'tab.open_graph': 'fa-facebook',
    'tab.custom': 'settings',
    'tab.access': 'how_to_reg',
    'tab.typography.styles': 'format_size',
    'tab.flat_rooms': 'meeting_room',

    'tab.location': 'location_on',
    'tab.check_in': 'how_to_reg',
    'tab.contacts': 'supervisor_account',
    'tab.solving_issues': 'live_help',
    'tab.apartment': 'meeting_room',

    'console.cms.snapshots': 'today',

    'media.files': 'insert_drive_file',
    'media.videos': 'movie_creation',
    'media.pano': '360',

    'db.types': 'ballot',
    'db.filters': 'filter_list',
    'db.fields.text': 'text_format',
    'db.fields.tab': 'tab',
    'db.fields.layout': 'view_compact',
    'db.fields.select': '',
    'db.fields.ref': 'link',
    'db.fields.media': 'photo',
    
    ...actionIcons,
    ...styleIcons,
    ...deviceIcons,
    ...consoleIcons,
    ...apartmentsIcons
};



export default icons;