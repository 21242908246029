import React from 'react';
import PropTypes from 'prop-types';
import Field, {RenderField} from "./Field";
import FormStyleContext from 'v2/FormStyleContext';

const nullArr = [];

function DefaultFieldStyle(props) {
    let type = props.schema.type;
    return <div className={"form-field form-field--"+type}>
        <RenderField {...props} />
    </div>;
}

function TabFieldStyle(props) {
    let type = props.schema.type;
    return <div className={"form-field form-field--tab form-field--"+type}>
        <div className="form-field__label">
            {props.schema.label}
        </div>
        <RenderField {...props} />
    </div>
}

function LabeledFieldStyle(props) {
    let type = props.schema.type;
    return <div className={"form-field form-field--labeled form-field--"+type}>
        <div className="form-field__layout">
            <div className="form-field__label" style={{
                width: '200px',
                fontSize: '14px',
                fontWeight: '600',
                paddingRight: '1.6rem',
                opacity: 0.5
            }}>
                {props.schema.label || props.schema.slug}
            </div>
            <div className="form-field__value">
                <RenderField {...props} />
            </div>
        </div>
    </div>;
}

const fieldStyles = {
    default: DefaultFieldStyle,
    labeled: LabeledFieldStyle
};

const typeStyles = {
    layout: DefaultFieldStyle,
    ref: DefaultFieldStyle,
    collection: DefaultFieldStyle,
    tab: TabFieldStyle,
    media: TabFieldStyle
};

function RenderFieldType(props) {
    let type = props.schema.type;
    
    return <FormStyleContext.Consumer>
        { formStyle => {
            let FieldRenderer = typeStyles[type] || fieldStyles[formStyle] || fieldStyles.default;
            return <FieldRenderer {...props} />;
        }}
    </FormStyleContext.Consumer>;
}

class Fieldset extends React.PureComponent {
    render() {
        const {fields, resource, id} = this.props;
        return fields.map(fieldId =>
            <Field
                key={fieldId}
                resource={resource}
                id={id}
                fieldId={fieldId}
            >
                {RenderFieldType}
            </Field>
        );
    }
}

Fieldset.defaultProps = {
    fields: nullArr
};

export default Fieldset;

export function RenderFieldset({resource, id, schema}) {
    return <Fieldset
        resource={resource}
        id={id}
        fields={schema.fields}
    />;
}