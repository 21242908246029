import React from 'react';
import {connect} from 'react-redux';

import { selectPath } from 'state/selectors/resources';
import { currentWebsite } from 'state/selectors/app';
import { hasAccess } from 'state/selectors/access';


import Grow from '@material-ui/core/Grow';

import {
    draftsPathAttach,
    draftsPathDetach,
    draftsCancel,
    draftsSubmit,
    draftsPathChange,
    draftsPathReorder,
    listFetch,
    listFilter,
    listFilterSet
} from 'state/actions';

import {
    query
} from 'state/actions/route';

import {
    changed
} from 'util/changed';

import {selectFilter,selectFilterConfig} from 'state/selectors/filter';

import Dialog from 'components/Dialog';
import Drawer from '@material-ui/core/Drawer';
import SideSheet from 'components/SideSheet';
import AttachSectionDialog from 'components/dialogs/AttachSection';

const mapStateToProps = (store, props) => {
    const isPowerUser = hasAccess(store, 'websites.power_user');
    const isDevUser = hasAccess(store, 'websites.dev');

    const sources = [
        {id: store.context.project, label: 'Aktualny projekt'}
    ];

    let projectLibraryId = isPowerUser ? currentWebsite(store).library : null;

    if (projectLibraryId) {
        sources.push({id: projectLibraryId, label: 'Biblioteka'});
    }

    const source = store.context.src || sources[0].id;
    const listId = 'cms.sections.attach.'+source;

    const path = [
        store.context.app+'.'+store.context.view,
        store.context.id,
        'sections'
    ].join('/');

    const data = selectPath(store, path.split('/')) || [];
    const filterId = 'cms.sections.attach';
    const filterConfig = selectFilterConfig(store, 'cms.sections');
    const tagOptionsList = 'cms.tags.cms.cms_tags.options';
    const cmsTags = store.lists[tagOptionsList];

    return {
        app: store.context.appPath,
        open: store.context.q === 'attach-section',
        resource: store.context.app + '.' + store.context.view,
        id: store.context.id,
        path,
        data,
        sources: sources,
        source: source,
        project: store.context.project,
        selectResource: 'cms.sections',
        listId: listId,
        filterId: filterId,
        isPending: store.ui.pending[listId],
        sourceCtx: {project: source},
        pagePath: [store.context.app+'.'+store.context.view, store.context.id].join('/'),
        sourceData: store.lists[listId],
        display: store.ui.listDisplay[listId] || 'list',
        filter: selectFilter(store, filterId),
        cmsTags,
        accessAllProjects: isDevUser
    }
};

const mapDispatchToProps = {
    onChange: draftsPathChange,
    onReorder: draftsPathReorder,
    onAttach: draftsPathAttach,
    onDetach: draftsPathDetach,
    onSubmit: draftsSubmit,
    onCancel: draftsCancel,
    onImport: (project, from, to)=>({type:'LIBRARY.IMPORT', project, from, to}),
    onExport: (project, from)=>({type:'LIBRARY.EXPORT', project, from}),
    query,
    listFetch,
    listFilterSet
};

class AttachSection extends React.PureComponent {

    componentDidMount() {
        this.fetchStyles();
        this.enforceFilter();
    }

    componentDidUpdate(prevProps) {
        if (changed(['project', 'source'], prevProps, this.props)) {
            this.fetchStyles();
            this.enforceFilter();
        }
    }

    enforceFilter = () => {
        if (this.props.cmsTags && !Object.keys(this.props.filter).length) {
            this.props.listFilterSet(
                this.props.filterId,
                {...this.props.filter, cms_tags: this.props.cmsTags[0]}
            );
        }
    };

    fetchStyles = () => {
        if (this.props.project === this.props.source) return;
        console.log('calling fetchStyles');
        this.props.listFetch('theme.styles', 'theme.styles.'+this.props.source, false, {
            project: this.props.source
        });
    };

    handleReorder = (drag, hover) => {
        this.props.onReorder(
            this.props.path.split('/'),
            drag,
            hover,
            this.props.data
        );
    };

    handleClose = () => {
        if (this.props.open) this.props.query({q: null});
    };

    handleAttach = (id) => {
        if (this.props.data.indexOf(id) > -1) return;
        this.props.onAttach(this.props.path.split('/'), id, null, this.props.data);
    };

    handleChange = (order) => {
        this.props.onChange(
            this.props.path.split('/'),
            order
        );
        return;
    };

    handleImport = (id, order) => {
        if (this.props.project !== this.props.source) {
            this.props.onImport(
                this.props.source,
                [this.props.selectResource, id],
                this.props.path.split('/')
            );
            return;
        }
    };

    handleDetach = (id) => {
        this.props.onDetach(this.props.path.split('/'), id, this.props.value);
    };

    handleExport = ({data, copy}) => {
        let id = Object.keys(copy)[0];
        if (this.props.sourceData.indexOf(id) > -1) return;
        
        if (this.props.project !== this.props.source) {
            this.props.onExport(
                this.props.source,
                [this.props.selectResource, id]
            );
            return;
        }
    };

    handleSubmit = () => {
        this.handleClose();
        this.props.onSubmit(this.props.resource, this.props.id);
    };
    handleCancel = () => {
        this.handleClose();
        if (this.props.app !== 'ide') {
            this.props.onCancel(this.props.resource, this.props.id);
        }
    };

    handleChangeSource = (id) => {
        this.props.query({src: id});
    };

    handleTargetActionRequest = (action, resource, id) => {
        switch (action) {
            case 'export': this.handleExport(id); break;
            default: break;
        }
    };
    
    handleDrop = ({id}) => {
        if (this.props.data.length > 0) return;
        
        if (this.props.source !== this.props.project) {
            this.handleImport(id);
            return;
        }
        if (!this.props.data.length) {
            this.handleAttach(id);
        }
    };

    wrapDialog = (content) => {
        const { open } = this.props;
        return (<Dialog
            open={open}
            onClose={this.handleCancel}
            maxWidth={"lg"}
            fullWidth={true}
            TransitionComponent={Grow}
        >
            {content}
        </Dialog>);
    };

    wrapDrawer = (content) => {
        const {open, display} = this.props;
        return (<SideSheet
            open={open}
            size={display === 'list' ? 'sm' : 'lg'}
        >
            {content}
        </SideSheet>);
    };

    shouldFetch = () => {
        return !!Object.keys(this.props.filter).length;
    };


    render() {
        const { open, wrap } = this.props;
        let content = <AttachSectionDialog
            {...this.props}
            onChange={this.handleChange}
            onReorder={this.handleReorder}
            onAttach={this.handleAttach}
            onDetach={this.handleDetach}
            onSubmit={this.handleSubmit}
            onCancel={this.handleCancel}
            onImport={this.handleImport}
            onExport={this.handleExport}
            onChangeSource={this.handleChangeSource}
            onDrop={this.handleDrop}
            onTargetActionRequest={this.handleTargetActionRequest}
            resource={this.props.selectResource}
            hold={!this.shouldFetch()}
        />;
        if (wrap === 'drawer') return this.wrapDrawer(content);
        if (wrap === 'dialog') return this.wrapDialog(content);
        return content;
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AttachSection);