import React from 'react';
import {connect} from 'react-redux';

import PresetBrowserImpl from 'components/fields/theme/PresetBrowser';
import { setUiFlag } from "state/actions/ui";

const mapStateToProps = (store, props) => {
    return {
        listId: 'console.presets',
        filterId: 'console.presets',
        mode: store.ui.flags['preset_browser.mode'] || 'presets'
    }
};

const mapDispatchToProps = {
    onSetUiFlag: setUiFlag
};

class PresetBrowser extends React.PureComponent {

    handleChangeMode = (mode) => this.props.onSetUiFlag('preset_browser.mode', mode);
    
    handleSelect = (id, data) => {
        let key = this.props.resource === 'colors'
            ? 'color_scheme'
            : 'typography';
        let update = data[key]
            ? data[key]
            : data;
        this.props.onSelect(id, update);
    };

    render() {
        return (<PresetBrowserImpl
            {...this.props}
            onChangeMode={this.handleChangeMode}
            onClick={this.handleSelect}
        />);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PresetBrowser);