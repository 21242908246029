import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { styled } from "state/selectors/styles";
import { selectResource } from "state/selectors/resources";
import { query, resourceRoute } from "state/actions/route";
import { sectionClick } from "state/actions/ide";

import Component from "ide/Component";
import { MergeDataContext } from "ide/DataContext";
import ColumnsLayout from "ide/components/Layout";
import Image from "ide/components/Image";
import SectionToolbar from "ide/ui/toolbar/Section";
import SectionEmptyState from "ide/ui/SectionEmptyState";

import VisibilitySensor from "react-visibility-sensor";

export const hasBackground = (section, display, s) => {
  let isParallax = display ? display.indexOf("parallax") > -1 : false;
  if (s.bool("background")) isParallax = true;
  return isParallax;
};

const background = (section, display, s) => {
  if (!hasBackground(section, display, s)) return null;
  return section.media ? section.media[0] : null;
};

const mapState = (store, props) => {
  return {
    id: props.ctxData.id,
    isSelected:
      props.ctxData.id === store.context.sc ||
      props.ctxData.id === store.context.scr ||
      props.ctxData.id === store.context.relatedId,
    s: styled(store, props, "section", props.data),
    display: props.data.display || props.ctxData.display || [],
    isPending:
      store.ui.pending["ide.page"] ||
      store.ui.pending[props.ctxData.id] ||
      store.ui.pending[props.data.id],
    enableDnd: !props.editable && !store.SSR,
    isVisible: store.SSR || props.isVisible,
    stylesReady: store.ui.styles,
  };
};

const mapDispatch = {
  query,
  resourceRoute,
  sectionClick,
};

class SectionContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
    this.events = {
      onClick: this.handleClick,
      onMouseEnter: this.handleMouseEnter,
      onMouseLeave: this.handleMouseLeave,
    };
    this.domRef = React.createRef();
    this.legacyRef = { current: null };
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (
      !nextProps.isVisible ||
      this.props.isDragging ||
      this.props.isOver ||
      nextProps.isOver
    )
      return false;
    return true;
  }

  handleClick = (evt) => {
    this.props.sectionClick(
      this.props.id,
      this.props.data.id,
      evt.componentId,
      {
        shift: evt.shiftKey,
        ctrl: evt.ctrlKey,
        alt: evt.altKey,
      }
    );
  };

  handleMouseEnter = () => {
    this.setState({ hover: true });
  };

  handleMouseLeave = () => {
    this.setState({ hover: false });
  };

  setRef = (node) => {
    this.legacyRef = { current: node };
  };

  render() {
    const { enableDnd } = this.props;
    const props = {
      ...this.props,
      id: this.props.data.id,
      events: this.events,
      setRef: !enableDnd ? this.domRef : this.setRef,
      domRef: !enableDnd ? this.domRef : this.legacyRef,
      isHovered: this.state.hover || this.props.isSelected,
      resource: "cms.sections",
    };
    if (!enableDnd) return <Section {...props} />;
    return <DraggableSection {...props} />;
  }
}

class Section extends React.PureComponent {
  withDnd = (section) => {
    if (!this.props.enableDnd || !this.props.connectDnd) return section;
    return this.props.connectDnd(section);
  };

  render() {
    const {
      s,
      isHovered,
      data,
      display,
      isVisible,
      isSelected,
      isPending,
      editable,
      stylesReady,
    } = this.props;

    const showToolbar = isSelected || isHovered;
    const visibility = !editable || isVisible;
    let sectionStyle = s.display("section", data) + " u-scroll";
    //let sectionStyle = s.get('section');
    if (this.props.isPending || !visibility) sectionStyle += " is-pending";
    const bg = background(data, display, s);
    const hasBg = hasBackground(data, display, s);
    //if (sectionStyle.indexOf('l-fixed-overlay') > -1 && !isSelected) return null;
    if (!data.active) return null;
    if (hasBg) sectionStyle += " section--bg";

    const renderContent =
      visibility && stylesReady && data && data.items && data.items.length;

    const section = (
      <section
        ref={this.props.setRef}
        {...this.props.events}
        className={sectionStyle}
      >
        {renderContent && bg ? (
          <Image
            media={bg}
            outerClassName={"section-background " + s.get("image_outer")}
            className={"block-image " + s.get("image")}
            overlayClassName={"Background__overlay " + s.get("overlay")}
            size={s.get("image_size")}
          />
        ) : null}
        <div
          ref={this.props.innerRef}
          className={"PageSection__wrapper section-wrapper " + s.get("wrapper")}
        >
          {renderContent ? (
            <ColumnsLayout
              s={s}
              data={data}
              layoutKey="columns"
              areaKey="column"
              childKey="items"
            />
          ) : (
            <SectionEmptyState isPending={isPending || !stylesReady} />
          )}
        </div>
        {editable && isVisible ? (
          <SectionToolbar
            hasBackground={hasBg}
            id={this.props.ctxId}
            layout={this.props.layout}
            anchorEl={this.props.domRef.current}
            open={showToolbar}
            sectionDisplay={display}
            onChange={this.props.onChange}
            onMouseEnter={this.props.events.onMouseEnter}
          />
        ) : null}
      </section>
    );

    return this.withDnd(section);
  }
}

function SectionVisibility(props) {
  return (
    <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => {
        return props.id ? (
          <SectionContainer {...props} isVisible={isVisible} />
        ) : null;
      }}
    </VisibilitySensor>
  );
}

const DraggableSection = Section; //connectSortable(Section);
const ConnectedSection = connect(mapState, mapDispatch)(SectionVisibility);

const mapStateSection = (store, props) => {
  const data = selectResource(store, "cms.sections", props.id);
  const template = data.template
    ? selectResource(store, "cms.sections", data.template)
    : null;
  const path = "cms.sections/" + props.id;
  let viewPath = path;
  if (data.template) viewPath = "cms.sections/" + data.template;
  if (template && template.template)
    viewPath = "cms.sections/" + template.template;
  return {
    ctxId: props.id,
    isTemplate: data.type === "view",
    ctxPath: path,
    path: viewPath, //data.template ? 'cms.sections/' + data.template : path
  };
};

const implicitMapping = {
  media: "media",
  //display: 'display',
  breakpoints: "breakpoints",
  styles: "styles|merge",
};

class SectionConnector extends React.PureComponent {
  render() {
    if (this.props.isTemplate) {
      return <SectionEmptyState text="Section template" />;
    }
    const component = (
      <Component
        ctxId={this.props.ctxId}
        index={this.props.index}
        type="section"
        virtual={true}
        s={this.props.s}
        path={this.props.path}
        layout={this.props.layout}
        mapping={implicitMapping}
        connectedComponent={ConnectedSection}
        connectDnd={this.props.connectDnd}
        {...this.props.itemProps}
      />
    );

    if (this.props.ctxPath) {
      return (
        <MergeDataContext value={this.props.ctxPath}>
          {component}
        </MergeDataContext>
      );
    } else {
      return component;
    }
  }
}

export default connect(mapStateSection)(SectionConnector);

Section.propTypes = {
  id: PropTypes.string.isRequired,
};
