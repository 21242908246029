import React from 'react';
import PropTypes from 'prop-types';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import TooltipIconButton from 'components/TooltipIconButton';
import Resource from "containers/Resource";
import CircularProgress from '@material-ui/core/CircularProgress';
import DateDiff from 'components/list/fields/DateDiff';
import 'css/notifications.css';

const iconRef = React.createRef();

class StatusIcon extends React.PureComponent {
    render() {
        const { status, size } = this.props;
        return (<div className="notification__status-icon">
            { status === 'success'
                ? <Icon>check</Icon>
                : <CircularProgress
                    color="inherit"
                    size={size || 24}
                /> }
        </div>);
    }
}

export default class Notifications extends React.PureComponent {

    render() {
        const { count, open, events } = this.props;
        return (<React.Fragment>
            <div ref={iconRef} style={{ display: 'flex', alignItems: 'center' }}>
                {count ? <CircularProgress
                    color={"inherit"}
                    size={16}/>
                : null }
                <TooltipIconButton
                    onClick={this.props.toggleNotifications}
                    icon="notifications"
                    tooltip="Zadania"
                />
            </div>
            <Popper
                anchorEl={iconRef.current}
                open={open}
                style={{ zIndex: 1100 }}
            >
                <Paper elevation={3} square={true}>
                    {events && events.map ? events.map((item) => {
                        let showDetails = item.status !== 'success' && item.subtasks.length > 1;
                        return (<div key={item.id} className="notification">
                            <div className="notification__info">
                                <StatusIcon status={item.status} />
                                <Resource
                                    resource={item.resource}
                                    id={item.resource_id}
                                    render={(props)=>
                                        <div className="notification__title">{props.data.name}</div>}
                                />
                            </div>
                            <div className="notification__subtitle">
                                {item.message || <DateDiff value={item.created_at} />}
                            </div>
                            {showDetails ? <div className="notification__log">{item.subtasks.map((id) => {
                                return (<div key={id} className="notification__log_entry"><Resource
                                    resource={'app.notifications.tasks'}
                                    id={id}
                                    render={(props)=>{
                                        return (<React.Fragment>
                                            <StatusIcon status={props.data.status} size={12}/>
                                            {props.data.message}
                                        </React.Fragment>);
                                    }}
                                /></div>)
                            })}</div> : null}
                        </div>);
                    }) : null }
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0,0,0,0.05)'
                    }}>
                        <TooltipIconButton
                            onClick={this.props.toggleNotifications}
                            tooltip="Ukryj"
                            icon="expand_less"
                        />
                    </div>
                </Paper>
        </Popper>
        </React.Fragment>);
    }

}

Notifications.propTypes = {};