import React from 'react';
import {connect} from 'react-redux';
import {selectUiTheme} from 'state/selectors/app';
import {setIn} from 'lodash-redux-immutability';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { themeConfig } from 'config/theme';
const defaultTheme = createMuiTheme(themeConfig);

const createCustomMuiTheme = (defaultConfig, preset) => {
    console.log(defaultConfig, preset);
    let customConfig = setIn(
        defaultConfig,
        ['palette', 'primary', 'main'],
        preset.color_scheme.primary_color
    );
    customConfig = setIn(
        customConfig,
        ['palette', 'secondary', 'main'],
        preset.color_scheme.success_color
    );
    return createMuiTheme(customConfig);
};

const mapStateToProps = (store, props) => {
    let preset = selectUiTheme(store);
    /*return {
        theme: preset
            ? createCustomMuiTheme(themeConfig, preset)
            : defaultTheme
    }*/
    return {
        theme: defaultTheme
    }
};

const mapDispatchToProps = {};

class ConsoleThemeProvider extends React.Component {

    componentDidUpdate(prevProps) {
        if (this.props.theme !== prevProps.theme) {
            const palette = this.props.theme.palette;
            const {primary,secondary} = palette;
            document.documentElement.style.setProperty('--primary-color', primary.main);
            document.documentElement.style.setProperty('--success-color', secondary.main);
        }
    }

    render() {
        const { theme } = this.props;
        return <MuiThemeProvider theme={theme}>
            {this.props.children}
        </MuiThemeProvider>;
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConsoleThemeProvider);