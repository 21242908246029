import React from 'react';
import {connect} from 'react-redux';

import { fetchComponentData } from "state/actions/ide";
import ListImpl from 'ide/components/List';

import {
    draftsChange,
    draftsPathCreate,
    draftsPathDetach,
    draftsPathDelete,
    resourceDeleteSuccess
} from "state/actions";

const parseListDataSource = (props, data) => {
    if (data.entry_type) {
        return {
            dstype: 'entries',
            dsentry_type: data.entry_type,
            dsresource: 'cms.entries.' + data.entry_type,
            dspath: 'component.'+data.id+'.entries',
            dsitemPath: 'cms.entries.' + data.entry_type
        }
    } else {
        return {
            dstype: 'items',
            dspath: props.mapped && props.mapped.items
                ? props.ctxPath + '/' + props.mapping.items
                : props.path + '/items',
            dsitemPath: props.mapping && props.mapping.items === 'links'
                ? 'cms.nav-link'
                : 'cms.items'
        }
    }
};

const mapStateToProps = (store, props) => {
    const data = props.data;
    const dataSource = parseListDataSource(props, data);
    const items = dataSource.dstype === 'entries'
        ? store.lists[dataSource.dspath]
        : data.items;
    const components = data.template_component
        ? store.data['cms.components'][data.template_component].components
        : data.components;
    return {
        ...dataSource,
        listTags: store.lists[[dataSource.dspath,'tags'].join('.')],
        items,
        components
    }
};

const mapDispatchToProps = {
    fetchComponentData,
    updatePath: draftsChange,
    onCreateItem: draftsPathCreate,
    deleteResource: resourceDeleteSuccess,
    onDeleteItem: draftsPathDetach
};

const implicitMapping = {
    title: 'title',
    media: 'media',
    headline: 'headline',
    text: 'text',
    icon: 'icon',
    style: 'style'
};

class List extends React.PureComponent {

    constructor(props) {
        super(props);

        this.itemProps = props.editable ? {
            onMouseEnter: this.handleMouseEnter,
            onMouseLeave: this.handleMouseLeave,
            onClick: this.handleClick
        } : null;

        this.state = {
            hover: null,
            open: false
        }
    }

    componentDidMount() {
        const { items } = this.props;
        if (!items) this.fetch();
    }

    handleMouseEnter = (evt, id) => {
        this.setState({
            hover: id,
            anchorEl: evt.currentTarget
        });
    };

    handleMouseLeave = (evt, id) => {
        if (id === this.state.hover) {
            //this.setState({hover: null, anchorEl: null});
        }
    };

    handleClick = (evt, id) => {
        this.setState({
            hover: id,
            open: !this.state.open,
            anchorEl: evt.currentTarget
        });
        if (this.props.onClick) this.props.onClick(evt);
        
    };

    fetch = () => {
        if (this.props.dstype === 'entries') {
            this.props.fetchComponentData(
                this.props.data,
                this.props.dspath
            );
        }
    };

    handleCreateItem = () => {
        this.props.onCreateItem(
            'cms.items',
            this.props.dspath.split('/'),
            {},
            this.state.hover
        );
    };

    handleDeleteItem = () => {
        this.props.onDeleteItem(
            this.props.dspath.split('/'),
            this.state.hover
        );
        this.props.deleteResource('cms.items', this.state.hover);
    };


    handleFocus = () => {

    };

    render() {
        return (<ListImpl
            {...this.props}
            {...this.state}
            onDeleteItem={this.handleDeleteItem}
            onCreateItem={this.handleCreateItem}
            domRef={e=>(this.domRef=e)}
            itemProps={this.itemProps}
            mapping={implicitMapping}
        />);
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(List);