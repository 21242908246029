import React from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import StaticList from 'containers/list/Static';
import ResourceList from 'containers/ResourceList';
import ResourceListItem from 'components/list/ResourceListItem';
import { SelectField } from 'components/fields/select/SelectField';

const options = [
    {id:window.APP_INSTANCE_ID},
    {id:'production-ecs'},
    {id:'michal-dev'},
    {id:'szczepan-dev'},
    {id:'szczepan-work-dev'}
];

export default class CmsSnapshots extends React.PureComponent {

    render() {
        const { resource, filters } = this.props;
        return (<div style={{ minWidth: '600px', padding: '1rem', overflow: 'hidden auto'}}>
            <SelectField
                id="ns"
                value={filters.ns || window.APP_INSTANCE_ID}
                options={options}
                label="Instance ID"
                onChange={(value)=>this.props.onSetFilter('ns', value)}
            />
            <List>
                <StaticList resource={resource}
                    list={resource}
                    render={(props)=><ResourceList
                        {...props}
                        filterId={resource}
                        actions={resource}
                        itemComponent={ResourceListItem}
                    />}
                />
            </List>
        </div>);
    }

}

CmsSnapshots.propTypes = {
    resource: PropTypes.string.isRequired,
    filters: PropTypes.object
};