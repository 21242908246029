import React from 'react';
import {connect} from 'react-redux';
import {query} from 'state/actions/route';
import {listAppend, draftsPathChange, draftsPathAttach, draftsSubmit} from "state/actions";
import _ from 'lodash';

import Dialog from 'components/Dialog';
import ResourceList from "v2/ResourceList";

const findFieldSchema = (store, path) => {
    let type = path[0].split('.');
    let field = path[2];
    let schemaPath = ['db.types.byName', type[1], 'fields_index', field];
    let schema = _.get(store.data, schemaPath);
    return schema;
};

const mapStateToProps = (store, props) => {
    let path = store.context.target.split('/');
    return {
        resource: store.context.find,
        path: path,
        schema: findFieldSchema(store, path)
    }
};

const mapDispatchToProps = {
    query,
    onChange: draftsPathChange,
    onAttach: draftsPathAttach,
    onAppend: listAppend,
    onSubmit: draftsSubmit
};

class FinderDialog extends React.PureComponent {

    handleClose = () => {
        //this.props.onClose();
        this.props.query({find: null});
    };

    handleSelect = (id) => {
        if (this.props.schema && this.props.schema.properties.multiple) {
            let parentId = this.props.schema.properties.parent_id;
            if (parentId) {
                let resourceParentPath = [this.props.resource, id, parentId];
                this.props.onChange(resourceParentPath, this.props.path[1]);
                this.props.onAppend(this.props.path.join('.'), id);
                this.props.onSubmit(this.props.resource, id);
                this.props.onSubmit(this.props.path[0], this.props.path[1]);
                return;
            }
            this.props.onAttach(this.props.path, id);
        } else {
            this.props.onChange(this.props.path, id);
        }
        this.handleClose();
    };

    render() {
        const { open, resource } = this.props;
        return (<Dialog
            open={open}
            onClose={this.handleClose}
            hideCloseButton={false}
        >
            <div className="dialog dialog--finder console-context">
            <ResourceList
                list={resource}
                resource={resource}
                viewType="list"
                onClick={this.handleSelect}
            />
            </div>
        </Dialog>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FinderDialog);