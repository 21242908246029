import React from 'react';
import PropTypes from 'prop-types';

export default class ImagePlaceholder extends React.PureComponent {

    render() {
        return (<div onClick={this.props.onClick}
             className="image-placeholder">
            <span style={{ opacity: 0.5 }}>
                Kliknij, aby dodać zdjęcie
            </span>
        </div>);
    }

}

ImagePlaceholder.propTypes = {};