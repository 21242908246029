import React from 'react';
import _ from "lodash";

export default function withScroll(offset) {
    return function (DecoratedComponent) {
        return class WithScroll extends React.PureComponent {
            constructor(props) {
                super(props);
                this.state = {scroll: false};
                this.offset = offset;
                this.scrollListener = null;
            }

            componentDidMount() {
                this.scrollListener = window.addEventListener(
                    'scroll',
                    _.debounce(this.handleScroll, 20)
                );
            }

            componentWillUnmount() {
                window.removeEventListener('scroll', this.scrollListener);
            }

            handleScroll = () => {
                if (window.scrollY >= this.offset && !this.state.scroll) {
                    this.setState({...this.state, scroll: true});
                }
                if (window.scrollY < this.offset && this.state.scroll) {
                    this.setState({...this.state, scroll: false});
                }
            };

            render() {
                return (<DecoratedComponent
                    {...this.props}
                    scroll={this.state.scroll}
                />);
            }
        }
    }
}