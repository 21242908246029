import React from 'react';
import {connect} from 'react-redux';
import Card from '@material-ui/core/Card';
import Typography from "@material-ui/core/Typography";

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';

import { icon } from 'util/icon';
import { filterList } from 'state/selectors/resources';
import _ from 'lodash';

const config = {
    'cms.components': {
        suggested: [
            'text',
            'structured',
            'list',
            'button',
            'template',
            'value',
            'nav',
            'icon',
            'card',
            'media-element',

            'collection',
            'checkbox',
            'select',
            'tab'
        ],
        legacy: [
            'contact-details',
            'instagram',
            'social',
            'media',
            'slider',
            'list-static'
        ]
    },
    'db.fields': {
        suggested: [
            'text',
            'select',
            'tab',
            'checkbox',
            'collection',
            'address',
            'media'
        ],
        legacy: []
    },
    'db.view_fields': {
        suggested: [
            'field',
            'layout',
            'tab',
            'tabs'
        ]
    }
};

const groupComponentTypes = (resources, tabs) => {
    return tabs.map((tab) => {
        return {...tab, items: Object.values(filterList(resources, {}, tab.filter))}
    });
};

const mapStateToProps = (store, props) => {
    const path = props.resource.split('.').concat(['types']);
    const resources = _.get(store.schema, path);
    const typesConfig = config[props.resource];
    const other = Object.keys(resources).filter((k)=>{
        return typesConfig.suggested.concat(typesConfig.legacy).indexOf(k) === -1;
    });
    const tabs = groupComponentTypes(resources,[
        {id: 'suggested', label: 'Często używane', filter: typesConfig.suggested},
        {id: 'components', label: 'Wszystkie', filter: other},
        {id: 'legacy', label: 'Legacy', filter: typesConfig.legacy}
    ]);
    return {
        tabs: tabs.filter(tab=>tab.items.length)
    }
};

const mapDispatchToProps = {};

const outerStyle = {
    padding: 0,
    backgroundColor: 'rgba(0,0,0,0.05)',
    width: '480px'
};

export class TypeCard extends React.PureComponent {
    render() {
        const { id, label, onClick } = this.props;
        const iconName = this.props.icon;
        return <div key={id} className="app-nav-resource">
            <Card
                className="card-icon"
                onClick={onClick}
            >
                <div className="card-icon__icon">
                    {icon(iconName)}
                </div>
                <Typography>{label}</Typography>
            </Card>
        </div>
    }
}

class ComponentTypeSelect extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {tab: 'suggested'};
    }

    handleCreate = (type) => {
        this.props.onClose();
        this.props.onCreate(type);
    };

    renderGroup = (key, components) => {
        const { resource } = this.props;
        return <div key={key} className="app-nav-resources app-nav-resources--4">
            {components.map((component) => {
                return (<div key={component.id} className="app-nav-resource">
                    <Card
                        className="card-icon"
                        onClick={()=>this.handleCreate(component.id)}
                    >
                        <div className="card-icon__icon">
                            {icon([resource,component.id].join('.'))}
                        </div>
                        <Typography>{component.label}</Typography>
                    </Card>
                </div>);
            })}
        </div>;
    };

    handleChange = (evt, tab) => {
        this.setState({tab});
    };

    render() {
        const { tabs } = this.props;
        const tab = this.state.tab;
        return (<div style={outerStyle}>
            { tabs.length > 1 ?<Paper>
                <Tabs
                    value={this.state.tab}
                    onChange={this.handleChange}
                    centered
                >
                {tabs.map((t)=><Tab key={t.id} value={t.id} label={t.label} />)}
                </Tabs>
            </Paper> : null }
            <div style={{ padding: '1rem' }}>
                {tabs.map((t)=>t.id===tab ? this.renderGroup(t.id, t.items) : null)}
            </div>
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ComponentTypeSelect);