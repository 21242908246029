import React from "react";
import Quill from "components/quill/SmartBreakQuill";
//import Quill from "react-quill";
import { quillToolbar } from "config/quill";

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.bubble.css';
import 'quill/dist/quill.snow.css';

export default class RichEditor extends React.PureComponent {

    handleChange = (value) => {
        this.props.onChange(this.props.id, value);
    };

    render() {
        const { value } = this.props;

        const styles = {
            marginTop: 0,
            marginBottom: 0,
            maxWidth: "100%",
            boxShadow: "0px 2px 6px rgba(0,0,0,0.1)"
        };

        const editorStyle = this.props.style || styles;
        const className =
            this.props.className || "text-outer t-default ql-text-width";

        return (<>
            <Quill
                theme={"snow"}
                modules={{
                    toolbar: quillToolbar,
                    clipboard: {
                        matchVisual: false
                    }
                }}
                className={className}
                style={editorStyle}
                value={value || ''}
                onChange={this.handleChange}
            />
        </>);
    }
}
