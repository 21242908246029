import React from 'react';
import {connect} from 'react-redux';

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

class SyncMapping extends React.Component {

    render() {
        return (<div>SyncMapping</div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SyncMapping);