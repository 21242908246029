import React, {Component} from 'react';
import {connect} from 'react-redux';
import Tree from "react-ui-tree";
import 'react-ui-tree/lib/react-ui-tree.css';
import uuid from 'uuid/v4';

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

class TreeView extends Component {

    handleChange = () => null;

    render() {
        const {
            tree,
            renderNode,
            onDelete,
            onMove,
            onExpand,
            onCollapse
        } = this.props;

        return (<Tree
            key={uuid()}
            tree={tree}
            renderNode={renderNode}
            paddingLeft={24}
            onChange={this.handleChange}
            onMove={onMove}
            onDelete={onDelete}
            onCollapse={onCollapse}
            onExpand={onExpand}
        />);
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(TreeView);