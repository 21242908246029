import React from 'react';
//import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { icon } from 'util/icon';

export default class TabsNav extends React.PureComponent {

    render() {
        const { current, tabs, onChange } = this.props;

        return (<div className="form__tabs-nav">
            { tabs ? tabs.map((tab) => {
                if (!tab.fields) return null;
                return (<Tooltip key={tab.id} title={tab.label}>
                    <IconButton
                        color={current===tab.id ? 'primary' : 'default' }
                        onClick={()=>onChange(tab.id)}
                    >
                        {icon('tab.'+tab.id) || icon('tab.default') || tab.id}
                    </IconButton>
                </Tooltip>);
            }) : null }
        </div>);
    }

}

TabsNav.propTypes = {};