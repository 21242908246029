import React, {Component} from 'react';
import {connect} from 'react-redux';
import {query, resourceRoute} from 'state/actions/route';
import {clipboardCopyResource,clipboardPaste} from 'state/actions/clipboard';

import IconMenu from 'components/IconMenu';
import MenuItem from '@material-ui/core/MenuItem';
import {bindMenuActions} from "components/menu/EnhancedMenu";

import { selectResource } from 'state/selectors/resources';
import { icon } from 'util/icon';

const contextMenu = [
    {
        key: 'edit',
        caption: 'Edytuj',
        icon: 'edit'
    },
    {
        key: 'copy',
        caption: 'Skopiuj',
        icon: 'file_copy'
    },
    {
        key: 'paste',
        caption: 'Wklej',
        icon: 'assignment'
    },
    {
        key: 'remove',
        caption: 'Usuń',
        icon: 'delete'
    },
    {
        key: 'activate',
        caption: 'Włącz/Wyłącz'
    }
];

const mapStateToProps = (store, props) => {
    let resource = props.resource;
    let sectionId = store.context.sc || store.context.relatedId;
    let data = props.id === 'root'
        ? selectResource(store, 'cms.sections', sectionId)
        : selectResource(store, resource, props.id);
    if (!data) return {};
    let type = data.element_type || data.type;
    return {
        section: sectionId,
        resourceTypeKey: resource+'.'+type,
        type: data.element_type || data.type,
        label: data.slug,
        style: data.style,
        isActive: !data.disabled,
        isLeaf: props.node.leaf
    };
};

const mapDispatchToProps = {
    query,
    resourceRoute,
    clipboardCopyResource,
    clipboardPaste
};

class TreeNode extends Component {

    constructor(props) {
        super(props);
        this.state = {open: false, anchorEl: null};
        this.contextMenu = bindMenuActions(contextMenu,{
            edit: this.handleEdit,
            copy: this.handleCopy,
            paste: this.handlePaste,
            remove: this.handleDelete,
            activate: this.handleActivate,
        }, this.contextMenuVisibility);
    }

    contextMenuVisibility = (option) => {
        if (this.props.id === 'root' && option.key !== 'paste') return false;
        if (option.key === 'paste' && this.props.isLeaf) return false;
        return true;
    };

    handleClick = (evt) => {
        evt.stopPropagation();
        this.props.onClick(this.props.id);
    };

    handleEdit = (evt) => {
        if (evt) evt.stopPropagation();
        this.props.onEdit(this.props.id);
    };

    handleDelete = (evt) => {
        //evt.stopPropagation();
        this.props.onDelete(this.props.node.parent, this.props.id);
    };

    handleOpenContextMenu = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({open: true, anchorEl: e.target});
    };

    handleCloseContextMenu = () => {
        this.setState({open: false, anchorEl: null});
    };

    handleActivate = (evt) => {
        //evt.stopPropagation();
        this.props.onChange(this.props.id, 'disabled', !!this.props.isActive);
    };

    handleCopy = (evt) => {
        //evt.stopPropagation();
        this.props.clipboardCopyResource(
            'cms.components',
            this.props.id
        )
    };

    handlePaste = (evt) => {
        //evt.stopPropagation();
        let path = this.props.id === 'root'
            ? ['cms.sections', this.props.section, 'items']
            : ['cms.components',this.props.id,'components'];
        this.props.clipboardPaste('cms.components', path);
    };

    render() {
        const {
            id,
            type,
            label,
            style,
            isSelected,
            isActive,
            resourceTypeKey
        } = this.props;

        let nodeClassName = "component-node";
        if (isSelected) nodeClassName += " selected";
        if (!isActive) nodeClassName += " disabled";
        
        return (<div
            className={nodeClassName}
            onClick={this.handleClick}
            onContextMenu={this.handleOpenContextMenu}
        >
            <span className="component-node__icon">{icon(resourceTypeKey, 'small')}</span>
            <span style={{ flex: 1 }}>{style || label || type || 'section'}</span>
            <IconMenu sm {...this.state} items={this.contextMenu} onClose={this.handleCloseContextMenu}>
                { !this.props.isLeaf ? <MenuItem onClick={this.handlePaste}>Wklej</MenuItem> : null }
                { id !== 'root' ? <React.Fragment>
                    <MenuItem onClick={this.handleActivate}>
                        {isActive ? 'Ukryj' : 'Pokaż'}
                    </MenuItem>
                    <MenuItem onClick={this.handleCopy}>Skopiuj</MenuItem>
                    <MenuItem onClick={this.handleEdit}>Edytuj</MenuItem>
                    <MenuItem onClick={this.handleDelete}>Usuń</MenuItem>
                </React.Fragment> : null }
            </IconMenu>
        </div>);
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(TreeNode);