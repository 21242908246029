import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MuiTableRow from '@material-ui/core/TableRow';
import MuiTableCell from '@material-ui/core/TableCell';

import DragHandle from 'components/list/DragHandle';

import IconButton from 'components/TooltipIconButton';
import Icon from '@material-ui/core/Icon';

import Resource from 'v2/Resource';
import Field from 'v2/Field';
import {RenderValue} from 'v2/list-items';
import ItemActions from "./ItemActions";

export class SimpleTableRow extends React.PureComponent {
    render() {
        const { fields, resource, id } = this.props;
        return <tr>{fields ? fields.map(fieldId=>{
            return (<Resource resource="db.view_fields" id={fieldId} fetchPolicy="never">
                {(viewFieldProps)=><Field resource={resource} id={id} fieldName={viewFieldProps.data.source}>
                    {(props)=>{
                        let isPrimary = viewFieldProps.data.source==='primaryText';
                        return <td
                            className={{
                                'table-cell--link': isPrimary
                            }}
                            onClick={isPrimary ? this.handleClick : null}>
                            <RenderValue {...props} fieldName={viewFieldProps.data.source} />
                        </td>
                    }}
                </Field>}
            </Resource>);
        }) : null}</tr>;
    }
}

export default class TableRow extends React.PureComponent {

    static ownDragHandle = true;

    handleClick = (evt) => {
        this.props.onClick(this.props.id);
    };

    handleDelete = (evt) => {
        this.props.onDelete(this.props.id);
    };

    handleActionRequest = (type, resource, id) => {
        if (type === 'delete' && this.props.onDelete)
            this.handleDelete();

        if (type === 'edit' && this.props.onClick)
            this.handleClick();

        if (this.props.onActionRequest)
            this.props.onActionRequest(type, resource, id);
    };

    render() {
        const {
            data,
            resource,
            id,
            fields,
            avatar,
            isDragging,
            connectDnd,
            onClick
        } = this.props;
        
        let style = {
            opacity: isDragging ? 0 : 1
        };
        return (<MuiTableRow style={style}>
            {connectDnd ?
                <MuiTableCell padding="checkbox">
                    <DragHandle connectDnd={connectDnd} />
                </MuiTableCell> : null}
            {fields ? fields.map(fieldId=>{
                return (<Resource resource="db.view_fields" id={fieldId} fetchPolicy="never">
                    {(viewFieldProps)=><Field resource={resource} id={id} fieldName={viewFieldProps.data.source}>
                        {(props)=>{
                            let smPadding = viewFieldProps.data.source === 'avatar';
                            let isPrimary = viewFieldProps.data.source==='primaryText';
                            return <MuiTableCell
                                className={{
                                    'table-cell--link': isPrimary
                                }}
                                padding={smPadding ? 'checkbox' : 'default'}
                                onClick={isPrimary ? this.handleClick : null}>
                                <RenderValue {...props} fieldName={viewFieldProps.data.source} />
                        </MuiTableCell>
                        }}
                    </Field>}
                </Resource>);
            }) : null}
            <MuiTableCell padding="checkbox">
                <ItemActions
                    onActionRequest={this.handleActionRequest}
                    resource={resource}
                    id={id}
                    actions='default'
                    isOpen={false}
                />
            </MuiTableCell>
        </MuiTableRow>);
    }

}

TableRow.propTypes = {};