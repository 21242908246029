import React from 'react';
import PropTypes from 'prop-types';
import {DndConnector} from "list/dnd";

export class SortableItem extends React.PureComponent {
    renderItem = (props) => {
        const {
            id,
            index,
            listId,
            resource,
            itemHandlers,
            renderItem,
            ...other
        } = this.props;
        return renderItem({
            ...props,
            ...other,
            isPlaceholder: itemHandlers.isPlaceholder ? itemHandlers.isPlaceholder({
                id,
                isDragging: props.isDragging
            }) : false
        });
    };

    render() {
        const {
            id,
            index,
            listId,
            resource,
            itemHandlers,
            renderItem,
            level,
            ...other
        } = this.props;
        
        return <DndConnector
            key={id}
            index={index}
            id={id}
            origin={listId || resource}
            resource={resource}
            sortable={true}
            level={level}
            {...itemHandlers}
        >
            {this.renderItem}
        </DndConnector>;
    }
}