import * as tree from "util/tree";
import uuid from "uuid/v4";

const mapIdentifiers = from => {
  const mapped = {};
  from.forEach(sourceId => {
    mapped[sourceId] = uuid();
  });
  return mapped;
};

export const cloneComponent = component => {
  const normalized = tree.normalize([component]);
  const identityMap = mapIdentifiers(Object.keys(normalized));
  const mapped = {};
  Object.keys(normalized).forEach(id => {
    let clonedItem = { ...normalized[id] };
    clonedItem.id = identityMap[id];
    clonedItem.parentId = identityMap[clonedItem.parentId];
    clonedItem.data.id = clonedItem.id;
    if (clonedItem.data.items) {
      clonedItem.data.items = clonedItem.data.items.map(item=>({...item, id: uuid()}))
    }
    mapped[clonedItem.id] = clonedItem;
  });
  return Object.values(mapped);
};

export const cloneClipboardContents = store => {
  const fragment = store.clipboard.content;
  return cloneComponent(fragment);
};

export const cloneSection = (section, withTemplate, sourceProject) => {
  const sectionCopy = { ...section };
  let styles = [];
  let sections = [];
  const items = sectionCopy.items ? sectionCopy.items.map(item => {
    let list = cloneComponent(item);
    list.forEach(component => {
      if (component.data.style) styles.push(component.data.style);
    });
    return tree.denormalize(list)[0];
  }) : null;
  const gallery = sectionCopy.gallery ? sectionCopy.gallery.map(item => {
    let list = cloneComponent(item);
    list.forEach(component => {
      if (component.data.style) styles.push(component.data.style);
    });
    return tree.denormalize(list)[0];
  }) : null;
  sectionCopy.library_ref = [sourceProject,section.id].join('.');
  sectionCopy.items = items;
  sectionCopy.id = uuid();
  sectionCopy.title = section.title;
  sectionCopy.slug = section.slug;
  sectionCopy.gallery = gallery;
  sectionCopy.tags = null;
  if (sectionCopy.template && withTemplate) {
    let templateCopy = cloneSection(sectionCopy.template, true, sourceProject);
    templateCopy.sections[0].type = 'view';
    sectionCopy.template = templateCopy.sections[0].id;
    styles = styles.concat(templateCopy.styles);
    sections = sections.concat(templateCopy.sections);
  }
  if (section.style) styles.push(section.style);
  if (sectionCopy.template && sectionCopy.template.id) sectionCopy.template = sectionCopy.template.id;
  sections.push(sectionCopy);
  return { sections: sections, styles: styles };
};

export const selectComponentStyles = (store, components) => {
  if (store.routes.params.projectId === store.clipboard.origin) return null;
  let styles = [];
  components.forEach(c => {
    if (c.data.style) styles.push(c.data.style);
  });
  return { website_id: store.clipboard.origin, styles: styles };
};

export const selectCopiedResource = (store, resource) => {
  const resources = store.clipboard[resource];
  return resources ? resources.slice().pop() : null;
};
