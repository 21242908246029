import React from 'react';
import {connect} from 'react-redux';
import Field, {RenderField} from 'v2/Field';
import Select from 'v2/fields/Select';
import classNames from 'classnames';
import {Icon} from "@material-ui/core";
import Edit from 'ide/ui/Edit';

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

class TaskField extends React.PureComponent {
    render() {
        const { onClick, value, label } = this.props;
        return <div className={'task-field'} onClick={onClick}>
            <div className={'task-field__label'}>{label}</div>
            {this.props.children}
        </div>;
    }
}

class TaskAssignedToValue extends React.PureComponent {
    render() {
        const { value, label } = this.props;
        return <TaskField {...this.props}>
            <div className={classNames({
                'task-assign task-field__value': true,
                'is-empty': !value
            })}>
                <div className={'task-assign__avatar'}>
                </div>
                <div className={'task-assign__value'}>
                    {value ? this.props.children : label}
                </div>
            </div>
        </TaskField>;
    }
}

class TaskStatusValue extends React.PureComponent {
    render() {
        const { value, label } = this.props;
        return <TaskField {...this.props}>
            <div className={classNames({
                'task-assign task-field__value': true,
                'is-empty': !value
            })}>
                <div className={'task-assign__value'}>
                    {value ? this.props.children : label}
                </div>
            </div>
        </TaskField>;
    }
}

class TaskDateValue extends React.PureComponent {
    render() {
        const { value, label } = this.props;
        return <TaskField {...this.props}>
            <div className={classNames({
                'task-date task-field__value': true,
                'is-empty': !value
            })}>
                <div className={'task-date__icon'}>
                    <Icon color={'inherit'} fontSize={'inherit'}>event</Icon>
                </div>
                <div className={'task-date__value'}>
                    {value || label}
                </div>
            </div>
        </TaskField>;
    }
}

class TaskFlagValue extends React.PureComponent {
    render() {
        const { value, label } = this.props;
        return <TaskField {...this.props}>
            <div className={classNames({
                'task-field__value': true,
                'active': value
            })}>
                {label}
            </div>
        </TaskField>;
    }
}

class TaskDescriptionValue extends React.PureComponent {
    render() {
        const { label, value, onChange } = this.props;
        return <div className={classNames({
            'task-description': true,
            'is-empty': !value
        })}>
            <Edit
                type={'inline'}
                onChange={onChange}
                placeholder={label}
            >
                {value}
            </Edit>
        </div>;
    }
}

function TaskDate(props) {
    return RenderField({...props, ValueComponent: TaskDateValue});
}

function TaskAssignedTo(props) {
    return RenderField({...props, ValueComponent: TaskAssignedToValue});
}

function TaskStatus(props) {
    return RenderField({...props, ValueComponent: TaskStatusValue});
}

function TaskFlag(props) {
    return RenderField({...props, ValueComponent: TaskFlagValue});
}

function TaskDescription(props) {
    return RenderField({
        ...props,
        ValueComponent: TaskDescriptionValue
    });
}

class TaskHeader extends React.PureComponent {

    render() {
        const { resource, id } = this.props;
        const fieldProps = {resource, id};
        return (<div>
            <Field {...fieldProps} fieldName={'description'}>{TaskDescription}</Field>
            <div className={'task-props'}>
                <Field {...fieldProps} fieldName={'assigned_to'}>{TaskAssignedTo}</Field>
                <Field {...fieldProps} fieldName={'date_due'}>{TaskDate}</Field>
                <div>
                    <Field {...fieldProps} fieldName={'priority'}>{TaskFlag}</Field>
                </div>
                <Field {...fieldProps} fieldName={'lifecycle_status'}>{TaskStatus}</Field>
            </div>
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskHeader);