import React from 'react';
import {connect} from 'react-redux';
import {listFilter,listFilterAppend} from 'state/actions';
import PaginationComponent from 'components/list/Pagination';
import InfiniteScroll from 'react-infinite-scroller';

const mapStateToProps = (store, props) => {
    let filterId = props.filterId || props.listId;
    let filter = store.filter[filterId];
    let pagination = filter ? filter.pagination : {};
    let isPending = store.ui.pending[props.listId];

    let enablePagination = pagination
        ? (pagination.pages_count > 1)
        : false;

    let isLastPage = pagination
        ? pagination.current >= pagination.pages_count
        : true;

    return {
        isPending,
        isLastPage,
        filter,
        ...pagination,
        enablePagination: enablePagination
    }
};

const mapDispatchToProps = {
    onSetPage: listFilter
};

class Pagination extends React.PureComponent {

    handleSetPage = (page) => {
        let filter = {...this.props.filter};
        let pagination = filter.pagination || {};
        filter.pagination = {...pagination, current: page};
        this.props.onSetPage(
            this.props.resource,
            this.props.listId,
            this.props.filterId,
            filter
        );
    };

    render() {
        if (!this.props.enablePagination) return null;
        return (<PaginationComponent
            {...this.props}
            onSetPage={this.handleSetPage}
        />);
    }

}

const mapDispatchScroll = {
    onSetPage: listFilterAppend
};

class ScrollPaginationComponent extends React.PureComponent {
    handleSetPage = (page) => {
        if (this.props.isPending) return;
        let next = this.props.current+1;        
        let filter = {...this.props.filter};
        let pagination = filter.pagination || {};
        filter.pagination = {...pagination, current: next};
        this.props.onSetPage(
            this.props.resource,
            this.props.listId,
            this.props.filterId,
            filter
        );
    };
    render() {
        const { domRef, enablePagination, isLastPage } = this.props;
        return <InfiniteScroll
            pageStart={0}
            initialLoad={false}
            hasMore={!!enablePagination && !isLastPage}
            loadMore={this.handleSetPage}
            useWindow={false}
            loader={<div>loading more...</div>}
        >
            {this.props.children}
        </InfiniteScroll>
    }
}

export const ScrollPagination = connect(
    mapStateToProps,
    mapDispatchScroll
)(ScrollPaginationComponent);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Pagination);