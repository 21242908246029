import React from "react";
//import PropTypes from 'prop-types';

import ResourceIcon from "./ResourceIcon";

export default class SectionType extends React.PureComponent {
  render() {
    const { data } = this.props;
    let usedIn = data && data.used_in ? data.used_in.length : 0;
    let iconType = data.template
      ? "cms.sections.data"
      : "cms.sections." + data.type;
    return (
      <ResourceIcon
        {...this.props}
        resourceTypeKey={iconType}
        badgeValue={usedIn > 1 ? usedIn : null}
      />
    );
  }
}

SectionType.propTypes = {};
