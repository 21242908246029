import React from 'react';
import ResourcesNav from "containers/ResourcesNav";
import ResourcesNavComponent from "../ResourcesNav";
import AppsNavComponent from "./AppsNav";
import AppsNav from "containers/AppsNav";

import {ResourceList,Resource} from "list";
import WebsiteCard from 'components/list/items/WebsiteCard';
import classNames from 'classnames';

export default class AppMenu extends React.PureComponent {
    render() {
        const {
            resourceNamespace,
            requireProject,
            projectType,
            project,
            onOpenProject,
            view
        } = this.props;
        return (<div className="app-menu">
            <div className="apps-nav">
                <AppsNav render={({apps, onChange, selected})=>(
                    <AppsNavComponent
                        apps={apps}
                        selected={selected}
                        onChange={onChange}
                    />
                )}/>
            </div>
            <div className="apps-nav-layout">
            { requireProject ? <div className={classNames({
                'projects-nav': true,
                'projects-grid': !project
            })}>
                <ResourceList
                    resource={projectType}
                    listId={projectType+'.browse'}
                    filterId={projectType+'.browse'}
                    view={view}
                    fetchStrategy="once"
                    renderItem={props=><Resource
                        {...props}
                        onClick={onOpenProject}
                    />}
                />
            </div> : null }
            { !requireProject || project ? <div className="resources-nav">
               <ResourcesNav resourceNamespace={resourceNamespace} render={({resources})=>(
                    <ResourcesNavComponent resources={resources}/>
                )}/>
            </div> : null }
            </div>
        </div>);
    }

}