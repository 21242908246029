import React from 'react';

import ListItemField from 'components/list/ListItemField';
import Icon from '@material-ui/core/Icon';

import Text from './Text';
import Area from './Area';
import DomainCertificate from './DomainCertificate';
import Font from './Font';
import DateDiff from './DateDiff';

import _ from "lodash";

const fieldTypes ={
    area: Area,
    default: Text,
    'domain-cert': DomainCertificate,
    font: Font,
    'date-diff': DateDiff
};

export const selectValue = (data, keys, langFallback) => {
    if (!keys || !data) return null;
    if (!Array.isArray(keys)) keys = [keys];
    let value = null;
    let fb = data.fallback_translations;

    let fbLang = langFallback && fb && !Array.isArray(fb)
        ? Object.keys(fb)[0]
        : null;

    keys.forEach((key) => {
        let path = key.split('.');
        let dataValue = _.get(data, path);
        let isValidValue = dataValue; //dataValue && !Array.isArray(dataValue);
        if (!isValidValue && fbLang) {
            let fbValue = _.get(fb[fbLang], path);
            if (fbValue) dataValue = (<span className="lang-fallback">
                <Icon>contact_support</Icon>
                <span>{fbValue}</span>
            </span>);
        }
        if (isValidValue && !value) value = dataValue;
    });
    return value;
};

export const renderField = (key, format, value, data) => {
    let FieldClassName = fieldTypes[format] || fieldTypes.default;
    return React.createElement(FieldClassName, {
        key,
        value,
        data
    });
};

export const renderFields = (fields, data) => {
    return Object.keys(fields).map((fieldId) => {
        let format = fields[fieldId].format;
        let source = fields[fieldId].value;
        let value = selectValue(data, source);
        return (<ListItemField key={fieldId}>
            {renderField(fieldId, format, value, data)}
        </ListItemField>);
    });
};