import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import Chip from 'components/Chip';
import Label from 'components/Label';


const selectOptionStyle = {
    color: 'rgba(0,0,0,0.5)',
    padding: '0.2rem 1rem',
    fontSize: '14px'
};

function ChipSelectOption({onClick, label, dotColor}) {
    return (<div className="nav-pill" onClick={onClick}>
        <Label dotColor={dotColor} label={label} />
    </div>);
}

export default function ChipSelect(props) {
    const {
        onChange,
        onClearSelection,
        value,
        options,
        layout,
        multiple
    } = props;

    const isHorizontal = layout === 'horizontal';

    const classes = classNames({
        'chip-input': true,
        'chip-input--horizontal': isHorizontal,
        'chip-input--vertical': !isHorizontal
    });

    return <div className={classes}>{options.map(option=> {
        let label = option.title || option.name || option.id;
        let isSelected = multiple
            ? value && value.indexOf(option.id) > -1
            : option.id === value;
        let clickHandler = (evt) => {
            if (!isSelected) {
                onChange(option.id);
            } else {
                onClearSelection(evt);
            }
        }
        if (!isSelected && !isHorizontal) return (<ChipSelectOption
            key={option.id}
            label={label}
            dotColor={option.color}
            onClick={clickHandler}
        />);
        return <Chip
                key={option.id}
                label={label}
                variant={!isHorizontal ? "outlined" : "outlined"}
                color={isSelected ? "primary" : "default"}
                dotColor={option.color}
                onDelete={isSelected ? onClearSelection : null}
                onClick={clickHandler}
                fullWidth={layout === 'vertical'}
            />;
    })}</div>;
}

ChipSelect.propTypes = {
    layout: PropTypes.oneOf(['horizontal', 'vertical']),
    multiple: PropTypes.bool,
    options: PropTypes.array.isRequired
};