import React from 'react';
import {connect} from 'react-redux';
import {subscribe} from 'util/broadcasting';

import { showEventNotification } from 'state/actions/ui';

const mapStateToProps = (store, props) => {
    return {
        channel: 'user-' + store.context.user
    }
};

const mapDispatchToProps = {
    showEventNotification
};

class Notifications extends React.PureComponent {

    componentDidMount() {
        subscribe(
            this.props.channel,
            '.notification',
            this.props.showEventNotification
        );
    }

    render() {
        return this.props.children || null;
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Notifications);