import React from 'react';
import {connect} from 'react-redux';
import { query } from 'state/actions/route';
import { appMenuHide } from 'state/actions/ui';
import {MediaLibraryDialog} from "containers/dialogs/MediaLibrary";
import AppMenuDialog from 'components/layout/AppMenuDialog';
import UserSettingsDialog from 'containers/dialogs/UserSettings';
import FormDialog from 'containers/dialogs/FormDialog';
import ResourceEdit from 'v2/dialogs/ResourceEdit';
import FinderDialog from 'v2/dialogs/FinderDialog';
import SyncDialog from 'v2/dialogs/ResourceSync';

const mapStateToProps = (store, props) => {
    return {
        media: store.context.q === 'media',
        settings: store.context.q === 'settings',
        resource: !!store.context.r,
        appMenu: store.ui.showAppMenu,
        edit: store.context.edit,
        find: store.context.find,
        sync: store.context.q === 'sync'
    }
};

const mapDispatchToProps = {
    query,
    onCloseAppMenu: appMenuHide
};

class Dialogs extends React.Component {

    handleClose = () => {
        this.props.query({q: null, r: null, edit: null});
    };

    handleCloseAppMenu = () => {
        this.props.onCloseAppMenu();
    };

    render() {
        const { media, settings, resource, edit, find, sync, appMenu } = this.props;
        return <React.Fragment>
            <MediaLibraryDialog open={media} onClose={this.handleClose} />
            <UserSettingsDialog open={settings} onClose={this.handleClose} />
            { resource ? <FormDialog open={resource} onClose={this.handleClose} /> : null }
            { edit ? <ResourceEdit open={edit} onClose={this.handleClose} /> : null }
            { find ? <FinderDialog open={find} onClose={this.handleClose} /> : null}
            { sync ? <SyncDialog open={sync} onClose={this.handleClose} /> : null}
            <AppMenuDialog onClose={this.handleCloseAppMenu} open={appMenu} />
        </React.Fragment>;
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dialogs);