import React from 'react';
//import PropTypes from 'prop-types';

import TextField from 'components/fields/text/TextField';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save'

export default class CreateThemeFile extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            file: ''
        }
    }

    handleChange = (id, value) => {
        let update = {};
        update[id] = value;
        this.setState(update);
    };

    handleSubmit = () => {
        this.props.onSubmit(this.state.file);
        this.setState({file: ''});
        this.props.onClose();
    };

    render() {
        return (<div className="popover-form">
            <TextField
                id="file"
                label="Nazwa pliku"
                value={this.state.file}
                onChange={this.handleChange}
            />
            <div>
                <Button
                    variant="contained"
                    size="small"
                    fullWidth={true}
                    color="secondary"
                    onClick={this.handleSubmit}
                >
                    Zatwierdź
                    <SaveIcon style={{ marginLeft: '0.4rem' }}/>
                </Button>
            </div>
        </div>);
    }

}

CreateThemeFile.propTypes = {};