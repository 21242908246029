export default {
    /** context menu actions **/
    'action.edit': 'edit',
    'action.delete': 'delete',
    'action.createAfter': 'vertical_align_bottom',
    'action.ide': 'phonelink',
    'action.sections': 'arrow_forward_ios',
    'action.activate': 'check',
    'action.deactivate': 'clear',

    /** list actions **/
    'cms.pages.createPage': 'description',
    'cms.pages.createSection': 'note',
    'cms.pages.attach': 'insert_link',

    'ide.createPage': 'description',
    'ide.createSection': 'note',
    'ide.attach': 'insert_link',

    'action.vcs': 'fa-github',
    'action.cert-renew': 'enhanced_encryption',
    'action.config-reload': 'refresh',
    'action.cache-clear': 'refresh',
    'action.refresh': 'refresh',
    'action.snapshots': 'unarchive',
    'action.backup': 'archive',
    'action.destroy': 'delete_forever',
    'action.build': 'refresh',
    'action.detach': 'link_off',
    'action.copy': 'file_copy'
};