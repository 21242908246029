import React from 'react';
import PropTypes from 'prop-types';
import Dot from "components/Dot";

const outerStyle = {
    display: 'flex',
    alignItems: 'center'
};

const labelStyle = {
    marginLeft: '0.4rem'
};

export default function Label(props) {
    const { label, dotColor } = props;
    return dotColor
        ? <React.Fragment>
            <div style={outerStyle}>
                <Dot color={dotColor} />
                <div style={labelStyle}>{label}</div>
            </div>
        </React.Fragment>
        : label || null;
};

Label.propTypes = {
    dotColor: PropTypes.string
};