import {takeEvery, put, call} from 'redux-saga/effects';
import uuid from 'uuid/v4';
import {draftsChange, draftsCreate, draftsSubmit, listCreate} from "../actions";
import * as api from 'util/api/saga';

function* handleCreate(action) {
    try {
        const {
            thread,
            message,
            attachType,
            attachId,
            rootType,
            rootId
        } = action.payload;
        const context = action.context;

        if (!thread) {
            const newThread = {id: uuid()};
            yield put.resolve(draftsCreate('db.threads', newThread.id, newThread));
            message.parent = newThread.id;
            const update = {thread: newThread.id};
            yield put.resolve(draftsChange([attachType, attachId], update));
            yield call(api.put, newThread, 'db.threads', null, context);
            if (rootType) {
                yield put.resolve(draftsSubmit(rootType, rootId, false));
            } else {
                yield put.resolve(draftsSubmit(attachType, attachId, false));
            }
        } else {
            message.parent = thread;
        }
        const list = ['db.threads', message.parent, 'messages'].join('.');
        yield put.resolve(listCreate('db.messages', list, message));
        yield put.resolve(draftsSubmit('db.messages', message.id, false));
    } catch (e) {

    }
}

export default function*() {
    yield takeEvery('MESSAGES.CREATE', handleCreate);
}