import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import reorder from 'util/reorder';
import { draggable } from 'components/Draggable';
import { droppable } from 'containers/DropArea';

export const makeSortable = () => {
    return (Sortable) => {
        const SortableDecorator = class SortableDecorator extends React.PureComponent {
            constructor(props) {
                super(props);
                this.state = {data: props.data};
                this.debounceReorder = _.debounce(this.handleChangeOrder, 2);
            }
            componentDidMount() {
                this.setState({
                    data: this.props.data,
                    placeholder: null
                });
            }
            componentDidUpdate(prevProps) {
                if (prevProps.data !== this.props.data) {
                    this.setState({
                        data: this.props.data,
                        placeholder: null
                    });
                }
            }
            handleChangeOrder = (drag, hover) => {
                let data = this.state.data;
                let newData = data.slice();
                if (this.state.placeholder
                    && drag === this.state.placeholder.id
                    && this.state.placeholder.source !== this.props.listId
                ) drag = 'placeholder';
                newData = reorder(newData, drag, hover, true);
                let stateUpdate = {
                    data: newData
                };
                if (newData.join('/')===data.join('/')) return;
                this.setState(stateUpdate);
            };
            handleDrop = (id) => {
                if (!this.props.itemProps) return;
                const { onChangeOrder, onDrop } = this.props.itemProps;
                if(onChangeOrder) onChangeOrder(this.state.data);
                if(onDrop && this.state.newId) onDrop(this.state.newId, this.state.data);
                this.setState({newId: null});
            };
            handleHoverWith = (hover, item) => {
                console.log('hoverWithExternalItem');
                if (item.resource === 'cms.sections') {
                    this.props.itemProps.onHoverWith(hover, item);
                }
            };
            handleDragOutside = (item) => {
                if (window.__pendingDragEffect) return;
                this.reject({id: 'placeholder', resource: 'cms.sections'});
                if (this.contains(item) && !this.contains(item, this.props.data)) {
                    this.reject(item);
                }
            };
            reject = (item) => {
                this.setState({
                    data: this.state.data.filter(id=>id!==item.id),
                    placeholder: null
                });
            };
            accept = (item) => {
                let newData = this.state.data.slice();
                newData.push(item.id);
                this.setState({
                    data:newData,
                    placeholder: item.id === 'placeholder'
                        ? item.item
                        : null
                });
            };
            reset = () => {
                this.setState({
                    data: this.props.data,
                    placeholder: null
                });
            };
            contains = (item, state) => {
                const { resource } = this.props;
                let data = state ? state : this.state.data;
                return resource === item.resource
                    && data.indexOf(item.id) > -1;
            };
            handleDragOver = (item) => {
                let placeholder = {
                    id: 'placeholder',
                    resource: 'cms.sections',
                    item
                };
                if (this.props.onChangeOrder && !this.contains(placeholder)) {
                    this.accept(placeholder);
                }
            };
            handleDropInto = (item) => {
                if (!this.contains(item, this.props.data)) {
                    console.log('dropInto', item);
                } else {
                    console.log('dropAnItemWhichIsContained')
                }
                this.handleSubmitOrder();
            };
            handleSort = (drag, hover) => {
                if (this.props.onChangeOrder && this.contains(drag)) {
                    this.debounceReorder(drag.id, hover);
                }
            };
            handleSubmitOrder = () => {
                if (this.props.onChangeOrder) {
                    this.props.onChangeOrder(this.state.data);
                    return;
                }
                const { onChangeOrder } = this.props.itemProps;
                if (onChangeOrder) {
                    onChangeOrder(this.state.data);
                }
            };
            handleSortEnd = (drag, effect) => {
                if (!this.contains(drag, this.props.data)) {
                    //this.reset();
                    //return;
                }
                
                this.props.onChangeOrder(
                    this.state.data,
                    drag.id,
                    effect
                );

                /*if (this.contains(drag, this.props.data)) {
                    this.handleSubmitOrder();
                } else {
                    const { onDropInto } = this.props;
                    const { data } = this.state;
                    if (onDropInto) onDropInto(data, drag.id);
                    alert('should import');
                    this.handleSubmitOrder();
                }*/
            };
            getDragIndex = (drag, hover) => {
                const data = this.state.data;
                return data.indexOf(drag.id);
            };

            makeItemProps = (props) => {
                return {...props, renderDragging: this.props.data.indexOf(props.id) === -1};
            };

            queueDragEffect = (drag) => {
                if (this.props.onChangeOrder) {
                    window.__pendingDragEffect = (item, effect) => {
                        this.handleSortEnd(drag, effect);
                        delete window.__pendingDragEffect;
                    };
                }
                if (this.props.onDropInto && !this.contains(drag)) {
                    this.props.onDropInto(drag.id);
                }
            };

            applyDragEffect = (item, effect) => {
                if (window.__pendingDragEffect) {
                    window.__pendingDragEffect(item, effect);
                }
            };

            shouldRenderItem = (id) => {
                if (id !== 'placeholder') return true;
                if (!this.state.placeholder) return false;
                if (this.state.placeholder.source !== this.props.listId) return true;
                return false;
            };

            render() {
                return (<React.Fragment><Sortable
                    {...this.props}
                    data={this.state.data}
                    onDragOver={this.handleDragOver}
                    onDragOutside={this.handleDragOutside}
                    onDropInto={this.queueDragEffect}
                    makeItemProps={this.makeItemProps}
                    itemProps={{
                        ...this.props.itemProps,
                        //onChangeOrder: this.handleChangeOrder,
                        //onDrop: this.handleDrop,
                        //onHoverWith: this.handleHoverWith
                        onSort: this.handleSort,
                        //onSortEnd: ()=>console.log('onSortEnd'), //this.handleSortEnd,
                        //onDropItem: (item, effect)=>alert(effect),
                        onDropItem: this.applyDragEffect,
                        onChangeOrder: null,
                        onDrop: null,
                        onHoverWith: null,
                        draggable: true,
                        getDragIndex: this.getDragIndex,
                        shouldRender: this.shouldRenderItem,
                        listId: this.props.listId
                    }}
                />
                <div>{this.state.placeholder ? this.state.placeholder.source : null}</div>
                </React.Fragment>);
            }
        };
        SortableDecorator.propTypes = componentPropTypes;
        return SortableDecorator;
    }
};

export const DragPlaceholder = (props) => {
    const {
        connectDrag,
        isOver,
        overItem,
        getDragIndex
    } = props;

    let dragIndex = null;
    if (getDragIndex && overItem) dragIndex = getDragIndex(overItem, props.ctxId);

    let direction = isOver ? 'upwards' : null;
    if (isOver && (props.ctxId === overItem.id)) direction = "upwards";
    if (isOver && (props.index > dragIndex)) direction = 'downwards';
    direction = null;

    const placeholder = (<div className="section-placeholder">
        Wstaw tutaj
    </div>);

    return (<React.Fragment>
        { direction === 'upwards' ? placeholder : null }
        {connectDrag(React.Children.only(props.children))}
        { direction === 'downwards' ? placeholder : null }
    </React.Fragment>)
};

export const makeSortableList = (ListComponent) => {
    return makeSortable()(ListComponent);
};

export const connectSortable = (SortableComponent) => {
    return draggable()(SortableComponent)
};

export const connectDropArea = (DropArea) => {
    return droppable()(DropArea);
};

const componentPropTypes = {
    data: PropTypes.array.isRequired
};