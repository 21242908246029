import React from 'react';
import PropTypes from 'prop-types';

import MaterialPopover from '@material-ui/core/Popover';

export default class Popover extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: null
        }
    }
    handleOpen = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        this.setState({open: true, anchorEl: evt.currentTarget});
    };
    handleClose = () => {
        this.setState({open: false});
        if (this.props.onClose) this.props.onClose();
    };
    render() {
        const { trigger, content } = this.props;
        return (<React.Fragment>
            {trigger({onClick: this.handleOpen})}
            <MaterialPopover
                onClose={this.handleClose}
                open={this.state.open}
                anchorEl={this.state.anchorEl}
                anchorOrigin={this.props.anchorOrigin}
            >
                {content
                    ? content({onClose: this.handleClose})
                    : this.props.children}
            </MaterialPopover>
        </React.Fragment>);
    }

}

Popover.propTypes = {
    trigger: PropTypes.func.isRequired,
    content: PropTypes.func,
    onClose: PropTypes.func
};