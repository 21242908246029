import React from 'react';
import {connect} from 'react-redux';

import Dialog from 'components/Dialog';

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

class ResourceSync extends React.Component {

    render() {
        const { open, ...other} = this.props;
        return <Dialog
            open={open}
            className={'console-context'}
            onClose={this.props.onClose}
        >resourcesync</Dialog>;
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResourceSync);