import React, { Component } from "react";
import ContentEditable from "react-simple-contenteditable";

class ContentEditableWatch extends ContentEditable {
  shouldComponentUpdate(nextProps, nextState) {
    if (super.shouldComponentUpdate(nextProps, nextState)) return true;
    if (nextProps.className !== this.props.className) return true;
    if (nextProps.isFocused !== this.props.isFocused) return true;
    return false;
  }
}

export default class Editable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.placeholderText = "Wpisz tresc";
    this.state = {
      focus: false
    };
  }

  handleChange = (event, value) => {
    if (value !== this.props.placeholder) {
      this.props.onChange(value);
    }
  };

  handleFocus = () => {
    this.setState({ focus: true });
    if (this.props.onFocus) this.props.onFocus();
  };

  handleBlur = () => {
    this.setState({ focus: false });
    if (this.props.onBlur) this.props.onBlur();
  };

  handleClick = (evt) => {
    //evt.stopPropagation();
  };

  render() {
    const {
      value,
      onChange,
      className,
      tagName,
      style,
      isPreview,
      placeholder
    } = this.props;
    let passedStyle = style || {};
    let useStyle = { ...passedStyle };
    let visibleValue = this.state.focus ? value : value || placeholder;
    useStyle.pointerEvents = "all";
    if (!this.state.focus && (!value || value === placeholder)) {
      useStyle.opacity = "0.5";
    }
    if (this.state.focus && !value) {
      useStyle.minWidth = "4em";
    }
    if (this.state.focus) {
      useStyle.textTransform = 'none';
    }
    if (!onChange) {
      if (!value && !isPreview) return null;
      if (value) return <span className={className}>{value}</span>;
      return <span className={className}>{placeholder}</span>;
    }
    return (
      <ContentEditableWatch
        onKeyPress={()=>null}
        isfocused={this.state.focus ? "true" : "false"}
        style={useStyle}
        html={visibleValue || ""}
        className={className}
        tagName={tagName}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onClick={this.handleClick}
        contentEditable="plaintext-only"
      />
    );
  }
}
