import React from 'react';
import {connect} from 'react-redux';
import SelectField from 'v2/fields/Select';

const mapStateToProps = (store, props) => {
    return {
        value: props.value || {}
    }
};

const mapDispatchToProps = {};

class RefField extends React.Component {

    handleChange = (id, value) => {
        let prev = this.props.value;
        let next = {...prev};
        next[id] = value;
        this.props.onChange(this.props.id, next);
    };

    render() {
        return (<div>
            <SelectField
                label={'Resource type'}
                id={'resource_type_id'}
                source={'api:db.types'}
                value={this.props.value.resource_type_id}
                onChange={this.handleChange}
            />
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RefField);