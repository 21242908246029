import React from 'react';
//import PropTypes from 'prop-types';

export default class Colors extends React.PureComponent {

    handleChange = (value) => {
        this.props.onChange(value);
    };

    render() {
        const { options, value } = this.props;
        return (<div style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
            {options.map((opt) => {
                let className = value === opt ? 'se-color ' + opt + ' active' : 'se-color ' + opt;
                return (<div onClick={()=>this.handleChange(opt)} key={opt} className={className} />);
            })}
        </div>);
    }

}

Colors.propTypes = {};