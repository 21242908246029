import lists from 'config/lists';

import {selectResource} from "./resources";
import {selectValue} from 'components/list/fields';
import {getIn} from "lodash-redux-immutability";

const nullObj = {};

export const selectListViewConfig = (store, props) => {
    let id = props.view || props.resource;
    return selectListViewConfigById(store, id);
};

export const selectListViewConfigById = (store, id) => {
    return lists[id] || lists.default;
};

export const selectPrimaryText = (store, {resource, id}) => {
    return selectValue(
        selectResource(store, resource, id),
        selectListViewConfigById(store, resource).primaryText
    );
};

export const selectSecondaryText = (store, {resource, id}) => {
    return selectValue(
        selectResource(store, resource, id),
        selectListViewConfigById(store, resource).secondaryText
    );
};

export const selectFields = (store, {resource, id, view}) => {
    const config = selectListViewConfigById(store, {resource, view});
    let fields = Object.keys(config.fields || {});
    let data = selectResource(store, resource, id);
    return fields.map(k=>{
        return {...fields[k], value: selectValue(data, fields[k].value)}
    });
};

export const selectListMeta = (store, {list}) => {
    return getIn(store.listsMeta, [list]) || nullObj;
};