export const pending = (id, status) => {
    return {
        type: 'UI.PENDING',
        id,
        status
    }
};

export const feedback = (message, status) => {
    return {
        type: 'UI.FEEDBACK',
        message,
        status
    }
};

export function showEventNotification(event) {
    return {
        type: "UI.EVENT.SHOW_NOTIFICATION",
        payload: {
            ...event.context,
            message: event.message
        }
    }
}

export function hideEventNotification(id) {
    return {
        type: "UI.EVENT.HIDE_NOTIFICATION",
        payload: {id}
    }
}

export const setUiFlag = (id, value) => ({
    type: 'UI.SET_FLAG',
    id,
    value
});

export const listDisplay = (id, value) => ({ type: 'UI.LIST_DISPLAY', id, value});

export const confirmShow = (message) => ({ type: 'UI.CONFIRM.SHOW', message});
export const confirmHide = () => ({ type: 'UI.CONFIRM.HIDE' });
export const confirmYes = () => ({type: 'UI.CONFIRM.YES'});
export const confirmNo = () => ({type: 'UI.CONFIRM.NO'});

export const promptShow = (id) => ({ type: 'UI.PROMPT.SHOW', id});
export const promptHide = () => ({ type: 'UI.PROMPT.HIDE' });
export const promptConfirm = () => ({type: 'UI.PROMPT.CONFIRM'});
export const promptCancel = () => ({type: 'UI.PROMPT.CANCEL'});

export const appMenuToggle = () => ({type: 'UI.APP_MENU.TOGGLE'});
export const appMenuHide = () => ({type: 'UI.APP_MENU.HIDE'});
export const appMenuShow = () => ({type: 'UI.APP_MENU.SHOW'});

export const listActionsToggle = () => ({type: 'UI.LIST_ACTIONS.TOGGLE'});
export const listActionsHide = () => ({type: 'UI.LIST_ACTIONS.HIDE'});
export const listActionsShow = () => ({type: 'UI.LIST_ACTIONS.SHOW'});

export const feedbackHide = () => ({type: 'UI.FEEDBACK.HIDE'});

export const uiNotificationsToggle = () => ({type: 'UI.NOTIFICATIONS.TOGGLE'});
export const uiNotificationsHide = () => ({type: 'UI.NOTIFICATIONS.HIDE'});
export const uiNotificationsShow = () => ({type: 'UI.NOTIFICATIONS.SHOW'});

export const uiTreeExpand = (id, status) => ({type: 'UI.TREE.EXPAND', id, status});

export const uiSetView = (type, resource, id) => ({type: 'UI.VIEW.SET', viewType: type, resource, id});