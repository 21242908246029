import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import {connect} from 'react-redux';
import {changed} from 'util/changed';

//import Resource from 'containers/Resource';
import Resource from 'v2/Resource';
import Form from 'components/form/Form';
//import Form from 'components/form/FormLayout';

import {
    resourceFetch,
    draftsSubmit,
    draftsCancel
} from "state/actions";

import { query } from 'state/actions/route';

import {
    selectPath
} from 'state/selectors/resources';

export const subtypeSchema = (store, resource, id) => {

    if (!resource || !id) {
        throw new Error(`Need to provide resource,id ${resource},${id}`)
    }

    let item_type = selectPath(store, [resource, id, 'type']);
    let element_type = selectPath(store, [resource, id, 'element_type']);

    let path = resource.split('.');
    let schemaPath = [path[0], path[1]];
    let schema = _.get(store.schema, schemaPath);
    if (!schema) {
        //throw new Error(`Unable to resolve schema for ${schemaPath.join('/')}`);
    }
    if (schema && schema.types && Object.keys(schema.types).length) {
        let defaultType = path[2] || Object.keys(schema.types)[0];
        let type = element_type || item_type || defaultType;
        path = [path[0],path[1],'types',type];
    }
    return path.join('/');
};

const selectViews = (store, resource) => {
  let parts = resource.split('.');
  let path = [parts[0]];
  let types = _.get(store.schema, path);
  return Object.values(types).filter((type) => {
      return type.id && (type.id.indexOf(parts[1]) === 0)
  });
};

const mapStateToProps = (store, props) => {
    let schemaPath = subtypeSchema(store, props.type, props.id);
    let view = store.context.v
        ? [store.context.app,store.context.v]
        : null;
    if (view) schemaPath = view.join('/');
    return {
        view: store.context.v,
        views: selectViews(store, props.type),
        schemaPath,
        path: [props.type,props.id].join('/'),
        isPending: store.ui.pending[props.id]
    }
};

const mapDispatchToProps = {
    fetch: resourceFetch,
    submit: draftsSubmit,
    onCancel: draftsCancel,
    onChangeView: query
};

class ResourceForm extends React.PureComponent {

    componentDidMount() {
        this.handleFetch();
    }

    componentDidUpdate(prevProps) {
        if (changed(['type','id'],prevProps,this.props))
            this.handleFetch();
    }
    
    handleFetch = () => {
        if (this.props.fetchPolicy !== 'never') {
            this.props.fetch(this.props.type, this.props.id);
        }
    };

    handleSubmit = () => {
        const submit = this.props.onSubmit || this.props.submit;
        submit(this.props.type, this.props.id);
    };

    handleSubmitDismiss = () => {
        const submit = this.props.onSubmit || this.props.submit;
        submit(this.props.type, this.props.id, true);
    };

    handleCancel = () => {
        this.props.onCancel(this.props.type, this.props.id);
    };

    handleChangeView = (id) => {
        this.props.onChangeView({v:id});
    };

    renderForm = (props) => {
        return (<Form
            {...this.props}
            onChangeView={this.handleChangeView}
            onSubmit={this.handleSubmit}
            onSubmitDismiss={this.handleSubmitDismiss}
            onCancel={this.handleCancel}
        />);
    };

    render() {
        const {
            type,
            id
        } = this.props;

        return (<Resource
            resource={type}
            id={id}
            fetchPolicy={'never'}
        >{this.renderForm}</Resource>);
    }

}

ResourceForm.propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    schemaPath: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourceForm);