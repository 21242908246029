import React from 'react';
//import PropTypes from 'prop-types';

import MaterialCard from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

export default class Card extends React.PureComponent {

    render() {
        const {
            primaryText,
            secondaryText,
            onClick,
            footer
        } = this.props;

        return (<MaterialCard
            onClick={onClick}
            className="resource-card"
        >
            <CardContent>
                {primaryText
                    ? <Typography
                        variant="h6"
                        noWrap={true}
                        className="resource-card__title"
                    >{primaryText}</Typography>
                    : null}

                {secondaryText
                    ? <Typography color="textSecondary">{secondaryText}</Typography>
                    : null}

                {this.props.children}
            </CardContent>
            {footer ? footer : null}
        </MaterialCard>);
    }

}

Card.propTypes = {};