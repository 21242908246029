import React from 'react';
import {connect} from 'react-redux';
import {push,query} from 'state/actions/route';
import {selectResource} from 'state/selectors/resources';
import IconMenu from 'components/IconMenu';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

const userMenu = [
    {
        key: 'access',
        caption: 'Ustawienia',
        icon: 'settings',
        scope: 'master',
        strict: true,
        action: () => query({q: 'settings'})
    },
    {
        key: 'logout',
        caption: 'Wyloguj',
        action: () => push('/logout')
    }
];

const mapStateToProps = (store, props) => {
    return {
        data: selectResource(store, 'console.users', store.context.user)
    }
};

const mapDispatchToProps = {
    push,
    query
};

class User extends React.PureComponent {

    constructor(props) {
        super(props);
        this.userMenu = userMenu;
    }

    render() {
        const { data } = this.props;
        return (<div style={{
            display: 'flex',
            alignItems: 'center'
        }}>
            <Avatar
                src={data.profile.avatar}
                style={{ width: '32px', height: '32px' }}
            />
            <Typography
                color={"inherit"}
                style={{ marginLeft: '1rem', marginRight: '1rem' }}
            >
                {data.name}
            </Typography>
            <IconMenu items={this.userMenu} />
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(User);