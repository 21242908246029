import React from 'react';
import {connect} from 'react-redux';
import {withDialog} from 'components/Dialog';
import {draftsPost} from 'state/actions';
import FormState from 'containers/FormState';
import Form from 'components/form/Form';

const mapState = (store, props) => {
    return {}
};

const mapDispatch = {
    onSubmit: draftsPost
};

class CreateWebsite extends React.PureComponent {

    handleSubmit = () => {
        this.props.onSubmit(
            'forms',
            'createWebsite',
            'console.website.setup'
        );
    };

    render() {
        return (<Form
            onCancel={this.props.onClose}
            onSubmit={this.handleSubmit}
            dismissible={true}
            schemaPath={['forms', 'createWebsite'].join('/')}
            path={'forms/createWebsite'}
            id={'createWebsite'}
        />);
    }

}

const ConnectedCreateWebsite = connect(mapState,mapDispatch)(CreateWebsite);

export default ConnectedCreateWebsite;

export const CreateWebsiteDialog = withDialog(
    (store)=>store.context.q==='create-website'
)(ConnectedCreateWebsite);