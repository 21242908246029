import React from 'react';
import {connect} from 'react-redux';

const blacklist = [
    'options',
    'flags',
    'option_types',
    'display_map',
    'section'
];

const selectElements = (store) => {
    const styles = store.data['theme.styles'] || {};
    let elements = [];
    Object.keys(styles).forEach((e) => {
        if (e.indexOf('.') === -1 && blacklist.indexOf(e) === -1) {
            elements = elements.concat(Object.keys(styles[e]));
        }
    });
    return elements;
};

const mapStateToProps = (store, props) => {
    return {
        allElements: selectElements(store)
    }
};

const mapDispatchToProps = {};

class AddElement extends React.PureComponent {

    handleAdd = (e) => {
        this.props.onClose();
        this.props.onAdd(e);
    };

    render() {
        const { ownElements, allElements } = this.props;
        return (<div
            className="popover-content se-classes"
        >
            <div>
            {ownElements.map((e)=><div
                key={e}
                className="se-class"
                onClick={()=>this.handleAdd(e)}>
                    {e}
                </div>
            )}
            </div>
            <div>
            {allElements.map((e)=><div
                    key={e}
                    className="se-class"
                    onClick={()=>this.handleAdd(e)}>
                    {e}
                </div>
            )}
            </div>
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddElement);