import React from 'react';
import {Link} from "react-router-dom";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';

import {icon} from 'util/icon';

export default class AppsNav extends React.PureComponent {

    render() {
        const { apps, selected, onChange } = this.props;
        if (apps.length < 2) return null;
        return (<Paper className="apps-nav">
            <Tabs value={selected} onChange={onChange}>
            {apps.map((app) => {
                return (<Tab
                    value={app.id}
                    key={app.id}
                    label={app.name}
                    icon={icon('apps.'+app.name)}
                />);
            })}
            </Tabs>
        </Paper>);
    }

}