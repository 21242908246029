import React from 'react';
import {connect} from 'react-redux';
import {query} from 'state/actions/route';
import IconButton from 'components/TooltipIconButton';
import SelectMenu from 'components/fields/select/SelectMenu';
import withSelectOptions from 'components/fields/select/SelectOptions';

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {
    query
};

class SectionTemplateSelect extends React.PureComponent {
    render() {
        return <SelectMenu
            value={this.props.value}
            options={this.props.options}
            filters={this.props.filters}
            onSearch={this.props.onSearch}
            onSelect={(value)=>this.props.onChange(value)}
            trigger={(props)=><IconButton
                {...props}
                tooltip="Zmień szablon"
                icon="arrow_drop_down"
            />}
        />;
    }
}

const TemplateSelect = withSelectOptions()(SectionTemplateSelect);

class SectionInfo extends React.PureComponent {

    handleShowSection = () => {
        if (!this.props.data.id) return;

        this.props.query({
            cp: null,
            sc:this.props.data.id,
            scr: this.props.section
        });
    };

    handleChangeTemplate = (key, value) => {
        this.props.onChange('template', value);
    };

    handleClear = () => {
        this.props.onChange('template', null);
        this.props.query({cp:null,scr:null});
    };
    
    render() {
        const { active, template, value } = this.props;
        const { description, title } = this.props.data;
        const showTitle = description || title;
        let className = "section-info";
        if (template) className += ' section-info--template';
        if (template && !value) className += ' section-info--empty';
        if (active) className += ' active';
        return (<div className={className}>
            <div className="section-info__label" onClick={this.handleShowSection}>
                {template && !value
                    ? 'wybierz szablon'
                    : showTitle || 'bez nazwy'}
            </div>
            <div style={{ display: 'flex' }}>
                { template && value && this.props.onChange ? <IconButton
                    icon="clear"
                    onClick={this.handleClear}
                    tooltip="Bez szablonu"
                /> : null }
                { template && this.props.onChange ? <TemplateSelect
                    path="cms.sections.templates"
                    onChange={this.handleChangeTemplate}
                    value={value}
                    source="api:sections?type=view&render=true"
                /> : null }
            </div>
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SectionInfo);