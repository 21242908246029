import {invert} from 'lodash';

const aliases = {
    '@link-item': 'link_ref,link,link_label,link_action,icon,headline,tooltip_text,event_param',
    '@section-links': 'links:items',
    '@section-items': 'gallery:items',
    '@text': 'title,headline,text',
    '@query-list': 'query@items:items',
    '@query': 'query@items:items',
    '@children': 'items:components'
};

export const parseTemplateMapping = (mappingString, invertMapping) => {
    if (!mappingString) return {};
    let mapping = aliases[mappingString] || mappingString;
    let parts = mapping.split(",");
    let parsed = {};
    parts.forEach(part => {
        let fromTo = part.split(":");
        let from = null;
        let to = null;
        from = fromTo[0];
        if (fromTo.length > 1) {
            to = fromTo[1];
        } else {
            to = from;
        }
        to = to.replace('?', '');
        from = from.replace('?', '');
        from = from.replace('context.', 'context@');
        from = from.replace('query.', 'query@');
        parsed[to] = from;
    });
    if (invertMapping) return invert(parsed);
    return parsed;
};

export const serializeMapping = (mapping) => {
    let pairs = [];
    Object.keys(mapping).forEach((from) => {
        let map = [];
        if (mapping[from] && mapping[from] !== from) map.push(mapping[from]);
        map.push(from);
        pairs.push(map.join(':'));
    });
    return pairs.join(',');
};