window.__subscriptions = {};

export const subscribe = (channel, namespace, handler) => {
    if (window.__subscriptions[channel]) return;
    console.log('Subscribe to notification channel ' + channel);
    window.Echo.channel(channel)
        .listen(namespace, (e) => {
            handler(e)
        });
    window.__subscriptions[channel] = true;
};