import Layout from "./Layout";
import Text from "./Text";
import Button from "./Button";
import Nav from "ide/containers/Nav";
import Value from "./Value";
import Template from "./Template";
import Newsletter from "./Newsletter";
import Info from "./Info";
import List from "ide/containers/List";
import Default from "./Default";
import Card from "./Card";
import Media from "./MediaElement";
import Tags from "./Tags";
import Map from "./Map";
import Booking from "./Booking";
import ConnectTemplate from "./ConnectTemplate";
import FormInput from "./FormInput";
import Children from "./Children";

export default {
  layout: Layout,
  structured: Layout,
  text: Text,
  button: Button,
  nav: Nav,
  value: Value,
  template: Template,
  newsletter: Newsletter,
  info: Info,
  list: List,
  card: Card,
  default: Default,
  "media-element": Media,
  tags: Tags,
  geo: Map,
  map: Map,
  quickbook: Booking,
  "connect-template": ConnectTemplate,
  form: Layout,
  "form-input": FormInput,
  link: Children,
};
