import React from 'react';
import {connect} from 'react-redux';
import {selectResource} from 'state/selectors/resources';
import ItemActions from "./ItemActions";
import IconButton from 'components/TooltipIconButton';
import {GraphCanvasContext} from "v2/GraphCanvas";
import classNames from 'classnames';
import {webfont_icon as icon} from 'ide/components/icon';

import Card from '@material-ui/core/Card';

const mapStateToProps = (store, props) => {
    let current = store.context.id;
    let currentNode = selectResource(store, 'db.nodes', current);
    return {
        current,
        currentNode
    }
};

const mapDispatchToProps = {};

class GraphNode extends React.PureComponent {

    handleClick = (evt) => {
        if (evt) evt.stopPropagation();
        this.props.onClick(this.props.id);
    };

    handleActionRequest = (type, resource, id) => {
        if (type === 'delete' && this.props.onDelete)
            this.handleDelete();

        if (type === 'edit' && this.props.onClick)
            this.handleClick();

        if (this.props.onActionRequest)
            this.props.onActionRequest(type, resource, id);
    };

    render() {
        const {
            current,
            currentNode,
            childIds,
            resource,
            id,
            style,
            data,
            onClick,
            childNodes
        } = this.props;

        let isVisible = [data.id,data.parent].indexOf(current) > -1
            || data.id === currentNode.parent
            //|| data.parent === currentNode.parent
            || (!data.parent && !currentNode.id);

        let isSelected = [data.id].indexOf(current) > -1;
        let ownStyle = {
            minWidth: '140px',
            maxWidth: '180px',
            minHeight: '80px',
            padding: '0.6rem',
            fontSize: '12px',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: isVisible
                ? 'translateY(-50%) translateX(-50%) scale(1)'
                : 'translateY(-50%) translateX(-50%) scale(0)',
            transformOrigin: 'center center',
            pointerEvents: 'all',
            opacity: isVisible ? 1 : 0,
            transition: 'all .3s',
            display: 'flex'
        };
        let containerStyle = {
            width: '500px',
            height: '500px',
            position: 'absolute',
            pointerEvents: 'none',
            ...style,
            transform: 'translateX(-50%) translateY(-50%)'
        };

        let itemStyle = {...ownStyle};
        let isParent = data.id && (data.id === currentNode.parent);

        let labelStyle = {
            flex: 1,
            marginLeft: 0,
            marginRight: 0,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: isParent ? 'center' : 'flex-start',
            flexDirection: 'column',
        };

        let backIconStyle = {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            display: 'flex',
            alignItems: 'flex-end'
        };

        let iconClass = {
            'fh-2x': true
        };
        if (data.color) iconClass[data.color] = true;

        return (<React.Fragment>
            <GraphCanvasContext.Consumer>
                {registerRef => (
                    <div className="graph-node" style={containerStyle} ref={e=>registerRef(data.id, e)}>
                        <Card className={classNames({
                            'graph-item': true,
                            'is-selected': isSelected,
                            'is-parent': isParent
                        })} onClick={this.handleClick} style={itemStyle}>
                            <div style={{ display: 'flex', flex: 1 }}>
                                <div style={labelStyle}>
                                    { data.icon ? <div className={classNames(iconClass)}>
                                        {icon(data.icon)}
                                    </div> : null }
                                    {isParent
                                        ? <IconButton icon="keyboard_backspace" onClick={this.handleClick}/>
                                        : null
                                    }
                                    {data.name}
                                </div>
                                {!isParent ? <div style={{ position: 'absolute', top: 0, right: 0}}>
                                    <ItemActions
                                        onActionRequest={this.handleActionRequest}
                                        resource={resource}
                                        id={id}
                                        actions='default'
                                        isOpen={false}
                                    />
                                </div> : null }
                            </div>
                        </Card>
                    </div>
                )}
            </GraphCanvasContext.Consumer>
            {childNodes}
        </React.Fragment>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GraphNode);