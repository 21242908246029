import React from 'react';
import {connect} from 'react-redux';
import {query} from 'state/actions/route';
import {selectResource} from 'state/selectors/resources';
import Resource from 'v2/Resource';
import Field, {RenderField} from 'v2/Field';
import AuditSection from 'v2/fields/audit/Section';
import Dialog from 'components/Dialog';
import Button from 'components/Button';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from "@material-ui/core/DialogContent";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LinearProgress from '@material-ui/core/LinearProgress';
import Card from '@material-ui/core/Card';
import Icon from '@material-ui/core/Icon';
import Audit from 'v2/items/Audit';
import IconButton from 'components/TooltipIconButton';
import Preloader from 'components/Preloader';
import { StyledProgress } from "v2/items/Audit";

const mapStateToProps = (store, props) => {
    let sectionId = store.context.as;
    let section = selectResource(store, 'db.audit-sections', sectionId);
    let id = props.resourceId;
    return {
        itemResource: ['db', props.id].join('.'),
        sectionId: sectionId,
        section,
        progress: section.progress,
        sectionTitle: section.name,
        current: props.value ? props.value.indexOf(store.context.as) : null,
        isLast: props.value ? props.value.indexOf(store.context.as) === props.value.length-1 : false,
        data: selectResource(store, ['db',store.context.view].join('.'), id),
        pending: store.ui.pending[id]
    }
};

const mapDispatchToProps = {
    query
};

const dialogContentRef = React.createRef();

class AuditSurvey extends React.PureComponent {

    handleShowSection = (id) => {
        this.props.query({as: id});
    };

    handleClose = () => {
        this.props.query({as: null});
    };

    handlePrevSection = () => {
        if (!this.props.current) {
            this.handleClose();
            return;
        }
        this.handleShowSection(
            this.props.value[this.props.current-1]
        );
    };
    handleNextSection = () => {
        if (this.props.current+1 === this.props.value.length) {
            this.handleClose();
            return;
        }
        this.handleShowSection(
            this.props.value[this.props.current+1]
        );
    };

    componentDidUpdate(prevProps) {
        if (!dialogContentRef.current) return;
        if (prevProps.current !== this.props.current) {
            dialogContentRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    }

    render() {
        const { value, section, sectionId, data, pending, isLast } = this.props;
        const sectionProgress = this.props.progress;
        const progress = data.progress || {};
        const resource = this.props.itemResource;
        if (!value) return null;
        return (<React.Fragment>
            <Audit resource={this.props.resource} id={this.props.resourceId} />
            <Card><List className="survey">{value.map(id=> {
                return <Resource resource={resource} id={id} fetchPolicy={'never'}>
                    {(props)=> {
                        let progress = props.data.progress;
                        return <ListItem
                            button
                            className="survey-section"
                            onClick={()=>this.handleShowSection(props.data.id)}
                        >
                            { progress ? <ListItemIcon style={{ fontSize: '12px' }}>
                                { progress.progress < 100 ? <div style={{ width: '2em' }}>
                                    {progress.progress}%
                                </div> : <div
                                    style={{ width: '2em', alignItems: 'center', display: 'flex' }}
                                    className={classNames({
                                        'text-success': !progress.failed,
                                        'text-danger': progress.failed
                                    })}
                                >
                                    { progress.failed
                                        ? <Icon>clear</Icon>
                                        : <Icon>check</Icon> }
                                </div> }
                            </ListItemIcon> : null }
                        <ListItemText primary={props.data.name} />
                    </ListItem>}}
                </Resource>
            })}</List></Card>
            <Dialog
                hideCloseButton={true}
                open={!!sectionId}
                maxWidth="md"
                onClose={this.handleClose}
            >
                <DialogContent style={{ padding: 0 }}>
                    <div ref={dialogContentRef}>
                        <DialogContent style={{ padding: 0 }}>
                            <div className="audit-survey-dialog console-context">
                                <div className={'form__header form__header--desktop'}>
                                    <IconButton icon={'clear'} onClick={this.handleClose} />
                                </div>
                                <div className={'form__header form__header--mobile'}>
                                    <IconButton icon={'keyboard_arrow_left'} onClick={this.handleClose} />
                                    <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                                        {this.props.current+1}/{this.props.value.length} {this.props.sectionTitle}
                                    </div>
                                </div>
                                <div className="form__body" style={{ paddingTop: '4rem' }}>
                                    { sectionId ? <AuditSection
                                        resource={'db.audit-sections'}
                                        id={sectionId}
                                        rootResource={this.props.resource}
                                        rootId={this.props.resourceId}
                                    /> : null }
                                </div>
                                <div className="form__footer form__footer--dialog" style={{ padding: 0 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                        <div style={{ display: 'flex', flex: 1, padding: '1rem' }}>
                                            <Button color="default" onClick={this.handlePrevSection}>prev</Button>
                                            <div style={{ width: '100%' }}>

                                            </div>
                                            <Button
                                                onClick={this.handleNextSection}
                                                disabled={!(sectionProgress && sectionProgress.is_complete)}
                                            >
                                                {isLast ? 'close' : 'next'}
                                            </Button>
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            { sectionProgress ? <StyledProgress
                                                color={'primary'}
                                                variant={pending ? 'indeterminate' : 'determinate'}
                                                value={sectionProgress.progress}
                                            /> : null }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DialogContent>
                    </div>
                </DialogContent>
            </Dialog>
        </React.Fragment>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuditSurvey);