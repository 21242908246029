import { index } from 'state/selectors/resources';

export default function(data) {
    let items = data.data.items;
    let source = data.data.source;
    items = items.map(item=>({...item, source}));
    let indexed = index(items, 'family');
    const result = {
        data: {
            data: indexed
        }
    };

    console.log(result);
    return result;
}