import React from 'react';
import {connect} from 'react-redux';
import {query} from 'state/actions/route';
import PropTypes from 'prop-types';

import List from 'v2/List';
import RenderList from 'list/List';
import {RenderResourceListItem} from 'components/list/ResourceListItem';

import Subfields from './Subfields';
import Dialog from 'components/Dialog';
import Button from 'components/Button';

import Card from '@material-ui/core/Card';

import {draftsPathCreate, draftsPathDetach, draftsCancel} from "state/actions";
import {handleUploadDirect} from "state/actions/files";
import {itemTypes} from 'v2/list-views';

import IconButton from "components/TooltipIconButton";

import listViewsConfig from 'v2/list-views';

const outerStyle = {
    display: 'flex',
    flexDirection: 'row',
    flex: 1
};

const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.6rem'
};

const labelStyle = {
    fontWeight: '600',
    fontSize: '16px'
};

const mapState = (store,props) => ({
    itemResource: ['db', props.id].join('.'),
    primaryTextField: props.schema.fields ? props.schema.fields.filter(f=>{
        return store.data['db.fields'][f] && store.data['db.fields'][f].properties && store.data['db.fields'][f].properties.primaryText;
    })[0] : {},
    secondaryTextField: props.schema.fields ? props.schema.fields.filter(f=>{
        return store.data['db.fields'][f] && store.data['db.fields'][f].properties && store.data['db.fields'][f].properties.secondaryText;
    })[0] : {},
    path: [props.resource,props.resourceId,props.id].join('/'),
    viewType: props.viewType || props.schema.properties.view_type || 'table'
});
const mapDispatch = {
    query,
    onCreate: draftsPathCreate,
    onUpload: handleUploadDirect,
    onDelete: draftsPathDetach,
    onCancel: draftsCancel
};

class Collection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: null
        };
    }

    handleEdit = (id) => {
        if (this.props.onClick) {
            this.props.onClick(id);
            return;
        }
        this.setState({open: id});
    };

    handleCreate = (evt, itemData) => {
        let data = {};
        if (itemData) data = {...itemData};
        this.props.onCreate(
            this.props.itemResource,
            this.props.path.split('/'),
            data,
            null,
            false
        );
        if (this.props.autoSave) this.props.onSubmit();
    };

    handleChangeOrder = (data) => {
        this.props.onChange(
            this.props.id,
            data.data
        );
    };

    handleDelete = (id) => {
        this.props.onDelete(
            this.props.path.split('/'),
            id
        );
        if (this.props.autoSave) this.handleSubmitEntity();
    };

    handleAction = (name, resource, id) => {
        if (name === 'detach') this.handleDelete(id);
        if (name === 'delete') this.handleDelete(id);
    };

    handleSubmitEntity = (resource, id) => {
        this.props.onSubmit(
            this.props.resource,
            this.props.resourceId
        );
    };

    handleSubmit = () => {
        this.setState({open: null});
        if (this.props.autoSave) this.handleSubmitEntity();
    };

    handleClose = () => {
        let resource = ['db', this.props.id].join('.');
        this.props.onCancel(resource, this.state.open);
        this.setState({open: null});
    };

    render() {
        const { readOnly } = this.props;
        let viewType = this.props.viewType;
        let config = listViewsConfig[viewType];
        let resource = ['db', this.props.id].join('.');
        let ListRenderer = config.listType;
        let ItemRenderer = config.itemType === 'RenderResourceListItem'
            ? RenderResourceListItem
            : config.itemType;

        if (this.props.list_item_type) ItemRenderer = itemTypes[this.props.list_item_type];

        return <React.Fragment>
            <CollectionLayout
                card={typeof this.props.card ==='undefined' ? true : this.props.card}
                label={this.props.label}
                actions={!readOnly ? <div style={{ flex: 1 }}>{this.props.actions
                    ? this.props.actions
                    : <IconButton
                        icon="add"
                        size="small"
                        onClick={this.handleCreate} />}
                </div> : null}
            >
                <List
                    resource={resource}
                    fetchStrategy="never"
                >
                    {({data, resource})=><RenderList
                        variant={readOnly ? "default" : "sortable"}
                        resource={resource}
                        data={this.props.value}
                        readOnly={readOnly}
                        renderList={(props)=><ListRenderer
                            {...props}
                            viewConfig={this.props.schema}
                            filterLayout="none"
                            listClassName={config.className}
                        />}
                        renderItem={(props)=><ItemRenderer
                            {...props}
                            readOnly={readOnly}
                            draggable={!readOnly}
                            fields={this.props.schema.view_fields}
                            onClick={this.handleEdit}
                            onDelete={this.handleDelete}
                            onSubmit={this.handleSubmitEntity}
                            onRequestAction={this.handleAction}
                        />}
                        config={config}
                        onChangeOrder={this.handleChangeOrder}
                    />}
                </List>
            </CollectionLayout>
            <Dialog
                open={!!this.state.open} onClose={this.handleClose}
                fullWidth={true}
                maxWidth="sm"
                title={this.props.label}
            >
                <div className="console-context" style={{padding: '2rem'}}>
                    <Subfields
                        resource={resource}
                        resourceId={this.state.open}
                        schema={this.props.schema}
                    />
                    <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end'}}>
                        <Button onClick={this.handleSubmit}>OK</Button>
                    </div>
                </div>
            </Dialog>
        </React.Fragment>;
    }

}

Collection.propTypes = {};

export class CollectionLayout extends React.PureComponent {

    render() {
        return <div style={outerStyle} className="collection">
            <div style={{ flex: 1 }}>
                <div className="field__label" style={headerStyle}>
                    <div style={labelStyle}>{this.props.label}</div>
                </div>
                <div style={{ display: 'flex' }}>
                    { this.props.card ? <Card onClick={this.props.onClick} style={{ flex: 1 }}>
                        <div>
                            {this.props.children}
                        </div>
                        <div className="form-collection__actions">
                            {this.props.actions}
                        </div>
                    </Card> : <div>
                        <div onClick={this.props.onClick} style={{ flex: 1 }}>
                            {this.props.children}
                        </div>
                        <div className="form-collection__actions">
                            {this.props.actions}
                        </div>
                    </div> }
                </div>
            </div>
        </div>;
    }

}

export default connect(mapState, mapDispatch)(Collection);