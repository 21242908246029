export const normalize = (tree, parentId = null, flatList = {}) => {
  tree.forEach(item => {
    let newItem = item; //{...item};
    //delete newItem.components;
    flatList[item.id] = {
      id: item.id,
      parentId: parentId,
      data: newItem
    };
    if (item.components && item.components.length) {
      flatList = normalize(item.components, item.id, flatList);
    }
  });
  return flatList;
};

export const denormalize = list => {
  let map = {},
    node,
    roots = [],
    i;
  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i].data.components = []; // initialize the children
  }
  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parentId) {
      // if you have dangling branches check that map[node.parentId] exists
      if (Object.keys(map).indexOf(node.parentId) > -1) {
        list[map[node.parentId]].data.components.push(node.data);
      }
    } else {
      roots.push(node.data);
    }
  }
  return roots;
};
