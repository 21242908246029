import React from 'react';
import {connect} from 'react-redux';
import Icon from '@material-ui/core/Icon';
import ListActions from "containers/dialogs/ListActions";
import Fab from '@material-ui/core/Fab';
import Zoom from '@material-ui/core/Zoom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Badge from '@material-ui/core/Badge';

import { ideSaveAll } from "state/actions/ide";
import { selectPath, selectDrafts } from 'state/selectors/resources';

import {
    query
} from 'state/actions/route';

const mapStateToProps = (store, props) => {
    return {
        id: store.context.id,
        list: 'ide',
        drafts: selectDrafts(store).length,
        isPending: store.ui.pending['ide.save'],
        canAdd: store.context.q !== 'attach-section'
    }
};

const mapDispatchToProps = {
    query,
    onSave: ideSaveAll
};

class FabContainer extends React.PureComponent {

    handleShowListActions = () => {
        this.props.query({q: 'list-actions'});
    };

    handleClickSave = (evt) => {
        if (evt.altKey) {
            this.props.query({dev: 'history'});
            return;
        }
        this.props.onSave();
    };

    render() {
        const { id, list, drafts, canAdd, isPending } = this.props;

        const saveIcon = isPending
            ? <CircularProgress size={20} color="inherit" style={{marginRight: '0.4rem'}}/>
            : <Icon style={{marginRight: '0.4rem'}}>check</Icon>;

        return (<div className="resource-list-fab fixed">
            <Zoom
                in={drafts > 0}
                unmountOnExit={true}
            >
                <Badge
                    color="primary"
                    badgeContent={drafts}
                    style={{ marginRight: '1rem' }}
                >
                    <Fab
                        variant="extended"
                        color="secondary"
                        onClick={this.handleClickSave}
                        disabled={isPending}
                    >
                        {saveIcon}
                        Zapisz zmiany
                    </Fab>
                </Badge>
            </Zoom>
            <Zoom in={canAdd} unmountOnExit={true}>
                <Fab
                    color={drafts ? "primary" : "secondary"}
                    aria-label="Add"
                    onClick={this.handleShowListActions}
                    disabled={isPending}
                >
                    <Icon>add</Icon>
                </Fab>
            </Zoom>
            <ListActions
                id={id}
                resource={list}
            />
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FabContainer);