import React from 'react';
//import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { resourceRoute, query } from 'state/actions/route';
import { resourceAction } from 'state/actions';

import Toolbar from 'ide/ui/Toolbar';
import ImagePicker from 'ide/ui/ImagePicker';
import IconButton from 'components/IconButton';

import {hasBackground} from 'ide/containers/Section';

const mapState = (store, props) => {
    return {
    }
};

const mapDispatch = {
    query,
    route: resourceRoute,
    action: resourceAction
};

class Section extends React.PureComponent {

    render() {
        const { open, layout, hasBackground } = this.props;
        return (
            <Toolbar
                id={this.props.id}
                open={open}
                anchorEl={this.props.anchorEl}
                placement="bottom-start"
                type={layout ? "layout-section" : "section"}
                onMouseEnter={this.props.onMouseEnter}
            >
                <IconButton
                    icon="create"
                    onClick={()=>this.props.route(
                        'cms.pages/section',
                        { relatedId: this.props.id },
                        { dev: null}
                    )} />
                <IconButton
                    icon="format_shapes"
                    onClick={()=>this.props.query(
                        { sc: this.props.id, dev: 'styles' }
                    )} />
                { hasBackground ? (<ImagePicker
                    onChange={this.props.onChange}
                />) : null }
                {!layout ? (<IconButton
                    icon="delete"
                    onClick={()=>this.props.action(
                        'detach',
                        'cms.sections',
                        this.props.id
                    )}
                />) : null }
                {!layout ? (<IconButton
                    icon="add"
                    onClick={()=>this.props.action(
                        'createAfter',
                        'cms.sections',
                        this.props.id
                    )}
                />) : null }
            </Toolbar>
        );
    }

}

export default connect(mapState, mapDispatch)(Section);

Section.propTypes = {};