import React from 'react';
import PropTypes from 'prop-types';
import FormField from 'containers/FormField';

export default class SchemaRef extends React.Component {

    render() {
        let path = this.props.schema.path;
        let newPath = ['db','apartments','fields'].concat(path.split('.')).join('/');
        return (<FormField {...this.props} schemaPath={newPath} />);
    }

}

SchemaRef.propTypes = {};