import React from 'react';
//import PropTypes from 'prop-types';
import _ from 'lodash';

const inputStyle = {
    border: 'none',
    fontFamily: 'inherit'
};

export default class Default extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            value: ''
        }
    }

    handleType = (evt) => {
        let value = evt.target.value;
        if (value.indexOf(' ') > -1) {
            console.log('add');
            let next = this.props.value.slice();
            next.push(value.replace(' ', ''));
            this.props.onChange(next);
            this.setState({value: ''});
        } else {
            this.setState({value: value});
        }
    };

    handleClear = (c) => {
        let next = this.props.value.slice();
        next.splice(next.indexOf(c), 1);
        this.props.onChange(next);
    };

    render() {
        const { value } = this.props;
        return (<div>
            {value.length ? <div className="se-classes">{value.map((c)=>{
                return <span
                    key={c}
                    className="se-class"
                    onClick={()=>this.handleClear(c)}
                >
                    {_.isObject(c) ? '[object]' : c}
                </span>;
            })}</div> : null }
            <input
                style={inputStyle}
                type="text"
                value={this.state.value}
                placeholder="Dodaj klasę..."
                onChange={this.handleType}
            />
        </div>);
    }

}

Default.propTypes = {};