import React from 'react';
import {connect} from 'react-redux';

import fieldTypes from './fields';
//import Icon from '@material-ui/core/Icon';
import IconButton from 'components/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const nullObject = {};

const propertiesSchema = {
    layout: {
        label: "Układ",
        type: 'radio',
        prefix: 'l-'
    },
    'text-align': {
        label: 'Wyrównanie',
        type: 'radio'
    },
    'cols-gap': {
        label: "Odstępy",
        type: 'select',
        prefix: 'l-cols--gap-'
    },
    'rows-gap': {
        label: "Odstępy",
        type: 'select',
        prefix: 'l-rows--gap-'
    },
    'grid-gap': {
        label: "Odstępy",
        type: 'select',
        prefix: 'l-grid--gap-'
    },
    'grid-row-height': {
        label: "Wysokość",
        type: 'select',
        prefix: 'l-grid--'
    },
    'area-padding': {
        label: "Padding",
        type: 'select',
        prefix: 'l-area--padding'
    },
    theme: {
        label: "Kolor tła",
        type: 'colors',
        prefix: 't-'
    },
    props: {
        label: "Klasy css",
        type: 'default'
    },
    inherit: {
        type: 'hidden'
    }
};

const mapStateToProps = (store, props) => {
    const id = props.id;
    const options = store.data['theme.styles'] && store.data['theme.styles'].options
        ? store.data['theme.styles'].options[id]
        : [];
    return {
        options,
        schema: propertiesSchema[id] || nullObject
    }
};

const mapDispatchToProps = {};

class PropertyGroup extends React.PureComponent {

    handleChange = (value) => {
        this.props.onChange(this.props.id, value);
    };

    handleClear = () => {
        this.props.onChange(this.props.id, null);
    };

    render() {
        const { id, value, schema, options, hideLabel } = this.props;
        if (schema.type === 'hidden') return null;
        const fieldType = schema.type || 'select';
        const FieldClass = fieldTypes[fieldType];
        const empty = !value;
        return (<div className={ empty ? 'se-prop se-prop--empty' : 'se-prop'}>
            {!hideLabel ? <div className="se-prop__label">{schema.label || id}</div> : null}
            <div className="se-prop__value">
                <FieldClass
                    onChange={this.handleChange}
                    value={value}
                    options={options}
                    schema={schema}
                />
                <Tooltip title="Unset property">
                    <IconButton
                        onClick={this.handleClear}
                        size="small"
                        icon="clear"
                        className="se-prop-reset"
                    />
                </Tooltip>
            </div>
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PropertyGroup);