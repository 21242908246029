import {call, takeEvery, fork, put, select} from "redux-saga/effects";
import {uploadProgressWatcher} from "util/saga-upload";
import * as actions from "state/actions/files";
import {upload} from 'util/api/saga';
import * as ui from 'util/sagas/feedback';
import {resourceFetchSuccess,listFetchSuccess,draftsPathAppend,draftsPathAttach,listAppend} from 'state/actions';
import {selectCurrentCms} from "state/selectors/app";

function* handleUpload(action) {
    const { id, file } = action.payload;
    try {
        const { context } = action;
        const data = new FormData();
        const dir = yield select((store)=>store.context.dir);
        const cms = yield select(selectCurrentCms);
        const storageId = cms.storage_id || cms.service_id;
        const fullPath = [storageId,'user',dir,'file.jpg'].join('/');
        data.append("id", id);
        if (dir) data.append("current", dir);
        data.append("file", file);
        const fileData = {id, file: fullPath, type: 'image'};
        yield ui.pending(id, true);
        yield put(resourceFetchSuccess('cms.media', id, fileData));
        yield put(listAppend('cms.media', fileData));
        const [uploadPromise, chan] = yield call(upload, 'cms.browser', data, context); //yield call(upload, 'browser', data, context);
        yield fork(uploadProgressWatcher, chan, id, actions.uploadProgress);
        const res = yield call(() => uploadPromise);
        yield put(actions.uploadSuccess(id, res.data.data));
        yield ui.success();
    } catch (e) {
        yield ui.pending(id, false);
        yield ui.error(e);
    }
}

function* handleDirectUpload(action) {
    const { id, file } = action.payload;
    try {
        const { resource, path, context } = action;
        const data = new FormData();
        data.append("id", id);
        data.append("file", file);
        yield ui.pending(id, true);
        const [uploadPromise, chan] = yield call(upload, resource, data, context); //yield call(upload, 'browser', data, context);
        yield fork(uploadProgressWatcher, chan, id, actions.uploadProgress);
        yield put(draftsPathAttach(path, id));
        const res = yield call(() => uploadPromise);
        yield put(actions.uploadSuccess(id, res.data.data, resource));
    } catch (e) {
        yield ui.pending(id, false);
        yield ui.error(e);
    }
}

function* handleUploadSuccess(action) {
    const { id, data } = action.payload;
    let resource = action.resource;
    try {
        yield put(resourceFetchSuccess(resource, id, data));
        yield ui.pending(id, false);
    } catch (e) {
        yield ui.pending(id, false);
        yield ui.error(e);
    }
}

export default function*() {
    yield takeEvery("FILES.UPLOAD", handleUpload);
    yield takeEvery("FILES.UPLOAD_DIRECT", handleDirectUpload);
    yield takeEvery("FILES.UPLOAD.SUCCESS", handleUploadSuccess);
}