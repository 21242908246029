import PlaceholderSection from 'components/list/items/PlaceholderSection';
import Media from 'components/list/items/Media';
import File from 'components/list/items/File';
import Note from 'components/list/items/Note';
import Task from 'v2/items/Task';
import Contact from 'v2/items/Contact';
import Post from 'v2/items/Post';
import Audit from 'v2/items/Audit';
import GalleryCard from 'v2/items/GalleryCard';
import Message from 'v2/items/Message';
import { ThemePresetCardColors, ThemePresetCardTypography} from 'components/list/items/ThemePresetCard';
import TableRow from 'components/list/items/TableRow';
import WebsiteCard from 'components/list/items/WebsiteCard';
import Credential from "v2/evernym/Credential";

export default {
    'cms.sections.placeholder': PlaceholderSection,
    'cms.media': Media,
    'cms.file': File,
    'console.presets.card.colors': ThemePresetCardColors,
    'console.presets.card.color_scheme': ThemePresetCardColors,
    'console.presets.card.typography': ThemePresetCardTypography,
    'console.services.website.card': WebsiteCard,
    'db.notes': Note,
    'db.fields': TableRow,
    'db.filters_config': TableRow,
    'db.media': Media,
    'db.file': File,
    'db.tasks': Task,
    'db.contacts': Contact,
    'db.posts': Post,
    'db.audits': Audit,
    'db.messages': Message,
    'db.credentials': Credential,
    'items.table-row': TableRow,
    'cms.gallery.card': GalleryCard
}