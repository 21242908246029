import React, { Component } from "react";
import SmartBreakQuill from "components/quill/SmartBreakQuill";

import { quillToolbar } from "config/quill";

export default class QuillEditor extends React.PureComponent {
  handleChange = (content) => {
    this.props.onChange(content);
  };
  render() {
    return (
      <SmartBreakQuill
        placeholder={this.props.placeholder}
        style={this.props.style}
        theme={"bubble"}
        modules={{
          toolbar: quillToolbar
        }}
        className={this.props.className}
        value={this.props.value || ''}
        onChange={this.handleChange}
      />
    );
  }
}
