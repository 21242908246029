import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {selectFilteredResources} from "state/selectors/resources";

function selectGraphNodes(store, parent) {
    let foreignNode = store.context.node;
    let listId = foreignNode
        ? 'db.nodes.' + foreignNode
        : 'db.nodes';
    let nodes = selectFilteredResources(store, 'db.nodes', null, listId);
    if (!nodes) return [];
    let roots = [];
    //roots.push(parent);
    Object.values(nodes).forEach(node=>{
        let nodeParent = node.parent || null;
        if (nodeParent === parent) roots.push(node.id);
    });
    return roots;
}

const mapState = (store, props) => {
    let graphRoots = selectGraphNodes(store, null);
    return {
        data: selectGraphNodes(store, props.parent || null),
        current: store.context.id,
        isRoot: graphRoots.indexOf(store.context.id) > -1
    };
};

const mapDispatch = {};

class GraphWithFetch extends React.PureComponent {
    render() {
        return <Graph {...this.props} />;
    }
}

class Graph extends React.PureComponent {
    render() {
        const {data, isRoot, current, Item, ...other} = this.props;
        const parent = this.props.parent;
        const rootPos = this.props.rootPosition;
        let itemIndex = 99;
        if (!isRoot && rootPos === 0) itemIndex = 3;
        if (rootPos === 1) itemIndex = 4;
        if (rootPos === 2) itemIndex = 5;
        if (rootPos === 3) itemIndex = 0;
        if (rootPos === 4) itemIndex = 1;
        if (rootPos === 5) itemIndex = 2;
        if (rootPos === 6) itemIndex = 3;
        let items = 6; //data.length;
        let r = 200;
        //let centerX = 250;
        //let centerY = 250;
        return data.map((id, index)=>{
                let x = 250, y = 250;

                if (this.props.parent) {
                    let centerX = this.props.parentPosition.x;
                    let centerY = this.props.parentPosition.y;
                    if (index >= itemIndex) index += 1;
                    x = (centerX + r * Math.cos((2 * Math.PI) * index / items));
                    y = (centerY + r / 1.2 * Math.sin((2 * Math.PI) * index / items));
                }

                let position = {
                    left: x + 'px',
                    top: y + 'px'
                };

                return <Item
                    key={id}
                    id={id}
                    index={index}
                    position={index}
                    rootPos={rootPos}
                    {...other}
                    childNodes={id !== parent ? <ConnectedGraph
                        Item={Item}
                        data={data}
                        {...other}
                        parent={id}
                        rootPosition={index}
                        parentPosition={{
                            x,
                            y
                        }}
                    /> : null}
                    style={position}
                />
            })
    }
}

const ConnectedGraph = connect(mapState, mapDispatch)(GraphWithFetch);

export const GraphIterator = (props, Item) => {
    const { data, ...other } = props;
    if (!Array.isArray(data)) return null;
    return <ConnectedGraph Item={Item} data={data} {...other} />;
};