import React  from "react";
import CircularProgress from '@material-ui/core/CircularProgress';

export default class Preloader extends React.PureComponent {

  render() {
    let className = "ui-preloader " + this.props.className;
    if (this.props.visible) className += ' is-visible';
    let { progress } = this.props;
    let mode = progress > 1 && progress < 100
        ? 'static'
        : 'indeterminate';
    return (
      <div className={className}>
        <div>
          <div style={{ marginBottom: "20px" }}>{this.props.children}</div>
          <div>
            <CircularProgress
                variant={mode}
                value={progress}
                thickness={3}
            />
          </div>
        </div>
      </div>
    );
  }
}
