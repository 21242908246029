import React from 'react';
//import PropTypes from 'prop-types';

import PropertyGroup from './PropertyGroup';

import Checkbox from 'components/fields/checkbox/CheckboxField';
import InputBase from '@material-ui/core/InputBase';

export default class ElementForm extends React.PureComponent {

    handleChange = (value) => {
        this.props.onChange(this.props.id, value);
    };

    render() {
        const { id, value, type } = this.props;
        return (<React.Fragment>
            {type === 'select' ? (<PropertyGroup
                hideLabel={true}
                id={id}
                value={value}
                onChange={(id,v)=>this.handleChange(v)}
            />) : null }
            {type === 'boolean' ? (
                <Checkbox value={value && value[0]==='true'} onChange={(id, value)=>{
                    if (value) this.handleChange(["true"]);
                    if (!value) this.handleChange([]);
                }} />
            ) : null }
            {type === 'value' ? (
                <InputBase
                    name={id}
                    onChange={(evt)=>this.handleChange([evt.target.value])}
                    value={value}
                    placeholder={id}
                    classes={{root: 'se-inline-input'}}
                />
            ) : null}
            { type === 'style-ref' ? (
                <div>{value[0]}</div>
            ) : null }
        </React.Fragment>);
    }

}

ElementForm.propTypes = {};