import React from "react";
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import MuiMenu from "@material-ui/core/Menu";
import MuiMenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';
import SubMenu from "./SubMenu";
import UploadButton from 'components/UploadButton';
import Access from 'components/Access';
import {icon} from "util/icon";

export const bindMenuActions = (items, actions, visibility) => {
    let withActions = items.map(item=>({...item, onClick: actions[item.key]}));
    if (!visibility) return withActions;
    return withActions.filter(visibility);
};

const mapState = () => ({});

const MenuItem = (props) => {
    const menuItem = props.item;
    const isUpload = menuItem.key === 'upload';
    const onClick = !isUpload ? (e)=>props.clickHandler(e,menuItem) : null;
    const item = <MuiMenuItem
        onClick={onClick}
    >
        {menuItem.active ? <ListItemIcon>
            <Icon>check</Icon>
        </ListItemIcon> : null}
        {menuItem.icon ? <ListItemIcon>
            {icon(menuItem.icon) || <Icon>{menuItem.icon}</Icon>}
        </ListItemIcon> : null}
        <Typography variant="inherit" noWrap>{menuItem.caption}</Typography>
    </MuiMenuItem>;

    if (isUpload) {
        return <UploadButton
            onUpload={menuItem.onUpload}
            trigger={item}
        />
    }

    return <Access
        scopes={menuItem.scope}
        strict={menuItem.strict}
    >
        {item}
    </Access>;
};

class EnhancedMenu extends React.PureComponent {
    
    handleItemClick = (event,item) => {
        if (!item.onClick && !item.action) return;
        if (item.onClick) item.onClick();
        if (item.action) this.props.dispatch(item.action());
        this.props.onClose(event);
    };
    
    renderMenuItems = () => {
        const { menuItems } = this.props;
        return menuItems.map((menuItem, i) => {
            if (menuItem.key === 'divider') {
                return (<Divider key={i} style={{ margin: '0.4rem 0' }} />);
            }
            if (menuItem.hasOwnProperty("subMenuItems")) {
                return (
                    <SubMenu
                        key={menuItem.key}
                        caption={menuItem.caption}
                        menuItems={menuItem.subMenuItems}
                    />
                );
            }

            return (
                <MenuItem
                    key={menuItem.key}
                    item={menuItem}
                    clickHandler={this.handleItemClick}
                />
            );
        });
    };

    render() {
        const {
            anchorElement,
            open,
            onClose,
            menuItems,
            dispatch,
            ...others
        } = this.props;
        
        return (
            <MuiMenu
                {...others}
                anchorEl={anchorElement}
                open={open}
                onClose={onClose}
            >
                {this.renderMenuItems()}
            </MuiMenu>
        );
    }
}

export default connect(mapState)(EnhancedMenu);

EnhancedMenu.propTypes = {
    anchorElement: PropTypes.any,
    menuItems: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};
