export const consoleDestroyWebsite = (id) => ({
  type: "CONSOLE.WEBSITES.DESTROY",
  id
});

export const consoleRefreshWebsite = (id) => ({
  type: "CONSOLE.WEBSITES.REFRESH",
  id
});

export const consoleRestoreSnapshot = (id) => ({
  type: "CONSOLE.CMS.RESTORE",
  id
});

export const consoleDumpSnapshot = (id) => ({
  type: "CONSOLE.CMS.DUMP",
  id
});

export const consoleRenewCertificate = (id) => ({
  type: "CONSOLE.CERT.RENEW",
  id
});

export const consoleDomainConfigReload = (id) => ({
  type: "CONSOLE.DOMAIN.CONFIG",
  id
});

export const consoleDomainCacheClear = (id) => ({
  type: "CONSOLE.DOMAIN.CACHECLEAR",
  id
});

export const consoleThemeBuild = (id) => ({
  type: "CONSOLE.THEME.BUILD",
  id
});