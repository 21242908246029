import googleFonts from './google-fonts';
import zuuFonts from './zuu-fonts';

export const adapters = {
    'cms.fonts.google': googleFonts,
    'cms.fonts.zuu': zuuFonts,
    'console.cms.snapshots': (data) => ({
        data: { data: Object.values(data.data.data) }
    })
};

export default function parseResponse(resource, response) {
    if (adapters[resource]) return adapters[resource](response);
    return response;
}