import { createSelector } from "reselect";
import createCachedSelector from 're-reselect';
import _ from 'lodash';

import { deepMerge } from 'util/deepMerge';

const nullObj = {};
const nullArr = [];

export const selectNamespacedResources = createSelector(
    [
        (store)=>store.resources,
        (store)=>store.data['db.types'],
        (store, props, ns)=>ns || store.context.app
    ],
    (resources, types, ns, depth = 99) => {
        let keys = {};
        let path = ns;
        let filtered = {};
        if (ns === 'db' && types) {
            Object.values(types).forEach(type=> {
                let key = [ns, type.name].join('.');
                filtered[key] = {
                    ...type,
                    id: key,
                    route: '/:app/:lang/:project/'+type.name
                };
            })
        }
        keys = Object.keys(resources).filter((key) => {
            return key.indexOf(path) === 0;
        });
        keys.forEach((key) => {
            resources[key].id = key;
            if (!filtered[key]) {
                filtered[key] = resources[key];
            }
        });
        return Object.values(filtered);
    }
);

export const index = (data, indexKey = 'id') => {
    let indexed = {};
    if (!data) return indexed;
    if (data && !Array.isArray(data)) return data;
    data.forEach((record) => {
        indexed[record[indexKey]] = record
    });
    return indexed;
};

export const selectFilteredResources = (store, resource, filterId, listId) => {
    let filter = store.filter[filterId] || {};
    let data = store.data[resource] || nullObj;
    let list = listId ? store.lists[listId] || [] : null;
    return filterList(data, filter, list);

};

export const filterMedia = (item, filter) => {
    if (filter.path === 'videos') {
        return item.type === 'video';
    }
    if (filter.path === 'pano') {
        return [
            'google_pano',
            'hosted_pano'
        ].indexOf(item.type) > -1;
    }
    let parts = item.file.split('/');
    let root = parts[1];
    let path = parts.slice(2).slice(0, -1).join('/');
    //if (!filter.path) return path === '/';
    return root === 'user' && (path === filter.path);
};

export const filterList = (data, filter, list) => {
    if (!list) list = Object.keys(data);
    
    let filtered = {};
    list.forEach((id) => {
        let searchable = null;
        let match = false;
        if (data[id]) {
            searchable = data[id].file
                || data[id].name
                || data[id].title
                || data[id].description
                || data[id].label
                || id;
        }
        if (filter.search && searchable && searchable.toLowerCase()
            .indexOf(filter.search.toLowerCase()) > -1
        ) {
            match = true;
        }
        if (!filter.search) {
            match = true;
        }
        //if (filter.type && data[id] && (data[id].type === filter.type)) match = true;
        if (filter.path !== undefined && !filter.search) {
            match = filterMedia(data[id], filter);
        }
        if (match && data[id]) filtered[id] = data[id];
    });
    return filtered;
};

export const selectFilteredList = (...args) => {
    return Object.keys(selectFilteredResources(...args))
};

export const selectUnindexedList = (...args) => {
    return Object.values(selectFilteredResources(...args));
};

export const selectStoreResource = (store, path) => {
    return _.get(store, path) || nullObj;
};

export const selectResource = createCachedSelector([
    (store,resource,id) => selectStoreResource(store.data, [resource, id]),
    (store,resource,id) => selectStoreResource(store.drafts, [resource, id])
], (data, draft) => {
    return deepMerge({}, data, draft); //{...data, ...draft};
})((state,resource,id)=>[resource,id].join('/'));

export const selectResourcePath = createCachedSelector([
    (store,path) => selectStoreResource(store.data, path),
    (store,path) => selectStoreResource(store.drafts, path)
], (data, draft) => {
    return deepMerge({}, data, draft); //{...data, ...draft};
    //return {...data, ...draft};
})((state,path)=>path.join('/'));

export const selectAllResources = (store, type) => {
    if (!store.data[type]) return nullArr;
    return Object.values(store.data[type]);
};

export const selectIds = (store, path) => {
    let data = selectPath(store, path) || [];
    if (!data) return nullArr;
    return data.map(item=>item.id);
};

export const selectAllResourcesIndexed = (store, type) => {
    if (!store.data[type]) return nullObj;
    return store.data[type];
};

export const selectPath = (store, path) => {
    let data = _.get(store.data, path);
    let draft = _.get(store.drafts, path);
    if (undefined === draft) return data;
    return draft;
};

export const selectList = (store, resource, ids) => {
    if (!ids) return nullArr;
    return ids.map((id) => {
        return selectResource(store, resource, id)
    });
};

export const selectListIndexed = (store, resource, ids) => {
    return index(selectList(store, resource, ids));
};

export const mergeEntities = (store) => {
    const data = {...store.data};
    const drafts = store.drafts;

    Object.keys(drafts).forEach((resource) => {
        Object.keys(drafts[resource]).forEach((id) => {
            if (!data[resource]) {
                data[resource] = {};
            } else {
                data[resource] = {...data[resource]};
            }
            if (!data[resource][id]) {
                data[resource][id] = {...drafts[resource][id]};
            } else {
                data[resource][id] = deepMerge(
                    {},
                    data[resource][id],
                    drafts[resource][id]
                );
            }
        });
    });

    return data;
};

export const selectParentId = (store, resource, id, root) => {
    const parent = selectPath(store, [resource,id,'__parent']);
    if (!parent) return id;
    return selectParentId(store, resource, parent);
};

export const selectDrafts = (store) => {
    const unsaved = [];
    Object.keys(store.drafts).forEach((k) => {
        Object.keys(store.drafts[k]).forEach((id) => {
            let p = null;
            if (k === 'cms.components') {
                p = selectParentId(store, k, id);
                unsaved.push({id: p, resource: 'cms.sections'});
                return;
            }
            if (k === 'cms.nav-link') {
                p = selectParentId(store, k, id);
                unsaved.push({id: p, resource: 'cms.sections'});
                return;
            }
            if (k === 'cms.items') {
                p = selectParentId(store, 'cms.components', selectParentId(store, k, id));
                unsaved.push({id: p, resource: 'cms.sections'});
                return;
            }
            unsaved.push({id: id, resource: k, draft: store.drafts[k][id].draft, parent: p});
        });
    });
    return unsaved;
};

export const selectResourceDefinition = (store, id) => {
    return store.resources[id];
};

export const isEmpty = (store, listId) => {
    return !(store.lists[listId] && store.lists[listId].length > 0);
};

export const isEmptyPath = (store, path) => {
    let value = selectPath(store, path);
    return !(value && value.length > 0);
};

export const selectSchemaOptions = (store, path) => {
    let options = _.get(store.schema, path.split('.'));
    return index(options);
};

export const selectDenormalizedResource = (store, resource, id) => {
    
};