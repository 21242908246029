import React from 'react';
import {connect} from 'react-redux';

import IconMenu from 'components/IconMenu';
import ResourceActions from 'containers/ResourceActions';
import Access from "components/Access";

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

class ItemActions extends React.Component {

    render() {
        const { actions, resource, id, isOpen, onClose, isActive } = this.props;

        return actions ? (
            <Access policy={['resource', resource, '*'].join(':')}>
                <IconMenu
                    open={isOpen}
                    onClose={onClose}
                >
                    <ResourceActions
                        isActive={isActive}
                        onActionRequest={this.props.onActionRequest}
                        actionsDefKey={actions}
                        resource={resource}
                        id={id}
                    />
                </IconMenu>
            </Access>) : null;
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ItemActions);