import React from 'react';
//import PropTypes from 'prop-types';

export default class ListItemFields extends React.PureComponent {

    render() {
        return (<div className="list-item-columns">
            {this.props.children}
        </div>);
    }

}

ListItemFields.propTypes = {};