import Logout from 'auth/views/Logout';
import CmsDashboard from 'views/cms/Dashboard';
import MainListFormView from 'views/layouts/MainListingForm';
import Ide from 'views/ide';
import ApartmentsDashboard from 'views/apartments/Dashboard';
import DbDashboard from 'views/db/Dashboard';
import Playground from 'views/playground/Playground';
import Timeline from 'views/playground/Timeline';
import MediaLibrary from 'views/cms/MediaLibrary';
import Main from 'v2/Main';
import Home from 'views/Home';


export { auth } from './auth';

export default [
    {
        component: Logout,
        paths: ['/logout']
    },
    {
        component: Timeline,
        params: {app: 'db'},
        paths: ['/db/:lang/:project/timeline']
    },
    {
        component: Playground,
        params: {app: 'dev'},
        paths: ['/playground']
    },
    {
        component: MediaLibrary,
        params: {app: 'cms'},
        paths: [
            '/cms/:lang/:project/media'
        ]
    },
    {
        component: MainListFormView,
        params: {app: 'cms'},
        paths: [
            '/cms/:lang/:project/:view/:id/:relation/:relatedId',
            '/cms/:lang/:project/:view/:id/:relation',
            '/cms/:lang/:project/:view/:id',
            '/cms/:lang/:project/:view',
            '/cms/:lang/:project'
        ]
    },
    {
        component: CmsDashboard,
        params: {app: 'cms'},
        paths: [
            '/cms/:lang',
            '/cms'
        ]
    },
    {
        component: MainListFormView,
        params: {app: 'apartments'},
        paths: [
            '/apartments/:lang/:project/:view/:id',
            '/apartments/:lang/:project/:view',
            '/apartments/:lang/:project'
        ]
    },
    {
        component: ApartmentsDashboard,
        params: {app: 'apartments'},
        paths: [
            '/apartments/:lang',
            '/apartments'
        ]
    },
    {
        component: Main,
        params: {app: 'db'},
        paths: [
            '/db/:lang/:project/:view/:id',
            '/db/:lang/:project/:view',
            '/db/:lang/:project'
        ]
    },
    {
        component: MainListFormView,
        params: {app: 'db'},
        paths: [
            '/db/:lang/:project/filters/:id',
            '/db/:lang/:project/filters'
        ]
    },
    {
        component: DbDashboard,
        params: {app: 'db'},
        paths: [
            '/db/:lang',
            '/db'
        ]
    },
    {
        component: MainListFormView,
        params: {app: 'console'},
        paths: [
            '/console/:view/:id',
            '/console/:view',
            '/console'
        ]
    },
    {
        component: Ide,
        params: {app: 'cms', appPath: 'ide'},
        paths: [
            '/ide/:lang/:project/:view/:id/:relation/:relatedId',
            '/ide/:lang/:project/:view/:id/:relation',
            '/ide/:lang/:project/:view/:id',
            '/ide/:lang/:project/:view',
            '/ide/:lang/:project'
        ]
    },
    {
        component: Home,
        params: {app: 'cms'},
        paths: [
            '/'
        ]
    },
]