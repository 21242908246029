import React from 'react';
import {connect} from 'react-redux';

import ListItem from 'components/list/ListItem';
import Draggable from 'components/Draggable';

import Resource from 'containers/Resource';

import { selectValue, renderFields, renderField } from './fields';
import { selectListViewConfig } from 'state/selectors/list';

import resourceListSchema from 'config/lists';
import itemTypes from 'config/list-item-types';
import avatarTypes from './avatar';

import {ExampleListItem} from "views/playground/Playground";

export class ResourceListItem extends React.PureComponent {

    draggable = (item) => {
        if (this.props.connectDnd) {
            return (<ExampleListItem {...this.props}>
                {item}
            </ExampleListItem>);
        }
        return <Draggable
            {...this.props}
        >{item}</Draggable>;
    };

    render() {
        const props = this.props;
        
        const {
            data,
            resource,
            resourceType,
            draggable,
            definition,
            config,
            readOnly
        } = props;

        const type = data ? data.type : null;
        if (!data) return (<div>ERROR!</div>);

        let itemTypeKey = !resourceType && type
            ? resource + '.' + type
            : resourceType || resource;

        let listSchema = config;

        let activeKey = listSchema.activeKey || 'active';
        let activeInvert = activeKey.indexOf('!')===0;
        activeKey = activeKey.replace('!','');

        let isActive = activeInvert
            ? !data[activeKey]
            : data[activeKey];

        itemTypeKey = config
            ? config.type || itemTypeKey
            : itemTypeKey;

        const fields = listSchema.fields
            ? renderFields(listSchema.fields, data)
            : null;

        const ListItemComponent = itemTypes[itemTypeKey]
            || itemTypes[resource]
            || itemTypes[listSchema.type]
            || ListItem;

        const avatarType = listSchema.avatar || 'resource-icon';
        const AvatarComponent = avatarTypes[avatarType];

        const primaryValue = selectValue(data, listSchema.primaryText, true);
        const secondaryValue = selectValue(data, listSchema.secondaryText, false);

        const secondaryAction = definition ? definition.secondaryAction : null;

        const secondaryActionLabel = definition && secondaryAction && definition.actions[secondaryAction]
            ? definition.actions[secondaryAction].label
            : null;
        
        const item = (
            <ListItemComponent
                {...props}
                secondaryAction={secondaryAction}
                secondaryActionLabel={secondaryActionLabel}
                isDraft={!!props.data.draft}
                primaryText={!listSchema.primaryTextFormat
                    ? primaryValue
                    : renderField(listSchema.primaryText, listSchema.primaryTextFormat, primaryValue, data)}
                secondaryText={!listSchema.secondaryTextFormat
                    ? secondaryValue
                    : renderField(listSchema.secondaryText, listSchema.secondaryTextFormat, secondaryValue, data)}
                isActive={isActive}
                avatar={AvatarComponent
                    ? (props) => <AvatarComponent
                        {...this.props}
                        {...props}
                        color={this.props.data.color}
                        resourceTypeKey={itemTypeKey}
                    />
                    : null}
                fields={fields}
            />);
        
        if (!readOnly && (draggable || this.props.connectDnd)) {
            if (ListItemComponent.ownDragHandle) {
                return item;
            }
            return this.draggable(item);
        }
        return item;
    }
}

export const connectResource = () => {
    return (DecoratedComponent) => {
        const mapState = (store, props) => ({
            definition: store.resources[props.actions] || {},
            config: selectListViewConfig(store, props)
        });
        const ResourceClass = class Decorator extends React.PureComponent {
            renderItem = (props) => {
                return <DecoratedComponent {...props} />
            };
            render() {
                return <Resource
                    {...this.props}
                    render={this.renderItem}
                />
            }
        };
        return connect(mapState)(ResourceClass);
    }
};

const ConnectedResourceListItem = connectResource()(ResourceListItem);

export default ConnectedResourceListItem;

export const RenderResourceListItem = (props) => {
    return <ConnectedResourceListItem {...props} />;
};