import { findDOMNode } from "react-dom";

const moveMonitor = {y: 0};

const logDragEvent = (event, props, monitor) => {
  return;
  let propsItem = [props.resource,props.id].join('.');
  let item = monitor.getItem();
  let monitorItem = item ? [item.resource,item.id].join('.') : null;
  console.log(event, propsItem, monitorItem);
};

export function shouldUpdateOrder(props, monitor, component) {
  const item = monitor.getItem();
  const dragIndex = item.index;
  const hoverIndex = props.index;
  const dragId = item.id;
  const hoverId = props.ctxId || props.id;

  // Don't replace items with themselves
  if (dragId === hoverId) {
    return false;
  }

  // Determine rectangle on screen
  const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

  // Get vertical middle
  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
  const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

  // Determine mouse position
  const clientOffset = monitor.getClientOffset();

  // Get pixels to the top
  const hoverClientY = clientOffset.y - hoverBoundingRect.top;
  const hoverClientX = clientOffset.x - hoverBoundingRect.left;

  // Only perform the move when the mouse has crossed half of the items height
  // When dragging downwards, only move when the cursor is below 50%
  // When dragging upwards, only move when the cursor is above 50%

  // Dragging downwards
  if (
    dragIndex < hoverIndex &&
    hoverClientX > hoverMiddleX &&
    (dragIndex < hoverIndex && hoverClientY > hoverMiddleY)
  ) {
    return false;
  }

  // Dragging upwards
  if (
    dragIndex > hoverIndex &&
    hoverClientX < hoverMiddleX &&
    (dragIndex > hoverIndex && hoverClientY < hoverMiddleY)
  ) {
    return false;
  }

  let moveDelta = Math.abs(moveMonitor.y - clientOffset.y);

  if (moveDelta < 20) {
    return false;
  }
  moveMonitor.y = clientOffset.y;

  //console.log('reorder',dragIndex,hoverIndex,hoverClientX,hoverClientY);

  return {
    dragIndex,
    hoverIndex,
    dragId,
    hoverId
  };
}

export const cardSource = {
  beginDrag(props, monitor, component) {
    logDragEvent('cardSource.beginDrag', props, monitor);
    moveMonitor.y = monitor.getClientOffset().y;
    return {
      id: props.ctxId || props.id,
      index: props.index,
      resource: props.resource || 'unknown',
      source: props.listId
    };
  },
  endDrag(props,monitor) {
    logDragEvent('cardSource.endDrag', props, monitor);
    let drop = monitor.getDropResult();
    if (drop && props.onDropItem) {
      props.onDropItem(monitor.getItem(), drop.dropEffect);
    }
  }
};

export const cardTarget = {
  hover(props, monitor, component) {
    let targetType = props.resource;
    let dragType = monitor.getItem().resource;

    if (dragType !== targetType && props.onHoverWith) {
        props.onHoverWith(props.id, monitor.getItem());
        return;
    }

    if (dragType !== targetType) return;

    let status = shouldUpdateOrder(props, monitor, component);
    if (!status) return;

    // Time to actually perform the action
    //props.moveCard(dragIndex, hoverIndex);
    //props.moveCard(dragId, hoverId);
    if (props.onSort) {
        logDragEvent('cardTarget.onSort', props, monitor);
        props.onSort(monitor.getItem(), status.hoverId);
        monitor.getItem().index = status.hoverIndex;
        return;
    }
    if (props.onChangeOrder) {
        props.onChangeOrder(status.dragId, status.hoverId);
        monitor.getItem().index = status.hoverIndex;
    }

    if (props.onChangeOrderIndex) {
        props.onChangeOrderIndex(status.dragIndex, status.hoverIndex);
        monitor.getItem().index = status.hoverIndex;
    }

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    //monitor.getItem().id = status.hoverId;
  },
  drop(props, monitor, component) {
    if (props.onSortEnd) props.onSortEnd(monitor.getItem(), props.id);
    if (props.resource !== monitor.getItem().resource) return;
    if (props.onDrop) props.onDrop(monitor.getItem());
  },
  canDrop() {
    return false;
  }
};

export const dropAreaTarget = {
  canDrop(props, monitor) {
    return true; //props.resource === monitor.getItem().resource;
  },
  hover(props, monitor, component) {
    logDragEvent('dropAreaTarget.hover', props, monitor);
      if (monitor.canDrop()) {
        if (props.onDragOver) props.onDragOver(monitor.getItem());
      }
  },
  drop(props, monitor, component) {
    logDragEvent('dropAreaTarget.drop', props, monitor);
    //console.log(monitor.getDropResult());
      if (monitor.canDrop()) {
        if (props.onDropInto) props.onDropInto(monitor.getItem());
      }
  }
};

export const collectSource = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
});

export const collectTarget = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver({ shallow: false }),
  canDrop: monitor.canDrop(),
  overItem: monitor.getItem()
});
