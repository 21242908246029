import React from "react";
//import PropTypes from 'prop-types';

//import {ResourceList} from 'list';
import ResourceList from "containers/ResourceList";
import Resource from "containers/Resource";

import Preloader from "components/Preloader";
import TextField from "components/fields/text/TextField";
import IconButton from "components/TooltipIconButton";
import File, { sizes } from "components/list/items/File";

import Breadcrumbs from "components/Breadcrumbs";

import Upload from "containers/Upload";

export default class MediaPicker extends React.PureComponent {
  handleSelect = (media) => {
    if (!this.props.multiple) this.props.onClose();
    this.props.onSelect({
      id: media.id,
      file: media.file,
      type: media.type,
    });
  };

  renderItem = (props) => {
    let isSelected = this.props.selectedItems
      ? this.props.selectedItems.indexOf(props.data.id) > -1
      : false;

    return (
      <File
        {...props}
        onDeleteFile={this.props.onDeleteFile}
        size={this.props.size}
        onClick={this.handleSelect}
        selected={isSelected}
      />
    );
  };

  connectItem = (props) => {
    return (
      <Resource
        key={props.id}
        resource={props.resource}
        render={this.renderItem}
        id={props.id}
      />
    );
  };

  handleFilterSearch = (id, value) => {
    this.props.onSetFilter(this.props.filterId, { search: value });
  };

  handleOpenLibrary = () => {
    this.props.onClose();
    this.props.onOpenLibrary();
  };

  render() {
    const { path, selection, filterId, filter, isPending, onOpenLibrary } =
      this.props;

    const cols = this.props.cols || 5;
    const sizePreset = this.props.size || "sm";
    const size = sizes[sizePreset];
    const width = (size + 1) * cols;

    let items = (
      <ResourceList
        listId={this.props.listId}
        resource="cms.media"
        filterId={filterId}
        renderItem={this.connectItem}
      />
    );

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          position: "relative",
        }}
      >
        <Preloader visible={isPending} />
        <div className="popover-filter">
          {false ? <Breadcrumbs /> : null}
          <div style={{ display: "flex" }}>
            <TextField
              id="cms-media-search"
              onChange={this.handleFilterSearch}
              value={filter.search}
              label="Szukaj..."
              variant="search"
              autoFocus={true}
            />
            {onOpenLibrary ? (
              <div>
                <IconButton
                  tooltip="Otwórz bibliotekę"
                  onClick={this.handleOpenLibrary}
                  icon={"launch"}
                />
              </div>
            ) : null}
          </div>
        </div>
        <Upload
          style={{
            overflow: "hidden auto",
            flex: 1,
            minWidth: width,
            width: "100%",
            padding: "0 0.8rem 0.8rem 0.8rem",
            maxHeight: ["xs", "sm"].indexOf(sizePreset) > -1 ? "300px" : "none",
          }}
          onFileUpload={this.props.onUpload}
        >
          <div
            style={{
              width: "100%",
              display: "grid",
              gridGap: ["xs", "sm"].indexOf(sizePreset) > -1 ? "1px" : "1rem",
              gridTemplateColumns:
                "repeat(auto-fill, minmax(" + size + "px, 1fr))",
            }}
          >
            {items}
          </div>
        </Upload>
        {path && selection && selection.length ? (
          <div className="media-selection">
            {selection ? (
              selection.map((media) => {
                return (
                  <File
                    data={media}
                    key={media.id}
                    onClick={this.handleSelect}
                  />
                );
              })
            ) : (
              <div>no items selected</div>
            )}
          </div>
        ) : null}
      </div>
    );
  }
}

MediaPicker.propTypes = {};
