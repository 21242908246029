import { index } from 'state/selectors/resources';

export default function(data) {
    const result = {
        data: {
            data: index(data.data.items, 'family')
        }
    };

    console.log(result);
    return result;
}