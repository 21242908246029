import React from 'react';
import {connect} from 'react-redux';
import Resource from 'v2/Resource';
import Avatar from '@material-ui/core/Avatar';
import {thumbnail} from "util/thumbnail";

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};


const avatarStyle = {
    width: '24px',
    height: '24px',
    marginRight: '0.4em'
};

class UserInfo extends React.Component {

    render() {
        return (<Resource
            resource={'db.users'}
            id={this.props.value}
            fetchPolicy={'yield'}
        >{(props)=>{
            const image = props.data.profile
                ? props.data.profile.avatar
                : null;
            return <div style={{ display: 'flex', alignItems: 'center' }}>
                { image ? <Avatar
                    src={image}
                    style={avatarStyle}
                /> : null }
                {props.data.name}
            </div>}
        }
        </Resource>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserInfo);