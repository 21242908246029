import React from 'react';
import {connect} from 'react-redux';
import { query } from 'state/actions/route';
import { draftsChange, listCreate, draftsSubmit } from 'state/actions';
import { selectList, selectResource } from 'state/selectors/resources';
import { refreshLessStylesFromState } from 'state/actions/ide';
import CodeEditor from 'components/fields/code-editor/CodeEditor';
import ResourceList from 'containers/ResourceList';
import ResourceListItem from 'components/list/ResourceListItem';
import List from '@material-ui/core/List';
import Popover from 'components/Popover';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import CreateThemeFile from './popovers/CreateThemeFile';
import Button from 'components/Button';
import Preloader from 'components/Preloader';
import Keymap from 'containers/keys/Keymap';

const blacklist = [
    'framework'
];

const filterFiles = (files) => {
    const filtered = [];
    Object.keys(files).forEach((file) => {
        if (blacklist.indexOf(file) < 0) {
            filtered[file] = files[file];
        }
    });
    return filtered;
};

const mapStateToProps = (store, props) => {
    const current = store.context.file;
    const listId = 'cms.theme.files';
    let all, files = null;
    let file = null;
    if (store.data[listId]) {
        file = current
            ? selectResource(store, listId, current)
            : null;
        all = Object.keys(store.data[listId]);
        files = selectList(store, listId, all);
    }
    //const files = filterFiles(store.data[listId] || []);
    //const file =  current ? files[current] : null;
    return {
        listId,
        files: files,
        file,
        fileName: current && file,
        draft: current && store.drafts[listId]
            ? store.drafts[listId][current]
            : false,
        isPending: current
            ? store.ui.pending[current]
            : false
    }
};

const mapDispatchToProps = {
    query,
    onChange: draftsChange,
    onCreate: listCreate,
    onSave: draftsSubmit,
    onRefresh: refreshLessStylesFromState
};

class Editor extends React.PureComponent {

    constructor(props) {
        super(props);
        this.fileProps = {
            onClick: this.handleChangeFile
        }
    }

    handleChangeFile = (file, onClose) => {
        this.props.query({file});
        if (onClose) onClose();
    };

    handleCreateFile = (file) => {
        this.props.onCreate(
            this.props.listId,
            this.props.listId,
            {id: file, content: '/** ' + file + ' **/\n'}
        );
        this.handleChangeFile(file);
    };

    handleEdit = (field, content) => {
        this.props.onChange(
            [this.props.listId, this.props.file.id],
            { content }
        );
        //this.delayedRefresh();
    };

    delayedRefresh = () => {
        if (this.refreshTimeout) clearTimeout(this.refreshTimeout);

        this.refreshTimeout = setTimeout(
            this.props.onRefresh,
            5000
        );
    };

    handleClearFile = () => {
        this.props.query({file:null});
    };

    saveKeyHandler = () => {
        return draftsSubmit(this.props.listId, this.props.file.id);
    };

    handleSave = () => {
        this.props.onSave(this.props.listId, this.props.file.id);
    };

    makeFileProps = (onClose) => {
        return {
            onClick: (id) => this.handleChangeFile(id, onClose)
        }
    };

    renderFilesList = (props) => {
        return <List><ResourceList
            resource={this.props.listId}
            itemComponent={ResourceListItem}
            itemProps={this.makeFileProps(props.onClose)}
            selectedItem={this.props.fileName}
            yieldData={true}
            fetchOnce={true}
        /></List>;
    };

    render() {
        const { isPending, draft } = this.props;
        return (<Keymap
            id="form"
            save={this.saveKeyHandler}
            style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
        >
            <Preloader visible={isPending} />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
            { this.props.fileName ? (<IconButton onClick={this.handleClearFile}>
                <Icon>arrow_back</Icon>
            </IconButton>) : null}
            <Popover
                trigger={(props)=><div {...props} style={{
                    padding: '0.6rem',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                }}>
                    <Icon fontSize="inherit">insert_drive_file</Icon> {this.props.file ? this.props.file.id : 'Wybierz plik'}
                </div>}
                content={this.renderFilesList}
            />
            <div style={{ flex: 1 }} />
            <Popover
                trigger={(props)=><IconButton {...props}><Icon>add_circle</Icon></IconButton>}
                content={(props)=><CreateThemeFile
                    onClose={props.onClose}
                    onSubmit={this.handleCreateFile}
                />}
            />
            </div>
            {this.props.file
                ? (<div style={{ flex: 1 }}><CodeEditor
                    value={this.props.file.content}
                    height="100%"
                    rootId={this.props.file.id}
                    id="code"
                    onChange={this.handleEdit}
                /></div>)
                : this.renderFilesList({}) }

            {this.props.file
                ? (<div className="form__footer">
                    <Button
                        icon="save"
                        onClick={this.handleSave}
                        disabled={!draft}
                    >
                        Zapisz
                    </Button>
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: '12px', opacity: 0.5 }}>
                        ctrl+s - save<br />
                        ctrl+space - refresh
                    </div>
                </div>)
                : null }
        </Keymap>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Editor);