import React from 'react';
import PropTypes from 'prop-types';
import itemTypes from "config/list-item-types";
import ListItem from "components/list/ListItem";
import Field from "v2/Field";
import Avatar from "v2/fields/info-fields/Avatar";
import {RenderValue} from "v2/list-items";
import RenderListField from 'v2/fields/info-fields';

export default function RenderListItem(props) {
    const handleOnClick = ()=>props.onClick(props.id);
    let CustomType = itemTypes[props.resource];
    const {
        resource,
        id,
        onClick,
        readOnly,
        fields,
        index,
        onSort,
        onSortEnd,
        origin,
        sortable,
        draggable,
        canDrop,
        connectDnd,
        connectDragHandle,
        connectDragPreview,
        isDragging,
        isOver,
        isPlaceholder,
        ...other
    } = props;

    if (CustomType) return <CustomType
        {...other}
        onClick={handleOnClick}
        resource={props.resource}
        id={props.id}
        readOnly={props.readOnly}
    />;

    return (<ListItem
        actions={props.resource}
        onClick={handleOnClick}
        resource={props.resource}
        id={props.id}
        readOnly={props.readOnly}
        avatar={()=><Field
            resource={props.resource}
            id={props.id}
            fieldName="avatar"
            hydrate={true}
        >
            {(props)=><Avatar {...props} />}
        </Field>}
        primaryText={<Field
            resource={props.resource}
            id={props.id}
            fieldId={props.primaryId}
            fieldName="primaryText"
            readOnly={props.readOnly}
        >
            {RenderListField}
        </Field>}
        secondaryText={<Field
            resource={props.resource}
            id={props.id}
            fieldId={props.secondaryId}
            fieldName="secondaryText"
            readOnly={props.readOnly}
            listView={true}
        >
            {RenderListField}
        </Field>}
    />);
}