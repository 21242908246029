import React from 'react';
import {connect} from 'react-redux';
import { selectResourcePath } from 'state/selectors/resources';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import DateDiff from 'components/list/fields/DateDiff';

const mapStateToProps = (store, props) => {
    const resource = selectResourcePath(store, props.path.split('/'));
    return {
        createdAt: resource.created_at,
        updatedAt: resource.updated_at
    }
};

const mapDispatchToProps = {};

class ModificationDate extends React.PureComponent {

    render() {
        const { updatedAt } = this.props;
        if (!updatedAt) return null;
        return (<div style={{
            display: 'flex',
            opacity: 0.5,
            alignItems: 'center'
        }}>
            <Icon style={{ opacity: 0.5, marginRight: '0.4rem' }}>access_time</Icon>
            <Typography>
                <DateDiff value={updatedAt} />
            </Typography>
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModificationDate);