import React from 'react';
import {connect} from 'react-redux';

import {
    listReorder,
    //listCreate,
    listSaveOrder,
    resourceAction,
    listUpdate,
    //resourceSave
} from 'state/actions';

import { isEmpty } from 'state/selectors/resources';

const mapDispatchToProps = {
    reorder: listReorder,
    listSaveOrder,
    resourceAction,
    listUpdate
};

const mapStateToProps = (store, props) => {
    const list = store.context.view ? [
        store.context.app,
        store.context.view
    ].join('.') : null;

    let id = store.context.id;

    return {
        project: store.context.project,
        view: store.context.view,
        id: id,
        list,
        listId: list,
        resource: list,
        isPending: store.ui.pending[list],
        isEmpty: isEmpty(store, list),
        primaryAction: props.primaryAction || 'primary',
        selectedItem: store.context.id
    }
};

class RoutePrimary extends React.PureComponent {

    constructor(props) {
        super(props);

        this.itemProps = {
            onClick: this.handleClick,
            onActionRequest: this.handleAction
        };
    }

    handleClick = (id) => {
        this.props.resourceAction(
            this.props.primaryAction,
            this.props.list, id
        );
    };

    handleChangeOrder = ({data}) => {
        console.log('handleChangeOrder ', data);
        this.props.listUpdate(this.props.list, data);
        this.props.listSaveOrder(this.props.list, this.props.list);
    };

    handleAction = (...args) => {
        this.props.resourceAction(...args);
    };

    render() {
        return this.props.render({
            ...this.props,
            itemProps: this.itemProps,
            onChangeOrder: this.handleChangeOrder
        })
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(RoutePrimary);