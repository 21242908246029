import React from 'react';
//import PropTypes from 'prop-types';

export default class ListItemField extends React.PureComponent {

    render() {
        return (<div key={this.props.key}>
            {this.props.children}
        </div>);
    }

}

ListItemField.propTypes = {};