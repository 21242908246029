import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import Card from '@material-ui/core/Card';
import DragHandle from 'components/list/DragHandle';
import IconButton from 'components/TooltipIconButton';
import CreateComponent from 'components/fields/components/CreateComponent';

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

class EditableField extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
          hover: false
        };
        this.events = {
            onMouseEnter: this.handleMouseEnter,
            onMouseLeave: this.handleMouseLeave
        }
    }

    handleMouseEnter = (evt) => { evt.stopPropagation(); this.setState({hover: true}) };
    handleMouseLeave = () => this.setState({hover: false});
    handleDelete = () => this.props.onDelete(this.props.fieldId);
    handleAppend = (type) => this.props.onAppend(type, this.props.fieldId);
    handleEdit = () => this.props.onEdit(this.props.fieldId);

    render() {
        const typeClass = this.props.data.type;
        const { connectDragHandle, connectDnd } = this.props;
        const classes = {
            "editable-field": true,
            'is-dragging': this.props.isDragging,
            'is-over': this.props.isOver && !this.props.isDragging
        };
        classes[typeClass] = true;
        const field = (<div className={classNames(classes)} {...this.events}>

                <div style={{
                    transition: 'opacity .3s',
                    display: 'flex', flex: 1, flexDirection: 'row',
                    opacity: this.props.isDragging ? 0 : 1
                }}>
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                    <div style={{ flex: 1 }}>
                        {this.props.children}
                    </div>
                    <div className="field-add" style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        pointerEvents: 'all'
                    }}>
                        <div style={{ transform: 'scale(0.8)'}}>
                            <IconButton onClick={this.handleEdit} icon="edit" />
                            <IconButton onClick={this.handleDelete} icon="clear" />
                            <CreateComponent
                                resource={'db.view_fields'}
                                onCreate={this.handleAppend}
                            />
                        </div>
                    </div>
                </div>
                </div>
        </div>);
        if (connectDnd) return connectDnd(field);
        return field;
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditableField);