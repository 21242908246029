import ResourceIcon from './ResourceIcon';
import Media from './Media';
import SectionType from './SectionType';
import FlatStatus from './FlatStatus';
import UserAvatar from './UserAvatar';
import Check from './Check';

export default {
    'resource-icon': ResourceIcon,
    'media': Media,
    'section-type': SectionType,
    'flat-status': FlatStatus,
    'user-avatar': UserAvatar,
    'check': Check
};