import React, {Component} from 'react';
import {connect} from 'react-redux';
import IconButton from "@material-ui/core/IconButton";
import Fingerprint from "@material-ui/icons/Fingerprint";
import Tooltip from "@material-ui/core/Tooltip";
import CopyToClipboard from 'react-copy-to-clipboard';

import { feedback } from 'state/actions/ui';

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {
    feedback
};

class IdentityIcon extends Component {

    handleOnCopy = () => {
      this.props.feedback('Skopiowano do schowka!', 'info');
    };

    render() {
        const { value } = this.props;
        return (
            <Tooltip title={value || ''}>
                <CopyToClipboard onCopy={this.handleOnCopy} text={value}>
                    <IconButton>
                        <Fingerprint/>
                    </IconButton>
                </CopyToClipboard>
            </Tooltip>
        );
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(IdentityIcon);