import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'components/fields/text/TextField';

export default class Text extends React.PureComponent {

    render() {
        return (<TextField {...this.props} variant={'inline'} />);
    }

}

Text.propTypes = {};