import { denormalize, normalize, schema } from "normalizr";
import { index } from "state/selectors/resources";

const processWithParent = {
  processStrategy: (value, parent, key) => {
    return { ...value, __parent: parent.parent ? parent.parent.id : parent.id };
  },
};

const cms = new schema.Entity("console.services.cms");

const preset = new schema.Entity("console.presets");
//const typography = new schema.Entity('console.typography');

const theme = new schema.Entity("console.themes", {
  preset,
});

theme.define({ parent: theme });

const themeReq = new schema.Entity("console.themes", { preset });

const domain = new schema.Entity("console.domains");
domain.define({
  alias: domain,
});

const website = new schema.Entity("console.services.website");
website.define({
  content_source: cms,
  theme: theme,
  canonical_domains: [domain],
  canonical_domain: domain,
  library: website,
});

const cluster = new schema.Entity("console.services.cluster");
cluster.define({
  services: [website],
  primary_service: website,
});

const tags = new schema.Entity("cms.tags");

const blocks = new schema.Entity("cms.items", {}, processWithParent);

const component = new schema.Entity("cms.components", {}, processWithParent);

const locations = new schema.Entity("cms.locations");

const form = new schema.Entity("cms.forms");

const navLink = new schema.Entity("cms.nav-link", {}, processWithParent);
navLink.define({ children: [navLink] });

const nav = new schema.Entity("cms.navigation", {
  links: [navLink],
});

component.define({
  template_component: component,
  components: [component],
  items: [blocks],
  tags: [tags],
  locations: [locations],
  form_definition: form,
  navigation: nav,
  tags_filter: [tags],
});

const enumValue = new schema.Entity("cms.enum-values");
const enumDef = new schema.Entity("cms.enum-definitions", {
  values: [enumValue],
});

const section = new schema.Entity("cms.sections", {
  tags: [tags],
  cms_tags: [tags],
  items: [component],
  gallery: [blocks],
  links: [navLink],
});

const layout = new schema.Entity("cms.layouts", {
  sections: [section],
  tags: [tags],
});

layout.define({ layout });

section.define({ template: section });

const componentReq = new schema.Entity("cms.components");
componentReq.define({
  components: [component],
  items: [blocks],
});

const sectionReq = new schema.Entity("cms.sections", {
  items: [component],
  gallery: [blocks],
  links: [navLink],
});

const offers = new schema.Entity("cms.entries.offers", {
  sections: [section],
  tags: [tags],
  links: [navLink],
  layout,
});

const offersReq = new schema.Entity("cms.entries.offers", {
  links: [navLink],
});

const rooms = new schema.Entity("cms.entries.rooms", {
  sections: [section],
  tags: [tags],
  amenities: [enumValue],
  layout,
});
const roomsConf = new schema.Entity("cms.entries.rooms_conf", {
  sections: [section],
  tags: [tags],
  amenities: [enumValue],
  layout,
});
const attractions = new schema.Entity("cms.entries.attractions", {
  sections: [section],
  tags: [tags],
  layout,
});
const properties = new schema.Entity("cms.entries.properties", {
  sections: [section],
  tags: [tags],
  layout,
});
const projects = new schema.Entity("cms.entries.projects", {
  sections: [section],
  tags: [tags],
  layout,
});
const gallery = new schema.Entity("cms.entries.gallery", {
  tags: [tags],
});

const posts = new schema.Entity("cms.entries.posts", {
  sections: [section],
  tags: [tags],
  layout,
});

const category = new schema.Entity("cms.categories");
category.define({
  category: category,
  tags: [tags],
  list_tags: [tags],
  layout,
});

const product = new schema.Entity("cms.products");
product.define({
  category,
  tags: [tags],
  layout,
});

const reviews = new schema.Entity("cms.entries.reviews", {
  tags: [tags],
});

const pages = new schema.Entity("cms.pages", {
  sections: [section],
  tags: [tags],
  layout,
  property: properties,
});

const integrations = new schema.Entity("cms.integrations", {
  tags: [tags],
  domains: [domain],
});

const floor = new schema.Entity("apartments.floors");
const flat = new schema.Entity("apartments.flats", {
  floor,
});
const building = new schema.Entity("apartments.buildings", {
  floors: [floor],
});

const user = new schema.Entity("console.users");

const userSettings = new schema.Entity("console.user_settings", {
  user,
  theme_preset: preset,
});

const apiKey = new schema.Entity("console.api_keys", {
  user,
});

const gitLogEntry = new schema.Entity("theme.vcs-log");

const themeVcs = new schema.Entity("theme.vcs", {
  log: [gitLogEntry],
});

const viewField = new schema.Entity("db.view_fields", {});
viewField.define({
  view_fields: [viewField],
});

const field = new schema.Entity("db.fields", {}, processWithParent);
field.define({
  fields: [field],
  view_fields: [viewField],
});

const type = new schema.Entity("db.types");

type.define({
  fields: [field],
  subtype_of: type,
});

const dbEnumValue = new schema.Entity("db.enum-values");
const dbEnumDef = new schema.Entity("db.enum-definitions", {
  values: [dbEnumValue],
});

const dbFormView = new schema.Entity("db.form-views");
dbFormView.define({
  view_fields: [viewField],
});

const dbListFilters = new schema.Entity("db.filters");

const dbListView = new schema.Entity("db.list-views");
dbListView.define({
  filters: dbListFilters,
});

const layoutSettings = new schema.Entity("cms.default_layouts");

const websiteSettings = new schema.Entity("cms.settings");
websiteSettings.define({
  default_layouts: [layoutSettings],
  layouts: [layoutSettings],
});

const jointIdentitiy = new schema.Entity("db.identities");
jointIdentitiy.define({
  identity: jointIdentitiy,
});

const jointNode = new schema.Entity("db.nodes");

const jointPost = new schema.Entity("db.posts");
jointPost.define({
  parent: jointNode,
});

const map = {
  "console.services.cms": cms,
  "console.services.cluster": cluster,
  "console.themes": theme,
  "console.domains": domain,
  "console.services.website": website,
  "console.api_keys": apiKey,
  "console.user_settings": userSettings,
  "cms.pages": pages,
  "cms.sections": section,
  "cms.layouts": layout,
  "cms.tags": tags,
  "cms.entries.offers": offers,
  "cms.entries.rooms": rooms,
  "cms.entries.rooms_conf": roomsConf,
  "cms.entries.attractions": attractions,
  "cms.entries.properties": properties,
  "cms.entries.gallery": gallery,
  "cms.entries.posts": posts,
  "cms.entries.projects": projects,
  "cms.entries.reviews": reviews,
  "cms.categories": category,
  "cms.products": product,
  "cms.components": component,
  "cms.enum-definitions": enumDef,
  "cms.navigation": nav,
  "cms.integrations": integrations,
  "cms.settings": websiteSettings,

  "theme.vcs": themeVcs,
  "req.cms.navigation": nav,
  "req.cms.sections": sectionReq,
  "req.cms.components": component,
  "req.cms.enum-definitions": enumDef,
  "req.cms.entries.offers": offersReq,
  "req.cms.entries.rooms": rooms,
  "req.cms.entries.rooms_conf": roomsConf,
  "req.cms.entries.attractions": attractions,
  "req.cms.entries.properties": properties,
  "req.cms.entries.gallery": gallery,
  "req.cms.entries.posts": posts,
  "req.cms.entries.projects": projects,
  "req.cms.entries.reviews": reviews,
  "req.cms.categories": category,
  "req.cms.products": product,
  "req.cms.settings": websiteSettings,
  "req.console.themes": themeReq,
  "req.console.presets": preset,

  "apartments.flats": flat,
  "apartments.buildings": building,

  "db.types": type,
  "db.fields": field,
  "db.enum-definitions": dbEnumDef,
  "db.form-views": dbFormView,
  "db.list-views": dbListView,
  "db.view_fields": viewField,
  "db.filters": dbListFilters,
  "req.db.enum-definitions": dbEnumDef,
  "req.db.types": type,
  "req.db.form-views": dbFormView,
  "req.db.list-views": dbListView,
  "req.db.view_fields": viewField,
  "req.db.filters": dbListFilters,
};

const collectFields = (schema, fields = []) => {
  if (!schema.fields) return fields;
  Object.keys(schema.fields).forEach((key) => {
    let type = schema.fields[key].type;
    if (type === "group" || type === "tab" || type === "layout") {
      fields = collectFields(schema.fields[key], fields);
    } else {
      fields.push(schema.fields[key]);
    }
  });
  return fields;
};

const normalizeCollectionSchemas = (type, entities) => {
  let collectionTypes = ["collection", "media"];
  let typeKey = ["db", type.id].join(".");
  let entitySchema =
    entities[typeKey] ||
    new schema.Entity(typeKey, {
      //created_by: user
    });
  let fields = collectFields(type);
  fields.forEach((field) => {
    if (collectionTypes.indexOf(field.type) > -1) {
      entities = normalizeCollectionSchemas(field, entities);
      let id = ["db", field.id].join(".");
      let collectionEntitySchema = entities[id] || new schema.Entity(id);
      let definition = {};
      definition[field.id] = [collectionEntitySchema];
      entitySchema.define(definition);
    }
  });
  entities[typeKey] = entitySchema;
  entities["req." + typeKey] = entities[typeKey];
  return entities;
};

const normalizeRefSchemas = (type, entities) => {
  let refTypes = ["ref"];
  let fields = collectFields(type);
  let typeKey = ["db", type.id].join(".");
  let entitySchema = entities[typeKey];
  fields.forEach((field) => {
    if (refTypes.indexOf(field.type) > -1) {
      let refType = field.properties.resource_type;
      if (refType !== typeKey) {
        let multiple = field.properties.multiple;
        let definition = {};
        if (multiple) {
          definition[field.id] = [entities[refType]];
        } else {
          definition[field.id] = entities[refType];
        }
        entitySchema.define(definition);
      }
    }
  });
  //console.log(entitySchema);
  entities[typeKey] = entitySchema;
  entities["req." + typeKey] = entities[typeKey];
  return entities;
};

export const makeSchema = (schema, state) => {
  let dbSchema = schema.db;
  if (!dbSchema) return map;

  let newMap = { ...map };
  Object.keys(dbSchema).forEach((type) => {
    newMap = normalizeCollectionSchemas(dbSchema[type], newMap);
    //newMap = normalizeRefSchemas(dbSchema[type], newMap);
  });
  if (state && state["db.types"]) {
    Object.keys(state["db.types"]).forEach((type) => {
      let dbType = state["db.types"][type];
      newMap = normalizeCollectionSchemas(
        {
          id: dbType.name,
          fields: dbType.fields_index,
        },
        newMap
      );
    });
    /*Object.keys(state['db.types']).forEach(type => {
            let dbType = state['db.types'][type];
            newMap = normalizeRefSchemas({
                id: dbType.name,
                fields: dbType.fields_index
            }, newMap);
        });*/
    //console.log(newMap);
  }
  return newMap;
};

export const denormalizeResource = (schema, state) => (store, resource, id) => {
  let normalizrSchema = makeSchema(schema, state);
  let requestSchema = normalizrSchema["req." + resource];
  let data = store[resource] ? store[resource][id] : null;
  if (data && data.subtype) {
    let subtype = store["db.types"][data.subtype];
    let subtypeKey = ["db", subtype.name].join(".");
    subtype = ["req", subtypeKey].join(".");
    requestSchema = normalizrSchema[subtype];
    store[subtypeKey] = {};
    store[subtypeKey][data.id] = data;
    resource = subtypeKey;
  }
  if (!data) return {};
  if (resource === "theme.styles") return { styles: data };
  if (!requestSchema) return data;
  const input = {};
  let reqSchema = {};
  reqSchema[resource] = [requestSchema];
  input[resource] = [id];
  const denormalized = denormalize(input, reqSchema, store);
  return denormalized[resource][0];
};

export const normalizeResources = (schema, state) => (resourceType, data) => {
  let normalizrSchema = makeSchema(schema, state);
  let resourceSchema = normalizrSchema[resourceType];
  let entities = {};
  if (!resourceSchema) {
    entities[resourceType] = index(data, "id");
    return entities;
  }
  if (!data) return {};
  if (!Array.isArray(data)) data = [data];
  data.forEach((item) => {
    let subtypeKey = null;
    if (item.subtype) {
      let subtype = state["db.types"][item.subtype];
      subtypeKey = ["db", subtype.name].join(".");
      resourceSchema = normalizrSchema[subtypeKey];
    }
    let normalizedItem = normalize([item], [resourceSchema]).entities;
    Object.keys(normalizedItem).forEach((key) => {
      let targetKey = key === subtypeKey ? resourceType : key;
      entities[targetKey] = { ...entities[targetKey], ...normalizedItem[key] };
    });
  });
  return entities;
  //return normalize(data, [resourceSchema]).entities;
};

export default map;
