import React from 'react';

import SectionPreview from 'ide/SectionPreview';
import ThemeProvider from 'ide/ThemeProvider';
import Styled from 'ide/containers/Styled';
import DataContext from 'ide/DataContext';
import {Icon, IconButton} from "@material-ui/core";
import NavPills from 'components/NavPills';
import Select from 'v2/fields/Select';

import ResourceList from 'list/containers/ResourceList';
import {Resource,Listing} from 'list';

export default class AttachSection extends React.PureComponent {

    constructor(props) {
        super(props);

        this.domRef = React.createRef();

        this.leftItemProps = {
            onClick: props.onAttach,
            listRef: this.domRef,
            dragStyle: "fade"
        };

        this.rightItemProps = {
            onClick: props.onDetach,
            onChangeOrder: props.onChange,
            onDrop: props.onImport,
            actions: 'sections-attach-target',
            onActionRequest: props.onTargetActionRequest
        };
    }

    handleExport = (id) => {
        this.props.onExport(id);
    };

    renderSourceList = (props) => {
        const {
            resource,
            listId,
            filterId,
            sourceData,
            display
        } = this.props;

        const ItemComponent = display === 'grid'
            ? SectionPreview
            : Resource;

        return <ResourceList
            listId={listId}
            filterId={filterId}
            resource={resource}
            context={this.props.sourceCtx}
            renderItem={itemProps=><ItemComponent
                {...itemProps}
                {...this.leftItemProps}
                s={props.s}
                context={this.props.sourceCtx}
            />}
            renderList={props=><Listing
                {...props}
                filterLayout="aside"
                filterId={filterId}
                displayOptions={['list', 'grid']}
                resource="cms.sections"
            />}
            watch
            yieldData={true}
            variant={display==='grid' ? "virtualized" : 'virtualized-list'}
            id={listId}
            onDrop={this.handleExport}
            hold={this.props.hold}
        />;
    };

    themeWrapper = (theme, display) => {
        if (display === 'list') return this.renderSourceList({});

        if (theme) return <ThemeProvider key={this.props.source}>
            <DataContext.Provider value={this.props.pagePath}>
                <Styled key={this.props.source} render={this.renderSourceList} />
            </DataContext.Provider>
        </ThemeProvider>;

        return (<DataContext.Provider value={this.props.pagePath}>
            <Styled key={this.props.source} render={this.renderSourceList} />
        </DataContext.Provider>);
    };

    render() {
        const {
            sources,
            source,
            onChangeSource,
            display
        } = this.props;

        return (<div className="attach-section-dialog">
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <div style={{ padding: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div className="console-context" style={{ display: 'flex' }}>
                        <NavPills
                            items={sources}
                            active={source}
                            onClick={onChangeSource}
                        />
                        { this.props.accessAllProjects ? <Select
                            onChange={(key, id)=>this.props.onChangeSource(id)}
                            value={this.props.source}
                            label="Website"
                            id="website"
                            source="api:console.services.website"
                            variant="dropdown"
                            fetchStrategy="always"
                        /> : null }
                    </div>
                    <IconButton onClick={this.props.onCancel}>
                        <Icon>clear</Icon>
                    </IconButton>
                </div>
                <div style={{ display: 'flex', flex: 1, padding: '0 2rem' }}>
                    {this.themeWrapper(this.props.withTheme, display)}
                </div>
            </div>
        </div>);
    }

}

AttachSection.propTypes = {};