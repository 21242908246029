import React from 'react';
import {connect} from 'react-redux';

import { confirmYes, confirmNo } from 'state/actions/ui';

import ConfirmDialog from 'components/dialogs/Confirm';

const mapStateToProps = (store, props) => {
    return {
        isOpen: store.ui.confirmOpen || false,
        message: store.ui.confirmMessage
    }
};

const mapDispatchToProps = {
    yes: confirmYes,
    no: confirmNo
};

class Confirm extends React.PureComponent {

    render() {
        return (<ConfirmDialog {...this.props} />);
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);