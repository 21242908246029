import React from 'react';
import {connect} from 'react-redux';
import List from 'v2/List';
import Resource from 'v2/Resource';
import {treeStructure} from 'containers/TreeView';

const mapStateToProps = (store, props) => {
    return {
    }
};

const mapDispatchToProps = {};

class RenderGraph extends React.PureComponent {
    render() {
        const { data, resource } = this.props;
        return data.map(node=>{
            return <Resource
                resource={resource}
                id={node}
                fetchPolicy="never"
            >
                {({data})=><div
                    onClick={()=>this.props.onClick('parent', data.id)}
                    key={data.id}>
                        {data.name}
                </div>}
            </Resource>;
        });
    }
}

const ConnectedGraph = connect(mapStateToProps, mapDispatchToProps)(RenderGraph);

export default class Graph extends React.Component {

    render() {
        const { resource, onClick } = this.props;

        return (<List
            resource={resource}
        >
            {({data})=><ConnectedGraph
                resource={resource}
                data={data}
                onClick={onClick}
            />}
        </List>);
    }

}