import React from 'react';
import {connect} from 'react-redux';

export const GraphCanvasContext = React.createContext(null);

const mapStateToProps = (store, props) => {
    return {
        id: store.context.id
    }
};

const mapDispatchToProps = {};

function calcNodeOffset(ref, baseX, baseY) {
    let w = ref.offsetWidth;
    let h = ref.offsetHeight;
    let top = ref.offsetTop-baseY;
    let left = ref.offsetLeft-baseX;
    let parent = ref.parentNode.closest('.graph-node');
    let offset = {top, left};
    if (parent) {
        let parentOffset = calcNodeOffset(parent, baseX, baseY);
        offset.top += parentOffset.top;
        offset.left += parentOffset.left;
    }
    return offset;
}

class GraphCanvas extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            offsetLeft: 0,
            offsetTop: 0
        };
        this.canvasRef = React.createRef();
    }

    registerRef = (id, ref) => {
        if (id === this.props.id && ref && this.canvasRef.current) {
            let w = this.canvasRef.current.offsetWidth;
            let h = this.canvasRef.current.offsetHeight;
            let offset = calcNodeOffset(ref, w/2, h/2);
            let ew = 400;
            //console.log(w, h, offset.left, offset.top);
            this.setState({
                offsetLeft: 0-offset.left, //-((w/2)-(lft/2)),
                offsetTop: 0-offset.top
                //offsetTop: (0 - ((offset.top)-421))/2 //-((h/2)-(top/2))
            });
        }
    };

    render() {
        let style = {
            transform: `translateX(${this.state.offsetLeft}px) translateY(${this.state.offsetTop}px)`
        };
        return (<GraphCanvasContext.Provider value={this.registerRef}>
            <div ref={this.canvasRef} className="graph-canvas" style={style}>
                {this.props.children}
            </div>
        </GraphCanvasContext.Provider>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GraphCanvas);