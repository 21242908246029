import React, {Component} from 'react';

import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import ProjectLocale from 'containers/ProjectLocale';
import AppMenuButton from 'components/layout/AppMenuButton';
import ProjectLink from 'components/layout/ProjectLink';
import NotificationsIcon from 'containers/NotificationsIcon';
import User from 'containers/User';
import Access from 'components/Access';
import ReloadWebsiteCache from "../ReloadWebsiteCache";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    }
});

class AppBarImpl extends Component {

    toggleMenu = () => this.props.appMenuToggle();
    closeMenu = () => this.props.appMenuHide();

    render() {
        const {
            classes,
            title,
            serviceType,
            serviceId
        } = this.props;
        return (<AppBar position="fixed" color="primary">
            <Toolbar>
                <AppMenuButton />
                <div className="hide-phone" style={{ margin: '0 1rem' }}>
                    <ProjectLocale />
                </div>
                <Typography onClick={this.toggleMenu} variant="h6" color="inherit">
                    {title}
                </Typography>
                <div className="hide-phone" style={{ flex: 1, display: 'flex', gap: "1rem" }}>
                    <ProjectLink />
                    {serviceType === "website" && <ReloadWebsiteCache id={serviceId} />}
                    <div className={classes.grow} />
                </div>
                <div id="app-bar-actions" className="hide-desktop" style={{ display: 'flex', flex:1, justifyContent: 'flex-end' }}/>
                <div className="hide-phone" style={{ display: 'flex' }}>
                    <User />
                    <Access scopes="master">
                        <NotificationsIcon/>
                    </Access>
                </div>
            </Toolbar>
        </AppBar>);
    }

}

export default withStyles(styles)(AppBarImpl);