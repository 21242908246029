import {
    call,
    takeEvery,
    takeLatest,
    put,
    select
} from 'redux-saga/effects'

import * as ui from 'util/sagas/feedback';

import {
    appSchemaSuccess,
    appUser,
    resourceFetchSuccess,
    listFetch,
    draftsChange,
    draftsChangeField,
    draftsPathCreate,
    draftsPathDetach,
    draftsPathAttach
} from 'state/actions';

import { fetchUrlInfo } from 'state/actions/cms';

import { push } from 'state/actions/route';
import { selectResource } from 'state/selectors/resources';

import * as api from 'util/api/saga';

import anchorme from "anchorme";

function* handleAppBootstrap(action) {
    try {
        const { id, context } = action;
        const userEndpoint = context.app === 'db' ? 'db.user' : 'user';
        const user = yield call(api.get, userEndpoint, null, context);
        const settings = yield call(api.getArgs, {expand:true}, 'console.user_settings.current', null, context);
        yield put(resourceFetchSuccess(
            'console.user_settings',
            'current',
            {...settings.data.data, id: 'current'}
        ));
        yield put(resourceFetchSuccess('console.users', user.data.data.id, user.data.data));
        yield put(resourceFetchSuccess('app.users', user.data.data.id, user.data.data));
        yield put(appUser(user.data.data.id));
        const data = yield call(api.get, id + '.schema', null, context);
        if (context.project) {
            const project = yield call(api.getArgs, {expand: true}, 'console.services', context.project, context);
            yield put(resourceFetchSuccess(
                'console.services.website',
                project.data.data.id,
                project.data.data
            ));
        }
        if (!context.project) {
            let services = user.data.data.services;
            if (user.data.data.services.length === 1) {
                let service = services[0];
                let type = service.type;
                if (type === 'website') {
                    yield put(push('/cms/pl/' + service.id + '/pages'));
                } else {
                    yield put(push('/' + type + '/pl/' + service.id));
                }
                return;
            }
        }
        if (id === 'cms' && context.project) {
            yield put(listFetch('cms.settings'));
            yield put(listFetch('cms.enum-definitions'));
        }
        if (id === 'db' && context.project) {
            yield put(listFetch('db.types'));
            yield put(listFetch('db.form-views'));
            yield put(listFetch('db.list-views'));
            yield put(listFetch('db.filters'));
            yield put(listFetch('db.enum-definitions'));
        }
        yield put(appSchemaSuccess(id, data.data));
        if (!context.project) {
            //yield put(push('/db/pl/123', {}));
        }
    } catch (e) {

    }
}

function* handleLangChange(action) {
    try {
        const { lang } = action;
        const route = yield select((store) => store.router.location.pathname);
        let parts = route.split('/');
        parts[2] = ':lang';
        yield put.resolve({type: 'APP.CLEANUP_RESOURCES'});
        yield put(push(parts.join('/'), {lang}));
    } catch (e) {
        console.log(e);
    }
}

function* handleUpdateSchema(action) {
    let schema = {};
    if (!action.data.schema) return;
    schema[action.data.schema.id] = action.data.schema;
    yield put({
        type: 'APP.SCHEMA.SUCCESS',
        id: 'db',
        schema
    });
}

function* handleExtractUrl(action) {
    try {
        const { resource, id, field, context } = action;
        const data = yield select(store=>selectResource(store, resource, id));
        const value = data[field];
        if (!data.link) {
            const urls = anchorme(value, {list: true});
            const links = anchorme(value);
            if (urls.length) {
                let url = urls[0].raw;
                let update = {link: url, content: links};
                yield put(draftsChange([resource, id], update));
                yield put(fetchUrlInfo(url));
            }
        }
    } catch (e) {
        yield ui.error(e);
    }
}

function* handleFetchUrlInfo(action) {
    try {
        const { url, context } = action;
        const request = {url};
        const response = yield call(api.getArgs, request, 'embed', false, context);
        yield put(resourceFetchSuccess('db.links', url, response.data.data));
    } catch (e) {
        yield ui.error(e);
    }
}

function* handleLayoutDrop(action) {
    try {
        const { drag, hover } = action.result;
        let hoverItem = yield select(store=>selectResource(store, 'db.view_fields', hover.id));
        console.log(action, hoverItem);
        let targetIsLayout = hoverItem.type === 'layout';
        let targetIsField = hoverItem.type === 'field';
        let targetIsSubview = hoverItem.type === 'subview';
        let sourceIsSchema = drag.origin === 'schema';
        if (sourceIsSchema) {
            if (targetIsLayout) {
                yield put(draftsPathCreate(
                    'db.view_fields',
                    ['db.view_fields', hoverItem.id, 'view_fields'],
                    {type: 'field', source: drag.id}
                ));
                return;
            }
            if (targetIsField) {
                yield put(draftsChangeField(
                    action.fieldResource,
                    hoverItem.id,
                    'source',
                    drag.id
                ));
                console.log('change field source');
                return;
            }
            if (targetIsSubview) {
                if (hoverItem.source) return;
                yield put(draftsChangeField(
                    action.fieldResource,
                    hoverItem.id,
                    'source',
                    drag.id
                ));
                return;
            }
            if (!hoverItem.id) {
                console.log('create new field from schema');
                console.log(action);
                yield put(draftsPathCreate(
                    'db.view_fields',
                    action.path,
                    {type: 'field', source: drag.id},
                    hover.id
                ));
                return;
            }
        }
        if (!sourceIsSchema && targetIsLayout && drag.origin !== hover.origin) {
            yield put(draftsPathDetach(
                ['db.view_fields', drag.origin, 'view_fields'],
                drag.id
            ));
            yield put(draftsPathAttach(
                ['db.view_fields', hover.id, 'view_fields'],
                drag.id
            ));
            console.log(action, drag, hover);
        }
    } catch (e) {
        yield ui.error(e);
    }
}

export default function*() {
    yield takeEvery('APP.BOOTSTRAP', handleAppBootstrap);
    yield takeEvery('APP.CHANGE_LANG', handleLangChange);
    yield takeEvery('DRAFT.SUBMIT.SUCCESS', handleUpdateSchema);
    yield takeEvery('APP.EXTRACT_URL', handleExtractUrl);
    yield takeEvery('APP.FETCH_URL_INFO', handleFetchUrlInfo);
    yield takeEvery('IDE.FORM_LAYOUT_DROP', handleLayoutDrop);
}