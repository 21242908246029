import React from 'react';
import {connect} from 'react-redux';
import {query} from 'state/actions/route';

import { selectResource } from 'state/selectors/resources';

import IconButton from 'components/TooltipIconButton';

const mapStateToProps = (store, props) => {
    let type = props.resource
        ? props.resource.split('.')[1]
        : null;
    let typeId = type
        ? selectResource(store, 'db.types.byName', type).id
        : null;
    return {
        id: typeId,
        path: ['db.types',typeId].join('/')
    }
};

const mapDispatchToProps = {
    query
};

class FormSetup extends React.Component {

    handleEdit = () => {
        this.props.query({r: this.props.path});
    };

    render() {
        const { id } = this.props;
        if (!id) return null;
        return (<IconButton size="small" icon="settings" onClick={this.handleEdit} />);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormSetup);