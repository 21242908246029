import React from "react";
import Resource from "containers/Resource";
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';

const UserAvatar = (props) => {
    if (!props.id) return null;
    return (<Resource
        resource="console.users"
        id={props.id}
        fetchPolicy={'yield'}
        render={(props) => {
            return props.data && props.data.profile ? <Tooltip title={props.data.name}>
                <Avatar
                    src={props.data.profile.avatar}
                    style={{ width: '32px', height: '32px' }}
                />
            </Tooltip> : null
        }}
    />)
};

export default UserAvatar;