import React from 'react';
import {connect} from 'react-redux';
import {selectResource} from 'state/selectors/resources';
import classNames from 'classnames';
import {query} from 'state/actions/route';
import { thumbnail } from "util/thumbnail";

import IconButton from 'components/TooltipIconButton';
import Button from 'components/Button';

const mapStateToProps = (store, props) => {
    let item = store.context.gallery;
    const [resource, id] = item ? item.split('/') : [];
    return {
        open: !!item,
        resource,
        id,
        data: selectResource(store, resource, id)
    }
};

const mapDispatchToProps = {
    query
};

class MediaPreview extends React.PureComponent {

    handleClose = () => {
        this.props.query({gallery: null});
    };

    render() {
        const { open, data } = this.props;
        return (<Gallery
            open={open}
            onClose={this.handleClose}
            file={data.file}
            url={data.url}
        />);
    }

}

class Gallery extends React.PureComponent {
    download = () => {
        window.open('/download?file=' + this.props.file);
    };
    open = () => {
        window.open(this.props.url);
    };
    render() {
        const { file, onClose, open } = this.props;
        let classes = {'gallery': true, 'console-context': true};
        classes['gallery--open'] = open;
        let url = thumbnail(file, 'lg-auto');
        let fileParts = file ? file.split('/') : [];
        let filename = fileParts[fileParts.length-1];
        return <div className={classNames(classes)} onClick={onClose}>
            <div className={'console-context gallery__toolbar'}>
                <div className={'gallery__info'}>
                    {filename}
                    <div style={{ display: 'flex', marginLeft: '1rem' }}>
                        <IconButton icon={'cloud_download'} onClick={this.download} />
                        <IconButton icon={'open_in_new'} onClick={this.open} />
                    </div>
                </div>
                <IconButton icon={'close'} onClick={onClose} />
            </div>
            <div className={'gallery__preview'}>
                { url
                    ? <img src={url} alt={file} />
                    : <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div style={{ marginBottom: '0.6rem' }}>Preview not available</div>
                        <div style={{ display: 'flex' }}>
                            <Button onClick={this.open} icon={'open_in_new'}>
                                Open
                            </Button>
                            <Button onClick={this.download} icon={'cloud_download'}>
                                Download
                            </Button>
                        </div>
                    </div> }
            </div>
        </div>
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MediaPreview);