import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";

export default class ListHeading extends Component {

    render() {
        return (<div className="list-heading">
            { this.props.title ? <Typography variant="overline">
                {this.props.title}
            </Typography> : null }
            {this.props.children}
        </div>);
    }

}

ListHeading.propTypes = {
    title: PropTypes.string
};