import React from 'react';
import {connect} from 'react-redux';
import {query} from 'state/actions/route';
//import PropTypes from 'prop-types';

import Popover from 'components/Popover';
import MediaBrowser from 'containers/MediaBrowser';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

const mapState = (store, props) => ({});
const mapDispatch = {
    query
};

class BrowseMediaButton extends React.PureComponent {

    handleLibrary = () => {
        this.props.query({q:'media', select: this.props.path});
    };

    render() {
        return (<Popover
            trigger={this.props.trigger
                ? this.props.trigger
                : (props)=><IconButton
                    onContextMenu={props.onClick}
                    onClose={props.onClose}
                    onClick={this.handleLibrary}
                >
                    <Icon>image_search</Icon>
                </IconButton>}
            content={(props)=><MediaBrowser
                {...props}
                path={this.props.path}
                onSelect={this.props.onSelect}
                onOpenLibrary={this.handleLibrary}
            />}
        />);
    }

}

export default connect(mapState,mapDispatch)(BrowseMediaButton);

BrowseMediaButton.propTypes = {};