import React from 'react';
import PropTypes from 'prop-types';
import CollectionField from 'v2/fields/Collection';

export default class Collection extends React.PureComponent {

    render() {
        return (<CollectionField
            {...this.props}
            viewType={'table-simple'}
            readOnly={true}
            card={false}
        />);
    }

}

Collection.propTypes = {};