import React from 'react';
import {connect} from 'react-redux';

import Timeline from 'react-calendar-timeline'
// make sure you include the timeline stylesheet or the timeline will not be styled
import 'react-calendar-timeline/lib/Timeline.css'
import moment from 'moment'

import App from 'views/layouts/App';
import AppContext from 'containers/AppContext';

import 'css/timeline.css';

const groups = [
    { id: 1, title: 'group 1' },
    { id: 2, title: 'group 2' }
];

for (let i = 3; i < 300; i ++) {
    groups.push({id:i, title: 'group ' + i});
}

const items = [
    {
        id: 1,
        group: 1,
        title: 'item 1',
        start_time: moment(),
        end_time: moment().add(24*3, 'hour')
    },
    {
        id: 2,
        group: 2,
        title: 'item 2',
        start_time: moment().add(-0.5, 'hour'),
        end_time: moment().add(24*7, 'hour')
    },
    {
        id: 3,
        group: 3,
        title: 'item 3',
        start_time: moment().add(2, 'hour'),
        end_time: moment().add(24*5, 'hour')
    }
];

const mapStateToProps = (store, props) => {
    return {
        app: store.context.app,
        lang: store.context.lang,
        project: store.context.project,
        view: store.context.view
    }
};

const mapDispatchToProps = {};

class TimelineView extends React.Component {

    constructor(props) {
        super(props);
        this.appWatch = ["project", "lang"];
    }

    render() {
        const { appKey, app, lang } = this.props;
        return (<AppContext key={appKey} app={app} watch={this.appWatch}>
            <App app={app} key={lang}><Timeline
                style={{ width: '100%' }}
            groups={groups}
            items={items}
                traditionalZoom={true}
                sidebarContent={<div>Above The Left</div>}
                dragSnap={1000*60*60*24}
            timeSteps={{
                second: 0,
                minute: 0,
                hour: 0,
                day: 1,
                month: 1,
                year: 1
            }}
                lineHeight={32}
                stickyHeader={true}
                onCanvasDoubleClick={(itemId,e,time)=>alert(time)}
            itemHeightRatio={1}
            defaultTimeStart={moment().add(-20, 'day')}
            defaultTimeEnd={moment().add(20, 'day')}
            /></App></AppContext>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TimelineView);