import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Resource from 'containers/Resource';

import {selectResource} from 'state/selectors/resources';
import {
    selectPrimaryText,
    selectSecondaryText,
    selectListViewConfigById
} from "state/selectors/list";

import avatarTypes from 'components/list/avatar';

import Chip from 'components/Chip';

const mapState = (store, {resource, id, field}) => {
    let data = selectResource(store, resource, id);
    let config = selectListViewConfigById(store, resource);

    let primaryText = selectPrimaryText(store, {resource, id});
    let secondaryText = selectSecondaryText(store, {resource, id});

    let value = field ? data[field] : primaryText;
    if (resource === 'cms.theme.styles') {
        primaryText = id;
        value = id;
    }

    let avatar = config.avatar
        ? avatarTypes[config.avatar]
        : null;

    return {
        icon: data.icon,
        color: data.color,
        primaryText,
        secondaryText,
        value,
        avatar,
        data
    }
};

function Value(props) {

    const {
        rightIcon,
        icon,
        data,
        color,
        value
    } = props;

    const Avatar = props.avatar;

    return <Chip
        label={value}
        dotColor={color}
        onClick={()=>null}
        rightIcon={rightIcon}
        avatar={Avatar ? <Avatar size="small" data={data} /> : null}
    />;

}

Value.propTypes = {
    rightIcon: PropTypes.element
};

export const ListItemConnector = connect(mapState)(function(props) {
    return props.children(props);
});

export default connect(mapState)(Value);