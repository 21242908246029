import React from 'react';
import {connect} from 'react-redux';
import ResourcesNav from 'containers/ResourcesNav';
import IconButton from 'components/TooltipIconButton';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Access from 'components/Access';
import ResourcesSideNav from 'components/layout/SideNav';

import ArrowBack from '@material-ui/icons/ArrowBack';
import {icon}  from 'util/icon';

import AppMenuButton from 'components/layout/AppMenuButton';

import { query, push } from 'state/actions/route';

import ProjectLocale from 'containers/ProjectLocale';
import DeviceSelect from './DeviceSelect';

const mapStateToProps = (store, props) => {
    const route = store.context.app + '.' + store.context.view;
    return {
        route: route,
        dev: store.context.dev,
        context: store.context,
        current: store.context.side
            ? store.context.side
            : route
    }
};

const mapDispatchToProps = {
    query,
    push
};

class SideNav extends React.PureComponent {

    handleChangeView = (id) => {
        if (id === this.props.route) {
            this.props.query({side: null});
            return;
        }
        this.props.query({side: id});
    };

    back = () => {
        this.props.push('/cms/:lang/:project/:view/:id?/:relation?/:relatedId?', this.props.context);
    };

    dev = () => {
        this.props.query({dev: this.props.dev ? null : true});
    };

    renderIcons = ({resources,current}) => {
        return resources.map((resource) => {
            return (
                <Tooltip key={resource.id} title={current}>
                    <IconButton
                        onClick={()=>this.handleChangeView(resource.id)}
                        color="inherit"
                        className={resource.id === current
                            ? 'side-nav-icon active'
                            : 'side-nav-icon'}
                    >
                        {icon(resource.id) || icon('default')}
                    </IconButton>
                </Tooltip>
            );
        });
    };

    render() {
        return (<div className="ide-side-nav">
            <AppMenuButton />
            <IconButton
                onClick={this.back}
                color="inherit"
                icon="arrow_back"
                tooltip="Powrót"
            />
            <Access scopes="websites.dev">
                <div>
                    <div className="device-select">
                        <DeviceSelect />
                    </div>
                </div>
            </Access>
            <ProjectLocale />
            <Access scopes="websites.dev">
                <IconButton
                    tooltip="Preset"
                    onClick={()=>this.props.query({dev:'preset'})}
                    icon="dev.preset" />
                <IconButton
                    tooltip="Code"
                    onClick={()=>this.props.query({dev:'editor'})}
                    icon="dev.editor" />
                <IconButton
                    tooltip="Github"
                    onClick={()=>this.props.query({dev:'github'})}
                    icon="dev.github" />
                <Divider style={{ color: 'white', margin: '1rem 0'}}/>
            </Access>
            <ResourcesSideNav />
            { false ? <ResourcesNav render={this.renderIcons} /> : null }
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SideNav);