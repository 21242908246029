import React from 'react';

import Typography from '@material-ui/core/Typography';
import Preloader from 'components/Preloader';
import Portal from '@material-ui/core/Portal';
import Access from 'components/Access';

import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

import Button from 'components/Button';

import IdentityIcon from "./IdentityIcon";
import ModificationDate from './widgets/ModificationDate';

import Keymap from 'containers/keys/Keymap';

import FormSetup from 'containers/FormSetup';

import FormTabs from 'containers/FormTabs';
import {SelectField} from 'components/fields/select/SelectField';

const LayoutContext = React.createContext(null);
export const FormLayoutContext = LayoutContext;

const wrapperStyle = {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    maxWidth: '100%',
    maxHeight: '100%'
};

class Form extends React.PureComponent {

    constructor(props) {
        super(props);
        this.root = document.getElementById('root');
    }

    render() {
        const {
            id,            
            isPending,
            schemaPath,
            path,
            onSubmit,
            onSubmitDismiss,
            onCancel,
            keysActive,
            dismissible,
            hideHeader,
            views,
            view,
            backdrop,
            hideCloseButton,
            className
        } = this.props;

        const title = this.props.type || this.props.resource;

        return (<React.Fragment>
            <Preloader visible={isPending} />
            <Keymap
                id="form"
                save={onSubmit}
                saveDismiss={onSubmitDismiss}
                cancel={onCancel}
                active={keysActive}
                global={false}
                style={wrapperStyle}
                className={className}
            >
                { !hideHeader ? <div className="form__header form__header--desktop">
                    {this.props.headerContent}
                    { views && views.length > 1 ? <SelectField
                        options={views}
                        variant="chip"
                        inline={true}
                        value={view}
                        onChange={this.props.onChangeView}
                    /> : null }
                    <div className="form__header-fields" ref={this.props.collectRef} />
                    { onCancel && !hideCloseButton ? (<IconButton onClick={onCancel}>
                        <Icon>clear</Icon>
                    </IconButton>) : null }
                </div> : null }
                { !hideHeader ? <div className="form__header form__header--mobile">
                    { onCancel ? (<IconButton color="inherit" onClick={onCancel}>
                        <Icon>clear</Icon>
                    </IconButton>) : null }
                    <Typography variant="h6" color="inherit" className="form__title">
                        {title}
                    </Typography>
                    { onSubmit ? (<IconButton color="inherit" onClick={onSubmitDismiss || onSubmit}>
                        <Icon>check</Icon>
                    </IconButton>) : null }
                </div> : null }
                <FormLayoutContext.Provider value={this.props.headerRef}>
                    { schemaPath && path ? <FormTabs
                        schemaPath={schemaPath}
                        path={path}
                    /> : this.props.children }
                </FormLayoutContext.Provider>
                <div className="form__footer">
                    <Access scopes="master">
                        <IdentityIcon value={id} />
                    </Access>
                    { path ? <ModificationDate path={path}/> : null }
                    <div style={{ flex: 1 }} />
                    <div className="btn-group">
                        { onCancel && dismissible ? (<Button
                            onClick={onCancel}
                            color="default"
                        >
                            Anuluj
                        </Button>) : null }
                        { onSubmit ? <Button
                            onClick={onSubmit}
                        >
                            Zapisz
                        </Button> : null }
                        { onSubmitDismiss && dismissible ? <Button
                            color="secondary"
                            onClick={onSubmitDismiss}
                            icon="check"
                        >
                            Zapisz i zamknij
                        </Button> : null }
                    </div>
                </div>
            </Keymap>
            { backdrop ? <Portal container={this.root}>
                <div onClick={onCancel} className="form-backdrop visible" />
            </Portal> : null }
        </React.Fragment>);
    }

}

class FormWithRef extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {headerRef: null};
        this.collectRef = (e) => this.setState({headerRef: e});
    }

    componentDidMount() {
        //console.log('mount ' + this.props.path, this.headerRef);
    }

    componentDidUpdate() {
        //console.log('update ' + this.props.path, this.headerRef);
    }

    render() {
        return <Form
            {...this.props}
            headerRef={this.state.headerRef}
            collectRef={this.collectRef}
        />
    }
}

export default FormWithRef;