import React from 'react';
//import PropTypes from 'prop-types';

import UploadButton from 'components/UploadButton';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import BrowseMediaButton from 'components/media/BrowseMediaButton';

export default class MediaCollectionActions extends React.PureComponent {

    render() {
        const { onSelect, onUpload } = this.props;
        return (<React.Fragment>
            { onSelect ? <BrowseMediaButton
                onSelect={this.props.onSelect}
                path={this.props.path}
            /> : null }
            { onUpload ? <UploadButton
                onUpload={this.props.onUpload}
                trigger={<IconButton>
                    <Icon>cloud_upload</Icon>
                </IconButton>}
            /> : null }
        </React.Fragment>);
    }

}

MediaCollectionActions.propTypes = {};