import { createReducer } from "util/redux/create-reducer";
import { setIn } from 'lodash-redux-immutability';

const setUiPendingState = (state, id, status) => {
    let newState = {...state};
    newState.pending = {...newState.pending};
    newState.pending[id] = status;
    return newState;
};

const setListDisplayMode = (state, id, value) => {
    let newState = {...state};
    newState.listDisplay = {...newState.listDisplay};
    newState.listDisplay[id] = value;
    return newState;
};

const toggle = (state, flag, status) => {
    let newState = {...state};
    if (undefined === status) {
        newState[flag] = !newState[flag];
        return newState;
    }
    newState[flag] = status;
    return newState;
};

const addSnackbarNotification = (state, message, status) => {
    let newState = {...state};
    newState.snackbarVisible = true;
    let notifications = newState.snackbar.slice(0,1);
    notifications.push({
        message,
        status
    });
    notifications.reverse();
    newState.snackbar = notifications;
    return newState;
};

const uploadProgress = (state, id, progress) => {
    let newState = {...state};
    newState.progress = {...newState.progress};
    newState.progress[id] = progress;
    return newState;
};

const setUiFlag = (state, id, value) => {
    let newFlags = {...state.flags};
    newFlags[id] = value;
    return {
        ...state,
        flags: newFlags
    }
};

const ideTearDown = (state) => {
    return {...state, styles: false};
};

const listToggle = (state, key, id, force) => {
    let data = {...state[key]};
    if (force) {
        data[id] = force;
    } else {
        data[id] = !data[id];
    }
    state[key] = data;
    return {...state};
};

const actions = {
    'UI.SET_FLAG': (state, {id, value}) => setUiFlag(state, id, value),
    'UI.PENDING': (state, {id,status}) => setUiPendingState(state, id, status),
    'UI.APP_MENU.TOGGLE': (state) => toggle(state, 'showAppMenu'),
    'UI.APP_MENU.SHOW': (state) => toggle(state, 'showAppMenu', true),
    'UI.APP_MENU.HIDE': (state) => toggle(state, 'showAppMenu', false),
    'UI.LIST_ACTIONS.TOGGLE': (state) => toggle(state, 'showListActions'),
    'UI.LIST_ACTIONS.SHOW': (state) => toggle(state, 'showListActions', true),
    'UI.LIST_ACTIONS.HIDE': (state) => toggle(state, 'showListActions', false),
    'UI.FEEDBACK': (state, {message, status}) => addSnackbarNotification(state, message, status),
    'UI.FEEDBACK.HIDE': (state) => ({...state, snackbarVisible: false}),
    'UI.CONFIRM.SHOW': (state, {message}) => ({...state, confirmOpen: true, confirmMessage: message}),
    'UI.CONFIRM.HIDE': (state) => ({...state, confirmOpen: false}),
    'UI.PROMPT.SHOW': (state, {id}) => ({...state, prompt: id}),
    'UI.PROMPT.HIDE': (state) => ({...state, prompt: false}),
    'UI.LIST_DISPLAY': (state, {id, value}) => setListDisplayMode(state, id, value),
    'UI.NOTIFICATIONS.TOGGLE': (state) => toggle(state, 'notifications'),
    'UI.NOTIFICATIONS.SHOW': (state) => toggle(state, 'notifications', true),
    'UI.NOTIFICATIONS.HIDE': (state) => toggle(state, 'notifications', false),
    'FILES.UPLOAD': (state, {payload}) => uploadProgress(state, payload.id, 1),
    'FILES.UPLOAD.PROGRESS': (state, {payload}) => uploadProgress(state, payload.id, payload.progress),
    'FILES.UPLOAD.SUCCESS': (state, {payload}) => uploadProgress(state, payload.id, null),
    //'IDE.REFRESH_STYLES': (state) => ({...state, styles: false}),
    'IDE.STYLES_READY': (state) => ({...state, styles: true}),
    'IDE.TOGGLE_ZOOM_OUT': (state) => toggle(state, 'ideZoomOut'),
    'IDE.TEAR_DOWN': (state) => ideTearDown(state),
    'UI.TREE.EXPAND': (state, {id, status}) => listToggle(state, 'treeState', id, status),
    'UI.VIEW.SET': (state, {viewType, resource, id}) => {
        return setIn(state, ['views', viewType, resource], id)
    }
};

const initial = {
    pending: {},
    listDisplay: {},
    showAppMenu: false,
    showListActions: false,
    snackbar: [],
    progress: {},
    styles: null,
    flags: {},
    key: null,
    notifications: false,
    prompt: false,
    accessScopes: false,
    treeState: {},
    views: {
        form: {},
        list: {}
    }
};

export default createReducer(initial, actions);