import React from 'react';
import {connect} from 'react-redux';
import {changed} from 'util/changed';
import {appBootstrap} from "state/actions";
import Preloader from 'components/Preloader';

const defaultAppWatch = ['app', 'lang'];

const mapStateToProps = (store, props) => {
    const app = props.app || store.context.app;
    return {
        app,
        lang: store.context.lang,
        project: store.context.project,
        context: store.context,
        watch: props.watch || defaultAppWatch,
        schema: store.schema[app]
    }
};

const mapDispatchToProps = {
    fetch: appBootstrap
};

class AppContext extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetch(this.props.app);
    }

    componentDidUpdate(prevProps) {
        if (changed(this.props.watch, prevProps, this.props)) {
            this.props.fetch(
                this.props.app,
                this.props.context
            );
        }
    }

    render() {
        if (!this.props.app || !this.props.context.app || !this.props.schema) {
            return <Preloader visible={true} />;
        }
        return this.props.children;
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(AppContext);