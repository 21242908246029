import React from 'react';
//import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PropertyGroup from './PropertyGroup';

/*const elementDefaultPropGroups = {
    "layout.layout": ["layout", "area-padding"]
};*/

const selectOptionGroups = (store, value) => {
    const index = store.data['theme.styles.optionsIndex'];
    const groups = {};
    const classes = [];
    if (!value) {
        groups.props = classes;
        return groups;
    }
    if (!Array.isArray(value)) {
        classes.push(value);
        groups.props = classes;
        return groups;
    }
    value.forEach((v)=> {
        if (groups && index && index[v]) {
            groups[index[v]] = v;
        } else {
            classes.push(v);
        }
    });
    groups.props = classes;
    return groups;
};

const withRoleGroups = (store, props) => {
    const groups = selectOptionGroups(store, props.value);
    const styles = store.data['theme.styles'];
    const roles = styles ? styles.element_roles || {} : {};
    const elementRoles = roles[props.id] || [];
    let allGroups = [];
    elementRoles.forEach((role)=> {
        let roleOptions = styles.option_roles[role];
        allGroups = allGroups.concat(roleOptions);
    });
    allGroups.forEach((g) => {
       if (!groups[g]) {
           groups[g] = '';
       }
    });
    return groups;
};

const mapStateToProps = (store, props) => {
    const groups = withRoleGroups(store, props);
    return {
        groups,
        groupIds: Object.keys(groups)
    };
};

const mapDispatchToProps = {};

class Element extends React.PureComponent {

    handleChange = (id, value) => {
        let newValue = {...this.props.groups};
        newValue[id] = value;
        let serialized = [];
        Object.keys(newValue).forEach((gid) => {
            serialized = serialized.concat(newValue[gid]);
        });
        this.props.onChange(serialized);
    };

    render() {
        const { groupIds, groups } = this.props;
        return (<div className="se-element">
            <div className="se-element__value">
                <div className="se-element__groups">
                    {groupIds.map((gid) => {
                        return (<PropertyGroup
                            key={gid}
                            id={gid}
                            value={groups[gid]}
                            onChange={this.handleChange}
                        />);
                    })}
                </div>
            </div>
        </div>);
    }

}

Element.propTypes = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Element);