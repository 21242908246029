import {connect} from 'react-redux';
import {selectNamespacedResources} from "state/selectors/resources";
import {selectUserApps, selectCurrentApp} from 'state/selectors/access';
import {setUiFlag} from 'state/actions/ui';

const mapStateToProps = (store, props) => {
    return {
        apps: selectUserApps(store),
        selected: selectCurrentApp(store),
        route: ['apps',store.context.app].join('.')
    }
};

const mapDispatchToProps = {
    onChange: setUiFlag
};

const AppsNav = (props) => {
    const handleChangeTab = (evt, id) => {
        props.onChange('appNavTab', id);
    };
    return props.render({...props, onChange: handleChangeTab});
};

export default connect(mapStateToProps, mapDispatchToProps)(AppsNav);