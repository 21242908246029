import React from 'react';
//import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Popover from 'components/Popover';
import ComponentTypeSelect from './ComponentTypeSelect';


export default class CreateComponent extends React.PureComponent {

    render() {
        return (<Popover
            trigger={(props)=><IconButton {...props}>
                <Icon>add_circle</Icon>
            </IconButton>}
             content={(props)=><ComponentTypeSelect
                 {...props}
                 resource={this.props.resource}
                 onCreate={this.props.onCreate}
             />}
        />);
    }

}

CreateComponent.propTypes = {};